import { Moment } from 'moment'
import { reservationKind } from '../constants'
import { CampingStyleProps, CampProps, SiteProps } from '../interfaces'
import Api2 from '../api2'
import { IAdditionalFee, IAddOnParam } from '../api2/price_details'
import { validElectric } from '../types'

export type PriceDetails = {
  nights: number
  nightly_price: number
  frequency_label: string
  subtotal: number
  discounted_subtotal: number
  service_rate: number
  service_fee: number
  tax_rate: number
  tax: number
  total: number
  holiday_nights: number
  holiday_nightly_fee: number
  holiday_total_fee: number
  discounted_holiday_total_fee: number
  weekend_nights: number
  weekend_nightly_fee: number
  weekend_total_fee: number
  discounted_weekend_total_fee: number
  additional_adults: number
  adults_fee: number
  additional_kids: number
  kids_fee: number
  additional_pets: number
  pets_fee: number
  flat_rate_price: number
  additional_fees: Array<IAdditionalFee>
  credit_card_rate: number
  credit_card_fee: number
}

const priceDetailsFor = async (data: {
  kind: reservationKind
  camp: CampProps
  campingStyle?: CampingStyleProps
  site?: SiteProps
  siteIds?: string[]
  startDate: Moment
  endDate: Moment
  discountSlug: string
  couponCodes?: string[]
  subtotalOverride?: number
  isTaxableEnabled?: boolean
  isPassengerFeesEnabled?: boolean
  isPetsFeeEnabled?: boolean
  isHolidayEnabled: boolean
  isWeekendEnabled: boolean
  isServiceFeeApplied?: boolean
  isCreditCardFeeEnabled?: boolean
  numberOfAdults?: number
  numberOfKids?: number
  numberOfPets?: number
  userElectric: validElectric
  addOns?: Array<IAddOnParam>
  intervalId?: string
}): Promise<PriceDetails | PriceDetails[]> => {
  if (data.siteIds?.length) {
    return Api2.PriceDetails.forSites(data.siteIds, {
      campingStyleId: data.campingStyle?.id,
      kind: data.kind,
      startDate: data.startDate.toISOString(),
      endDate: data.endDate.toISOString(),
      discountSlug: data.discountSlug,
      couponCodes: data.couponCodes,
      subtotalOverride: typeof data.subtotalOverride === 'number' ? data.subtotalOverride : null,
      isTaxEnabled: data.isTaxableEnabled,
      is_passenger_fees_enabled: data.isPassengerFeesEnabled,
      isPetsFeeEnabled: data.isPetsFeeEnabled,
      isHolidayEnabled: data.isHolidayEnabled,
      isWeekendEnabled: data.isWeekendEnabled,
      isServiceFeeApplied: data.isServiceFeeApplied,
      isCreditCardFeeEnabled: data.isCreditCardFeeEnabled,
      number_of_adults: data.numberOfAdults,
      number_of_kids: data.numberOfKids,
      number_of_pets: data.numberOfPets,
      user_electric: data.userElectric,
      add_ons: data.addOns,
      interval_id: data.intervalId,
    })
  } else if (data.site) {
    return Api2.PriceDetails.forSite(data.site.id, {
      campingStyleId: data.campingStyle?.id,
      kind: data.kind,
      startDate: data.startDate.toISOString(),
      endDate: data.endDate.toISOString(),
      discountSlug: data.discountSlug,
      couponCodes: data.couponCodes,
      subtotalOverride: typeof data.subtotalOverride === 'number' ? data.subtotalOverride : null,
      isTaxEnabled: data.isTaxableEnabled,
      is_passenger_fees_enabled: data.isPassengerFeesEnabled,
      isPetsFeeEnabled: data.isPetsFeeEnabled,
      isHolidayEnabled: data.isHolidayEnabled,
      isWeekendEnabled: data.isWeekendEnabled,
      isServiceFeeApplied: data.isServiceFeeApplied,
      isCreditCardFeeEnabled: data.isCreditCardFeeEnabled,
      number_of_adults: data.numberOfAdults,
      number_of_kids: data.numberOfKids,
      number_of_pets: data.numberOfPets,
      user_electric: data.userElectric,
      add_ons: data.addOns,
      interval_id: data.intervalId,
    })
  } else {
    throw null
  }
}

export default priceDetailsFor
