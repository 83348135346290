import { DocumentPlusIcon, XCircleIcon } from '@heroicons/react/24/solid'
import React, { useContext, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import api from '../../api2'
import { SiteProps } from '../../interfaces'
import { useDebouncedCallback } from 'use-debounce'
import Spinner from '../ui/Spinner'
import { AdminContext } from '../../contexts/AdminContext'
import { uniqueId } from 'lodash'

const fileTypes = ['JPG', 'JPEG', 'PNG']

function SitePhotoUpload({ site }: { site: SiteProps }) {
  const { showNotification } = useContext(AdminContext)
  const [imageStates, setImageStates] = useState<Array<{ file: File, url: string; key: string; isLoading: boolean }>>([])

  useEffect(() => {
    fetchImages()
  }, [])

  const fetchImages = useDebouncedCallback(() => {
    if (!site) return

    api.Sites.fetchImages(site.id)
      .then((response) => {
        setImageStates(response.images.map((image) => ({
          file: null,
          url: image.url,
          key: image.key,
          isLoading: true // assume they still need to load in
        })))
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Failed to fetch images',
          description: err.message,
        })
      })
  }, 50)

  const handleAdd = async (files: FileList) => {
    const fileList = Array.from(files)
    const newImages = fileList.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Temporary URL for preview
      key: `temp-key-${uniqueId()}`,
      isLoading: true
    }))

    setImageStates(imageStates.concat(newImages))

    newImages.forEach((imageState) => {
      api.Sites.uploadImage(site.id, imageState.file)
        .then((response) => {
          setImageStates((currentStates) =>
            currentStates.map((img) =>
              img.key === imageState.key ? { ...img, url: response.image.url, key: response.image.key, isLoading: false } : img
            )
          )
        })
        .catch((err) => {
          console.error(err)
          showNotification({
            type: 'error',
            title: `Failed to upload image ${imageState.file.name}`,
            description: err.message,
          })
        })
    })
  }

  const handleRemove = (key: string) => {
    setImageStates((currentStates) =>
      currentStates.map((img) => (img.key === key ? { ...img, isLoading: true } : img))
    )

    api.Sites.removeImage(site.id, key)
      .then(() => {
        setImageStates((currentStates) => currentStates.filter((img) => img.key !== key))
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Failed to remove image',
          description: err.message,
        })
        setImageStates((currentStates) =>
          currentStates.map((img) => (img.key === key ? { ...img, isLoading: false } : img))
        )
      })
  }

  const updateImageLoadState = (key: string, isLoading: boolean) => {
    setImageStates((currentStates) =>
      currentStates.map((img) =>
        img.key === key ? { ...img, isLoading } : img
      )
    )
  }

  if (!site) return <></>

  return (
    <>
      <label className='block text-md text-gray-900 mb-1'>Site photos</label>
      <div className='gap-2 mb-2 mt-1 flex overflow-x-auto'>
        {imageStates?.map(({ url, key, isLoading }) =>
          <Image
            src={url}
            handleRemove={() => handleRemove(key)}
            isLoading={isLoading}
            onLoaded={() => updateImageLoadState(key, false)}
            onError={() => updateImageLoadState(key, false)}
          />
        )}
      </div>
      
      <FileUploader
        handleChange={handleAdd}
        name='file'
        types={fileTypes}
        multiple
        children={
          <div className='border-2 border-dashed border-gray-300 p-8 h-24 flex items-center rounded-md cursor-pointer hover:bg-gray-75'>
            <DocumentPlusIcon className='w-8 h-8 text-gray-500 mr-4' />
            <div className='flex justify-between w-full'>
              <span className='text-gray-600 text-md'>Click or drag a photo here to upload</span>
              <span className='text-gray-500 text-md'>{fileTypes.join(', ')}</span>
            </div>
          </div>
        }
      />
    </>
  )
}

export const Image = ({ src, handleRemove, isLoading, onLoaded, onError }) => (
  <div className='block flex-none bg-white w-24 h-24 relative overflow-hidden rounded-lg'>
    {isLoading && (
      <div className='absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center'>
        <Spinner size={8} className='w-8 h-8 text-white' />
      </div>
    )}
    <button type='button' className='z-10 w-8 h-8 absolute top-0 right-0' onClick={handleRemove}>
      <XCircleIcon className='text-white hover:text-gray-300 cursor-pointer' />
    </button>
    <img
      src={src}
      className='absolute w-full h-full object-cover object-center hover:opacity-90 cursor-pointer'
      alt="Uploaded content"
      onLoad={onLoaded}
      onError={onError}
      onClick={() => window.open(src, '_blank')}
    />
  </div>
)

export default SitePhotoUpload
