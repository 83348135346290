import { Menu, Transition } from '@headlessui/react'
import { ArrowLeftOnRectangleIcon, EnvelopeIcon, CheckBadgeIcon as CheckBadgeIconSolid } from '@heroicons/react/24/solid'
import { Fragment, useContext, useEffect, useState } from 'react'
import React from 'react'
import Button from './Button'
import classNames from '../../utils/classNames'
import { AdminContext } from '../../contexts/AdminContext'
import { ReservationProps } from '../../interfaces'

interface ComponentProps {
  className?: string
  checkins: ReservationProps[]
  checkouts: ReservationProps[]
  reservations: ReservationProps[]
}

const TodoDropdownButton = (props: ComponentProps) => {
  const [helperText, setHelperText] = useState({ text: '', isError: true })
  const [clicked, setClicked] = useState(false)
  const [clickedTimeout, setClickedTimeout] = useState(setTimeout(() => {}))
  const { showMessageModal, camp } = useContext(AdminContext)

  const handleClick = () => {
    setClicked(true)

    clearTimeout(clickedTimeout)
    setClickedTimeout(
      setTimeout(() => {
        setClicked(false)
      }, 2500)
    )
  }
  
  return (
    <div className="flex items-center">
      <Transition
        show={clicked}
        as={Fragment}
        enter="transform ease-in duration-500 transition"
        enterFrom="translate-x-2 opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transform ease-out duration-500 transition"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-2 opacity-0"
      >
        <span
          className={`text-sm mr-2.5 font-semibold ${
            helperText.isError ? 'text-red-400' : 'text-green-500'
          }`}
        >
          {helperText.text}
        </span>
      </Transition>
      <Menu
        as="div"
        className={classNames('relative flex-shrink-0 ml-4 sm:ml-0', props.className)}
      >
        <Menu.Button>
          <Button variant="blue" className="ml-auto flex-shrink-0" style={{height: 40.5}}>
            Message guests
          </Button>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-left right-auto left-0 sm:origin-top-right absolute sm:left-auto sm:right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
            <Menu.Items>
              <Menu.Item as="div">
                <button
                  onClick={() => {
                    showMessageModal({
                      reservations:
                        props.checkins,
                      isToAndCCBlocked: true
                    })
                  }}
                  className="flex items-center py-4 px-6 hover:bg-gray-100 cursor-pointer w-full"
                >
                  <CheckBadgeIconSolid className="text-gray-400 w-5.5 h-5.5 mr-2" />
                  <p className="text-gray-700">Message check-ins</p>
                </button>
              </Menu.Item>
              <Menu.Item as="div">
                <button
                  onClick={() => {
                    showMessageModal({
                      reservations:
                        props.checkouts,
                      isToAndCCBlocked: true
                    })
                  }}
                  className="flex items-center py-4 px-6 hover:bg-gray-100 cursor-pointer w-full"
                >
                  <ArrowLeftOnRectangleIcon className="text-gray-400 w-5.5 h-5.5 mr-2" />
                  <p className="text-gray-700">Message check-outs</p>
                </button>
              </Menu.Item>
              <Menu.Item as="div">
                <button
                  onClick={() => {
                    showMessageModal({
                      reservations:
                        props.reservations,
                      isToAndCCBlocked: true
                    })
                  }}
                  className="flex items-center py-4 px-6 hover:bg-gray-100 cursor-pointer w-full"
                >
                  <EnvelopeIcon className="text-gray-400 w-5.5 h-5.5 mr-2" />
                  <p className="text-gray-700 whitespace-nowrap">
                    Message all current guests
                  </p>
                </button>
              </Menu.Item>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    </div>
  )
}

export default TodoDropdownButton
