import React, { useContext, useEffect, useRef, useState } from 'react'
import IOrder from '../../../interfaces/IOrder'
import { AdminContext } from '../../../contexts/AdminContext'
import { useDebouncedCallback } from 'use-debounce'
import api from '../../../api2'
import ILineItem from '../../../interfaces/ILineItem'
import { mapLineItemToParams } from '../../../api2/orders'
import * as Sentry from '@sentry/react'
import PaymentTypeSelector from '../../Reservations/PaymentModal/PaymentTypeSelector'
import ePaymentType from '../../../enums/ePaymentType'
import serviceRateModeForPaymentType from '../../../utils/serviceRateModeForPaymentType'
import DiscountProps from '../../../interfaces/DiscountProps'
import IAddOn from '../../../interfaces/IAddOn'
import AdminAddOns from '../../Reservations/PaymentModal/AdminAddOns'
import { Switch, Transition } from '@headlessui/react'
import Spinner from '../../ui/Spinner'
import classNames from '../../../utils/classNames'
import { CubeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { formatMoneyFromCents } from '../../../utils/formatMoney'
import OrderSummary from '../Orders/OrderSummary'
import Button from '../../ui/Button'
import StripeCardForm from '../../Reservations/PaymentModal/StripeCardForm'
import StoreSearchBar from './StoreSearchBar'
import CamperSearchBar from './CamperSearchBar'
import ICamper from '../../../interfaces/ICamper'
import CamperCard from '../../ui/CamperCard'
import PaymentLinkForm from '../../Reservations/PaymentModal/PaymentLinkForm'
import ProductModalContent from '../Settings/Product/ProductModalContent'
import IPaymentMethods from '../../../interfaces/IPaymentMethods'
import Modal from '../../ui/Modal'
import Toggle from '../../ui/Toggle'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { set } from 'lodash'
import { useReactToPrint } from 'react-to-print'
import { bySort } from '../../../utils/sorts'

const StoreContent = (props) => {
  const { camp, campingStyles, showNotification, showModal } = useContext(AdminContext)
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState<IAddOn[]>([])
  const [order, setOrder] = useState<IOrder>({} as IOrder)
  const [orderToPrint, setOrderToPrint] = useState<IOrder>({} as IOrder)
  const [lineItems, setLineItems] = useState<ILineItem[]>([])
  const [isEnabledTaxFee, setIsEnabledTaxFee] = useState(Number(camp.tax_rate) > 0)
  const [isEnabledCreditCardFee, setIsEnabledCreditCardFee] = useState(Number(campingStyles.find((cs) => cs.credit_card_rate)?.credit_card_rate) > 0)
  const [paymentMethod, setPaymentMethod] = useState<ePaymentType>(ePaymentType.CARD_INPUT)
  const [isEnabledServiceFee, setIsEnabledServiceFee] = useState([ePaymentType.CARD_INPUT, ePaymentType.LINK, ePaymentType.CARD_READER].includes(paymentMethod))
  const [filter, setFilter] = useState('')
  const [camperFilter, setCamperFilter] = useState()
  const [selectedCamper, setSelectedCamper] = useState<ICamper>()
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>()
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [intervalId, setIntervalId] = useState<number>()
  const [showCloseButton, setShowCloseButton] = useState(false)
  const campingStyleId = campingStyles.find((cs) => cs.credit_card_rate)?.id || campingStyles[0]?.id
  const [isSuccess, setIsSuccess] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isPrintLoading, setIsPrintLoading] = useState(false)

  const receiptPrintRef = useRef(null)


  useEffect(() => {
    fetchProducts()
  }, [camp])

  useEffect(() => {
    if (open) buildOrder()
  }, [lineItems, isEnabledTaxFee, isEnabledCreditCardFee, paymentMethod, selectedCamper])

  const fetchProducts = async (): Promise<void> => {
    api.Products.forSites(camp.id)
      .then(({ products: p }) => {
        setProducts(p)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const buildOrder = useDebouncedCallback(() => {
    if (!camp) return

    api.Orders.createPreview({
      order_id: order.id,
      camp_id: camp.id,
      camping_style_id: campingStyleId,
      camper_id: selectedCamper?.id,
      include_payment_methods: true,
      is_credit_card_fee_enabled: isEnabledCreditCardFee,
      is_service_fee_enabled: isEnabledServiceFee,
      service_rate_mode: serviceRateModeForPaymentType(paymentMethod),
      discount_slug: discountFromLineItems(lineItems)?.slug,
      line_items_attributes: lineItems.map((li) => mapLineItemToParams(li, isEnabledTaxFee)),
    })
      .then(({ order: { preview } }) => {
        setOrder(preview)
        setPaymentMethods(preview.payment_methods || paymentMethods)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
        setIsLoading(false)
        //setErrorMessage('Something went wrong. Please reload the page')
      })
  }, 100)

  const discountFromLineItems = (lineItems: Partial<ILineItem>[]): DiscountProps => {
    const discountIds = lineItems?.map((li) => li.discount_id) || []
    return camp.discounts.find((discount) => discountIds.includes(discount.id))
  }

  const handleChangePaymentType = (v: ePaymentType) => {
    setPaymentMethod(v)
    if (v === 'cash') {
      setIsEnabledCreditCardFee(order.credit_card_rate > 0)
      setIsEnabledServiceFee(false)
    } else {
      setIsEnabledCreditCardFee(order.credit_card_rate > 0)
      setIsEnabledServiceFee(true)
    }
  }

  const onAutofill = (camper: ICamper) => {
    setSelectedCamper(camper)
  }

  const handleClickTakeCashPayment = () => {
    api.Orders.createPayment({
      order_id: order.id,
      camp_id: camp.id,
      camper_id: selectedCamper?.id,
      camping_style_id: campingStyleId,
      is_credit_card_fee_enabled: isEnabledCreditCardFee,
      discount_slug: discountFromLineItems(lineItems)?.slug,
      line_items_attributes: lineItems.map((li) => mapLineItemToParams(li, isEnabledTaxFee)),
    })
      .then(({ payment }) => {
        setOrderToPrint(payment.order)        
        setIsSuccess(true)
      })
      .catch((err) => {
        console.error(err)
        showNotification({ type: 'error', title: err.message })
      })
  }

  const handleProductClick = (selectedProduct?: IAddOn) => {
    showModal(
      <ProductModalContent fetchProducts={fetchProducts} selectedProduct={selectedProduct} />,
      true,
      'max-w-4xl'
    )
  }

  const shownProducts = products?.filter(
    (addon) =>
      !['always-applied', 'hidden'].includes(addon.kind) &&
      addon.addable_camping_style_id === null &&
      addon.label.toLowerCase().includes(filter.toLowerCase())
  )

  const clearStore = (): boolean => {
    // TODO: clear everything back to null
    setOrder({} as IOrder)
    setSelectedCamper(null)
    setCamperFilter(null)
    setFilter('')
    setLineItems([])
    setPaymentMethod(ePaymentType.CARD_INPUT)
    setPaymentStatus(null)
    setErrorMessage('')
    setPaymentMethods(null)

    return true
  }

  const usePrint = useReactToPrint({
    content: () => receiptPrintRef.current,
    onAfterPrint: () => setIsPrintLoading(false),
  })

  useEffect(() => {
    if (isPrintLoading) {
      usePrint()
    }
  }, [isPrintLoading])

  return (
    <div className='w-full'>
      {isPrintLoading && (
        <div className='fixed overflow-hidden inset-0 bg-white z-50 flex flex-col space-y-4 justify-center items-center h-full w-full'>
          <Spinner size={16} />
          <p>Preparation for printing...</p>
        </div>
      )}
      <Modal
        open={isSuccess}
        onClose={() => {
          clearStore()
          setIsSuccess(false)
        }}
        maxWidth='max-w-xl'>
        <div className=''>
          <div className='text-center'>
            <CheckCircleIcon className='w-8 h-8 text-green-500 mx-auto' />
            <h3 className='mb-12 text-xl font-bold text-green-500'>Payment successful</h3>
          </div>
          <div className='flex justify-center mt-6 gap-3'>
            <Button
              variant='gray'
              onClick={() => {
                setIsPrintLoading(true)
              }}
            >
              Print receipt
            </Button>
            <Button
              variant='blue'
              onClick={() => {
                clearStore()
                setIsSuccess(false)
              }}>
              Close and clear order
            </Button>
          </div>
        </div>
      </Modal>
      <div className='flex flex-row justify-between'>
        <div className='flex-grow pr-8 border-r border-gray-200 h-screen flex flex-col'>
          <div className='flex items-center pb-4 justify-between w-full bg-white border-b z-10 sticky top-0'>
            <StoreSearchBar setFilter={setFilter} />
            <div>
              <Button
                variant='white'
                onClick={() => {
                  if (isEditing) {
                    setIsEditing(false)
                  } else {
                    setIsEditing(true)
                  }
                }}
                className={classNames('flex-shrink-0 ml-2', isEditing && 'animate-pulse')}>
                {isEditing ? 'Stop editing' : 'Edit products'}
              </Button>
              <Button
                variant='blue'
                onClick={() => handleProductClick()}
                className='flex-shrink-0 ml-2'>
                Add product
              </Button>
            </div>
          </div>
          <div className='flex-grow overflow-y-auto' style={{ maxHeight: 'calc(100vh - 10rem)' }}>
            <AdminAddOns
              addOns={shownProducts?.sort(bySort)}
              primaryColor={camp?.primary_color}
              lineItems={lineItems}
              setLineItems={setLineItems as (v: Array<Partial<ILineItem>>) => void}
              handleProductClick={handleProductClick}
              isEditing={isEditing}
            />
          </div>
        </div>

        <div className='max-w-lg w-full flex-shrink-0 ml-8'>
          {isLoading ? (
            <Transition
              show={isLoading}
              enter='transition-opacity ease-out duration-75'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-in duration-75'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div className='z-10 absolute w-full h-full bg-white opacity-50 flex items-center justify-center'>
                <Spinner size={6} />
              </div>
            </Transition>
          ) : (
            <div
              className={classNames('flex flex-col w-full items-stretch max-w-lg pb-6')}
              style={{ height: 'calc(100vh - 5rem)' }}>
              <div className='max-w-lg w-full mb-2 mt-px'>
                {selectedCamper ? (
                  <CamperCard camper={selectedCamper} onClose={() => clearStore()} />
                ) : (
                  <CamperSearchBar
                    value={camperFilter}
                    onChange={(e) => setCamperFilter(e.target.value)}
                    onAutofill={onAutofill}
                  />
                )}
              </div>
              <div className={classNames('overflow-y-auto flex-grow')}>
                {order?.line_items?.map((line_item) => (
                  <div className='bg-gray-50 rounded-md border border-gray-200 px-4 py-2 mb-2'>
                    <div className='mb-1 flex justify-between items-center'>
                      <div className='flex flex-col'>
                        <label htmlFor='name' className='flex items-center text-base text-gray-900'>
                          <span>{line_item.label}</span>
                          <XMarkIcon className='w-4 h-4 inline mx-1 mt-0.5' />
                          <span>{line_item.quantity}</span>
                        </label>
                      </div>
                      <span className='text-gray-900'>
                        <span>
                          {formatMoneyFromCents(line_item.total_amount_in_cents, camp.currency)}
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className='flex-shrink-0 flex-grow w-full max-w-lg bg-white flex flex-col-reverse'>
                <div className='pb-4'>
                  <div className=''>
                    <div className='flex flex-col justify-end space-y-2 mb-4'>
                      {camp.tax_rate && (
                        <Toggle
                          checked={isEnabledTaxFee}
                          onChange={setIsEnabledTaxFee}
                          label='Apply tax fee'
                        />
                      )}
                      {campingStyles.some((cs) => cs.credit_card_rate) && (
                        <Toggle
                          checked={isEnabledCreditCardFee}
                          onChange={setIsEnabledCreditCardFee}
                          label='Apply credit card fee'
                        />
                      )}
                    </div>
                  </div>
                  <div className='mb-2 flex justify-between items-center'>
                    <label htmlFor='name' className='inline font-bold text-md text-gray-900'>
                      Payment method
                    </label>
                  </div>
                  <PaymentTypeSelector
                    options={['card-input', 'link', 'cash']}
                    selectedOption={paymentMethod}
                    onChange={handleChangePaymentType}
                  />
                  {paymentMethod === 'cash' ? (
                    <div className='flex justify-end items-center mt-6'>
                      <Button
                        variant='blue'
                        onClick={handleClickTakeCashPayment}
                        disabled={Number(order?.total_in_cents) <= 0}>
                        Take offline payment
                      </Button>
                    </div>
                  ) : paymentMethod === 'link' ? (
                    <PaymentLinkForm
                      order={order}
                      email={selectedCamper?.email}
                      campingStyleId={campingStyleId}
                      onSuccess={() => setIsSuccess(true)}
                      isTaxFeeEnabled={isEnabledTaxFee}
                      isCreditCardFeeEnabled={isEnabledCreditCardFee}
                      discountSlug={discountFromLineItems(lineItems)?.slug}
                      lineItems={lineItems}
                      depositLineItems={[]}
                      setNewOrder={setOrderToPrint}
                    />
                  ) : (
                    <StripeCardForm
                      ctaText={`Charge ${formatMoneyFromCents(order?.unpaid_total_in_cents)}`}
                      setPaymentIntentId={() => {}}
                      paymentIntentId={null}
                      onSuccess={() => setIsSuccess(true)}
                      order={order}
                      isCamperPresent={!!selectedCamper}
                      campingStyleId={campingStyleId}
                      isTaxFeeEnabled={isEnabledTaxFee}
                      isCreditCardFeeEnabled={isEnabledCreditCardFee}
                      discountSlug={discountFromLineItems(lineItems)?.slug}
                      lineItems={lineItems}
                      depositLineItems={[]}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      paymentMethods={paymentMethods}
                      errorMessage={errorMessage}
                      setErrorMessage={setErrorMessage}
                      setPaymentStatus={setPaymentStatus}
                      setIntervalId={setIntervalId}
                      setShowCloseButton={setShowCloseButton}
                      setNewOrder={setOrderToPrint}
                    />
                  )}
                </div>
                <div className='w-full mt-3 border-t border-gray-200 pt-3 mb-3'>
                  <OrderSummary order={order} camp={camp} isPreview />
                </div>
                <div className='w-full mt-3 border-t border-gray-200 hidden'>
                  <div className='m-8' ref={receiptPrintRef}>
                    <OrderSummary order={orderToPrint} camp={camp} isPreview isIncludingLineItems={true} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StoreContent
