import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import React from 'react'
import classNames from '../../utils/classNames'

interface ComponentProps {
  description: string | JSX.Element | JSX.Element[]
  className?: string
  kind?: "error" | "success"
}

const Alert = (props: ComponentProps) => {
  let colorClassName = "red"
  let icon = <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />

  if (props.kind === "success") {
    colorClassName = "green"
    icon = <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
  }

  return (
    <div className={classNames(`border-l-4 border-${colorClassName}-400 bg-${colorClassName}-50 p-4`, props.className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          {icon}
        </div>
        <div className="ml-3">
          <p className={`text-sm text-${colorClassName}-700`}>
            {props.description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Alert
