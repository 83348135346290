import { DocumentPlusIcon, XCircleIcon } from '@heroicons/react/24/solid'
import React, { useContext, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import api from '../../../api2'
import { useDebouncedCallback } from 'use-debounce'
import Spinner from '../../ui/Spinner'
import { AdminContext } from '../../../contexts/AdminContext'
import { uniqueId } from 'lodash'
import ICamper from '../../../interfaces/ICamper'

const fileTypes = ['JPG', 'JPEG', 'PNG', 'PDF']

interface INewDoc{
  file: File;
  url: string;
  key: string;
  isLoading: boolean;
}

function CamperDocumentUpload({ camper }: { camper: ICamper }) {
  const { showNotification, selectedCamper } = useContext(AdminContext)
  const [docStates, setDocStates] = useState<INewDoc[]>([])

  useEffect(() => {
    if(selectedCamper?.id){
      fetchDocuments()
    }
  }, [])

  const fetchDocuments = useDebouncedCallback(() => {
    if (!camper) return

    api.Campers.fetchDocuments(camper.id)
      .then((response) => {
        setDocStates(response.documents.map((document) => ({
          file: null,
          url: document.url,
          key: document.key,
          isLoading: true // assume they still need to load in
        })))
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Failed to fetch documents',
          description: err.message,
        })
      })
  }, 50)

  const handleAdd = async (files: FileList) => {
    const fileList = Array.from(files)
    const newDocs = fileList.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Temporary URL for preview
      key: `temp-key-${uniqueId()}`,
      isLoading: true
    }))

    setDocStates(docStates.concat(newDocs))

    if(camper?.id){
      newDocs.forEach((docState) => {
        api.Campers.uploadDocument(camper.id, docState.file)
          .then((response) => {
            setDocStates((currentStates) =>
              currentStates.map((img) =>
                img.key === docState.key ? { ...img, url: response.document.url, key: response.document.key, isLoading: false } : img
              )
            )
          })
          .catch((err) => {
            console.error(err)
            showNotification({
              type: 'error',
              title: `Failed to upload document ${docState.file.name}`,
              description: err.message,
            })
          })
      })
    }
  }

  const handleRemove = (key: string) => {
    if(camper?.id){
      setDocStates((currentStates) =>
        currentStates.map((img) => (img.key === key ? { ...img, isLoading: true } : img))
      )

      api.Campers.removeDocument(camper.id, key)
      .then(() => {
        setDocStates((currentStates) => currentStates.filter((img) => img.key !== key))
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Failed to remove document',
          description: err.message,
        })
        setDocStates((currentStates) =>
          currentStates.map((img) => (img.key === key ? { ...img, isLoading: false } : img))
        )
      })
    }else{
      setDocStates(docStates.filter(img => img.key !== key))
    }
  }

  const updateDocumentLoadState = (key: string, isLoading: boolean) => {
    setDocStates((currentStates) =>
      currentStates.map((img) =>
        img.key === key ? { ...img, isLoading } : img
      )
    )
  }

  if (!camper) return <></>

  return (
    <>
      <label className='block text-md text-gray-900 mb-1'>Documents</label>
      <div className='gap-2 mb-2 mt-1 flex overflow-x-auto'>
        {docStates?.map(({ url, key, isLoading }) =>
          <UploadedDocument
            src={url}
            handleRemove={() => handleRemove(key)}
            isLoading={isLoading}
            onLoaded={() => updateDocumentLoadState(key, false)}
            onError={() => updateDocumentLoadState(key, false)}
          />
        )}
      </div>
      
      <FileUploader
        handleChange={handleAdd}
        name='file'
        types={fileTypes}
        multiple
        children={
          <div className='border-2 border-dashed border-gray-300 p-8 h-24 flex items-center rounded-md cursor-pointer hover:bg-gray-75'>
            <DocumentPlusIcon className='w-8 h-8 text-gray-500 mr-4' />
            <div className='flex flex-col justify-between w-full'>
              <span className='text-gray-600 text-md'>Click or drag a document here to upload</span>
              <span className='text-gray-500 text-md'>{fileTypes.join(', ')}</span>
            </div>
          </div>
        }
      />
    </>
  )
}

const UploadedDocument = ({ src, handleRemove, isLoading, onLoaded, onError }) => {
  const [isPdf, setIsPdf] = useState(false)

  useEffect(() => {
    try {
      const url = new URL(src)
      const isPdfFile = url.pathname.toLowerCase().endsWith('.pdf')
      setIsPdf(isPdfFile)
      onLoaded()
    } catch (error) {
      console.error("Error parsing URL", error)
      setIsPdf(false)
    }
  }, [src])

  return (
    <div className='block flex-none bg-white w-24 h-24 relative overflow-hidden rounded-lg'>
      {isLoading && (
        <div className='absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center'>
          <Spinner size={8} className='w-8 h-8 text-white' />
        </div>
      )}
      <button type='button' className='z-10 w-8 h-8 absolute top-0 right-0' onClick={handleRemove}>
        <XCircleIcon className='text-white hover:text-gray-300 cursor-pointer' />
      </button>
      {isPdf ? (
        <div
          className='absolute w-full h-full flex justify-center items-center bg-gray-200 cursor-pointer hover:opacity-90'
          onClick={() => window.open(src, '_blank')}
        >
          PDF
        </div>
      ) : (
        <img
          src={src}
          className='absolute w-full h-full object-cover object-center hover:opacity-90 cursor-pointer'
          alt="Uploaded content"
          onLoad={onLoaded}
          onError={onError}
          onClick={() => window.open(src, '_blank')}
        />
      )}
    </div>
  )
}

export default CamperDocumentUpload
