import React from 'react'
import IOrder from '../../../interfaces/IOrder'
import { CampProps } from '../../../interfaces'
import classNames from '../../../utils/classNames'
import { formatMoneyFromCents } from '../../../utils/formatMoney'
import IOrderPreview from '../../../interfaces/IOrderPreview'
import { LINE_ITEM_KINDS } from '../../../constants/line_item_kinds'
import parseDateTime from '../../../utils/parseDateTime'

const OrderSummary = ({
  order,
  camp,
  isLoading,
  isIncludingLineItems,
  isPreview,
  isHidingServiceFee,
  isInvoice,
  noBackground,
}: {
  order: IOrder | IOrderPreview
  camp: CampProps
  isLoading?: boolean
  isIncludingLineItems?: boolean
  isPreview?: boolean
  isHidingServiceFee?: boolean
  isInvoice?: boolean
  noBackground?: boolean
}) => {
  const backgroundColor = noBackground ? 'bg-transparent' : 'bg-gray-50'

  const getAmountDueValue = () => {
    if (isInvoice) {
      return 'unpaid_total_without_service_fee_in_cents' in order && order?.unpaid_total_without_service_fee_in_cents
        ? order?.unpaid_total_without_service_fee_in_cents
        : order?.unpaid_total_in_cents
    } else if (isPreview) {
      return isHidingServiceFee ? (order as IOrderPreview)?.unpaid_total_without_service_fee_in_cents : order?.unpaid_total_in_cents
    } else {
      return null
    }
  }

  const amountDueValue = getAmountDueValue()
  const amountDueCurrency = isPreview ? camp.currency : undefined

  if (isLoading) {
    return (
      <div className={classNames(backgroundColor, 'p-4 rounded-md whitespace-nowrap')}>
        <div className='w-full animate-pulsing flex space-x-4'>
          <div className='w-full flex-1 space-y-4 py-1'>
            <div className='space-y-2'>
              <div className='mb-2 w-full grid grid-cols-6 gap-4'>
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-3' />
                <div className='col-span-2' />
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
              </div>
              <div className='mb-2 w-full grid grid-cols-6 gap-4'>
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
                <div className='col-span-4' />
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
              </div>
              <div className='mb-2 w-full grid grid-cols-6 gap-4'>
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-2' />
                <div className='col-span-3' />
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
              </div>
            </div>
            <div className='mt-3 mb-6 w-full h-0.25 bg-gray-50' />
            <div className='space-y-2'>
              <div className='mb-2 w-full grid grid-cols-6 gap-4'>
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
                <div className='col-span-4' />
                <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
              </div>
            </div>
            <div className='mt-3 mb-6 w-full h-0.25 bg-gray-50' />
            <div className='w-full grid grid-cols-6 gap-4'>
              <div className='h-3 w-full rounded-md bg-gray-200 col-span-3' />
              <div className='col-span-2' />
              <div className='h-3 w-full rounded-md bg-gray-200 col-span-1' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(backgroundColor, 'p-4 rounded-md whitespace-nowrap')}>
      {isIncludingLineItems && <LineItems order={order} camp={camp} />}
      <div>
        <OrderRow
          label={'Subtotal'}
          value={formatMoneyFromCents(order?.subtotal_in_cents, camp.currency)}
          className={`${!isIncludingLineItems && 'mt-0'} ${!order?.discounted_subtotal_in_cents && !order?.paid_subtotal_in_cents && 'font-bold'}`}
        />

        <DiscountsAndPaidAmounts order={order} camp={camp} />

        <TaxesAndFees
          order={order}
          isPreview={isPreview}
          isInvoice={isInvoice}
          camp={camp}
          isHidingServiceFee={isHidingServiceFee}
        />
      </div>
      <Divider />
      <OrderRow
        label={'Amount due'}
        value={formatMoneyFromCents(amountDueValue, amountDueCurrency)}
        isVisible={isPreview || isInvoice}
        className="font-bold"
      />
      {isInvoice && 'unpaid_total_without_service_fee_in_cents' in order && (
        <PayingOnline order={order} camp={camp} />
      )}
      {(!isPreview && !isInvoice) && (
        <OrderRow
          label={'Total owed'}
          className='font-bold'
          value={formatMoneyFromCents(order?.total_in_cents, camp.currency)}
        />
      )}
    </div>
  )
}

const PayingOnline = ({ order, camp }: { order: IOrder; camp: CampProps }) => (
  <>
    {order?.service_fee_in_cents > 0 && !camp.is_absorbing_service_fee && order?.unpaid_total_in_cents > 0 ? (
      <div className='mt-8'>
        <div className='mt-4 pb-1 italic text-sm border-b border-color-400'>When paying online</div>
        <ServiceFees order={order} camp={camp} />
        <OrderRow
          label={'Amount due'}
          className="font-bold"
          value={formatMoneyFromCents(order?.unpaid_total_in_cents, camp.currency)}
          isVisible={true}
        />
      </div>
    ) : null}
  </>
)

const DiscountsAndPaidAmounts = ({ order, camp }: { order: IOrder; camp: CampProps }) => {
  return (
    <>
      {order?.discount_in_cents > 0 && (
        <OrderRow label={`Discount (${order?.discount_name})`} value={'-' + formatMoneyFromCents(order?.discount_in_cents, camp.currency)} />
      )}
      {order?.discounted_subtotal_in_cents > 0 && (
        <OrderRow
          label={'Discounted subtotal'}
          value={formatMoneyFromCents(order?.discounted_subtotal_in_cents, camp.currency)}
          className={!order?.paid_total_in_cents && 'font-bold'}
        />
      )}
      {order?.paid_surcharges_in_cents > 0 && (
        <OrderRow
          label={'Paid fees'}
          value={formatMoneyFromCents(order.paid_surcharges_in_cents, camp.currency)}
        />
      )}
      {order?.paid_total_in_cents > 0 && (
        <OrderRow
          label={order?.paid_at ? `Paid on ${parseDateTime(order?.paid_at).toFormat("MMM d, yyyy")}` : "Paid amount"}
          value={`- ${formatMoneyFromCents(order?.paid_total_in_cents, camp.currency)}`}
        />
      )}
      {order?.paid_subtotal_in_cents > 0 && (
        <OrderRow
          label={'Remaining subtotal'}
          className='font-bold'
          value={formatMoneyFromCents(order?.unpaid_subtotal_in_cents, camp.currency)}
        />
      )}
    </>
  )
}

const TaxesAndFees = ({ order, isInvoice, camp, isHidingServiceFee, isPreview }: { order: IOrder; isInvoice: boolean; camp: CampProps; isHidingServiceFee: boolean; isPreview: boolean }) => {
  const hasAnyFees = order?.tax_fee_in_cents > 0 || order?.credit_card_fee_in_cents > 0 || (order?.service_fee_in_cents > 0 && !isInvoice && !isHidingServiceFee)

  if (isPreview) {
    return (
      <>
        {hasAnyFees && <Divider />}
        {order?.tax_fee_in_cents > 0 && (
          <>
            <OrderRow label={'Tax'} value={formatMoneyFromCents(order?.tax_fee_in_cents, camp.currency)} />
          </>
        )}
        {order?.credit_card_fee_in_cents > 0 && (
          <OrderRow
            label={'Credit card fee'}
            value={formatMoneyFromCents(order?.credit_card_fee_in_cents, camp.currency)}
          />
        )}
        {order?.service_fee_in_cents > 0 && !isInvoice && !isHidingServiceFee && (
          <ServiceFees className={order?.is_absorbing_service_fee && 'text-gray-400'} order={order} camp={camp} />
        )}
      </>
    )
  } else if (order?.tax_fee_in_cents > 0) {
    return (
      <>
        <Divider />
        <OrderRow label={`Tax`} value={formatMoneyFromCents(order?.tax_fee_in_cents, camp.currency)} />
      </>
    )
  } else {
    return (
      <></>
    )
  }
}

const ServiceFees = ({ order, camp, className }: { order: IOrder; camp: CampProps; className?: string }) => {
  return (
    <OrderRow
      className={className}
      label={<div>Service fee {order?.is_absorbing_service_fee && <span className='text-xs ml-1'>(Not included in total)</span>}</div>}
      value={formatMoneyFromCents(order?.service_fee_in_cents, camp.currency)}
    />
  )
}

const OrderRow = ({
  label,
  value,
  isVisible,
  className,
  labelClasses,
}: {
  label: string | React.ReactNode
  value: string
  isVisible?: boolean
  className?: string
  labelClasses?: string
}) => {
  if (isVisible !== undefined && !isVisible) return <></>

  return (
    <div className={classNames('py-1 flex justify-between w-full', className)}>
      <div className={labelClasses}>{label}</div>
      <div>{value}</div>
    </div>
  )
}

const LineItems = ({ order, camp }: { order: IOrder; camp: CampProps }) => {
  return (
    <div>
      {order?.line_items?.map((lineItem) => (
        <div className='mb-3 flex justify-between items-center w-full'>
          <div className='flex flex-col'>
            <label htmlFor='name' className='flex items-center text-base text-gray-900'>
              <span>{lineItem.label}</span>
              {(lineItem.quantity > 1 || lineItem.kind === LINE_ITEM_KINDS.ELECTRIC) && (
                <span className='ml-1'>x {formatMoneyFromCents(lineItem.unit_amount_in_cents, camp.currency)}</span>
              )}
              <span className='ml-1'>
                x {lineItem.quantity}
                {lineItem.kind === LINE_ITEM_KINDS.ELECTRIC ? ' kWh' : ''}
              </span>
              {Number(lineItem.occurrences) > 1 && (
                <span className='ml-1'>
                  x {lineItem.occurrences} {lineItem.occurrence_unit}
                </span>
              )}
            </label>
          </div>
          <span className='text-gray-900'>
            <span>{formatMoneyFromCents(lineItem.total_amount_in_cents, camp.currency)}</span>
          </span>
        </div>
      ))}
      <Divider />
    </div>
  )
}

const Divider = () => {
  return <div className='my-2 w-full h-0.25 bg-gray-300' />
}

export default OrderSummary
