import { createContext } from 'react'

interface ICtxPayload {
  onClose: () => void
}

const initialContext = {
  onClose: () => {},
}

export const ModalContext = createContext<ICtxPayload>(initialContext)
