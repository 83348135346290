import { CAMPING_STYLES, ELECTRICS, KINDS } from "../constants"
import { SiteProps } from '../interfaces'

import img_electric_15_30a_split from 'images/park__icon_ux__electric_15_30a_split.svg'
import img_electric_20_30a_split from 'images/park__icon_ux__electric_20_30a_split.svg'
import img_electric_30_50a_split from 'images/park__icon_ux__electric_30_50a_split.svg'
import img_electric_15_30_50a_split from 'images/park__icon_ux__electric_15_30_50a_split.svg'
import img_electric_20_30_50a_split from 'images/park__icon_ux__electric_20_30_50a_split.svg'
import img_electric_15a from 'images/park__icon_ux__electric_15a.svg'
import img_electric_20a from 'images/park__icon_ux__electric_20a.svg'
import img_electric_30a from 'images/park__icon_ux__electric_30a.svg'
import img_electric_50a from 'images/park__icon_ux__electric_50a.svg'
import img_electric_basic from 'images/park__icon_ux__electric.svg'
import img_electric_none from 'images/park__icon_ux__electric_none.svg'

import img_kind_back_in from 'images/park__icon_ux__kind_back_in.svg'
import img_kind_pull_through from 'images/park__icon_ux__kind_pull_through.svg'
import img_kind_hybrid from 'images/park__icon_ux__kind_hybrid.svg'
import img_kind_tent from 'images/park__icon_ux__tent.svg'
import img_kind_cabin from 'images/park__icon_ux__cabin.svg'
import img_kind_docks from 'images/park__icon_ux__docks.svg'
import img_kind_boat_rental from 'images/park__icon_ux__boat.svg'
import img_kind_mobile_home from 'images/park__icon_ux__mobile_home.svg'
import img_kind_storage from 'images/park__icon_ux__storage.svg'
import img_kind_pavilion from 'images/park__icon_ux__pavilion.svg'
import img_kind_cabana from 'images/park__icon_ux__cabana.svg'
import img_kind_garage from 'images/park__icon_ux__garage.svg'
import img_kind_horse_pen from 'images/park__icon_ux__horse_pen.svg'
import img_kind_horse_stall from 'images/park__icon_ux__horse_pen.svg'
import img_kind_shelter from 'images/park__icon_ux__shelter.svg'
import img_kind_rv_storage from 'images/park__icon_ux__rv_storage.svg'
import img_kind_event_center from 'images/park__icon_ux__event_center.svg'
import img_kind_rental_rv from 'images/park__icon_ux__rental_rv.svg'
import img_kind_lodge from 'images/park__icon_ux__lodge.svg'
import img_kind_golf_cart from 'images/park__icon_ux__golf_cart.svg'
import img_kind_pool from 'images/park__icon_ux__pool.svg'
import img_vendor_booth from 'images/park__icon_ux__vendor_booth.svg'
import img_disc_golf from 'images/park__icon_ux__disc_golf.svg'
import img_dry_camping from 'images/park__icon_ux__cs__dry_camping.svg'
import img_fire_pic from 'images/park__icon_ux__fire_pit.svg'
import img_dome from 'images/park__icon_ux__cs__dome.svg'
import img_glamping from 'images/park__icon_ux__cs__glamping.svg'
import img_home from 'images/park__icon_ux__cs__home.svg'
import img_lodging from 'images/park__icon_ux__cs__home.svg'
import img_food_truck from 'images/park__icon_ux__food_truck.svg'
import img_misc from 'images/park__icon_ux__cube.svg'
import img_ensuite_unit from 'images/park__icon_ux__lodge.svg'
import img_unpowered_site from 'images/park__icon_ux__rental_rv.svg'
import img_main_house from 'images/park__icon_ux__cs__main_house.svg'
import img_cottage from 'images/park__icon_ux__cs__cottage.svg'
import img_platform from 'images/park__icon_ux__cs__platform.svg'
import img_canvas_tent from 'images/park__icon_ux__cs__canvas_tent.svg'

import img_linens from 'images/park__icon_ux__linens.svg'
import img_no_linens from 'images/park__icon_ux__no_linens.svg'
import img_bed from 'images/park__icon_ux__bed-v0.svg'
import img_cable_tv from 'images/park__icon_ux__cable_tv.svg'
import img_extra_car_space from 'images/park__icon_ux__car_space.svg'
import img_dog_park from 'images/park__icon_ux__dog_park.svg'
import img_dump_station from 'images/park__icon_ux__dump_station.svg'
import img_fire_pit from 'images/park__icon_ux__fire_pit.svg'
import img_no_fire_pit from 'images/park__icon_ux__no_fire_pit.svg'
import img_general_store from 'images/park__icon_ux__general_store.svg'
import img_laundry from 'images/park__icon_ux__laundry.svg'
import img_max_rig_length from 'images/park__icon_ux__max_rig_length.svg'
import img_pad_type from 'images/park__icon_ux__pad_type.svg'
import img_pets from 'images/park__icon_ux__pets.svg'
import img_picnic_table from 'images/park__icon_ux__picnic_table.svg'
import img_no_picnic_table from 'images/park__icon_ux__no_picnic_table.svg'
import img_public_bathrooms from 'images/park__icon_ux__public_bathrooms.svg'
import img_public_kitchen from 'images/park__icon_ux__public_kitchen.svg'
import img_sewage from 'images/park__icon_ux__sewage.svg'
import img_showers from 'images/park__icon_ux__showers.svg'
import img_pool from 'images/park__icon_ux__swimming_pool.svg'
import img_water from 'images/park__icon_ux__water.svg'
import img_no_water from 'images/park__icon_ux__no_water.svg'
import img_wifi from 'images/park__icon_ux__wifi.svg'
import img_premium_view from 'images/park__icon_ux__premium_view.svg'
import img_heating from 'images/park__icon_ux__heating.svg'
import img_microwave from 'images/park__icon_ux__microwave.svg'
import img_oven from 'images/park__icon_ux__oven.svg'
import img_refrigerator from 'images/park__icon_ux__refrigerator.svg'
import img_stove from 'images/park__icon_ux__stove.svg'
import img_air_conditioning from 'images/park__icon_ux__air_conditioning.svg'
import img_private_bathroom from 'images/park__icon_ux__private_bathroom.svg'
import img_futon_bed from 'images/park__icon_ux__bed_futon.svg'
import img_king_bed from 'images/park__icon_ux__bed_king.svg'
import img_queen_bed from 'images/park__icon_ux__bed_queen.svg'
import img_full_bed from 'images/park__icon_ux__bed_twin.svg'
import img_twin_bed from 'images/park__icon_ux__bed_twin.svg'
import img_single_bed from 'images/park__icon_ux__bed_single.svg'
import img_bunk_bed from 'images/park__icon_ux__bed_bunk.svg'
import img_grill_barbecue from 'images/park__icon_ux__grill_barbecue.svg'
import img_restaurant from 'images/park__icon_ux__restaurant.svg'
import img_basketball_court from 'images/park__icon_ux__basketball_court.svg'
import img_golf_course from 'images/park__icon_ux__golf_course.svg'
import img_mini_golf_course from 'images/park__icon_ux__mini_golf_course.svg'
import img_playground from 'images/park__icon_ux__playground.svg'
import img_propane_fill_up from 'images/park__icon_ux__propane_fill_up.svg'
import img_volleyball_court from 'images/park__icon_ux__volleyball_court.svg'
import img_tv from 'images/park__icon_ux__tv.svg'
import img_kayak from 'images/park__icon_ux__kayak.svg'
import img_tipi from 'images/park__icon_ux__tipi.svg'
import img_towels from 'images/park__icon_ux__towel.svg'
import img_no_towels from 'images/park__icon_ux__no_towel.svg'
import img_hotplate from 'images/park__icon_ux__hotplate.svg'
import img_van from 'images/park__icon_ux__van.svg'
import img_room from 'images/park__icon_ux__room.svg'
import img_rental_item from 'images/park__icon_ux__rental_item.svg'
import img_generator from 'images/park__icon_ux__generator.svg'
import img_picnic_area from 'images/park__icon_ux__picnic_table.svg'

import img_lock_fee from 'images/park__icon_ux__lock_fee.svg'
import img_firewood from 'images/park__icon_ux__firewood.svg'
import img_ice from 'images/park__icon_ux__ice.svg'
import img_propane from 'images/park__icon_ux__propane.svg'
import img_pet from 'images/park__icon_ux__pet.svg'
import img_group from 'images/park__icon_ux__group.svg'
import img_truck from 'images/park__icon_ux__pickup_truck.svg'
import { BookerContext } from "../contexts/BookerContext"
import { useContext } from "react"

const iconFor = (siteFeature: (keyof SiteProps) | 'kind_back_in' | 'kind_pull_through' | 'add-on', value?: any) => {
  switch (siteFeature) {
    case 'electric':
      return electricIconFor(value)
    case 'kind':
      return kindIconFor(value)
    case 'add-on':
      return addOnIconFor(value)
    case 'camper_capacity':
      return img_bed
    case 'cable_tv':
      return img_cable_tv
    case 'extra_car_space':
      return img_extra_car_space
    case 'dog_park':
      return img_dog_park
    case 'dump_station':
      return img_dump_station
    case 'fire_pit':
      return value ? img_fire_pit : img_no_fire_pit
    case 'general_store':
      return img_general_store
    case 'kind_back_in':
      return img_kind_back_in
    case 'kind_pull_through':
      return img_kind_pull_through
    case 'has_laundry':
      return img_laundry
    case 'max_rig_length':
      return img_max_rig_length
    case 'pets':
      return img_pets
    case 'picnic_table':
      return value ? img_picnic_table : img_no_picnic_table
    case 'public_bathrooms':
      return img_public_bathrooms
    case 'public_kitchen':
      return img_public_kitchen
    case 'sewage':
      return img_sewage
    case 'showers':
      return img_showers
    case 'pad_type':
      return img_pad_type
    case 'pool':
      return img_pool
    case 'water':
      return value ? img_water : img_no_water
    case 'has_linens':
      return value ? img_linens : img_no_linens
    case 'has_wifi':
      return value ? img_wifi : null
    case 'premium_view':
      return img_premium_view
    case 'has_heating':
      return img_heating
    case 'has_microwave':
      return img_microwave
    case 'has_oven':
      return img_oven
    case 'has_refrigerator':
      return img_refrigerator
    case 'has_stove':
      return img_stove
    case 'has_air_conditioning':
      return img_air_conditioning
    case 'has_private_bathroom':
      return img_private_bathroom
    case 'number_of_futon_beds':
      return img_futon_bed
    case 'number_of_king_beds':
      return img_king_bed
    case 'number_of_queen_beds':
      return img_queen_bed
    case 'number_of_full_beds':
      return img_full_bed
    case 'number_of_twin_beds':
      return img_twin_bed
    case 'number_of_single_beds':
      return img_single_bed
    case 'number_of_bunk_beds':
      return img_bunk_bed
    case 'has_basketball_court':
      return img_basketball_court
    case 'has_community_fire_pit':
      return img_fire_pit
    case 'has_golf_course':
      return img_golf_course
    case 'has_grill_barbecue':
      return img_grill_barbecue
    case 'has_mini_golf_course':
      return img_mini_golf_course
    case 'has_playground':
      return img_playground
    case 'has_propane_fill_up':
      return img_propane_fill_up
    case 'has_restaurant':
      return img_restaurant
    case 'has_tv':
      return img_tv
    case 'has_volleyball_court':
      return img_volleyball_court
    case 'disc_golf_course':
      return img_disc_golf
    case 'has_hotplate':
      return img_hotplate
    case 'has_towels':
      return value ? img_towels : img_no_towels
    default:
      return null
  }
}

const electricIconFor = (value: string) => {
  switch(value) {
    case null:
    case undefined:
    case ELECTRICS.NONE:
      return img_electric_none
    case ELECTRICS.BASIC:
      return img_electric_basic
    case ELECTRICS.E15:
      return img_electric_15a
    case ELECTRICS.E20:
      return img_electric_20a
    case ELECTRICS.E30:
      return img_electric_30a
    case ELECTRICS.E50:
      return img_electric_50a
    case ELECTRICS.SPLIT_15_30:
      return img_electric_15_30a_split
    case ELECTRICS.SPLIT_20_30:
      return img_electric_20_30a_split
    case ELECTRICS.SPLIT_30_50:
      return img_electric_30_50a_split
    case ELECTRICS.SPLIT_15_30_50:
      return img_electric_15_30_50a_split
    case ELECTRICS.SPLIT_20_30_50:
      return img_electric_20_30_50a_split
    default:
      return null
  }
}

const kindIconFor = (value: string) => {
   const {
     campingStyle
   } = useContext(BookerContext)

  if (campingStyle?.name == CAMPING_STYLES.TENT) return img_kind_tent  

  switch (value) {
    case KINDS.RV_BACKIN:
      return img_kind_back_in
    case KINDS.RV_PULLTHROUGH:
      return img_kind_pull_through
    case KINDS.RV_HYBRID:
      return img_kind_hybrid
    case KINDS.TENT:
      return img_kind_tent
    case KINDS.CABIN:
      return img_kind_cabin
    case KINDS.BOAT_SLIP:
      return img_kind_docks
    case KINDS.BOAT_RENTAL:
      return img_kind_boat_rental
    case KINDS.MOBILE_HOME:
      return img_kind_mobile_home
    case KINDS.STORAGE:
      return img_kind_storage
    case KINDS.PAVILION:
      return img_kind_pavilion
    case KINDS.CABANA:
      return img_kind_cabana
    case KINDS.EVENT_CENTER:
      return img_kind_event_center
    case KINDS.GARAGE:
      return img_kind_garage
    case KINDS.HORSE_PEN:
      return img_kind_horse_pen
    case KINDS.HORSE_STALL:
      return img_kind_horse_stall
    case KINDS.SHELTER:
      return img_kind_shelter
    case KINDS.RV_STORAGE:
      return img_kind_rv_storage
    case KINDS.RENTAL_RV:
      return img_kind_rental_rv
    case KINDS.LODGE:
      return img_kind_lodge
    case KINDS.GOLF_CART:
      return img_kind_golf_cart
    case KINDS.POOL:
      return img_kind_pool
    case KINDS.VENDOR_BOOTH:
      return img_vendor_booth
    case KINDS.DISC_GOLF:
      return img_disc_golf
    case KINDS.DRY_CAMPING:
      return img_dry_camping
    case KINDS.DOME:
      return img_dome
    case KINDS.GLAMPING:
      return img_glamping
    case KINDS.HOME:
      return img_home
    case KINDS.LODGING:
      return img_lodging
    case KINDS.BONFIRE:
      return img_fire_pic
    case KINDS.GROUP:
      return img_group
    case KINDS.TRUCK:
      return img_truck
    case KINDS.COTTAGE:
      return img_cottage
    case KINDS.MAIN_HOUSE:
      return img_main_house
    case KINDS.FOOD_TRUCK:
      return img_food_truck
    case KINDS.MISC:
      return img_misc
    case KINDS.ENSUITE_UNIT:
      return img_ensuite_unit
    case KINDS.UNPOWERED_SITE:
      return img_unpowered_site
    case KINDS.TIPI:
      return img_tipi
    case KINDS.KAYAK:
      return img_kayak
    case KINDS.VAN:
      return img_van
    case KINDS.ROOM:
      return img_room
    case KINDS.RENTAL_ITEM:
      return img_rental_item
    case KINDS.GENERATOR:
      return img_generator
    case KINDS.PICNIC_AREA:
      return img_picnic_area
    case KINDS.KITCHEN:
      return img_public_kitchen
    case KINDS.PLATFORM:
      return img_platform
    case KINDS.CANVAS_TENT:
      return img_canvas_tent
    default:
      return null
  }
}

const addOnIconFor = (value: string) => {
  switch (value) {
    case 'lock-fee':
      return img_lock_fee
    case 'firewood':
      return img_firewood
    case 'ice':
      return img_ice
    case 'propane':
      return img_propane
    case 'pet':
      return img_pet
    default:
      return null
  }
}

export default iconFor
