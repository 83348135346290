import { SiteProps } from '../../interfaces'
import { base_url, check_ok_response, handle_error, request_options } from '../utils'

interface IFetchSitesOptions {
  rigType?: string
  rigLength?: number
  electric?: string
  campingStyleId?: string
  groupedReservationId?: string
  intervalId?: string
  numberOfAdults?: number
  numberOfKids?: number
}

export const fetchSites = async (
  campSlug: string,
  startDate: string,
  endDate: string,
  options: IFetchSitesOptions
): Promise<SiteProps[]> => {
  let url: string = base_url + `/camps/${campSlug}/sites?start_date=${startDate}&end_date=${endDate}`

  const {
    rigType,
    rigLength,
    electric,
    campingStyleId,
    groupedReservationId,
    intervalId,
    numberOfAdults,
    numberOfKids,
  } = options

  if (campingStyleId) url = url + `&camping_style_id=${campingStyleId}`
  if (rigType) url = url + `&rig_type=${rigType}`
  if (rigLength) url = url + `&rig_length=${rigLength}`
  if (electric) url = url + `&electric=${electric}`
  if (groupedReservationId) url = url + `&grouped_reservation_id=${groupedReservationId}`
  if (intervalId) url = url + `&interval_id=${intervalId}`
  if (numberOfAdults) url = url + `&number_of_adults=${numberOfAdults}`
  if (numberOfKids) url = url + `&number_of_kids=${numberOfKids}`

  return fetch(url, { ...request_options })
    .then(check_ok_response)
    .then((data: any) => {
      return data.sites
    })
    .catch(handle_error)
}

// NOTE: that availability will be `false` because no date data is fetched
export const fetchSite = async (siteId: string): Promise<SiteProps> => {
  const url: string = base_url + `/sites/${siteId}`

  return fetch(url, { ...request_options })
    .then(check_ok_response)
    .then((data: any) => {
      return data.site
    })
    .catch(handle_error)
}
