import { Switch } from '@headlessui/react'
import React from 'react'
import classNames from '../../../utils/classNames'

interface ComponentProps {
  checked: boolean
  onChange: (v: boolean) => void
  name?: string
  label?: string
  description?: string
  disabled?: boolean
  labelClassName?: string
}

const PreviewInvoiceToggle = ({
  checked,
  onChange,
  name,
  label,
  description,
  disabled,
  labelClassName,
}: ComponentProps) => {
  return (
    <Switch.Group as='div' className='flex items-center justify-between'>
      {label && (
        <span className='flex-grow flex flex-col mr-2 '>
          <Switch.Label as='span' className={`text-lg font-semibold text-gray-900 mr-4`} passive>
            {label}
          </Switch.Label>
          {description && (
            <Switch.Description as='span' className='text-sm text-gray-500'>
              {description}
            </Switch.Description>
          )}
        </span>
      )}
      <Switch
        disabled={disabled}
        name={name}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-200 hover:bg-gray-300',
          disabled && 'opacity-50 pointer-events-none',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700'
        )}>
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default PreviewInvoiceToggle
