import React, { CSSProperties, ElementType, Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce'
import { useFloating, offset, shift, flip, autoUpdate } from '@floating-ui/react-dom'
import { isMobile } from 'react-device-detect'
import classNames from '../../utils/classNames'

interface ComponentProps {
  children: JSX.Element | JSX.Element[]
  className?: string
  content: string | JSX.Element
  forceOpen?: boolean
  transitionDelay?: number
  placement?: 'bottom' | 'top' | 'right'
  onlyClick?: boolean
  hoverClasses?: string
  xs?: boolean
  width?: string
  wrap?: boolean
  popoverHover?: boolean
  delayOpen?: boolean
  delayClose?: boolean
  noPadding?: boolean
  active?: boolean
  style?: {}
  offset?: number
  contentClass?: string
  as?: ElementType<any>
  white?: boolean
  tipPosition?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed'
}

enum DeviceType {
  Desktop,
  Touch,
}

const HoverTip = (props: ComponentProps) => {
  const [open, setOpen] = useState(false)

  const { x, y, reference, floating, strategy, update, refs } = useFloating({
    placement: props.placement || 'bottom',
    middleware: [offset(props.offset === undefined ? 8 : props.offset), shift(), flip()],
  })

  const debouncedSetOpen = useDebouncedCallback((v: boolean) => {
    setOpen(v)
  }, props.transitionDelay || 100)

  const showTip = (device: DeviceType) => {
    if ((device === DeviceType.Touch) === isMobile) {
      props.delayOpen !== null && !props.delayOpen && setOpen(true)
      debouncedSetOpen(true)
    }
  }

  const hideTip = (device: DeviceType) => {
    if ((device === DeviceType.Touch) === isMobile) {
      !props.delayClose && setOpen(false)
      debouncedSetOpen(false)
    }
  }

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return
    }

    // Only call this when the floating element is rendered
    return autoUpdate(refs.reference.current, refs.floating.current, update)
  }, [refs.reference, refs.floating, update])

  if (props.active === false) {
    return props.as ? (
      <props.as className={props.className}>
        {props.children}
      </props.as>
    ) : (
      <>{props.children}</>
    )
  }

  return (
    <Popover className='relative' style={props.style}>
      <Popover.Button
        as={props.as || 'div'}
        ref={reference}
        onMouseEnter={() => {
          !props.onlyClick && showTip(DeviceType.Desktop)
        }}
        onMouseLeave={() => !props.onlyClick && hideTip(DeviceType.Desktop)}
        onTouchStart={() => !props.onlyClick && showTip(DeviceType.Touch)}
        onTouchEnd={() => !props.onlyClick && hideTip(DeviceType.Touch)}
        onTouchCancel={() => !props.onlyClick && hideTip(DeviceType.Touch)}>
        {props.children}
      </Popover.Button>

      <Popover.Panel
        static
        as='div'
        ref={floating}
        style={{
          position: props.tipPosition || strategy,
          top: props.tipPosition === 'fixed' && y ? y : undefined,
          left: props.tipPosition === 'fixed' && x ? x : undefined,
        }}
        onMouseEnter={() => {
          if (props.popoverHover) debouncedSetOpen(true)
        }}
        onMouseLeave={() => {
          if (props.popoverHover) debouncedSetOpen(false)
        }}
        onTouchStart={() => {
          if (props.popoverHover) setOpen(true)
        }}
        onTouchEnd={() => {
          if (props.popoverHover) setOpen(false)
        }}
        onTouchCancel={() => {
          if (props.popoverHover) setOpen(false)
        }}
        className={classNames(
          'z-50 text-center',
          !props.xs && '',
          props.xs && !props.wrap
            ? 'whitespace-nowrap max-w-xl'
            : props.width
            ? props.width
            : 'w-64',
          props.className
        )}>
        {(props.forceOpen || open) &&<Transition
          as={Fragment}
          show={props.forceOpen || open}
          enter='transition ease-out duration-75'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-75'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'>
          <div
            className={
              'overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ' +
              props.contentClass
            }>
            <div
              className={classNames(
                'relative grid gap-8 font-medium z-50',
                props.noPadding ? '' : props.xs ? 'px-3 py-2' : 'px-5 py-4',
                props.white ? 'text-black bg-white border border-gray-200' : 'text-white bg-black',
                props.xs ? 'text-xs' : 'text-sm',
                props.hoverClasses
              )}>
              {props.content}
            </div>
          </div>
        </Transition>}
      </Popover.Panel>
    </Popover>
  )
}

export default HoverTip
