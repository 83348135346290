import React from 'react'
import { STATUSES, reservationStatus } from '../../constants'

const ToDoStatusBadge = ({
  status,
  isCheckedIn,
  isCheckedOut,
  name,
  section,
  forPrint
}: {
  status: reservationStatus
  isCheckedIn: boolean
  isCheckedOut: boolean
  name: string
  section: string
  forPrint?: boolean
}): JSX.Element => {
  if (section == 'checkouts') {
    if (status == STATUSES.PAID && !isCheckedOut) {
      return (
        <div className='flex-shrink-0 flex items-center'>
          <span className='text-green-400 -mr-1 font-bold'>✓</span>
          <span className='text-gray-300 font-bold'>✓</span>
          <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
          <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-gray-200 text-gray-700'>
            Paid & not checked out
          </div>
        </div>
      )
    } else if (status == STATUSES.PAID && isCheckedOut) {
      return (
        <div className='flex-shrink-0 flex items-center'>
          <span className='text-green-400 -mr-1 font-bold'>✓</span>
          <span className='text-green-400 font-bold'>✓</span>
          <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
          <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-green-100 text-green-700'>
            Paid & checked out
          </div>
        </div>
      )
    } else if (status == STATUSES.UNPAID && !isCheckedOut) {
      return (
        <div className='flex-shrink-0 flex items-center'>
          <span className='text-gray-300 -mr-1 font-bold'>✓</span>
          <span className='text-gray-300 font-bold'>✓</span>
          <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
          <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-red-100 text-red-600'>
            Unpaid & not checked out
          </div>
        </div>
      )
    } else if (status == STATUSES.UNPAID && isCheckedOut) {
      return (
        <div className='flex-shrink-0 flex items-center'>
          <span className='text-gray-300 -mr-1 font-bold'>✓</span>
          <span className='text-green-400 font-bold'>✓</span>
          <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
          <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-red-100 text-red-600'>
            Unpaid & checked out
          </div>
        </div>
      )
    }
  } else if (status == STATUSES.UNPAID && !isCheckedIn) {
    return (
      <div className='flex-shrink-0 flex items-center'>
        <span className='text-gray-300 -mr-1 font-bold'>✓</span>
        <span className='text-gray-300 font-bold'>✓</span>
        <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
        <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-gray-200 text-gray-700'>
          Unpaid & not checked in
        </div>
      </div>
    )
  } else if (status == STATUSES.PAID && !isCheckedIn) {
    return (
      <div className='flex-shrink-0 flex items-center'>
        <span className='text-green-400 -mr-1 font-bold'>✓</span>
        <span className='text-gray-300 font-bold'>✓</span>
        <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
        <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-yellow-100 text-yellow-700'>
          Paid & not checked in
        </div>
      </div>
    )
  } else if (status == STATUSES.PAID && isCheckedIn) {
    return (
      <div className='flex-shrink-0 flex items-center'>
        <span className='text-green-400 -mr-1 font-bold'>✓</span>
        <span className='text-green-400 font-bold'>✓</span>
        <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
        <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-green-100 text-green-700'>
          Paid & checked in
        </div>
      </div>
    )
  } else if (status == STATUSES.UNPAID && isCheckedIn) {
    return (
      <div className='flex-shrink-0 flex items-center'>
        <span className='text-gray-300 -mr-1 font-bold'>✓</span>
        <span className='text-green-400 font-bold'>✓</span>
        <div className='ml-2 font-medium text-lg text-black truncate pr-4' style={{maxWidth: forPrint ? 200 : 'auto'}}>{name}</div>
        <div className='px-3 py-1 inline-flex flex-shrink-0 text-sm leading-5 rounded-full bg-red-100 text-red-600'>
          Unpaid & checked in
        </div>
      </div>
    )
  }

  return <></>
}

export default ToDoStatusBadge
