const ELECTRIC: 'electric' = 'electric'
const MAX_RIG_LENGTH: 'max_rig_length' = 'max_rig_length'
const PASSENGERS: 'passengers' = 'passengers'
const RIG_TYPE: 'rig_type' = 'rig_type'
const RIG_YEAR: 'rig_year' = 'rig_year'
const PETS: 'pets' = 'pets'

const PREREQUISITES = { ELECTRIC, MAX_RIG_LENGTH, PASSENGERS, RIG_TYPE, RIG_YEAR, PETS }

export { PREREQUISITES }
