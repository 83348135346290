import { Moment } from "moment"
import PassengersField from "./components/Sites/prerequisites/PassengersField"
import { discountType, reservationStatus, reservationKind } from "./constants"
import { SiteProps, ReservationProps, UserProps } from "./interfaces"
import { validElectric } from "./types"
import eSendingMode from "./enums/eSendingMode"
import ICamper from "./interfaces/ICamper"

type AnyObject = { [key: string]: any }

const baseUrl = window.origin
const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
const requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrf
  }
}

const checkOkResponse = (resp: Response) => {
  if (resp.ok) {
    return resp.json()
  } else {
    return Promise.reject(resp)
  }
}

const handleApiError = async (error: Response) => {
  if (typeof error.json === "function") {
    let result: any

    await error
      .json()
      .then(jsonError => {
        // console.log("[DEBUG] Json error from API")
        // console.log(jsonError)

        result = jsonError
      })
      .catch(genericError => {
        // console.log("[DEBUG] Generic error from API")
        // console.log(error.statusText)

        if (error.statusText.toLowerCase().includes('internal server error')) {
          result = 'Something unexpected went wrong. If this persists, please reach out to Park Support at support@poweredbypark.com'
        } else {
          result = error.statusText
        }

        result = { message: result }
      })

    return Promise.reject(result)
  } else {
    // console.log("[DEBUG] Fetch error")
    // console.log(error)

    return Promise.reject({ message: error })
  }
}

const fetchSites = async (campSlug: string, startDate: string, endDate: string, options): Promise<SiteProps[]> => {
  let url: string = baseUrl + `/camps/${campSlug}/sites?start_date=${startDate}&end_date=${endDate}`

  const { rigType, rigLength, electric } = options

  if (rigType) url = url + `&rig_type=${rigType}`
  if (rigLength) url = url + `&rig_length=${rigLength}`
  if (electric) url = url + `&electric=${electric}`

  return fetch(url, { ...requestOptions })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      return data.sites
    })
    .catch(handleApiError)
}

// NOTE: that availability will be `false` because no date data is fetched
const fetchSite = async (siteId: string): Promise<SiteProps> => {
  const url: string = baseUrl + `/sites/${siteId}`

  return fetch(url, { ...requestOptions })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      return data.site
    })
    .catch(handleApiError)
}

const stripePaymentIntent = async (
  siteId: string,
  startDate: string,
  endDate: string,
  userElectric?: validElectric,
  userPassengers?: number,
  userKidCampers?: number,
  discountType?: discountType,
  discountId?: string
): Promise<string> => {
  const url: string = baseUrl + `/payments/intent`

  const body: object = {
    site_id: siteId,
    start_date: startDate,
    end_date: endDate,
    user_electric: userElectric,
    user_passengers: userPassengers,
    user_kid_campers: userKidCampers,
    discount_type: discountType,
    discount_membership_id: discountId
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.clientSecret) {
        return data.clientSecret
      } else {
        console.log(`[ERROR] unexpected payment intent data: ${data}`)
        return Promise.reject(data)
      }
    })
    .catch(handleApiError)
}

interface FetchReservationsProps {
  from?: Moment
  until?: Moment
  except_status?: reservationStatus
  search?: string
  created_at?: {
    from?: Moment
    until?: Moment
  }
  kind?: reservationKind
  camp_id: string
  include_recurrences?: boolean
}

const fetchReservations = async (data: FetchReservationsProps): Promise<ReservationProps[]> => {
  // TODO: make /api/ endpoints, we shouldn't be clogging up our normal one
  let url: string = baseUrl + `/admin/reservations`

  let queryParams = []

  queryParams.push(`camp_id=${data.camp_id}`)

  if (data.from) queryParams.push(`from=${data.from.toISOString()}`)
  if (data.until) queryParams.push(`until=${data.until.toISOString()}`)

  if (data.search) queryParams.push(`search=${data.search}`)
  if (data.except_status) queryParams.push(`except_status=${data.except_status}`)

  if (data.created_at) {
    if (data.created_at.from) queryParams.push(encodeURIComponent('created_at[from]') + `=${data.created_at.from.toISOString()}`)
    if (data.created_at.until) queryParams.push(encodeURIComponent('created_at[until]') + `=${data.created_at.until.toISOString()}`)
  }

  if (data.kind) queryParams.push(`kind=${data.kind}`)
  
  if (data.include_recurrences) queryParams.push(`include_recurrences=${data.include_recurrences}`)

  url = url + '?' + queryParams.join('&')

  // TODO: add session cookie data
  return fetch(url, { ...requestOptions })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      return data.reservations
    })
    .catch(handleApiError)
}

interface SearchProps {
  search?: string
  camp_id: string

}

const fetchUsers = async (data: SearchProps): Promise<UserProps[]> => {
  let url: string = baseUrl + `/api/search?type=user&query=` + data.search

  return fetch(url, { ...requestOptions })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      return data.users
    })
    .catch(handleApiError)
}

const mockCreateReservation = async (
  siteId: string,
  startDate: string,
  endDate: string,
  passengers: number,
  kidCampers: number,
  userParams: UserProps,
  interval_id: string,
  groupedReservationId?: string,
  notes?: string,
  priceDetailParams?: object,
  addedSiteIds?: string[]
): Promise<ReservationProps> => {
  const url: string = baseUrl + `/sites/${siteId}/reservations`

  const body: object = {
    dry_run: true,
    start_date: startDate,
    end_date: endDate,
    passengers: passengers,
    kid_campers: kidCampers,
    notes: notes,
    email: userParams.email,
    first_name: userParams.first_name,
    last_name: userParams.last_name,
    phone: userParams.phone,
    address: userParams.address,
    // NOTE: license_plate does not have a vehicle_ prefix in the database
    license_plate: userParams.license_plate,
    vehicle_rig_type: userParams.vehicle_rig_type,
    vehicle_year: userParams.vehicle_year,
    vehicle_make: userParams.vehicle_make,
    vehicle_color: userParams.vehicle_color,
    grouped_reservation_id: groupedReservationId,
    interval_id: interval_id,
    price_details_params: priceDetailParams,
    added_site_ids: addedSiteIds
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.reservation) {
        return data.reservation
      } else {
        console.log(`[ERROR] unexpected reservation data: ${data}`)
        return Promise.reject(data)
      }
    })
    .catch(handleApiError)
}

const createReservation = async (
  paymentIntentId: string,
  siteId: string,
  selectedCampingStyleId: string,
  startDate: string,
  endDate: string,
  passengers: number,
  kidCampers: number,
  petsInfo: string | number,
  userParams: UserProps | ICamper,
  interval_id: string,
  groupedReservationId?: string,
  notes?: string,
  priceDetailParams?: object,
  addedSiteIds?: string[]
): Promise<ReservationProps> => {
  const url: string = baseUrl + `/sites/${siteId}/reservations`

  const body: object = {
    stripe_payment_intent_id: paymentIntentId,
    selected_camping_style_id: selectedCampingStyleId,
    start_date: startDate,
    end_date: endDate,
    passengers: passengers,
    kid_campers: kidCampers,
    pets_info: petsInfo ? String(petsInfo) : null,
    notes: notes,

    email: userParams.email,
    first_name: userParams.first_name,
    last_name: userParams.last_name,
    phone: userParams.phone,
    address: userParams.address,
    // NOTE: license_plate does not have a vehicle_ prefix in the database
    license_plate: userParams.license_plate,
    vehicle_rig_type: userParams.vehicle_rig_type,
    vehicle_rig_length: userParams.vehicle_rig_length,
    vehicle_electric: userParams.vehicle_electric,
    vehicle_year: userParams.vehicle_year,
    vehicle_make: userParams.vehicle_make,
    vehicle_color: userParams.vehicle_color,

    emergency_contact_name: (userParams as ICamper).emergency_contact_name,
    emergency_contact_relationship: (userParams as ICamper).emergency_contact_relationship,
    emergency_contact_email: (userParams as ICamper).emergency_contact_email,
    emergency_contact_phone: (userParams as ICamper).emergency_contact_phone,

    additional_vehicle_make: (userParams as ICamper).additional_vehicle_make,
    additional_vehicle_year: (userParams as ICamper).additional_vehicle_year,
    additional_vehicle_color: (userParams as ICamper).additional_vehicle_color,
    additional_vehicle_license_plate: (userParams as ICamper).additional_vehicle_license_plate,


    grouped_reservation_id: groupedReservationId,
    interval_id: interval_id,
    price_details_params: priceDetailParams,
    added_site_ids: addedSiteIds
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.reservation) {
        return data.reservation
      } else {
        console.log(`[ERROR] unexpected reservation data: ${data}`)
        return Promise.reject(data)
      }
    })
    .catch(handleApiError)
}

type UnpaidReservationProps = ReservationProps & Omit<UserProps, 'license_plate'> & {
  discount_name: string
  vehicle_license_plate: string
}

type RecurringReservationProps = ReservationProps & Omit<UserProps, 'license_plate'> & {
  discount_name: string
  vehicle_license_plate: string
  status: string
}

const createUnpaidReservation = async (siteId: string, startDate: string, endDate: string, discountName: string, passengers: number, userParams: UserProps) => {
  const url: string = baseUrl + `/sites/${siteId}/reservations`

  const body: UnpaidReservationProps = {
    start_date: startDate,
    end_date: endDate,
    discount_name: discountName,
    passengers: passengers,
    ...userParams,
    // NOTE: license_plate does not have a vehicle_ prefix in the database
    vehicle_license_plate: userParams.license_plate
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.reservation) {
        return data.reservation
      } else {
        console.log(`[DEBUG] unexpected reservation data: ${data}`)
        return Promise.reject(data)
      }
    })
    .catch(handleApiError)
}

const createBlockedReservation = async (siteId: string, startDate: string, endDate: string, status: reservationStatus): Promise<ReservationProps> => {
  const url: string = baseUrl + `/sites/${siteId}/reservations`

  const body: object = {
    start_date: startDate,
    end_date: endDate,
    status: status
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.reservation) {
        return data.reservation
      } else {
        console.log(`[ERROR] unexpected reservation data: ${data}`)
      }
    })
    .catch(handleApiError)
}

const updateReservation = async (
  reservationId: string,
  params: ReservationProps & {
    sending_mode?: eSendingMode
  }
): Promise<ReservationProps> => {
  const url: string = baseUrl + `/reservations/${reservationId}`

  // TODO: don't fetch if params is empty
  return fetch(url, {
    method: 'PUT',
    ...requestOptions,
    body: JSON.stringify(params)
  })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data.reservation) {
        return data.reservation
      } else {
        console.log(`[ERROR] unexpected reservation data: ${data}`)
      }
    })
    .catch(handleApiError)
}

const removeReservation = async (reservationId: string): Promise<AnyObject> => {
  const url: string = baseUrl + `/reservations/${reservationId}`

  return fetch(url, { method: 'DELETE', ...requestOptions })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      return data
    })
    .catch(handleApiError)
}

const adminSignup = async (
  campSlug: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  role?: string,
) => {
  const url: string = baseUrl + `/admin/signup/${campSlug}`

  const body: object = {
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
    role: role
  }

  return fetch(url, { method: 'POST', ...requestOptions, body: JSON.stringify(body) })
    .then(checkOkResponse)
    .then((data: AnyObject) => {
      if (data) {
        console.log(data)

        return data.admin_user
      } else {
        console.log(`[ERROR] unexpected session data: ${data}`)
      }
    })
    .catch(handleApiError)
}

const updateUser = async (id: string, params: UserProps) => {
  const url: string = baseUrl + `/users/${id}`

  return fetch(url, { method: 'PUT', ...requestOptions, body: JSON.stringify(params) })
    .then(checkOkResponse)
    .then((data) => data.user)
    .then((user: UserProps) => user)
    .catch(handleApiError)
}

const api = {
  stripePaymentIntent,
  fetchReservations,
  fetchUsers,
  mockCreateReservation,
  createReservation,
  createUnpaidReservation,
  createBlockedReservation,
  updateReservation,
  removeReservation,
  fetchSites,
  fetchSite,
  updateUser,
  adminSignup
}

export default api
