import React, { useContext, useEffect, useState } from 'react'
import { Card, Container, Divider, Segment, Transition } from 'semantic-ui-react'
import moment, { Moment } from 'moment'

import DatePicker from './DatePicker'
import SelectedDates from './SelectedDates'
import { CampingStyleProps, CampProps } from '../../interfaces'
import CampingStyleSelector from '../ui/CampingStyleSelector'
import { CAMPING_STYLES } from '../../constants'
import TimeSelector, { TimeSelectorOption } from '../ui/TimeSelector'
import { BookerContext } from '../../contexts/BookerContext'
import IBookingInterval from '../../interfaces/IBookingInterval'
import { DateTime } from 'luxon'
import _ from 'lodash'

enum HardCodedTiming {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  ALL_DAY = 'all-day'
}

const hardCodedTimeOptions: TimeSelectorOption[] = [
  {
    id: HardCodedTiming.MORNING,
    slug: HardCodedTiming.MORNING,
    label: "Morning"
  },
  {
    id: HardCodedTiming.AFTERNOON,
    slug: HardCodedTiming.AFTERNOON,
    label: "Afternoon"
  },
  {
    id: HardCodedTiming.ALL_DAY,
    slug: HardCodedTiming.ALL_DAY,
    label: "All day"
  }
]

const hasHourlyOrDailyIntervals = (campingStyle: CampingStyleProps) => {
  return campingStyle
    ?.booking_intervals
    ?.some((interval) => {
      if (interval.start_time && interval.end_time) {
        const startTime = DateTime.fromFormat(interval.start_time, 'HH:mm')
        const endTime = DateTime.fromFormat(interval.end_time, 'HH:mm')
        return endTime.diff(startTime).hours < 18 && endTime.diff(startTime).hours > 0
      } else {
        if (interval.label === HardCodedTiming.MORNING || interval.label === HardCodedTiming.AFTERNOON || interval.label === HardCodedTiming.ALL_DAY) {
          return true
        }
      }

      return false
    })
}

interface ComponentProps {
  visible: boolean
  setShowDatesCard: any
  onComplete: () => void
  resetAllPrereq: () => void
}

const optionsForIntervals = (intervals: IBookingInterval[]) =>
  intervals.map((interval) => ({
    ...interval,
    slug: _.kebabCase(interval.label),
    label: interval.label
  }))

const DatesCard = (props: ComponentProps) => {
  const {
    camp,
    campingStyle,
    setCampingStyle,
    preferred_checkin_times,
    preferred_checkout_times,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedInterval,
    setSelectedInterval,
  } = useContext(BookerContext)

  const [selectedCheckinHour, selectedCheckinMinutes] = preferred_checkin_times[campingStyle.id]
    .split(':')
    .map((v) => parseInt(v))
  const [selectedCheckoutHour, selectedCheckoutMinutes] = preferred_checkout_times[campingStyle.id]
    .split(':')
    .map((v) => parseInt(v))

  const [editingDates, setEditingDates] = useState(props.visible)
  const [selectedTimingId, setSelectedTimingId] = useState<string>(HardCodedTiming.ALL_DAY)
  const [minimumNights, setMinimumNights] = useState(hasHourlyOrDailyIntervals(campingStyle) ? 0 : campingStyle.minimum_bookable_nights)

  useEffect(() => {
    props.setShowDatesCard(editingDates)
  }, [editingDates])

  useEffect(() => {
    setEditingDates(props.visible)
  }, [props.visible])

  useEffect(() => {
    const isDailyOrHourly = campingStyle.booking_intervals.length > 0

    if (editingDates && isDailyOrHourly && startDate && !endDate) {
      let timeDiffInMinutes = 0
      if (isDailyOrHourly) {
        const intervals = campingStyle.booking_intervals || []
        const selectedInterval = intervals.length > 1 ? intervals.find((interval) => interval.id === selectedTimingId) : intervals[0]

        if (selectedInterval) {
          timeDiffInMinutes = moment(selectedInterval.end_time, 'HH:mm').diff(
            moment(selectedInterval.start_time, 'HH:mm'),
            'minutes'
          )
        }
      } else {
        switch (selectedTimingId) {
          case HardCodedTiming.MORNING:
            timeDiffInMinutes = 300
            break
          case HardCodedTiming.AFTERNOON:
            timeDiffInMinutes = 300
            break
          case HardCodedTiming.ALL_DAY:
            timeDiffInMinutes = 600
            break
          default:
            timeDiffInMinutes = 1440
            break
        }
      }

      setEndDate(moment(startDate).add(timeDiffInMinutes, 'minutes'))
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (startDate && !endDate && hasHourlyOrDailyIntervals(campingStyle)) {
      console.log('setting end date', startDate.clone().format('YYYY-MM-DD'))
      setEndDate(startDate.clone().hour(Number(selectedInterval.end_time.split(':')[0])).minute(Number(selectedInterval.end_time.split(':')[1])))
    }
  }, [selectedInterval])

  const applyTimesToDateRange = () => {
    setEditingDates(false)
    const mStartDate = moment(startDate)
    const mEndDate = moment(endDate)

    const dtStart = DateTime.fromJSDate(mStartDate.toDate()).setZone(camp.timezone)
    const dtEnd = DateTime.fromJSDate(mEndDate.toDate()).setZone(camp.timezone)

    const isHourly = !!campingStyle?.default_hours

    if (isHourly && selectedTimingId === HardCodedTiming.MORNING) {
      setStartDate(moment(dtStart.set({ hour: 9 }).toISO()))
      setEndDate(moment(dtEnd.set({ hour: 14 }).toISO()))
    } else if (isHourly && selectedTimingId === HardCodedTiming.AFTERNOON) {
      setStartDate(moment(dtStart.set({ hour: 14 }).toISO()))
      setEndDate(moment(dtEnd.set({ hour: 19 }).toISO()))
    } else if (isHourly && selectedTimingId === HardCodedTiming.ALL_DAY) {
      setStartDate(moment(dtStart.set({ hour: 9 }).toISO()))
      setEndDate(moment(dtEnd.set({ hour: 19 }).toISO()))
    } else if (selectedInterval) {
      const startHour = parseInt(selectedInterval.start_time.split(':')[0])
      const startMinute = parseInt(selectedInterval.start_time.split(':')[1])
      const endHour = parseInt(selectedInterval.end_time.split(':')[0])
      const endMinute = parseInt(selectedInterval.end_time.split(':')[1])

      const nStart = moment(mStartDate)
        .utc(true)
        .year(mStartDate.year())
        .month(mStartDate.month())
        .day(mStartDate.day())
        .hour(startHour)
        .minute(startMinute)

      const nEnd = moment(mEndDate)
        .utc(true)
        .year(mEndDate.year())
        .month(mEndDate.month())
        .day(mEndDate.day())
        .hour(endHour)
        .minute(endMinute)

      setStartDate(nStart)
      setEndDate(nEnd)
    } else if (mStartDate && mEndDate) {
      setStartDate(moment(
        DateTime.fromJSDate(mStartDate.toDate())
          .setZone(camp.timezone)
          .set({
            hour: selectedCheckinHour,
            minute: selectedCheckinMinutes
          })
          .toISO()
      ))
      setEndDate(moment(
        DateTime.fromJSDate(mEndDate.toDate())
          .setZone(camp.timezone)
          .set({
            hour: selectedCheckoutHour,
            minute: selectedCheckoutMinutes
          })
          .toISO()
      ))
    } else {
      setStartDate(mStartDate)
      setEndDate(mEndDate)
    }

    props.onComplete()
  }

  const selectCampingStyleByLabel = (name: string) => {
    const newCampingStyle = camp.camping_styles.find((cs) => cs.name === name)
    setCampingStyle(newCampingStyle)
    const minNights = hasHourlyOrDailyIntervals(newCampingStyle) ? 0 : newCampingStyle.minimum_bookable_nights
    setMinimumNights(minNights)
  }

  const campingStyles = camp.camping_styles.filter((style) => style.is_bookable_online)

  return (
    <Transition animation='fade up' className='cards-transition'>
      <Card fluid className={editingDates ? 'active' : ''}>
        <Card.Content>
          <DatePicker
            camp={camp}
            visible={editingDates}
            startDate={startDate}
            endDate={endDate}
            campingStyle={campingStyle}
            minimumNights={minimumNights}
          />
          {campingStyles.length > 1 && editingDates && (
            <>
              {editingDates ? <Divider /> : ''}
              <div className='flex justify-center'>
                <CampingStyleSelector
                  label='What are you reserving?'
                  campingStyles={campingStyles
                    .sort((a, b) => {
                      // Check if `a.sort` or `b.sort` is undefined or null and convert them to a very large number if true
                      const sortA = a.sort === undefined || a.sort === null ? 99 : a.sort
                      const sortB = b.sort === undefined || b.sort === null ? 99 : b.sort

                      const aName = a.name
                      const bName = b.name
                      const rvName = CAMPING_STYLES.RV

                      // Check for 'rv' priority when both sort values are at their max
                      if (sortA === 99 && sortB === 99) {
                        if (aName === rvName && bName !== rvName) {
                          return -1
                        }
                        if (bName === rvName && aName !== rvName) {
                          return 1
                        }
                      }

                      return sortA - sortB
                    })}
                  selectedCampingStyle={campingStyle}
                  setCampingStyle={(cs) => {
                    console.log(cs)
                    setCampingStyle(cs)
                  }}
                  campColor={camp.secondary_color}
                />
              </div>
            </>
          )}

          {campingStyle.booking_intervals.length > 1 && editingDates ? (
            <div className='flex justify-center mt-6'>
              <TimeSelector
                label='When would you like to rent?'
                options={optionsForIntervals(campingStyle.booking_intervals)}
                selectedOption={optionsForIntervals(campingStyle.booking_intervals).find((o) => o.id === selectedTimingId || o.id === selectedInterval?.id)}
                setSelectedOption={(option) => {
                  setSelectedTimingId(option.id)
                  setSelectedInterval(campingStyle.booking_intervals.find((i) => i.id === option.id))
                }}
                startDate={startDate}
                endDate={endDate}
                campColor={camp.secondary_color}
              />
            </div>
          ) : (
            ''
          )}
          {editingDates ? <Divider /> : ''}
          <SelectedDates
            editingDates={editingDates}
            confirmDateRange={applyTimesToDateRange}
            editDates={setEditingDates}
            startDate={startDate}
            endDate={endDate}
            maximumNights={campingStyle.max_stay_nights}
            maximumNightsMessage={campingStyle.max_stay_nights_message}
            resetAllPrereq={props.resetAllPrereq}
          />
        </Card.Content>
      </Card>
    </Transition>
  )
}

export default DatesCard
