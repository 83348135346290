import eSendingMode from '../../enums/eSendingMode'

export const sendingOptions = [
  { label: 'Not sending confirmation', value: eSendingMode.NONE },
  { label: 'Email confirmation', value: eSendingMode.EMAIL },
  { label: 'Text confirmation', value: eSendingMode.TEXT },
  { label: 'Email & text confirmation', value: eSendingMode.EMAIL_AND_TEXT },
]

export const sendingInvoiceOptions = [
  { label: 'Email invoice', value: eSendingMode.EMAIL },
  { label: 'Text invoice', value: eSendingMode.TEXT },
  { label: 'Email & text invoice', value: eSendingMode.EMAIL_AND_TEXT },
]

