import React from 'react'
import { Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import Button from './Button'
import classNames from '../../utils/classNames'
import copyText from '../../utils/copyText'
import { CheckIcon } from '@heroicons/react/24/outline'
import { LinkIcon } from '@heroicons/react/24/solid'

const CopyPasteButton = (props: {
  text?: string
  value: string
  mobile?: boolean
  closeModal?
  isEmbedLinkBtn?: boolean
  buttonClassName?: string
  iconClassName?: any
  isClickedCallback?: (v: boolean) => void
  id?: string
  isClipboardBtn?: boolean
  personalNote?: string
  disabled?: boolean
}) => {
  const [clicked, setClicked] = useState(false)
  const [clickedTimeout, setClickedTimeout] = useState(setTimeout(() => {}))

  const handleClick = () => {
    setClicked(true)
    if (props.isClickedCallback) props.isClickedCallback(true)

    copyText(props.value)

    clearTimeout(clickedTimeout)

    setClickedTimeout(
      setTimeout(() => {
        setClicked(false)
        if (props.isClickedCallback) props.isClickedCallback(false)
        if (props.closeModal) props.closeModal()
      }, 2500)
    )
  }

  const handleIcon = () => {
    if (props.isClipboardBtn) {
      return <img className='mr-3' src='/app/clipboard-icon.svg' />
    }

    return <LinkIcon height={18} width={18} className='mr-2' />
  }

  if (!props.text)
    return (
      <button type='button' id={props.id} className={props.buttonClassName} onClick={handleClick}>
        <div className='flex items-center justify-center'>
          <Transition
            show={!clicked}
            as={Fragment}
            enter='transform ease-out duration-100 transition delay-150'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave=''
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <span className='absolute'>
              <LinkIcon className={props.iconClassName} />
            </span>
          </Transition>
          <Transition
            show={clicked}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-2 sm:translate-x-0'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transform ease-out duration-300 transition'
            leaveFrom='translate-y-0 opacity-100'
            leaveTo='translate-y-2 opacity-0'>
            <span className='absolute'>
              <CheckIcon className={classNames('text-green-600 w-5 h-5')} />
            </span>
          </Transition>
        </div>
      </button>
    )
  else
    return (
      <Button
        type='button'
        disabled={props.disabled}
        className={props.buttonClassName || 'w-full h-12 rounded-l-none'}
        variant='white'
        onClick={handleClick}>
        <div className='flex items-center justify-center'>
          <Transition
            show={!clicked}
            as={Fragment}
            enter='transform ease-out duration-100 transition delay-150'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave=''
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <span
              className={classNames(
                !props.isEmbedLinkBtn && 'absolute',
                'flex text-black mx-auto items-center'
              )}>
              {handleIcon()}
              Copy {props.text}
            </span>
          </Transition>
          <Transition
            show={clicked}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-2 sm:translate-x-0'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transform ease-out duration-300 transition'
            leaveFrom='translate-y-0 opacity-100'
            leaveTo='translate-y-2 opacity-0'>
            <span className='absolute flex text-black items-center'>
              <CheckIcon className='text-black mr-2' height={18} width={18} />
              Copied!
            </span>
          </Transition>
        </div>
      </Button>
    )
}

export default CopyPasteButton
