import React, { useContext } from 'react'
import {
  CheckCircleIcon,
  LockClosedIcon,
  MinusCircleIcon,
  NoSymbolIcon,
  XCircleIcon,
  ArrowsUpDownIcon
} from '@heroicons/react/24/solid'
import moment from 'moment'
import classNames from '../../utils/classNames'
import HoverTip from './HoverTip'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import { AdminContext } from '../../contexts/AdminContext'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export const StatusDeleted = (props) => (
  <div className='flex items-center justify-start font-semibold text-red-500'>
    <div className='flex items-center'>
      <XCircleIcon className='w-5 h-5 mr-1 xs:mr-2' />
      <span className=''>Deleted {props.deleted_at && props.deleted_at}</span>
    </div>
  </div>
)

export const StatusUnpaid = () => {
  return (
    <div className='flex items-center justify-start font-semibold text-yellow-500'>
      <div className='flex items-center'>
        <MinusCircleIcon className='w-5 h-5 mr-1 xs:mr-2' />
        <span className=''>Unpaid</span>
      </div>
    </div>
  )
}

export const StatusPaid = (props?: { refundedAmountInCents?: number }) => {
  const { camp } = useContext(AdminContext)

  return (
    <div className='flex items-center justify-start font-semibold text-green-600'>
      <div className='flex items-center'>
        <CheckCircleIcon className='w-5 h-5 mr-1 xs:mr-2' />
        <span className=''>Paid</span>
        {props.refundedAmountInCents > 0 && (<HoverTip white content={`${formatMoneyFromCents(props.refundedAmountInCents, camp.currency)} has been refunded`} delayOpen transitionDelay={300}>
          <span className="flex items-center ml-2 rounded-lg bg-gray-200 text-sm text-gray-600 py-1 px-2.5">
            Refunded
            <InformationCircleIcon className="ml-1 w-4 h-4" />
          </span>
        </HoverTip>)}
      </div>
    </div>
  )
}

export const StatusBlocked = () => {
  return (
    <div className='flex items-center justify-start font-semibold text-gray-600'>
      <div className='flex items-center'>
        <NoSymbolIcon className='w-5 h-5 mr-1 xs:mr-2' />
        <span className=''>Blocked</span>
      </div>
    </div>
  )
}

export const StatusImported = () => {
  return (
    <div className='flex items-center justify-start font-semibold text-gray-600'>
      <div className='flex items-center'>
        <ArrowsUpDownIcon className='w-5 h-5 mr-1 xs:mr-2' />
        <span className=''>Imported</span>
      </div>
    </div>
  )
}

export const StatusLocked = () => {
  return (
    <div className='flex items-center justify-start font-semibold text-gray-600'>
      <div className='flex items-center'>
        <LockClosedIcon className='w-5 h-5 mr-1 xs:mr-2' />
        <span className=''>Locked</span>
      </div>
    </div>
  )
}

export const CamperBlocked = (props) => {
  return (
    <div className={classNames('flex items-center justify-start font-semibold text-red-500', props.className)}>
      <div className='flex items-center'>
        {props.small ? (
          <>
            <NoSymbolIcon className='w-4 h-4 ml-1 mr-0.5' />
            <span className='text-md'>Blocked</span>
          </>
        ) : (
          <>
            <NoSymbolIcon className='w-5.5 h-5.5 mr-2' />
            <span className='text-base'>Blocked from booking</span>
          </>
        )}
      </div>
    </div>
  )
}
