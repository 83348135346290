import { UserProps } from '../interfaces'
import ICamper from '../interfaces/ICamper'
import { get } from './utils/requests'

const users = (campId: string, query: string): Promise<{ users: UserProps[] }> =>
  get('/api/search', { type: 'user', query, camp_id: campId })

const campers = (campId: string, query: string): Promise<{ campers: ICamper[] }> =>
  get('/api/search', { type: 'camper', query, camp_id: campId })

const Search = {
  users,
  campers
}

export default Search
