import React, { useContext, useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { Form, Grid, Select, Popup } from 'semantic-ui-react'

import 'react-dates/initialize'
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css' // NOTE; yeah

import { CampingStyleProps, UserProps } from '../../interfaces'
import { formatPhoneNumber, titleCase } from '../../utils'
import moment, { Moment } from 'moment'
import PriceDetailsSection from '../Admin/PriceDetailsSection'
import { CAMPING_STYLES, RESERVATION_KINDS } from '../../constants'
import { Combobox, Transition } from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce/lib'
import api from '../../api2'
import Spinner from '../ui/Spinner'
import { AdminContext } from '../../contexts/AdminContext'
import { useCookies } from 'react-cookie'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import SitesDropdown from './SitesDropdown/SitesDropdown'
import { NewReservationContext } from '../../contexts/admin/NewReservationContext'
import { validElectric, validRigType } from '../../types'
import ReservationTypeSelector from '../ui/ReservationTypeSelector'
import SelectInput, { Option } from '../ui/SelectInput'
import { CamperBlocked } from '../ui/StatusBadges'
import ICamper from '../../interfaces/ICamper'
import { formatName } from '../../utils/formatName'
import RichTextInput from '../ui/RichTextInput'
import { RIG_TYPES } from '../../constants/rigTypes'
import RECURRING_LIMIT_IN_YEARS from '../../constants/recurring_limit_in_years'
import classNames from '../../utils/classNames'
import { createElectricOptions } from '../../utils/admin/createElectricOptions'
import { NewReservationAction } from '../../reducers/newReservation'

interface ComponentProps {
  isProcessing: boolean
  errorResponse
  rigLengthError: string
  setRigLengthError: React.Dispatch<React.SetStateAction<string>>
}

const NewReservationForm = (props: ComponentProps) => {
  const { camp, campingStyles, sites } = useContext(AdminContext)
  const {
    newReservation,
    dispatch,
    inputNotes,
    setInputNotes,
    inputFirstName,
    setInputFirstName,
    inputLastName,
    setInputLastName,
    inputEmail,
    setInputEmail,
    inputPhone,
    setInputPhone,
    inputVehicleRigLength,
    setInputVehicleRigLength,
    inputVehicleElectric,
    setInputVehicleElectric,
    inputVehicleRigType,
    setInputVehicleRigType,
    inputVehicleLicense,
    setInputVehicleLicense,
    inputVehicleYear,
    setInputVehicleYear,
    inputVehicleMake,
    setInputVehicleMake,
    inputVehicleColor,
    setInputVehicleColor,
    inputAdults,
    setInputAdults,
    inputChildren,
    setInputChildren,
    inputPets,
    setInputPets,
    address,
    setAddress,
    isAcceptingDeposit,
    setIsAcceptingDeposit,
    selectedReservationType,
    setSelectedReservationType,
    selectedSitesForGroup,
    setSelectedSitesForGroup,
  } = useContext(NewReservationContext)

  const selectedSite = sites.find((site) => site.id === newReservation.site_id)
  let campingStyle: CampingStyleProps | undefined

  if (selectedSite) {
    campingStyle = campingStyles.find((cs) => cs.id === selectedSite.camping_style_id)
  }

  const hasPetsFees = Number(campingStyle?.extra_pets_price_in_cents) > 0
  const defaultHours = Number(campingStyle?.default_hours)
  const isHourly = defaultHours > 0

  const [inputFocusedStartDate, setInputFocusedStartDate] = useState(false)
  const [inputFocusedEndDate, setInputFocusedEndDate] = useState(false)
  const [cookies, setCookie] = useCookies(['isAdditionalInfoShown', 'isTaxAdded', 'isReccuringTaxAdded'])
  const [isShown, setIsShown] = useState(cookies.isAdditionalInfoShown === 'true')
  const [tempEndDate, setTempEndDate] = useState<Moment | null>(null)

  const [startTime, setStartTime] = useState(
    campingStyle?.booking_intervals?.find(
      (interval) => interval.id === newReservation.interval_id
    )?.start_time ||
    newReservation.start_date?.format('HH:mm') ||
    moment().hours(9).minutes(0).format('HH:mm')
  )
  const [endTime, setEndTime] = useState(
    campingStyle?.booking_intervals?.find(
      (interval) => interval.id === newReservation.interval_id
    )?.end_time ||
    newReservation.end_date?.format('HH:mm') ||
    moment().hours(9).minutes(0).add(defaultHours, 'hours').format('HH:mm')
  )

  useEffect(() => {
    !cookies['isTaxAdded']?.length && setCookie('isTaxAdded', newReservation.is_tax_enabled)
    !cookies['isReccuringTaxAdded']?.length && setCookie('isReccuringTaxAdded', newReservation.is_tax_enabled)
  }, [])

  const handleDisclosureClick = () => {
    setCookie('isAdditionalInfoShown', !isShown)
    setIsShown(!isShown)
  }

  useEffect(() => {
    if (
      newReservation.start_date &&
      newReservation.end_date &&
      newReservation.end_date.diff(newReservation.start_date, 'days') < 1 &&
      !campingStyle?.booking_intervals?.length
    ) {
      dispatch({
        type: 'UPDATE_END_DATE',
        payload: {
          end_date: moment(newReservation.start_date).add(1, 'day'),
        },
      })
    }
  }, [campingStyle])

  const handleEventTypeChange = (selectedType) => {
    setSelectedReservationType(selectedType)
    dispatch({
      type: 'UPDATE_STATUS_AND_KIND',
      payload: {
        status: selectedType == RESERVATION_KINDS.BLOCKED ? 'blocked' : 'unpaid',
        kind: selectedType == 'recurring' || selectedType == 'group' ? selectedType : null,
      },
    })

    if (selectedType === RESERVATION_KINDS.RECURRING) {
      if (
        newReservation.start_date &&
        newReservation.end_date &&
        newReservation.end_date.diff(newReservation.start_date, 'days') < 30
      ) {
        setTempEndDate(newReservation.end_date)
        dispatch({
          type: 'UPDATE_END_DATE',
          payload: {
            end_date: moment(newReservation.start_date).add(1, 'month'),
          },
        })
      }
    } else {
      if (tempEndDate) {
        dispatch({
          type: 'UPDATE_END_DATE',
          payload: {
            end_date: tempEndDate,
          },
        })
      }
    }
  }

  const onAutofill = (camper: any) => {
    if (camper != 'hidden') {
      setInputFirstName(camper.first_name)
      setInputLastName(camper.last_name)
      setInputEmail(camper.email)
      setInputPhone(formatPhoneNumber(camper.phone))
      setInputAdults(camper.default_adults_count)
      setInputChildren(camper.default_kids_count)
      setInputPets(camper.default_pets_info)
      setInputVehicleColor(camper.vehicle_color)
      setInputVehicleLicense(camper.license_plate)
      setInputVehicleMake(camper.vehicle_make)
      setInputVehicleRigLength(camper.vehicle_rig_length)
      setInputVehicleRigType(camper.vehicle_rig_type as validRigType)
      setInputVehicleYear(
        [undefined, null, ''].includes(camper.vehicle_year) ? null : Number(camper.vehicle_year)
      )
      setAddress(camper.address)
      setInputVehicleElectric(camper.vehicle_electric as validElectric)
    }
  }

  const typeOptions = [
    { label: 'Single Reservation', value: 'single' },
    { label: 'Recurring Reservation', value: 'recurring' },
    { label: 'Group Reservation', value: 'group' },
    { label: 'Blocked Reservation', value: 'blocked' },
  ]

  const electricOptions = createElectricOptions(selectedSite)

  const setVehicleRigLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rigLength = e.target.value

    if (Number(rigLength) > selectedSite.max_rig_length) {
      props.setRigLengthError(
        `The length of the rig should not exceed ${selectedSite.max_rig_length}ft.`
      )
    } else {
      props.setRigLengthError(null)
    }
    setInputVehicleRigLength(rigLength)
  }
  const rigTypeOptions = [null]
    .concat(Object.values(RIG_TYPES))
    .map((rigType) => ({ key: rigType, value: titleCase(rigType), label: titleCase(rigType) }))

  useEffect(() => {
    return () => props.setRigLengthError(null)
  }, [])

  return (
    <Form loading={props.isProcessing} autocomplete='new-password'>
      <input
        autoComplete='new-password'
        name='hidden'
        type='text'
        style={{ display: 'none' }}></input>
      <div className='flex justify-start'>
        <div className='hidden sm:block'>
          <ReservationTypeSelector
            options={typeOptions}
            selectedOption={selectedReservationType}
            onChange={handleEventTypeChange}
          />
        </div>
        <div className='sm:hidden w-full mt-1.5 mb-5'>
          <SelectInput
            options={typeOptions}
            selected={typeOptions.find((option) => option.value === selectedReservationType)}
            setSelected={(option: Option) => handleEventTypeChange(option.value)}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row md:mt-4 justify-between border-t border-gray-200 pt-3'>
        <div className='w-full md:w-3/5'>
          <div>
            <div className='w-full mb-4'>
              <SitesDropdown
                label='Site'
                selectedIds={selectedSitesForGroup.map((site) => site?.id)}
                setSelectedIds={(ids) => {
                  const mSites = ids
                    .map((id) => sites.find((site) => site.id === id))
                    .sort((a, b) => a.sort - b.sort)

                  setSelectedSitesForGroup(mSites)
                  // NOTE: Also update site_id for newReservation for non-group reservations
                  dispatch({
                    type: 'UPDATE_SITE_ID',
                    payload: { site_id: mSites[0]?.id },
                  })
                }}
                reservation={newReservation}
                selectedReservationType={selectedReservationType}
                required
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-3'>
            <Form.Field required>
              <label>Arrival</label>
              <SingleDatePicker
                id='NEW_RESERVATION_START_DATE'
                block
                date={newReservation.start_date}
                onDateChange={(date) => {
                  const [hours, minutes] = startTime.split(':')
                  dispatch({
                    type: 'UPDATE_START_DATE',
                    payload: { start_date: date?.hours(Number(hours))?.minutes(Number(minutes)) },
                  })

                  dispatch({
                    type: 'UPDATE_END_DATE',
                    payload: { end_date: null },
                  })
                }}
                noBorder
                isOutsideRange={(day: Moment) => false}
                focused={inputFocusedStartDate}
                onFocusChange={(e) => setInputFocusedStartDate(e.focused)}
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                displayFormat={camp.date_format}
              />
              {isHourly && (
                <Form.Input
                  type='time'
                  step='900'
                  className='pt-1'
                  defaultValue={startTime}
                  onChange={(time) => {
                    setStartTime(time.target.value)
                    const splitTime = time.target.value.split(':')
                    dispatch({
                      type: 'UPDATE_START_DATE',
                      payload: {
                        start_date: moment(newReservation.start_date)
                          .hours(Number(splitTime[0]))
                          .minutes(Number(splitTime[1])),
                      },
                    })
                    dispatch({
                      type: NewReservationAction.UPDATE_START_TIME,
                      payload: { start_time: time.target.value },
                    })
                  }}
                />
              )}
            </Form.Field>
            <Form.Field required>
              <label>Departure</label>
              <SingleDatePicker
                id='NEW_RESERVATION_END_DATE'
                block
                date={newReservation.end_date}
                onDateChange={(date) =>{
                  const [hours, minutes] = endTime.split(':')
                  dispatch({
                    type: 'UPDATE_END_DATE',
                    payload: { end_date: date?.hours(Number(hours))?.minutes(Number(minutes)) },
                  })
                }}
                focused={inputFocusedEndDate}
                onFocusChange={(e) => setInputFocusedEndDate(e.focused)}
                numberOfMonths={1}
                initialVisibleMonth={() =>
                  newReservation.end_date || newReservation.start_date || moment()
                }
                hideKeyboardShortcutsPanel={true}
                noBorder
                isOutsideRange={(day: Moment) =>
                  defaultHours
                    ? moment(day).isBefore(newReservation.start_date) ||
                      moment(day).isAfter(
                        moment(newReservation.start_date).add(RECURRING_LIMIT_IN_YEARS, 'years')
                      )
                    : isInclusivelyBeforeDay(day, newReservation.start_date) ||
                      moment(day).isAfter(
                        moment(newReservation.start_date).add(RECURRING_LIMIT_IN_YEARS, 'years')
                      )
                }
                displayFormat={camp.date_format}
              />
              {isHourly && (
                <Form.Input
                  type='time'
                  step='900'
                  className='pt-1'
                  value={endTime}
                  onChange={(time) => {
                    setEndTime(time.target.value)
                    const splitTime = time.target.value.split(':')
                    // TODO: confrim that this dispatch works with hours as well and check this logic
                    dispatch({
                      type: 'UPDATE_END_DATE',
                      payload: {
                        end_date: moment(newReservation.end_date)
                          .hours(Number(splitTime[0]))
                          .minutes(Number(splitTime[1])),
                      },
                    })
                    dispatch({
                      type: NewReservationAction.UPDATE_END_TIME,
                      payload: { end_time: time.target.value },
                    })
                  }}
                />
              )}
            </Form.Field>
          </div>
          {selectedReservationType === RESERVATION_KINDS.BLOCKED ? (
            <div className='mb-2'>
              <Form.TextArea
                error={props.errorResponse?.errors?.notes}
                label='Notes'
                value={inputNotes}
                onChange={(e) => setInputNotes(e.target.value)}
              />
            </div>
          ) : (
            <>
              <Grid>
                <Grid.Column width='16'>
                  {selectedReservationType == RESERVATION_KINDS.GROUP ? (
                    <div className='mb-3 flex items-end'>
                      <span className='text-lg font-semibold'>Group leader info</span>
                    </div>
                  ) : (
                    <p className='text-lg font-semibold mb-3'>Customer info</p>
                  )}
                  <Form.Group widths='equal'>
                    <input type='text' autoComplete='false' className='hidden'></input>
                    <AutocompleteField
                      label='First name'
                      error={props.errorResponse?.errors?.first_name}
                      value={inputFirstName}
                      onChange={(e) => setInputFirstName(e.target.value)}
                      onAutofill={onAutofill}
                    />
                    <AutocompleteField
                      label='Last name'
                      error={props.errorResponse?.errors?.last_name}
                      value={inputLastName}
                      onChange={(e) => setInputLastName(e.target.value)}
                      onAutofill={onAutofill}
                    />
                  </Form.Group>
                  <Form.Group widths='equal'>
                    <AutocompleteField
                      label='Email'
                      className='ghostinspector-new-reservation-email-input'
                      error={props.errorResponse?.errors?.email}
                      value={inputEmail}
                      onChange={(e) => setInputEmail(e.target.value)}
                      onAutofill={onAutofill}
                    />
                    <AutocompleteField
                      label='Phone'
                      error={props.errorResponse?.errors?.phone}
                      value={formatPhoneNumber(inputPhone)}
                      onChange={(e) => setInputPhone(e.target.value)}
                      onAutofill={onAutofill}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid>
              <Form.Group widths='equal'>
                <div className='p-2 w-full'>
                  <label className='text-sm font-bold'>Notes</label>
                  <RichTextInput
                    value={inputNotes}
                    setValue={setInputNotes}
                    style={{ marginTop: 4 }}
                  />
                </div>
              </Form.Group>
              {newReservation.kind != RESERVATION_KINDS.GROUP && (
                <Disclosure defaultOpen={isShown}>
                  {({ open }) => (
                    /* Use the `open` state to conditionally change the direction of an icon. */
                    <>
                      <Disclosure.Button
                        onClick={handleDisclosureClick}
                        className='flex justify-start w-full mt-4 mb-3'>
                        <ChevronRightIcon
                          className={`${
                            open ? 'rotate-90 transform' : ''
                          } h-5.5 w-5.5 text-gray-700 mr-2 mt-0.5`}
                        />
                        <div className='text-lg font-semibold'>Additional info</div>
                      </Disclosure.Button>
                      <Disclosure.Panel className='w-full'>
                        <Form.Group widths='equal' className='relative'>
                          <Popup
                            trigger={
                              <Form.Input
                                className='flex flex-col'
                                error={props.errorResponse?.errors?.vehicle_rig_length}
                                label='Rig length'
                                value={inputVehicleRigLength}
                                onChange={setVehicleRigLength}
                              />
                            }
                            hideOnScroll
                            position='top left'
                            open={!!props?.rigLengthError?.length}
                            style={{
                              color: 'rgba(239, 68, 68)',
                              fontSize: '.875rem',
                            }}>
                            {props?.rigLengthError}
                          </Popup>
                          <div className='w-full flex-col items-start'>
                            <div className=' text-sm font-bold mb-1.5'>Amperage</div>
                            <SelectInput
                              options={electricOptions}
                              selected={
                                electricOptions.find((o) => o.value === inputVehicleElectric) ||
                                electricOptions.find((o) => o.value === null || '\u00A0')
                              }
                              setSelected={(option: Option) =>
                                setInputVehicleElectric(option.value as validElectric)
                              }
                              buttonSize='normal'
                            />
                          </div>
                          <div className='w-full flex-col items-start ml-4'>
                            <div className='text-sm font-bold mb-1.5'>RV Type</div>
                            <SelectInput
                              options={rigTypeOptions}
                              selected={rigTypeOptions.find((o) => o.value === inputVehicleRigType)}
                              setSelected={(option: Option) =>
                                setInputVehicleRigType(option.value as validRigType)
                              }
                              buttonSize='normal'
                            />
                          </div>
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Input
                            fluid
                            error={props.errorResponse?.errors?.license_plate}
                            label='License plate'
                            value={inputVehicleLicense}
                            onChange={(e) => setInputVehicleLicense(e.target.value)}
                          />
                          <Form.Field>
                            <label>Year</label>
                            <Select
                              fluid
                              error={props.errorResponse?.errors?.vehicle_year}
                              className='sites-filter-dropdown'
                              options={Array.from(
                                new Array(new Date().getFullYear() - 1948),
                                (x, i) => new Date().getFullYear() - i + 1
                              ).map((x) => {
                                return { key: x, value: x, text: x }
                              })}
                              value={inputVehicleYear}
                              onChange={(e, { value }) => setInputVehicleYear(value as number)}
                            />
                          </Form.Field>
                          <Form.Input
                            fluid
                            error={props.errorResponse?.errors?.vehicle_make}
                            label='Make & model'
                            value={inputVehicleMake}
                            onChange={(e) => setInputVehicleMake(e.target.value)}
                          />
                          <Form.Input
                            fluid
                            error={props.errorResponse?.errors?.vehicle_color}
                            label='Color'
                            value={inputVehicleColor}
                            onChange={(e) => setInputVehicleColor(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Input
                            type='number'
                            error={props.errorResponse?.errors?.passengers}
                            label='Adults'
                            value={inputAdults}
                            onChange={(e) => setInputAdults(Number(e.target.value))}
                          />
                          <Form.Input
                            type='number'
                            error={props.errorResponse?.errors?.kid_campers}
                            label='Kids'
                            value={inputChildren}
                            onChange={(e) => setInputChildren(Number(e.target.value))}
                          />
                          <Form.Input
                            type={hasPetsFees ? 'number' : 'string'}
                            error={props.errorResponse?.errors?.pets_info}
                            label='Pets info'
                            value={inputPets}
                            onChange={(e) => setInputPets(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.TextArea
                            type='string'
                            rows={2}
                            error={props.errorResponse?.errors?.address}
                            label='Address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Form.Group>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}
            </>
          )}
        </div>
        {selectedReservationType != RESERVATION_KINDS.BLOCKED && (
          <PriceDetailsSection
            camp={camp}
            sites={sites}
            reservation={newReservation}
            selectedSiteIds={selectedSitesForGroup.map((site) => site.id)}
            selectedReservationType={selectedReservationType}
            reservationDispatch={dispatch}
            isAcceptingDeposit={isAcceptingDeposit}
            setIsAcceptingDeposit={setIsAcceptingDeposit}
            isShowingPassengerFeeToggle={selectedReservationType != RESERVATION_KINDS.GROUP}
            hasPetsFees={hasPetsFees}
          />
        )}
      </div>
    </Form>
  )
}

const AutocompleteField = ({
  ref,
  label,
  className,
  error,
  value,
  onChange,
  onAutofill,
}: {
  ref?: any // TODO
  label: string
  className?: string
  error: string
  value: string
  onChange: any // TODO
  onAutofill: any // TODO
}) => {
  const { camp } = useContext(AdminContext)
  const [isLoading, setIsLoading] = useState(false)
  const [campers, setCampers] = useState<ICamper[]>([])

  const reloadSearchResults = useDebouncedCallback(async (query: string) => {
    api.Search.campers(camp.id, query)
      .then(({ campers }) => {
        setCampers(campers)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 1000)

  useEffect(() => {
    if (value && !campers.length) {
      reloadSearchResults(value)
    }
  }, [])

  return (
    <div className='w-full flex flex-col ml-2 mr-2 relative'>
      <div className='mb-1 text-sm font-bold'>{label}</div>
      <Combobox value={value} onChange={onAutofill}>
        <Combobox.Input
          ref={ref}
          className={className}
          autoComplete='new-password'
          onChange={(event) => {
            onChange(event)

            setIsLoading(true)
            reloadSearchResults(event.target.value)
          }}
        />
        <Transition
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0 absolute w-full z-10'
          enterTo='transform scale-100 opacity-100 absolute w-full z-10'
          leave='transition duration-75 ease-out absolute w-full z-10'
          leaveFrom='transform scale-100 opacity-100 absolute w-full z-10'
          leaveTo='transform scale-95 opacity-0 absolute w-full z-10'
          style={{ top: 60 }}>
          <Combobox.Options as='div' className='z-50 shadow-2xl rounded-md bg-white w-full py-2'>
            <>
              <div className='text-sm font-bold px-3 py-2'>
                Previous guests {!isLoading ? ` (${campers.length} results)` : ''}
              </div>
              {isLoading ? (
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                  <span className='text-sm'>Loading...</span>
                  <Spinner size={5} />
                </div>
              ) : (
                <div className='flex flex-col max-h-64 overflow-y-scroll overflow-x-hidden'>
                  <Combobox.Option value={'hidden'} className='hidden' />
                  {campers
                    .filter((c) => c.first_name || c.last_name || c.email || c.phone)
                    .map((camper) => (
                      <Combobox.Option
                        as='span'
                        key={camper.id}
                        value={camper}
                        className='cursor-pointer hover:bg-gray-100 hover:text-white text-md'>
                        {({ active }) => (
                          <div
                            className={classNames(
                              'py-1.5 px-3',
                              camper.blocked_at ? 'text-gray-400' : 'text-black',
                              active ? 'bg-gray-100' : ''
                            )}>
                            <div className='flex'>
                              {formatName(camper as any)}{' '}
                              {camper.blocked_at && <CamperBlocked small />}
                            </div>
                            {camper.email || camper.phone}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                </div>
              )}
            </>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  )
}

export default NewReservationForm
