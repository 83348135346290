import { Moment } from 'moment'

const displayDates = (startDate: Moment, endDate: Moment) => {
  if (!startDate) {
    return null
  }

  if (!endDate) {
    return startDate.format('MMM D')
  }

  if (startDate.isSame(endDate)) {
    return startDate.format('MMM Do')
  } else if (startDate.year() != endDate.year()) {
    return `${startDate.format('MMM D, YYYY')}－${endDate.format('MMM D, YYYY')}`
  } else if (startDate.month() != endDate.month()) {
    return `${startDate.format('MMM D')}－${endDate.format('MMM D')}`
  } else {
    return `${startDate.format('MMM D')}－${endDate.format('D')}`
  }
}

export default displayDates
