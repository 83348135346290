import React, { useState } from 'react'
import { Container, Divider, Menu, Message, Segment } from 'semantic-ui-react'

import CampStatistics from './AdminSettings/CampStatistics'
import parkLogoPath from '../../assets/images/park_logo__green.svg'

interface componentProps {
  data: string
}

const AdminStatistics = (props: componentProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const handleLoginMenuItemClick = () => {
    window.location.href = `${window.origin}/admin/login`
  }

  return (
    <>
      <Segment>
        <Container>
          <Menu secondary>
            <span className='flex-inline align-middle mr-2'>
              <img src={parkLogoPath} className='inline z-10 h-10' />
            </span>
            <span className='flex relative p-4 mr-4 align-middle leading-none font-bold'>
            </span>
            <Menu.Menu position='right'>
              <Menu.Item name='login' active={false} onClick={handleLoginMenuItemClick} />
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
      <Container>
        <Message hidden={!errorMessage} error={!!errorMessage} content={errorMessage} />
        <Divider hidden fitted={!!errorMessage} />
      </Container>
      <div className='w-full'>
        <CampStatistics ReservationsData={props.data} setErrorMessage={setErrorMessage} />
      </div>
    </>
  )
}

export default AdminStatistics
