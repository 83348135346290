import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import classNames from '../../../utils/classNames'

interface DropdownOption {
  icon?: any // TODO: component definition
  label: string
  onClick?: () => void
}

interface ComponentProps {
  stopPropagation?: boolean
  children: React.ReactNode
  options: Array<DropdownOption>
}

const DropdownButton = (props: ComponentProps) => {
  
  const menuButtonRef = useRef(null)

  const [menuOpened, setMenuOpened] = useState(false)
  const [menuButtonPosition, setMenuButtonPosition] = useState(
    menuButtonRef?.current?.getBoundingClientRect()
  )

  useEffect(() => {
    setMenuButtonPosition(menuButtonRef?.current?.getBoundingClientRect())
  }, [menuButtonRef, menuOpened])
  
  return (
    <div className='flex h-full items-center'>
      <Menu
        as='div'
        className='relative inline-block text-left'
        style={{ height: 35 }}
      >
        <div>
          <Menu.Button
            ref={menuButtonRef}
            onClick={() => setMenuOpened((prev) => !prev)}
            className='inline-flex justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          >
            {props.children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            style={
              menuButtonPosition && menuButtonPosition?.x
                ? {
                    right:
                      window.screen.width < 1280
                        ? menuButtonPosition?.right -
                            menuButtonPosition?.left || 35
                        : 0,
                    transform:
                      window.screen.width < 1280
                        ? 'translateY(-100%)'
                        : undefined,
                    top:
                      window.screen.width < 1280
                        ? menuButtonPosition.y
                        : 'auto',
                  }
                : undefined
            }
            className='fixed xl:absolute xl:top-0 z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          >
            <div className='px-1 py-1 '>
              {props.options.map((option, index) =>
                option.label === '_divider_' ? (
                  <div className='my-1 border-b border-gray-200' key={index} />
                ) : (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-black' : 'text-gray-900',
                          'group flex w-full items-center rounded-md px-2 py-2'
                        )}
                        onClick={option.onClick}
                      >
                        {option.icon && (
                          <span className='mr-2'>
                            <option.icon className='w-4 h-4' />
                          </span>
                        )}
                        {option.label}
                      </button>
                    )}
                  </Menu.Item>
                )
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default DropdownButton
