import { LINE_ITEM_KINDS, lineItemKind } from "../constants/line_item_kinds"
import ILineItem from "../interfaces/ILineItem"
import IOrder from "../interfaces/IOrder"
import IOrderPreview from "../interfaces/IOrderPreview"
import IPayment from "../interfaces/IPayment"
import IPaymentLink from "../interfaces/IPaymentLink"
import { get, post } from "./utils/requests"

export enum serviceRateMode {
  pos = "pos",
  payment_link = "payment_link",
  base = "base"
}

export const mapLineItemToParams = (
  li: Partial<ILineItem>,
  isTaxFeeEnabled: boolean
): LineItemParams => ({
  kind: li.kind,
  label: li.label,
  total_amount_in_cents: li.reservation_id ? li.total_amount_in_cents : null,
  unit_amount_in_cents: li.reservation_id ? null : li.unit_amount_in_cents,
  quantity: li.quantity,
  occurrences: li.occurrences,
  occurrence_unit: li.occurrence_unit,
  is_tax_fee_enabled: isTaxFeeEnabled,
  reservation_id: li.reservation_id,
  add_on_id: li.add_on_id,
  is_attached_to_reservation: !!li.parent_line_item_id
})

export const depositLineItemParams = (amount: number, isTaxFeeEnabled: boolean): Array<LineItemParams> => ([
  {
    kind: LINE_ITEM_KINDS.BASIC,
    label: 'Deposit',
    unit_amount_in_cents: amount,
    quantity: 1,
    is_tax_fee_enabled: false,
    total_amount_in_cents: null,
    occurrences: null,
    occurrence_unit: null,
    reservation_id: null,
    add_on_id: null,
    is_attached_to_reservation: false
  }
])

export type OrderParams = {
  order_id: string
  camp_id: string
  camping_style_id: string
  camper_id?: string
  is_credit_card_fee_enabled: boolean
  discount_slug: string
  is_storing_credit_card_enabled?: boolean
  is_terminal_payment?: boolean
  selected_payment_method_id?: string
  line_items_attributes: Array<LineItemParams>
  partial_line_items_attributes?: Array<LineItemParams>
}

type OrderPreviewParams = OrderParams & {
  is_service_fee_enabled: boolean
  service_rate_mode: serviceRateMode
  include_payment_methods?: boolean
}

export type LineItemParams = {
  kind: lineItemKind
  label: string
  total_amount_in_cents: number
  unit_amount_in_cents: number
  occurrences: number
  occurrence_unit: string
  quantity: number
  is_tax_fee_enabled: boolean
  reservation_id: string
  add_on_id: string
  is_attached_to_reservation: boolean
}

type PaymentLinkParams = {
  email: string
}

const fetch = async (id: string, includePaymentMethods?: boolean): Promise<{ order: IOrder }> => {
  let url = `/api/orders/${id}`;
  if (includePaymentMethods) {
    url += '?include_payment_methods=true';
  }

  return get(url);
};

const orderAndPreviewFor = async (
  id: string,
  campId: string,
  campingStyleId: string,
  mode: serviceRateMode = serviceRateMode.base,
  isPaid: boolean = false,
  discountSlug: string
): Promise<{ order: IOrder, preview: IOrderPreview }> => {
  const { order } = await fetch(id)
  let preview: IOrderPreview

  if (!isPaid) {
    const { order: orderWithPreview } = await createPreview({
      order_id: order.id,
      camp_id: campId,
      camping_style_id: campingStyleId,
      is_credit_card_fee_enabled: !!order.credit_card_rate,
      is_service_fee_enabled: true,
      service_rate_mode: mode,
      discount_slug: discountSlug,
      line_items_attributes: order.line_items.map((li) => mapLineItemToParams(li, Number(li.tax_rate) > 0)),
    })

    preview = orderWithPreview.preview
  }

  return Promise.resolve({ order, preview })
}

const createPreview = async (data: OrderPreviewParams): Promise<{ order: { preview: IOrderPreview } }> =>
  post(`/api/orders/previews`, data)

const createPayment = async (
  data: OrderParams,
  options?: {
    isForGroup?: boolean
  }
): Promise<{ payment: IPayment & { order: IOrder } }> =>
  options?.isForGroup ? (
    post(`/api/orders/groups/payments`, data)
  ) : (
    post(`/api/orders/payments`, data)
  )

const createPaymentIntent = async (data: OrderParams): Promise<{ payment_intent: { id: string; client_secret: string; status: string, order: IOrder } }> =>
  post(`/api/orders/payment_intents`, data)

const createPaymentLink = async (
  data: OrderParams & PaymentLinkParams
): Promise<{ payment_link: IPaymentLink & { order: IOrder } }> =>
  post(`/api/orders/payment_links`, data)

const Orders = {
  createPayment,
  createPaymentIntent,
  createPaymentLink,
  createPreview,
  fetch,
  orderAndPreviewFor
}

export default Orders
