import React, { useState } from 'react'
import { Card, Divider, Form, Segment } from 'semantic-ui-react'
import api from '../../api2'

interface ComponentProps {
  setErrorMessage
}

const LoginForm = (props: ComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleEmailChange = (e, { value }) => {
    setEmail(value)
  }

  const handlePasswordChange = (e, { value }) => {
    setPassword(value)
  }

  const handleSubmit = () => {
    setIsLoading(true)

    api.Sessions.login({ email, password })
      .then(() => {
        props.setErrorMessage('')
        window.location.href = `${window.origin}/admin/reservations`
      })
      .catch((errResponse) => {
        props.setErrorMessage(errResponse.error)
        setIsLoading(false)
      })
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Log in</Card.Header>
        <Card.Meta>Log in to your existing administrative account.</Card.Meta>

        <Divider />

        <Form onSubmit={handleSubmit} loading={isLoading}>
          <Form.Input
            label='Email'
            icon='mail'
            iconPosition='left'
            value={email}
            placeholder={'Email'}
            onChange={handleEmailChange}
          />

          <Form.Input
            label='Password'
            type='password'
            icon='key'
            iconPosition='left'
            value={password}
            placeholder={'Password'}
            onChange={handlePasswordChange}
          />

          <Form.Button fluid primary>
            <span className='text-lg leading-5'>Log in</span>
          </Form.Button>

          <p className='text-right'>
            <a href='/password_resets/new' className='underline text-blue hover:underline'>
              Forgot password?
            </a>
          </p>

          <p className='text-right'>
            No account?{' '}
            <a
              href='https://www.poweredbypark.com/#contact-form'
              className='underline text-blue hover:underline'>
              Contact us to get started
            </a>
          </p>
        </Form>
      </Card.Content>
    </Card>
  )
}

export default LoginForm