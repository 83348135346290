import React, { useState, Fragment, useEffect, useContext } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController } from 'react-dates'
import moment, { Moment } from 'moment'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import { displayDates } from '../../../utils'
import classNames from '../../../utils/classNames'
import { AdminContext } from '../../../contexts/AdminContext'

const intervalOptions = [
  'Today',
  'Last 7 days',
  'Last 4 weeks',
  'Last 3 months',
  'Last 12 months',
  'Month to date',
  'Quarter to date',
  'Year to date',
  'All time',
]

const intervalOptionsMonthly = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

interface ComponentProps {
  visible: boolean
  startDate: Moment
  endDate: Moment | null
  setStartDate: any
  setEndDate: any
  isMonthlyIntervals?: boolean
}

const ReportsDatePicker = (props: ComponentProps) => {
  const [selectedInterval, setSelectedInterval] = useState<string>(
    props.isMonthlyIntervals ? moment().format('MMMM') : 'Last 7 days'
  )
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>('startDate')
  const { camp } = useContext(AdminContext)

  const onChange = (dates: { startDate: Moment; endDate: Moment }) => {
    // NOTE: hard set the hour so that we're exactly 24 hours apart
    const start: Moment = dates.startDate?.hour(12)?.utc(true)
    const end: Moment = dates.endDate?.hour(12)?.utc(true)

    setSelectedInterval('Custom')
    updateDates(start, end)
  }

  const updateDates = (start: Moment, end: Moment) => {
    props.setStartDate(start)
    props.setEndDate(end)
  }

  useEffect(() => {
    if (props.isMonthlyIntervals) setSelectedInterval(moment().format('MMMM'))
    else setSelectedInterval('Last 7 days')
  }, [props.isMonthlyIntervals])

  const onChangeSelectedInterval = (interval: string) => {
    let start: Moment = moment()
    let end: Moment = moment()

    switch (interval) {
      case 'Today':
        start = moment()
        end = moment()
        updateDates(start, end)
        break
      case 'Last 7 days':
        start = moment().subtract(7, 'days')
        end = moment()
        updateDates(start, end)
        break
      case 'Last 4 weeks':
        start = moment().subtract(28, 'days')
        end = moment()
        updateDates(start, end)
        break
      case 'Last 3 months':
        start = moment().subtract(3, 'months')
        end = moment()
        updateDates(start, end)
        break
      case 'Last 12 months':
        start = moment().subtract(12, 'months')
        end = moment()
        updateDates(start, end)
        break
      case 'Month to date':
        start = moment().startOf('month')
        end = moment()
        updateDates(start, end)
        break
      case 'Quarter to date':
        start = moment().startOf('quarter')
        end = moment()
        updateDates(start, end)
        break
      case 'Year to date':
        start = moment().startOf('year')
        end = moment()
        updateDates(start, end)
        break
      case 'All time':
        start = moment(camp.created_at)
        end = moment()
        updateDates(start, end)
        break
      case 'January':
        start = moment().startOf('year').month(0)
        end = moment().startOf('year').month(1).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'February':
        start = moment().startOf('year').month(1)
        end = moment().startOf('year').month(2).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'March':
        start = moment().startOf('year').month(2)
        end = moment().startOf('year').month(3).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'April':
        start = moment().startOf('year').month(3)
        end = moment().startOf('year').month(4).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'May':
        start = moment().startOf('year').month(4)
        end = moment().startOf('year').month(5).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'June':
        start = moment().startOf('year').month(5)
        end = moment().startOf('year').month(6).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'July':
        start = moment().startOf('year').month(6)
        end = moment().startOf('year').month(7).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'August':
        start = moment().startOf('year').month(7)
        end = moment().startOf('year').month(8).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'September':
        start = moment().startOf('year').month(8)
        end = moment().startOf('year').month(9).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'October':
        start = moment().startOf('year').month(9)
        end = moment().startOf('year').month(10).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'November':
        start = moment().startOf('year').month(10)
        end = moment().startOf('year').month(11).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'December':
        start = moment().startOf('year').month(11)
        end = moment().startOf('year').month(12).subtract(1, 'day')
        updateDates(start, end)
        break
      case 'Custom':
        break
      default:
        break
    }
  }

  const onFocusChange = (input) => {
    if (input) {
      if (input == 'endDate') {
        props.setEndDate(null)
      }
      setFocusedInput(input)
    } else {
      setFocusedInput('startDate')
    }
  }

  return (
    <div className='flex'>
      <Listbox
        value={selectedInterval}
        onChange={(option) => {
          onChangeSelectedInterval(option)
          setSelectedInterval(option)
        }}>
        {({ open }) => (
          <>
            <div className='relative'>
              <Listbox.Button
                className={classNames(
                  props.isMonthlyIntervals ? 'rounded-md' : 'rounded-l-md',
                  'inline-flex justify-center items-center w-full border border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-gray-100 open-sans noOutline'
                )}>
                {selectedInterval}
                <span className=''>
                  <ChevronDownIcon className='ml-2 mt-0.5 h-5.5 w-5.5' aria-hidden='true' />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Listbox.Options className='absolute z-10 mt-1 w-56 bg-white shadow-lg max-h-96 rounded-md py-1 text-base overflow-auto focus:outline-none'>
                  {props.isMonthlyIntervals
                    ? intervalOptionsMonthly.map((option) => (
                        <Listbox.Option
                          key={option}
                          className={({ active }) =>
                            classNames(
                              active && 'bg-gray-100',
                              'cursor-pointer select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={option}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-medium text-green-600' : 'font-normal',
                                  'block truncate'
                                )}>
                                {option}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    'text-green-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                  <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))
                    : intervalOptions.map((option) => (
                        <Listbox.Option
                          key={option}
                          className={({ active }) =>
                            classNames(
                              active && 'bg-gray-100',
                              'cursor-pointer select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={option}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-medium text-green-600' : 'font-normal',
                                  'block truncate'
                                )}>
                                {option}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    'text-green-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                  <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <Menu as='div' className='relative inline-block text-left z-10 mr-auto'>
        <div>
          <Menu.Button
            className={classNames(
              props.isMonthlyIntervals ? 'hidden' : 'inline-flex',
              'justify-center items-center w-full border border-l-0 rounded-r-md border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2  focus:ring-offset-gray-100 open-sans noOutline'
            )}>
            <CalendarIcon className='mr-3.5 -ml-1 mb-0.25 mt-0.25 h-5.5 w-5.5' aria-hidden='true' />
            {displayDates(props.startDate, props.endDate)}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <DayPickerRangeController
              startDate={props.startDate}
              endDate={props.endDate}
              onDatesChange={onChange}
              focusedInput={focusedInput}
              onFocusChange={onFocusChange}
              initialVisibleMonth={() => props.startDate || moment()}
              noBorder={true}
              hideKeyboardShortcutsPanel={true}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default ReportsDatePicker
