import React, { useContext, useEffect, useState } from 'react'
import EditCampForm from './EditCampForm'
import SelectInput, {Option} from '../../ui/SelectInput'
import Button from '../../ui/Button'
import { useForm } from 'react-hook-form'
import { AdminContext } from '../../../contexts/AdminContext'
import _ from 'lodash'
import api from '../../../api2'
import HoverTip from '../../ui/HoverTip'
import { CampProps, CampingStyleProps } from '../../../interfaces'
import Spinner from '../../ui/Spinner'
import PolicyAndRulesForm from './PolicyAndRulesForm'
import Alert from '../../ui/Alert'
import Products from './Product/Products'
import Pricing from './Pricing'
import InvoicesSettings from './InvoiceSettings'

type ISettingTabOptions = 'camp' | 'products' | 'policy-and-rules' | 'add-ons' | 'invoices'

enum tabs {
  camp = 'camp',
  policyAndRules = 'policy-and-rules',
  products = 'products',
  invoices = 'invoices'
  // addOns = 'add-ons',
}

const tabOptions = [
  { label: 'Camp Settings', value: tabs.camp },
  { label: 'Policy & Rules', value: tabs.policyAndRules },
  { label: 'Products', value: tabs.products },
  { label: 'Invoices', value: tabs.invoices },
  // { label: 'Add-ons', value: tabs.addOns },
]

const formSanitized = (camp: CampProps, campingStyles: CampingStyleProps[]) => {
  return {
    ...camp,
    updated_at: null,
    created_at: null,
    camping_styles: null,
    discounts: null,
    camp_discounts: null,
    prerequisites: null,
    checkin_policy: campingStyles.find((style) => style.checkin_policy)?.checkin_policy,
    refund_policy: campingStyles.find((style) => style.refund_policy)?.refund_policy,
  }
}

const Settings = () => {
  const { camp, reloadAccessibleCamps, campingStyles } = useContext(AdminContext)
  const [activeTab, setActiveTab] = useState<ISettingTabOptions>(tabs.camp)
  const [defaultValues, setDefaultValues] = useState<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>(formSanitized(camp, campingStyles))
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [isEdited, setIsEdited] = useState(false)
  const reactHookForm = useForm({ defaultValues })
  const  { register, control, reset, watch, setError } = reactHookForm
  const currentValues = watch()

  useEffect(() => {
    setIsEdited(!_.isEqual(defaultValues, currentValues))
  }, [defaultValues, currentValues])

  useEffect(() => {
    setIsLoading(true)
    setDefaultValues(formSanitized(camp, campingStyles))
    reset(formSanitized(camp, campingStyles))
    setIsLoading(false)
  }, [camp])

  const handleCancel = () => {
    reset(defaultValues)
    setErrorMessage(null)
  }

  const handleSave = async () => {
    setIsLoading(true)
    setErrorMessage(null)
    return api.Camps.update(camp.id, currentValues)
      .then(({ camp: c, camping_styles: cs }) => {
        setDefaultValues(formSanitized(c, cs))
        reset(formSanitized(c, cs))
        reloadAccessibleCamps()
        setIsLoading(false)
        // TODO: triggerNotification('success')
      })
      .catch((err) => {
        console.error(err)
        setErrorMessage(err.message)
        if (err.errors) {
          Object.keys(err.errors).forEach((key: keyof CampProps) => {
            setError(key, { message: err.errors[key] })
          })
        }
        setIsLoading(false)
        // TODO: triggerNotification(err.message)
      })
  }

  return (
    <div className='w-full max-w-4xl'> 
      <div className='bg-white top-20 mx-4 py-4 z-10'>
        <div className='flex justify-between'>
          <div className='w-64 mr-4'>
            <SelectInput
              options={tabOptions}
              selected={tabOptions.find((option) => option.value === activeTab)}
              setSelected={(option: Option) => setActiveTab(option.value)}
              large
            />
          </div>
          {activeTab !== 'products' && <div className='flex'>
            <Button
              disabled={!isEdited || isLoading}
              className='mr-4'
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              disabled={!isEdited || isLoading}
              variant='blue'
              onClick={handleSave}>
              {isLoading ? <Spinner size={4} /> : 'Save'}
            </Button>
          </div>}
        </div>
      </div>
      <div className='mx-4 mb-48'>
        {errorMessage && <Alert description={errorMessage} />}
        <form>
          {activeTab === 'camp' && <EditCampForm reactHookForm={reactHookForm} />}
          {activeTab === 'invoices' && <InvoicesSettings reactHookForm={reactHookForm} />}
          {activeTab === 'products' && <Products  />}
          {activeTab === 'policy-and-rules' && (
            <PolicyAndRulesForm reactHookForm={reactHookForm} />
          )}
          {/* {activeTab === 'add-ons' && (<div>Add-ons</div>)} */}
        </form>
      </div>
    </div>
  )
}

export default Settings
