import React, { useState, useEffect } from "react"
import useAdminForm from "../../../../hooks/useAdminForm"
import { CampingStyleProps } from "../../../../interfaces"
import classNames from "../../../../utils/classNames"

interface NumberInputProps {
  field: keyof CampingStyleProps
  label?: string
  subheader?: string
  prefix?: string
  suffix?: string
  suffixButtonCta?: string | JSX.Element
  placeholder?: string
  onSuffixButtonClick?: () => void
  className?: string
  hidden?: boolean
  disabled?: boolean
  stopPropagation?: boolean
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  asText?: boolean
}

const DollarFieldWithLabel = ({
  field,
  label,
  subheader,
  prefix,
  suffix,
  placeholder,
  className,
  hidden,
  disabled,
  stopPropagation,
  onInput,
}: NumberInputProps) => {
  
  const { resource, isChanged, setValue, errors, clearError, register } = useAdminForm<CampingStyleProps>()

  const convertToDollars = (value) => {
    return value === null ? null : Number(value) / 100
  }

  const convertToCents = (value) => {
    return (value === null || !value?.length) ? null : Number(value) * 100
  }
  
  const convertToNumber = (value) => {
    return (value === null || !value?.length) ? null : Number(value)
  }

  const defaultValue = convertToDollars(resource[field])

  const [fieldValue, setFieldValue] = useState<number | null>(defaultValue)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['.', ','].includes(e.key)) {
      e.preventDefault()
    }
  }
  
  useEffect(() => {
    register(field, {})
  }, [register])

  return (
    <div className={classNames(className, hidden ? 'hidden' : '')}>
      {label && (
        <div className='mb-1'>
          <label
            htmlFor='name'
            className={classNames(
              'block font-medium text-md leading-4',
              errors[field] ? 'text-red-600' : 'text-gray-900'
            )}>
            {label}

            {errors[field] && (
              <span className='ml-1 text-red-500 text-xs'>{errors[field].message}</span>
            )}
          </label>
          {subheader && <span className='text-gray-500 font-normal text-sm'>{subheader}</span>}
        </div>
      )}
      <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
        {prefix && (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            <span className='text-gray-500 sm:text-sm'>{prefix}</span>
          </div>
        )}
        <input
          onChange={(e) => {
            if (errors[field]) clearError(field)
            setValue(field, convertToCents(e.target.value))
            setFieldValue(convertToNumber(e.target.value))
          }}
          onKeyDown={handleKeyDown}
          onInput={onInput}
          type='number'
          placeholder={placeholder}
          hidden={!!hidden}
          min={0}
          value={fieldValue}
          onClick={(e) => stopPropagation && e.stopPropagation()}
          disabled={disabled}
          className={classNames(
            'block w-full',
            prefix && 'pl-8',
            suffix && 'md:pr-12',
            'rounded-md focus:border-green-700 focus:ring-green-700',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            errors[field] ? 'border-red-600' : 'border-gray-300',
            isChanged(field) ? 'bg-blue-50' : 'bg-white'
          )}
          step='any'
        />
      </div>
    </div>
  )
}

export default DollarFieldWithLabel