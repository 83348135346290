import React, { useContext, useEffect, useState } from 'react'
import { Transition } from 'semantic-ui-react'

import Feature from './Feature'
import { CampingStyleProps, SiteProps } from '../../interfaces'
import { validElectric } from '../../types'
import { BookerContext } from '../../contexts/BookerContext'
import CampImagesCarousel from '../ui/CampImagesCarousel'
import api from '../../api2'
import createMarkup from '../../utils/createMarkup'

const features = [
  'kind',
  'pad_type',
  'camper_capacity',
  'has_linens',
  'max_rig_length',
  'extra_car_space',
  'premium_view',
  'electric',
  'electric_15',
  'electric_20',
  'electric_30',
  'electric_50',
  'water',
  'sewage',
  'has_wifi',
  'pets',
  'dog_park',
  'pool',
  'dump_station',
  'public_bathrooms',
  'showers',
  'has_laundry',
  'public_kitchen',
  'picnic_table',
  'cable_tv',
  'fire_pit',
  'general_store',
  'has_heating',
  'has_microwave',
  'has_oven',
  'has_refrigerator',
  'has_stove',
  'has_air_conditioning',
  'has_private_bathroom',
  'number_of_king_beds',
  'number_of_futon_beds',
  'number_of_queen_beds',
  'number_of_full_beds',
  'number_of_twin_beds',
  'number_of_single_beds',
  'number_of_bunk_beds',
  'has_community_fire_pit',
  'has_grill_barbecue',
  'has_restaurant',
  'has_tv',
  'has_playground',
  'has_propane_fill_up',
  'has_volleyball_court',
  'has_basketball_court',
  'has_disc_golf_course',
  'has_golf_course',
  'has_mini_golf_course',
  'has_hotplate',
  'has_towels',
]

interface ComponentProps {
  visible: boolean
  site: SiteProps
  nights: number
  confirmCallback: any
  userElectric: validElectric
  userCampingStyle: CampingStyleProps
}

const Features = (props: ComponentProps) => {
  const { selectedSite, camp } = useContext(BookerContext)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    api.Sites.fetchImages(selectedSite.id)
      .then(({ images }) => {
        setImageUrls(images.map((image) => image.url))
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }, [])

  return (
    <Transition visible={props.visible || false} animation='fade right'>
      <div>
        {props.visible && selectedSite ? (
          <div className='space-y-6'>
            <div className='pb-20'>
              <div className='text-lg font-bold mb-6'>Features</div>
              {selectedSite.notes && (
                <div className={`${imageUrls.length > 0 ? 'pb-3' : 'pb-7'} whitespace-pre-wrap`}>
                  <div dangerouslySetInnerHTML={createMarkup(selectedSite.notes)} />
                </div>
              )}
              {imageUrls.length > 0 && (
                <div className='w-full pb-6'>
                  <CampImagesCarousel isInfinite={false} imageUrls={imageUrls} isLoading={isLoading} />
                </div>
              )}
              {props.userCampingStyle.is_showing_features && (
                <div className='grid sm:grid-cols-2 grid-cols-1 gap-6'>
                  {features
                    .map((feature) =>{
                      return selectedSite[feature] ||
                      [
                        'has_wifi',
                        'electric',
                        'water',
                        'has_linens',
                        'fire_pit',
                        'picnic_table',
                        'has_towels'
                      ].includes(feature) ? (
                        <Feature
                          key={feature}
                          name={feature as keyof SiteProps}
                          value={selectedSite[feature]}
                          site={selectedSite}
                        />
                      ) : null
                    }
                    )
                    .filter((feature) => feature)}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </Transition>
  )
}

export default Features
