import React from 'react'
import { Form, Select } from 'semantic-ui-react'
import { RIG_TYPES } from '../../../constants/rigTypes'
import { validRigType } from '../../../types'
import { titleCase } from '../../../utils'

interface ComponentProps {
  userRigType: validRigType
  handleRigTypeChange
  label?: boolean
  fluid?: boolean
  placeholder? : string
}

const RigTypeField = ({ userRigType, handleRigTypeChange, label, fluid, placeholder }: ComponentProps) => {
  if (label == undefined) label = true

  // TODO: we should populate this array from all available options from camp sites
  const rigTypeOptions = [null].concat(Object.values(RIG_TYPES)).map(rigType => (
    { key: rigType, value: rigType, text: titleCase(rigType) }
  ))

  return <Form.Field required>
    { label && <label>Your RV Type</label> }
    <Select
      clearable
      fluid={fluid}
      className='sites-filter-dropdown'
      options={rigTypeOptions}
      value={userRigType}
      onChange={handleRigTypeChange}
      placeholder='RV Type'
    />
  </Form.Field>
}

export default RigTypeField
