const NIGHTLY: '1d' = '1d'
const NIGHTLY_2_PLUS: '2d+' = '2d+'
const NIGHTLY_3_PLUS: '3d+' = '3d+'
const WEEKLY: '1w' = '1w'
const MONTHLY: '1m' = '1m'
const FLAT_RATE: 'flat-rate' = 'flat-rate'

const PRICE_DURATIONS = {
  NIGHTLY,
  NIGHTLY_2_PLUS,
  NIGHTLY_3_PLUS,
  WEEKLY,
  MONTHLY,
  FLAT_RATE,
}

export { PRICE_DURATIONS }
