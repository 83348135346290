import React, { useContext, useEffect, useState } from 'react'
import { MagnifyingGlassIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { AdminContext } from '../../../contexts/AdminContext'
import { useDebouncedCallback } from 'use-debounce'
import api from '../../../api2'
import ICamper from '../../../interfaces/ICamper'
import { Combobox, Transition } from '@headlessui/react'
import Spinner from '../../ui/Spinner'
import { formatName } from '../../../utils/formatName'
import { CamperBlocked } from '../../ui/StatusBadges'

const CamperSearchBar = ({
  value,
  onChange,
  onAutofill,
}: {
  value: any
  onChange: any // TODO
  onAutofill: any // TODO
}) => {
  const { camp } = useContext(AdminContext)

  const [isLoading, setIsLoading] = useState(false)
  const [campers, setCampers] = useState<ICamper[]>([])

  const reloadSearchResults = useDebouncedCallback(async (query: string) => {
    api.Search.campers(camp.id, query)
      .then(({ campers }) => {
        setCampers(campers)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 1000)

  useEffect(() => {
    if (value && !campers.length) {
      reloadSearchResults(value)
    }
  }, [])

  return (
    <div className='w-full flex flex-col relative'>
      <Combobox value={value} onChange={onAutofill}>
        <div className='relative'>
          <div className='pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center'>
            <UserCircleIcon className='h-8 w-8 text-gray-500' aria-hidden='true' />
          </div>

          <Combobox.Input
            className='block w-full bg-white border border-gray-300 rounded-md py-3.5 pl-14 pr-3 text-base placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-green focus:border-green'
            autoComplete='new-password'
            placeholder='Add guest'
            onChange={(event) => {
              onChange(event)

              setIsLoading(true)
              reloadSearchResults(event.target.value)
            }}
          />
        </div>
        <Transition
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0 absolute w-full z-10'
          enterTo='transform scale-100 opacity-100 absolute w-full z-10'
          leave='transition duration-75 ease-out absolute w-full z-10'
          leaveFrom='transform scale-100 opacity-100 absolute w-full z-10'
          leaveTo='transform scale-95 opacity-0 absolute w-full z-10'
          style={{ top: 50 }}>
          <Combobox.Options as='div' className='z-50 shadow-2xl rounded-md bg-white w-full py-2'>
            <>
              <div className='text-sm font-bold px-3 py-2'>
                Previous guests {!isLoading ? ` (${campers.length} results)` : ''}
              </div>
              {isLoading ? (
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                  <span className='text-sm'>Loading...</span>
                  <Spinner size={5} />
                </div>
              ) : (
                <div className='flex flex-col max-h-64 overflow-y-scroll overflow-x-hidden'>
                  {campers.map((camper) => (
                    <Combobox.Option
                      as='span'
                      key={camper.id}
                      value={camper}
                      className='py-1.5 px-3 cursor-pointer hover:bg-gray-100 hover:text-white text-md'>
                      <div className={camper.blocked_at ? 'text-gray-400' : 'text-black'}>
                        <div className='flex'>
                          {formatName(camper as any)} {camper.blocked_at && <CamperBlocked small />}
                        </div>
                        {camper.email}
                      </div>
                    </Combobox.Option>
                  ))}
                </div>
              )}
            </>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  )
}

export default CamperSearchBar
