const CLASS_A: 'class-a' = 'class-a'
const CLASS_B: 'class-b' = 'class-b'
const CLASS_C: 'class-c' = 'class-c'
const TRAVEL_TRAILER: 'travel-trailer' = 'travel-trailer'
const FIFTH_WHEEL: 'fifth-wheel' = 'fifth-wheel'
const POPUP_TRAILER: 'popup-trailer' = 'popup-trailer'
const VAN: 'van' = 'van'
const TOY_HAULER: 'toy-hauler' = 'toy-hauler'
const TEARDROP_TRAILER: 'teardrop-trailer' = 'teardrop-trailer'
const TRUCK_CAMPER: 'truck-camper' = 'truck-camper'
const BUS: 'bus' = 'bus'
const TENT: 'tent' = 'tent'

const RIG_TYPES = {
  CLASS_A,
  CLASS_B,
  CLASS_C,
  TRAVEL_TRAILER,
  FIFTH_WHEEL,
  VAN,
  TOY_HAULER,
  TEARDROP_TRAILER,
  POPUP_TRAILER,
  TRUCK_CAMPER,
  BUS,
  TENT
}

export { RIG_TYPES }
