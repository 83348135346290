const RV: 'rv' = 'rv'
const SEASONAL_RV: 'seasonal-rv' = 'seasonal-rv'
const TENT: 'tent' = 'tent'
const CABIN: 'cabin' = 'cabin'
const BOAT_SLIP: 'boat-slip' = 'boat-slip'
const BOAT_RENTAL: 'boat-rental' = 'boat-rental'
const MOBILE_HOME: 'mobile-home' = 'mobile-home'
const STORAGE: 'storage' = 'storage'
const PAVILION: 'pavilion' = 'pavilion'
const CABANA: 'cabana' = 'cabana'
const EVENT_CENTER: 'event-center' = 'event-center'
const GARAGE: 'garage' = 'garage'
const HORSE_PEN: 'horse-pen' = 'horse-pen'
const HORSE_STALL: 'horse-stall' = 'horse-stall'
const SHELTER: 'shelter' = 'shelter'
const RV_STORAGE: 'rv-storage' = 'rv-storage'
const RENTAL_RV: 'rental-rv' = 'rental-rv'
const LODGE: 'lodge' = 'lodge'
const GOLF_CART: 'golf-cart' = 'golf-cart'
const POOL: 'pool' = 'pool'
const VENDOR_BOOTH: 'vendor-booth' = 'vendor-booth'
const DISC_GOLF: 'disc-golf' = 'disc-golf'
const DRY_CAMPING: 'dry-camping' = 'dry-camping'
const DOME: 'dome' = 'dome'
const GLAMPING: 'glamping' = 'glamping'
const HOME: 'home' = 'home'
const LODGING: 'lodging' = 'lodging'
const BONFIRE: 'bonfire' = 'bonfire'
const TRUCK: 'truck' = 'truck'
const GROUP: 'group' = 'group'
const COTTAGE: 'cottage' = 'cottage'
const MAIN_HOUSE: 'main-house' = 'main-house'
const FOOD_TRUCK: 'food-truck' = 'food-truck'
const MISC: 'miscellaneous' = 'miscellaneous'
const ENSUITE_UNIT: 'ensuite-unit' = 'ensuite-unit'
const UNPOWERED_SITE: 'unpowered-site' = 'unpowered-site'
const TIPI: 'tipi' = 'tipi'
const KAYAK: 'kayak' = 'kayak'
const VAN: 'van' = 'van'
const ROOM: 'room' = 'room'
const GENERATOR: 'generator' = 'generator'
const RENTAL_ITEM: 'rental-item' = 'rental-item'
const PICNIC_AREA: 'picnic-area' = 'picnic-area'
const KITCHEN: 'kitchen' = 'kitchen'
const PLATFORM: 'platform' = 'platform'
const CANVAS_TENT: 'canvas-tent' = 'canvas-tent'

const CAMPING_STYLES = {
  RV,
  TENT,
  CABIN,
  BOAT_SLIP,
  BOAT_RENTAL,
  MOBILE_HOME,
  STORAGE,
  PAVILION,
  CABANA,
  EVENT_CENTER,
  GARAGE,
  HORSE_PEN,
  HORSE_STALL,
  SHELTER,
  RV_STORAGE,
  RENTAL_RV,
  LODGE,
  GOLF_CART,
  POOL,
  VENDOR_BOOTH,
  DISC_GOLF,
  DRY_CAMPING,
  DOME,
  GLAMPING,
  HOME,
  LODGING,
  BONFIRE,
  TRUCK,
  GROUP,
  COTTAGE,
  MAIN_HOUSE,
  FOOD_TRUCK,
  MISC,
  ENSUITE_UNIT,
  UNPOWERED_SITE,
  TIPI,
  KAYAK,
  VAN,
  ROOM,
  GENERATOR,
  RENTAL_ITEM,
  PICNIC_AREA,
  SEASONAL_RV,
  KITCHEN,
  PLATFORM,
  CANVAS_TENT,
}

export { CAMPING_STYLES }
