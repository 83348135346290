import { IOption } from "../../ui/Admin/OptionSelector";

export enum INVOICE_FILTER_OPTIONS {
  ALL = 'all',
  DRAFT = 'draft',
  OUTSTANDING = 'outstanding',
  PAST_DUE = 'past-due',
  PAID = 'paid',
  ARCHIVED = 'archived',
}

export const filterOptions: IOption[] = [
  {
    value: INVOICE_FILTER_OPTIONS.ALL,
    label: 'All invoices',
  },
  {
    value: INVOICE_FILTER_OPTIONS.DRAFT,
    label: 'Draft',
  },
  {
    value: INVOICE_FILTER_OPTIONS.OUTSTANDING,
    label: 'Outstanding',
  },
  {
    value: INVOICE_FILTER_OPTIONS.PAST_DUE,
    label: 'Past due',
  },
  {
    value: INVOICE_FILTER_OPTIONS.PAID,
    label: 'Paid',
  },
  {
    value: INVOICE_FILTER_OPTIONS.ARCHIVED,
    label: 'Archived',
  },
]
