import ICoupon from "../interfaces/ICoupon"
import { get, post } from "./utils/requests"

const validate = (code: string): Promise<{ coupon: ICoupon }> =>
  post(`/api/coupons/validate`, { code })

const fetchForCamp = (camp_id: string): Promise<{ coupons: Array<ICoupon> }> =>
  get(`/api/camps/${camp_id}/coupons`)

const Coupons = {
  validate,
  fetchForCamp: fetchForCamp,
}

export default Coupons
