import { ELECTRICS } from '../../../constants'
import { SiteProps } from '../../../interfaces'

let electricOptions = (sites: SiteProps[]) => {
  let is15A = false
  let is20A = false
  let is30A = false
  let is50A = false
  let isNonElectric = false

  if (sites && sites.length > 0) {
    sites.forEach((site) => {
      if (site?.electric == ELECTRICS.E15) {
        is15A = true
      } else if (site?.electric == ELECTRICS.E20) {
        is20A = true
      } else if (site?.electric == ELECTRICS.E30) {
        is30A = true
      } else if (site?.electric == ELECTRICS.E50) {
        is50A = true
      } else if (site?.electric == ELECTRICS.SPLIT_20_30) {
        is20A = true
        is30A = true
      } else if (site?.electric == ELECTRICS.SPLIT_30_50) {
        is30A = true
        is50A = true
      } else if (site?.electric == ELECTRICS.SPLIT_20_30_50) {
        is20A = true
        is30A = true
        is50A = true
      } else if (site?.electric == null || site?.electric == ELECTRICS.NONE) { 
        isNonElectric = true
      }
    })
  }

  let ampsOptions = []
  if (is15A) {
    ampsOptions.push({ key: ELECTRICS.E15, value: ELECTRICS.E15, text: '15-amp' })
  }
  if (is20A) {
    ampsOptions.push({ key: ELECTRICS.E20, value: ELECTRICS.E20, text: '20-amp' })
  }
  if (is30A) {
    ampsOptions.push({ key: ELECTRICS.E30, value: ELECTRICS.E30, text: '30-amp' })
  }
  if (is50A) {
    ampsOptions.push({ key: ELECTRICS.E50, value: ELECTRICS.E50, text: '50-amp' })
  }
  if (isNonElectric) {
    ampsOptions.push({ key: ELECTRICS.NONE, value: ELECTRICS.NONE, text: 'Non-electric' })
  }

  return ampsOptions
}

export default electricOptions
