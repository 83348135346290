import React, { MutableRefObject, useState } from 'react'
import classNames from '../../../utils/classNames'

const InputFieldWithCurrency = ({
  type,
  ref,
  label,
  name,
  id,
  value,
  defaultValue,
  placeholder,
  disabled,
  className,
  icon,
  onError,
  onChange,
  onBlur,
  full,
  currency,
  isChanged,
  readOnly,
  required,
}: {
  type?: string
  ref?: MutableRefObject<any>
  label?: string
  name?: string
  id?: string
  value?: any
  defaultValue?: any
  placeholder?: any
  disabled?: boolean
  className?: string
  icon?: any
  onError?: (err) => any
  onChange?: (e) => any
  onBlur?: (e) => any
  full?: boolean
  currency?: string
  isChanged?: boolean
  readOnly?: boolean
  required?: boolean
}) => {
  return (
    <div className={classNames(full && 'w-full', className)}>
      {label && (
        <label htmlFor='name' className='block font-semibold text-md text-gray-900 mb-0.5'>
          {label}
        </label>
      )}
      <div className='relative rounded-md shadow-sm'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <span className='text-gray-500'>$</span>
        </div>
        <input
          ref={ref}
          type={type || 'number'}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          id={id || name}
          readOnly={readOnly}
          required={required}
          onError={onError}
          onChange={onChange}
          onBlur={onBlur}
          className={classNames(
            'block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-green-700 focus:ring-green-700 focus:border disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            isChanged ? 'bg-blue-50' : 'bg-white'
          )}
          aria-describedby='price-currency'
          step='any'
        />
        <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
          <span className='text-gray-500 sm:text-sm' id='price-currency'>
            {currency?.toUpperCase() || 'USD'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default InputFieldWithCurrency
