import React, { Component, Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import classNames from '../../utils/classNames'
import { ArrowPathIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import Button from './Button'

interface ComponentProps {
  visible: boolean
  title: string
  description?: string

  success?: boolean
  warning?: boolean
  error?: boolean

  iconSuccess?: boolean
  iconWarning?: boolean
  iconError?: boolean

  refreshButton?: boolean

  onDismiss?: (...any: any) => any
  dismissAfter?: number

  onClick?: (...any: any) => any
}

// Global notification live region, render this permanently at the end of the document
export const NotificationContainer = (props: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div
      aria-live='assertive'
      className='fixed bottom-0 right-0 w-full flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50'>
      <div className='w-full flex flex-col items-center space-y-4 sm:items-start'>
        {props.children}
      </div>
    </div>
  )
}

const Notification = (props: ComponentProps) => {
  const [show, setShow] = useState(props.visible)
  const [showTimeout, setShowTimeout] = useState(setTimeout(() => {}))

  let bgColor = 'bg-white'
  let iconColor = 'text-gray-500'
  let iconComponent = <InformationCircleIcon />

  // NOTE: control colors + icon
  switch (true) {
    case props.success:
      iconColor = 'text-green-400'
      iconComponent = <CheckCircleIcon />
      break
    case props.warning:
      iconColor = 'text-yellow'
      iconComponent = <ExclamationCircleIcon />
      break
    case props.error:
      iconColor = 'text-red'
      iconComponent = <XCircleIcon />
      break
  }

  // NOTE: control just icon
  switch (true) {
    case props.iconSuccess:
      iconComponent = <CheckCircleIcon />
      break
    case props.iconWarning:
      iconComponent = <ExclamationCircleIcon />
      break
    case props.iconError:
      iconComponent = <XCircleIcon />
      break
  }

  const dismissMe = () => {
    setShow(false)
    props.onDismiss()
  }

  useEffect(() => {
    setShow(props.visible)
    if (props.visible == true && props.onDismiss && (props.dismissAfter || props.success)) {
      clearTimeout(showTimeout)
      setShowTimeout(
        setTimeout(() => {
          dismissMe()
        }, props.dismissAfter || 5000)
      )
    }
  }, [props.visible])

  if(!props.visible) return <></>

  return (
    <>
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <Transition
        show={props.visible}
        as={Fragment}
        enter='transform ease-out duration-300 transition'
        enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
        enterTo='translate-y-0 opacity-100 sm:translate-x-0'
        leave='transition ease-in duration-100'
        leaveFrom='translate-y-0 opacity-100 sm:translate-x-0'
        leaveTo='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'>
        <a
          className={classNames(
            bgColor,
            'max-w-lg w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden',
            props.onClick && 'cursor-pointer hover:bg-gray-50'
          )}
          onClick={(args) => {
            if (!props.onClick) return

            props.onClick(args)
            dismissMe()
          }}>
          <div className='p-6'>
            <div className='flex items-start'>
              <div className='flex-shrink-0'>
                <div className={classNames('h-8 w-8', iconColor)}>{iconComponent}</div>
              </div>
              <div className='ml-3 w-0 flex-1 pt-0.5'>
                <p className='text-lg text-gray-800'>{props.title}</p>
                {props.description && <p className='mt-1 text-gray-500'>{props.description}</p>}
                {props.refreshButton && (
                  <Button
                    variant='white'
                    className='mt-4'
                    icon={<ArrowPathIcon className='h-5 w-5' aria-hidden='true' />}
                    onClick={() => {
                      location.reload()
                    }}>
                    Reload
                  </Button>
                )}
              </div>

              {props.onDismiss && (
                <div className='ml-4 flex-shrink-0 flex'>
                  <button
                    className='bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={(e) => {
                      e.preventDefault()
                      setShow(false)
                      props.onDismiss()
                    }}>
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              )}
            </div>
          </div>
        </a>
      </Transition>
    </>
  )
}

export default Notification
