import { Moment } from "moment"
import IInvoice from "../interfaces/IInvoice"
import { get, patch, post, transformRequestData } from "./utils/requests"
import InvoiceDecorator from "../decorators/InvoiceDecorator"
import { LineItemParams, OrderParams } from "./orders"
import { DeepCamelCaseKeys } from "../types/utilities"

const fetchForDates = async (
  campId: string,
  data: {
    from: Moment,
    until: Moment,
    filter: string,
    include_single_reservations?: boolean,
    include_seasonal_reservations?: boolean,
    includeArchived?: boolean
  }
): Promise<{ invoices: InvoiceDecorator[] }> =>
  get(`/api/camps/${campId}/invoices`, transformRequestData(data))
    .then(({ invoices }: { invoices: IInvoice[] }) =>
      Promise.resolve({ invoices: invoices.map((inv) => new InvoiceDecorator(inv))})
    )

const createForReservation = async (reservationId: string): Promise<{ invoice: IInvoice }> =>
  post(`/api/reservations/${reservationId}/invoices`)
    .then(({ invoice }: { invoice: IInvoice }) =>
      Promise.resolve({ invoice: new InvoiceDecorator(invoice) })
    )

interface UpdateData {
  isSending: boolean
  sendingMode: string
  admin_email?: string
  sendDate: Moment
  dueDate: Moment
  recipientEmail: string
  recipientPhone: string
  recipientName: string
  recipientAddress: string
  memo: string
  lastMeterReading: number
  meterReading: number
  lineItemsAttributes: DeepCamelCaseKeys<LineItemParams>[]
}

const update = async (
  id: string,
  campId: string,
  data: UpdateData
): Promise<{ invoice: IInvoice }> =>
  patch(`/api/camps/${campId}/invoices/${id}`, transformRequestData(data))
    .then(({ invoice }: { invoice: IInvoice }) =>
      Promise.resolve({ invoice: new InvoiceDecorator(invoice) })
    )

const updateMany = async (
  campId: string,
  data: {
    invoices: {
      invoiceId: string
      sendingMode: string
      isSending: boolean
    }[]
  }
): Promise<{ invoices: IInvoice[], records?: (IInvoice & { errors: string[] })[] }> =>
  patch(`/api/camps/${campId}/invoices/batch`, transformRequestData(data))
    .then(({ invoices }: { invoices: IInvoice[] }) =>
      Promise.resolve({ invoices: invoices.map((inv) => new InvoiceDecorator(inv)) })
    )

const Invoices = {
  createForReservation,
  fetchForDates,
  update,
  updateMany
}

export default Invoices
