import React, { createContext, useContext, useEffect, useState } from 'react'
import { ReservationProps } from '../../interfaces'
import ReservationInvoiceWrapper from '../../components/helpers/ReservationInvoiceWrapper';
import ContextMenu, {
  ContextMenuDivider,
  ContextMenuItem,
} from '../../components/ui/Admin/ContextMenu'
import { Menu } from '@headlessui/react'
import classNames from '../../utils/classNames'
import { AdminContext } from '../AdminContext'
import api from '../../api2'
import { RESERVATION_KINDS, STATUSES, reservationStatus } from '../../constants'
import DeleteConfirmationModalContent from '../../components/Admin/Reservations/DeleteConfirmationModalContent'
import {
  CheckBadgeIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/20/solid'
import InvoicesModalContent from '../../components/Admin/Invoices/InvoicesModalContent'
import EditInvoice from '../../components/Admin/Invoices/EditInvoice'
import ReservationDecorator from '../../decorators/ReservationDecorator'

interface ICtxPayload {
  showContextMenu: (
    reservationState: { persisted: ReservationProps; updated?: ReservationProps },
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => any
}

const initialContext = {
  showContextMenu: null,
}

export const ReservationContextMenuContext = createContext<ICtxPayload>(initialContext)

export const ReservationContextMenuProvider = ({ children }: { children: any }) => {
  const {
    camp,
    campingStyles,
    setSelectedReservation,
    setCurrentPage,
    setSelectedCamper,
    updateLocalReservations,
    showNotification,
    showModal,
    closeModal,
    showPaymentModal,
    showMessageModal,
  } = useContext(AdminContext)
  const [reservation, setReservation] = useState<ReservationProps>()
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onClose = () => setIsOpen(false)

  const showContextMenu = (
    reservationState: { persisted: ReservationProps; updated?: ReservationProps },
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setReservation(reservationState.persisted)
    setMenuPosition({ x: e.clientX, y: e.clientY })
    setIsOpen(true)
  }

  const onClickEdit = () => {
    setSelectedReservation(reservation)
    onClose()
  }

  const onClickViewCamper = () => {
    // TODO: make sure camper is accessible
    setSelectedCamper(reservation?.user?.camper)
    onClose()
  }

  const onClickCheckin = () => {
    setIsLoading(true)

    let is_checked_in: boolean = false
    let is_checked_out: boolean = false

    if (!reservation?.is_checked_in && !reservation?.is_checked_out) {
      is_checked_in = true
      is_checked_out = false
    } else if (reservation?.is_checked_in && !reservation?.is_checked_out) {
      is_checked_in = true
      is_checked_out = true
    } else if (reservation?.is_checked_in && reservation?.is_checked_out) {
      is_checked_in = false
      is_checked_out = false
    }

    api.Reservations.update(reservation.id, { is_checked_in, is_checked_out })
      .then(({ reservation: updated }) => {
        updateLocalReservations([updated])
        setIsLoading(false)
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        showNotification({
          type: 'error',
          title: 'Could not mark as checked-in',
          description: err.message,
        })
      })
  }

  const onClickSendInvoice = () => {
    showModal(
      <ReservationInvoiceWrapper reservation={reservation} order={reservation.order}>
        {({ invoice }) => (
          <EditInvoice
            invoice={invoice}
            site={reservation.site}
            campingStyle={campingStyles.find((cs) => cs.id === reservation.site.camping_style_id)}
            onSuccess={(invoice) => closeModal()}
            isHidingBackButton
          />
        )}
      </ReservationInvoiceWrapper>,
      true,
      'max-w-7xl'
    )
  }

  const onClickMarkPaid = () => {
    setIsLoading(true)
    api.Reservations.update(reservation.id, { status: STATUSES.PAID })
      .then(({ reservation: updated }) => {
        updateLocalReservations([updated])
        setIsLoading(false)
        onClose()
      })
      .catch((err) => {
        setIsLoading(false)
        showNotification({
          type: 'error',
          title: 'Could not mark as checked-in',
          description: err.message,
        })
      })
  }

  const onClickAcceptPayment = () => {
    const decoratedReservation = new ReservationDecorator(reservation)
    showPaymentModal(decoratedReservation.remainingOwedBeforeFeesInCents, { reservation })
    onClose()
  }

  const onClickDelete = () => {
    if (reservation.status !== STATUSES.BLOCKED) {
      showModal(<DeleteConfirmationModalContent reservation={reservation} />)
    } else {
      setIsLoading(true)
      api.Reservations.remove(reservation.id)
        .then(({ reservations }) => {
          updateLocalReservations(reservations)
          setIsLoading(false)
          onClose()
        })
        .catch((err) => {
          setIsLoading(false)
          showNotification({
            type: 'error',
            title: err.message,
            dismissAfter: 7000,
          })
        })
    }
  }

  const isNonReservation = ([STATUSES.BLOCKED, STATUSES.IMPORTED] as reservationStatus[]).includes(
    reservation?.status
  )
  const isRecurring = reservation?.kind === RESERVATION_KINDS.RECURRING
  const isUnpaid = reservation?.status === STATUSES.UNPAID

  return (
    <ReservationContextMenuContext.Provider value={{ showContextMenu }}>
      <ContextMenu
        menuX={menuPosition.x}
        menuY={menuPosition.y}
        isOpen={isOpen}
        onClose={onClose}
        isDisabled={isLoading}>
        <ContextMenuItem onClick={onClickEdit} icon={PencilSquareIcon}>
          Edit reservation
        </ContextMenuItem>

        {!isNonReservation && (
          <ContextMenuItem onClick={onClickCheckin} icon={CheckBadgeIcon}>
            {reservation?.is_checked_out
              ? 'Remove checked status'
              : reservation?.is_checked_in
              ? 'Mark as checked-out'
              : 'Mark as checked-in'}
          </ContextMenuItem>
        )}
        {reservation?.user?.camper && (
          <>
            <ContextMenuDivider />
            <ContextMenuItem
              onClick={() => {
                setSelectedCamper(reservation?.user?.camper)
                setCurrentPage('guests')
              }}
              icon={UserIcon}>
              View camper
            </ContextMenuItem>
          </>
        )}
        {(reservation?.user?.email || reservation?.user?.phone) && (
          <ContextMenuItem
            onClick={() => showMessageModal({ reservations: [reservation] })}
            icon={EnvelopeIcon}>
            Send message
          </ContextMenuItem>
        )}

        <ContextMenuDivider />
        {isRecurring ? (
          <ContextMenuItem onClick={onClickSendInvoice} icon={DocumentTextIcon}>
            Edit invoice
          </ContextMenuItem>
        ) : (
            reservation?.status != STATUSES.BLOCKED && reservation?.status != STATUSES.IMPORTED && reservation?.status != STATUSES.BLOCKED_IMPORT
           && (
            <ContextMenuItem
              onClick={() => window.open(`/i/${reservation.hashed_id}`)}
              icon={DocumentTextIcon}>
              View invoice
            </ContextMenuItem>
          )
        )}

        {isUnpaid && (
          <>
            <ContextMenuItem onClick={onClickMarkPaid} icon={CurrencyDollarIcon}>
              Mark as paid
            </ContextMenuItem>
            <ContextMenuItem onClick={onClickAcceptPayment} icon={CreditCardIcon}>
              Accept payment
            </ContextMenuItem>
            <ContextMenuDivider />
          </>
        )}
        <ContextMenuItem onClick={onClickDelete} icon={TrashIcon} className='text-red-500'>
          Delete reservation
        </ContextMenuItem>
      </ContextMenu>
      {children}
    </ReservationContextMenuContext.Provider>
  )
}
