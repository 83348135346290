import React, { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import classNames from '../../utils/classNames'
import { ReservationProps } from '../../interfaces'
import { UserProps } from '../../interfaces'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { titleCase } from '../../utils'
import moment from 'moment'
import Spinner from '../ui/Spinner'
import { AdminContext } from '../../contexts/AdminContext'
import { DocumentIcon, UserIcon, UserGroupIcon, RectangleStackIcon } from '@heroicons/react/24/outline'
import ICamper from '../../interfaces/ICamper'
import { formatName } from '../../utils/formatName'
import formatDateRange from '../../utils/admin/formatDateRange'
import GroupModalContents from './GroupedReservations/GroupModalContents'
import { STATUSES } from '../../constants'

enum eResultItemTypes {
  Camper = 'camper',
  Reservation = 'reservation',
  Group = 'group'
}

interface resultItems {
  type: eResultItemTypes
  item: ICamper | ReservationProps | ReservationProps[]
  id: string
  sortString: string
}

interface ComponentProps {
  visible: boolean
  searchResults: UserProps[]
  searchStatus: { status: 'waiting' | 'loading' | 'error', message: string }
}

const SearchDropDown = (props: ComponentProps) => {
  const stateMessage = (state: { status: 'waiting' | 'loading' | 'error', message: string }) => {
    switch(state.status) {
      case 'waiting':
      case 'loading':
        return <SearchStateMessage title='Loading' message={state.message} />
      case 'error':
        return <SearchStateMessage title='Uh-Oh!' message={state.message} />
      default:
        return <p>Type to start searching for a reservation.</p>
    }
  }

  let resultItems: resultItems[] = []

  props.searchResults?.forEach?.((user) => {
    if (user?.camper && !resultItems.find((result) => result.id === user.camper.id)) {
      resultItems.push({
        type: eResultItemTypes.Camper,
        item: user.camper,
        id: user.camper.id,
        sortString: formatName(user.camper)
      })
    }

    const groupedReservations = user.reservations.reduce((acc, reservation) => {
      if (reservation.grouped_reservation_id) {
        console.log("processing reservation", reservation?.id)
        if (!acc[reservation.grouped_reservation_id]) {
          acc[reservation.grouped_reservation_id] = [reservation]
        } else {
          acc[reservation.grouped_reservation_id].push(reservation)
          acc[reservation.grouped_reservation_id] = acc[reservation.grouped_reservation_id].filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            ))
          )
        }
      } else {
        acc["nonGroup"].push(reservation)
      }

      return acc
    }, { nonGroup: [] } as Record<string, ReservationProps[]>)

    Object.entries(groupedReservations).forEach(([groupedReservationId, reservations]) => {
      if (groupedReservationId === "nonGroup") {
        resultItems.push(...reservations.map((reservation) => ({
          type: eResultItemTypes.Reservation,
          item: reservation,
          id: reservation.id,
          sortString: reservation.start_date + reservation.site.name
        })))
      } else {
        const minStartDate = reservations.reduce((minDate, reservation) => {
          return minDate < reservation.start_date ? minDate : reservation.start_date
        }, reservations[0].start_date)

        resultItems.push({
          type: eResultItemTypes.Group,
          item: reservations,
          id: groupedReservationId,
          sortString: minStartDate
        })
      }
    })
  })

  return (
    <Menu as='div' className='z-40 w-full'>
      {props.visible && <Transition
        as={Fragment}
        show={props.visible}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <div
          className={classNames(
            (!props.searchResults || props.searchResults.length === 0) && 'items-center',
            'absolute w-full h-96 flex justify-center rounded-md shadow-lg',
            'bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll'
          )}>
          {!props.searchResults || props.searchStatus.status ? (
            stateMessage(props.searchStatus)
          ) : (
            <SearchResults resultItems={resultItems} />
          )}
        </div>
      </Transition>}
    </Menu>
  )
}

const ResultCamper = ({ camper }: { camper: ICamper }) => {
  const { setCurrentPage, setSelectedCamper } = useContext(AdminContext)

  const handleClick = (e) => {
    setSelectedCamper(camper)
    setCurrentPage('guests')
  }

  return (
    <Menu.Item
      key={camper.id}
      as='a'
      onMouseDown={handleClick}
      className='py-2 hover:bg-gray-50 cursor-pointer flex justify-between'>
      <div className='flex-grow flex items-center'>
        <UserIcon className='h-6 w-6 mr-2' />
        <div className='text-sm font-medium text-gray-900 mr-3'>{formatName(camper)}</div>
        <div className='text-sm text-gray-500 mr-3'>{camper.email}</div>
        <div className='text-sm text-gray-500 italic'>{camper.phone}</div>
      </div>
      <ChevronRightIcon className='h-5 w-5 text-gray-400 mr-1' aria-hidden='true' />
    </Menu.Item>
  )
}

const ResultReservation = ({ reservation, isTabbed }: { reservation: ReservationProps, isTabbed?: boolean }) => {
  const { setCurrentPage, setSelectedReservation } = useContext(AdminContext)

  const handleClick = (e) => {
    setSelectedReservation(reservation)
    setCurrentPage('reservations')
  }

  return (
    <Menu.Item
      key={reservation.id}
      as="a"
      onMouseDown={handleClick}
      className="py-2 hover:bg-gray-50 cursor-pointer flex justify-between">
      <div className='flex-grow flex items-center'>
        {isTabbed && <div className="ml-1 mr-3 text-gray-200">•</div>}
        <DocumentIcon className='h-6 w-6 mr-2' />
        <div className='text-sm font-medium text-gray-900 mr-4'>{reservation.site.name}</div>
        <div className='text-sm text-gray-500 mr-4'>
          {reservation.user?.camper && formatName(reservation.user?.camper)}
        </div>
        <div className='text-sm text-gray-500 mr-4 italic'>
          {formatDateRange(reservation.start_date, reservation.end_date)}
        </div>
        <StatusBadge {...reservation} />
      </div>
      <ChevronRightIcon className='h-5 w-5 text-gray-400 mr-1' aria-hidden='true' />
    </Menu.Item>
  )
}

const ResultGroup = ({ reservations }: { reservations: ReservationProps[] }) => {
  const { showModal } = useContext(AdminContext)

  const groupedReservationId = reservations[0].grouped_reservation_id
  const firstNamedReservation = reservations.find(reservation => {
      const formattedName = formatName(reservation.user?.camper)
      return formattedName !== ""
  })
  const fullName = firstNamedReservation ? formatName(firstNamedReservation.user?.camper) : ""
  const [minStartDate, maxEndDate] = reservations.reduce(
    ([minStart, maxEnd], reservation) => {
      const { start_date, end_date } = reservation
      return [
        minStart < start_date ? minStart : start_date,
        maxEnd > end_date ? maxEnd : end_date
      ]
    },
    [reservations[0].start_date, reservations[0].end_date]
  )

  const maxDeletedAt = reservations.reduce((deleted_at, reservation) => {
    return deleted_at > reservation.deleted_at ? deleted_at : reservation.deleted_at
  }, reservations[0].deleted_at)

  const status = reservations.every((r) => r.status === STATUSES.PAID) ? STATUSES.PAID : STATUSES.UNPAID

  const handleClick = () => {
    showModal(<GroupModalContents groupedReservationId={groupedReservationId} />, true, "max-w-3xl")
  }

  return (
    <>
      <Menu.Item
        key={groupedReservationId}
        as='a'
        onMouseDown={handleClick}
        className='py-2 hover:bg-gray-50 cursor-pointer flex justify-between'>
        <div className='flex-grow flex items-center'>
          <RectangleStackIcon className='h-6 w-6 mr-2' />
          <div className='text-sm font-medium text-gray-900 mr-4'>Group</div>
          {fullName && (
            <div className='text-sm text-gray-500 mr-4'>
              {fullName}
            </div>
          )}
          <div className='text-sm text-gray-500 mr-4 italic'>
            {formatDateRange(minStartDate, maxEndDate)}
          </div>
          <StatusBadge {...{ deleted_at: maxDeletedAt, status }} />
        </div>
        <ChevronRightIcon className='h-5 w-5 text-gray-400 mr-1' aria-hidden='true' />
      </Menu.Item>
      {reservations.sort((a, b) => a.site?.name > b.site?.name ? 1 : -1).map((reservation, index) => (
        <ResultReservation reservation={reservation} isTabbed />
      ))}
    </>
  )
}

const StatusBadge = (reservation: ReservationProps) => {
  return (
    <div className="ml-2 flex-shrink-0 flex">
      {reservation.deleted_at ? (
        <span
          className={classNames(
            'px-3 py-0.5 inline-flex text-sm leading-5 font-semibold rounded-full',
            'bg-red-100 text-red-800'
          )}
        >
          Deleted
        </span>
      ) : (
        <span
          className={classNames(
            'px-3 py-0.5 inline-flex text-sm leading-5 font-semibold rounded-full',
            reservation.status == 'paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
          )}
        >
          {titleCase(reservation.status)}
        </span>
      )}
    </div>
  )
}

interface searchResultsProps {
  resultItems: resultItems[]
}

const SearchResults = ({ resultItems }: searchResultsProps) => {
  enum ResultTypeLabels {
    Guests = 'GUESTS',
    Reservations = 'RESERVATIONS'
  }

  const compiledList: {
    title: string,
    list: resultItems[],
    max_results: number
  }[] = [
    {
      title: ResultTypeLabels.Guests,
      list: resultItems.filter(({ type }) => type === eResultItemTypes.Camper),
      max_results: 5
    },
    {
      title: ResultTypeLabels.Reservations,
      list: resultItems.filter(({ type }) => type !== eResultItemTypes.Camper),
      max_results: 50
    },
  ].filter(({ list }) => list.length > 0)

  if (resultItems.filter((item) => item).length === 0) {
    return (
      <div>
        No results found.
      </div>
    )
  }

  return (
    <Menu.Items as='ul' className='flex-grow mb-4 mx-4 sm:mx-8 md:mx-14 lg:mx-20 xl:mx-28 2xl:mx-44'>
      {compiledList.map(({ title, list, max_results }) => (
        <div key={title} className='mb-4'>
          <div className='text-md font-medium text-gray-900 my-2'>{title}</div>
          {list.sort((a, b) => a.sortString < b.sortString ? 1 : -1).slice(0, max_results).map(({ item, type, id }, index) => {
            if (type === eResultItemTypes.Camper) {
              return <ResultCamper key={index} camper={item as ICamper} />
            } else {
              if (type === eResultItemTypes.Group) {
                return <ResultGroup key={index} reservations={item as ReservationProps[]} />
              } else {
                return <ResultReservation key={index} reservation={item as ReservationProps} />
              }
            }
          })}
          {list.length > max_results && (
            <div className='text-xs text-gray-400 my-2'>
              And {list.length - max_results} more...
            </div>
          )}
        </div>
      ))}
    </Menu.Items>
  )
}

const SearchStateMessage = ({ title, message }) => {
  return (
    <div className='flex flex-col text-center justify-center justify-items-center'>
      <div className='flex flex-row justify-center'>
        {title == 'Loading' && <Spinner size={8} className='mb-4' />}
      </div>
      <span className='text-lg font-bold'>{title}</span>
      <span className=''>{message}</span>
    </div>
  )
}

export default SearchDropDown
