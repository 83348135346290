/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useContext, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { ReservationProps } from '../../interfaces';
import SelectInput, {Option} from '../ui/SelectInput';
import { sendingOptions } from './sendingOptions';
import { AdminContext } from '../../contexts/AdminContext';
import Button from '../ui/Button';

interface ComponentProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  content: null | string | number | JSX.Element | Array<JSX.Element>;
  onConfirm: any;
  updatedReservations: ReservationProps[]
}

export default function ConfirmChanges({
  open,
  setOpen,
  content,
  onConfirm,
  updatedReservations
}: ComponentProps) {
  const cancelButtonRef = useRef(null)
  const { selectedSendingMode, setSelectedSendingMode } = useContext(AdminContext)

  if(!open) return <></>   

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-40 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-min sm:w-full'>
              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationCircleIcon className='h-6 w-6 text-yellow-600' aria-hidden='true' />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title className='text-lg leading-6 font-medium text-gray-900'>
                      Update Reservations
                    </Dialog.Title>
                    <div className='mt-2'>{content}</div>
                  </div>
                </div>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:px-6 sm:flex sm:flex-row justify-end items-center'>
                <div className='mr-0.5 overflow-visible'>
                  <SelectInput
                    name='sendingOptions'
                    width='w-72'
                    isBottomPlacement
                    large
                    options={sendingOptions}
                    selected={sendingOptions.find((o) => o.value === selectedSendingMode)}
                    setSelected={(o: Option) => setSelectedSendingMode(o.value)}
                  />
                </div>
                <Button
                  type='button'
                  variant='gray'
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}>
                  Cancel
                </Button>
                <Button
                  type='button'
                  variant='blue'
                  onClick={onConfirm}>
                  Update
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
