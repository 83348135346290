import consumer from "./consumer"

export default () => consumer.subscriptions.create("ReservationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log('in original connection!')
  },
  
  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log('Connection has been disconnected!')
  },

  rejected() {
    // console.log('Connection has been rejected!')
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log('in original received with data', data)
  }
})
