import React, { useContext, useState } from 'react'
import { FormContext } from '../../../../contexts/FormContext'
import classNames from '../../../../utils/classNames'

const TextAreaInput = ({
  field,
  label,
  className,
  disabled,
  placeholder,
  asText,
  rows,
}: {
  field: string
  label?: string
  className?: string
  disabled?: boolean
  placeholder?: string
  asText?: boolean
  rows?: number
}) => {
  const { resource, register, isChanged, watch, errors, clearError } = useContext(FormContext)
  const { onChange: registeredOnChange, ...registered } = register(field)

  if (asText) {
    return <div className={classNames(className, 'flex items-center')}>{watch(field)}</div>
  }

  return (
    <div className={classNames('mb-2', className)}>
      <div className={classNames(className, disabled && 'opacity-30')}>
        {label && (
          <label
            htmlFor='name'
            className={classNames(
              'mb-1 block font-medium text-md',
              errors[field] ? 'text-red-600' : 'text-gray-900'
            )}>
            {label}
            {errors[field] && (
              <span className='ml-1 text-red-500 text-xs'>{errors[field].message}</span>
            )}
          </label>
        )}
        <div className=''>
          <textarea
            {...registered}
            onChange={(e) => {
              if (errors[field]) clearError(field)
              registeredOnChange(e)
            }}
            rows={rows || 3}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(
              'block w-full rounded-md p-2 border shadow-sm focus:border-green-700 focus:ring-green-700',
              errors[field] ? 'border-red-600' : 'border-gray-300',
              isChanged(field) ? 'bg-blue-50' : 'bg-white'
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default TextAreaInput
