export default async (error: Response) => {
  if (typeof error.json === "function") {
    let result: any

    await error
      .json()
      .then(jsonError => {
        // console.log("[DEBUG] Json error from API")
        // console.log(jsonError)

        result = jsonError
      })
      .catch(genericError => {
        // console.log("[DEBUG] Generic error from API")
        // console.log(error.statusText)

        let status = error.status

        if (error.statusText.toLowerCase().includes('internal server error')) {
          result = 'Something unexpected went wrong. If this persists, please reach out to Park Support at support@poweredbypark.com'
        } else {
          result = error.statusText
        }

        result = { message: result, status: status }
      })

    return Promise.reject(result)
  } else {
    // console.log("[DEBUG] Fetch error")
    // console.log(error)

    return Promise.reject({ message: error })
  }
}
