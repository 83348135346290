import moment, { Moment } from 'moment'

const formatDateRange = (start_date: string | Moment, end_date: string | Moment) => {
  const startDate = moment(start_date)
  const endDate = moment(end_date)

  return startDate.isSame(endDate, 'year')
    ? `${startDate.format('MMM Do')} - ${endDate.format('MMM Do, YYYY')}`
    : `${startDate.format('MMM Do YYYY')} - ${endDate.format('MMM Do, YYYY')}`
}

export default formatDateRange
