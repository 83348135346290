import { UserProps } from '../interfaces'
import ICamper from '../interfaces/ICamper'
import titleCase from './titleCase'

export const formatName = (user: UserProps | ICamper) => {
  let ret = ''

  if (user?.first_name != null) {
    ret += titleCase(user?.first_name) + ' '
  }

  if (user?.last_name != null) {
    ret += titleCase(user?.last_name)
  }

  if (!user?.first_name && !user?.last_name) {
    ret = user?.email
  }

  return ret
}

export const formatNameFromFirstLast = (first_name, last_name) => {
  let ret = ''

  if (first_name != null) {
    ret += titleCase(first_name) + ' '
  }

  if (last_name != null) {
    ret += titleCase(last_name)
  }

  return ret
}
