import React, { useContext } from 'react'
import { ReservationProps } from '../../../../interfaces'
import ICamper from '../../../../interfaces/ICamper'
import moment from 'moment'
import pluralize from '../../../../utils/pluralize'
import { CamperBlocked } from '../../../ui/StatusBadges'
import { formatName } from '../../../../utils/formatName'
import createMarkup from '../../../../utils/createMarkup'
import avatarFor from '../../../../utils/avatarFor'
import generateAvatar from '../../../../utils/generateAvatar'
import generateConsistentNaturalHexColor from '../../../../utils/generateNaturalHexColor'
import { formatPhoneNumber } from '../../../../utils'
import { AdminContext } from '../../../../contexts/AdminContext'

export const checkIsCamperEmpty = (camper: ICamper) => {
  if (!camper) return true

  return (
    !camper?.first_name &&
    !camper?.last_name &&
    !camper?.email &&
    !camper?.phone &&
    !camper?.address &&
    !camper?.vehicle_rig_length &&
    !camper?.vehicle_year &&
    !camper?.vehicle_color &&
    !camper?.vehicle_make &&
    !camper?.vehicle_rig_type &&
    !camper?.vehicle_electric &&
    !camper?.license_plate &&
    !camper?.pets_info &&
    !camper?.notes
  )
}

const hasAdditionalVehicleInfo = (camper: ICamper) => {
  return (
    camper.additional_vehicle_make ||
    camper.additional_vehicle_year ||
    camper.additional_vehicle_color ||
    camper.additional_vehicle_license_plate
  )
}

const hasEmergencyContactInfo = (camper: ICamper) => {
  return (
    camper.emergency_contact_name ||
    camper.emergency_contact_relationship ||
    camper.emergency_contact_email ||
    camper.emergency_contact_phone
  )
}

interface ComponentProps {
  camper: ICamper
  setSelectedIndex?: (index: number) => void
  isBlocked: boolean
  reservation?: ReservationProps
  isCamperLoading?: boolean
}

const ProfileCard = ({ camper: oldCamper, reservation, setSelectedIndex, isBlocked, isCamperLoading }: ComponentProps) => {
  const { selectedCamper, setSelectedCamper, setCurrentPage } = useContext(AdminContext)
  const camper = selectedCamper || oldCamper

  if(isCamperLoading){
    return (
      <div className='flex flex-row items-center bg-white rounded-lg border border-dotted border-gray-200 px-4.5 py-4.5 animate-pulsing'>
        <div className='w-16 h-16 mr-4 flex-shrink-0 rounded-full bg-gray-200' />
        <div className='flex flex-col space-y-3 w-full'>
          <div className='flex justify-between'>
            <div className='flex flex-col space-y-1 w-full'>
              <div className='bg-gray-200 font-medium h-5 rounded-md w-full'></div>
              <div className='bg-gray-200 h-5 rounded-md w-1/2'></div>
              <div className='bg-gray-200 h-5 rounded-md w-1/3'></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!camper || checkIsCamperEmpty(camper)) {
    return (
      <div className='flex flex-col items-center bg-white rounded-lg border border-gray-200 px-4.5 py-4.5'>
        <div className='text-gray-300 text-md'>No camper information to show</div>
      </div>
    )
  }

  const mostRecentReservation =
    reservation ||
    (camper.reservations || []).sort((a, b) => {
      const momentA = moment(a.created_at)
      const momentB = moment(b.created_at)

      if (momentA.isBefore(momentB)) {
        return 1
      } else if (momentA.isAfter(momentB)) {
        return -1
      } else {
        return 0
      }
    })[0]

  let vehicleInfo = [
    camper.vehicle_rig_length &&
      (camper.vehicle_rig_length.includes('ft')
        ? camper.vehicle_rig_length
        : camper.vehicle_rig_length + ' ft'),
    camper.vehicle_year && camper.vehicle_year,
    camper.vehicle_color && camper.vehicle_color,
    camper.vehicle_make && camper.vehicle_make,
  ]
    .filter((x) => x)
    .join(' ')

  if (camper.vehicle_rig_type && camper.vehicle_electric) {
    vehicleInfo += ' - ' + camper.vehicle_rig_type + ', ' + camper.vehicle_electric + '-amp'
  } else if (camper.vehicle_rig_type) {
    vehicleInfo += ' - ' + camper.vehicle_rig_type
  } else if (camper.vehicle_electric) {
    vehicleInfo += ' - ' + camper.vehicle_electric + '-amp'
  }

  if (vehicleInfo.startsWith(' - ')) {
    vehicleInfo = vehicleInfo.slice(3)
  }

  const formatPetsInfo = (petsInfo: string) => {
    if (/^\d+$/.test(petsInfo)) {
      return petsInfo + pluralize(' pet', Number(petsInfo))
    } else {
      return petsInfo
    }
  }

  const additionalVehicleInfo = [
    camper.additional_vehicle_make && camper.additional_vehicle_make,
    camper.additional_vehicle_year && camper.additional_vehicle_year,
    camper.additional_vehicle_color && camper.additional_vehicle_color,
  ]
    .filter((x) => x)
    .join(' ')

  const passengerInfo = mostRecentReservation || camper.pets_info
    ? [
        mostRecentReservation?.passengers &&
          mostRecentReservation?.passengers +
            (mostRecentReservation?.kid_campers
              ? pluralize(' adult', mostRecentReservation?.passengers)
              : pluralize(' passenger', mostRecentReservation?.passengers)),
        mostRecentReservation?.kid_campers &&
          mostRecentReservation?.kid_campers +
            pluralize(' child', mostRecentReservation?.kid_campers),
        formatPetsInfo(camper.pets_info),
      ]
        .filter((x) => x)
        .join(', ')
    : ''
  
  return (
    <div
      className='flex flex-row bg-white rounded-lg border border-gray-200 px-4.5 pt-4 pb-3.5 cursor-pointer hover:bg-gray-50'
      onClick={() => {
        setSelectedCamper(camper)
        setCurrentPage('guests')
      }}>
      <div className='w-16 h-16 mr-4 flex-shrink-0'>
        {avatarFor(
          generateAvatar(camper.first_name + camper.last_name || camper.email || camper.phone),
          generateConsistentNaturalHexColor(
            camper.first_name + camper.last_name || camper.email || camper.phone
          )
        )}
      </div>
      <div className='flex flex-col space-y-3 w-full'>
        <div className='flex justify-between'>
          <div className='flex flex-col space-y-1'>
            <div className='text-gray-800 text-lg leading-5 font-medium'>{formatName(camper)}</div>
            {isBlocked && <CamperBlocked />}
            <div className='text-gray-450 text-md leading-4'>{camper.email}</div>
            <div className='text-gray-450 text-md leading-4'>{formatPhoneNumber(camper.phone)}</div>
            <div className='text-gray-450 text-md leading-4'>{camper.address}</div>
            <div className='text-gray-450 text-md leading-4'>{camper.company_name}</div>
          </div>
        </div>

        {vehicleInfo && (
          <>
            <div className='w-full border-b border-gray-200' />
            <div className='flex flex-col text-md leading-5'>
              <div>{vehicleInfo}</div>
              {camper.license_plate && <div>License #: {camper.license_plate}</div>}
              {passengerInfo && <div>{passengerInfo}</div>}
            </div>
          </>
        )}

        {hasAdditionalVehicleInfo(camper) && (
          <>
            <div className='w-full border-b border-gray-200' />
            <div className='flex flex-col text-md leading-5'>
              <div>{additionalVehicleInfo}</div>
              {camper.additional_vehicle_license_plate && (
                <div>License #: {camper.additional_vehicle_license_plate}</div>
              )}
            </div>
          </>
        )}

        {hasEmergencyContactInfo(camper) && (
          <>
            <div className='w-full border-b border-gray-200' />
            <div className='flex flex-col text-md leading-5'>
              <div>
                Emergency Contact: {camper.emergency_contact_name}
                {camper.emergency_contact_relationship &&
                  ' - ' + camper.emergency_contact_relationship}
              </div>
              {camper.emergency_contact_email && <div>{camper.emergency_contact_email}</div>}
              {camper.emergency_contact_phone && (
                <div>{formatPhoneNumber(camper.emergency_contact_phone)}</div>
              )}
            </div>
          </>
        )}

        {camper.notes && (
          <>
            <div className='w-full border-b border-gray-200' />
            <div className='flex flex-col text-md leading-5 notes-text'>
              <div dangerouslySetInnerHTML={createMarkup(camper.notes)} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProfileCard
