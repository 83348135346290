import React, { useContext, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { AdminContext } from '../../../contexts/AdminContext'
import { adminPages } from '../../../types/adminPages'
import ADMIN_NAVBAR_ITEMS from '../../../constants/admin_navbar_items'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface ComponentProps {
  handleLogout: (string) => void
  campName: string
  campSlug: string
}

export default function AdminDropdown(props: ComponentProps) {
  const {
    currentPage,
    setCurrentPage,
    setCamp,
    accessibleCamps,
    reloadAccessibleCamps,
    currentUserEmail,
    currentUserRole,
  } = useContext(AdminContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleAccountSwitch = (id: string) => {
    setIsLoading(true)
    setCamp(accessibleCamps.find((camp) => camp.id === id))
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    reloadAccessibleCamps().then(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex rounded-md border border-gray-300 bg-white items-center overflow-visible px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 open-sans'>
          <div className='w-32 text-left xsm:w-full truncate'>{props.campName}</div>
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items className='absolute right-0 z-40 mt-2 origin-top-right w-80 divide-y divide-gray-100 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='px-4 py-3'>
            <p className='text-md'>Signed in as</p>
            <p className='truncate text-md font-medium text-gray-900'>{currentUserEmail}</p>
          </div>
          <div className='lg:hidden py-1'>
            {ADMIN_NAVBAR_ITEMS.map((item) => {
              const isCurrent = currentPage == item.key
              if (!(currentUserRole == 'user' && item.key == 'reports')) {
                return (
                  <Menu.Item>
                    {({ close }) => (
                      <a
                        key={item.name}
                        onClick={(e) => {
                          close()
                          e.preventDefault()
                          setCurrentPage(item.key)
                        }}
                        href={isCurrent ? null : item.href}
                        target={isCurrent ? null : '_blank'}
                        className={classNames(
                          isCurrent
                            ? 'bg-gray-100 text-gray-900 hover:text-gray-900'
                            : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900',
                          'px-4 py-3 text-md flex'
                        )}>
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                )
              }
            })}
          </div>
          <div className='py-1 overflow-auto max-h-64'>
            {accessibleCamps.map((camp) => {
              if (camp.name != props.campName) {
                return (
                  <Menu.Item>
                    {({ active, close }) => (
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          if (!isLoading) handleAccountSwitch(camp.id)
                          close()
                        }}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          isLoading && 'text-opacity-30',
                          'block px-4 py-3 text-md'
                        )}>
                        Switch to <span className='font-medium'>{camp.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                )
              }
            })}

            {currentUserRole == 'admin' && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => {
                      location.href = '/admin/settings'
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-3 text-md cursor-pointer'
                    )}>
                    Settings
                  </a>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/camps/${props.campSlug}`}
                  target='_blank'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-md'
                  )}>
                  Booker
                  <ArrowTopRightOnSquareIcon className='inline h-4 mr-2 ml-1.5 -mt-0.5' />
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`https://help.poweredbypark.com/`}
                  target='_blank'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-md'
                  )}>
                  Help Center
                  <ArrowTopRightOnSquareIcon className='inline h-4 mr-2 ml-1.5 -mt-0.5' />
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => {
                    const mailtoText =
                      'mailto:support@poweredbypark.com?subject=Support ticket for ' +
                      props.campName
                    const el = document.createElement('a')
                    el.href = mailtoText
                    el.target = '_blank'
                    el.click()
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-3 text-md cursor-pointer'
                  )}>
                  Submit a Support Ticket
                  <div className='flex items-center text-gray-400 -mt-0.5'>
                    <EnvelopeIcon className='h-4 mr-1.5' />
                    <div className='text-xssm'>support@poweredbypark.com</div>
                  </div>
                </a>
              )}
            </Menu.Item>
          </div>
          <div className='py-1'>
            <form method='POST' action='#'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type='submit'
                    onClick={props.handleLogout}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-3 text-left text-md open-sans'
                    )}>
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
