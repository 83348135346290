import React, { useEffect, useState } from 'react'
import { CampingStyleProps, CampProps, ReservationProps, SiteProps } from '../interfaces'
import Navbar from './Admin/Navigation/Navbar'
import { AdminProvider } from '../contexts/AdminProvider'
import { CookiesProvider, useCookies } from 'react-cookie'
import useLogRocket from '../hooks/useLogRocket'
import Settings from './Admin/Settings'
import useAdminCamp from '../hooks/useAdminCamp'

interface ComponentProps {
  camp: CampProps
  camps: CampProps[]
  sites: SiteProps[]
  camping_styles: CampingStyleProps[]
  reservations: ReservationProps[]
  current_user_id: string
  current_user_email: string
  current_user_role: string
}

const AdminSettings = (props: ComponentProps) => {
  const identifyLogRocketUser = useLogRocket()
  const [camp, campingStyles, setCamp, setCampingStyles] = useAdminCamp(props.camps, props.camp)

  useEffect(() => {
    if (!camp) return

    identifyLogRocketUser(
      {
        id: props.current_user_id,
        email: props.current_user_email
      },
      camp.name
    )
  }, [camp])

  if (!(camp && campingStyles)) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <div className="">
          Loading your settings...
        </div>
      </div>
    )
  }

  return (
    <CookiesProvider>
      <AdminProvider
        currentPage={'settings'}
        setCurrentPage={(page) => location.href = `/admin/reservations`}
        currentUserEmail={props.current_user_email}
        camp={camp}
        setCamp={setCamp}
        sites={[]}
        setSites={() => []}
        campingStyles={campingStyles}
        setCampingStyles={setCampingStyles}
        selectedReservation={null}
        setSelectedReservation={() => { }}
        currentUserRole={props.current_user_role}
      >
        <Navbar />
        <div className='mt-4 flex flex-grow justify-center lg:px-16 xl:px-28 2xl:px-44 sm:pb-4 pb-0'>
          <Settings key={campingStyles.map((cs) => cs.id).join()} />
        </div>
      </AdminProvider>
    </CookiesProvider>
  )
}

export default AdminSettings
