import React, { useState } from 'react'
import { Card, Container, Divider, Form, Menu, Message, Segment } from 'semantic-ui-react'

import parkLogoPath from '../../assets/images/park_logo__green.svg'

interface componentProps {
  authenticity_token: string
  email: string
  token: string
}

const UpdatePassword = (props: componentProps) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handlePasswordChange = (e, { value }) => {
    setPassword(value)
  }

  const passwordIsValid = () => {
    const regexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
    return regexp.test(password)
  }

  const handlePasswordConfirmationChange = (e, { value }) => {
    setPasswordConfirmation(value)
  }

  const passwordsMatch = () => {
    return password == passwordConfirmation
  }

  return (
    <>
      <Segment>
        <Container>
          <Menu secondary>
            <span className='flex-inline align-middle mr-2'>
              <img src={parkLogoPath} className='inline z-10 h-10' />
            </span>
          </Menu>
        </Container>
      </Segment>
      <Container>
        <Segment basic>
          <Card.Group centered>
            <Card>
              <Card.Content>
                <Card.Header>Change your password</Card.Header>
                <p>Create a new password for your account, {props.email}, and confirm it below.</p>

                <Divider />

                <Form method='POST' action={`/password_resets/${props.token}`} >
                  <input hidden name='authenticity_token' value={props.authenticity_token} />
                  <input hidden name='_method' value='PUT' />

                  <Form.Input
                    label='Password'
                    type='password'
                    name='admin_user[password]'
                    icon={
                      password.length > 0 && passwordIsValid() ? {
                        name: 'check circle', color: 'green'
                      } : {
                        name: 'key'
                      }
                    }
                    iconPosition='left'
                    value={password}
                    placeholder={'Password'}
                    onChange={handlePasswordChange}
                  />

                  <Form.Input
                    label='Password Confirmation'
                    type='password'
                    name='admin_user[password_confirmation]'
                    icon={
                      passwordConfirmation.length > 0 && passwordsMatch() ? {
                        name: 'check circle', color: 'green'
                      } : {
                        name: 'key'
                      }
                    }
                    iconPosition='left'
                    value={passwordConfirmation}
                    placeholder={'Retype password'}
                    onChange={handlePasswordConfirmationChange}
                  />

                  <Form.Button fluid primary>Update Password</Form.Button>
                </Form>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </Container>
    </>
  )
}

export default UpdatePassword
