import React, { useContext, useEffect, useRef, useState } from 'react'
import ICamper from '../../../../interfaces/ICamper'
import { displayDates, titleCase } from '../../../../utils'
import moment from 'moment'
import { formatMoneyFromCents } from '../../../../utils/formatMoney'
import useAdminForm from '../../../../hooks/useAdminForm'
import formatDateRange from '../../../../utils/admin/formatDateRange'
import { AdminContext } from '../../../../contexts/AdminContext'
import IOrder from '../../../../interfaces/IOrder'
import api from '../../../../api2'
import { ReceiptRefundIcon, PrinterIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../utils/classNames'
import { useDebouncedCallback } from 'use-debounce'
import IPayment from '../../../../interfaces/IPayment'
import RefundModalContents from '../../../Reservations/RefundModal/RefundModalContents'
import { DateTime } from 'luxon'
import { ReservationProps } from '../../../../interfaces'
import Accordian from '../../../ui/Accordian'
import Button from '../../../ui/Button'
import stripeIntentUrlFor from '../../../../utils/admin/stripeIntentUrlFor'
import { useReactToPrint } from 'react-to-print'
import OrderSummary from '../../Orders/OrderSummary'
import Spinner from '../../../ui/Spinner'

const History = () => {
  const { resource: camper }: { resource: ICamper } = useAdminForm()

  return (
    <div className='mb-32'>
      <div className=''>
        <div className='text-2xl font-medium text-gray-900'>Reservations</div>
        <ReservationsTable reservations={camper.reservations || []} />
      </div>

      <div className='mt-8'>
        <Accordian label={`Deleted Reservations (${Number(camper.archived_reservations?.length)})`}>
          <ReservationsTable reservations={camper.archived_reservations || []} />
        </Accordian>
      </div>

      <div className='mt-8'>
        <CamperOrders camper={camper} />
      </div>
    </div>
  )
}

const ReservationsTable = ({ reservations }: { reservations: ReservationProps[] }) => {
  const { setSelectedCamper, setSelectedReservation } = useContext(AdminContext)

  const sortedReservations = reservations.sort((a, b) => {
    const momentA = moment(a.created_at)
    const momentB = moment(b.created_at)

    if (momentA.isBefore(momentB)) {
      return 1
    } else if (momentA.isAfter(momentB)) {
      return -1
    } else {
      return 0
    }
  })

  return (
    <table className='min-w-full divide-y divide-gray-300'>
      <thead>
        <tr>
          <th
            scope='col'
            className='py-3.5 xs:pl-2 xs:pr-3 text-left text-md font-normal text-gray-500'>
            Booked on
          </th>
          <th
            scope='col'
            className='hidden xs:px-3 py-3.5 text-left text-md font-normal text-gray-500 sm:table-cell'>
            Site
          </th>
          <th
            scope='col'
            className='xs:px-3 py-3.5 text-left text-md font-normal text-gray-500 sm:table-cell'>
            Dates
          </th>
          <th scope='col' className='xs:px-3 py-3.5 text-left text-md font-normal text-gray-500'>
            Amount
          </th>
          <th scope='col' className='xs:px-3 py-3.5 text-left text-md font-normal text-gray-500'>
            Status
          </th>
        </tr>
      </thead>
      <tbody className='divide-y divide-gray-200 bg-white'>
        {sortedReservations?.length === 0 ? (
          <tr>
            <td colSpan={5} className='text-center py-4 text-gray-300'>
              No reservations
            </td>
          </tr>
        ) : (
          sortedReservations.map((reservation) => (
            <tr
              key={reservation.id}
              className='hover:bg-gray-50 cursor-pointer'
              onClick={() => {
                setSelectedReservation(reservation)
                setSelectedCamper(null)
              }}>
              <td className='xs:whitespace-nowrap pr-1 py-4 xs:pl-3 xs:pr-3 text-md font-medium text-gray-800'>
                {moment(reservation.created_at).format('MM/DD/YYYY')}
              </td>
              <td className='hidden xs:whitespace-nowrap pr-1 xs:px-3 py-4 text-md text-gray-800 sm:table-cell'>
                {reservation.site?.name}
              </td>
              <td className='xs:whitespace-nowrap pr-1 xs:px-3 py-4 text-md text-gray-800 sm:table-cell'>
                {formatDateRange(reservation.start_date, reservation.end_date)}
              </td>
              <td className='xs:whitespace-nowrap pr-1 xs:px-3 py-4 text-md text-gray-800'>
                {formatMoneyFromCents(reservation.total_owed_in_cents)}
              </td>
              <td className='xs:whitespace-nowrap xs:px-3 py-4 text-md text-gray-800'>
                {titleCase(reservation.status)}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

const CamperOrders = ({ camper }: { camper: ICamper }) => {
  const { camp, showModal } = useContext(AdminContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [camperOrders, setCamperOrders] = useState<IOrder[]>([])

  const [orderForPrint, setOrderForPrint] = useState<IOrder>(null)
  const [isPrintLoading, setIsPrintLoading] = useState(false)

  const receiptPrintRef = useRef(null)
  const usePrint = useReactToPrint({
    content: () => receiptPrintRef.current,
    onAfterPrint: () => {
      setOrderForPrint(null)
      setIsPrintLoading(false)
    },
  })

  useEffect(() => {
    if (orderForPrint?.id) {
      setIsPrintLoading(true)
      usePrint()
    }
  }, [orderForPrint])

  useEffect(() => {
    setIsLoading(true)
    debouncedOrdersFor(camper.id)
  }, [camper])

  const debouncedOrdersFor = useDebouncedCallback((camperId: string) => {
    api.Campers.Orders.all(camperId)
      .then(({ orders }) => {
        setCamperOrders(orders)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 100)

  const handleClickRefund = (payment: IPayment, externalUrl: string) => {
    showModal(
      <RefundModalContents
        payment={payment}
        initialAmount={payment.amount_in_cents / 100}
        externalUrl={externalUrl}
      />,
      true,
      'max-w-2xl',
      {
        closeCallback: () => {
          debouncedOrdersFor(camper.id)
        },
      }
    )
  }

  const sortedOrders = camperOrders
    .filter((order) => !order.line_items.some((li) => li.reservation_id))
    .sort((a, b) => b.created_at.localeCompare(a.created_at))

  return (
    <div className='overflow-hidden'>
      {isPrintLoading && (
        <div className='fixed overflow-hidden bg-white bg-opacity-40 z-40 flex flex-col space-y-4 justify-center items-center h-full w-full left-0 top-20'>
          <Spinner size={16} />
          <p>Preparing to print...</p>
        </div>
      )}
      <div className=''>
        <div className='text-2xl font-medium text-gray-900'>Store purchases</div>
      </div>

      <div className=''>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-2 pr-3 text-left text-md font-normal text-gray-500'>
                Created on
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-md font-normal text-gray-500'>
                Amount
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-md font-normal text-gray-500'>
                Status
              </th>
              {window.screen.width >= 768 && (
                <>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-md font-normal text-gray-500'></th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-md font-normal text-gray-500'></th>
                </>
              )}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {sortedOrders?.length === 0 ? (
              isLoading ? (
                <tr>
                  <td colSpan={5} className='text-center py-4 text-gray-300'>
                    Loading...
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={5} className='text-center py-4 text-gray-300'>
                    No orders found
                  </td>
                </tr>
              )
            ) : (
              sortedOrders.map((order) => (
                <tr key={order.id}>
                  <td className='xs:whitespace-nowrap py-4 pl-3 pr-3 text-md font-medium text-gray-800 flex flex-col gap-3'>
                    {DateTime.fromJSDate(new Date(order.created_at), {
                      zone: camp.timezone,
                    }).toFormat('LLL d, yyyy h:mm a')}
                  </td>
                  <td className='px-3 py-4 text-md text-gray-800 content-start'>
                    <>
                      {formatMoneyFromCents(order.total_in_cents)}
                      {order.payments.length === 1 && order.payments[0].refunds.length ? (
                        <span className='ml-2 text-gray-400'>
                          (
                          {formatMoneyFromCents(
                            order.payments[0].refunds.reduce(
                              (sum, { amount_in_cents }) => amount_in_cents,
                              0
                            )
                          )}{' '}
                          refunded)
                        </span>
                      ) : (
                        ''
                      )}
                    </>
                  </td>
                  <td className='xs:whitespace-nowrap px-3 py-4 text-md text-gray-800 flex'>
                    {titleCase(order.status)}
                  </td>
                  {window.screen.width >= 600 && (
                    <td className='xs:whitespace-nowrap text-md text-gray-800'>
                      <Button
                        type='button'
                        variant='white'
                        size='small'
                        shadow='small'
                        disabled={!order}
                        className={classNames(
                          'flex-shrink-0 justify-self-end flex text-gray-600 items-center',
                          !order && 'cursor-not-allowed opacity-30'
                        )}
                        onClick={() => setOrderForPrint(order)}>
                        <PrinterIcon className='w-5 h-5 mr-2' />
                        Print receipt
                      </Button>
                    </td>
                  )}
                  <td className='xs:whitespace-nowrap text-md text-gray-800'>
                    {order.payments?.length === 1 &&
                      order.payments[0].stripe_charge_id &&
                      (order.payments[0].refunds.length > 0
                        ? order.payments[0].refunds[0]?.amount_in_cents <
                          order.payments[0].amount_in_cents
                        : true) && (
                        <Button
                          type='button'
                          variant='white'
                          size='small'
                          shadow='small'
                          disabled={!order}
                          className={classNames(
                            'flex-shrink-0 justify-self-end flex text-gray-600 h-9',
                            !order && 'cursor-not-allowed opacity-30'
                          )}
                          onClick={() =>
                            handleClickRefund(
                              order.payments[0],
                              stripeIntentUrlFor(
                                camp.stripe_account_id,
                                order.payments[0].stripe_charge_id
                              )
                            )
                          }>
                          <ReceiptRefundIcon className='w-5 h-5 mr-2' />
                          Refund
                        </Button>
                      )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className='w-full mt-3 border-t border-gray-200 hidden'>
          <div className='m-8' ref={receiptPrintRef}>
            <OrderSummary order={orderForPrint} camp={camp} isPreview isIncludingLineItems={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default History
