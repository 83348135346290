import { ReservationPriceDetailsProps } from './reservations'
import { post } from './utils/requests'

interface CreatePaymentLinkProps {
  email?: string
  amount?: number
  skip_send?: boolean
}

const create = (reservationId: string, data?: CreatePaymentLinkProps) =>
  post(`/api/reservations/${reservationId}/payment_links`, data)

const PaymentLinks = {
  create
}

export default PaymentLinks
