import React, { useContext, useEffect, useState } from 'react'
import classNames from '../../../utils/classNames'
import moment from 'moment'
import { formatName } from '../../../utils/formatName'
import { ArrowRightIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { RESERVATION_KINDS, STATUSES } from '../../../constants'
import IReservationWithErrors from '../../../interfaces/IReservationWithError'
import { ReservationProps } from '../../../interfaces'
import useAdminForm from '../../../hooks/useAdminForm'
import {
  IUpdatingFormData,
  IUpdatingReservation,
  mapStatesToFormData,
  rebuildOrderFor,
} from './ConfirmationModalContent'
import { AdminContext } from '../../../contexts/AdminContext'
import api from '../../../api2'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import { formatMoney } from '../../../utils/formatMoney'
import HoverTip from '../../ui/HoverTip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import OrderSummary from '../Orders/OrderSummary'
import useBreakpoint from '../../../hooks/useBreakpoint'

const isPresent = (value: object) => Object.keys(value || {}).length > 0

const UpdatingReservationsTable = ({
  failedReservations,
  succeededReservations,
}: {
  failedReservations: IReservationWithErrors[]
  succeededReservations: ReservationProps[]
}) => {
  const { sites, reservationStates, camp } = useContext(AdminContext)
  const { resource, setValue, watch } = useAdminForm<any>() // TODO: should be IUpdatingFormData but is causing an infinite type error
  const [recurringReservations, setRecurringReservations] = useState<
    Record<string, ReservationProps[]>
  >({})

  const reservations = watch('reservations')
  const isMedium = useBreakpoint('(min-width: 768px)')

  useEffect(() => {
    const promises = reservations
      .filter((r) => r.kind === RESERVATION_KINDS.RECURRING && r.is_updating_series)
      .map(async (r) => api.Reservations.recurrencesFor(r.id))

    Promise.all(promises)
      .then((recurringReservationsSet) => {
        const recurringReservations = recurringReservationsSet.flatMap((res) => res.recurrences)
        const reduced = recurringReservations.reduce(
          (acc, res): Record<string, ReservationProps[]> => {
            if (!acc[res.long_term_reservation_id]) {
              acc[res.long_term_reservation_id] = []
            }
            acc[res.long_term_reservation_id].push(res)
            return acc
          },
          {} as Record<string, ReservationProps[]>
        )
        setRecurringReservations(reduced)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [reservations])

  if (!resource.reservations || resource.reservations.length === 0) {
    return <></>
  }

  const hasRecurrences = resource.reservations.some(
    (r) => r.kind === RESERVATION_KINDS.RECURRING && r.site_id != r.old_values.site_id
  )

  return (
    <div className='flex flex-col overflow-visible'>
      <div className='-my-2 sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8'>
          <div className='border border-gray-200 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50 sm:rounded-lg'>
                <tr>
                  <th scope='col' className=''></th>
                  <th
                    scope='col'
                    className='pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Camper
                  </th>
                  <th
                    scope='col'
                    className='hidden md:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Site
                  </th>
                  <th
                    scope='col'
                    className='hidden md:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Arrival
                  </th>
                  <th
                    scope='col'
                    className='hidden md:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Departure
                  </th>
                  <th
                    scope='col'
                    className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Update total owed
                  </th>
                  {hasRecurrences && (
                    <th
                      scope='col'
                      className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Update recurrences
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {resource.reservations?.map((res) => {
                  if (!res || res.status === STATUSES.BLOCKED) return <></>

                  const failedStates = failedReservations.filter(
                    (r) =>
                      r.id === res.id ||
                      r.long_term_reservation_id === res.long_term_reservation?.id
                  )
                  const canUpdateRecurrences =
                    (res.kind === RESERVATION_KINDS.RECURRING &&
                      res.deleted_at != res.old_values.deleted_at) ||
                    (res.start_date == res.old_values.start_date &&
                      res.end_date == res.old_values.end_date)
                  let recurrences: IUpdatingReservation[]
                  const longtermReservation = res.long_term_reservation
                  const thisRecurrences = recurringReservations[longtermReservation?.id]
                  if (thisRecurrences?.length) {
                    const states = thisRecurrences
                      .filter((recurrence) => recurrence.id !== res.id)
                      .map((recurrence) => {
                        return {
                          persisted: recurrence,
                          updated: {
                            ...recurrence,
                            site_id: res.site_id,
                          },
                        }
                      })
                    recurrences = mapStatesToFormData(states)
                  } else {
                    const states = Object.values(reservationStates)
                      .filter(({ persisted }) => {
                        // NOTE: this only fetches from local state, so it won't necessarily show all
                        // reservations in the series
                        return (
                          persisted.long_term_reservation_id === longtermReservation?.id &&
                          persisted.id !== res.id &&
                          moment(persisted.end_date).isAfter(moment(res.end_date), 'd') &&
                          persisted.kind === RESERVATION_KINDS.RECURRING
                        )
                      })
                      .map((r) => {
                        if (r.updated) {
                          return { ...r }
                        } else {
                          return {
                            persisted: r.persisted,
                            updated: {
                              ...r.persisted,
                              site_id: res.site_id,
                            },
                          }
                        }
                      })

                    recurrences = mapStatesToFormData(states)
                  }

                  const primaryFailedState = failedStates.find((r) => r.id === res.id)
                  const succeeded = succeededReservations.filter(
                    (r) =>
                      r.id === res.id ||
                      r.long_term_reservation_id === res.long_term_reservation?.id
                  )
                  const primarySucceeded = succeeded.find((r) => r.id === res.id)
                  const isPrimaryDeleted = res.deleted_at && res.old_values.deleted_at === null

                  const PrimaryRow = () => (
                    <>
                      <tr
                        key={res.user?.email}
                        className={classNames(
                          'border-t border-gray-200',
                          primarySucceeded && 'opacity-40'
                        )}>
                        <td
                          className={classNames(
                            'px-2',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4'
                          )}>
                          {failedStates.length > 0 ? (
                            <ExclamationCircleIcon
                              className='h-6 w-6 text-red-600'
                              aria-hidden='true'
                            />
                          ) : (
                            primarySucceeded && (
                              <CheckCircleIcon
                                className='h-6 w-6 text-green-600'
                                aria-hidden='true'
                              />
                            )
                          )}
                        </td>
                        <td
                          className={classNames(
                            'pr-6 whitespace-nowrap text-left text-sm text-gray-500',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4'
                          )}>
                          <div className='flex flex-col'>
                            <div className='font-medium text-gray-900'>
                              <span>{formatName(res.user)}</span>
                            </div>
                            <div className='text-gray-400 text-xssm leading-4'>
                              <span>{res.user?.email}</span>
                            </div>
                            <div className='text-gray-400 text-xssm leading-4'>
                              <span>{res.user?.phone}</span>
                            </div>
                          </div>
                        </td>
                        <td
                          className={classNames(
                            'hidden md:table-cell px-4 whitespace-nowrap text-sm',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4',
                            isPresent(primaryFailedState?.errors) ? 'text-red-600' : 'text-gray-500'
                          )}>
                          <div className='flex flex-col md:flex-row justify-items-center'>
                            {res.old_values.site_id != res.site_id && (
                              <span className='text-gray-300 line-through mr-2'>
                                {sites.find((s) => s.id === res.old_values.site_id)?.name}
                              </span>
                            )}
                            <span>{sites.find((s) => s.id === res.site_id)?.name}</span>
                          </div>
                        </td>
                        <td
                          className={classNames(
                            'hidden md:table-cell px-4 whitespace-nowrap text-sm',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4',
                            isPresent(primaryFailedState?.errors) ? 'text-red-600' : 'text-gray-500'
                          )}>
                          <div className='flex flex-col md:flex-row justify-items-center'>
                            {!moment(res.old_values.start_date).isSame(res.start_date, 'd') && (
                              <span className='text-gray-300 line-through mr-2'>
                                {moment(res.old_values.start_date).format('M/D/YY')}
                              </span>
                            )}
                            <span className='mr-2'>
                              {res.start_date && moment(res.start_date).format('M/D/YY')}
                            </span>
                          </div>
                        </td>
                        <td
                          className={classNames(
                            'hidden md:table-cell px-4 whitespace-nowrap text-sm',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4',
                            isPresent(primaryFailedState?.errors) ? 'text-red-600' : 'text-gray-500'
                          )}>
                          <div className='flex flex-col md:flex-row justify-items-center'>
                            {!moment(res.old_values.end_date).isSame(res.end_date, 'd') && (
                              <span className='text-gray-300 line-through mr-2'>
                                {moment(res.old_values.end_date).format('M/D/YY')}
                              </span>
                            )}
                            <span className='mr-2'>
                              {res.end_date && moment(res.end_date).format('M/D/YY')}
                            </span>
                          </div>
                        </td>
                        <td
                          className={classNames(
                            'table-cell px-4 whitespace-nowrap text-sm',
                            primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4',
                            isPresent(primaryFailedState?.errors) ? 'text-red-600' : 'text-gray-500'
                          )}>
                          <div
                            className={classNames(
                              'flex items-center',
                              res.kind == RESERVATION_KINDS.RECURRING && 'hidden'
                            )}>
                            <CheckboxWithLabel
                              value={res.is_auto_updating_price}
                              setValue={(v) => {
                                const index = resource.reservations.findIndex(
                                  (r) => r.id === res.id
                                )
                                if (v === true) {
                                  setValue(`reservations.${index}`, {
                                    ...res,
                                    is_auto_updating_price: true,
                                    is_loading: true,
                                  })
                                  rebuildOrderFor(res)
                                    .then((order) => {
                                      setValue(`reservations.${index}`, {
                                        ...res,
                                        total_owed_in_cents: order.total_in_cents,
                                        order: order,
                                        is_loading: false,
                                        is_auto_updating_price: true,
                                      })
                                    })
                                    .catch((err) => {
                                      setValue(`reservations.${index}.is_loading`, false)
                                      console.error(err)
                                      // TODO: show error state
                                    })
                                } else {
                                  setValue(`reservations.${index}`, {
                                    ...res,
                                    is_auto_updating_price: false,
                                  })
                                }
                              }}
                              disabled={
                                res.total_owed_in_cents === res.old_values.total_owed_in_cents
                              }
                              label={''}
                            />

                            {isMedium ? (
                              <HoverTip
                                white
                                placement='bottom'
                                width='w-96'
                                content={
                                  <>
                                    <div className='text-baselg font-semibold -mb-6 mt-2'>
                                      Suggested updated price
                                    </div>
                                    <OrderSummary
                                      order={res.order}
                                      camp={camp}
                                      isIncludingLineItems
                                      noBackground
                                    />
                                  </>
                                }>
                                <div className='flex items-center'>
                                  <span
                                    className={classNames(
                                      'mr-1.5',
                                      res.is_auto_updating_price
                                        ? 'text-gray-300 line-through'
                                        : 'text-gray-400'
                                    )}>
                                    {formatMoney(
                                      res.old_values.total_owed_in_cents / 100,
                                      camp.currency
                                    )}
                                  </span>
                                  <ArrowRightIcon
                                    className={classNames(
                                      'w-5 h-5 mr-1.5',
                                      res.is_auto_updating_price ? 'text-gray-500' : 'text-gray-400'
                                    )}
                                  />
                                  <span
                                    className={classNames(
                                      'flex mr-2 whitespace-nowrap',
                                      res.is_auto_updating_price
                                        ? 'font-semibold text-green-500'
                                        : 'text-gray-400'
                                    )}>
                                    {formatMoney(res.total_owed_in_cents / 100, camp.currency)}
                                    <InformationCircleIcon className='w-3.5 h-3.5 ml-0.5 text-gray-700' />
                                  </span>
                                </div>
                              </HoverTip>
                            ) : (
                              <div className='flex items-center'>
                                <span
                                  className={classNames(
                                    'mr-1.5',
                                    res.is_auto_updating_price
                                      ? 'text-gray-300 line-through'
                                      : 'text-gray-400'
                                  )}>
                                  {formatMoney(
                                    res.old_values.total_owed_in_cents / 100,
                                    camp.currency
                                  )}
                                </span>
                                <ArrowRightIcon
                                  className={classNames(
                                    'w-5 h-5 mr-1.5',
                                    res.is_auto_updating_price ? 'text-gray-500' : 'text-gray-400'
                                  )}
                                />
                                <span
                                  className={classNames(
                                    'flex mr-2 whitespace-nowrap',
                                    res.is_auto_updating_price
                                      ? 'font-semibold text-green-500'
                                      : 'text-gray-400'
                                  )}>
                                  {formatMoney(res.total_owed_in_cents / 100, camp.currency)}
                                </span>
                              </div>
                            )}
                          </div>
                        </td>

                        {hasRecurrences && (
                          <td
                            className={classNames(
                              'px-4 whitespace-nowrap text-sm text-gray-500',
                              primaryFailedState && primaryFailedState.errors ? 'pt-4 pb-1' : 'py-4'
                            )}>
                            <CheckboxWithLabel
                              value={res.is_updating_series}
                              setValue={(v) => {
                                const mReservations = resource.reservations
                                const index = mReservations.findIndex((r) => r.id === res.id)
                                mReservations[index].is_updating_series = v
                                setValue('reservations', mReservations)
                              }}
                              disabled={!canUpdateRecurrences}
                              label={''}
                            />
                          </td>
                        )}
                      </tr>
                      {primaryFailedState && primaryFailedState.errors && (
                        <tr key={res.id + '-error'}>
                          <td></td>
                          <td></td>
                          <td
                            colSpan={3}
                            className='hidden md:table-cell -mt-8 px-4 pb-3 text-sm text-red-600'>
                            {Object.values(primaryFailedState.errors).join(', ')}
                          </td>
                          <td></td>
                        </tr>
                      )}
                    </>
                  )

                  let SeriesRows

                  if (canUpdateRecurrences && recurrences?.length > 0 && res.is_updating_series) {
                    SeriesRows = () => (
                      <>
                        {recurrences
                          .slice(0, 3)
                          .map((seriesRes) => {
                            const failedState = failedStates.find((r) => r.id === seriesRes.id)
                            return (
                              <>
                                <tr key={seriesRes.id}>
                                  <td></td>
                                  <td></td>
                                  <td
                                    className={classNames(
                                      'hidden md:table-cell px-4 py-1 whitespace-nowrap text-sm',
                                      isPresent(failedState?.errors)
                                        ? 'text-red-600'
                                        : 'text-gray-500'
                                    )}>
                                    {seriesRes.old_values.site_id != seriesRes.site_id && (
                                      <span className='text-gray-300 line-through mr-2'>
                                        {
                                          sites.find((s) => s.id === seriesRes.old_values.site_id)
                                            ?.name
                                        }
                                      </span>
                                    )}
                                    <span>
                                      {sites.find((s) => s.id === seriesRes.site_id)?.name}
                                    </span>
                                  </td>
                                  <td
                                    className={classNames(
                                      'hidden md:table-cell px-4 py-1 whitespace-nowrap text-sm',
                                      isPresent(failedState?.errors)
                                        ? 'text-red-600'
                                        : 'text-gray-500'
                                    )}>
                                    <span className='mr-2'>
                                      {isPrimaryDeleted ? (
                                        <span className='text-gray-300 line-through mr-2'>
                                          {moment(seriesRes.old_values.start_date).format('M/D/YY')}
                                        </span>
                                      ) : (
                                        moment(seriesRes.start_date).format('M/D/YY')
                                      )}
                                    </span>
                                  </td>
                                  <td
                                    className={classNames(
                                      'hidden md:table-cell px-4 py-1 whitespace-nowrap text-sm',
                                      isPresent(failedState?.errors)
                                        ? 'text-red-600'
                                        : 'text-gray-500'
                                    )}>
                                    <span className='mr-2'>
                                      {isPrimaryDeleted ? (
                                        <span className='text-gray-300 line-through mr-2'>
                                          {moment(seriesRes.old_values.end_date).format('M/D/YY')}
                                        </span>
                                      ) : (
                                        moment(seriesRes.end_date).format('M/D/YY')
                                      )}
                                    </span>
                                  </td>
                                  <td></td>
                                </tr>
                                {failedState && failedState.errors && (
                                  <tr key={seriesRes.id + '-error'}>
                                    <td></td>
                                    <td></td>
                                    <td
                                      colSpan={3}
                                      className='hidden md:table-cell px-4 text-sm text-red-600'>
                                      {Object.values(failedState.errors).join(', ')}
                                    </td>
                                    <td></td>
                                  </tr>
                                )}
                              </>
                            )
                          })
                          .concat(
                            recurrences.length - 3 > 0
                              ? [
                                  <tr key={res.id + '-end'}>
                                    <td></td>
                                    <td></td>
                                    <td
                                      colSpan={3}
                                      className='hidden md:table-cell px-4 text-sm text-gray-400'>
                                      + {recurrences.length - 3} more recurrences...
                                    </td>
                                    <td></td>
                                  </tr>,
                                ]
                              : []
                          )}
                      </>
                    )
                  } else {
                    SeriesRows = () => <></>
                  }

                  return (
                    <>
                      <PrimaryRow />
                      <SeriesRows />
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdatingReservationsTable
