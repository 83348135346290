import React, { useContext, useState } from 'react'
import { ReservationProps } from '../../../interfaces'
import Button from '../../ui/Button'
import classNames from '../../../utils/classNames'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import Spinner from '../../ui/Spinner'
import api from '../../../api2'
import { AdminContext } from '../../../contexts/AdminContext'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { FormProvider } from '../../../contexts/FormContext'
import { IUpdatingFormData, mapStatesToFormData } from './ConfirmationModalContent'
import UpdatingReservationsTable from './UpdatingReservationsTable'
import useAdminForm from '../../../hooks/useAdminForm'

interface ComponentProps {
  reservation: ReservationProps
}

const DeleteConfirmationModalContent = (props: ComponentProps) => {
  const { reservationStates } = useContext(AdminContext)
  const originalReservationState = reservationStates[props.reservation?.id]

  const updatedState = originalReservationState ? {
    ...originalReservationState,
    updated: {
      ...originalReservationState.persisted,
      deleted_at: new Date().toISOString(),
      site_id: null,
      start_date: null,
      end_date: null,
    }
  } : null

  return (
    <div className='bg-white rounded-lg text-left overflow-hidden transform transition-all'>
      <FormProvider init={{ reservations: mapStatesToFormData([updatedState].filter((r) => r)) }}>
        <DeleteForm reservation={props.reservation} />
      </FormProvider>
    </div>
  )
}

const DeleteForm = ({ reservation }: { reservation: ReservationProps }) => {
  const { updateLocalReservations, showNotification } = useContext(AdminContext)
  const { onClose } = useContext(ModalContext)
  const { resource } = useAdminForm<IUpdatingFormData>()
  const [isLoading, setIsLoading] = useState(false)
  const deletedReservation = resource?.reservations && resource.reservations[0]

  const handleDelete = async () => {
    if (deletedReservation?.is_updating_series) {
      handleDeleteWithRecurring()
    } else {
      handleDeleteSingle()
    }
  }

  const handleDeleteSingle = async () => {
    setIsLoading(true)
    api.Reservations.remove(deletedReservation?.id)
      .then(({ reservations }) => {
        updateLocalReservations(reservations)
        setIsLoading(false)
        onClose()
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          title: error.message,
          dismissAfter: 7000
        })
        console.error(error)
        setIsLoading(false)
      })
  }

  const handleDeleteWithRecurring = async () => {
    setIsLoading(true)
    api.Reservations.remove(deletedReservation?.id, { include_recurrences: true })
      .then(({ reservations }) => {
        updateLocalReservations(reservations)
        setIsLoading(false)
        onClose()
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          title: error.message,
          dismissAfter: 7000
        })
        console.error(error)
        setIsLoading(false)
      })
  }

  return (
    <div className=''>
      <div className='mb-12 sm:mr-8'>
        <div className='sm:flex sm:items-start place-items-center'>
          <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-50 sm:mx-0 sm:h-10 sm:w-10'>
            <XCircleIcon className='h-12 w-12 text-red-600' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
            <div className='text-lg leading-6 font-medium text-gray-900'>Remove reservation?</div>
            <div className='mt-2'>
              {reservation?.vendor_id
                ? 'Removing a reservation does not issue a refund automatically. Please issue a refund from the reservation payment history, if applicable.'
                : 'This reservation will be removed from the calendar and all reporting, but can still be viewed in the reservation history for this guest.'}
            </div>
            <div className='mt-6'>
              <UpdatingReservationsTable succeededReservations={[]} failedReservations={[]} />
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end items-center'>
        <Button
          type='button'
          variant='gray'
          className={classNames(
            'sm:ml-2 mr-2.5 w-1/2 sm:w-32 rounded-md',
            isLoading && 'pointer-events-none opacity-60'
          )}
          onClick={onClose}
          disabled={isLoading}>
          Cancel
        </Button>
        <Button variant='red' onClick={handleDelete} disabled={isLoading} className='w-1/2 sm:w-32'>
          Remove
        </Button>
      </div>
    </div>
  )
}

export default DeleteConfirmationModalContent
