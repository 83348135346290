import React from 'react'
import classNames from '../../../../utils/classNames'
import CopyPasteButton from '../../../ui/CopyPasteButton'

interface ComponentProps {
  campSlug: string
  groupedReservationId: string
}

const GroupedReservationLinkField = ({ campSlug, groupedReservationId }: ComponentProps) => {
  const url = `${window.location.origin}/camps/${campSlug}?grouped_reservation=${groupedReservationId}`

  if (!groupedReservationId) return <></>

  return (
    <div className='w-full mb-4'>
      <label htmlFor='name' className='block font-bold text-md text-gray-900 mb-1.5'>
        Group booking link
      </label>
      <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
        <input
          value={url}
          type='text'
          step='any'
          readOnly
          name='grouped_reservation_url'
          id='grouped_reservation_url'
          className={classNames(
            'block w-full text-md h-10.5 rounded-l-md border-gray-300 pl-3.5 pr-4 focus:border-green-700 focus:ring-green-700',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 bg-white'
          )}
        />
        <div className='w-48'>
          <CopyPasteButton text={'link'} value={url} buttonClassName='h-10.5 w-full rounded-l-none' />
        </div>
      </div>
    </div>
  )
}

export default GroupedReservationLinkField
