import { Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef } from 'react'
import classNames from '../../../utils/classNames'
import { PencilIcon } from '@heroicons/react/20/solid'

interface ComponentProps {
  menuX: number
  menuY: number
  isOpen: boolean
  onClose: () => any
  isDisabled?: boolean
  children: JSX.Element | JSX.Element[]
}

const ContextMenu = ({ menuX, menuY, children, isOpen, onClose, isDisabled }: ComponentProps) => {
  const menuRef = useRef(null)

  useEffect(() => {
    if (isOpen && menuRef.current) {
      const firstItem = menuRef.current.querySelector('[role="menuitem"]')
      firstItem && firstItem.focus()
    }
  }, [isOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && menuRef && !menuRef.current.contains(event.target)) {
        onClose()
      }
    }

    const handleKeyDown = (event) => {
      if (isOpen && event.key === 'Escape') {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  return (
    <div className={classNames('inset-0 z-20 bg-black bg-opacity-0', isOpen ? 'fixed' : 'hidden')}>
      <div className='z-50 absolute' style={{ top: menuY, left: menuX }}>
        <Menu ref={menuRef} as="div" className='relative inline-block text-left'>
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items static className="absolute z-20 left-0 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className={classNames('transition-opacity duration-100 px-1 py-1', isDisabled && 'opacity-30 pointer-events-none')}>
                {children}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

export const ContextMenuDivider = () => <div className='my-1 border-b border-gray-200' />

export const ContextMenuItem = (props: {
  onClick?: () => any
  icon?: any
  children: any
  className?: string
}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={props.onClick}
          className={classNames(
            active ? 'bg-gray-100 text-black' : 'text-gray-900',
            'group flex w-full items-center rounded-md px-2 py-2 cursor-pointer',
            props.className
          )}
        >
          {props.icon && (
            <span className='mr-2'>
              <props.icon className='w-4 h-4' />
            </span>
          )}
          {props.children}
        </button>
      )}
    </Menu.Item>
  )
}

export default ContextMenu
