import React, { useContext, useState } from 'react'
import { FormProvider } from '../../../contexts/FormContext'
import ICamper from '../../../interfaces/ICamper'
import api from '../../../api2'
import useAdminForm from '../../../hooks/useAdminForm'
import Button from '../../ui/Button'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import Form from '../../ui/Admin/Form'
import { AdminContext } from '../../../contexts/AdminContext'
import electricOptionsFor from '../../../utils/admin/electricOptionsFor'
import CamperDecorator from '../../../decorators/CamperDecorator'
import CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD from '../../../constants/camp_slugs_with_company_name_field'
import CamperDropdown from '../Reservations/CamperDropdown'

export const newCamper: ICamper = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  phone: null,
  address: null,
  company_name: null,
  default_adults_count: null,
  default_kids_count: null,
  default_pets_info: null,
  license_plate: null,
  vehicle_year: null,
  vehicle_make: null,
  vehicle_color: null,
  vehicle_rig_type: null,
  vehicle_rig_length: null,
  vehicle_electric: null,
  pets_info: null,
  reservations: null,
  notes: null,
  blocked_at: null,
  last_stay_date: null,
  num_stays: null,
  num_nights: null,
  spent: null,
  emergency_contact_name: null,
  emergency_contact_relationship: null,
  emergency_contact_email: null,
  emergency_contact_phone: null,
  additional_vehicle_make: null,
  additional_vehicle_year: null,
  additional_vehicle_color: null,
  additional_vehicle_license_plate: null,
}

const NewCamperModalContent = ({ onSuccess, camper, userId, reservationId }: { onSuccess: (camper: ICamper) => void, camper?: ICamper, userId?: string, reservationId?: string }) => {

  return (
    <div>
      <div className='flex items-center justify-between bg-gray-100 px-4 pt-6 pb-4 -mx-4 -mt-5 sm:-mx-6 sm:-mt-6 rounded-t-lg'>
        <div className='flex flex-col w-full'>
          <div className='font-semibold text-xl'>{!!camper?.id ? "Update" : "New"} guest</div>
        </div>
      </div>

      <div className=''>
        <FormProvider init={camper || newCamper}>
          <NewCamperForm userId={userId} reservationId={reservationId} onSuccess={onSuccess} isUpdating={!!camper?.id} />
        </FormProvider>
      </div>
    </div>
  )
}

const NewCamperForm = ({ userId, reservationId, isUpdating, onSuccess }: { userId?: string, reservationId?: string, isUpdating?: boolean, onSuccess: (camper: CamperDecorator) => void }) => {
  const { camp, sites, showNotification } = useContext(AdminContext)
  const [existingCamper, setExistingCamper] = useState<ICamper>(null)
  const { resource: camper, reset, defaultValues, setError, clearErrors } = useAdminForm<ICamper>()
  const { onClose } = useContext(ModalContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleClickSave = () => {
    if (!camper.first_name && !camper.last_name && !camper.email && !camper.phone) {
      if (!camper.first_name && !camper.last_name) {
        setError('first_name', { message: '' })
        setError('last_name', { message: '' })
      }
      if (!camper.email) setError('email', { message: '' })
      if (!camper.phone) setError('phone', { message: '' })

      showNotification({
        type: 'error',
        title: 'Error adding guest',
        description: 'Please add either a name, email, or phone number',
        dismissAfter: 3000,
      })
      return
    }

    clearErrors()
    setIsLoading(true)

    if (userId && existingCamper) {
      api.Users.update(userId, { camper_id: existingCamper.id } as Parameters<typeof api.Users.update>[1])
        .then(() => {
          const decorated = new CamperDecorator(existingCamper)
          onSuccess(decorated)
          setIsLoading(false)
          showNotification({
            type: 'success',
            title: 'Guest updated',
            description: `${decorated.formattedName} was updated successfully`,
          })
          onClose()
        })
        .catch((err) => {
          setIsLoading(false)
          showNotification({
            type: 'error',
            title: 'Error updating guest',
            description: err.message,
            dismissAfter: 3000,
          })
        })
    } else if (isUpdating) {
      api.Campers.update(camper.id, camper)
        .then((persistedCamper) => {
          onSuccess(persistedCamper)
          setIsLoading(false)
          showNotification({
            type: 'success',
            title: 'Guest added',
            description: `${persistedCamper.formattedName} was added successfully`,
          })
          onClose()
        })
        .catch((err) => {
          setIsLoading(false)

          if (err.errors) {
            const values = Object.entries(err.errors as Record<keyof ICamper, Array<string>>)
            values.forEach(([key, messages]) => {
              setError(key as keyof ICamper, { message: messages.join(',') })
            })
          }

          showNotification({
            type: 'error',
            title: 'Error updating guest',
            description: err.message,
            dismissAfter: 3000,
          })
        })
    } else {
      api.Campers.create(camp.id, { ...camper, user_id: userId, reservation_id: reservationId })
        .then((persistedCamper) => {
          onSuccess(persistedCamper)
          setIsLoading(false)
          showNotification({
            type: 'success',
            title: 'Guest added',
            description: `${persistedCamper.formattedName} was added successfully`,
          })
          onClose()
        })
        .catch((err) => {
          setIsLoading(false)

          if (err.errors) {
            const values = Object.entries(err.errors as Record<keyof ICamper, Array<string>>)
            values.forEach(([key, messages]) => {
              setError(key as keyof ICamper, { message: messages.join(',') })
            })
          }

          showNotification({
            type: 'error',
            title: 'Error adding guest',
            description: err.message,
            dismissAfter: 3000,
          })
        })
    }
  }

  const handleClickCancel = () => {
    onClose()
  }

  const electricOptions = electricOptionsFor(sites)

  return (
    <form>
      <div className='flex-col mb-10'>
        {userId && (
          <div className='flex flex-col items-start'>
            <div className='mt-6 mb-2 text-lgxl font-semibold text-gray-900'>Attach existing guest</div>
            <div className='w-full sm:w-1/2 sm:pr-4'>
              <CamperDropdown
                isCamperLoading={false}
                camper={existingCamper}
                setCamper={(value) => {
                  reset(value as ICamper || defaultValues)
                  setExistingCamper(value)
                }}
              />
            </div>
          </div>
        )}

        <>
          <div className='mt-6 mb-2 space-y-1 flex'>
            <div className='text-lgxl font-semibold text-gray-900'>Guest Information</div>
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.TextInput field='first_name' label='First Name' disabled={!!existingCamper} />
            <Form.TextInput field='last_name' label='Last Name' disabled={!!existingCamper} />
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.TextInput field='id' disabled hidden />
            <Form.TextInput field='email' label='Email' inputType='email' disabled={!!existingCamper} />
            <Form.TextInput field='phone' label='Phone' disabled={!!existingCamper} />
          </div>
          {CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD.includes(camp.slug) && (
            <div className='grid grid-cols-2 gap-6'>
              <Form.TextInput field='company_name' label='Company name' disabled={!!existingCamper} />
            </div>
          )}
          <div className='grid grid-cols-1'>
            <Form.TextAreaInput className='w-full' field='address' label='Address' disabled={!!existingCamper} rows={2} />
          </div>
          <div className='mt-6 mb-2 space-y-1'>
            <div className='text-lgxl font-semibold text-gray-900'>Vehicle info</div>
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.TextInput field='license_plate' label='License Plate' disabled={!!existingCamper} />
            <Form.TextInput field='vehicle_rig_type' label='Vehicle Rig Type' disabled={!!existingCamper} />
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.TextInput field='vehicle_rig_length' label='Vehicle Rig Length' disabled={!!existingCamper} />
            <Form.SelectInput
              disabled={!!existingCamper}
              field='vehicle_electric'
              label='Vehicle Electric'
              options={electricOptions}
            />
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.TextInput field='vehicle_make' label='Vehicle Make & Model' disabled={!!existingCamper} />
            <Form.TextInput field='vehicle_color' label='Vehicle Color' disabled={!!existingCamper} />
          </div>
          <div className='grid sm:grid-cols-2 gap-2 sm:gap-6'>
            <Form.NumberInput field='vehicle_year' label='Vehicle Year' disabled={!!existingCamper} />
          </div>
          <div className='mt-6 mb-2 space-y-1'>
            <div className='text-lgxl font-semibold text-gray-900'>Additional Info</div>
          </div>
          <Form.RichTextInput field='notes' label='Notes' disabled={!!existingCamper} />
        </>
      </div>
      <div className='sm:flex sm:flex-row justify-end items-center'>
        <div className='flex sm:inline mt-2 sm:mt-0'>
          <Button
            type='button'
            variant='gray'
            className='sm:ml-2 mr-2.5 w-1/2 sm:w-32 rounded-md'
            disabled={isLoading}
            onClick={handleClickCancel}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='blue'
            className='w-1/2 sm:w-auto'
            disabled={isLoading}
            onClick={handleClickSave}>
            Update guest profile
          </Button>
        </div>
      </div>
    </form>
  )
}

export default NewCamperModalContent
