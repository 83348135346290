import React, { Dispatch, useContext, useState } from 'react'
import { Transition } from 'semantic-ui-react'
import { Moment } from 'moment'
import { displayDates } from '../../utils'
import Button from '../ui/Button'
import { BookerContext } from '../../contexts/BookerContext'
import moment from 'moment'

interface ComponentProps {
  editingDates: boolean
  confirmDateRange: any
  editDates: any // TODO: proper type for setX function
  startDate: Moment
  endDate: Moment | null
  maximumNights: number
  maximumNightsMessage: string
  resetAllPrereq: () => void
}

const SelectedDate = (props: ComponentProps) => {
  const { groupedReservationId, secondaryColor, startDate, endDate, unavailableDates } = useContext(BookerContext)

  const isDateRangeOverMax = (): boolean => {
    if (!(props.endDate && props.startDate && props.maximumNights)) return false

    const numberOfDays = props.endDate?.diff(props.startDate, 'days')
    return numberOfDays > props.maximumNights
  }

  const isDateInRange = (date: moment.MomentInput, start: moment.MomentInput, end: moment.MomentInput) => {
    return moment(date).isBetween(start, moment(end).subtract(1, 'day'), null, '[]') || moment(date).isSame(start, 'day') || moment(date).isSame(moment(end).subtract(1, 'day'), 'day')
  } 

  const hasBlockedDateInSelectedRange = () => {
    const blockedDates = Object.values(unavailableDates)?.flat()

    return blockedDates.some(date => isDateInRange(date, startDate, endDate))
  }

  return (
    <>
      <Transition.Group animation='fade right'>
        {!props.editingDates && (
          <div className='ui selection-header' style={{ paddingRight: '1rem' }}>
            {displayDates(props.startDate, props.endDate)}
          </div>
        )}
        {!groupedReservationId &&
          (props.editingDates ? (
            <Button
              style={{ backgroundColor: secondaryColor }}
              className='hover:opacity-80 text-white float-right'
              size='large'
              disabled={!(props.startDate && props.endDate) || isDateRangeOverMax() || hasBlockedDateInSelectedRange()}
              onClick={() => {
                props.confirmDateRange()
              }}>
              Next
            </Button>
          ) : (
            <Button
              variant='none'
              style={{ borderColor: secondaryColor, color: secondaryColor }}
              className='hover:opacity-80 float-right border-2 bg-white'
              disabled={!(props.startDate && props.endDate) || isDateRangeOverMax()}
              onClick={() => {
                props.editDates(true)
                props.resetAllPrereq()
              }}>
              Change
            </Button>
          ))}
        {isDateRangeOverMax() ? (
          <span className='dates-error-message right floated'>
            {props.maximumNightsMessage ? (
              <>{props.maximumNightsMessage.replace('%%', props.maximumNights.toString())}</>
            ) : (
              <>
                Maximum {props.maximumNights} night stay<br></br>Please select new dates
              </>
            )}
          </span>
        ) : (
          <div></div>
        )}
      </Transition.Group>
    </>
  )
}

export default SelectedDate
