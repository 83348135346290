import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import React, { useContext, useEffect, useState } from 'react'
import api from '../../api2'
import { ReservationProps } from '../../interfaces'
import Modal from '../ui/Modal'
import Spinner from '../ui/Spinner'
import * as Sentry from '@sentry/react'
import Button from '../ui/Button'
import StripeCardForm from './PaymentModal/StripeCardForm'
import PaymentLinkForm from './PaymentModal/PaymentLinkForm'
import { AdminContext } from '../../contexts/AdminContext'
import { Transition } from '@headlessui/react'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import IGroupedReservation from '../../interfaces/IGroupedReservation'
import AdminAddOns from './PaymentModal/AdminAddOns'
import IAddOn from '../../interfaces/IAddOn'
import PaymentTypeSelector from './PaymentModal/PaymentTypeSelector'
import { useDebouncedCallback } from 'use-debounce'
import classNames from '../../utils/classNames'
import IOrder from '../../interfaces/IOrder'
import { XMarkIcon } from '@heroicons/react/24/solid'
import ILineItem from '../../interfaces/ILineItem'
import IPaymentMethods from '../../interfaces/IPaymentMethods'
import { depositLineItemParams, mapLineItemToParams, serviceRateMode } from '../../api2/orders'
import InputFieldWithCurrency from '../Admin/Inputs/InputFieldWithCurrency'
import DiscountProps from '../../interfaces/DiscountProps'
import OrderSummary from '../Admin/Orders/OrderSummary'
import { WifiIcon } from '@heroicons/react/24/outline'
import ePaymentType from '../../enums/ePaymentType'
import serviceRateModeForPaymentType from '../../utils/serviceRateModeForPaymentType'
import ProductModalContent from '../Admin/Settings/Product/ProductModalContent'
import dynamicRound from '../../utils/dynamicRound'
import Toggle from '../ui/Toggle'

interface ComponentProps {
  open: boolean
  onClose: () => void
  reservation?: ReservationProps
  reservations?: ReservationProps[]
  groupedReservation?: IGroupedReservation
  owedInCents: number
  onChangeIntentId: (intentId: string) => Promise<any>
  isDeposit?: boolean
}

const PosModal = ({
  open,
  onClose,
  reservation,
  reservations,
  groupedReservation,
  owedInCents,
  onChangeIntentId,
  isDeposit,
}: ComponentProps) => {
  const orderId = (groupedReservation || reservation)?.order_id
  const paidInCents =
    reservations?.length > 0
      ? reservations.reduce((acc, r) => acc + r.total_paid_in_cents, 0)
      : reservation?.total_paid_in_cents

  const { camp, campingStyles, sites, showNotification, showModal } = useContext(AdminContext)

  const campingStyle = campingStyles.find((cs) => {
    const mReservations = reservations || [reservation]
    const mSites = sites.filter((s) =>
      mReservations
        .filter((r) => r)
        .map((r) => r.site_id)
        .includes(s.id)
    )
    return mSites.map((s) => s.camping_style_id).includes(cs.id)
  })

  const discountFromLineItems = (lineItems: Partial<ILineItem>[]): DiscountProps => {
    const discountIds = lineItems?.map((li) => li.discount_id) || []
    return camp.discounts.find((discount) => discountIds.includes(discount.id))
  }

  const [intentId, setIntentId] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [amountOwed, setAmountOwed] = useState(owedInCents / 100)
  const [amountPaid, setAmountPaid] = useState(paidInCents / 100)
  const [products, setProducts] = useState<IAddOn[]>([])
  const [paymentMethod, setPaymentMethod] = useState<ePaymentType>(ePaymentType.CARD_INPUT)
  const [order, setOrder] = useState<IOrder>()
  const [lineItems, setLineItems] = useState<Partial<ILineItem>[]>()
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>()
  const [depositOrder, setDepositOrder] = useState<IOrder>()
  const [isTaxFeeEnabled, setIsTaxFeeEnabled] = useState(false)
  const [isCreditCardFeeEnabled, setIsCreditCardFeeEnabled] = useState(false)
  const [isServiceFeeEnabled, setIsServiceFeeEnabled] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [intervalId, setIntervalId] = useState<number>()
  const [showCloseButton, setShowCloseButton] = useState(false)

  const handleChangePaymentType = (v: ePaymentType) => {
    setPaymentMethod(v)
    if (v === ePaymentType.CASH) {
      setIsCreditCardFeeEnabled(order.credit_card_rate > 0)
      setIsServiceFeeEnabled(false)
    } else {
      setIsCreditCardFeeEnabled(order.credit_card_rate > 0)
      setIsServiceFeeEnabled(true)
    }
  }
  const handleProductClick = (selectedProduct?: IAddOn) => {
    showModal(
      <ProductModalContent fetchProducts={fetchProducts} selectedProduct={selectedProduct} />,
      true,
      'max-w-4xl'
    )
  }

  useEffect(() => {
    if (!open) return

    buildOrder()
    if (isDeposit) buildDepositOrder()
    else setDepositOrder(null)
  }, [lineItems, isCreditCardFeeEnabled, paymentMethod, isTaxFeeEnabled, isDeposit])

  useEffect(() => {
    fetchProducts()
  }, [amountOwed, amountPaid, camp, sites])

  useEffect(() => {
    if (!open) return

    fetchOrder(orderId)
  }, [reservation, isDeposit, open])

  const handleClosed = () => {
    setPaymentStatus(null)
    setErrorMessage('')
    setLineItems([])
    setOrder(null)
    setDepositOrder(null)
    clearInterval(intervalId)
    setShowCloseButton(false)

    if (intentId && camp.stripe_reader_id) {
      api.Terminal.cancelCurrentPayment(camp.id, intentId).catch((err) => {
        console.error(err)
      })
    }
  }

  useEffect(() => {
    if (!open) handleClosed()
  }, [open])

  useEffect(() => {
    if (intentId) onChangeIntentId(intentId)
  }, [intentId])

  useEffect(() => {
    setAmountPaid(paidInCents / 100)
  }, [paidInCents])

  useEffect(() => {
    setAmountOwed(owedInCents / 100)
  }, [owedInCents])

  const fetchProducts = async (): Promise<void> => {
    api.Products.forSites(
      camp.id,
      sites.map((site) => site.id)
    )
      .then(({ products: p }) => {
        setProducts(p)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const fetchOrder = useDebouncedCallback((orderId: string) => {
    if (!orderId) {
      setOrder(null)
      return
    }

    api.Orders.fetch(orderId, true)
      .then(({ order }: { order: IOrder }) => {
        setOrder(order)
        setIsTaxFeeEnabled(order.tax_fee_in_cents > 0)
        setIsCreditCardFeeEnabled(order.credit_card_rate > 0)
        setIsServiceFeeEnabled(paymentMethod !== 'cash')
        setLineItems(order.line_items)
        setPaymentMethods(order.payment_methods)
      })
      .catch((err) => {
        console.error(err)
      })
  }, 100)

  const buildOrder = useDebouncedCallback(() => {
    if (!camp || !campingStyle) return

    api.Orders.createPreview({
      order_id: orderId,
      camp_id: camp.id,
      camping_style_id: campingStyle.id,
      is_credit_card_fee_enabled: isCreditCardFeeEnabled,
      is_service_fee_enabled: isServiceFeeEnabled,
      service_rate_mode: serviceRateModeForPaymentType(paymentMethod),
      discount_slug: discountFromLineItems(lineItems)?.slug,
      line_items_attributes: lineItems?.map((li) => mapLineItemToParams(li, isTaxFeeEnabled)),
    })
      .then(({ order: { preview } }) => {
        setOrder(preview)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
        setIsLoading(false)
        //setErrorMessage('Something went wrong. Please reload the page')
      })
  }, 100)

  const buildDepositOrder = useDebouncedCallback(async () => {
    if (!camp || !campingStyle) return

    api.Orders.createPreview({
      order_id: orderId,
      camp_id: camp.id,
      camping_style_id: campingStyle.id,
      is_credit_card_fee_enabled: isCreditCardFeeEnabled,
      is_service_fee_enabled: isServiceFeeEnabled,
      service_rate_mode: serviceRateModeForPaymentType(paymentMethod),
      discount_slug: discountFromLineItems(lineItems)?.slug,
      line_items_attributes: depositLineItemParams(owedInCents, false),
    })
      .then(({ order: { preview } }) => {
        setDepositOrder(preview)
      })
      .catch((err) => {
        console.error(err)
      })
  }, 100)

  const handleClickTakeCashPayment = async (e) => {
    api.Orders.createPayment(
      {
        order_id: orderId,
        camp_id: camp.id,
        camping_style_id: campingStyle.id,
        is_credit_card_fee_enabled: isCreditCardFeeEnabled,
        discount_slug: discountFromLineItems(lineItems)?.slug,
        line_items_attributes: lineItems.map((li) => mapLineItemToParams(li, isTaxFeeEnabled)),
        partial_line_items_attributes: depositOrder?.line_items.map((li) =>
          mapLineItemToParams(li, false)
        )
      },
      {
        isForGroup: !!groupedReservation
      }
    )
      .then((resp) => {
        setPaymentStatus('success')
        setDepositOrder(null)
        onClose()
      })
      .catch((err) => {
        console.error(err)
        showNotification({ type: 'error', title: err.message })
      })
  }

  const shownProducts = products?.filter(
    (addon) =>
      !['always-applied', 'hidden'].includes(addon.kind) && addon.addable_camping_style_id === null
  )
  const summaryOrder = isDeposit ? depositOrder : order

  const isShowingProducts = !groupedReservation

  return (
    <Modal
      open={open}
      onClose={onClose}
      className='z-40'
      maxWidth={isShowingProducts ? 'max-w-7xl' : 'max-w-5xl'}>
      <>
        <div className='text-xl font-bold mb-4'>Accept payment</div>
        <div className='flex w-full flex-col'>
          <div className='flex flex-row justify-between gap-6'>
            <div className='flex-grow flex-1 flex flex-col'>
              {isShowingProducts ? (
                <div className='overflow-y-scroll h-full'>
                  <AdminAddOns
                    addOns={shownProducts}
                    primaryColor={camp?.primary_color}
                    lineItems={lineItems}
                    setLineItems={setLineItems as (v: Array<Partial<ILineItem>>) => void}
                    handleProductClick={handleProductClick}
                  />
                </div>
              ) : (
                <div className='flex-grow block' />
              )}
              <div className='flex gap-8 flex-wrap'>
                {campingStyle?.tax_rate > 0 && (
                  <div className='flex items-center gap-x-2'>
                    <div className='text-sm md:text-base'>
                      Add tax ({dynamicRound(campingStyle?.tax_rate * 100)}%)
                    </div>
                    <Toggle checked={isTaxFeeEnabled} onChange={setIsTaxFeeEnabled} />
                  </div>
                )}
                {campingStyle?.credit_card_rate > 0 && (
                  <div className='flex items-center gap-x-2'>
                    <div className=' text-sm md:text-base'>
                      Add credit card fee ({dynamicRound(campingStyle?.credit_card_rate * 100)}
                      %)
                    </div>
                    <Toggle checked={isCreditCardFeeEnabled} onChange={setIsCreditCardFeeEnabled} />
                  </div>
                )}
              </div>
            </div>
            {isLoading ? (
              <Transition
                show={isLoading}
                enter='transition-opacity ease-out duration-75'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-in duration-75'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='z-10 absolute w-full h-full bg-white opacity-50 flex items-center justify-center'>
                  <Spinner size={6} />
                </div>
              </Transition>
            ) : (
              <div className='flex flex-col space-y-4 w-full flex-shrink-0 max-w-mdlg'>
                <div className={classNames(isShowingProducts ? 'h-72' : 'h-24')}>
                  {order?.line_items?.map((line_item) => (
                    <div className='rounded-md border border-gray-200 px-4 py-2 mb-1.5'>
                      <div className='mb-1 flex justify-between items-center'>
                        <div className='flex flex-col'>
                          <label
                            htmlFor='name'
                            className='flex items-center text-base text-gray-900'>
                            <span>{line_item.label}</span>
                            <XMarkIcon className='w-4 h-4 inline mx-1 mt-0.5' />
                            <span>{line_item.quantity}</span>
                          </label>
                        </div>
                        <span className='text-gray-900'>
                          <span>
                            {formatMoneyFromCents(line_item.total_amount_in_cents, camp.currency)}
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className=''>
                  <div className='flex flex-col justify-end mb-4'>
                    {isDeposit && (
                      <div className='flex flex-col w-full mb-4'>
                        <div className='text-sm text-gray-400'>Deposit amount</div>
                        <InputFieldWithCurrency value={amountOwed} readOnly disabled />
                      </div>
                    )}
                    <div className='w-full'>
                      <OrderSummary order={summaryOrder} camp={camp} isPreview />
                    </div>
                  </div>
                  <div className=''>
                    <>
                      <div className=''>
                        <div className='mb-2 flex justify-between items-center'>
                          <label htmlFor='name' className='inline font-bold text-md text-gray-900'>
                            Payment method
                          </label>
                        </div>
                        <PaymentTypeSelector
                          options={['card-input', 'link', 'cash']}
                          selectedOption={paymentMethod}
                          onChange={handleChangePaymentType}
                          />
                        {paymentMethod === 'cash' ? (
                          <div className='flex justify-end items-center mt-6'>
                            <Button variant='blue' onClick={handleClickTakeCashPayment}>
                              Take offline payment
                            </Button>
                          </div>
                        ) : paymentMethod === 'link' ? (
                          <PaymentLinkForm
                            order={order}
                            email={(reservation || groupedReservation)?.user?.email || ''}
                            campingStyleId={campingStyle?.id}
                            onSuccess={() => setPaymentStatus('success')}
                            isTaxFeeEnabled={isTaxFeeEnabled}
                            isCreditCardFeeEnabled={isCreditCardFeeEnabled}
                            discountSlug={discountFromLineItems(lineItems)?.slug}
                            lineItems={lineItems}
                            depositLineItems={depositOrder?.line_items}
                          />
                        ) : (
                          <StripeCardForm
                            ctaText={`Charge ${formatMoneyFromCents(
                              summaryOrder?.unpaid_total_in_cents
                            )}`}
                            setPaymentIntentId={setIntentId}
                            paymentIntentId={intentId}
                            onSuccess={() => setPaymentStatus('success')}
                            order={order}
                            isCamperPresent={!!reservation?.user?.camper_id}
                            campingStyleId={campingStyle?.id}
                            isTaxFeeEnabled={isTaxFeeEnabled}
                            isCreditCardFeeEnabled={isCreditCardFeeEnabled}
                            discountSlug={discountFromLineItems(lineItems)?.slug}
                            lineItems={lineItems}
                            depositLineItems={depositOrder?.line_items}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            paymentMethods={paymentMethods}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                            setPaymentStatus={setPaymentStatus}
                            setIntervalId={setIntervalId}
                            setShowCloseButton={setShowCloseButton}
                          />
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {paymentStatus != null || showCloseButton === true ? (
          <Confirmation
            paymentStatus={paymentStatus}
            setPaymentStatus={setPaymentStatus}
            paymentMethod={paymentMethod}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            onCtaClick={onClose}
            showCloseButton={showCloseButton}
            setShowCloseButton={setShowCloseButton}
            campId={camp.id}
            paymentIntentId={intentId}
            intervalId={intervalId}
          />
        ) : null}
      </>
    </Modal>
  )
}

const Confirmation = ({
  paymentStatus,
  setPaymentStatus,
  onCtaClick,
  paymentMethod,
  errorMessage,
  setErrorMessage,
  showCloseButton,
  setShowCloseButton,
  campId,
  paymentIntentId,
  intervalId
}) => {
  let text = ''

  if (paymentMethod === 'link') {
    setShowCloseButton(false)
    text = 'Payment link sent'
  } else if (paymentStatus === 'success') {
    setShowCloseButton(false)
    text = `Payment successful`
  } else if (paymentStatus === 'failed') {
    setShowCloseButton(false)
    text = errorMessage || 'Something went wrong. Please try again'
  } else if (paymentStatus === 'in_progress' || showCloseButton === true) {
    text = `Tap or insert card to pay`
  }

  return (
    <div
      className='bg-white left-0 top-16 absolute bg-opacity-90 z-20 w-full flex flex-col justify-center items-center'
      style={{ height: '92%' }}>
      <div className='px-10 py-7 bg-gray-100 border border-gray-200 flex flex-col items-start rounded-lg'>
        <div className='flex justify-center items-center'>
          {paymentStatus == 'in_progress' || showCloseButton == true ? (
            <WifiIcon className='w-7 h-7 text-gray-700 transform rotate-90 animate-pulse' />
          ) : (
            <></>
          )}
          {paymentStatus == 'failed' && (
            <ExclamationTriangleIcon className='w-7 h-7 text-yellow-500' />
          )}
          {(paymentStatus == 'success' || paymentMethod === 'link') && (
            <CheckCircleIcon className='w-7 h-7 text-green-500' />
          )}
          <div
            className={classNames(
              'text-left font-semibold ml-4 text-gray-700 max-w-sm',
              paymentStatus === 'in_progress' && 'animate-pulse'
            )}>
            {text}
          </div>
        </div>

        <div className='mt-6 self-center'>
          <Button
            variant='white'
            type='button'
            className={!paymentStatus && 'invisble'}
            onClick={() => {
              if (paymentStatus === 'success') {
                onCtaClick()
              } else {
                if (paymentStatus === 'in_progress' && campId.stripe_reader_id) {
                  api.Terminal.cancelCurrentPayment(campId, paymentIntentId)
                  clearInterval(intervalId)
                }
                setPaymentStatus(null)
                setShowCloseButton(false)
                setErrorMessage('')
              }
            }}>
            {paymentStatus === 'success'
              ? 'Close'
              : paymentStatus === 'failed'
              ? 'Try another payment method'
              : 'Cancel'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PosModal
