import { ReservationProps } from '../interfaces'
import IGroupedReservation from '../interfaces/IGroupedReservation'
import { destroy as destroyReq, get, patch, post } from './utils/requests'

interface IGroupedResponse {
  grouped_reservation: IGroupedReservation
  reservations: ReservationProps[]
}

const find = async (id: string): Promise<IGroupedReservation> =>
  get(`/api/grouped_reservations/${id}`)

const updateIntent = async (id: string, intentId: string): Promise<IGroupedReservation> =>
  patch(`/api/grouped_reservations/${id}`, { stripe_intent_id: intentId })

const destroy = async (id: string): Promise<IGroupedResponse> =>
  destroyReq(`/api/grouped_reservations/${id}`)

const updateAggregate = async (
  id: string,
  data: {
    amount_owed_by_group_in_cents: number,
    amount_paid_by_group_in_cents: number
  }
): Promise<IGroupedResponse> => patch(`/api/grouped_reservations/${id}`, data)

const paymentLink = async (id: string, data: { email: string; amount: number }) =>
  post(`/api/grouped_reservations/${id}/payment_links`, data)

const GroupedReservations = {
  destroy,
  find,
  paymentLink,
  updateAggregate,
  updateIntent,
}

export default GroupedReservations
