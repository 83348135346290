import React, { useEffect, useState } from "react"
import IPayment from "../interfaces/IPayment"
import { ReservationProps } from "../interfaces"

export type LiveWebAppNotification = {
  camp_id: string
  type: "payment",
  data: LiveWebAppNotificationDataForPayment
}

export type LiveWebAppNotificationDataForPayment = {
  payment: IPayment
  reservation?: ReservationProps
}

type Channel = {
  received: (event: any) => void
  connected: () => void
  disconnected: () => void
  rejected: () => void
  consumer: {
    connection: {
      disconnected: boolean
    }
  }
}

const useWebsocket = (
  onReceive: (event: any) => void,
  channel: Channel,
  dep: React.DependencyList
) => {
  const [isConnected, setIsConnected] = useState<boolean>(null)

  useEffect(() => {
    setTimeout(() => {
      if (isConnected == null && channel.consumer.connection.disconnected) {
        setIsConnected(false)
      }
    }, 10000)

    if (!channel.consumer.connection.disconnected) setIsConnected(true)

    channel.connected = () => setIsConnected(true)
    channel.disconnected = () => setIsConnected(false)
    channel.rejected = () => setIsConnected(false)
  }, [])

  useEffect(() => {
    channel.received = onReceive
  }, dep)

  return [isConnected]
}

export default useWebsocket
