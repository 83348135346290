import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AdminContext } from '../../../contexts/AdminContext'
import { ReservationProps } from '../../../interfaces'
import { adminPages } from '../../../types/adminPages'
import Api2 from '../../../api2'
import { longTermReservationKind } from '../../../constants'
import moment, { Moment } from 'moment'
import Table, { RowItem } from './Table'
import { FormProvider } from '../../../contexts/FormContext'
import EditInvoice from './EditInvoice'
import IInvoice from '../../../interfaces/IInvoice'
import { filterOptions } from './InvoiceFilterOptions'
import { useDebouncedCallback } from 'use-debounce'
import { INVOICE_STATUSES } from '../../../decorators/InvoiceDecorator'
import roundToMaxPrecision from '../../../utils/roundToMaxPrecision'
import { reportFilterCookie } from '../../../constants/cookies'
import { useCookies } from 'react-cookie'

export const mapInvoiceToRowItem = (invoice: IInvoice): RowItem => ({
  isSiteOnly: false,
  amountOwed: invoice.order?.total_in_cents / 100,
  status: invoice.status,
  invoice,
  invoiceId: invoice.id,
  site: invoice.reservation?.site,
  siteId: invoice.reservation?.site_id,
  siteName: invoice.reservation?.site?.name,
  siteSort: invoice.reservation?.site?.sort,
  reservation: invoice.reservation,
  reservationId: invoice.reservation?.id,
  reservationStartDate: moment(invoice.reservation?.start_date),
  reservationEndDate: moment(invoice.reservation?.end_date),
  reservationRecurringKind: invoice.reservation.kind === null && invoice.reservation.long_term_reservation_id ? "seasonal" : invoice.reservation?.long_term_reservation?.kind,
  camperName: invoice.recipient_name || [invoice.order?.camper?.first_name || invoice.reservation?.user?.first_name, invoice.order?.camper?.last_name || invoice.reservation?.user?.last_name].filter((str) => str).join(' '),
  lastMeterReading: invoice.reservation?.last_meter_reading || invoice.reservation?.site?.last_meter_reading,
  currentMeterReading: invoice.reservation?.meter_reading || invoice.reservation?.site?.last_meter_reading,
  electricUsed: roundToMaxPrecision(Number(invoice.reservation?.meter_reading) - Number(invoice.reservation?.last_meter_reading)),
  electricSubtotal: electricSubtotalFor(invoice),
  invoiceSendDate: moment(invoice.send_date),
  invoiceDueDate: moment(invoice.due_date),
})

const electricSubtotalFor = (invoice: IInvoice): number => {
  const electricLineItem = invoice.order?.line_items?.find((li) => li.kind === 'electric')
  if (electricLineItem) {
    return electricLineItem.total_amount_in_cents / 100
  } else {
    return invoice.reservation?.electricity_fee_in_cents / 100
  }
}

export interface IInvoicesForm {
  electric_rate: number
  rowItems: RowItem[]
}

/** @deprecated */
export type iInvoice = ReservationProps & {
  invoice_status: INVOICE_STATUSES
  invoice_date: string
  recurring_price_in_cents: number
  electric_used: number
  last_meter_reading: number
  recurring_kind: longTermReservationKind
  is_site_only: boolean
}

const mapFilterName = (filter: string) => {
  switch (filter) {
    case 'start_date':
      return 'reservation_start_date'
    case 'end_date':
      return 'reservation_end_date'
    default:
      return null
  }

}

interface ComponentProps {
  setPage: (page: adminPages) => void // TODO:
  setSelectedReservation: (reservation: ReservationProps) => void
}

const InvoicesPage = (props: ComponentProps) => {
  const { camp, sites, campingStyles, updateLocalReservations, reservationStates } = useContext(AdminContext)
  const [rowItems, setRowItems] = useState<RowItem[]>([])
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isShowingAllSites, setIsShowingAllSites] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState<Moment>(moment())
  const [selectedEndDate, setSelectedEndDate] = useState<Moment>(moment().add(14, 'days'))
  const [selectedInterval, setSelectedInterval] = useState<string>('Next 2 weeks')
  const [cookies, setCookie] = useCookies([reportFilterCookie])
  const [selectedFilterBy, setSelectedFilterBy] = useState<string>(cookies[reportFilterCookie] || "due_date")
  const [sortBy, setSortBy] = useState('invoice_date')
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0])
  const [isAscending, setIsAscending] = useState(false)

  useEffect(() => {
    if (!(selectedStartDate && selectedEndDate)) return
    setIsLoading(true)

    fetchInvoices()
  }, [selectedEndDate, selectedFilterBy, reservationStates])
  // TODO: ^useEffect on `reservationStates` not best experience, since it'll refresh unecessarily
  // if online reservation comes in, for example, but best we can do since it's difficult to pass
  // callback functions to the showPaymentModal function. We should optimize this.

  const fetchInvoices = useDebouncedCallback(() => {
    if (!(selectedStartDate && selectedEndDate)) {
      setIsLoading(false)
      return
    }

    Api2.Invoices.fetchForDates(
      camp.id,
      {
        from: selectedStartDate,
        until: selectedEndDate,
        filter: mapFilterName(selectedFilterBy),
        include_seasonal_reservations: true,
        includeArchived: true
      }
    )
      .then(({ invoices: resp }) => {
        setRowItems(resp.map(mapInvoiceToRowItem))
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, 100)

  const refreshLocalInvoices = () => {
    fetchInvoices()
  }

  return (
    <div className='flex-grow sm:mx-0 pb-12'>
      {selectedInvoice ? (
        <>
          <EditInvoice
            invoice={selectedInvoice}
            setSelectedInvoice={setSelectedInvoice}
            updateLocalInvoices={refreshLocalInvoices}
            disabled={false}
            site={selectedInvoice.reservation?.site}
            campingStyle={campingStyles.find((cs) => cs.id === selectedInvoice.reservation?.site?.camping_style_id)}
            isProcessing={isProcessing}
            customerView={false}
          />
        </>
      ) : (
        <>
          <div className='text-2xl mb-6 font-semibold'>Invoices</div>
          <MemoizedFormProvider electric_rate={camp.electricity_rate} rowItems={rowItems}>
            <Table
              rowItems={rowItems}
              setRowItems={setRowItems}
              setSelectedInvoice={setSelectedInvoice}
              isShowingAllSites={isShowingAllSites}
              refreshLocalInvoices={refreshLocalInvoices}
              setIsShowingAllSites={setIsShowingAllSites}
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              sortBy={sortBy}
              setSortBy={setSortBy}
              isAscending={isAscending}
              setIsAscending={setIsAscending}
              isLoading={isLoading}
              selectedFilterOption={selectedFilterOption}
              setSelectedFilterOption={setSelectedFilterOption}
              selectedFilterBy={selectedFilterBy}
              setSelectedFilterBy={setSelectedFilterBy}
            />
          </MemoizedFormProvider>
        </>
      )}
    </div>
  )
}

const MemoizedFormProvider = ({ children, electric_rate, rowItems }) => {
  const memoizedValue = useMemo(() => ({
    electric_rate,
    rowItems
  }), [electric_rate, rowItems])

  return <FormProvider init={memoizedValue}>{children}</FormProvider>
}

export default InvoicesPage
