import React, { useState, useEffect, useReducer, useContext } from 'react'
import { Form, Icon, Message } from 'semantic-ui-react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import api from '../../api'
import { Moment } from 'moment'
import { CampingStyleProps, CampProps, SiteProps, UserProps } from '../../interfaces'
import { userParamsReducer, userParamsInitialState, userParamsActionTypes } from '../../reducers/userParamsReducer'
import { validElectric, validRigType } from '../../types'
import Button from '../ui/Button'
import * as Sentry from '@sentry/react'
import api2 from '../../api2'
import { UpsertData } from '../../api2/payment_intents'
import { CAMPING_STYLES, discountType, PAYMENT_MODES } from '../../constants'
import { BookerContext } from '../../contexts/BookerContext'
import isValidEmail from '../../utils/isValidEmail'
import { useDebouncedCallback } from 'use-debounce'
import ICamper from '../../interfaces/ICamper'
import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { post } from '../../api2/utils/requests'
import { IPriceDetails } from '../../api2/price_details'
import DiscountProps from '../../interfaces/DiscountProps'
import CampDiscountProps from '../../interfaces/CampDiscountProps'
import CampingStyleDiscountProps from '../../interfaces/CampingStyleDiscountProps'
import { bySort } from '../../utils/sorts'
import { formatPhoneNumber } from '../../utils'
import { useCookies } from 'react-cookie'
import priceDetailsFor from '../../utils/priceDetailsFor'
declare let gtag: Function

const flattenNotesFrom = (notesAndCustomAnswers: Record<string, string>) => {
  const notes = notesAndCustomAnswers['notes']
  delete notesAndCustomAnswers['notes']

  const customAnswers = Object.keys(notesAndCustomAnswers).map((key, idx) => {
    return `<h3>${key}</h3><p>${notesAndCustomAnswers[key]}</p>`
  })

  return [notes, ...customAnswers].join('<br/><br/>').replace(/^<br\/><br\/>/, '')
}

interface ComponentProps {
  camp: CampProps

  site: SiteProps | null
  startDate: Moment
  endDate: Moment
  setIsPaying: any

  discountType: discountType
  discountId: string

  userRigType: validRigType
  userRigLength: number
  userElectric: validElectric
  userPassengers: number
  userKidCampers: number

  onError: (errorMessage: string) => void
}

const CheckoutForm = (props: ComponentProps) => {
  const {
    camp,
    campingStyle,
    selectedAddOns,
    groupedReservationId,
    selectedInterval,
    couponCode,
    secondaryColor,
    userRigYear,
    userPets,
    trackForCamp,
    trackForPark,
    trackAdConversionForCamp,
  } = useContext(BookerContext)

  const [intentId, setIntentId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [validCard, setValidCard] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()
  const [camper, setCamper] = useState<ICamper>()
  const [isBlocked, setIsBlocked] = useState<boolean>(false)
  const [isLoadingCamper, setIsLoadingCamper] = useState<boolean>(false)
  const [isLoadingIntent, setIsLoadingIntent] = useState(false)
  const [userParams, userParamsDispatch] = useReducer(userParamsReducer, userParamsInitialState)
  const [notesAndCustomAnswers, setNotesAndCustomAnswers] = useState({})
  const [hasBegunCheckout, setHasBegunCheckout] = useState(false)
  const [cookies, setCookie] = useCookies(['from_ad'])
  const queryParams = new URLSearchParams(decodeURIComponent(window.location.search))
  const fromAdParam = queryParams.get('from_ad')
  const [fromAd, setFromAd] = useState(false)

  useEffect(() => {
    if (fromAdParam) {
      const expirationDate = new Date()
      expirationDate.setDate(expirationDate.getDate() + 30) // Set cookie to expire in 30 days

      setCookie('from_ad', 'true', { path: '/', expires: expirationDate })
    }

    if (fromAdParam || cookies.from_ad === 'true') {
      setFromAd(true)
    }
  }, [])

  const fetchCamper = useDebouncedCallback(() => {
    api2.Camps.findCamperByData({
      campId: camp.id,
      email: userParams.email,
      phone: userParams.phone,
    })
      .then(({ campers }) => {
        setCamper(campers[0])
        if (campers[0]?.blocked_at) setIsBlocked(true)
        setIsLoadingCamper(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoadingCamper(false)
      })
  }, 500)

  const checkForCamper = () => {
    if (!isValidEmail(userParams.email) && !userParams.phone) return

    setIsLoadingCamper(true)
    fetchCamper()
  }

  const selectedCampingStyle = (): CampingStyleProps => {
    if (groupedReservationId) {
      return camp?.camping_styles?.find((cs) => cs.id == props.site?.camping_style_id)
    } else {
      return campingStyle
    }
  }

  const buildPriceDetailParams = () => ({
    id: intentId,
    payment_mode: PAYMENT_MODES.ONLINE,
    camping_style_id: selectedCampingStyle().id,
    site_id: props.site?.id,
    start_date: props.startDate.toISOString(),
    end_date: props.endDate.toISOString(),
    user_electric: props.userElectric,
    user_passengers: props.userPassengers,
    user_kid_campers: props.userKidCampers,
    number_of_pets: userPets,
    user_rig_year: userRigYear,
    interval_id: selectedInterval?.id,
    add_ons: selectedAddOns,
    // NOTE: these should be on by default
    is_service_fee_applied: true,
    is_tax_enabled: true,
    is_passenger_fees_enabled: true,
    is_pets_fee_enabled: true,
    is_holiday_rate_enabled: true,
    is_weekend_fee_enabled: true,
    is_credit_card_fee_enabled: true,
    coupon_codes: [couponCode],
    ...discountDataForUpsert()
  })

  const discountDataForUpsert = (): {
    discount_type?: discountType
    discount_membership_id?: string
  } => {
    const discountSlug = props.discountType
    const memberId = props.discountId
    let discountParams = {}

    const csDiscount = selectedCampingStyle().discounts.find((discount) => discount.slug == discountSlug)
    const cDiscount = props.camp.discounts.find((discount) => discount.slug == discountSlug)

    let discount: DiscountProps
    let joinDiscount: CampDiscountProps | CampingStyleDiscountProps

    if (csDiscount) {
      discount = csDiscount
      joinDiscount = selectedCampingStyle().camping_style_discounts.find((cd) => cd.discount_id == csDiscount.id)
    } else if (cDiscount) {
      discount = cDiscount
      joinDiscount = props.camp.camp_discounts.find((cd) => cd.discount_id == cDiscount.id)
    }

    if (discount && joinDiscount) {
      if (joinDiscount.require_membership_id) {
        if (memberId) {
          discountParams = {
            discount_type: discountSlug,
            discount_slug: discountSlug,
            discount_membership_id: memberId,
          }
        } else {
          return {}
        }
      } else {
        discountParams = {
          discount_type: discountSlug,
          discount_slug: discountSlug,
          discount_membership_id: null,
        }
      }
    }

    return discountParams
  }

  useEffect(() => {
    if (!(props.site?.id && props.startDate && props.endDate)) return

    setErrorMessage('')
    setIsLoadingIntent(true)
  }, [props.site?.id, props.startDate, props.endDate, props.discountType, props.discountId])

  useEffect(() => {
    userParamsDispatch({
      type: userParamsActionTypes.vehicle_rig_type,
      payload: { vehicle_rig_type: props.userRigType },
    })

    userParamsDispatch({
      type: userParamsActionTypes.vehicle_rig_length,
      payload: {
        vehicle_rig_length: props.userRigLength ? `${props.userRigLength} ft` : props.userRigLength,
      },
    })

    userParamsDispatch({
      type: userParamsActionTypes.vehicle_electric,
      payload: { vehicle_electric: props.userElectric },
    })

    userParamsDispatch({
      type: userParamsActionTypes.vehicle_year,
      payload: { vehicle_year: userRigYear },
    })
  }, [props.userRigType, props.userRigLength, props.userElectric, userRigYear])

  useEffect(() => {
    props.setIsPaying(processing)
  }, [processing])

  useEffect(() => {
    if (
      userParams.first_name &&
      userParams.last_name &&
      isValidEmail(userParams.email) &&
      validCard
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [userParams, validCard])

  const createMarkup = (text: string) => {
    return { __html: text }
  }

  useEffect(() => {
    if (isBlocked) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    // Cleanup function to remove the class when the component is unmounted or hidden
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isBlocked]) // Runs only when `isBlocked` changes

  const handleCreditCardChange = async (event) => {
    setValidCard(!event.error && event.complete)
    setErrorMessage(event.error ? event.error.message : '')
    checkForCamper()
  }

  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault()
      setProcessing(true)
      if (!stripe || !elements) {
        console.log('[STRIPE] stripe or elements not loaded')
        setErrorMessage(
          "There's an issue loading the Stripe payment processor. Please reload the page and try again."
        )
        return
      }

      const pdParams = buildPriceDetailParams()

      const { price_detail: pd } = (await post(
        `/sites/${props.site?.id}/price_details`,
        pdParams
      )) as { price_detail: IPriceDetails }
      const addedSiteIds = pd.additional_fees
        ?.filter((fee) => fee.type == 'camping-style')
        .flatMap((fee) => fee.site_ids)

      await api.mockCreateReservation(
        props.site?.id,
        props.startDate.toISOString(),
        props.endDate.toISOString(),
        props.userPassengers,
        props.userKidCampers,
        // TODO: pass in userPassengers as reservation#passengers field
        userParams as UserProps, // TODO: migrate this to ICamper
        selectedInterval?.id,
        groupedReservationId,
        flattenNotesFrom(notesAndCustomAnswers),
        pdParams,
        addedSiteIds
      )

      setErrorMessage('')

      const { id, clientSecret } = await upsertIntent(pdParams)
  
      const payload = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: userParams.email,
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })

      if (payload.error) {
        let stripeMessage = payload.error.message

        if (payload.error.code == 'processing_error') {
          stripeMessage += ` If you continue to experience issues, please give us a call at ${props.camp.phone} to book directly.`
        }

        throw Error(stripeMessage)
      }

      setErrorMessage('')

      trackForPark('purchase', { value: pd.total, currency: camp.currency })
      
      if (fromAd || fromAdParam) {
        trackForCamp('purchase_ad', { value: pd.total, currency: camp.currency })
        // trackAdConversionForCamp('conversion', { value: pd.total, currency: camp.currency })
        gtag('event', 'conversion', {
          send_to: camp.google_ad_conversion_id,
          value: pd.total,
          currency: camp.currency,
        })
      }
      trackForCamp('purchase', { value: pd.total, currency: camp.currency })

      const reservation = await createReservation(id, pdParams, addedSiteIds)
      
      window.location.href = `/reservations/${reservation.id}`
    } catch (err) {
      Sentry.captureException(err)

      setDisabled(true)
      setErrorMessage(err.message)
      props.onError(err.message)
      setProcessing(false)
    }
  }

  const upsertIntent = async (data: UpsertData) => {
    setIsLoadingIntent(true)
    const intent: { id: string; clientSecret: string } = await api2.PaymentIntents.upsert(data)

    if (!intent.clientSecret) throw new Error('Missing client secret from payment intent response')

    setIntentId(intent.id)
    setIsLoadingIntent(false)
    return { id: intent.id, clientSecret: intent.clientSecret }
  }

  const createReservation = async (paymentIntentId: string, pdParams: any, addedSiteIds: string[]) =>
    api.createReservation(
      paymentIntentId,
      props.site?.id,
      selectedCampingStyle().id,
      props.startDate.toISOString(),
      props.endDate.toISOString(),
      props.userPassengers,
      props.userKidCampers,
      userPets || userParams.pets_info,
      // TODO: pass in userPassengers as reservation#passengers field
      userParams as UserProps, // TODO: migrate this to ICamper
      selectedInterval?.id,
      groupedReservationId,
      flattenNotesFrom(notesAndCustomAnswers),
      pdParams,
      addedSiteIds
    )

  const isDisablingError = () => {
    try {
      if (!errorMessage || typeof errorMessage.includes != 'function') return false

      return errorMessage.includes('Try again') ? false : !!errorMessage
    } catch (err) {
      Sentry.captureException(err, { contexts: { info: { errorMessage } } })
      return false
    }
  }

  // TODO: remove these once we convert custom fields
  const isReservingBoatSlip = selectedCampingStyle().name === CAMPING_STYLES.BOAT_SLIP
  const isReservingRVSite = selectedCampingStyle().name === CAMPING_STYLES.RV

  return (
    <Form onSubmit={handleSubmit} loading={processing} error={!!errorMessage}>
      <Form.Group widths='equal'>
        <Form.Input
          fluid
          required
          label='First Name'
          onChange={(e) => {
            setErrorMessage('')
            if (!hasBegunCheckout) {
              trackForCamp('begin_checkout')
              trackForPark('begin_checkout')
              setHasBegunCheckout(true)
            }
            userParamsDispatch({
              type: userParamsActionTypes.first_name,
              payload: { first_name: e.target.value },
            })
          }}
        />
        <Form.Input
          fluid
          required
          label='Last Name'
          onChange={(e) => {
            setErrorMessage('')
            userParamsDispatch({
              type: userParamsActionTypes.last_name,
              payload: { last_name: e.target.value },
            })
          }}
        />
      </Form.Group>

      <Form.Group widths='equal'>
        <Form.Input
          fluid
          required
          label='Email'
          onChange={(e) => {
            setErrorMessage('')
            userParamsDispatch({
              type: userParamsActionTypes.email,
              payload: { email: e.target.value },
            })
            checkForCamper()
          }}
        />
      </Form.Group>

      <Form.Group widths='equal'>
        <Form.Input
          fluid
          required
          label='Phone'
          onChange={(e) => {
            setErrorMessage('')
            userParamsDispatch({
              type: userParamsActionTypes.phone,
              payload: { phone: e.target.value },
            })
            checkForCamper()
          }}
          onBlur={(e) => {
            if (e.target.value.match(/\d/g)?.length < 10) {
              setErrorMessage('Please enter a valid phone number with area code')
            } else {
              userParamsDispatch({
                type: userParamsActionTypes.phone,
                payload: { phone: formatPhoneNumber(e.target.value) },
              })
            }
          }}
          value={userParams.phone}
        />
      </Form.Group>

      {/* TODO: please remove this after we use custom fields for these */}
      {isReservingBoatSlip && (
        <>
          <div className='py-4 font-semibold'>Boat info</div>
          <Form.Group widths='equal'>
            <Form.Input
              fluid
              required
              label='Boat year'
              onChange={(e) => {
                setErrorMessage('')
                userParamsDispatch({
                  type: userParamsActionTypes.vehicle_year,
                  payload: { vehicle_year: e.target.value },
                })
              }}
              value={userParams.vehicle_year}
            />
            <Form.Input
              fluid
              required
              label='Boat model'
              onChange={(e) => {
                setErrorMessage('')
                userParamsDispatch({
                  type: userParamsActionTypes.vehicle_make,
                  payload: { vehicle_make: e.target.value },
                })
              }}
              value={userParams.vehicle_make}
            />
            <Form.Input
              fluid
              required
              label='Boat color'
              onChange={(e) => {
                setErrorMessage('')
                userParamsDispatch({
                  type: userParamsActionTypes.vehicle_color,
                  payload: { vehicle_color: e.target.value },
                })
              }}
              value={userParams.vehicle_color}
            />
          </Form.Group>
        </>
      )}

      {/* TODO: Same here: convert this to a custom field */}
      {props.camp.requires_license_plate && (isReservingRVSite || isReservingBoatSlip) && (
        <Form.Group widths='equal'>
          {isReservingBoatSlip && (
            <Form.Input
              fluid
              required
              label='Boat registration number'
              onChange={(e) => {
                setErrorMessage('')
                setNotesAndCustomAnswers({
                  ...notesAndCustomAnswers,
                  boat_registration: e.target.value,
                })
              }}
              value={notesAndCustomAnswers['boat_registration']}
            />
          )}

          <Form.Input
            fluid
            required
            label={isReservingBoatSlip ? 'Trailer license plate' : 'License plate'}
            onChange={(e) => {
              setErrorMessage('')
              userParamsDispatch({
                type: userParamsActionTypes.license_plate,
                payload: { license_plate: e.target.value },
              })
            }}
            value={userParams.license_plate}
          />
        </Form.Group>
      )}

      {selectedCampingStyle().custom_fields?.sort(bySort)?.map((field) => (
        <>
          <Form.Group widths='equal'>
            <Form.Input
              fluid
              required={field.required}
              label={field.label}
              onChange={(e) => {
                setErrorMessage('')

                const actionType = `UPDATE_${field.camper_attribute_name?.toUpperCase()}`

                if (Object.values(userParamsActionTypes).includes(actionType)) {
                  userParamsDispatch({
                    type: actionType,
                    payload: { [field.camper_attribute_name]: e.target.value },
                  })
                } else {
                  setNotesAndCustomAnswers({
                    ...notesAndCustomAnswers,
                    [field.label]: e.target.value,
                  })
                }

              }}
              value={notesAndCustomAnswers[field.label]}
            />
          </Form.Group>
        </>
      ))}

      <Form.Field required>
        <label>Payment Information</label>
        <div className='mb-4 rounded-md border border-gray-200 px-4'>
          <CardElement id='card-element' onChange={handleCreditCardChange} />
        </div>
      </Form.Field>

      {selectedCampingStyle().refund_policy && (
        <Message info size='small' className='whitespace-pre-wrap flex items-start'>
          <Icon name='info circle' />
          <div dangerouslySetInnerHTML={createMarkup(selectedCampingStyle().refund_policy)}></div>
        </Message>
      )}

      {selectedCampingStyle().legal_acknowledgements && (
        <>
          <div className='pt-2.5' />
          {selectedCampingStyle().legal_acknowledgements.map((acknowledgement, id) => {
            return (
              <div className='relative flex items-start py-2'>
                <div className='flex h-5 items-center'>
                  <input
                    id={id.toString()}
                    required
                    aria-describedby='comments-description'
                    name='legal_acknowledgement'
                    type='checkbox'
                    className='h-4.5 w-4.5 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer'
                  />
                </div>
                <div className='ml-3 text-md'>
                  <label
                    htmlFor='legal_acknowledgement'
                    dangerouslySetInnerHTML={createMarkup(acknowledgement)}
                    className='text-gray-600'></label>
                </div>
              </div>
            )
          })}
        </>
      )}

      <Message error content={errorMessage} />

      <Button
        style={{ backgroundColor: secondaryColor }}
        className={`${
          isDisablingError() || !!processing || !!disabled ? '' : 'hover:opacity-80'
        } text-white w-full mt-4`}
        size='large'
        type='submit'
        disabled={isDisablingError() || !!processing || !!disabled || isLoadingCamper}>
        Purchase Reservation
      </Button>
      <div className='mt-2.5 mb-4 text-sm'>
        By submitting this form, I agree to{' '}
        {props.camp.rules && (
          <>
            the{' '}
            <a
              href={`/camps/${props.camp.slug}/rules`}
              target='_blank'
              className='text-blue-500 hover:text-blue-400 underline hover:underline'>
              Rules at {props.camp.name}
            </a>{' '}
            and{' '}
          </>
        )}
        authorize this payment to the campground via{' '}
        <a
          href='https://stripe.com/checkout/legal'
          target='_blank'
          className='text-blue-500 hover:text-blue-400 underline hover:underline'>
          Stripe
        </a>
        .
      </div>
      {isBlocked && (
        <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-600 bg-opacity-95 z-50'>
          <div className='text-center'>
            <NoSymbolIcon className='w-16 h-16 text-white mx-auto mb-4' />
            <p className='text-white text-lg font-medium max-w-lg mx-4'>
              Due to previous violations of our{' '}
              <a
                href={`/camps/${props.camp.slug}/rules`}
                target='_blank'
                className='text-blue-400 hover:text-blue-500 underline hover:underline'>
                Campground Rules
              </a>
              , you are no longer permitted to stay at {props.camp.name}. If you think this was a
              mistake, please contact us directly.
            </p>
          </div>
        </div>
      )}
    </Form>
  )
}

export default CheckoutForm
