import React, { Fragment, useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import Spinner from "../Spinner"

interface ComponentProps {
  loadingMessages: string[]
  successMessage: string
}

const ProcessingState = ({ loadingMessages, successMessage }: ComponentProps) => {
  const [isShowing, setIsShowing] = useState(true)
  const [messageIndex, setMessageIndex] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    const handleHide = () => {
      setIsShowing(false)
      setTimeout(() => {
        setMessageIndex((prevIndex) => {
          const nextIndex = prevIndex + 1
          if (nextIndex < loadingMessages.length) {
            setIsShowing(true)
          }
          return nextIndex
        })
      }, 200) // Duration of leave transition
    }

    if (messageIndex < loadingMessages.length && !showSuccess) {
      const intervalId = setInterval(handleHide, 2000) // Change message every 2 seconds

      return () => clearInterval(intervalId) // Cleanup interval on component unmount
    }
  }, [messageIndex, loadingMessages.length, showSuccess])

  useEffect(() => {
    if (successMessage) {
      setIsShowing(false)
      setTimeout(() => {
        setShowSuccess(true)
        setShowSpinner(false)
        setIsShowing(true)
      }, 200) // Duration of leave transition
    }
  }, [successMessage])

  return (
    <div className="flex flex-col items-center py-16">
      <div className="mb-4">
        <Transition
          as={Fragment}
          show={showSpinner}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 rotate-[-120deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="h-full w-full flex items-center justify-center">
            {!showSuccess && <Spinner size={7} />}
          </div>
        </Transition>
      </div>
      <div className="w-96 text-gray-500">
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 rotate-[-120deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="h-full w-full flex items-center justify-center">
            {showSuccess ? (
              <div className="flex flex-col items-center text-green-500">
                <CheckCircleIcon className="mb-4 h-7 w-7 text-green-500" />
                <span>{successMessage}</span>
              </div>
            ) : (
              <span>{loadingMessages[messageIndex]}</span>
            )}
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default ProcessingState
