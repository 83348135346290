import React from 'react'
import { CampProps } from '../../../interfaces'
import classNames from '../../../utils/classNames'
import { formatMoneyFromCents } from '../../../utils/formatMoney'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import twoDecimalPlaces from '../../../utils/twoDecimalPlaces'

const RefundOrderSummary = ({
  paymentTotal,
  parkApplicationFees,
  stripeProcessingFees,
  yourNet,
  yourUpdatedNet,
  camp,
  externalUrl,
}: {
  paymentTotal: number
  parkApplicationFees: number
  stripeProcessingFees: number
  yourNet: number
  yourUpdatedNet: number
  camp: CampProps
  externalUrl: string
}) => {
  return (
    <div className={'px-3.5 py-2 rounded-md whitespace-nowrap mt-6 mb-2 bg-gray-50'}>
      <LineItemRow
        label={'Payment Total'}
        value={formatMoneyFromCents(paymentTotal, camp.currency)}
        className='font-bold'
        externalUrl={externalUrl}
      />
      <div className='ml-4'>
        <LineItemRow
          label={'Park application fees'}
          value={formatMoneyFromCents(parkApplicationFees, camp.currency)}
        />
        <LineItemRow
          label={'Stripe processing fees'}
          value={formatMoneyFromCents(stripeProcessingFees, camp.currency)}
        />
      </div>
      <LineItemRow label={'Your Net'} value={formatMoneyFromCents(yourNet, camp.currency)} />
      <Divider />
      {console.log('updatednet', Number(twoDecimalPlaces(yourUpdatedNet)))}
      <LineItemRow
        label={'Your updated Net'}
        value={formatMoneyFromCents(yourUpdatedNet, camp.currency)}
        className={'font-bold'}
        valueClassName={
          Number(twoDecimalPlaces(yourUpdatedNet)) < -0.5 ? 'text-red-500' : 'text-black'
        }
      />
    </div>
  )
}

const LineItemRow = ({
  label,
  value,
  className,
  externalUrl,
  valueClassName,
}: {
  label: string | React.ReactNode
  value: string
  className?: string
  externalUrl?: string
  valueClassName?: string
}) => {
  return (
    <div className={classNames('py-0.5 flex justify-between w-full', className)}>
      <div className='flex items-center'>
        <div>{label}</div>
        {externalUrl && (
          <ArrowTopRightOnSquareIcon
            className='w-4.5 h-4.5 text-gray-800 ml-1.5 mb-px cursor-pointer'
            onClick={() => window.open(externalUrl, '_blank')}
          />
        )}
      </div>
      <div className={valueClassName}>{value}</div>
    </div>
  )
}

const Divider = () => {
  return <div className='my-2 w-full h-0.25 bg-gray-300' />
}

export default RefundOrderSummary
