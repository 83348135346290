import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { titleCase } from '../../utils'
import classNames from '../../utils/classNames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Icon from '../../icon'
import { Moment } from 'moment'
import { DateTime } from 'luxon'
import ExternalSvg from './ExternalSvg'

export interface TimeSelectorOption {
  id?: string
  label?: string
  slug: string
  start_time?: string
  end_time?: string
  icon_url?: string
  sort?: number
  can_be_multiple_days?: boolean
}

interface ComponentProps {
  label?: string
  options: Array<TimeSelectorOption>
  selectedOption: TimeSelectorOption
  setSelectedOption: (v: TimeSelectorOption) => void
  startDate: Moment
  endDate: Moment
  campColor?: string
}

const TimeSelector = (props: ComponentProps) => {
  useEffect(() => {
    if (props.endDate?.diff(props.startDate, 'days') > 0) {
      props.setSelectedOption(
        props.options.find((option) => option.can_be_multiple_days) ||
          props.options.find((option) => option.slug === 'all-day')
      )
    }
  }, [props.endDate])

  return (
    <RadioGroup className='flex-grow max-w-2xl' value={props.selectedOption?.id} onChange={(id) => props.setSelectedOption(props.options.find((o) => o.id === id))}>
      <RadioGroup.Label className='text-base text-gray-900'>{props.label}</RadioGroup.Label>

      <div className='mt-4 flex flex-row gap-6'>
        {props.options.sort(byTiming).sort((a, b) => a.sort - b.sort).map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.id}
            disabled={
              props.endDate?.diff(props.startDate, 'days') > 0 &&
              (!option.can_be_multiple_days || option.slug === 'morning' || option.slug === 'afternoon')
            }
            className={({ checked, active }) =>
              classNames(
                props.endDate?.diff(props.startDate, 'days') > 0 &&
                  (!option.can_be_multiple_days || option.slug === 'morning' || option.slug === 'afternoon') &&
                  'opacity-40 cursor-not-allowed',
                checked ? 'border-transparent' : 'border-gray-300',
                'flex-grow relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }>
            {({ checked, active }) => (
              <>
                <div className='flex-1 flex justify-center'>
                  <div className='flex flex-col text-center justify-items-center'>
                    {option.icon_url ? (
                      <ExternalSvg
                        url={option.icon_url}
                        style={checked ? { color: props.campColor, fill: 'currentColor' } : { color: 'rgb(17, 24, 39)' }}
                      />
                    ) : (
                      <CampingStyleIcon
                        name={option.slug}
                        style={checked ? { color: props.campColor, fill: 'currentColor' } : { color: 'rgb(17, 24, 39)' }}
                      />
                    )}
                    <RadioGroup.Label
                      as='span'
                      className={classNames('block text-sm', checked ? 'font-bold' : 'font-medium')}
                      style={checked ? { color: props.campColor } : { color: 'rgb(17, 24, 39)' }}>
                      {option.label || titleCase(option.slug)}
                      <p className='text-sm font-normal'>{timingHours(option)}</p>
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames('h-5 w-5 absolute right-4', !checked ? 'invisible' : '')}
                  style={checked ? { color: props.campColor } : { color: 'rgb(17, 24, 39)' }}
                  aria-hidden='true'
                />
                <div
                  className={classNames(
                    'absolute -inset-px rounded-lg pointer-events-none border-2'
                  )}
                  style={
                    checked ? { borderColor: props.campColor } : { borderColor: 'transparent' }
                  }
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const slugToTime = (slug: string) => {
  switch (slug) {
    case 'morning':
      return [DateTime.fromFormat('09:00', "HH:mm"), DateTime.fromFormat('14:00', "HH:mm")]
    case 'afternoon':
      return [DateTime.fromFormat('14:00', "HH:mm"), DateTime.fromFormat('19:00', "HH:mm")]
    case 'all-day':
      return [DateTime.fromFormat('09:00', "HH:mm"), DateTime.fromFormat('19:00', "HH:mm")]
    default:
      return [DateTime.fromFormat('09:00', "HH:mm"), DateTime.fromFormat('19:00', "HH:mm")]
  }
}

const byTiming = (a: TimeSelectorOption, b: TimeSelectorOption) => {
  const startA = a.start_time ? DateTime.fromFormat(a.start_time, "HH:mm") : slugToTime(a.slug)[0]
  const startB = b.start_time ? DateTime.fromFormat(b.start_time, "HH:mm") : slugToTime(b.slug)[0]
  const endA = a.end_time ? DateTime.fromFormat(a.end_time, "HH:mm") : slugToTime(a.slug)[1]
  const endB = b.end_time ? DateTime.fromFormat(b.end_time, "HH:mm") : slugToTime(b.slug)[1]

  return startA.toMillis() - startB.toMillis() || endA.toMillis() - endB.toMillis()
}

const timingHours = (option: TimeSelectorOption) => {
  if (option.start_time && option.end_time) {
    const start_time = DateTime.fromFormat(option.start_time, "HH:mm")
    const end_time = DateTime.fromFormat(option.end_time, "HH:mm")
    const reformattedStartTime = start_time.minute === 0 ? start_time.toFormat("ha") : start_time.toFormat("h:mma")
    const reformattedEndTime = end_time.minute === 0 ? end_time.toFormat("ha") : end_time.toFormat("h:mma")
    return `${reformattedStartTime.toLowerCase()} - ${reformattedEndTime.toLowerCase()}`
  }

  if (option.slug == 'morning') {
    return '9am - 2pm'
  } else if (option.slug == 'afternoon') {
    return '2pm - 7pm'
  } else if (option.slug == 'all-day') {
    return '9am - 7pm'
  }
}

const svgForName = (name: string) => {
  switch (name) {
    case 'morning':
      return Icon.Morning
    case 'afternoon':
      return Icon.Afternoon
    case 'all-day':
      return Icon.AllDay
    default:
      return null
  }
}

const CampingStyleIcon = ({ name, style }: { name: string; style: any }) => {
  const IconSvg = svgForName(name)
  if (IconSvg) {
    return <IconSvg style={style} />
  } else {
    return <></>
  }
}

export default TimeSelector
