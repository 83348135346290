function generateConsistentNaturalHexColor(key: string) {
  if (!key || key.length === 0) {
    return '#cccccc'
  }

  // Function to convert a single component to a hex value
  const componentToHex = (c) => {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  // Simple hashing function to convert string to hue
  const stringToHue = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash // Convert to 32bit integer
    }
    return Math.abs(hash % 360) // Ensure hue is between 0 and 359
  }

  // Convert key string to hue
  const hue = stringToHue(key)
  // Set saturation to 70% for richer colors
  const saturation = 70
  // Set lightness to 60% to avoid dark and too pale colors
  const lightness = 60

  // Convert HSL to RGB
  const rgb = hslToRgb(hue, saturation / 100, lightness / 100)

  // Convert each component to hex and concatenate them with a "#" prefix
  return '#' + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2])
}

function hslToRgb(h, s, l) {
  let r, g, b
  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1
    if (t > 1) t -= 1
    if (t < 1 / 6) return p + (q - p) * 6 * t
    if (t < 1 / 2) return q
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
    return p
  }

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s
  const p = 2 * l - q
  h /= 360 // ensure hue is scaled from 0 to 1

  r = hue2rgb(p, q, h + 1 / 3)
  g = hue2rgb(p, q, h)
  b = hue2rgb(p, q, h - 1 / 3)
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

export default generateConsistentNaturalHexColor
