import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

import moment, { Moment } from 'moment'

import { ReservationProps, SiteProps } from '../../interfaces'
import { newReservationInitialState, newReservationReducer } from '../../reducers/newReservation'

import Calendar from './Calendar'
import api from '../../api'
import NewReservation from './NewReservation'
import RealTimeIndicator from './RealTimeIndicator'
import createSubscriptionReservationChannel from '../../channels/reservations_channel'
import createSubscriptionLiveWebAppNotificationsChannel from '../../channels/live_web_app_notifications_channel'
import pluralize from '../../utils/pluralize'
import Spinner from '../ui/Spinner'
import Button from '../ui/Button'
import ConfirmChanges from './ConfirmChanges'
import UpdatedReservationsTable from '../UpdatedReservationsTable'
import { PriceDetails } from '../../utils/priceDetailsFor'
import { AdminContext } from '../../contexts/AdminContext'
import { NewReservationProvider } from '../../contexts/admin/NewReservationContext'
import { ReservationContextMenuProvider } from '../../contexts/admin/ReservationContextMenuContext'
import { useDebouncedCallback } from 'use-debounce'
import { useCookies } from 'react-cookie'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import useWebsocket, { LiveWebAppNotification } from '../../hooks/useWebsocket'

interface ReservationStateProps {
  persisted: ReservationProps
  updated?: ReservationProps
}

interface ChangedFieldsProps {
  siteId?: string
  startDate?: Moment
  endDate?: Moment
  totalOwedInCents?: number
}

interface ComponentProps {
  sites: SiteProps[]
  setSites: (sites: SiteProps[]) => void
  showSuccessMessage: any
  setErrorMessage
  setNewUpdateMessage: (v: string) => any
  setShowLiveUpdatesDisconnected: (v: boolean) => any
  currentUserRole: string
  setNotificationReservation: (v: ReservationProps) => void
}

const Reservations = (props: ComponentProps) => {
  const {
    camp,
    reservationStates,
    setReservationStates,
    selectedSendingMode,
    showReservationsConfirmation,
    currentUserRole,
    showNotification,
    setSelectedReservation
  } = useContext(AdminContext)

  const [calendarFromDate, setCalendarFromDate] = useState(moment())
  const [calendarDuration, setCalendarDuration] = useState(31) // NOTE: in days

  const [isDimmerLoading, setIsDimmerLoading] = useState(false)
  const [isCalendarLoading, setIsCalendarLoading] = useState(false)
  const [isRealTime, setIsRealTime] = useState<boolean>() // NOTE: null is used as a 'connecting' state
  const [isUpdating, setIsUpdating] = useState(false)
  const [openCreateForm, setOpenCreateForm] = useState(false)
  const [numberOfUpdatedReservations, setNumberOfUpdatedReservations] = useState(0)
  const [newReservation, newReservationDispatch] = useReducer(
    newReservationReducer,
    newReservationInitialState
  )
  const [priceDetails, setPriceDetails] = useState<PriceDetails>()
  const [cookies, setCookie] = useCookies(['isQuarterlyView'])
  const [isQuarterlyView, setIsQuarterlyView] = useState(cookies.isQuarterlyView === 'true')

  const [ReservationChannel, setReservationChannel] = useState(
    createSubscriptionReservationChannel()
  )

  const [LiveWebAppNotificationsChannel, setLiveWebAppNotificationsChannel] = useState(
    createSubscriptionLiveWebAppNotificationsChannel()
  )

  // NOTE: for updateReservations button
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationContent, setConfirmationContent] = useState(
    <p className='text-base text-gray-500'>You haven't updated any reservations!</p>
  )

  const reduceArrayOfReservations = (
    rezzes: ReservationProps[]
  ): { [reservationId: string]: ReservationStateProps } => {
    return rezzes.reduce(
      (obj: { [reservationId: string]: ReservationStateProps }, res: ReservationProps) => {
        obj[res.id] = { persisted: res, updated: null }
        return obj
      },
      {}
    )
  }

  const fetchReservationsFor = useDebouncedCallback((fromDate: Moment, duration: number) => {
    setIsCalendarLoading(true)

    const from = moment(fromDate).subtract(2 + 31, 'days')
    const until = moment(fromDate).add((isQuarterlyView ? 3 : 2), 'months')

    api
      .fetchReservations({
        camp_id: camp.id,
        from: from,
        until: until,
      })
      .then((response) => {
        const res = reduceArrayOfReservations(response)
        setReservationStates(res)
        setIsCalendarLoading(false)
      })
      .catch((err) => {
        props.setErrorMessage(
          'There was a problem fetching reservations, please reload and try again. If the problem persists, please contact our support.'
        )
        console.error(JSON.stringify(err))

        setIsCalendarLoading(false)
      })
  }, 50)

  useEffect(() => {
    fetchReservationsFor(moment(calendarFromDate.creationData().input), calendarDuration)
  }, [props.sites, calendarFromDate, calendarDuration, isQuarterlyView])

  useEffect(() => {
    // NOTE: sanity check that there are no erroneous keys:
    const mReservations = { ...reservationStates }
    const reservationIds = Object.keys(mReservations)
    if (reservationIds.some((id: string) => id === undefined || id === null || id == '')) {
      Object.keys(mReservations).forEach((key) => {
        if (key === undefined || key === null || key == '') {
          delete mReservations[key]
        }
      })
      setReservationStates(mReservations)
    }
  }, [camp?.id, reservationStates])

  const [isReservationsWebsocketConnected] = useWebsocket((received: ReservationProps | ReservationProps[]) => {
    if (Array.isArray(received)) {
      const receivedReservations = received as ReservationProps[]
      if (receivedReservations[0].site?.camp_id != camp.id) return false

      const mReservations = { ...reservationStates }
      receivedReservations.forEach((receivedRes) => {
        const isNew = mReservations[receivedRes.id] ? false : true
        const isDeleted = moment(receivedRes.deleted_at).isSameOrBefore(moment())

        if (isDeleted) {
          const mRes = { ...mReservations[receivedRes.id] }
          mRes.persisted = null
          mRes.updated = null
          mReservations[receivedRes.id] = mRes
          delete mReservations[receivedRes.id]
        } else {
          const mRes = { ...mReservations[receivedRes.id] }
          mRes.persisted = receivedRes
          mRes.updated = null
          mReservations[receivedRes.id] = mRes
        }
      })

      console.log('setting from group websocket...')
      setReservationStates(mReservations)
    } else {
      const receivedRes = received as ReservationProps
      if (receivedRes.site?.camp_id != camp.id) return false

      if (!isRealTime) setIsRealTime(true) // NOTE: clearly it's connected

      const mReservations = { ...reservationStates }
      const isNew = mReservations[receivedRes.id] ? false : true
      const isDeleted = moment(receivedRes.deleted_at).isSameOrBefore(moment())

      if (isDeleted) {
        const mRes = { ...mReservations[receivedRes.id] }
        mRes.persisted = null
        mRes.updated = null
        mReservations[receivedRes.id] = mRes
        delete mReservations[receivedRes.id]
        setReservationStates(mReservations)
      } else {
        const mRes = { ...mReservations[receivedRes.id] }
        mRes.persisted = receivedRes
        mRes.updated = null
        mReservations[receivedRes.id] = mRes
        setReservationStates(mReservations)
      }

      if (!isDeleted && isNew && receivedRes.vendor_id) {
        let fullName = [receivedRes?.user?.first_name, receivedRes?.user?.last_name].join(' ')
        let siteName = receivedRes?.site?.name
        let startDate = moment(receivedRes?.start_date).format('MMM D')

        props.setNotificationReservation(receivedRes)
        props.setNewUpdateMessage(
          `Received a new reservation for ${fullName} at ${siteName} starting on ${startDate}`
        )
      }
    }
  }, ReservationChannel, [camp?.id, reservationStates])

  const [isLiveWebAppNotificationsConnected] = useWebsocket((receivedNotification: LiveWebAppNotification) => {
    if (receivedNotification.camp_id != camp.id) return false

    if (receivedNotification.type == 'payment') {
      const receivedPayment = receivedNotification.data.payment
      const receivedReservation = receivedNotification.data.reservation

      if (receivedReservation) {
        // NOTE: to update reservation#status
        const mReservations = { ...reservationStates }
        const mRes = { ...mReservations[receivedReservation.id] }
        mRes.persisted = receivedReservation
        mRes.updated = null
        mReservations[receivedReservation.id] = mRes
        setReservationStates(mReservations)
      }

      const siteName = receivedReservation?.site?.name
      const reservationDates = receivedReservation ? `${moment(receivedReservation?.start_date).format('MMM D')} - ${moment(
        receivedReservation?.end_date
      ).format('MMM D')}` : null
      const fullName = [receivedPayment.camper?.first_name, receivedPayment.camper?.last_name].join(' ')

      showNotification({
        type: "success",
        title: "New payment",
        description: `Received a new payment from ${fullName} for ${formatMoneyFromCents(receivedPayment.amount_in_cents)} at ${siteName} for ${reservationDates}`
      })
    }
  }, LiveWebAppNotificationsChannel, [camp?.id, reservationStates])

  useEffect(() => {
    const isConnected = isReservationsWebsocketConnected && isLiveWebAppNotificationsConnected
    setIsRealTime(isConnected)
    props.setShowLiveUpdatesDisconnected(!isConnected)
  }, [isReservationsWebsocketConnected, isLiveWebAppNotificationsConnected])

  useEffect(() => {
    const allReservationStates = Object.values(reservationStates)

    if (allReservationStates.some((resState) => resState.updated)) {
      setIsUpdating(true)
      setNumberOfUpdatedReservations(
        allReservationStates.filter((resState: ReservationStateProps) => resState.updated).length
      )
    } else {
      setIsUpdating(false)
    }
  }, [Object.values(reservationStates)])

  const persistReservationChanges = () => {
    setIsDimmerLoading(true)
    setConfirmationOpen(false)

    updateReservations(updatedReservations())
      .then(() => {
        setIsDimmerLoading(false)
      })
      .catch(() => {
        setIsDimmerLoading(false)
      })
  }

  const updateReservations = async (updatedReservations: ReservationProps[]): Promise<boolean> => {
    let responsePromises = updatedReservations.map(
      async (reservation: ReservationProps) =>
        await api
          .updateReservation(reservation.id, {
            site_id: reservation.site_id,
            start_date: reservation.start_date,
            end_date: reservation.end_date,
            status: reservation.status,
            sending_mode: selectedSendingMode,
          })
          .then((reservation) => {
            const mReservations = { ...reservationStates }
            const mRes = mReservations[reservation.id]
            mRes.persisted = reservation
            mRes.updated = null
            mReservations[reservation.id] = mRes
            setReservationStates(mReservations)

            return reservation
          })
          .catch((error) => {
            return error
          })
    )

    const responses = await Promise.all(responsePromises)
    const successes = responses.filter((x) => x.id)
    const errors = responses.filter((x) => !x.id)

    if (successes.length > 0) props.showSuccessMessage()
    if (errors.length > 0) {
      props.setErrorMessage(
        errors
          .map((x: { message: string; record: ReservationProps }) => {
            if (x.record) {
              return `${x.message} for reservation for ${x.record?.user?.last_name} at ${x.record?.site?.name}`
            } else if (x.message) {
              return x.message
            } else {
              return x
            }
          })
          .join(', ')
      )
    }

    return true
  }

  const changeReservation = (reservationId: string, changedFields: ChangedFieldsProps) => {
    const mReservations = { ...reservationStates }
    const reservation: ReservationStateProps = mReservations[reservationId]

    if (Object.values(changedFields).every((val) => !val)) {
      reservation.updated = null
    } else {
      reservation.updated = { ...reservation.persisted }

      if (changedFields.siteId) reservation.updated.site_id = changedFields.siteId

      if (changedFields.startDate)
        reservation.updated.start_date = changedFields.startDate.toISOString()

      if (changedFields.endDate) reservation.updated.end_date = changedFields.endDate.toISOString()

      if (changedFields.totalOwedInCents) reservation.updated.total_owed_in_cents = changedFields.totalOwedInCents
    }

    mReservations[reservationId] = reservation
    setReservationStates(mReservations)

    // NOTE: if updating blocked off reservations, we do not need the confirmation.
    if (updatedReservations().length == 0) {
      setConfirmationContent(
        <p className='text-base text-gray-500'>You haven't updated any reservations!</p>
      )
    } else {
      // TODO: do we need to pass in updatedReservationStates now?
      // TODO: @John do we even use this? Or can we remove this?
      setConfirmationContent(
        <UpdatedReservationsTable
          updatedReservationStates={Object.values(reservationStates).filter((state) => state.updated)}
        />
      )
    }
  }

  const updatedReservations = (): ReservationProps[] => {
    return Object.values(reservationStates)
      .map((obj: ReservationStateProps) => obj.updated)
      .filter((obj) => obj)
  }

  const clearReservationChanges = () => {
    const mReservations = { ...reservationStates }

    Object.values(mReservations).map((resState: ReservationStateProps) => (resState.updated = null))

    setReservationStates(mReservations)
  }

  return (
    <>
      <Dimmer active={isDimmerLoading}>
        <Loader size='huge'>Loading</Loader>
      </Dimmer>
      <div className='flex flex-col flex-grow'>
        <div className={`reservation-create flex-shrink flex flex-wrap items-center justify-end custom-500:justify-between mb-4 px-2 lg:px-0`}>
          <RealTimeIndicator active={isRealTime} />
          <div className='flex sm:space-x-4 sm:space-y-0 space-y-2 items-center'>
            {isUpdating && (
              <>
                <span className='sm:inline block sm:mt-0 mt-4 text-sm text-gray-500 sm:text-right text-center'>
                  Updating {numberOfUpdatedReservations}{' '}
                  {pluralize('reservation', numberOfUpdatedReservations)}
                </span>
                <Button
                  variant='gray'
                  disabled={!isUpdating}
                  onClick={clearReservationChanges}
                  className='mt-3 mr-3 inline-flex justify-center'>
                  Cancel Changes
                </Button>
                <Button
                  variant='green'
                  className='inline-flex mr-3 justify-center'
                  disabled={!isUpdating}
                  onClick={() => {
                    if (
                      updatedReservations().every((e) => {
                        return e.status == 'blocked'
                      })
                    ) {
                      persistReservationChanges()
                    } else {
                      const updating = Object.values(reservationStates).filter((res) => res.updated)
                      showReservationsConfirmation(updating)
                    }
                  }}>
                  Confirm Changes
                </Button>
              </>
            )}
            {currentUserRole != 'view-only' && <Button variant='blue' onClick={() => setOpenCreateForm(true)}>
              Create reservation
            </Button>}
          </div>
        </div>
        <ReservationContextMenuProvider>
          <div className='relative flex-grow'>
            {isCalendarLoading && (
              <div className='absolute bg-white bg-opacity-30 z-40 flex justify-center items-center h-full w-full'>
                <Spinner size={16} />
              </div>
            )}
            <Calendar
              camp={camp}
              sites={props.sites}
              setSites={props.setSites}
              changeReservation={changeReservation}
              newReservationDispatch={newReservationDispatch}
              openCreateForm={() => setOpenCreateForm(true)}
              calendarFromDate={calendarFromDate}
              calendarDuration={calendarDuration}
              setCalendarFromDate={setCalendarFromDate}
              setCalendarDuration={setCalendarDuration}
              isQuarterlyView={isQuarterlyView}
              setIsQuarterlyView={setIsQuarterlyView}
            />
          </div>
        </ReservationContextMenuProvider>
      </div>
      <ConfirmChanges
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        content={confirmationContent}
        onConfirm={() => persistReservationChanges()}
        updatedReservations={updatedReservations()}
      />
      <NewReservationProvider newReservation={newReservation} dispatch={newReservationDispatch}>
        <NewReservation
          isOpenCreateForm={openCreateForm}
          closeCreateForm={() => setOpenCreateForm(false)}
          setPriceDetails={setPriceDetails}
          setIsLoading={setIsDimmerLoading}
          sites={props.sites}
          camp={camp}
        />
      </NewReservationProvider>
    </>
  )
}

export default Reservations
