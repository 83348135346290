import React from 'react'
import { CampProps, CampingStyleProps, NewReservationProps } from '../../interfaces'
import SelectInput, {Option} from '../ui/SelectInput'
import { formatMoney, formatMoneyFromCents } from '../../utils/formatMoney'

interface ComponentProps {
  camp: CampProps
  campingStyle: CampingStyleProps
  newReservation: NewReservationProps
  newReservationDispatch
}

const DiscountFormGroup = (props: ComponentProps) => {
  const onDiscountChange = (slug: string) => {
    props.newReservationDispatch({
      type: 'UPDATE_DISCOUNT_NAME',
      payload: { discount_name: slug }
    })
  }

  const radioOptionsFromCampingStyle = props.campingStyle.discounts?.map((discount) => {
    const csd = props.campingStyle.camping_style_discounts.find((csd) => csd.discount_id == discount.id)
    const amountLabel = csd.amount_off_per_night_in_cents
      ? `${formatMoneyFromCents(csd.amount_off_per_night_in_cents)} off per night`
      : csd.subtotal_off_in_cents
      ? `${formatMoneyFromCents(csd.subtotal_off_in_cents)} off`
      : csd.flat_rate_in_cents
      ? `${formatMoneyFromCents(csd.flat_rate_in_cents)} flat`
      : `${csd.percent_off * 100}%`

    return { label: `${discount.name} (${amountLabel})`, value: discount.slug }
  }, {}) || []

  const radioOptionsFromCamp = props.camp.discounts?.map(discount => {
    const cd = props.camp.camp_discounts.find((cd) => cd.discount_id == discount.id)
    const amountLabel = cd.amount_off
      ? `${formatMoney(cd.amount_off)} off per night`
      : cd.subtotal_off_in_cents
      ? `${formatMoneyFromCents(cd.subtotal_off_in_cents)} off`
      : cd.flat_rate_in_cents
      ? `${formatMoneyFromCents(cd.flat_rate_in_cents)} flat`
      : `${cd.percent_off * 100}%`

    return { label: `${discount.name} (${amountLabel})`, value: discount.slug }
  }, {}) || []

  const radioOptions = [...radioOptionsFromCamp, ...radioOptionsFromCampingStyle]

  return (
    <SelectInput
      options={[{ label: 'No discount', value: '' }, ...radioOptions]}
      selected={radioOptions.find((o) => o.value === props.newReservation.discount_name) || { label: 'No discount', value: '' }}
      setSelected={(option: Option) => {
        onDiscountChange(option.value)
      }}
    />
  )
}

export default DiscountFormGroup
