import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { NewReservationProps, SiteProps } from '../../interfaces'
import { NewReservationAction, newReservationReducer } from '../../reducers/newReservation'
import { validElectric, validRigType } from '../../types'
import { AdminContext } from '../AdminContext'
import {
  RESERVATION_KINDS,
  reservationKind,
  LONG_TERM_RESERVATION_KINDS,
  longTermReservationKind,
} from '../../constants'
import moment from 'moment'

interface ICtxPayload {
  newReservation: NewReservationProps
  dispatch: any
  inputNotes: string
  setInputNotes: (v: string) => any
  inputFirstName: string
  setInputFirstName: (v: string) => any
  inputLastName: string
  setInputLastName: (v: string) => any
  inputEmail: string
  setInputEmail: (v: string) => any
  inputPhone: string
  setInputPhone: (v: string) => any
  inputVehicleRigLength: string
  setInputVehicleRigLength: (v: string) => any
  inputVehicleElectric: validElectric
  setInputVehicleElectric: (v: validElectric) => any
  inputVehicleRigType: validRigType
  setInputVehicleRigType: (v: validRigType) => any
  inputVehicleLicense: string
  setInputVehicleLicense: (v: string) => any
  inputVehicleYear: number
  setInputVehicleYear: (v: number) => any
  inputVehicleMake: string
  setInputVehicleMake: (v: string) => any
  inputVehicleColor: string
  setInputVehicleColor: (v: string) => any
  inputAdults: number
  setInputAdults: (v: number) => any
  inputChildren: number
  setInputChildren: (v: number) => any
  inputPets: string
  setInputPets: (v: string) => any
  isAcceptingDeposit: boolean
  setIsAcceptingDeposit: (v: boolean) => any
  clearForm: () => any
  selectedReservationType: reservationKind
  setSelectedReservationType: (v: reservationKind) => any
  isPayingSeparately: boolean
  setIsPayingSeparately: (v: boolean) => any
  recurringKind: longTermReservationKind
  setRecurringKind: (v: longTermReservationKind) => any
  selectedSitesForGroup: SiteProps[]
  setSelectedSitesForGroup: React.Dispatch<React.SetStateAction<SiteProps[]>>
  address: string
  setAddress: React.Dispatch<React.SetStateAction<string>>
}

const initialContext = {
  newReservation: null,
  dispatch: null,
  inputNotes: null,
  setInputNotes: null,
  inputFirstName: null,
  setInputFirstName: null,
  inputLastName: null,
  setInputLastName: null,
  inputEmail: null,
  setInputEmail: null,
  inputPhone: null,
  setInputPhone: null,
  inputVehicleRigLength: null,
  setInputVehicleRigLength: null,
  inputVehicleElectric: null,
  setInputVehicleElectric: null,
  inputVehicleRigType: null,
  setInputVehicleRigType: null,
  inputVehicleLicense: null,
  setInputVehicleLicense: null,
  inputVehicleYear: null,
  setInputVehicleYear: null,
  inputVehicleMake: null,
  setInputVehicleMake: null,
  inputVehicleColor: null,
  setInputVehicleColor: null,
  inputAdults: null,
  setInputAdults: null,
  inputChildren: null,
  setInputChildren: null,
  inputPets: null,
  setInputPets: null,
  isAcceptingDeposit: null,
  setIsAcceptingDeposit: null,
  clearForm: null,
  selectedReservationType: null,
  setSelectedReservationType: null,
  isPayingSeparately: null,
  setIsPayingSeparately: null,
  recurringKind: null,
  setRecurringKind: null,
  selectedSitesForGroup: null,
  setSelectedSitesForGroup: null,
  address: null,
  setAddress: null,
}

export const NewReservationContext = createContext<ICtxPayload>(initialContext)

// export const NewReservationContext = createContext<ICtxPayload>(initialContext)
export const NewReservationProvider = ({
  newReservation,
  dispatch,
  children,
}: {
  newReservation: NewReservationProps
  dispatch: any
  children: any
}) => {
  const { sites, campingStyles, reservationStates } = useContext(AdminContext)

  const site = sites.find((s) => s.id === newReservation.site_id)
  const campingStyle = campingStyles.find((cs) => cs.id === site?.camping_style_id)

  const [inputNotes, setInputNotes] = useState(newReservation?.notes)
  const [inputFirstName, setInputFirstName] = useState<string>()
  const [inputLastName, setInputLastName] = useState<string>()
  const [inputEmail, setInputEmail] = useState<string>()
  const [inputPhone, setInputPhone] = useState<string>()
  const [inputVehicleRigLength, setInputVehicleRigLength] = useState<string>()
  const [inputVehicleElectric, setInputVehicleElectric] = useState<string>()
  const [inputVehicleRigType, setInputVehicleRigType] = useState<validRigType>()
  const [inputVehicleLicense, setInputVehicleLicense] = useState<string>()
  const [inputVehicleYear, setInputVehicleYear] = useState<number>()
  const [inputVehicleMake, setInputVehicleMake] = useState<string>()
  const [inputVehicleColor, setInputVehicleColor] = useState<string>()
  const [inputAdults, setInputAdults] = useState<number>(newReservation?.passengers)
  const [inputChildren, setInputChildren] = useState<number>(newReservation?.kid_campers)
  const [inputPets, setInputPets] = useState<string>()
  const [isAcceptingDeposit, setIsAcceptingDeposit] = useState<boolean>()
  const [address, setAddress] = useState<string>()
  const [selectedReservationType, setSelectedReservationType] = useState<reservationKind>(
    RESERVATION_KINDS.SINGLE
  )
  const [isPayingSeparately, setIsPayingSeparately] = useState(true)
  const [recurringKind, setRecurringKind] = useState<longTermReservationKind>(
    LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES
  )
  const [selectedSitesForGroup, setSelectedSitesForGroup] = useState<SiteProps[]>(
    [site].filter((s) => s)
  )

  useEffect(() => {
    updateSelectedSitesForGroup()
  }, [newReservation])

  useEffect(() => {
    setIntervalId()
  }, [campingStyle])

  const updateSelectedSitesForGroup = () => {
    if (selectedSitesForGroup.length > 0) return
    setSelectedSitesForGroup([site].filter((s) => s))
  }

  const setIntervalId = () => {
    if (!campingStyle?.booking_intervals?.length) {
      dispatch({
        type: NewReservationAction.UPDATE_INTERVAL_ID,
        payload: {
          interval_id: null,
        },
      })
      return
    }

    // TODO: always grabs first, but should be based on user selection if more than 1
    const interval = campingStyle.booking_intervals[0]
    dispatch({
      type: NewReservationAction.UPDATE_INTERVAL_ID,
      payload: {
        interval_id: interval.id,
      },
    })
  }

  const clearForm = () => {
    setInputNotes(null)
    setInputFirstName(null)
    setInputLastName(null)
    setInputEmail(null)
    setInputPhone(null)
    setInputVehicleRigLength(null)
    setInputVehicleElectric(null)
    setInputVehicleRigType(null)
    setInputVehicleLicense(null)
    setInputVehicleYear(null)
    setInputVehicleMake(null)
    setAddress(null)
    setInputVehicleColor(null)
    setInputAdults(null)
    setInputChildren(null)
    setInputPets(null)
    setIsAcceptingDeposit(null)
    setSelectedReservationType(RESERVATION_KINDS.SINGLE)
    dispatch({
      type: 'UPDATE_START_DATE',
      payload: { start_date: null },
    })
    dispatch({
      type: 'UPDATE_END_DATE',
      payload: { end_date: null },
    })
    dispatch({ type: 'UNEDITED_TOTAL' })
    dispatch({
      type: 'UPDATE_STATUS_AND_KIND',
      payload: {
        status: 'unpaid',
        kind: null,
      },
    })
    dispatch({
      type: 'UPDATE_DEPOSIT_AMOUNT',
      payload: {
        deposit_in_cents: null,
      },
    })
    dispatch({
      type: 'UPDATE_DISCOUNT_NAME',
      payload: {
        discount_name: null,
      },
    })
    dispatch({
      type: NewReservationAction.UPDATE_INTERVAL_ID,
      payload: {
        interval_id: null,
      },
    })
  }

  useEffect(() => {
    if (!isAcceptingDeposit || newReservation.deposit_in_cents) return

    const currentTotalOwed = newReservation.total_owed_in_cents // NOTE: this is NOT in cents haha
    const defaultDeposit = campingStyle ? campingStyle.default_deposit_in_cents / 100 : 0

    const depositAmount =
      campingStyle && currentTotalOwed >= defaultDeposit ? defaultDeposit : currentTotalOwed

    dispatch({
      type: NewReservationAction.UPDATE_DEPOSIT_AMOUNT,
      payload: {
        deposit_in_cents: depositAmount,
      },
    })
  }, [isAcceptingDeposit, newReservation.total_owed_in_cents])

  return (
    <NewReservationContext.Provider
      value={{
        newReservation: newReservation,
        dispatch: dispatch,
        inputNotes: inputNotes,
        setInputNotes: setInputNotes,
        inputFirstName: inputFirstName,
        setInputFirstName: setInputFirstName,
        inputLastName: inputLastName,
        setInputLastName: setInputLastName,
        inputEmail: inputEmail,
        setInputEmail: setInputEmail,
        inputPhone: inputPhone,
        setInputPhone: setInputPhone,
        inputVehicleRigLength: inputVehicleRigLength,
        setInputVehicleRigLength: setInputVehicleRigLength,
        inputVehicleElectric: inputVehicleElectric as validElectric,
        setInputVehicleElectric: setInputVehicleElectric,
        inputVehicleRigType: inputVehicleRigType,
        setInputVehicleRigType: setInputVehicleRigType,
        inputVehicleLicense: inputVehicleLicense,
        setInputVehicleLicense: setInputVehicleLicense,
        inputVehicleYear: inputVehicleYear,
        setInputVehicleYear: setInputVehicleYear,
        inputVehicleMake: inputVehicleMake,
        setInputVehicleMake: setInputVehicleMake,
        inputVehicleColor: inputVehicleColor,
        setInputVehicleColor: setInputVehicleColor,
        inputAdults: inputAdults,
        setInputAdults: setInputAdults,
        inputChildren: inputChildren,
        setInputChildren: setInputChildren,
        inputPets: inputPets,
        setInputPets: setInputPets,
        isAcceptingDeposit: isAcceptingDeposit,
        setIsAcceptingDeposit: setIsAcceptingDeposit,
        selectedReservationType: selectedReservationType,
        setSelectedReservationType: setSelectedReservationType,
        isPayingSeparately: isPayingSeparately,
        setIsPayingSeparately: setIsPayingSeparately,
        recurringKind: recurringKind,
        setRecurringKind: setRecurringKind,
        clearForm: clearForm,
        selectedSitesForGroup: selectedSitesForGroup,
        setSelectedSitesForGroup: setSelectedSitesForGroup,
        address: address,
        setAddress: setAddress,
      }}>
      {children}
    </NewReservationContext.Provider>
  )
}
