import { Transition } from '@headlessui/react'
import { CurrencyDollarIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useCookies } from 'react-cookie'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'
import { BookerContext } from '../../contexts/BookerContext'
import { CampProps } from '../../interfaces'

interface ComponentProps {
  show: boolean
  setShow: (v: boolean) => void
  camp: CampProps
}

const ReferralModal = (props: ComponentProps) => {
  const [show, setShow] = useState(false)
  const [cookies, setCookie] = useCookies(['hide_referral_modal'])

  const { trackForPark } = useGoogleAnalytics(props.camp)

  const triggerShow = useDebouncedCallback(() => {
    setShow(props.show && !cookies.hide_referral_modal)
    trackForPark('view_confirmation')
  }, 4000)

  useEffect(() => {
    triggerShow()
  }, [])

  return (
    <div
      aria-live='assertive'
      className='fixed bottom-0 right-0 w-full flex items-end pointer-events-none sm:p-6 sm:items-start z-40'>
      <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
        <Transition
          show={show}
          as={Fragment}
          enter='transform ease-out duration-500 transition'
          enterFrom='translate-y-64 opacity-100 sm:opacity-0 sm:translate-y-0 sm:translate-x-8'
          enterTo='translate-y-0 opacity-100 sm:translate-x-0'
          leave='transition ease-in duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='pointer-events-auto w-full max-w-2xl rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='p-8'>
              <div className='flex items-start'>
                <div className='hidden sm:block flex-shrink-0 pt-0.5'>
                  <div className='h-8 w-8'>
                    <CurrencyDollarIcon />
                  </div>
                </div>
                <div className='ml-3 w-0 flex-1'>
                  <p className='text-center sm:text-left text-xl font-semibold text-gray-900'>
                    Save up to 35% on RV Insurance with Roamly
                  </p>
                  <p className='text-center sm:text-left mt-4 sm:mt-1 text-gray-700'>
                    Get a free online quote from Roamly in minutes, or shop pricing from multiple
                    insurance companies with one of our RV Experts.
                  </p>
                  <div className='mt-8 sm:mt-4 flex flex-col sm:flex-row'>
                    <a
                      href='https://outdoorsyinc.go2cloud.org/aff_c?offer_id=28&aff_id=1269&source=park'
                      target='_blank'>
                      <button
                        type='button'
                        className='w-full sm:w-auto justify-center inline-flex items-center rounded-md border border-transparent bg-green-600 px-5 py-3.5 font-semibold leading-4 text-white shadow-sm hover:bg-green-700'>
                        Get Quote
                      </button>
                    </a>
                    <button
                      type='button'
                      className='w-full sm:w-auto justify-center mt-3 sm:mt-auto sm:ml-3 inline-flex items-center rounded-md px-5 py-4 font-semibold leading-4 text-gray-700 hover:bg-gray-50'
                      onClick={() => {
                        setCookie('hide_referral_modal', true)
                        setShow(false)
                      }}>
                      No, thanks
                    </button>
                  </div>
                </div>
                <div className='ml-4 flex flex-shrink-0'>
                  <button
                    type='button'
                    className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500'
                    onClick={() => {
                      setShow(false)
                    }}>
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default ReferralModal
