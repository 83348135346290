import moment from "moment"
import { CampingStyleProps, NewReservationProps, ReservationProps, SiteProps } from "../../../interfaces"
import { SiteOption } from "./SitesDropdown"
import { CAMPING_STYLES } from "../../../constants"

type CampingStyleMap = Record<string, string>

const createCampingStyleMap = (campingStyles: CampingStyleProps[]): CampingStyleMap => {
  return campingStyles.reduce((acc, cs) => {
    acc[cs.id] = cs.name
    return acc
  }, {})
}

const sortedSites = (a: SiteProps, b: SiteProps, campingStyles: CampingStyleProps[]) => {
  const campingStyleMap = createCampingStyleMap(campingStyles)

  const aStyleName = campingStyleMap[a.camping_style_id]
  const bStyleName = campingStyleMap[b.camping_style_id]
  const rvName = CAMPING_STYLES.RV

  if (aStyleName === rvName && bStyleName !== rvName) {
    return -1
  } else if (aStyleName !== rvName && bStyleName === rvName) {
    return 1
  } else {
    return a.sort - b.sort
  }
}

const generateSiteOptions = (
  sites: SiteProps[],
  reservation: ReservationProps | NewReservationProps,
  reservationStates: Record<string, { persisted: ReservationProps; updated?: ReservationProps }>,
  campingStyles: CampingStyleProps[]
): SiteOption[] => {
  const reservationsBySiteId: Record<string, ReservationProps[]> = {}

  Object.values(reservationStates).forEach(reservationState => {
    const reservation = reservationState.updated || reservationState.persisted
    if (!reservationsBySiteId[reservation.site_id]) {
      reservationsBySiteId[reservation.site_id] = []
    }
    reservationsBySiteId[reservation.site_id].push(reservation)
  })

  const reservationStart = reservation?.start_date && moment(reservation.start_date)
  const reservationEnd = reservation?.end_date && moment(reservation.end_date)

  return sites.sort((a, b) => sortedSites(a, b, campingStyles)).map((site) => {
    if (reservationStart && reservationEnd) {
      const isAvailable = (reservationsBySiteId[site.id] || []).every(res => {
        const isSameReservation = res.id === reservation.id
        const mStart = moment(res.start_date)
        const mEnd = moment(res.end_date)
        reservationStart.hour(mStart.hour()).minute(mStart.minute())
        reservationEnd.hour(mEnd.hour()).minute(mEnd.minute())
        const isSameDateRange = reservationStart.isBefore(res.end_date) &&
          reservationEnd.isAfter(res.start_date)

        return isSameReservation || !isSameDateRange
      })

      return {
        key: site.id,
        value: site.id,
        title: site.name,
        description: isAvailable ? '' : 'Unavailable for dates',
        site: site,
        isDisabled: !isAvailable,
      }
    } else {
      return {
        key: site.id,
        value: site.id,
        title: site.name,
        site: site,
        isDisabled: false,
      }
    }
  })
}

export default generateSiteOptions
