import { useEffect } from 'react'
import { CampProps } from '../interfaces'

const useCampTheme = (camp: CampProps) => {
  const search = window.location.search

  let campName = camp.name
  let campCity = camp.city
  let campState = camp.state
  let campPrimaryColor = camp.primary_color
  let campSecondaryColor = camp.secondary_color
  let campHeaderColor = camp.header_color
  let campFont = ''
  let campFontFamily = ''

  // If there are params in the URL, use those instead

  const paramName = new URLSearchParams(search).get('p')
  const paramCity = new URLSearchParams(search).get('c')
  const paramState = new URLSearchParams(search).get('s')
  const paramPrimaryColor = new URLSearchParams(search).get('primary')
  const paramSecondaryColor = new URLSearchParams(search).get('secondary')
  const paramHeaderColor = new URLSearchParams(search).get('header')
  const paramFont = new URLSearchParams(search).get('font')
  const paramFontFamily = new URLSearchParams(search).get('family')
  const fontUrl = decodeURIComponent(paramFont)

  campName = paramName ? paramName : campName
  campCity = paramCity ? paramCity : campCity
  campState = paramState ? paramState : campState
  campPrimaryColor = paramPrimaryColor ? paramPrimaryColor : (campPrimaryColor || '#44614B')
  campSecondaryColor = paramSecondaryColor ? paramSecondaryColor : (campSecondaryColor || '#F9B942')
  campHeaderColor = paramHeaderColor ? paramHeaderColor : (campHeaderColor)
  campFont = paramFont ? paramFont : campFont
  campFontFamily = paramFontFamily ? paramFontFamily : campFontFamily

  if (campPrimaryColor[0] != '#') campPrimaryColor = '#' + campPrimaryColor
  if (campSecondaryColor[0] != '#') campSecondaryColor = '#' + campSecondaryColor
  if (campHeaderColor[0] != '#') campHeaderColor = '#' + campHeaderColor

  useEffect(() => {
    if (fontUrl) {
      const link = document.createElement('link')
      link.href = fontUrl
      link.rel = 'stylesheet'
      document.head.appendChild(link)
    }
  }, [fontUrl])

  return {
    campPrimaryColor,
    campSecondaryColor,
    campHeaderColor,
    campFont,
    campFontFamily,
    campName,
    campCity,
    campState,
  }
}

export default useCampTheme
