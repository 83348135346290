import IAddOn from "../interfaces/IAddOn"
import { destroy, get, patch, post } from "./utils/requests"

const fetchForCamp = async (campId: string): Promise<Array<{ add_ons: Array<IAddOn> }>> =>
  get(`/api/camps/${campId}/add_ons`)

const fetchForCampingStyle = async (campingStyleId: string): Promise<Array<IAddOn>> =>
  get(`/api/camping_styles/${campingStyleId}/add_ons`)

const create = async (campId: string, data: Partial<IAddOn>) =>
  post(`/api/camps/${campId}/add_ons`, data)

const update = async (addOnId: string, data: Partial<IAddOn>) =>
  patch(`/api/add_ons/${addOnId}`, data)

const archive = async (addOnId: string) =>
  destroy(`/api/add_ons/${addOnId}`)

const fetch = async (
  id: string,
  startDate: string,
  endDate: string
): Promise<{ add_ons: Array<IAddOn> }> =>
  get(`/api/camping_styles/${id}/add_ons`, {
    start_date: startDate,
    end_date: endDate,
  })

const AddOns = {
  archive,
  create,
  fetchForCamp,
  fetchForCampingStyle,
  update,
  fetch
}

export default AddOns
