import React from 'react'
import { KINDS } from '../constants'
import { SiteProps } from '../interfaces'

import iconFor from '../utils/iconFor'
import classNames from '../utils/classNames'
import isRvKind from '../utils/isRvKind'
import ExternalSvg from './ui/ExternalSvg'

interface ComponentProps {
  site: SiteProps
  size: string
  campingStyle: any
  isCalendarView?: boolean
  isDropdown?: boolean
}

const SiteIcons = (props: ComponentProps) => {
  const ElectricImage = () =>
    props.site.electric && (
      <img
        src={iconFor('electric', props.site.electric)}
        className={`h-${props.size} w-${props.size}`}
      />
    )

  const kindImage = props.site.icon_url
    ? <ExternalSvg url={props.site.icon_url} className={`h-${props.size} w-${props.size}`} asImg />
    : props.site.kind
    ? <img src={iconFor('kind', props.site.kind)} className={`h-${props.size} w-${props.size}`} />
    : <></>

  const RigLength = () =>
    props.site.max_rig_length && (
      <span style={{ alignSelf: 'center', marginLeft: '0.5rem' }}>
        {props.site.max_rig_length}ft
      </span>
    )

  const CamperCapacity = () =>
    props.site.camper_capacity &&
    (props.campingStyle?.name == KINDS.CABIN ||
      props.campingStyle?.name == KINDS.RENTAL_RV ||
      props.campingStyle?.name == KINDS.MOBILE_HOME ||
      props.campingStyle?.name == KINDS.HOME ||
      props.campingStyle?.name == KINDS.LODGING ||
      props.campingStyle?.name == KINDS.LODGE ||
      props.campingStyle?.name == KINDS.GLAMPING ||
      props.campingStyle?.name == KINDS.DOME) && (
      <div className='flex items-center'>
        <div
          className={`flex items-center justify-center w-${props.size} h-${props.size} border-1.5 border-black rounded-full ml-1`}>
          <div className='text-black text-center'>
            <div
              className={classNames('font-bold', props.isDropdown ? 'text-base' : 'text-baselg')}>
              {props.site.camper_capacity}
            </div>
            <div className={props.isDropdown ? 'text-xs -mt-1.5' : 'text-xs'}>Max</div>
          </div>
        </div>
      </div>
    )
  
  const WaterSewer = () =>
    (props.site.water || props.site.sewage) &&
    isRvKind(props.site.kind) && (
      <div className='flex items-center'>
        <div
          className={`flex items-center justify-center ${
            props.isDropdown ? 'w-9 h-9' : 'w-10.5 h-10.5'
          } border-black rounded-full ml-1`}
          style={props.isDropdown ? { borderWidth: '1px' } : { borderWidth: '1.5px' }}>
          <div className='text-black text-center'>
            <div className={classNames('font-semibold text-md')}>
              {props.site.water && props.site.sewage ? 'W/S' : props.site.water ? 'W' : 'S'}
            </div>
          </div>
        </div>
      </div>
    )

  if (props.isCalendarView)
    return (
      <div className='flex-shrink-0'>
        {kindImage}
      </div>
    )
  else
    return (
      <div className='flex items-center h-8'>
        {kindImage}
        <ElectricImage />
        <WaterSewer />
        <RigLength />
        <CamperCapacity />
      </div>
    )
}

export default SiteIcons
