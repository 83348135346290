import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../ui/Button'
import { CubeIcon } from '@heroicons/react/24/outline'
import { AdminContext } from '../../../../contexts/AdminContext'
import IAddOn from '../../../../interfaces/IAddOn'
import api from '../../../../api2'
import Notification, { NotificationContainer } from '../../../ui/Notification'
import { CampingStyleIcon } from '../../../ui/CampingStyleSelector'
import HoverTip from '../../../ui/HoverTip'
import { titleCase } from '../../../../utils'
import ProductModalContent from './ProductModalContent'
import ProcessingState from '../../../ui/Admin/ProcessingState'
import formatCampingStyleName from '../../../../utils/formatCampingStyleName'

export const productTypes = [
  {
    value: 'normal',
    label: 'Standard',
    description: 'Standard product that you can add any number of times',
  },
  { value: 'boolean', label: 'Single', description: 'Can only be applied once (ie - Lock fee)' },
  {
    value: 'always-applied',
    label: 'Always applied (non-recurring)',
    description: 'Always applied to a reservation for a given site type (ie - Cleaning fee)',
  },
  {
    value: 'always-applied-recurring',
    label: 'Always applied (recurring only)',
    description: 'Always applied to a recurring reservation (ie - Meter reading fee)',
  },
]

const mapType = (product) => {
  switch (product) {
    case 'normal':
      return 'Standard'
    case 'boolean':
      return 'Single'
    case 'always-applied':
      return 'Always applied (non-recurring)'
    case 'always-applied-recurring':
      return 'Always applied (recurring only)'
    default:
      break
  }
}

const Products = () => {
  const [products, setProducts] = useState<IAddOn[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { camp, campingStyles, showModal } = useContext(AdminContext)

  const handleProductClick = (selectedProduct?: IAddOn) => {
    showModal(
      <ProductModalContent fetchProducts={fetchProducts} selectedProduct={selectedProduct} />,
      true,
      'max-w-4xl'
    )
  }

  const fetchProducts = () => {
    setIsLoading(true)
    return api.AddOns.fetchForCamp(camp.id).then((add_ons) => {
      setProducts(add_ons['add_ons'])
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  const productCampingStyles = (product: IAddOn) =>
    campingStyles.filter((style) => style.id === product.camping_style_id || style.id === product?.addable_camping_style_id)

  return (
    <div className='relative'>
      <div className='flex justify-between wrap gap-3 items-center flex-row'>
        <div className='my-6 space-y-2'>
          <div className='text-xl font-semibold text-gray-900'>Products</div>
          <div className='font-medium text-gray-500'>
            Products can be used internally and/or shown to campers during booking.
          </div>
        </div>
        <Button
          className='whitespace-nowrap w-32 xs:w-40 -mt-24'
          variant='blue'
          onClick={(e) => {
            e.preventDefault()
            handleProductClick()
          }}>
          Add product
        </Button>
      </div>
      {products && (
        <>
          <div>
            <div className='grid overflow-auto' style={{ gridTemplateColumns: 'repeat(5, auto)' }}>
              <div className='font-semibold text-md text-gray-900 py-5 whitespace-nowrap px-2'>
                Name
              </div>
              <div className='font-semibold text-md text-gray-900 py-5 whitespace-nowrap px-2'>
                Unit price
              </div>
              <div className='font-semibold text-md text-gray-900 py-5 whitespace-nowrap px-2'>
                Type
              </div>
              <div className='font-semibold text-md text-gray-900 py-5 whitespace-nowrap px-2'>
                Camping style
              </div>
              <div className='font-semibold text-md text-gray-900 py-5 whitespace-nowrap px-2'>
                Bookable
              </div>
              {products
                ?.filter((product) => product.kind !== 'hidden')
                .map((product) => (
                  <React.Fragment key={product?.id}>
                    <div
                      className='font-medium text-md text-gray-900 flex gap-4 border-t py-5 px-2'
                      key={product?.id}>
                      <div className='w-11 h-11 bg-gray-100 rounded-full flex items-center justify-center text-gray-475'>
                        <CubeIcon style={{ width: 18, height: 18 }} />
                      </div>
                      <div className={!product?.description && 'flex items-center'}>
                        <div>{product?.label}</div>
                        {product?.description && (
                          <div className='w-72 truncate whitespace-nowrap overflow-hidden text-gray-475'>
                            {product?.description}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='font-medium text-md flex items-center border-t px-2 text-gray-475'>
                      ${product?.amount_in_cents / 100}
                    </div>
                    <div className='font-medium text-md flex items-center border-t px-2'>
                      <div className='whitespace-nowrap text-gray-475'>
                        {mapType(product?.kind)}
                      </div>
                    </div>
                    <div className='font-medium text-md flex items-center pl-8 border-t'>
                      <div
                        className={`grid ${
                          productCampingStyles(product).length <= 1 ? 'grid-cols-1' : 'grid-cols-2 pt-1.5'
                        } overflow-y-auto scrollbar-thin justify-center w-full`}
                        style={{ maxHeight: 74.5 }}>
                        {productCampingStyles(product).map((style) => (
                          <div>
                            <HoverTip
                              className='w-auto'
                              tipPosition={'fixed'}
                              children={
                                <CampingStyleIcon
                                  campingStyle={style}
                                  style={{
                                    maxWidth: 40,
                                    maxHeight: 40,
                                    width: 'auto',
                                    height: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                />
                              }
                              content={style.name == 'rv' ? 'RV' : product?.addable_camping_style_id ? formatCampingStyleName(style.name) : titleCase(style.name)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='font-medium whitespace-nowrap text-md flex items-center justify-between gap-3 border-t px-2 text-gray-475'>
                      {product?.is_bookable ? (
                        <div className='flex items-center gap-3'>
                          <div className='rounded-full w-1.5 h-1.5 bg-green-400'></div>
                          <div className='text-green-400'>Yes</div>
                        </div>
                      ) : (
                        <div className='flex items-center gap-3'>
                          <div className='rounded-full w-1.5 h-1.5 bg-gray-400'></div>
                          <div className='text-gray-400'>No</div>
                        </div>
                      )}
                      <Button
                        variant='none'
                        onClick={(e) => {
                          e.preventDefault()
                          handleProductClick(product)
                        }}
                        style={{ padding: 0 }}
                        className='rounded-3xl text-base font-medium transition-colors text-blue-650 ml-4 underline'
                        children={'Edit'}
                      />
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <ProcessingState loadingMessages={[]} successMessage={''}/>
      )}
    </div>
  )
}

export default Products
