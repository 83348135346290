// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import 'tailwindcss/tailwind.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const sentryDsn = process.env.RAILS_ENV === 'production'
  ? 'https://cfdf7f93dca8449db47458306e23cd8b@o1115146.ingest.sentry.io/6156346'
  : ''

Sentry.init({
  dsn: sentryDsn,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  beforeSend(event, hint) {
    const error = hint.originalException

    // Handle Error object instances
    if (error instanceof Error) {
      return event
    }

    // Handle string exceptions
    if (typeof error === "string") {
      event.message = `Caught a string exception: ${error}`
      return event
    }

    // Handle object exceptions
    if (typeof error === "object" && error !== null) {
      // Convert the error object to a JSON string and include it in the message
      const errorString = JSON.stringify(error)
      event.title = `Non-Error exception captured with key/values: ${errorString}`
      event.message = `Caught an object exception: ${errorString}`

      // Optionally, you can add the error object to event.extra for better visibility in Sentry
      event.extra = event.extra || {}
      event.extra.errorObject = error

      return event
    }

    return event
  }
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);