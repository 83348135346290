import React, { createContext, useEffect, useState } from 'react'
import { Moment } from 'moment'
import { CampingStyleProps, CampProps, PriceProps, SiteProps } from '../interfaces'
import { validCampingStyles, validElectric } from '../types'
import IBookingInterval from '../interfaces/IBookingInterval'
import { CAMPING_STYLES } from '../constants'
import api from '../api2'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import useGoogleAnalytics from '../hooks/useGoogleAnalytics'
import IDateConfiguration from '../interfaces/IDateConfiguration'

interface AddOnParam {
  id: string
  quantity: number
}

interface ICtxPayload {
  camp: CampProps
  preferred_checkin_times: Record<string, string>
  preferred_checkout_times: Record<string, string>
  campingStyle: CampingStyleProps
  setCampingStyle: (campingStyle: CampingStyleProps) => void
  campingStylePrices: PriceProps[]
  campingStyleDateConfigurations: IDateConfiguration[]
  selectedSite: SiteProps
  startDate: Moment
  setStartDate: (v: Moment) => void
  endDate: Moment
  setEndDate: (v: Moment) => void
  selectedAddOns: Array<AddOnParam>
  setSelectedAddOns: (v: Array<AddOnParam>) => void
  userElectric: validElectric
  groupedReservationId: string
  setGroupedReservationId: (v: string) => void
  selectedInterval: IBookingInterval
  setSelectedInterval: (v: IBookingInterval) => void
  isDailyOrHourly: boolean
  secondaryColor: string
  userRigYear: number
  setUserRigYear: (v: number) => void
  userPets: number
  setUserPets: (v: number) => void
  trackForPark: (action: string, params?: object) => void
  trackForCamp: (action: string, params?: object) => void
  trackAdConversionForCamp: (action: string, params?: object) => void
  isKidsPrereqPresent: boolean
  unavailableDates: Record<string, string[]>
  setUnavailableDates: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
  couponCode: string
  setCouponCode: (v: string) => void
}

const initialContext = {
  camp: null,
  preferred_checkin_times: null,
  preferred_checkout_times: null,
  campingStyle: null,
  setCampingStyle: null,
  campingStylePrices: [],
  campingStyleDateConfigurations: [],
  selectedSite: null,
  startDate: null,
  setStartDate: null,
  endDate: null,
  setEndDate: null,
  selectedAddOns: null,
  setSelectedAddOns: () => {},
  userElectric: null,
  groupedReservationId: null,
  setGroupedReservationId: null,
  selectedInterval: null,
  setSelectedInterval: () => {},
  isDailyOrHourly: false,
  secondaryColor: null,
  userRigYear: null,
  setUserRigYear: null,
  userPets: null,
  setUserPets: null,
  trackForPark: null,
  trackForCamp: null,
  trackAdConversionForCamp: null,
  isKidsPrereqPresent: null,
  unavailableDates: null,
  setUnavailableDates: null,
  couponCode: null,
  setCouponCode: null,
}

export const BookerProvider = ({
  camp,
  preferred_checkin_times,
  preferred_checkout_times,
  campingStyle,
  setCampingStyle,
  selectedSite,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userElectric,
  groupedReservationId,
  setGroupedReservationId,
  children,
  secondaryColor,
  unavailableDates,
  setUnavailableDates,
}: Omit<
  ICtxPayload,
  | 'trackForPark'
  | 'trackForCamp'
  | 'trackAdConversionForCamp'
  | 'campingStyleDateConfigurations'
  | 'selectedAddOns'
  | 'setSelectedAddOns'
  | 'selectedInterval'
  | 'setSelectedInterval'
  | 'isDailyOrHourly'
  | 'campingStylePrices'
  | 'userRigYear'
  | 'setUserRigYear'
  | 'userPets'
  | 'setUserPets'
  | 'isKidsPrereqPresent'
  | 'couponCode'
  | 'setCouponCode'
> & { children: JSX.Element | JSX.Element[] }) => {
  const { trackForCamp, trackForPark, trackAdConversionForCamp } = useGoogleAnalytics(camp)
  const [fatalError, setFatalError] = useState(null)
  const [selectedAddOns, setSelectedAddOns] = useState<Array<AddOnParam>>([])
  const [selectedInterval, setSelectedInterval] = useState<IBookingInterval>(
    campingStyle?.booking_intervals[0]
  )
  const [prices, setPrices] = useState<PriceProps[]>([])
  const [dateConfigurations, setDateConfigurations] = useState<IDateConfiguration[]>([])
  const [userRigYear, setUserRigYear] = useState<number>()
  const [userPets, setUserPets] = useState<number>()
  const [couponCode, setCouponCode] = useState<string>("")
  const isKidsPrereqPresent =
    !!campingStyle.extra_kid_campers_age ||
    !!campingStyle.extra_kid_campers_price_in_cents ||
    !!campingStyle.max_free_kid_campers ||
    !!campingStyle.kids_capacity

  // TODO: rename and move out of here
  const intervalKindFor = (interval: IBookingInterval) => {
    if (!interval) return 'nightly'

    const [sHour, sMinute] = interval.start_time.split(':').map((v) => parseInt(v))
    const [eHour, eMinute] = interval.end_time.split(':').map((v) => parseInt(v))

    return sHour < eHour ? 'daily' : 'nightly'
  }

  useEffect(() => {
    if (!campingStyle) return

    console.log(campingStyle)

    setSelectedAddOns(campingStyle.add_ons.map((addOn) => ({ id: addOn.id, quantity: 0 })))
    setSelectedInterval(campingStyle.booking_intervals[0])

    api.CampingStyles.getPricesFor(campingStyle.id)
      .then(({ prices }) => {
        setPrices(prices)
      })
      .catch((err) => {
        console.error(err)
      })

    api.CampingStyles.dateConfigurationsFor(campingStyle.id).then(({ date_configurations }) => {
      setDateConfigurations(date_configurations)
    })

    const queryParams = new URLSearchParams(decodeURIComponent(window.location.search))
    const mGroupedReservationId = queryParams.get('grouped_reservation')

    if (
      endDate?.diff(startDate, 'days') &&
      campingStyle.minimum_bookable_nights &&
      !mGroupedReservationId
    ) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [campingStyle])

  useEffect(() => {
    // TODO: need to open this up for dynamic intervals
    const isDailyOrHourly = ['daily', 'morning', 'afternoon'].includes(intervalKindFor(selectedInterval))

    if (startDate && !endDate && isDailyOrHourly) {
      setEndDate(startDate.clone())
    }
  }, [selectedInterval])

  return (
    <>
      <BookerContext.Provider
        value={{
          camp,
          preferred_checkin_times,
          preferred_checkout_times,
          campingStyle,
          setCampingStyle,
          campingStylePrices: prices,
          campingStyleDateConfigurations: dateConfigurations,
          selectedSite,
          startDate,
          setStartDate,
          endDate,
          setEndDate,
          selectedAddOns,
          setSelectedAddOns,
          userElectric,
          groupedReservationId,
          setGroupedReservationId,
          selectedInterval,
          setSelectedInterval,
          isDailyOrHourly: ['daily', 'morning', 'afternoon'].includes(intervalKindFor(selectedInterval)), // TODO: need to open this up for dynamic intervals
          secondaryColor,
          userRigYear,
          setUserRigYear,
          userPets,
          setUserPets,
          trackForPark,
          trackForCamp,
          trackAdConversionForCamp,
          isKidsPrereqPresent,
          unavailableDates,
          setUnavailableDates,
          couponCode,
          setCouponCode,
        }}>
        {children}
      </BookerContext.Provider>
    </>
  )
}

export const BookerContext = createContext<ICtxPayload>(initialContext)
