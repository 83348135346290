import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, UseFormRegister, UseFormReturn } from 'react-hook-form'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { AdminContext } from '../../../contexts/AdminContext'
import moment from 'moment'
import InputFieldWithLabel from '../Inputs/InputFieldWithLabel'
import classNames from '../../../utils/classNames'
import { CampProps, CampingStyleProps } from '../../../interfaces'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import NumberInput from '../../ui/NumberInput'
import RichTextInput from '../../ui/RichTextInput'
import { Listbox, Transition } from '@headlessui/react'
import { useCookies } from 'react-cookie'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { FileUploader } from 'react-drag-drop-files'
import { DocumentPlusIcon } from '@heroicons/react/24/solid'
import { uniqueId } from 'lodash'
import api from '../../../api2'
import { Image } from '../SitePhotoUpload'
import { useDebouncedCallback } from 'use-debounce'
import { dateFormats } from '../../../constants/dateFormats'
import Pricing from './Pricing'

interface ComponentProps {
  reactHookForm: UseFormReturn<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>
}

const fileTypes = ['JPG', 'JPEG', 'PNG']

const EditCampForm = ({ reactHookForm }: ComponentProps) => {
  const { register, control, watch, setValue } = reactHookForm
  const { isParkAdminUser, camp, showNotification } = useContext(AdminContext)
  const [focusedInput, setFocusedInput] = useState(null)
  const date_format = watch('date_format')

  const handleChangeFormat = (format: string) => {
    setValue('date_format', format)
  }
  const [imageStates, setImageStates] = useState<Array<{ file: File, url: string; key: string; isLoading: boolean }>>([])

  const primary_color = watch('primary_color')
  const secondary_color = watch('secondary_color')
  
  useEffect(() => {
    register('bio', {})
    register('date_format', {})
  }, [register])

  const onEditorStateChange = (editorState) => {
    setValue('bio', editorState)
  }
  const editorContent = watch('bio')

  const handleAddPhoto = async (files: FileList) => {
    const fileList = Array.from(files)
    const newImages = fileList.map((file) => ({
      file,
      url: URL.createObjectURL(file), // Temporary URL for preview
      key: `temp-key-${uniqueId()}`,
      isLoading: true
    }))

    setImageStates(imageStates.concat(newImages))

    newImages.forEach((imageState) => {
      api.Camps.uploadImage(camp.id, imageState.file)
        .then((response) => {
          setImageStates((currentStates) =>
            currentStates.map((img) =>
              img.key === imageState.key ? { ...img, url: response.image.url, key: response.image.key, isLoading: false } : img
            )
          )
        })
        .catch((err) => {
          console.error(err)
          showNotification({
            type: 'error',
            title: `Failed to upload image ${imageState.file.name}`,
            description: err.message,
          })
        })
    })
  }

  const handleRemovePhoto = (key: string) => {
    setImageStates((currentStates) =>
      currentStates.map((img) => (img.key === key ? { ...img, isLoading: true } : img))
    )

    api.Camps.removeImage(camp.id, key)
      .then(() => {
        setImageStates((currentStates) => currentStates.filter((img) => img.key !== key))
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Failed to remove image',
          description: err.message,
        })
        setImageStates((currentStates) =>
          currentStates.map((img) => (img.key === key ? { ...img, isLoading: false } : img))
        )
      })
  }

  const fetchImages = useDebouncedCallback(() => {
    if (!camp) return

    api.Camps.fetchImages(camp.id)
      .then((response) => {
        setImageStates(response.images.map((image) => ({
          file: null,
          url: image.url,
          key: image.key,
          isLoading: true // assume they still need to load in
        })))
      })
      .catch((err) => {
        console.error(err)
      })
  }, 50)

  const updateImageLoadState = (key: string, isLoading: boolean) => {
    setImageStates((currentStates) =>
      currentStates.map((img) =>
        img.key === key ? { ...img, isLoading } : img
      )
    )
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return (
    <div>
      <div className='my-6 space-y-1'>
        <div className='text-lgxl font-semibold text-gray-900'>Camp Information</div>
        <div className='font-medium text-gray-500'>
          Info shown in the booking flow for campers about your camp.
        </div>
      </div>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
        <TextInput field='name' label='Name' register={register} />
        <div className='flex gap-4'>
          <ColorInput
            field='primary_color'
            label='Primary color'
            register={register}
            color={primary_color}
          />
          <ColorInput
            field='secondary_color'
            label='Secondary color'
            register={register}
            color={secondary_color}
          />
        </div>
        <TextInput field='email' label='Email' register={register} inputType='email' />
        <TextInput field='phone' label='Phone' register={register} />
        <div className='col-span-full grid gap-4 grid-cols-4'>
          <TextInput field='address' label='Address' register={register} />
          <TextInput field='city' label='City' register={register} />
          <TextInput field='state' label='State' register={register} />
          <TextInput field='zip' label='Zip' register={register} />
        </div>
        <div className='col-span-full'>
          <label className='block font-medium text-md text-gray-900'>Bio</label>
          <RichTextInput
            value={editorContent}
            setValue={onEditorStateChange}
            style={{ marginTop: 3.5 }}
            hyperlink
          />
        </div>
      </div>

      <hr className='my-6' />

      <div className='text-lgxl font-bold'>Season Dates</div>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
        <TextInput field='season_start_mm_dd' label='Opening (mm-dd)' register={register} />
        <TextInput field='season_end_mm_dd' label='Closing (mm-dd)' register={register} />
        <TextInput
          field='booking_start_mm_dd'
          label='Online Reservations Open (mm-dd)'
          register={register}
        />
      </div>

      <hr className='my-6' />

      <div className='text-lgxl font-bold'>Amenities Info</div>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
        <TextInput field='bathroom_code' label='Bathroom Code' register={register} />
        <TextInput field='wifi_network' label='Wi-Fi Network' register={register} />
        <TextInput field='wifi_password' label='Wi-Fi Password' register={register} />
      </div>

      <hr className='my-6' />

      <div className='text-lgxl font-bold'>Upload Camp Photos</div>
      <div className='gap-2 mb-2 mt-1 flex overflow-x-auto'>
        {imageStates?.map(({ url, key, isLoading }) =>
          <Image
            src={url}
            handleRemove={() => handleRemovePhoto(key)}
            isLoading={isLoading}
            onLoaded={() => updateImageLoadState(key, false)}
            onError={() => updateImageLoadState(key, false)}
          />
        )}
      </div>
      <FileUploader
        handleChange={handleAddPhoto}
        name='file'
        types={fileTypes}
        multiple
        children={
          <div className='border-2 border-dashed border-gray-300 p-8 h-24 flex items-center rounded-md cursor-pointer hover:bg-gray-75'>
            <DocumentPlusIcon className='w-8 h-8 text-gray-500 mr-4' />
            <div className='flex justify-between w-full'>
              <span className='text-gray-600 text-md'>Click or drag a photo here to upload</span>
              <span className='text-gray-500 text-md'>{fileTypes.join(', ')}</span>
            </div>
          </div>
        }
      />

      {isParkAdminUser && (
        <div className=''>
          <hr className='my-6' />

          <div className='text-lgxl font-bold'>Park Admin fields</div>

          <div className='mt-6 mb-2 text-base font-semibold'>Camp images</div>

          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            <TextInput field='map_url' label='Map URL' register={register} />
            <TextInput field='logo_url' label='Logo URL' register={register} />
          </div>

          <div className='mt-6 mb-2 text-base font-semibold'>Camp-level flags</div>

          <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
            <CheckboxWithLabel
              name='allow_same_day_reservations'
              label='Allow same day'
              register={register}
            />

            <CheckboxWithLabel
              name='allow_rv_on_tent_site'
              label='Allow RVs on tent sites'
              register={register}
            />
          </div>

          <div className='mt-4 grid grid-cols-1 gap-4 md:grid-cols-2'>
            <TextInput
              field='same_day_reservations_cutoff_time'
              label='Same day reservation cutoff time'
              register={register}
              disabled={!watch('allow_same_day_reservations')}
            />
          </div>

          <div className='mt-6 mb-2 text-base font-semibold'>Backend</div>

          <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
            <TextInput field='stripe_account_id' label='Stripe account ID' register={register} />
            <TextInput field='slug' label='Slug' register={register} />
            <TextInput field='shortened_slug' label='Shortened slug' register={register} />
            <TextInput
              field='timezone_offset'
              label='Timezone offset (deprecated)'
              register={register}
            />
            <TextInput field='timezone' label='Timezone' register={register} />

            <TextInput
              field='bookable_for_n_days'
              label='Bookable for N days'
              register={register}
            />
          </div>
        </div>
      )}
      <hr className='my-6' />

      <div className='text-lgxl font-bold'>Date Format</div>
      <Listbox value={date_format} onChange={handleChangeFormat}>
        <div className='relative mt-1'>
          <Listbox.Button className='relative w-full cursor-default rounded-md bg-white border border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 py-2 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:leading-6'>
            <span className='block truncate'>{date_format}</span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
              {dateFormats.map((format, i) => (
                <Listbox.Option
                  key={i}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={format}
                >
                  <span
                    className={`block truncate ${
                      format === date_format
                        ? 'font-medium'
                        : 'font-normal'
                    }`}
                  >
                    {format}
                  </span>
                  {format === date_format ? (
                    <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                      <CheckIcon className='h-5 w-5' aria-hidden='true' />
                    </span>
                  ) : null}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export const TextInput = ({
  field,
  label,
  register,
  inputType = 'text',
  className,
  disabled,
}: {
  field: string
  label: string
  register: UseFormRegister<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>
  inputType?: string
  className?: string
  disabled?: boolean
}) => {
  const { camp } = useContext(AdminContext)

  return (
    <div className={classNames('mb-2', className)}>
      <InputFieldWithLabel
        label={label}
        name={field}
        type={inputType}
        defaultValue={camp[field]}
        register={register}
        disabled={disabled}
      />
    </div>
  )
}

export const ColorInput = ({
  field,
  label,
  className,
  register,
  color,
}: {
  field: string
  label: string
  className?: string
  register: UseFormRegister<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>
  color: string
}) => {
  return (
    <div className='w-full'>
      <label htmlFor='email' className='block text-md font-medium leading-6 text-gray-900'>
        {label}
      </label>
      <div className='relative mt-1 rounded-md shadow-sm bg-white w-full border-gray-300 border'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <div className='rounded-full w-5 h-5' style={{ backgroundColor: color }}></div>
        </div>
        <div className='absolute text-gray-700 h-full flex items-center inset-y-0 left-11'>
          {color}
        </div>
        <input
          type='color'
          name={field}
          id={field}
          className='block opacity-0 cursor-text w-full rounded-md bg-white border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 py-5 pl-10 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:leading-6'
          defaultValue={color}
          {...register(field as any)}
        />
      </div>
    </div>
  )
}

export const DatePickerField = ({ control, field, label, focusedInput, setFocusedInput }) => {
  const { camp } = useContext(AdminContext)

  return (
    <div className='mb-4 w-full'>
      <label htmlFor={field} className='block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <Controller
        control={control}
        name={field}
        defaultValue={camp[field]}
        render={({ field: { onChange, value } }) => (
          <SingleDatePicker
            id={field}
            date={value ? moment(value) : null}
            block
            // disabled={disabled}
            onDateChange={(date) => onChange(date)}
            focused={focusedInput === field}
            onFocusChange={({ focused }) => setFocusedInput(focused ? field : null)}
            numberOfMonths={1}
            isOutsideRange={() => false}
            displayFormat='MMMM Do'
            hideKeyboardShortcutsPanel
          />
        )}
      />
    </div>
  )
}

export default EditCampForm
