import { useContext, useState } from 'react'
import InputFieldWithLabel from '../../Inputs/InputFieldWithLabel'
import React from 'react'
import Button from '../../../ui/Button'
import { AdminContext } from '../../../../contexts/AdminContext'
import api from '../../../../api2'
import SelectInput, { Option } from '../../../ui/SelectInput'
import { titleCase } from '../../../../utils'
import IAddOn from '../../../../interfaces/IAddOn'
import { ModalContext } from '../../../../contexts/admin/ModalContext'
import Toggle from '../../../ui/Toggle'
import { productTypes } from './Products'
import InputFieldWithCurrency from '../../Inputs/InputFieldWithCurrency'

const ProductModalContent = ({
  fetchProducts,
  selectedProduct,
}: {
  fetchProducts: () => Promise<void>
  selectedProduct: IAddOn | null
  }) => {
  const { camp, campingStyles, showNotification } = useContext(AdminContext)
  const { onClose } = useContext(ModalContext)
  const [selectedCampingStyleId, setSelectedCampingStyleId] = useState<string>(
    selectedProduct?.camping_style_id || 'all'
  )
  const [label, setLabel] = useState(selectedProduct?.label || '')
  const [selectedType, setSelectedType] = useState(
    selectedProduct
      ? productTypes.find((style) => style?.value == selectedProduct?.kind)?.value
      : 'normal'
  )
  const [description, setDescription] = useState(selectedProduct?.description || '')
  const [unitPrice, setUnitPrice] = useState(selectedProduct?.amount_in_cents / 100 || null)
  const [inventoryQuantity, setInventoryQuantity] = useState(
    selectedProduct?.inventory_quantity || null
  )
  const [isBookable, setIsBookable] = useState(selectedProduct?.is_bookable || false)
  const [isAppliedNightly, setIsAppliedNightly] = useState(
    selectedProduct?.is_applied_nightly || false
  )
  const [isRemoveButtonClicked, setIsRemoveButtonClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    api.AddOns.create(camp.id, {
      label,
      slug: label.toLowerCase().replace(/ /g, '-'),
      camp_id: camp.id,
      camping_style_id: selectedCampingStyleId == 'all' ? null : selectedCampingStyleId,
      kind: selectedType,
      description,
      amount_in_cents: unitPrice * 100,
      is_applied_nightly: isAppliedNightly,
      inventory_quantity: inventoryQuantity,
      is_bookable: isBookable,
    })
      .then(() => {
        showNotification({
          type: 'success',
          title: 'Product Created!',
          description: 'The product was successfully created.',
        })
        onClose()
        setIsLoading(false)
        fetchProducts()
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          title: error.message,
        })
        setIsLoading(false)
      })
  }

  const handleEditSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    api.AddOns.update(selectedProduct.id, {
      camp_id: camp.id,
      camping_style_id: selectedCampingStyleId == 'all' ? null : selectedCampingStyleId, //TODO: remove camping style id Ruby validation
      is_bookable: isBookable,
      label,
      slug: label.toLowerCase().replace(/ /g, '-'),
      kind: selectedType,
      description,
      amount_in_cents: unitPrice * 100,
      is_applied_nightly: isAppliedNightly,
      inventory_quantity: inventoryQuantity,
    })
      .then((product) => {
        showNotification({
          type: 'success',
          title: 'Changes Saved!',
          description: 'The product you modified were successfully updated.',
        })
        setIsLoading(false)
        fetchProducts()
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          title: error.message,
        })
        setIsLoading(false)
      })
      .finally(onClose)
  }

  const handleCancel = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClose()
  }

  const handleRemoveClick = (e) => {
    isRemoveButtonClicked ? handleArchive(e) : setIsRemoveButtonClicked(true)
  }

  const handleArchive = (e: React.FormEvent<HTMLButtonElement>) => {
    setIsRemoveButtonClicked(false)
    e.preventDefault()
    setIsLoading(true)

    api.AddOns.archive(selectedProduct.id)
      .then(() => {
        setIsLoading(false)
        fetchProducts()
      })
      .finally(onClose)
  }

  const campingStyleOptions = campingStyles
    .map((style) => ({
      label: titleCase(style.name).replace('Rv', 'RV'),
      value: style.id,
    }))

    campingStyleOptions.unshift({ label: 'All site types', value: 'all' })

  return (
    <form
      className='flex flex-col justify-between'
      onSubmit={selectedProduct ? handleEditSave : handleCreateSave}>
      <div className='flex flex-col gap-y-5'>
        <div className='font-semibold text-xl pb-4 mb-1 border-b border-gray-200'>
          Create product
        </div>
        <div className='text-baselg font-semibold -mb-2'>Basic settings</div>
        <div className='flex items-center gap-x-4'>
          <InputFieldWithLabel
            label='Name'
            required
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            className='flex-1'
          />
          <div className='relative flex-1'>
            <InputFieldWithCurrency
              label='Unit price'
              currency={camp.currency}
              required
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
            />
          </div>
          <InputFieldWithLabel
            label='Inventory quantity'
            type='number'
            value={inventoryQuantity}
            onChange={(e) => setInventoryQuantity(e.target.value)}
            className='flex-1'
          />
        </div>
        <InputFieldWithLabel
          label='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className='w-64 xs:w-auto'
        />
        <div className='text-baselg font-semibold -mb-2 mt-4'>Advanced settings</div>
        <Toggle
          checked={isBookable}
          onChange={setIsBookable}
          name='isBookable'
          label='Bookable'
          description='If enabled, this product will show up during online booking'
        />
        <Toggle
          checked={isAppliedNightly}
          onChange={setIsAppliedNightly}
          name='isAppliedNightly'
          label='Applied nightly'
          description='If enabled, this add-on will apply to each night of the reservation'
        />
        <div className='flex items-center justify-between'>
          <div>
            <div className='text-gray-900 text-md'>Type</div>
            <div className='text-gray-500 text-sm'>
              {productTypes.find((style) => style.value === selectedType).description}
            </div>
          </div>
          <div className='w-72 ml-3'>
            <SelectInput
              labelClassName='font-medium'
              options={productTypes}
              selected={productTypes.find((style) => style.value === selectedType)}
              setSelected={(option: Option) => setSelectedType(option.value)}
            />
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <div>
            <div className='text-gray-900 text-md'>
              Applicable for<span className='text-red-600 text-lg ml-0.5'>*</span>
            </div>
            <div className='text-gray-500 text-sm'>
              Determines if applicable for some or all site types
            </div>
          </div>
          <div className='w-72 ml-3'>
            <SelectInput
              labelClassName='font-medium'
              options={campingStyleOptions}
              selected={
                campingStyleOptions.find((style) => style.value === selectedCampingStyleId)
              }
              setSelected={(option: Option) => {
                setSelectedCampingStyleId(option.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col xs:flex-row xs:justify-between border-t border-gray-200 pt-5 mt-6'>
        <Button
          type='button'
          className={!selectedProduct && 'invisible'}
          variant='red'
          children={!isRemoveButtonClicked ? 'Delete' : 'Are you sure?'}
          onClick={handleRemoveClick}
          disabled={isLoading}
        />

        <div className='flex gap-4 mt-3 xs:mt-0'>
          <Button
            variant='white'
            children={'Cancel'}
            className='flex-auto xs:flex-initial w-32'
            onClick={handleCancel}
            disabled={isLoading}
          />
          <Button
            variant='green'
            children={'Save'}
            className='flex-auto xs:flex-initial w-32'
            type='submit'
            disabled={isLoading}
          />
        </div>
      </div>
    </form>
  )
}

export default ProductModalContent
