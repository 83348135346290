import React, { useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import classNames from '../../utils/classNames'
import Button from '../ui/Button'
import { BanknotesIcon, CreditCardIcon, LinkIcon } from '@heroicons/react/24/outline'
import { NewReservationProps } from '../../interfaces'
import HoverTip from '../ui/HoverTip'
import { NewReservationContext } from '../../contexts/admin/NewReservationContext'
import { LONG_TERM_RESERVATION_KINDS, RESERVATION_KINDS } from '../../constants'
import eSendingMode from '../../enums/eSendingMode'
import { AdminContext } from '../../contexts/AdminContext'

interface ComponentProps {
  className?: string
  disabled: boolean
  createReservation: any
  reservationData: NewReservationProps
  selectedSendingMode: eSendingMode
}

const CreateReservationDropdown = (props: ComponentProps) => {
  const [helperText, setHelperText] = useState({ text: '', isError: true })
  const {
    isAcceptingDeposit: isDeposit,
    selectedReservationType,
    isPayingSeparately,
    recurringKind,
    inputEmail,
    inputPhone,
  } = useContext(NewReservationContext)

  const isAcceptingDeposit = isDeposit
  const isGroup = selectedReservationType == RESERVATION_KINDS.GROUP
  const isRecurring = selectedReservationType == RESERVATION_KINDS.RECURRING

  const validInputEmail = inputEmail && /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/i.test(inputEmail)
  const validInputPhone = inputPhone && /^\+?[0-9]+$/.test(inputPhone.replace(/[() -]/g, ""))
  const canPaymentLink =
    (validInputEmail || validInputPhone) && props.selectedSendingMode != eSendingMode.NONE
  const { selectedSendingMode } = useContext(AdminContext)

  const isValidEmailOrPhone = () => {
    return (props.selectedSendingMode == eSendingMode.EMAIL && validInputEmail) ||
      (props.selectedSendingMode == eSendingMode.TEXT && validInputPhone) ||
      (props.selectedSendingMode == eSendingMode.EMAIL_AND_TEXT && validInputEmail && validInputPhone) ||
      (props.selectedSendingMode == eSendingMode.NONE)
  }

  let createButtonEnabled = true
  if ((isGroup && isPayingSeparately) || isRecurring) {
    createButtonEnabled = isValidEmailOrPhone()
  }

  const paymentLinkButton = (
    <button
      disabled={!canPaymentLink}
      onClick={() =>
        props.createReservation({
          withLink: true,
          recurringKind: recurringKind,
        })
      }
      className={classNames(
        'flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full text-white',
        !canPaymentLink && 'text-blue-300 pointer-events-none bg-opacity-30'
      )}>
      <LinkIcon className='w-5.5 h-5.5' />
      <p className='ml-2.5'>
        Payment link <span className='text-sm text-opacity-50'>(service fee applies)</span>
      </p>
    </button>
  )

  const paidUnpaidButtons = (
    <>
      <Menu.Item as='div'>
        <button
          onClick={() =>
            props.createReservation({
              isPrePaid: true,
              recurringKind: recurringKind,
            })
          }
          className='flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full'>
          <BanknotesIcon className='w-6' />
          <p className='text-white ml-2.5'>Paid reservation</p>
        </button>
      </Menu.Item>
      <Menu.Item as='div'>
        <button
          onClick={() => props.createReservation({ recurringKind: recurringKind })}
          className='flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full'>
          <svg
            version='1.1'
            id='Capa_1'
            width={20}
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            fill='white'
            viewBox='0 0 60.618 60.618'>
            <path
              d='M40.147,47.552l3,3H12.054c-4.272,0-7.746-3.475-7.746-7.746V18.682c0-1.938,0.72-3.708,1.901-5.068l2.139,2.139
	c-0.641,0.81-1.04,1.819-1.04,2.929v2.235h6.204l8.27,8.27H7.308v13.619c0,2.617,2.129,4.746,4.746,4.746H40.147z M52.872,50.552
	h-0.069l6.074,6.074c0.781,0.781,0.781,2.047,0,2.828c-0.391,0.391-0.902,0.586-1.414,0.586s-1.023-0.195-1.414-0.586L0.586,3.992
	c-0.781-0.781-0.781-2.047,0-2.828c0.781-0.781,2.047-0.781,2.828,0l9.772,9.771h39.686c4.271,0,7.746,3.476,7.746,7.747v24.124
	C60.618,47.078,57.144,50.552,52.872,50.552z M16.186,13.935l6.983,6.982h34.45v-2.235c0-2.617-2.129-4.747-4.746-4.747H16.186z
	 M49.804,47.552h3.069c2.617,0,4.746-2.129,4.746-4.746V29.187h-26.18L49.804,47.552z'
            />
          </svg>
          <p className='ml-2.5 text-white'>Unpaid reservation</p>
        </button>
      </Menu.Item>
    </>
  )

  return (
    <div className='flex items-center'>
      {isGroup && isPayingSeparately ? (
        <HoverTip
          content={'Please add a valid phone or email to send the booking link to'}
          active={!createButtonEnabled}
          className='w-72'>
          <Button
            variant='blue'
            size='large'
            type='submit'
            className='ml-2.5 flex-shrink-0'
            onClick={
              isPayingSeparately &&
              (() =>
                props.createReservation({
                  isGroupSeparated: true,
                  recurringKind: recurringKind,
                }))
            }
            disabled={!createButtonEnabled}>
            Create <span className='hidden sm:inline'>reservation</span>
          </Button>
        </HoverTip>
      ) : (
        <>
          <Transition
            show={true}
            as={Fragment}
            enter='transform ease-in duration-500 transition'
            enterFrom='translate-x-2 opacity-0'
            enterTo='translate-x-0 opacity-100'
            leave='transform ease-out duration-500 transition'
            leaveFrom='translate-x-0 opacity-100'
            leaveTo='translate-x-2 opacity-0'>
            <p
              className={`text-sm mr-2.5 font-semibold ${
                helperText.isError ? 'text-red-400' : 'text-green-500'
              }`}>
              {helperText.text}
            </p>
          </Transition>
          <Menu as='div' className={classNames('relative flex-shrink-0', props.className)}>
            <HoverTip
              content={'Please add a valid phone or email that invoices can be sent to'}
              active={!createButtonEnabled}
              className='w-72'>
              <Menu.Button disabled={props.disabled}>
                <Button
                  variant='blue'
                  size='large'
                  className='ml-auto'
                  disabled={props.disabled || !createButtonEnabled}>
                  Create <span className='hidden sm:inline'>reservation</span>
                </Button>
              </Menu.Button>
            </HoverTip>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'>
              <div
                className='origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-xl py-1 bg-blue-500 text-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40'
                style={{
                  top:
                    isRecurring && !isAcceptingDeposit
                      ? '-17.75rem'
                      : isAcceptingDeposit
                      ? '-12rem'
                      : '-15.5rem',
                }}>
                <Menu.Items>
                  {isAcceptingDeposit ? (
                    <>
                      <Menu.Item as='div'>
                        <button
                          onClick={() =>
                            props.createReservation({
                              isPayingNow: true,
                              recurringKind: recurringKind,
                            })
                          }
                          className='flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full'>
                          <CreditCardIcon className='w-6' />
                          <p className='ml-2.5 text-white'>Accept deposit now</p>
                        </button>
                      </Menu.Item>

                      <Menu.Item as='div'>
                        {canPaymentLink ? (
                          <div>{paymentLinkButton}</div>
                        ) : (
                          <HoverTip
                            content={
                              props.selectedSendingMode == eSendingMode.NONE
                                ? 'In order to send a payment link, you must send a confirmation message'
                                : 'Please add a valid phone or email to send the payment link to'
                            }
                          >
                            <div>{paymentLinkButton}</div>
                          </HoverTip>
                        )}
                      </Menu.Item>

                      <Menu.Item as='div'>
                        <button
                          onClick={() =>
                            props.createReservation({
                              recurringKind: recurringKind,
                            })
                          }
                          className='flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full'>
                          <BanknotesIcon className='w-6' />
                          <p className='text-white ml-2.5'>Deposit paid</p>
                        </button>
                      </Menu.Item>
                    </>
                  ) : (
                    <>
                      {isRecurring && (
                        <Menu.Item
                          as='div'
                          className='border-b border-white bg-blue-400 -mt-1 rounded-t-md'>
                          <p className='ml-4 text-white py-2 font-semibold'>
                            {recurringKind == LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY || recurringKind == LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY
                              ? 'Payment for the season'
                              : 'Payment for first month'}
                          </p>
                        </Menu.Item>
                      )}
                      <Menu.Item as='div'>
                        <button
                          onClick={() =>
                            props.createReservation({
                              isPayingNow: true,
                              recurringKind: recurringKind,
                            })
                          }
                          className='flex items-center py-4 px-4 hover:bg-blue-600 cursor-pointer w-full'>
                          <CreditCardIcon className='w-6' />
                          <p className='ml-2.5 text-white'>Accept payment now</p>
                        </button>
                      </Menu.Item>
                      <Menu.Item as='div'>
                        {canPaymentLink ? (
                          <div>{paymentLinkButton}</div>
                        ) : (
                          <HoverTip
                            content={
                              props.selectedSendingMode == eSendingMode.NONE
                                ? 'In order to send a payment link, you must send a confirmation message'
                                : 'Please add a valid phone or email to send the payment link to'
                            }>
                            <div>{paymentLinkButton}</div>
                          </HoverTip>
                        )}
                      </Menu.Item>
                      {paidUnpaidButtons}
                    </>
                  )}
                </Menu.Items>
              </div>
            </Transition>
          </Menu>
        </>
      )}
    </div>
  )
}

export default CreateReservationDropdown
