import { reservationKind, reservationStatus } from "../constants"
import eSendingMode from "../enums/eSendingMode"
import { SiteProps, ReservationProps, UserProps } from "../interfaces"
import ILongTermReservation from "../interfaces/ILongTermReservation"
import { PriceDetails } from "../utils/priceDetailsFor"

class ReservationDecorator implements ReservationProps {
  id?: string
  hashed_id?: string
  vendor_id?: string
  is_manual_charge?: boolean
  start_date?: string
  end_date?: string
  status?: reservationStatus
  discount_type?: string
  discount_membership_id?: string
  is_checked_in?: boolean
  is_checked_out?: boolean
  is_invoice_sent?: boolean
  invoice_sent_at?: string
  passengers?: number
  pets_info?: string
  kind?: reservationKind
  kid_campers?: number
  notes?: string
  invoice_memo?: string
  total?: number
  total_owed_in_cents?: number
  total_paid_in_cents?: number
  park_fee_in_cents?: number
  price_details?: PriceDetails
  is_locked?: boolean
  electricity_fee_in_cents?: number
  last_meter_reading?: number
  meter_reading?: number
  stripe_payment_link_url?: string
  stripe_payment_link_admin_url?: string
  selected_camping_style_id?: string
  sending_mode?: eSendingMode
  grouped_reservation_id?: string
  site_id?: string
  site?: SiteProps
  user_id?: string
  user?: UserProps
  long_term_reservation_id?: string
  long_term_reservation?: ILongTermReservation
  created_at?: string
  updated_at?: string
  deleted_at?: string

  constructor(private reservation: ReservationProps) {
    Object.assign(this, reservation)
  }

  get remainingOwedBeforeFeesInCents() {
    if (!this.total_owed_in_cents) return null

    return this.totalOwedBeforeFeesInCents - (this.total_paid_in_cents || 0)
  }

  get totalOwedBeforeFeesInCents() {
    if (!this.total_owed_in_cents)
      return null

    if (this.price_details) {
      const parkFeeInCents = this.price_details?.service_fee * 100
      const taxFeeInCents = this.price_details?.tax * 100
      const creditCardFeeInCents = this.price_details?.credit_card_fee * 100
      return this.total_owed_in_cents - parkFeeInCents - taxFeeInCents - creditCardFeeInCents
    } else {
      return this.total_owed_in_cents
    }
  }
}

export default ReservationDecorator
