import React, { useContext, useState } from 'react'

import { CampProps, CampingStyleProps, ReservationProps, SiteProps } from '../../interfaces'
import useLogRocket from '../../hooks/useLogRocket'
import EditInvoice from '../Admin/Invoices/EditInvoice'
import { AdminContext } from '../../contexts/AdminContext'
import { MapPinIcon } from '@heroicons/react/24/solid'
import IInvoice from '../../interfaces/IInvoice'

interface ComponentProps {
  invoice: IInvoice
  email: string
  site: SiteProps
  camp: CampProps
  is_admin: boolean
  camping_style: CampingStyleProps
}

const CustomerInvoice = (props: ComponentProps) => {
  useLogRocket()
  const [camp, setCamp] = useState<CampProps>(props.camp)

  return (
    <>
      <div
        className='flex justify-center shadow-none drop-shadow-none'
        style={{ backgroundColor: props.camp.primary_color }}>
        <div className='mx-2 xs:mx-4 flex-grow flex items-center my-8 flex-wrap'>
          <div style={{ color: props.camp.header_color }} id='camp-text'>
            <h2
              className='text-2xl sm:text-3xl header pt-0.5'
              style={{ fontFamily: 'Josefin sans' }}>
              {/* // TODO add in dynamic font type from params or db */}
              {props.camp.name}
            </h2>
            <span className='subheader flex items-center'>
              <MapPinIcon className='w-5 h-5 mr-1.5' />
              <span>
                {props.camp.city && !props.camp.state
                  ? `${props.camp.city}`
                  : `${props.camp.city}, ${props.camp.state}`}
              </span>
            </span>
          </div>
          {props.camp.logo_url && (
            <img src={props.camp.logo_url} className='h-32 ml-auto py-2 mr-4 ' />
          )}
        </div>
      </div>
      <EditInvoice
        invoice={props.invoice}
        disabled={false}
        site={props.site}
        campingStyle={props.camping_style}
        camp={camp}
        setCamp={setCamp}
        customerView
      />
    </>
  )
}

export default CustomerInvoice
