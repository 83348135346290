import { SiteProps } from "../interfaces"
import IS3Document from "../interfaces/IS3Document"
import { validDurations } from "../types"
import { destroy, get, patch, post } from "./utils/requests"

type IUpdateProps = {
  name?: string
  is_bookable?: boolean
  last_meter_reading?: number
  camper_capacity?: number
  vendor_calendars_attributes?: {
    id?: string
    kind: string
    calendar_url: string
    _destroy?: boolean
  }[]
  prices_attributes?: {
    duration: validDurations
    amount_in_cents: number
  }[]
}

const update = (id: string, data: IUpdateProps): Promise<{ site: SiteProps }> =>
  patch(`/sites/${id}`, { site: data })

const fetchImages = async (id: string): Promise<{ images: Array<IS3Document> }> =>
  get(`/api/sites/${id}/images`)

const uploadImage = async (id: string, file: File): Promise<{ image: IS3Document }> => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(`/api/sites/${id}/images`, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to upload image')
  }

  const resp = await response.json()

  return resp
}

const removeImage = async (id: string, key: string): Promise<{ message: string }> =>
  destroy(`/api/sites/${id}/images`, { key })

const Sites = {
  update,
  fetchImages,
  removeImage,
  uploadImage
}

export default Sites
