import React, { useContext, useEffect, useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerRangeController } from 'react-dates'
import { isInclusivelyBeforeDay } from 'react-dates'
import { HORIZONTAL_ORIENTATION } from 'react-dates/src/constants'
import moment, { Moment } from 'moment'
import Holidays from 'date-holidays'
import { displayDates } from '../../utils'
import { CampingStyleProps, CampProps, PriceProps } from '../../interfaces'
import { BookerContext } from '../../contexts/BookerContext'
import api from '../../api2'
import classNames from '../../utils/classNames'
import * as Sentry from '@sentry/react'
import HoverTip from '../ui/HoverTip'
import { DateTime } from 'luxon'
import CampDecorator from '../../decorators/CampDecorator'
import weekday from '../../types/weekday'
import IDateConfiguration from '../../interfaces/IDateConfiguration'

const initDateConfiguration: IDateConfiguration = {
  start_mm_dd: null,
  end_mm_dd: null,
  start_type: null,
  end_type: null,
  minimum_nights: null,
  is_minimum_inside_range: false,
  minimum_weekend_nights: null,
  restricted_check_in_days: [],
  restricted_check_out_days: []
}

const mapWeekendHolidayToDateConfiguration = (
  day: Moment,
  minimum_nights: number
): IDateConfiguration => {
  if (day.weekday() == 1) { // Monday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).subtract(3, 'days').format('MM-DD'),
      end_mm_dd: moment(day).add(1, 'day').format('MM-DD'),
      minimum_nights
    }
  } else if (day.weekday() == 2) { // Tuesday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).subtract(4, 'days').format('MM-DD'),
      end_mm_dd: moment(day).format('MM-DD'),
      minimum_nights
    }
  } else if (day.weekday() == 3) { // Wednesday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).format('MM-DD'),
      end_mm_dd: moment(day).add(4, 'day').format('MM-DD'),
      minimum_nights
    }
  } else if (day.weekday() == 4) { // Thursday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).format('MM-DD'),
      end_mm_dd: moment(day).add(3, 'day').format('MM-DD'),
      minimum_nights
    }
  } else if (day.weekday() == 5) { // Friday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).format('MM-DD'),
      end_mm_dd: moment(day).add(2, 'day').format('MM-DD'),
      minimum_nights
    }
  } else if (day.weekday() == 6) { // Saturday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).subtract(1, 'day').format('MM-DD'),
      end_mm_dd: moment(day).add(2, 'day').format('MM-DD'),
      minimum_nights
    }
  } else { // Sunday
    return {
      ...initDateConfiguration,
      start_mm_dd: moment(day).subtract(2, 'days').format('MM-DD'),
      end_mm_dd: moment(day).add(1, 'day').format('MM-DD'),
      minimum_nights
    }
  }
}

const holidayWeekendsFor = (camp: CampProps, year: number): Map<string, IDateConfiguration> => {
  const holidays = new Holidays(camp.country)
  const hashMap = new Map<string, IDateConfiguration>()

  if (!camp.minimum_holiday_weekend_nights) return hashMap

  const observedHolidayNames =
    camp.country == 'CA'
      ? ['Victoria Day', 'Canada Day', 'Civic Holiday', 'Labour Day']
      : ['Memorial Day', 'Independence Day', 'Labor Day', 'Columbus Day']
  const observedHolidays = holidays
    .getHolidays(year)
    .filter((h) => observedHolidayNames.includes(h.name))

  observedHolidays.forEach((holiday) => {
    const drc = mapWeekendHolidayToDateConfiguration(moment(holiday.date), camp.minimum_holiday_weekend_nights)
    const rangeStart = moment(drc.start_mm_dd, 'MM-DD').year(moment(holiday.date).year())
    const rangeEnd = moment(drc.end_mm_dd, 'MM-DD').year(moment(holiday.date).year())

    for (let m = moment(rangeStart); m.isSameOrBefore(rangeEnd); m.add(1, 'day')) {
      const formattedDate = m.format('YYYY-MM-DD')
      hashMap.set(formattedDate, drc)
    }
  })

  return hashMap
}

const rangeStartAndEndForConfig = (config: IDateConfiguration, year: number): [Moment, Moment] => {
  let rangeStart: Moment
  let rangeEnd: Moment

  if (config?.start_type === 'holiday') {
    const holidays = new Holidays('US')
    rangeStart = moment(holidays.getHolidays(year).find(h => h.name === config.start_mm_dd).date)
  } else {
    rangeStart = moment(config.start_mm_dd, 'MM-DD').year(year)
  }

  if (config?.end_type === 'holiday') {
    const holidays = new Holidays('US')
    rangeEnd = moment(holidays.getHolidays(year).find(h => h.name === config.end_mm_dd).date)
  } else {
    rangeEnd = moment(config.end_mm_dd, 'MM-DD').year(year)
  }

  return [rangeStart, rangeEnd]
}

const isDateWithinConfig = (date: Moment, config: IDateConfiguration, year: number): boolean => {
  const [rangeStart, rangeEnd] = rangeStartAndEndForConfig(config, year)
  return date.isBetween(rangeStart, rangeEnd, 'day', '[]')
}

const weekendsFor = (camp: CampProps, year: number, dateConfigurations: IDateConfiguration[]): Map<string, IDateConfiguration> => {
  const hashMap = new Map<string, IDateConfiguration>()
  const weekendDateConfigurations = dateConfigurations.filter((dc) => dc.minimum_weekend_nights)

  if (!camp.minimum_weekend_nights && !weekendDateConfigurations.length) {
    return hashMap
  }

  const startOfYear = moment().year(year).startOf('year')
  const endOfYear = moment().year(year).endOf('year')

  for (let m = moment(startOfYear); m.isSameOrBefore(endOfYear); m.add(1, 'day')) {
    if (m.weekday() !== 5) continue // Only process Fridays

    const dateConfig = weekendDateConfigurations.find((dc) => isDateWithinConfig(m, dc, year))
    const minimumNights = dateConfig?.minimum_weekend_nights || camp.minimum_weekend_nights

    if (!minimumNights) continue

    const drc: IDateConfiguration = {
      ...initDateConfiguration,
      start_mm_dd: m.format('MM-DD'),
      end_mm_dd: m.clone().add(2, 'day').format('MM-DD'),
      start_type: "fixed",
      end_type: "fixed",
      minimum_nights: minimumNights
    }

    const [rangeStart, rangeEnd] = rangeStartAndEndForConfig(drc, year)

    for (let n = moment(rangeStart); n.isSameOrBefore(rangeEnd); n.add(1, 'day')) {
      hashMap.set(n.format('YYYY-MM-DD'), drc)
    }
  }

  return hashMap
}

const inCampTimezone = (date: moment.MomentInput, camp: CampProps) => {
  return moment(date).zone(camp.timezone_offset)
}

const preprocessIntoHashMap = (seasonalPrices: PriceProps[], viewingMonth: Moment): Map<string, PriceProps> => {
  const hashMap = new Map<string, PriceProps>()

  seasonalPrices.forEach(price => {
    const currentYear = moment().year()
    let rangeStart = moment(price.range_start, 'MM-DD').year(currentYear)
    let rangeEnd = moment(price.range_end, 'MM-DD').year(currentYear)

    if (rangeStart.isAfter(rangeEnd)) {
      const viewingMonth = moment()
      viewingMonth.year() > moment().year() ? rangeStart.subtract(1, 'y') : rangeEnd.add(1, 'y')
    }

    const rangeDuration = rangeEnd.diff(rangeStart, 'days')

    for (let m = moment(rangeStart); m.isSameOrBefore(rangeEnd); m.add(1, 'day')) {
      const formattedDate = m.format('YYYY-MM-DD')

      if (hashMap.has(formattedDate)) {
        const existingPrice = hashMap.get(formattedDate)
        const existingRangeStart = moment(existingPrice.range_start, 'MM-DD').year(currentYear)
        const existingRangeEnd = moment(existingPrice.range_end, 'MM-DD').year(currentYear)
        const existingRangeDuration = existingRangeEnd.diff(existingRangeStart, 'days')

        if (rangeDuration < existingRangeDuration) {
          hashMap.set(formattedDate, price)
        }
      } else {
        hashMap.set(formattedDate, price)
      }
    }
  })

  return hashMap
}

const preprocessConfigurationsIntoHashMap = (dateConfigurations: IDateConfiguration[], viewingMonth: Moment): Map<string, IDateConfiguration> => {
  const hashMap = new Map<string, IDateConfiguration>()

  if (!dateConfigurations) return hashMap

  dateConfigurations.forEach(configuration => {
    if (configuration.start_mm_dd && configuration.end_mm_dd) {
      let rangeStart: Moment
      let rangeEnd: Moment

      if (configuration?.start_type === 'holiday') {
        const holidays = new Holidays('US')
        rangeStart = moment(holidays.getHolidays(viewingMonth.year()).find(h => h.name === configuration.start_mm_dd).date)
      } else {
        rangeStart = moment(configuration.start_mm_dd, 'MM-DD').year(viewingMonth.year())
      }

      if (configuration?.end_type === 'holiday') {
        const holidays = new Holidays('US')
        rangeEnd = moment(holidays.getHolidays(viewingMonth.year()).find(h => h.name === configuration.end_mm_dd).date)
      } else {
        rangeEnd = moment(configuration.end_mm_dd, 'MM-DD').year(viewingMonth.year())
      }

      if (rangeStart.isAfter(rangeEnd)) {
        viewingMonth.year() > moment().year() ? rangeStart.subtract(1, 'y') : rangeEnd.add(1, 'y')
      }

      if (configuration.minimum_nights > 1 && !configuration.is_minimum_inside_range) {
        rangeEnd = moment(rangeEnd).add(configuration.minimum_nights - 1, 'd')
      }

      for (let m = moment(rangeStart); m.isSameOrBefore(rangeEnd); m.add(1, 'day')) {
        const formattedDate = m.format('YYYY-MM-DD')
        hashMap.set(formattedDate, { ...configuration })
      }
    } else {
      const startOfYear = moment(viewingMonth).startOf('year')
      const endOfYear = moment(viewingMonth).endOf('year')
      for (let m = moment(startOfYear); m.isSameOrBefore(endOfYear); m.add(1, 'day')) {
        const formattedDate = m.format('YYYY-MM-DD')
        hashMap.set(formattedDate, {
          ...configuration,
          start_mm_dd: startOfYear.format('MM-DD'),
          end_mm_dd: endOfYear.format('MM-DD'),
          start_type: "fixed",
          end_type: "fixed"
        })
      }
    }
  })

  return hashMap
}

interface ComponentProps {
  camp: CampProps
  visible: boolean
  startDate: Moment
  endDate: Moment | null
  campingStyle: CampingStyleProps
  minimumNights: number
}

const DatePicker = (props: ComponentProps) => {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const [monthRange, setMonthRange] = useState<{ from: moment.Moment; until: moment.Moment }>({
    from: currentMonth.clone().subtract(1, 'months'),
    until: currentMonth.clone().add(2, 'months'),
  })
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    props.startDate ? 'endDate' : 'startDate'
  )
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    camp,
    campingStyle,
    campingStylePrices,
    campingStyleDateConfigurations,
    selectedInterval,
    isDailyOrHourly,
    unavailableDates,
    setUnavailableDates
  } = useContext(BookerContext)

  const seasonalPrices = campingStylePrices.filter((p) => p.range_start && p.range_end)
  const preprocessedSeasonalPrices = preprocessIntoHashMap(seasonalPrices, currentMonth)
  const preprocessedHolidayWeekends = holidayWeekendsFor(camp, currentMonth.year())
  const preprocessedWeekends = weekendsFor(camp, currentMonth.year(), campingStyleDateConfigurations)
  const preprocessedDateConfigurations = preprocessConfigurationsIntoHashMap(campingStyleDateConfigurations, currentMonth)

  useEffect(() => {
    let from
    let until

    if (window.outerWidth > 675) {
      from = currentMonth
        ? moment(currentMonth).subtract(1, 'month').startOf('month').utc(true).toISOString()
        : moment().subtract(1, 'month').startOf('month').utc(true).toISOString()
      until = currentMonth
        ? moment(currentMonth).add(1, 'month').endOf('month').utc(true).toISOString()
        : moment().add(1, 'month').endOf('month').utc(true).toISOString()
    } else {
      from = monthRange.from.startOf('month').utc(true).toISOString()
      until = monthRange.until.endOf('month').utc(true).toISOString()
    }

    api.DateAvailabilities.forDates({
      camp_id: camp.id,
      camping_style_id: campingStyle.id,
      start_date: from,
      end_date: until,
    })
      .then(({ unavailable_dates }: { unavailable_dates: string[] }) => {
        const datesByMonth = unavailable_dates.reduce((acc, date) => {
          const month = moment(date).format('YYYY-MM')
          acc[month] = acc[month] || []
          acc[month].push(date)
          return acc
        }, {})
        setUnavailableDates(datesByMonth)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
      })
  }, [currentMonth, campingStyle, monthRange])

  useEffect(() => {
    if (!startDate) {
      setFocusedInput("startDate")
    }
  }, [startDate])

  const onMonthChange = (currentMonth: Moment) => {
    setCurrentMonth(currentMonth)
  }

  const onChange = (dates: { startDate: Moment; endDate: Moment }) => {
    // NOTE: hard set the hour so that we're exactly 24 hours apart
    let start: Moment = dates.startDate?.hour(12)?.utc(true)
    let end: Moment = dates.endDate?.hour(12)?.utc(true)

    if (selectedInterval) {
      const startHour = Number(selectedInterval.start_time.split(":")[0])
      const endHour = Number(selectedInterval.end_time.split(":")[0])
      start = dates.startDate?.hour(startHour)?.utc(true)
      end = dates.endDate?.hour(endHour)?.utc(true)
    }

    setStartDate(start)
    setEndDate(end)
  }

  const onFocusChange = (input: 'startDate' | 'endDate') => {
    if (input) {
      if (input == 'endDate') {
        setEndDate(null)
      }
      setFocusedInput(input)
    } else {
      setFocusedInput('startDate')
    }
  }

  const onClickClearDates = () => {
    setStartDate(null)
    setEndDate(null)
    setFocusedInput('startDate')
  }

  const isOutsideSeason = (day: Moment, seasonStartDate: Moment, seasonEndDate: Moment) => {
    if (!(seasonStartDate && seasonEndDate)) return false

    const seasonStart: Moment = moment(seasonStartDate).hour(12)?.utc(true)
    const seasonEnd: Moment = moment(seasonEndDate)?.endOf('day')?.utc(true)

    return day.dayOfYear() < seasonStart.dayOfYear() || day.startOf('day').dayOfYear() > seasonEnd.dayOfYear()
  }

  const queryObject: { t?: string } = decodeURIComponent(window.location.search)
    .split('?')
    .pop()
    .split('&')
    .reduce((obj, x) => {
      const [key, value] = x.split('=')
      obj[key] = value
      return obj
    }, {})

  const bypassUnpublishedFlag = queryObject.t == 'bypass'

  const isOutsideBookableDays = (day: Moment, bookingStart: Moment, seasonEnd: Moment) => {
    if (!bookingStart || bypassUnpublishedFlag) return false

    const todayIsBeforeBookingStart = moment().isBefore(bookingStart)
    const dayIsAfterSeasonEnd = seasonEnd ? day.isAfter(seasonEnd, 'days') : day.isAfter(moment(bookingStart).endOf('year'), 'days')

    return todayIsBeforeBookingStart || dayIsAfterSeasonEnd
  }

  const dateRangeConfigurationFor = (day: Moment): IDateConfiguration => {
    let formattedDay = day.format('YYYY-MM-DD')

    if (window.outerWidth < 675) {
      formattedDay = `${currentMonth.format('YYYY')}-${day.format('MM-DD')}`
    }

    const seasonalPrice = preprocessedSeasonalPrices.get(formattedDay)
    const holidayWeekend = preprocessedHolidayWeekends.get(formattedDay)
    const weekend = preprocessedWeekends.get(formattedDay)
    const config = preprocessedDateConfigurations.get(formattedDay)

    let dateRangeConfiguration: IDateConfiguration
    let isWeekend = false

    if (holidayWeekend) {
      dateRangeConfiguration = holidayWeekend
    } else if (weekend) {
      dateRangeConfiguration = weekend
      isWeekend = true
    } else if (config) {
      dateRangeConfiguration = config
    } else if (seasonalPrice && seasonalPrice.minimum_nights) {
      // NOTE: this is deprecated and should be removed
      console.log("DEPRECATED: seasonalPrice.minimum_nights")
      Sentry.captureMessage("DEPRECATED: seasonalPrice.minimum_nights in DatePicker for camp " + camp.slug)
      dateRangeConfiguration = {
        ...initDateConfiguration,
        start_mm_dd: seasonalPrice.range_start,
        end_mm_dd: seasonalPrice.range_end,
        start_type: seasonalPrice.range_end_type,
        end_type: seasonalPrice.range_end_type,
        minimum_nights: seasonalPrice.minimum_nights
      }
    } else {
      return null
    }

    const nDay = moment(day).hour(0).minute(0).utc(true)
    const rangeStart = moment(dateRangeConfiguration.start_mm_dd, 'MM-DD').year(day.year()).hour(0).minute(0).utc(true)
    const rangeEnd = moment(dateRangeConfiguration.end_mm_dd, 'MM-DD').year(day.year()).hour(0).minute(0).utc(true)

    if (rangeStart.isAfter(rangeEnd)) {
      day.year() > moment().year() ? rangeStart.subtract(1, 'y') : rangeEnd.add(1, 'y')
    }

    const isEndOfRangeTooShort = (!startDate || nDay.isBefore(startDate)) && !isWeekend && dateRangeConfiguration.is_minimum_inside_range && nDay.isBetween(moment(rangeEnd).subtract(dateRangeConfiguration.minimum_nights, 'd'), rangeEnd, 'day', '()')
    let wouldStartOfRangeBeTooShort = false
    if (startDate) {
      const selectedStartDate = moment(startDate)
      if (selectedStartDate.isBefore(rangeStart)) {
        let endOfMinimumNightsRestriction: Moment
        if (dateRangeConfiguration.is_minimum_inside_range) {
          endOfMinimumNightsRestriction = moment(rangeStart).add(dateRangeConfiguration.minimum_nights, 'd').add(12, "h")
        } else {
          endOfMinimumNightsRestriction = moment(selectedStartDate).add(dateRangeConfiguration.minimum_nights, 'd')
        }
        wouldStartOfRangeBeTooShort = startDate.isBefore(rangeEnd) &&
          nDay.isBetween(moment(rangeStart), endOfMinimumNightsRestriction, 'day', '()')
      } else {
        wouldStartOfRangeBeTooShort = startDate.isBefore(rangeEnd) &&
          nDay.isBetween(moment(selectedStartDate), moment(selectedStartDate).add(dateRangeConfiguration.minimum_nights, 'd'), 'day', '()')
      }
    }

    if (isEndOfRangeTooShort || wouldStartOfRangeBeTooShort) {
      return dateRangeConfiguration
    } else {
      return null
    }
  }

  const isOutsideRange = (dayInput: Moment) => {
    const day = moment(dayInput).hour(12)?.utc(true)
    const today = moment()
    const formattedDay = moment(dayInput).format('YYYY-MM-DD')
    const flattenedUnavailableDates = Object.values(unavailableDates).flatMap(d => d)
    const [restrictedCheckInDays, restrictedCheckOutDays] = restricted_days_for(day)

    const isBetweenRestrictedAndUnavailable = flattenedUnavailableDates.includes(moment(formattedDay).add(1, 'day').format('YYYY-MM-DD')) &&
      restrictedCheckInDays &&
      restrictedCheckInDays.includes((day.weekday() - 1) % 7)

    if (!(startDate && !endDate) && flattenedUnavailableDates.includes(formattedDay) || isBetweenRestrictedAndUnavailable) {
      return true
    } else if (startDate && !endDate && flattenedUnavailableDates.length) {
      const unavailableDateAfterStart = flattenedUnavailableDates.sort((a, b) => a > b ? 1 : -1).find((d) => startDate.isBefore(d, 'day'))
      if (unavailableDateAfterStart && dayInput.isAfter(unavailableDateAfterStart, 'day')) {
        return true
      }
    }

    const nextBookableDays = props.camp.bookable_for_n_days

    const isBeforeToday = isInclusivelyBeforeDay(day, moment().subtract(1, 'd'))
    const isToday = today.isSame(day, 'd')
    let canCheckInSameDay = campingStyle.allow_same_day_reservations === null ? camp.allow_same_day_reservations : campingStyle.allow_same_day_reservations
    const cutoffTime = campingStyle.allow_same_day_reservations === null ? camp.same_day_reservations_cutoff_time : campingStyle.same_day_reservations_cutoff_time

    if (canCheckInSameDay && cutoffTime) {
      const [cutoffHour, cutoffMinute] = cutoffTime
        .split(':')
        .map((t) => Number(t))

      const currentLocalTime = DateTime.now().setZone(props.camp.timezone)
      const allowedCheckinTime = DateTime
        .now()
        .setZone(props.camp.timezone)
        .set({ hour: cutoffHour, minute: cutoffMinute})

      if (allowedCheckinTime.diffNow("seconds").seconds < 0) {
        canCheckInSameDay = false
      }
    }

    // TODO: replace all camp references with decoratedCamp via API
    const decoratedCamp = new CampDecorator(camp)

    const seasonStart = camp.season_start_mm_dd && moment(camp.season_start_mm_dd, 'MM-DD').year(day.year())
    const seasonEnd = camp.season_end_mm_dd && moment(camp.season_end_mm_dd, 'MM-DD').year(day.year())
    const bookingStart = decoratedCamp.bookingStartDatetime &&
      moment(decoratedCamp.bookingStartDatetime.set({ year: day.year() }).toJSDate())

    return (
      isBeforeToday ||
      (isToday && !canCheckInSameDay) ||
      isOutsideSeason(day, seasonStart, seasonEnd) ||
      isOutsideBookableDays(day, bookingStart, seasonEnd) ||
      (nextBookableDays && day.isAfter(moment(today).add(nextBookableDays, 'd')))
    )
  }

  const isDayRestrictedFor = (day: Moment, sDate: Moment, eDate: Moment) => {
    const [restrictedCheckInDays, restrictedCheckOutDays] = restricted_days_for(day)

    if ((!sDate || (sDate && eDate) || (sDate && day.isBefore(sDate))) && restrictedCheckInDays && restrictedCheckInDays.includes(day.weekday())) {
      return true
    }

    if (sDate && !eDate && restrictedCheckOutDays && restrictedCheckOutDays.includes(day.weekday())) {
      return true
    }

    return false
  }

  const restricted_days_for = (day: Moment): [number[], number[]] => {
    const config = preprocessedDateConfigurations.get(day.format('YYYY-MM-DD'))
    const restrictedCheckInDays = mapWeekdaysToNumbers(config?.restricted_check_in_days || campingStyle.restricted_check_in_days)
    const restrictedCheckOutDays = mapWeekdaysToNumbers(config?.restricted_check_out_days || campingStyle.restricted_check_out_days)

    return [restrictedCheckInDays, restrictedCheckOutDays]
  }

  const calculateInitialVisibleMonth = (): Moment => {
    if (startDate) return startDate

    const now = moment()

    let seasonStart = camp.season_start_mm_dd && moment(camp.season_start_mm_dd, 'MM-DD').year(now.year())
    const seasonEnd = camp.season_end_mm_dd && moment(camp.season_end_mm_dd, 'MM-DD').year(now.year())
    if (seasonEnd && now.isAfter(seasonEnd)) {
      seasonStart = seasonStart.year(now.year() + 1)
    }

    if (seasonStart && now.isBefore(seasonStart)) {
      return seasonStart
    } else {
      return now
    }
  }

  const handleRenderDayContents = (day: Moment) => {
    const dateRangeConfiguration = dateRangeConfigurationFor(day)
    let minimumNights: number
    let maximumNights: number

    if (dateRangeConfiguration) {
      minimumNights = dateRangeConfiguration.minimum_nights
    } else if (startDate && !endDate) {
      if (!!campingStyle.minimum_bookable_nights) {
        const from = moment(startDate)
        const until = moment(startDate).add(campingStyle.minimum_bookable_nights, 'd')
        if (day.isBetween(from, until, 'day', '[)')) {
          minimumNights = campingStyle.minimum_bookable_nights
        }
      }

      if (!!camp.max_stay_nights || !!campingStyle.max_stay_nights) {
        const lastEndDate = moment(startDate).add(camp.max_stay_nights || campingStyle.max_stay_nights, 'd')
        if (day.isAfter(lastEndDate, 'day')) {
          maximumNights = camp.max_stay_nights || campingStyle.max_stay_nights
        }
      }
    }

    if (isDayRestrictedFor(day, startDate, endDate)) {
      return (
        <HoverTip
          xs
          placement='top'
          content={`Not available for ${startDate && !endDate && day.isAfter(startDate) ? 'check-out' : 'check-in'}`}
        >
          <div className=''>{day.format('D')}</div>
        </HoverTip>
      )
    } else if (startDate && maximumNights && maximumNights > 1) {
      return (
        <HoverTip
          xs={!props.campingStyle.max_stay_nights_message}
          placement='top'
          content={
            props.campingStyle.max_stay_nights_message
              ? props.campingStyle.max_stay_nights_message.replace('%%', maximumNights.toString())
              : `${maximumNights}-night maximum`
          }>
          <div className=''>{day.format('D')}</div>
        </HoverTip>
      )
    } else if (minimumNights && minimumNights > 1) {
      let minimumEndDateStr: string

      if (dateRangeConfiguration) {
        if (dateRangeConfiguration.end_type == 'holiday') {
          minimumEndDateStr = dateRangeConfiguration.end_mm_dd
        } else {
          minimumEndDateStr = moment(dateRangeConfiguration.end_mm_dd, 'MM-DD').format('MMM D')
        }
      }

      return (
        <HoverTip
          xs
          placement='top'
          content={(startDate && !day.isBefore(startDate)) || !dateRangeConfiguration?.is_minimum_inside_range
            ? `${minimumNights}-night minimum`
            : `${minimumNights}-night minimum until ${minimumEndDateStr}`
          }
        >
          <div className=''>{day.format('D')}</div>
        </HoverTip>
      )
    } else {
      return (
        <>
          <div className=''>{day.format('D')}</div>
        </>
      )
    }
  }

  const mapWeekdaysToNumbers = (weekdays: Array<weekday>) => {
    return weekdays.map((day) => {
      return (moment().day(day).isoWeekday()) % 7
    })
  }

  const handleIsDayBlocked = (day: Moment) => {    
    if (isDayRestrictedFor(day, startDate, endDate)) return true

    const seasonalMinimumNightsForDay = dateRangeConfigurationFor(day)?.minimum_nights
    const isBeforeMinimumStay = Number(seasonalMinimumNightsForDay) > 1
    const maxStayNights = camp.max_stay_nights || campingStyle.max_stay_nights
    const isAfterMaxStay = startDate && !endDate && maxStayNights && day.isAfter(moment(startDate).add(maxStayNights, 'd'), 'day')

    return isBeforeMinimumStay || isAfterMaxStay
  }

  const handleRenderNextButton = (args) => {
    const { onClick, disabled }: { onClick: () => any; disabled: boolean } = args

    if (window.outerWidth > 675) return null

    return (
      <button
        onClick={() => {
          setMonthRange({
            ...monthRange,
            until: monthRange.until.clone().add(4, 'months'),
          })

          onClick()
        }}
        disabled={disabled}
        className='mt-8 w-full border border-gray-500 rounded-md py-2 text-gray-800 hover:text-gray-500 transition-colors duration-200 ease-in-out'
      >
        Load more dates
      </button>
    )
  }

  let headerText = ''
  let headerSubtext = ''
  // TODO: let's make sure this handles all edgecases with timezones/times of day
  const numberOfDays = moment(endDate).hour(0).minute(0).diff(moment(startDate).hour(0).minute(0), 'days')

  if (!startDate) {
    headerText = 'Select arrival date'
  } else if (startDate && !endDate) {
    headerText = 'Select departure date'
  } else if (isDailyOrHourly) {
    headerText = numberOfDays + 1 + (numberOfDays + 1 > 1 ? ' Days' : ' Day')
  } else if (startDate && endDate) {
    headerText = numberOfDays + (numberOfDays > 1 ? ' Nights' : ' Night')
  }

  if (!startDate && !endDate) headerSubtext = 'Add your travel dates'
  else if (startDate && !endDate && props.minimumNights > 1)
    headerSubtext = `Minimum stay: ${props.minimumNights} nights`
  else headerSubtext = displayDates(startDate, endDate)

  return (
    <div className={props.visible ? 'transition visible' : 'transition hidden'}>
      <div className='text-center mt-3 mb-6 lg:mb-0'>
        <div className='text-2xl font-semibold'>{headerText}</div>
        <p className='text-gray-600 text-base'>{headerSubtext}</p>
        {startDate &&
          !endDate &&
          campingStyle?.max_stay_nights &&
          campingStyle?.max_stay_nights_message && (
            <p className='text-gray-600 text-base'>
              {campingStyle.max_stay_nights_message.replace(
                '%%',
                campingStyle.max_stay_nights.toString()
              )}
            </p>
          )}
        <a
          onClick={onClickClearDates}
          className={classNames(
            'text-sm underline font-semibold hover:text-gray-700',
            !startDate && !endDate && 'opacity-30 pointer-events-none'
          )}>
          Clear dates
        </a>
      </div>
      <div className='h-96'>
        <DayPickerRangeController
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          noNavPrevButton={window.outerWidth < 675}
          renderNavNextButton={window.outerWidth <= 650 && handleRenderNextButton}
          onNextMonthClick={onMonthChange}
          onPrevMonthClick={onMonthChange}
          orientation={window.outerWidth > 675 ? HORIZONTAL_ORIENTATION : 'verticalScrollable'}
          isOutsideRange={isOutsideRange}
          // NOTE: used for minimum nights logic
          isDayBlocked={handleIsDayBlocked}
          numberOfMonths={window.outerWidth > 675 ? 2 : 4}
          initialVisibleMonth={calculateInitialVisibleMonth}
          renderDayContents={handleRenderDayContents}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
          minimumNights={props.minimumNights}
        />
      </div>
    </div>
  )
}

export default DatePicker
