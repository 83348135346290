export const desktopToolbar = [
  ['bold', 'italic', 'underline'], // toggled buttons
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme

  ['clean'], // remove formatting button
]

export const desktopToolbarHyperlink = [
  ['bold', 'italic', 'underline'], // toggled buttons
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme

  ['link','clean'], // remove formatting button
]


export const mobileToolbar = [
  ['bold', 'italic', 'underline'], // toggled buttons
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  [{ list: 'ordered' }, { list: 'bullet' }],
]
