import React, { useEffect, useState } from 'react'
import { Card, Container, Divider, Menu, Message, Segment } from 'semantic-ui-react'
import { CampProps } from '../interfaces'

import LoginForm from './AdminLogin/LoginForm'
import parkLogoPath from '../../assets/images/park_logo__green.svg'
import useLogRocket from '../hooks/useLogRocket'

interface ComponentProps {
  camp?: CampProps
  notices?: Array<string>[]
}

const AdminLogin = (props: ComponentProps) => {
  useLogRocket()

  const [errorMessage, setErrorMessage] = useState('')

  const handleLoginMenuItemClick = () => {
    window.location.href = `${window.origin}/admin/login`
  }

  return (
    <>
      <Segment>
        <Container>
          <Menu secondary>
            <span className='flex-inline align-middle mr-2'>
              <img src={parkLogoPath} className='inline z-10 h-10' />
            </span>
            <Menu.Menu position='right'>
              <Menu.Item name='login' active onClick={handleLoginMenuItemClick} />
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
      <Container>
        {props.notices?.map(([type, message]: [string, string]) => {
          return <Message key={type} content={message} />
        })}
        <Message hidden={!errorMessage} error={!!errorMessage} content={errorMessage} />
        <Divider hidden fitted={!!errorMessage} />
      </Container>
      <Container>
        <Segment basic className='flex justify-center'>
          <Card.Group centered className='max-w-md w-full'>
            <LoginForm setErrorMessage={setErrorMessage} />
          </Card.Group>
        </Segment>
      </Container>
    </>
  )
}

export default AdminLogin