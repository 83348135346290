import React, { useContext, useEffect, useState } from 'react'
import api from '../../../api2'
import Button from '../../ui/Button'
import classNames from '../../../utils/classNames'
import Alert from '../../ui/Alert'
import pluralize from '../../../utils/pluralize'
import { ReservationProps } from '../../../interfaces'
import { AdminContext } from '../../../contexts/AdminContext'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import { SendInvoiceParams } from '../../../api2/reservations'
import Spinner from '../../ui/Spinner'
import SelectInput, {Option} from '../../ui/SelectInput'
import { sendingOptions } from '../../Reservations/sendingOptions'
import eSendingMode from '../../../enums/eSendingMode'
import { formatName } from '../../../utils/formatName'
import IInvoice from '../../../interfaces/IInvoice'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import eSendingModeCookieName from '../../../enums/eSendingModeCookieName'

const mapToReqData = (invoice: IInvoice, sendingMode: eSendingMode): SendInvoiceParams => ({
  id: invoice.id,
  recurring_price_in_cents: invoice.order.total_in_cents,
  electricity_fee_in_cents: invoice.reservation?.electricity_fee_in_cents,
  last_meter_reading: invoice.reservation?.last_meter_reading,
  meter_reading: Number(invoice.reservation?.meter_reading),
  electric_used: Number(invoice.reservation?.meter_reading) - Number(invoice.reservation?.last_meter_reading),
  is_sending_invoice: true,
  sending_mode: sendingMode,
})

interface ComponentProps {
  invoices: IInvoice[]
  onSuccess: (reservations: ReservationProps[]) => void
  refreshLocalInvoices: () => void
}

const cookieSendingMode = eSendingModeCookieName.BULK_INVOICES

const InvoicesModalContent = (props: ComponentProps) => {
  const { onClose } = useContext(ModalContext)
  const { camp, showNotification, updateLocalReservations } = useContext(AdminContext)
  const [invoices, setInvoices] = useState<IInvoice[]>(props.invoices) // NOTE: for updating on resp
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isShowingCc, setIsShowingCc] = useState(false)
  const [cookies, setCookie] = useCookies([cookieSendingMode])

  const sendinModeFromCookie = cookies && Object.values(eSendingMode).filter(item => item !== eSendingMode.NONE).includes(cookies[cookieSendingMode]) ? cookies[cookieSendingMode] : false
  const [sendingMode, setSendingMode] = useState(sendinModeFromCookie || invoices[0]?.reservation?.sending_mode || camp.booking_sending_mode)

  useEffect(() => {
    setInvoices(props.invoices)
  }, [props.invoices])

  const handleClickSendInvoices = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true)

    e.preventDefault() // NOTE: prevents "form submission" and trying to change pages

    api.Invoices.updateMany(
      camp.id,
      {
        invoices: invoices.map((invoice) => ({
          invoiceId: invoice.id,
          isSending: true,
          sendingMode: sendingMode,
          admin_email: isShowingCc ? camp.email : undefined,
        }))
      }
    )
      .then(({ invoices, records: erroneousInvoices }) => {
        updateLocalReservations(invoices.map((r) => r.reservation).filter(Boolean))
        props.refreshLocalInvoices()

        if (erroneousInvoices?.length) {
          setErrorMessage(erroneousInvoices.map((r) => r.errors.join(", ")).join(", "))
          showNotification({
            type: 'warning',
            title: `${invoices.length} ${pluralize('invoice', invoices.length)} sent, ${erroneousInvoices.length} ${pluralize('error', erroneousInvoices.length)}`,
            description: erroneousInvoices.map((r) => r.errors.join(", ")).join(", "),
            dismissAfter: 5000,
          })
          setInvoices(erroneousInvoices.map((r) => r))
        } else {
          showNotification({
            type: 'success',
            title: `${invoices.length} ${pluralize('invoice', invoices.length)} sent`
          })
          onClose()
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
        showNotification({
          type: 'error',
          title: 'Error sending invoices',
          description: error.message,
          dismissAfter: 5000,
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    !(sendingMode === eSendingMode.EMAIL || sendingMode === eSendingMode.EMAIL_AND_TEXT) && setIsShowingCc(false)
    sendingMode && setCookie(cookieSendingMode, sendingMode)
  }, [sendingMode])

  useEffect(() => {
    !cookies[cookieSendingMode] && setCookie(cookieSendingMode, sendingMode)
  }, [])

  return (
    <>
      <div className='flex items-center justify-between mb-4 bg-gray-100 px-4 pt-6 pb-4 -m-6 rounded-t-lg'>
        <div className='flex flex-col w-full'>
          <div className='font-semibold text-xl'>
            Confirm sending invoices to {invoices.length} {pluralize('guest', invoices.length)}
          </div>
        </div>
      </div>

      <form className='space-y-4'>
        {errorMessage && <Alert description={errorMessage} />}

        <div className='text-baselg'>
          {invoices
            .map((invoice) => <p>{formatName(invoice.reservation?.user) + ' in ' + invoice.reservation?.site?.name}</p>)
            }
        </div>

        <div className='pt-4 border-t border-gray-200'>
          <div className='flex flex-col space-x-2 justify-between'>
          {(sendingMode === eSendingMode.EMAIL ||
              sendingMode === eSendingMode.EMAIL_AND_TEXT) && (
              <div className="flex items-center mb-2 justify-end">
                <CheckboxWithLabel
                  label={`cc ${camp.email}`}
                  value={isShowingCc}
                  setValue={setIsShowingCc}
                  labelClasses="text-sm whitespace-nowrap"
                />
              </div>
            )}
            <div className='w-full flex items-center justify-end'>
              <div className='mr-4'>
                <SelectInput
                  name='sendingOptions'
                  options={sendingOptions.filter((o) => o.value !== eSendingMode.NONE)}
                  selected={sendingOptions
                    .filter((o) => o.value !== eSendingMode.NONE)
                    .find((o) => o.value === sendingMode)}
                  setSelected={(o: Option) => setSendingMode(o.value)}
                />
              </div>
              <div className='hidden sm:block'>
                <Button
                  type='button'
                  variant='gray'
                  className={classNames('mr-4 w-28', isLoading && 'pointer-events-none opacity-60')}
                  onClick={onClose}
                  disabled={isLoading}>
                  Cancel
                </Button>
              </div>
              <Button variant='green' onClick={handleClickSendInvoices} disabled={isLoading}>
                {isLoading ? <Spinner size={4} /> : 'Send invoices'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default InvoicesModalContent
