import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  EnvelopeIcon,
  EyeIcon,
  PencilIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'
import Button from '../../ui/Button'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

export default function GuestDropdown({
  isCamperLoading,
  camper,
  onClickEditCamper,
  onClickRemoveCamper,
  onClickViewCamper,
  onClickMessageCamper,
}) {
  return (
    <div className='text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <Menu.Button>
          <Button
            variant='white'
            disabled={isCamperLoading}
            className='flex items-center text-gray-600'
            size='small'
            shadow='small'
            type='button'>
            <EllipsisHorizontalIcon className='h-5.5 w-5.5 text-gray-600' aria-hidden='true' />
          </Button>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white z-20 shadow-lg ring-1 ring-gray-200 focus:outline-none'>
            <div className='py-1.5'>
              {camper?.id ? (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100' : 'text-gray-600'
                        } group flex w-full items-center px-2 py-2 text-base`}
                        onClick={onClickEditCamper}>
                        <PencilIcon className='w-5 h-5 mr-2' />
                        Edit guest
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100' : 'text-gray-600'
                        } group flex w-full items-center px-2 py-2 text-base`}
                        onClick={onClickViewCamper}>
                        <EyeIcon className='w-5 h-5 mr-2' />
                        View guest
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? 'bg-gray-100' : 'text-gray-600'
                        } group flex w-full items-center px-2 py-2 text-base`}
                        onClick={onClickRemoveCamper}>
                        <UserMinusIcon className='w-5 h-5 mr-2' />
                        Detach guest
                      </button>
                    )}
                  </Menu.Item>
                </>
              ) : (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100' : 'text-gray-600'
                      } group flex w-full items-center px-2 py-2 text-base`}
                      onClick={onClickEditCamper}>
                      <UserPlusIcon className='w-5 h-5 mr-2' />
                      Add guest
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
            <div className='py-1.5'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-600'
                    } group flex w-full items-center px-2 py-2 text-base`}
                    onClick={onClickMessageCamper}>
                    <EnvelopeIcon className='w-5 h-5 mr-2' />
                    Message guest
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
