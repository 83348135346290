import React from 'react'
import classNames from '../../utils/classNames'

const classes = {
  base: 'focus:outline-none transition ease-in-out duration-100 rounded-md open-sans line-height-inherit',
  disabled: 'opacity-30 cursor-not-allowed pointer-events-none',
  pill: 'rounded-full',
  size: {
    small: 'px-2.5 py-1.5 text-md',
    normal: 'px-4 py-2.5 text-mdbase',
    large: 'px-6 py-3 text-lg',
  },
  shadow: {
    small: 'shadow-sm',
    medium: 'shadow-md',
    large: 'shadow-lg',
  },
  variant: {
    // add enabled: for hover with tailwind v3
    green: 'bg-green-500 hover:bg-green-600 text-white',
    gray: 'bg-gray-200 hover:bg-gray-300 text-black',
    red: 'bg-red-500 hover:bg-red-600 text-white',
    redOutline: 'hover:bg-red-50 border border-red-500 text-red-600 hover:text-red-500',
    pink: 'bg-red-300 hover:bg-red-400 text-black',
    blue: 'bg-blue-500 hover:bg-blue-600 text-white',
    yellow: 'bg-yellow hover:bg-orange text-white',
    yellowOutline: 'hover:bg-yellow border-2 border-yellow text-orange hover:text-white',
    white: 'bg-white hover:bg-gray-75 text-black border border-gray-300 hover:border-gray-400',
    black: 'bg-black hover:bg-gray-800 text-white',
  },
}

interface ComponentProps {
  children: any
  className?: string
  style?: object
  variant?:
    | 'black'
    | 'green'
    | 'gray'
    | 'red'
    | 'blue'
    | 'yellow'
    | 'yellowOutline'
    | 'none'
    | 'white'
    | 'pink'
    | string
  size?: 'small' | 'normal' | 'large' | string
  pill?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
  type?: 'button' | 'submit' | 'reset'
  icon?: any
  ref?: any
  shadow?: 'small' | 'medium' | 'large'
}

const Button = ({
  children,
  className,
  style,
  variant = 'gray',
  size = 'normal',
  shadow,
  pill,
  disabled = false,
  onClick,
  type,
  icon,
  ref,
}: ComponentProps) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        `${classes.base ? classes.base : ''} ${classes.size[size] ? classes.size[size] : ''} ${
          classes.variant[variant] ? classes.variant[variant] : ''
        } ${pill ? classes.pill : ''} ${disabled ? classes.disabled : ''} ${
          classes.shadow[shadow] ? classes.shadow[shadow] : ''
        } ${className ? className : ''} ${icon ? 'flex justify-center items-center' : ''}`
      )}
      style={style}
      type={type || 'button'}
      ref={ref}>
      {icon && <span className='mr-2 -ml-0.5'>{icon}</span>}
      {children}
    </button>
  )
}

export default Button
