const ONLINE: 'online' = 'online'
const PAYMENT_LINK: 'payment-link' = 'payment-link'
const ADMIN_MANUAL: 'admin-manual' = 'admin-manual'
const ADMIN_PAY_NOW: 'admin-pay-now' = 'admin-pay-now'
const POS: 'pos' = 'pos'
const INVOICE: 'invoice' = 'invoice'

export const PAYMENT_MODES = {
  ONLINE,
  PAYMENT_LINK,
  ADMIN_MANUAL,
  ADMIN_PAY_NOW,
  POS,
  INVOICE,
}
