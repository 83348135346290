import React, { useContext, useEffect, useState } from 'react'
import GroupedReservationLinkField from '../Reservations/EditReservationForm/GroupedReservationLinkField'
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  MapPinIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import classNames from '../../../utils/classNames'
import { AdminContext } from '../../../contexts/AdminContext'
import IGroupedReservation from '../../../interfaces/IGroupedReservation'
import api from '../../../api2'
import Modal from '../../ui/Modal'
import moment from 'moment'
import { formatMoney } from '../../../utils/formatMoney'
import { ReservationProps } from '../../../interfaces'
import { StatusBlocked, StatusDeleted, StatusPaid, StatusUnpaid } from '../../ui/StatusBadges'
import { STATUSES } from '../../../constants'
import pluralize from '../../../utils/pluralize'
import Button from '../../ui/Button'
import { GROUP_PAYMENT_TYPES } from '../../../constants/group_payment_types'
import { PriceDetails as IPriceDetail } from '../../../utils/priceDetailsFor'
import mergePriceDetails from '../../../utils/mergePriceDetails'
import NumberInput from '../../ui/NumberInput'
import useAdminForm from '../../../hooks/useAdminForm2'
import { formatName } from '../../../utils/formatName'
import ReservationDecorator from '../../../decorators/ReservationDecorator'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import stripeIntentUrlFor from '../../../utils/admin/stripeIntentUrlFor'

interface IForm {
  amountOwedByGroup: number
  amountPaidByGroup: number
}

interface ComponentProps {
  groupedReservationId: string
}

const GroupModalContents = ({ groupedReservationId }: ComponentProps) => {
  const { reservationStates, camp, sites, showPaymentModal, updateLocalReservations } =
    useContext(AdminContext)
  const { onClose } = useContext(ModalContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRemoveButtonClicked, setIsRemoveButtonClicked] = useState<boolean>(false)
  const [groupedReservation, setGroupedReservation] = useState<IGroupedReservation>(null)
  const [reservations, setReservations] = useState<ReservationProps[]>([])
  const [priceDetail, setPriceDetail] = useState<IPriceDetail>(null)

  const {
    register,
    resetDefaults,
    watch,
    isDirty,
    setValue,
    defaultValues,
    resource: formObject,
  } = useAdminForm<IForm>({
    amountOwedByGroup: reservations.reduce((acc, r) => acc + r.total_owed_in_cents, 0) / 100.0,
    amountPaidByGroup: reservations.reduce((acc, r) => acc + r.total_paid_in_cents, 0) / 100.0,
  })

  const { amountOwedByGroup, amountPaidByGroup } = watch()

  const [isPaid, setIsPaid] = useState<boolean>(amountPaidByGroup >= amountOwedByGroup)

  const fetchLocalReservationsForGroup = () =>
    Object.values(reservationStates)
      .filter((rs) => {
        const localRes = rs.updated || rs.persisted
        return localRes.grouped_reservation_id === groupedReservationId
      })
      .map((rs) => rs.updated || rs.persisted)

  const handleAcceptPaymentForGroup = async () => {
    const decoratedGroup = reservations.map((r) => new ReservationDecorator(r))
    const amountOwedByGroupBeforeFees =
      decoratedGroup.reduce((acc, r) => acc + r.remainingOwedBeforeFeesInCents, 0) / 100.0
    showPaymentModal(amountOwedByGroupBeforeFees * 100, {
      reservations,
      groupedReservation,
    })
  }

  useEffect(() => {
    if (!open || !groupedReservationId) return

    const localReservations = fetchLocalReservationsForGroup()
    setPriceDetail(mergePriceDetails(localReservations.map((r) => r.price_details)))

    setReservations(localReservations)

    setValue(
      'amountOwedByGroup',
      localReservations.reduce((acc, r) => acc + r.total_owed_in_cents, 0) / 100.0
    )
    setValue(
      'amountPaidByGroup',
      localReservations.reduce((acc, r) => acc + r.total_paid_in_cents, 0) / 100.0
    )

    api.GroupedReservations.find(groupedReservationId)
      .then((gr) => {
        setGroupedReservation(gr)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [open, groupedReservationId])

  useEffect(() => {
    const localReservations = fetchLocalReservationsForGroup()

    if (!localReservations.length) {
      onClose()
      return
    }

    setPriceDetail(mergePriceDetails(localReservations.map((r) => r.price_details)))
    setReservations(localReservations)
    setValue(
      'amountOwedByGroup',
      localReservations.reduce((acc, r) => acc + r.total_owed_in_cents, 0) / 100.0
    )
    setValue(
      'amountPaidByGroup',
      localReservations.reduce((acc, r) => acc + r.total_paid_in_cents, 0) / 100.0
    )
  }, [reservationStates])

  // TODO: we should only set this based on persisted values
  useEffect(() => {
    setIsPaid(reservations.every((r) => r.status === STATUSES.PAID))
  }, [reservations.map((r) => r.status).join('')])

  useEffect(() => {
    setIsRemoveButtonClicked(false)
  }, [open])

  const handleCancelClick = () => {
    resetDefaults()
    onClose()
  }

  const handleSaveClick = () => {
    setIsLoading(true)

    api.GroupedReservations.updateAggregate(groupedReservationId, {
      amount_owed_by_group_in_cents: amountOwedByGroup * 100,
      amount_paid_by_group_in_cents: amountPaidByGroup * 100,
    })
      .then(({ grouped_reservation, reservations }) => {
        updateLocalReservations(reservations)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }

  const handleRemoveClick = () => {
    !isRemoveButtonClicked ? setIsRemoveButtonClicked(true) : handleRemove()
  }

  const handleRemove = () => {
    setIsRemoveButtonClicked(false)
    setIsLoading(true)

    api.GroupedReservations.destroy(groupedReservationId)
      .then(({ grouped_reservation, reservations }) => {
        updateLocalReservations(reservations)
        setIsLoading(false)
        onClose()
      })
      .catch((err) => {
        console.error(err)
        // TODO: show message
        setIsLoading(false)
      })
  }

  const isBlockerGroup = reservations.some((r) => r.status === STATUSES.BLOCKED)

  return (
    <>
      <div className='mb-4 border-b border-gray-200'>
        <div className='flex items-center justify-between mb-4 bg-gray-100 px-4 pt-6 pb-4 -m-6 rounded-t-lg'>
          <div className='flex flex-col w-full'>
            <div className='font-semibold text-xl'>
              Manage {formatName(groupedReservation?.user)} group
            </div>
            <div className='flex items-center mt-2'>
              <div className='flex items-center text-gray-600'>
                <MapPinIcon className='w-5 h-5 text-gray-600 mr-2' />
                <div className='font-medium'>
                  {reservations.length} {pluralize('site', reservations.length)}
                </div>
              </div>
              <div className='flex items-center text-gray-600 ml-4 sm:ml-8'>
                <CalendarDaysIcon className='w-5 h-5 text-gray-600 mr-2' />
                <div className=''>
                  <span className='font-medium'>
                    {/* TODO: should we store this info on the goruped_reservation table? Since theoritically it could change? */}
                    {moment(reservations[0]?.start_date).isSame(reservations[0]?.end_date, 'day')
                      ? moment(reservations[0]?.start_date).format('MMM Do')
                      : `${moment(reservations[0]?.start_date).format('MMM Do')} - ${moment(
                          reservations[0]?.end_date
                        ).format('MMM Do')}`}
                  </span>
                </div>
              </div>
              <div className='ml-4 sm:ml-8 font-medium'>
                {
                  // TODO: add in once there is deleted_at on grouped reservations
                  // groupedReservation.deleted_at ? (
                  //   <StatusDeleted deleted_at={groupedReservation.deleted_at} />
                  // ) :
                  isPaid ? (
                    <StatusPaid />
                  ) : isBlockerGroup ? (
                    <StatusBlocked />
                  ) : (
                    <StatusUnpaid />
                  )
                }
              </div>
            </div>
            <div className='flex justify-between mt-5 text-sm text-gray-500'>
              <span className='mr-4 italic'>
                Created {moment(groupedReservation?.created_at).format('M/D/YYYY h:mma')}
              </span>
              <span className='italic'>
                Last edited {moment(groupedReservation?.updated_at).fromNow()}
              </span>
            </div>
          </div>
        </div>
        {!isBlockerGroup && (
          <>
            <div className='flex items-center justify-between pt-2 mb-4 sm:min-w-626'>
              <div className='flex flex-col'>
                <div className='font-semibold'>Payment info</div>
                <div className='text-md text-gray-500 -mt-0.5'>
                  Paying
                  {groupedReservation?.group_payment_type == GROUP_PAYMENT_TYPES.SEPARATE
                    ? ' separately'
                    : ' together'}
                </div>
              </div>
              {groupedReservation?.group_payment_type != GROUP_PAYMENT_TYPES.SEPARATE && (
                <div className='flex'>
                  <a
                    className={classNames(
                      'focus:outline-none transition ease-in-out duration-300 rounded-md cursor-pointer',
                      'px-4 py-2.5 mr-2',
                      'flex items-center text-sm bg-white hover:text-black hover:bg-gray-50 border border-gray-400 hover:border-gray-500',
                      groupedReservation?.stripe_payment_link_url && 'mr-2'
                    )}
                    onClick={handleAcceptPaymentForGroup}>
                    <CreditCardIcon className='w-5 h-5 text-gray-700 mr-2' />
                    <div>Accept payment</div>
                  </a>

                  {groupedReservation?.stripe_payment_link_admin_url && !isPaid && (
                    <a
                      className={classNames(
                        'focus:outline-none transition ease-in-out duration-300 rounded-md cursor-pointer',
                        'px-4 py-2.5',
                        'flex items-center text-sm bg-white hover:text-black hover:bg-gray-50 border border-gray-400 hover:border-gray-500',
                        'mr-2'
                      )}
                      href={groupedReservation?.stripe_payment_link_admin_url}
                      target='_blank'>
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 mr-2' />
                      <div className=''>Payment Link</div>
                    </a>
                  )}

                  {groupedReservation?.stripe_payment_link_url ? (
                    <a
                      className={classNames(
                        'focus:outline-none transition ease-in-out duration-300 rounded-md',
                        'px-4 py-2.5',
                        'flex items-center text-sm bg-white hover:text-black hover:bg-gray-50 border border-gray-400 hover:border-gray-500',
                        'mr-2'
                      )}
                      href={groupedReservation?.stripe_payment_link_url}
                      target='_blank'>
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 mr-2' />
                      <div className=''>Stripe Link</div>
                    </a>
                  ) : groupedReservation?.stripe_intent_id ? (
                    <a
                      className={classNames(
                        'focus:outline-none transition ease-in-out duration-300 rounded-md',
                        'px-4 py-2.5',
                        'flex items-center text-sm bg-white hover:text-black hover:bg-gray-50 border border-gray-400 hover:border-gray-500',
                        'mr-2'
                      )}
                      href={stripeIntentUrlFor(camp.stripe_account_id, groupedReservation.stripe_intent_id)}
                      target='_blank'>
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 mr-2' />
                      <div className=''>Stripe Link</div>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            {groupedReservation?.group_payment_type != GROUP_PAYMENT_TYPES.SEPARATE ? (
              <div className='flex pb-4 items-end'>
                <div className='w-1/2'>
                  <NumberInput
                    resource={formObject}
                    field='amountOwedByGroup'
                    label='Total owed'
                    register={register}
                    isChanged={defaultValues.amountOwedByGroup != amountOwedByGroup}
                    prefix='$'
                    suffix={camp.currency.toUpperCase()}
                  />
                </div>
                <div className='ml-4 w-1/2'>
                  <NumberInput
                    resource={formObject}
                    field='amountPaidByGroup'
                    label='Total paid'
                    register={register}
                    isChanged={defaultValues.amountPaidByGroup != amountPaidByGroup}
                    prefix='$'
                    suffix={camp.currency.toUpperCase()}
                    suffixButtonCta='Paid in full'
                    onSuffixButtonClick={() => setValue('amountPaidByGroup', amountOwedByGroup)}
                  />
                </div>
              </div>
            ) : <div className='w-full' />}
          </>
        )}
      </div>

      {groupedReservation?.group_payment_type == GROUP_PAYMENT_TYPES.SEPARATE && (
        <GroupedReservationLinkField
          campSlug={camp.slug}
          groupedReservationId={groupedReservationId}
        />
      )}
      <>
        <div className='font-semibold mb-2 pt-2'>Reservations in the group</div>
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-4 lg:px-8'>
              <div className='overflow-hidden border border-gray-200 sm:rounded-lg'>
                <table className='min-w-full w-96 divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    {isBlockerGroup ? (
                      <tr>
                        <th
                          scope='col'
                          className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Site
                        </th>
                      </tr>
                    ) : (
                      <tr>
                        <th
                          scope='col'
                          className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Camper
                        </th>
                        <th
                          scope='col'
                          className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Site
                        </th>
                        <th
                          scope='col'
                          className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Owed
                        </th>
                        <th
                          scope='col'
                          className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Paid
                        </th>
                        <th
                          scope='col'
                          className='pl-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                          Status
                        </th>
                        {/* // TODO: in followup, open slideover with selectedReservation. Need setSelectedReservation in the AdminContext to do this
                          <th scope='col' className='w-11'></th> */}
                      </tr>
                    )}
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {reservations
                      .sort((a, b) => a.site.sort - b.site.sort)
                      .map((reservation) => {
                        if (!reservation) return <></>
                        if (reservation.status === 'blocked') {
                          return (
                            <tr key={reservation.id}>
                              <td className='px-4 py-3 whitespace-nowrap text-sm text-gray-500'>
                                <span>
                                  {sites?.find((s) => s.id === reservation.site_id)?.name}
                                </span>
                              </td>
                            </tr>
                          )
                        } else {
                          return (
                            <tr key={reservation.user?.email}>
                              <td className='pl-5 pr-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                                <div className='flex flex-col'>
                                  <div className='font-medium text-gray-900'>
                                    <span>{formatName(reservation?.user)}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='px-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                                <span>
                                  {sites?.find((s) => s.id === reservation.site_id)?.name}
                                </span>
                              </td>
                              <td className='px-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                                <span>{formatMoney(reservation.total_owed_in_cents / 100)}</span>
                              </td>
                              <td className='px-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                                <span>{formatMoney(reservation.total_paid_in_cents / 100)}</span>
                              </td>
                              <td className='px-4 py-4 w-28 text-sm text-gray-500'>
                                <span>
                                  {reservation.deleted_at ? (
                                    // TODO: include deleted reservations in the reservationsState so this can be used
                                    <StatusDeleted />
                                  ) : reservation.total_paid_in_cents >=
                                    reservation.total_owed_in_cents ? (
                                    <StatusPaid />
                                  ) : reservation.total_paid_in_cents > 0 ? (
                                    <StatusUnpaid />
                                  ) : (
                                    <StatusUnpaid />
                                  )}
                                </span>
                              </td>
                              {/* // TODO: in followup, open slideover with selectedReservation. Need setSelectedReservation in the AdminContext to do this
                              <td className='w-11 whitespace-nowrap text-sm text-gray-500'>
                                <ArrowRightIcon
                                  className='w-8 h-8 p-1 -my-0.5 rounded-md hover:bg-gray-100'
                                  onClick={() => {
                                    // TODO: open slideover with selectedReservation. Need setSelectedReservation in the AdminContext to do this
                                  }}
                                />
                              </td> */}
                            </tr>
                          )
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className='flex flex-shrink-0 justify-between items-center mt-8 mb-2'>
        <div className='flex items-center'>
          <Button
            variant='red'
            type='button'
            disabled={isLoading}
            onClick={handleRemoveClick}
            style={{ animationDuration: '1s !important' }}
            className='mr-auto'>
            {!isRemoveButtonClicked ? 'Remove' : 'Are you sure?'}
          </Button>
          {isRemoveButtonClicked && (
            <div className='hidden sm:block ml-3 text-red-500 text-sm transition-all duration-300 w-48'>
              This will remove all reservations in this group!
            </div>
          )}
        </div>
        <div className='flex items-center'>
          <Button
            type='button'
            variant='gray'
            className={classNames('mr-4 w-28', isLoading && 'pointer-events-none opacity-60')}
            onClick={handleCancelClick}
            disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='green'
            className={classNames('w-28 mr-2', isLoading && 'pointer-events-none opacity-60')}
            onClick={handleSaveClick}
            disabled={isLoading || !isDirty}>
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default GroupModalContents
