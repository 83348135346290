import React, { useContext, useState } from 'react'
import { SiteProps } from '../../../interfaces'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import api from '../../../api2'
import { AdminContext } from '../../../contexts/AdminContext'
import IVendorCalendar from '../../../interfaces/IVendorCalendar'
import { LinkIcon } from '@heroicons/react/24/outline'

import { ReactComponent as AirBnb } from "../../../../assets/images/vendor_icons/Airbnb.svg"
import { ReactComponent as Booking } from '../../../../assets/images/vendor_icons/booking.svg'
import { ReactComponent as Hipcamp } from '../../../../assets/images/vendor_icons/hipcamp.svg'
import { ReactComponent as Vrbo } from '../../../../assets/images/vendor_icons/vrbo.svg'
import InputFieldWithButton from '../Inputs/InputFieldWithButton'
import CopyPasteButton from '../../ui/CopyPasteButton'
import Button from '../../ui/Button'

const isValidCalendarSync = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.hostname.includes('.') && parsedUrl.pathname.includes('.ics')
  } catch (error) {
    return false
  }
}

const externalNameFor = (urlStr: string, isThrowingError = false) => {
  try {
    const url = new URL(urlStr)
    const hostnameArr = url.hostname.split('.')
    return hostnameArr[hostnameArr.length - 2]
  } catch (error) {
    if (isThrowingError) {
      throw error
    } else {
      return ''
    }
  }
}

const VendorCalendarModalContent = ({ site, vendorCalendar }: { site: SiteProps, vendorCalendar: IVendorCalendar }) => {
  const { showNotification, sites, setSites } = useContext(AdminContext)
  const { onClose } = useContext(ModalContext)
  const [isRemoveButtonClicked, setIsRemoveButtonClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [externalUrl, setExternalUrl] = useState(vendorCalendar?.calendar_url || '')
  const [isValidUrl, setIsValidUrl] = useState(isValidCalendarSync(externalUrl))

  const handleCreateSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const upsertedData = {
      id: null,
      kind: externalNameFor(externalUrl),
      calendar_url: externalUrl
    }


    api.Sites.update(site?.id, {
      vendor_calendars_attributes: site?.vendor_calendars?.filter((vc) => vc?.id !== vendorCalendar?.id)?.map((vc) => ({
        id: vc.id,
        kind: vc.kind,
        calendar_url: vc.calendar_url
      })).concat([upsertedData])
    })
      .then(({ site }) => {
        showNotification({
          type: 'success',
          title: 'Vendor Calendar Created!',
          description: 'The calendar sync was successfully created.',
        })
        setSites(sites.map((s) => s.id === site.id ? site : s))
        onClose()
        setIsLoading(false)
        // TODO: reload site data for slideover
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsLoading(false)
      })
  }

  const handleEditSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    api.Sites.update(site?.id, {
      vendor_calendars_attributes: site?.vendor_calendars?.filter((vc) => vc.id !== vendorCalendar.id)?.map((vc) => ({
        id: vc.id,
        kind: vc.kind,
        calendar_url: vc.calendar_url
      }))?.concat([{
        id: vendorCalendar.id,
        kind: externalNameFor(externalUrl),
        calendar_url: externalUrl
      }])
    })
      .then(({ site }) => {
        showNotification({
          type: 'success',
          title: 'Vendor Calendar Updated!',
          description: 'The calendar sync was successfully updated.',
        })
        setSites(sites.map((s) => s.id === site.id ? site : s))
        onClose()
        setIsLoading(false)
      })
  }

  const handleCancel = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClose()
  }

  const handleRemoveClick = (e) => {
    isRemoveButtonClicked ? handleArchive(e) : setIsRemoveButtonClicked(true)
  }

  const handleArchive = (e: React.FormEvent<HTMLButtonElement>) => {
    setIsRemoveButtonClicked(false)
    e.preventDefault()
    setIsLoading(true)

    api.Sites.update(site?.id, {
      vendor_calendars_attributes: site?.vendor_calendars?.filter((vc) => vc.id !== vendorCalendar.id)?.map((vc) => ({
        id: vc.id,
        kind: vc.kind,
        calendar_url: vc.calendar_url,
        _destroy: false
      }))?.concat([{
        id: vendorCalendar.id,
        kind: vendorCalendar.kind,
        calendar_url: vendorCalendar.calendar_url,
        _destroy: true
      }])
    })
      .then(({ site }) => {
        showNotification({
          type: 'success',
          title: 'Vendor Calendar Deleted!',
          description: 'The calendar sync was successfully deleted.',
        })
        setSites(sites.map((s) => s.id === site.id ? site : s))
        onClose()
        setIsLoading(false)
      })
  }

  const parkCalendarUrlFor = (site: SiteProps, externalUrl: string) => {
    if (!externalUrl) {
      return `https://parkwith.us/api/calendars/${site.id}.ics`
    }

    try {
      return `https://parkwith.us/api/calendars/${site.id}.ics?calendar=${externalNameFor(externalUrl, true)}`
    } catch (error) {
      return `https://parkwith.us/api/calendars/${site.id}.ics`
    }
  }

  return (
    <form
      className='flex flex-col justify-between'
      onSubmit={vendorCalendar ? handleEditSave : handleCreateSave}>
      <div className='flex flex-col gap-y-5'>
        <div className='font-semibold text-xl pb-4 mb-1 border-b border-gray-200'>
          Create Calendar Sync
        </div>
        <div className='text-baselg font-semibold -mb-2'>Step 1</div>
        <div className="text-gray-700 -mt-2">
          Paste in the external URL. It must be a valid .ics URL path.
        </div>
        <div className='flex items-center gap-x-4'>
          <ExternalCalendarUrlInput url={externalUrl} setUrl={(v) => {
            setIsValidUrl(isValidCalendarSync(v))
            setExternalUrl(v)
          }} />
        </div>
        <div className='text-baselg font-semibold -mb-2 mt-4'>Step 2</div>
        <div className="text-gray-700 -mt-2">
          Copy the Park URL and paste it into the vendor.
        </div>
        <div className='flex items-center gap-x-4'>
          <InputFieldWithButton
            disabled={!isValidUrl}
            label='Park Calendar URL'
            readOnly
            value={parkCalendarUrlFor(site, externalUrl)}
            className='flex-1'
            button={
              <CopyPasteButton
                text='URL'
                disabled={!isValidUrl}
                value={parkCalendarUrlFor(site, externalUrl)}
                buttonClassName="rounded-l-none w-48"
              />
            }
          />
        </div>
      </div>
      <div className='flex flex-col xs:flex-row xs:justify-between border-t border-gray-200 pt-5 mt-6'>
        <Button
          type='button'
          className={!vendorCalendar && 'invisible'}
          variant='red'
          children={!isRemoveButtonClicked ? 'Delete' : 'Are you sure?'}
          onClick={handleRemoveClick}
          disabled={isLoading}
        />

        <div className='flex gap-4 mt-3 xs:mt-0'>
          <Button
            variant='white'
            children={'Cancel'}
            className='flex-auto xs:flex-initial w-32'
            onClick={handleCancel}
            disabled={isLoading}
          />
          <Button
            disabled={!isValidUrl || isLoading}
            variant='green'
            children={'Save'}
            className='flex-auto xs:flex-initial w-32'
            type='submit'
          />
        </div>
      </div>
    </form>
  )
}

export const ExternalCalendarUrlInput = ({ url, setUrl, readOnly }: { url: string; setUrl: (v: string) => void; readOnly?: boolean }) => {
  const [error, setError] = useState<string>("")

  const getVendorImage = (url: string) => {
    if (url.toLocaleLowerCase().includes("airbnb")) {
      return <AirBnb />
    } else if (url.toLocaleLowerCase().includes("hipcamp")) {
      return <Hipcamp />
    } else if (url.toLocaleLowerCase().includes("booking")) {
      return <Booking />
    } else if (url.toLocaleLowerCase().includes("vrbo")) {
      return <Vrbo />
    } else {
      return (
        <div className='rounded-full border p-1 flex items-center justify-center'>
          <LinkIcon />
        </div>
      )
    }
  }

  return (
    <div className='w-full flex relative items-center gap-2'>
      <div className='absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400 w-6 h-6 z-10'>
        {getVendorImage(url)}
      </div>
      <input
        type={'text'}
        readOnly={readOnly}
        onChange={(e) => {
          const value = e.currentTarget.value

          setError("")

          setUrl(value)

          if (value !== 'ics') {
            setError('Incorrect file extension, it should be ".ics"')
          }
        }}
        value={url}
        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 pl-11'
      />
    </div>
  )
}

export default VendorCalendarModalContent
