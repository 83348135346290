import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { CampProps, ReservationProps } from '../../interfaces'
import { STATUSES, reservationStatus } from '../../constants'
import classNames from '../../utils/classNames'
import {
  ArrowPathRoundedSquareIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline'
import { CheckBadgeIcon as CheckBadgeIconSolid } from '@heroicons/react/24/solid'
import { CalendarApi } from '@fullcalendar/react'
import moment from 'moment'
import { Transition } from '@headlessui/react'
import {
  LockClosedIcon,
  UserGroupIcon,
  CreditCardIcon,
  DocumentTextIcon,
  LinkIcon,
} from '@heroicons/react/20/solid'
import HoverTip from '../ui/HoverTip'
import { ReservationContextMenuContext } from '../../contexts/admin/ReservationContextMenuContext'
import { ReactComponent as AirBnb } from '../../../assets/images/vendor_icons/Airbnb.svg'
import { ReactComponent as Booking } from '../../../assets/images/vendor_icons/booking.svg'
import { ReactComponent as Hipcamp } from '../../../assets/images/vendor_icons/hipcamp.svg'
import { ReactComponent as Vrbo } from '../../../assets/images/vendor_icons/vrbo.svg'
import ArrowLeftOnRectangleIconSolid from '../../../assets/images/park__icon_ux__check_out.svg'
import CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD from '../../constants/camp_slugs_with_company_name_field'

interface ReservationStateProps {
  persisted: ReservationProps
  updated?: ReservationProps
}

interface ComponentProps {
  camp: CampProps
  reservations: { [reservationId: string]: ReservationStateProps }
  eventInfo: any // TODO: this should be the FullCalendar event
  setSelected: (reservation: ReservationProps) => void
  isSelected: boolean
  calendarApi: CalendarApi
  isQuarterlyView: boolean
}

const EventButton = (props: ComponentProps) => {
  const { showContextMenu } = useContext(ReservationContextMenuContext)
  const containerRef = useRef<HTMLDivElement>()
  const textContentRef = useRef<HTMLDivElement>()
  const [containerWidth, setContainerWidth] = useState<number>()
  const [isSmall, setIsSmall] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)
  const event = props.eventInfo.event
  const reservationState: ReservationStateProps = props.reservations[event.id]
  const persistedReservation = reservationState?.persisted

  const [isChanged, setIsChanged] = useState(reservationState?.updated != null)

  const reservation: ReservationProps = reservationState?.updated || reservationState?.persisted

  useEffect(() => {
    setIsChanged(reservationState?.updated != null)
  }, [reservationState])

  // NOTE: optional in case of blocked reservation (no user/camper)
  const isUsingCompanyName = CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD.includes(props.camp.slug)
  const companyName = persistedReservation?.user?.camper?.company_name
  const { email, first_name, last_name } = persistedReservation?.user?.camper || persistedReservation.user || {}
  const eventTitle = isUsingCompanyName && companyName
    ? companyName
    : last_name && first_name
      ? `${last_name}, ${first_name}`
      : last_name || first_name || email
  const amountOfNights = moment(persistedReservation.end_date).diff(
    persistedReservation.start_date,
    'days'
  )

  const changeBlockedImporteStatus = () =>
    ({
      [props.eventInfo.event.id]: {
        persisted: {
          ...reservationState.persisted,
          status: 'blocked-import',
        },
        updated: reservationState?.updated
          ? {
              ...reservationState.updated,
              status: 'blocked-import',
            }
          : null,
      },
    } as { [reservationId: string]: ReservationStateProps })

  const buttonStylesFor = (reservation: ReservationProps): CSSProperties => {
    return {
      background: isChanged ? 'white' : eventColorFor(reservation, true),
      boxShadow: `0 0 0 1px ${eventColorFor(reservation, false)} inset`,
      color: isChanged ? 'black' : 'white',
      border: eventBorder(reservation.status),
      outlineOffset: props.isSelected ? '0px' : undefined,
    }
  }

  const importKindStyle = (kind?: string) => {
    switch (kind) {
      case 'airbnb':
        return {
          color: `#FF6A86`,
          blockedColor: `repeating-linear-gradient(45deg, #fff, #fff 5px, #FFB0B8 5px, #FFB0B8 10px)`,
          blockedBorder: '1px solid #FD4D5D',
          image: AirBnb,
        }
      case 'hipcamp':
        return {
          color: `#FD4D5D`,
          blockedColor: `repeating-linear-gradient(45deg, #fff, #fff 5px, #FFB0B8 5px, #FFB0B8 10px)`,
          blockedBorder: '1px solid #FD4D5D',
          image: Hipcamp,
        }
      case 'booking':
        return {
          color: `#00ADF4`,
          blockedColor: `repeating-linear-gradient(45deg, #fff, #fff 5px, #84CFEE 5px, #84CFEE 10px)`,
          blockedBorder: '1px solid #00ADF4',
          image: Booking,
        }
      case 'vrbo':
        return {
          color: `#457EC1`,
          blockedColor: `repeating-linear-gradient(45deg, #fff, #fff 5px, #76A2D5 5px, #76A2D5 10px)`,
          blockedBorder: '1px solid #457EC1',
          image: Vrbo,
        }
      default:
        return {
          color: `#FD4D5D`,
          blockedColor: `repeating-linear-gradient(45deg, #fff, #fff 5px, #FFB0B8 5px, #FFB0B8 10px)`,
          blockedBorder: '1px solid #FD4D5D',
          image: Hipcamp,
        }
    }
  }

  const eventBorder = (status: reservationStatus): string => {
    if (status === STATUSES.BLOCKED) {
      return '1px solid #CDCDCD'
    } else if (status === STATUSES.PAID) {
      return '1px solid #78AC6B'
    } else if (status === STATUSES.UNPAID) {
      return '1px solid #AAD09F'
    } else if (status === STATUSES.IMPORTED) {
      return '1px solid #fff'
    } else if (status === STATUSES.BLOCKED_IMPORT) {
      return importKindStyle(
        reservation.site?.vendor_calendars?.find(
          (calendar) => calendar.id === reservation.vendor_calendar_id
        )?.kind
      ).blockedBorder
    }
  }

  const eventColorFor = (reservation: ReservationProps, background?: boolean): string => {
    switch (reservation.status) {
      case STATUSES.IMPORTED:
        return importKindStyle(
          reservation.site?.vendor_calendars?.find(
            (calendar) => calendar.id === reservation.vendor_calendar_id
          )?.kind
        ).color
      case STATUSES.BLOCKED_IMPORT:
        return importKindStyle(
          reservation.site?.vendor_calendars?.find(
            (calendar) => calendar.id === reservation.vendor_calendar_id
          )?.kind
        ).blockedColor
      case STATUSES.BLOCKED:
        return `repeating-linear-gradient(45deg, #fff, #fff 5px, #CDCDCD 5px, #CDCDCD 10px)`
      case STATUSES.PAID:
        return '#81C87E'
      case STATUSES.UNPAID:
        const percentPaid =
          (reservation.total_paid_in_cents / reservation.total_owed_in_cents) * 100

        if (percentPaid > 0 && background)
          return `linear-gradient(to right,#81C87E 0%,#81C87E ${percentPaid}%,#E0F6DB ${percentPaid}%, #E0F6DB 100%)`
        else return '#E0F6DB'
      default:
        return 'grey'
    }
  }
  let PrimaryIcon = DocumentTextIcon

  if (persistedReservation.grouped_reservation_id) {
    PrimaryIcon = UserGroupIcon
  } else if (persistedReservation.status === 'blocked') {
    PrimaryIcon = NoSymbolIcon
  } else if (persistedReservation.status === 'blocked-import') {
    PrimaryIcon = importKindStyle(
      reservation.site?.vendor_calendars?.find(
        (calendar) => calendar.id === reservation.vendor_calendar_id
      )?.kind
    ).image
  } else if (persistedReservation.kind === 'recurring') {
    PrimaryIcon = ArrowPathRoundedSquareIcon
  } else if (persistedReservation.stripe_payment_link_admin_url) {
    PrimaryIcon = LinkIcon
  } else if (persistedReservation.vendor_id) {
    if (persistedReservation.is_manual_charge) {
      PrimaryIcon = CreditCardIcon
    } else {
      PrimaryIcon = GlobeAltIcon
    }
  }

  const isHourlyReservation = () =>
    moment(persistedReservation.end_date).diff(persistedReservation.start_date, 'hours') <= 12

  const isShortReservationInQuarterlyView = () => {
    if (!props.calendarApi?.view?.type) return false

    if (
      props.calendarApi.view.type === 'resourceTimelineQuarterly' &&
      (amountOfNights < 2 ||
        moment(persistedReservation.end_date).diff(
          moment(props.calendarApi?.view?.activeStart),
          'days'
        ) <= 1)
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (isShortReservationInQuarterlyView() || isHourlyReservation()) {
      setIsSmall(true)
    }

    if (
      props.calendarApi?.view?.currentStart &&
      moment(persistedReservation.end_date).diff(
        moment(props.calendarApi.view.currentStart),
        'days'
      ) < 1
    ) {
      setIsOverflow(true)
    }
  }, [props.calendarApi?.view?.type])

  useEffect(() => {
    if (!containerRef?.current) return

    setContainerWidth(containerRef.current.clientWidth)
  }, [containerRef])

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    showContextMenu(reservationState, e)
  }
  const [showEventImage, setShowEventImage] = useState(true)
  const calculateDifferenceInDaysForQuarterly = () => {
    const differenceInDays = moment(reservation?.end_date).diff(
      moment(props?.calendarApi?.view.activeStart),
      'days',
      true
    )
    if (differenceInDays <= 1 && props?.isQuarterlyView) {
      showEventImage && setShowEventImage(false)
      if (reservation?.status === 'blocked-import') return 'small-bottom-import'
      else return 'small-top-import'
    }

    return ''
  }

  return (
    <>
      <div
        style={buttonStylesFor(persistedReservation)}
        className={classNames(
          `${
            reservation.status === 'blocked-import' ? 'blocked-import' : 'event-button'
          } items-center hover:opacity-90 rounded-lg`,
          !persistedReservation.is_locked  && 'cursor-pointer',
          props.calendarApi?.view?.type === 'resourceTimelineQuarterly' &&
            moment(persistedReservation.end_date).dayOfYear() ==
              moment(props.calendarApi?.view?.activeStart).dayOfYear()
            ? 'absolute z-20'
            : 'flex',
          'transition-opacity duration-300',
          props.isSelected && ' outline-black',
          isSmall
            ? `justify-center${persistedReservation.status !== STATUSES.BLOCKED && ' px-1.125'}`
            : `justify-start${persistedReservation.status !== STATUSES.BLOCKED && ' px-1.5'}`,
          reservation.is_locked && 'border-1 border-gray-300',
          calculateDifferenceInDaysForQuarterly()
        )}
        onClick={() => props.setSelected(persistedReservation)}
        onContextMenu={handleContextMenu}>
        {persistedReservation.status !== STATUSES.BLOCKED && (
          <div className='flex-grow flex-shrink min-w-0' ref={containerRef}>
            <div className='sticky left-6 right-6 flex items-center'>
              <Transition
                show={persistedReservation.is_checked_in && !persistedReservation.is_checked_out}
                enter='transition-all duration-200'
                enterFrom='scale-0'
                enterTo='scale-100'
                leave='transition-all duration-200 '
                leaveFrom='scale-100'
                leaveTo='scale-0'
                className={classNames('absolute -top-3 rounded-full bg-white z-50')}
                style={{
                  left: isSmall ? '-11px' : '-15px',
                }}>
                <CheckBadgeIconSolid className='w-5.5 h-5.5' style={{ color: '#3A8860' }} />
              </Transition>
              <Transition
                show={persistedReservation.is_checked_out}
                enter='transition-all duration-200'
                enterFrom='scale-0'
                enterTo='scale-100'
                leave='transition-all duration-200 '
                leaveFrom='scale-100'
                leaveTo='scale-0'
                className={classNames('absolute -top-3 rounded-full bg-white')}
                style={{
                  left: isSmall ? '-11px' : '-15px',
                }}>
                <img src={ArrowLeftOnRectangleIconSolid} className='w-5.5 h-5.5' />
              </Transition>
              <Transition
                show={persistedReservation.is_locked}
                enter='transition-all duration-200'
                enterFrom='scale-0'
                enterTo='scale-100'
                leave='transition-all duration-200 '
                leaveFrom='scale-100'
                leaveTo='scale-0'
                className={classNames('absolute top-3.5 rounded-full bg-white')}
                style={{
                  left: isSmall ? '-11px' : '-15px',
                }}>
                <HoverTip xs content='Reservation locked' active={reservation.is_locked}>
                  <div className='rounded-full bg-white w-5.5 h-5.5 flex items-center justify-center'>
                    <div className='rounded-full bg-gray-500 text-white w-4.5 h-4.5 flex items-center justify-center'>
                      <LockClosedIcon className='w-2.5 h-2.5' />
                    </div>
                  </div>
                </HoverTip>
              </Transition>
              {PrimaryIcon && showEventImage && (
                <PrimaryIcon
                  style={reservation.status && { width: 17, height: 17 }}
                  className={classNames(
                    'w-4.5 h-4.5 flex-shrink-0 text-black',
                    !isSmall && 'mr-1.5'
                  )}
                />
              )}
              {!isSmall && !isOverflow && (
                <>
                  {(persistedReservation.invoice?.send_date || persistedReservation.is_invoice_sent) && (
                      <EnvelopeIcon className='w-4 h-4 flex-shrink-0 mr-1.5 text-black' />
                    )}
                  <div
                    ref={textContentRef}
                    className='truncate text-gray-800 text-baselg font-medium'
                    style={{
                      width:
                        containerWidth &&
                        textContentRef.current &&
                        containerWidth < textContentRef.current.clientWidth + 18
                          ? containerWidth - 18 + 'px'
                          : 'auto',
                      lineHeight: '19px',
                    }}>
                    {eventTitle}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {reservation.status === 'imported' && (
        <EventButton
          camp={props.camp}
          reservations={changeBlockedImporteStatus()}
          eventInfo={props.eventInfo}
          isSelected={props.isSelected}
          setSelected={props.setSelected}
          calendarApi={props.calendarApi}
          isQuarterlyView={props.isQuarterlyView}
        />
      )}
    </>
  )
}

export default EventButton
