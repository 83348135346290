import React from 'react'

const SummaryStat = ({ label, value }) => {
  return (
    <div>
      <dl className='max-w-xs mb-2 sm:mb-3'>
        <div className='overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border border-gray-200 shadow-pk-card'>
          <dt className='truncate text-sm font-medium text-gray-500'>{label}</dt>
          <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>{value}</dd>
        </div>
      </dl>
    </div>
  )
}

export default SummaryStat
