import React, { useState } from 'react'
import Spinner from '../../ui/Spinner'
import { formatMoney, formatMoneyFromCents } from '../../../utils/formatMoney'
import SummaryStat from './SummaryStat'

type iSiteReport = {
  site_name: string
  booked: number
  avg_rate: number
  room_rate: number
  revenue: number
  unbooked: number
  occupancy: string
}

type iSiteSummaryReport = {
  avg_camp_occupancy: string;
  avg_site_revenue: number;
}

interface ComponentProps {
  data: iSiteReport[]
  summary: iSiteSummaryReport
}

const SitesTable = (props: ComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className='relative'>
      <div className='sm:flex sm:items-center'>
        {isLoading && (
          <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-start pt-72 justify-center'>
            <Spinner size={12} />
          </div>
        )}
        <div className='sm:flex-auto'></div>
      </div>
      <div className='flow-root'>
        <div className='overflow-y-visible sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='flex gap-5'>
              <SummaryStat
                label='Average site occupancy'
                value={props.summary && props.summary.avg_camp_occupancy}
              />
              <SummaryStat
                label='Average revenue per site'
                value={props.summary && formatMoney(props.summary.avg_site_revenue)}
              />
            </div>

            <div className='relative'>
              <table className='min-w-full table-auto divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 w-1/12 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Site Name
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Booked <span className='hidden md:inline'>nights</span>
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Unbooked <span className='hidden md:inline'>nights</span>
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Occupancy
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Avg. <span className='hidden md:inline'>nightly</span> rate
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Room Rate <span className='hidden md:inline'>Revenue</span>
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      <span className='hidden md:inline'>Total</span> Revenue
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {props.data &&
                    props.data.map((siteData, index) => {
                      const { site_name, booked, avg_rate, room_rate, revenue, unbooked, occupancy } = siteData

                      return (
                        <tr className='h-10'>
                          <td className='font-medium flex-shrink'>{site_name}</td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>{booked}</td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>{unbooked}</td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>
                            {occupancy}
                          </td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>
                            {formatMoney(avg_rate)}
                          </td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>
                            {formatMoney(room_rate)}
                          </td>
                          <td className='text-gray-500 text-right whitespace-nowrap'>
                            {formatMoney(revenue)}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SitesTable
