import React, { useContext, useEffect, useState } from 'react'
import ICamper from '../../../interfaces/ICamper'
import ProfileCard from './Camper/ProfileCard'
import Button from '../../ui/Button'
import { Tab } from '@headlessui/react'
import History from './Camper/History'
import Info from './Camper/Info'
import classNames from '../../../utils/classNames'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import api2 from '../../../api2'
import useAdminForm from '../../../hooks/useAdminForm'
import Spinner from '../../ui/Spinner'
import moment from 'moment'
import { formatMoneyFromCents } from '../../../utils/formatMoney'
import { AdminContext } from '../../../contexts/AdminContext'
import { NoSymbolIcon, EnvelopeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import CamperDocumentUpload from './CamperDocumentUpload'
import { formatName } from '../../../utils/formatName'
import api from '../../../api2'

interface CamperProps {
  camper: ICamper
  setSelectedCamper: (camper: any) => void
  reloadCampers: () => void
}

const Camper = ({ camper, setSelectedCamper, reloadCampers }: CamperProps) => {
  const { resource, handleSubmit, resetDefaults, isDirty } = useAdminForm()
  const { showMessageModal, showNotification, showConfirm, currentUserRole, camp, setCurrentPage} = useContext(AdminContext)

  const [isFetchingReservations, setIsFetchingReservations] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isBlocked, setIsBlocked] = useState<boolean>(!!camper.blocked_at)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (camper && !camper.reservations) {
      fetchReservationsForCamper()
    }
  }, [camper?.id])

  const fetchReservationsForCamper = async () => {
    if(camper?.id){
      setIsFetchingReservations(true)
  
      api2.Camps.findCamper(camper.id)
        .then(({ camper }) => {
          setSelectedCamper(camper)
          setIsFetchingReservations(false)
        })
        .catch((err) => {
          console.error(err)
          setIsFetchingReservations(false)
        })
    }
  }

  const onUpdateCamper = (data: ICamper) => {
    setIsLoading(true)

    api2.Camps.updateCamper(camper.id, data)
      .then(({ camper }) => {
        setSelectedCamper(camper)
        setIsLoading(false)
        setErrorMessage('')
      })
      .catch((err) => {
        setErrorMessage(
          (err.errors.email && 'Email is already in use') || (err.errors.phone && 'Phone is already in use') || err.message || ''
        )
        console.error(err)
        setIsLoading(false)
      })
  }

  const handleCancelClick = () => {
    resetDefaults()
  }

  const handleBackClick = () =>
    setSelectedCamper(null)

  const handleBlockClick = () => {
    const blockedAt = isBlocked ? null : moment().toISOString()
    api2.Camps.updateCamper(camper.id, { ...camper, blocked_at: blockedAt })
      .then(({ camper }) => {
        setSelectedCamper(camper)
        setIsBlocked(!isBlocked)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleMessageClick = () => {
    showMessageModal({ emails: [camper.email], phoneNumbers: [camper.phone]})
  }

  const handleDeleteClick = async () => {
    if (await showConfirm(`Are you sure you want to delete ${formatName(camper)}?`)) {
      api2.Campers.remove(camper.id)
        .then(() => {
          showNotification({
            type: "success",
            title: `Camper ${formatName(camper)} has been deleted`
          })
          reloadCampers()
          setSelectedCamper(null)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const tabbedContent = {
    'Guest info': <Info />,
    History: <History />,
  }

  return (
    <div className='w-full'>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div className='flex justify-between'>
          <Button
            variant='white'
            className='border-none'
            icon={<ArrowLeftIcon className='w-5 h-5' />}
            onClick={handleBackClick}>
            Back
          </Button>
          {currentUserRole != 'view-only' && (
            <div className='space-x-2 flex'>
              <div className='hidden sm:flex items-center gap-2 mr-3 border-gray-200'>
              {errorMessage && <div className='text-red-500'>{errorMessage}</div>}
                <Button
                  onClick={handleSubmit(onUpdateCamper)}
                  type='submit'
                  variant='green'
                  className={classNames('w-28', isLoading && 'pointer-events-none opacity-60')}
                  disabled={isLoading || !isDirty}>
                  {isLoading ? <Spinner size={4} /> : 'Save'}
                </Button>
                <Button
                  type='button'
                  variant='gray'
                  className={classNames('w-28', isLoading && 'pointer-events-none opacity-60')}
                  onClick={handleCancelClick}
                  disabled={isLoading || !isDirty}>
                  Cancel
                </Button>
              </div>
              {camper?.id && (
                <div className='flex items-center ml-3'>
                  <Button
                    className='mr-2'
                    variant={isBlocked ? 'red' : 'white'}
                    icon={<NoSymbolIcon className='w-5 h-5' />}
                    onClick={handleBlockClick}
                  >
                    {isBlocked ? 'Unblock' : 'Block'}
                  </Button>
                  <Button variant='white' icon={<EnvelopeIcon className='w-5 h-5' />} onClick={handleMessageClick}>
                    Message
                  </Button>
                  <div className='hidden sm:block'>
                    <Button variant='redOutline' icon={<XMarkIcon className='w-5 h-5' />} onClick={handleDeleteClick} className='ml-2'>
                      Delete
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='mb-6 lg:grid lg:grid-cols-11 lg:gap-12 mt-4'>
          <div className='lg:col-span-4' />
          <div className='lg:col-span-7 flex justify-between'>
            <div className='border-b border-gray-200 w-full'>
              <Tab.List className='flex space-x-6 max-w-sm'>
                {Object.keys(tabbedContent).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        'whitespace-nowrap border-b-2 py-2 text-baselg focus:outline-none',
                        selected
                          ? 'border-gray-900 text-gray-900 font-semibold'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
        </div>
        <div className='lg:grid lg:grid-cols-11 lg:gap-12'>
          <div className='col-span-4 flex flex-col lg:-mt-12 mb-6 lg:mb-0'>
            <ProfileCard
              camper={camper}
              setSelectedIndex={setSelectedIndex}
              isBlocked={isBlocked}
            />
            <div className='mt-4 flex gap-4 flex-wrap'>
              <div className='flex flex-col border-gray-200 border rounded-md p-6 justify-center items-center flex-grow'>
                <div className='font-bold text-1.5xl text-gray-900 text-center'>
                  {camper.last_stay_date ? moment(camper.last_stay_date).format('M/D/YYYY') : 'N/A'}
                </div>
                <div className='font-normal text-base text-gray-500'>last stayed</div>
              </div>
              <div className='flex flex-col border-gray-200 border rounded-md p-6 justify-center items-center flex-grow'>
                <div className='font-bold text-1.5xl text-gray-900'>
                  {camper.reservations?.length}
                </div>
                <div className='font-normal text-base text-gray-500'>bookings</div>
              </div>
              <div className='flex flex-col border-gray-200 border rounded-md p-6 justify-center items-center flex-grow'>
                <div className='font-bold text-1.5xl text-gray-900'>
                  {camper.reservations && formatMoneyFromCents(
                    camper.reservations
                      ?.filter((r) => r.total_paid_in_cents > 0)
                      .reduce((sum, reservation) => {
                        return sum + reservation.total_paid_in_cents
                      }, 0)
                  )}
                </div>
                <div className='font-normal text-base text-gray-500'>total spent</div>
              </div>
            </div>
            <div className='mt-6'>
              <CamperDocumentUpload camper={camper} />
            </div>
          </div>
          <div className='lg:col-span-7'>
            <Tab.Panels>
              {Object.values(tabbedContent).map((content, index) => (
                <Tab.Panel key={index} className={'focus:outline-none'}>
                  <>
                    {content}
                    {index === 1 && (
                      <div className='sm:hidden flex items-center gap-2 mr-3 border-gray-200 mb-32'>
                        <Button
                          onClick={handleSubmit(onUpdateCamper)}
                          type='submit'
                          variant='green'
                          className={classNames(
                            'w-28',
                            isLoading && 'pointer-events-none opacity-60'
                          )}
                          disabled={isLoading || !isDirty}>
                          {isLoading ? <Spinner size={4} /> : 'Save'}
                        </Button>
                        <Button
                          type='button'
                          variant='gray'
                          className={classNames(
                            'w-28',
                            isLoading && 'pointer-events-none opacity-60'
                          )}
                          onClick={handleCancelClick}
                          disabled={isLoading || !isDirty}>
                          Cancel
                        </Button>
                      </div>
                    )}
                  </>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </div>
      </Tab.Group>
    </div>
  )
}

export default Camper
