import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../../../../contexts/FormContext'
import classNames from '../../../../utils/classNames'
import InputFieldWithLabel from '../../../Admin/Inputs/InputFieldWithLabel'

const TextInput = ({
  field,
  label,
  subheader,
  inputType = 'text',
  className,
  disabled,
  placeholder,
  asText,
  formatValue,
  hidden,
}: {
  field: string
  label?: string
  subheader?: string
  inputType?: string
  className?: string
  disabled?: boolean
  placeholder?: string
  asText?: boolean
  formatValue?: (value: any) => string
  hidden?: boolean
}) => {
  const { resource, register, isChanged, setValue, watch, errors, clearError } =
    useContext(FormContext)
  const { onChange: registeredOnChange, ...registered } = register(field)
  const [bgColorClass, setBgColorClass] = useState('bg-white')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors[field]) clearError(field)
    const inputValue = e.target.value
    const formattedValue = formatValue ? formatValue(inputValue) : inputValue
    setValue(field, formattedValue)
    registeredOnChange(e)
  }

  if (asText) {
    return <div className={classNames(className, 'flex items-center')}>{watch(field)}</div>
  }

  return (
    <div className={classNames('mb-2', className, hidden && 'hidden')}>
      <div className={classNames(className, disabled && 'opacity-30')}>
        {label && (
          <div className='mb-1'>
            <label
              htmlFor='name'
              className={classNames(
                'block font-medium text-md leading-4',
                errors[field] ? 'text-red-600' : 'text-gray-900'
              )}>
              {label}

              {errors[field] && (
                <span className='ml-1 text-red-500 text-xs'>{errors[field].message}</span>
              )}
            </label>
            {subheader && <span className='text-gray-500 font-normal text-sm'>{subheader}</span>}
          </div>
        )}
        <div>
          <input
            {...registered}
            hidden={hidden}
            onChange={handleChange}
            type={inputType || 'text'}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames(
              'block w-full rounded-md shadow-sm focus:border-green-700 focus:ring-green-700',
              bgColorClass,
              errors[field] ? 'border-red-600' : 'border-gray-300',
              isChanged(field) ? 'bg-blue-50' : 'bg-white'
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default TextInput
