const deformatPhoneNumber = (formattedPhone: string): string => {
  return ('' + formattedPhone).replace(/\D/g, '')
}

const formatPhoneNumber = (phoneNumberString: string): string => {
  // Strip any non-digits and assume the string to be properly formatted digits only
  let input = deformatPhoneNumber(phoneNumberString)
  const size = input.length

  if (input.startsWith('00') && size > 10) {
    input = input.substring(2)
  }

  // Identify and format with a possible country code
  if (size > 10) {
    // More than a standard 10-digit number, assuming there is a country code
    const countryCode = input.substring(0, input.length - 10) // Country code is the excess part
    const mainNumber = input.substring(input.length - 10) // Last 10 digits are the main number
    return `+${countryCode} (${mainNumber.substring(0, 3)}) ${mainNumber.substring(
      3,
      6
    )} - ${mainNumber.substring(6, 10)}`
  } else if (size === 10) {
    // Standard 10-digit number
    return `(${input.substring(0, 3)}) ${input.substring(3, 6)} - ${input.substring(6, 10)}`
  } else if (size > 6) {
    // Partially entered standard number
    return `(${input.substring(0, 3)}) ${input.substring(3, 6)} - ${input.substring(6, size)}`
  } else if (size > 3) {
    // Partially entered standard number
    return `(${input.substring(0, 3)}) ${input.substring(3, size)}`
  } else if (size > 0) {
    // Very partially entered number
    return `(${input}`
  } else {
    // Empty or non-numeric input that resulted in an empty string
    return ''
  }
}

export { formatPhoneNumber, deformatPhoneNumber }
