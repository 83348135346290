import { ReactComponent as Rv } from '../../assets/images/park__icon_ux_no_fill__rv.svg'
import { ReactComponent as Tent } from '../../assets/images/park__icon_ux_no_fill__tent.svg'
import { ReactComponent as Cabin } from '../../assets/images/park__icon_ux_no_fill__cabin.svg'
import { ReactComponent as BoatSlip } from '../../assets/images/park__icon_ux_no_fill__docks.svg'
import { ReactComponent as BoatRental } from '../../assets/images/park__icon_ux_no_fill__boat.svg'
import { ReactComponent as MobileHome } from '../../assets/images/park__icon_ux_no_fill__mobile_home.svg'
import { ReactComponent as Storage } from '../../assets/images/park__icon_ux_no_fill__storage.svg'
import { ReactComponent as Pavilion } from '../../assets/images/park__icon_ux_no_fill__pavilion.svg'
import { ReactComponent as Cabana } from '../../assets/images/park__icon_ux_no_fill__cabana.svg'
import { ReactComponent as Garage } from '../../assets/images/park__icon_ux_no_fill__garage.svg'
import { ReactComponent as Morning } from '../../assets/images/park__icon_ux__morning.svg'
import { ReactComponent as Afternoon } from '../../assets/images/park__icon_ux__afternoon.svg'
import { ReactComponent as AllDay } from '../../assets/images/park__icon_ux__all_day.svg'
import { ReactComponent as HorsePen } from '../../assets/images/park__icon_ux_no_fill__horse_pen.svg'
import { ReactComponent as HorseStall } from '../../assets/images/park__icon_ux_no_fill__horse_pen.svg'
import { ReactComponent as RvStorage } from '../../assets/images/park__icon_ux_no_fill__rv_storage.svg'
import { ReactComponent as EventCenter } from '../../assets/images/park__icon_ux_no_fill__event_center.svg'
import { ReactComponent as RentalRv } from '../../assets/images/park__icon_ux_no_fill__rental_rv.svg'
import { ReactComponent as SingleReservation } from '../../assets/images/park__icon_ux__single_reservation.svg'
import { ReactComponent as RecurringReservation } from '../../assets/images/park__icon_ux__recurring_reservation.svg'
import { ReactComponent as GroupReservation } from '../../assets/images/park__icon_ux__group_reservation.svg'
import { ReactComponent as BlockedReservation } from '../../assets/images/park__icon_ux__blocked_reservation.svg'
import { ReactComponent as Lodge } from '../../assets/images/park__icon_ux_no_fill__lodge.svg'
import { ReactComponent as GolfCart } from '../../assets/images/park__icon_ux_no_fill__golf_cart.svg'
import { ReactComponent as Pool } from '../../assets/images/park__icon_ux_no_fill__pool.svg'
import { ReactComponent as VendorBooth } from '../../assets/images/park__icon_ux_no_fill__vendor_booth.svg'
import { ReactComponent as DiscGolf } from '../../assets/images/park__icon_ux_no_fill__disc_golf.svg'
import { ReactComponent as DryCamping } from '../../assets/images/park__icon_ux_no_fill__cs__dry_camping.svg'
import { ReactComponent as Dome } from '../../assets/images/park__icon_ux_no_fill__cs__dome.svg'
import { ReactComponent as Glamping } from '../../assets/images/park__icon_ux_no_fill__cs__glamping.svg'
import { ReactComponent as Home } from '../../assets/images/park__icon_ux_no_fill__cs__home.svg'
import { ReactComponent as Lodging } from '../../assets/images/park__icon_ux_no_fill__cs__home.svg'
import { ReactComponent as Bonfire } from '../../assets/images/park__icon_ux_no_fill__cs__dry_camping.svg'
import { ReactComponent as Truck } from '../../assets/images/park__icon_ux_no_fill__pickup_truck.svg'
import { ReactComponent as Group } from '../../assets/images/park__icon_ux_no_fill__group.svg'
import { ReactComponent as FoodTruck } from '../../assets/images/park__icon_ux_no_fill__food_truck.svg'
import { ReactComponent as Misc } from '../../assets/images/park__icon_ux_no_fill__cube.svg'
import { ReactComponent as EnsuiteUnit } from '../../assets/images/park__icon_ux_no_fill__lodge.svg'
import { ReactComponent as UnpoweredSite } from '../../assets/images/park__icon_ux_no_fill__rental_rv.svg'
import { ReactComponent as Cottage } from '../../assets/images/park__icon_ux_no_fill__cs__cottage.svg'
import { ReactComponent as MainHouse } from '../../assets/images/park__icon_ux_no_fill__cs__main_house.svg'
import { ReactComponent as Tipi } from '../../assets/images/park__icon_ux_no_fill__cs__tipi.svg'
import { ReactComponent as Kayak } from '../../assets/images/park__icon_ux_no_fill__cs__kayak.svg'
import { ReactComponent as Van } from '../../assets/images/park__icon_ux_no_fill__cs__van.svg'
import { ReactComponent as Room } from '../../assets/images/park__icon_ux_no_fill__cs__room.svg'
import { ReactComponent as Generator } from '../../assets/images/park__icon_ux_no_fill__cs__generator.svg'
import { ReactComponent as RentalItem } from '../../assets/images/park__icon_ux_no_fill__cs__rental_item.svg'
import { ReactComponent as PicnicArea } from '../../assets/images/park__icon_ux_no_fill__picnic_table.svg'
import { ReactComponent as Kitchen } from '../../assets/images/park__icon_ux_no_fill__cs__kitchen.svg'
import { ReactComponent as Platform } from '../../assets/images/park__icon_ux_no_fill__cs__platform.svg'
import { ReactComponent as CanvasTent } from '../../assets/images/park__icon_ux_no_fill__cs__canvas_tent.svg'

const icons = {
  Rv,
  Tent,
  Cabin,
  BoatRental,
  BoatSlip,
  MobileHome,
  Storage,
  Pavilion,
  Cabana,
  Garage,
  Morning,
  Afternoon,
  AllDay,
  HorsePen,
  HorseStall,
  RvStorage,
  EventCenter,
  RentalRv,
  SingleReservation,
  RecurringReservation,
  GroupReservation,
  BlockedReservation,
  Lodge,
  GolfCart,
  Pool,
  VendorBooth,
  DiscGolf,
  DryCamping,
  Dome,
  Glamping,
  Home,
  Lodging,
  Bonfire,
  Truck,
  Group,
  FoodTruck,
  Misc,
  EnsuiteUnit,
  UnpoweredSite,
  Cottage,
  MainHouse,
  Tipi,
  Kayak,
  Van,
  Room,
  Generator,
  RentalItem,
  PicnicArea,
  Kitchen,
  Platform,
  CanvasTent,
}

export default icons
