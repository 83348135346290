import React, { useEffect, useState } from 'react'
import InputFieldWithLabel from '../../Inputs/InputFieldWithLabel'
import { SiteProps } from '../../../../interfaces'
import CopyPasteButton from '../../../ui/CopyPasteButton'
import { LinkIcon, TrashIcon } from '@heroicons/react/24/solid'
import { ReactComponent as AirBnb } from '../../../../../assets/images/vendor_icons/Airbnb.svg'
import { ReactComponent as Booking } from '../../../../../assets/images/vendor_icons/booking.svg'
import { ReactComponent as Hipcamp } from '../../../../../assets/images/vendor_icons/hipcamp.svg'
import { ReactComponent as Vrbo } from '../../../../../assets/images/vendor_icons/vrbo.svg'
import * as uuid from 'uuid'
import { DateTime } from 'luxon'

const vendorOptions = [
  { value: null, label: 'None' },
  { value: 'airbnb', label: 'Airbnb' },
  { value: 'hipcamp', label: 'Hipcamp' },
  { value: 'booking', label: 'Booking' },
  { value: 'vrbo', label: 'VRBO' },
]

interface ComponentProps {
  site: SiteProps
  isLoading: boolean
  setErrorMessage: (message: string) => void
  setIsSiteSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const extractDataFromUrl = (urlString: string) => {
  try {
    const url = new URL(urlString)
    const pathname = url.pathname
    const hostnameArr = url.hostname.split('.')

    const match = pathname.match(/\.([a-zA-Z0-9]+)$/)
    return { extension: match ? match[1] : null, hostnameArr }
  } catch (e) {
    console.error(e)
    return null
  }
}

interface IVendorLink{
  id: string
  value: string
  kind: string
  isNewLink: boolean
  _destroy?: boolean
}

const CalendarSyncInputs = (props: ComponentProps) => {
  const vendorCalendars = props.site?.vendor_calendars
  const defaultOtherLinks = vendorCalendars.map((calendar) => ({
    id: calendar.id,
    value: calendar.calendar_url,
    kind: calendar.kind,
    isNewLink: false
  }))

  const persistedOption = vendorCalendars && vendorCalendars.length > 0
    ? vendorOptions.find((v) => v.value === vendorCalendars[0]?.kind)
    : vendorOptions[0];
  const [vendor, setVendor] = useState<any>(persistedOption || vendorOptions[0])
  const [vendorCalendarUrl, setvendorCalendarUrl] = useState(props.site?.vendor_calendars.find((v) => v?.kind === vendor.value)?.calendar_url || '')
  const [vendorCalendarId, setvendorCalendarId] = useState(props.site?.vendor_calendars.find((v) => v?.kind === vendor.value)?.id || '')
  const [vendorCalendarIds, setvendorCalendarIds] = useState(props.site?.vendor_calendars?.map((vendor) => vendor?.id) || [])
  const baseParkvendorCalendarUrl = `https://parkwith.us/api/calendars/${props.site?.id}.ics`
  const [vendorSyncLinks, setOtherLinks] = useState<IVendorLink[]>(defaultOtherLinks)
  const [errors, setErrors] = useState<Record<string, string>>({})

  const getVendorImage = (image: string) => {
    switch (image?.toLocaleLowerCase()) {
      case 'airbnb':
        return <AirBnb />
      case 'hipcamp':
        return <Hipcamp />
      case 'booking':
        return <Booking />
      case 'vrbo':
        return <Vrbo />
      default:
        return (
          <div className='rounded-full border p-1 flex items-center justify-center'>
            <LinkIcon />
          </div>
        )
    }
  }

  const setFieldError = (field: string, error: string) => {
    setErrors({...errors, [field]: error})
  }

  const clearFieldError = (field: string) => {
    setErrors({...errors, [field]: ''})
  }

  useEffect(() => {
    setvendorCalendarUrl(props.site?.vendor_calendars.find((v) => v?.kind === vendor.value)?.calendar_url || '')
    setvendorCalendarId(props.site?.vendor_calendars.find((v) => v?.kind === vendor.value)?.id || ''),
    setvendorCalendarIds(props.site?.vendor_calendars?.map((vendor) => vendor?.id) || [])
  }, [vendor])

  useEffect(() => {
    const errorValues = Object.values(errors)
    if (errorValues.filter((error) => error?.length).length) {
      props.setIsSiteSaveDisabled(true)
    } else {
      props.setIsSiteSaveDisabled(false)
    }
  }, [errors])

  return (
    <div className=''>
      <label className='block font-semibold text-gray-900 pt-4 mb-1'>Calendar sync</label>
      <div className='text-sm text-gray-500 mb-4'>
        Sync your calendar with another booking platform to avoid double bookings.
      </div>
      <div className='mt-1'>
      {(() => {
          return (
            <>
              <div className='mt-4 relative flex'>
                <InputFieldWithLabel
                  name='park_calendar_url'
                  label={`Park Calendar URL`}
                  value={baseParkvendorCalendarUrl}
                  className='flex-1'
                  readOnly
                />
                <div className='ml-4 w-32'>
                  <label className='block text-md opacity-0'>Copy</label>
                  <div className='mt-1'>
                    <CopyPasteButton text={'link'} value={baseParkvendorCalendarUrl} buttonClassName='h-10.5 w-full' />
                  </div>
                </div>
              </div>
            </>
          )
        })()}
        <label className='block font-semibold text-gray-900 pt-4 mb-1'>Other links</label>
        <div className='text-sm text-gray-500 mb-4'>
          Get a link with .ics from the other website and add it below.
        </div>
        <input hidden value={JSON.stringify(vendorSyncLinks)} name='vendor_links' />
        {vendorSyncLinks.filter((link) => !link._destroy).map((link) => {
          return (
            <div className=''>
              <div className='mt-1 flex relative items-center gap-2'>
                <div className='absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400 w-6 h-6 z-10'>
                  {getVendorImage(link.kind)}
                </div>
                <input
                  type={'text'}
                  onChange={(e) => {
                    const value = e.currentTarget.value

                    clearFieldError(`other_link_${link.id}`)

                    const { extension, hostnameArr } = extractDataFromUrl(value)

                    const domain = vendorOptions.find((option) => hostnameArr.includes(option.value))?.value || hostnameArr.join('.')

                    let newOtherLinks = [...vendorSyncLinks]
                    const index = newOtherLinks.findIndex((item) => item.id === link.id)
                    newOtherLinks[index].value = value

                    newOtherLinks[index].kind = domain
                    setOtherLinks(newOtherLinks)

                    if (extension !== 'ics') {
                      setFieldError(`other_link_${link.id}`, `Incorrect file extension, it should be ".ics"`)
                    }
                  }}
                  value={link.value}
                  onBlur={(e) => {
                    let value = e.currentTarget.value

                    // Check if the value contains 'http:' and replace it with 'https:'

                    if (value.includes('http:')) {
                      value = value.replace('http:', 'https:')
                    }

                    let newOtherLinks = [...vendorSyncLinks]
                    const index = newOtherLinks.findIndex((item) => item.id === link.id)
                    newOtherLinks[index].value = value
                    setOtherLinks(newOtherLinks)
                  }}
                  className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 pl-11'
                />
                <TrashIcon
                  className='w-6 h-6 cursor-pointer'
                  onClick={() => {
                    setOtherLinks(vendorSyncLinks.map((item) => item.id === link.id ? { ...item, _destroy: true } : item))
                  }}
                />
              </div>
              {errors?.[`other_link_${link.id}`] && (
                <div className='text-red-600 text-xssm font-semibold'>{errors?.[`other_link_${link.id}`]}</div>
              )}
            </div>
          )
        })}
        <div className='flex justify-start mt-2.5'>
          <div
            onClick={() => setOtherLinks([...vendorSyncLinks, { value: '', id: uuid.v4(), kind: '', isNewLink: true }])}
            className='cursor-pointer text-green-500 font-semibold text-xssm'
          >
            + Add another link
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarSyncInputs
