import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Divider, Form, Icon, Message } from 'semantic-ui-react'
import { discountType } from '../../constants'
import { CampProps, CampingStyleProps } from '../../interfaces'
import CampDiscountProps from '../../interfaces/CampDiscountProps'
import { displayPercentage, titleCase } from '../../utils'
import Button from '../ui/Button'

import DiscountOption from './DiscountOption'
import { BookerContext } from '../../contexts/BookerContext'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import api from '../../api2'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import InputFieldWithLabel from '../Admin/Inputs/InputFieldWithLabel'
import Alert from '../ui/Alert'
import classNames from '../../utils/classNames'
import ICoupon from '../../interfaces/ICoupon'

const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

interface ComponentProps {
  camp: CampProps
  campingStyle: CampingStyleProps
  discountRates // : { (slug: string): CampDiscountProps }
  discountType
  setDiscountType
  discountId
  setDiscountId
  discountValid: boolean
  isLoading: boolean
  setIsLoading: (v: boolean) => void
}

const DiscountForm = (props: ComponentProps) => {
  const { secondaryColor, couponCode, setCouponCode } = useContext(BookerContext)

  const [isActive, setIsActive] = useState(props.discountType || props.discountId || false)
  const [isCouponFormActive, setIsCouponFormActive] = useState<boolean>(!!couponCode)
  const [radioOption, setRadioOption] = useState<discountType>()
  const [inputValue, setInputValue] = useState('')
  const [inputTimer, setInputTimer] = useState(setTimeout(() => {}))
  const [errorMessage, setErrorMessage] = useState('')
  const [codeStr, setCodeStr] = useState('')
  const [couponError, setCouponError] = useState("")
  const [couponSuccess, setCouponSuccess] = useState("")
  const [isLoadingCouponCode, setIsLoadingCouponCode] = useState(false)
  const [couponsCount, setCouponsCount] = useState(0)

  const validateCoupon = () => {
    setCouponCode(null)
    setCouponError(null)
    setCouponSuccess(null)
    setIsLoadingCouponCode(true)

    api.Coupons.validate(codeStr)
      .then(({ coupon }) => {
        setCouponCode(coupon.code)
        setCouponSuccess(appliedMessageForCoupon(coupon))
        setIsLoadingCouponCode(false)
      })
      .catch((err) => {
        setCouponError(err.message)
        setIsLoadingCouponCode(false)
      })
  }

  const handleDiscountTypeChange = (e, { value }) => setRadioOption(value)

  const handleInputChange = (e, { value }) => setInputValue(value)

  const applyDiscount = () => {
    // NOTE: set it in the parent, we'll send it to the backend there...
    console.log('setting discount type', radioOption, inputValue)
    props.setDiscountType(radioOption)
    props.setDiscountId(inputValue)
  }

  const removeDiscount = () => {

    setRadioOption(undefined)
    setInputValue('')

    props.setDiscountType(undefined)
    props.setDiscountId('')
  }

  useEffect(() => {
    if (props.discountValid) {
      setErrorMessage(null)
    } else {
      if (props.discountType) setErrorMessage('Please provide a valid membership ID')
    }
  }, [props.discountId, props.discountType])

  const handleFormClick = () => {
    setIsActive(!isActive)
  }

  const appliedMessageForCoupon = (coupon: ICoupon) => {
    if (!coupon) return ""

    if (coupon.percent_off) {
      return `Discount of ${displayPercentage(coupon.percent_off)} has been applied to your total.`
    } else if (coupon.amount_off_in_cents) {
      return `Discount of ${usd.format(coupon.amount_off_in_cents)} per night has been removed from your total.`
    } else if (coupon.flat_rate_in_cents) {
      return `Discounted rate of ${usd.format(coupon.flat_rate_in_cents / 100)} per night has been applied to your total.`
    } else if (coupon.subtotal_amount_off_in_cents) {
      return `Discount of ${usd.format(coupon.subtotal_amount_off_in_cents / 100)} has been applied to your total.`
    }
  }

  const appliedMessageForDiscount = () => {
    const selectedDiscount = props.discountRates[props.discountType]
    if (!selectedDiscount) return ""

    if (selectedDiscount.percent_off) {
      return `${selectedDiscount.name} discount of ${displayPercentage(selectedDiscount.percent_off)} has been applied to your total.`
    } else if (selectedDiscount.amount_off) {
      return `${selectedDiscount.name} discount of ${usd.format(selectedDiscount.amount_off)} per night has been removed from your total.`
    } else if (selectedDiscount.amount_off_per_night_in_cents) {
      return `${selectedDiscount.name} discount of ${formatMoneyFromCents(selectedDiscount.amount_off_per_night_in_cents)} per night has been applied to your total.`
    } else if (selectedDiscount.flat_rate_in_cents) {
      return `${selectedDiscount.name} discounted rate of ${usd.format(selectedDiscount.flat_rate_in_cents / 100)} per night has been applied to your total.`
    } else if (selectedDiscount.subtotal_off_in_cents) {
      return `${selectedDiscount.name} discount of ${usd.format(selectedDiscount.subtotal_off_in_cents / 100)} has been applied to your total.`
    }
  }

  useEffect(() => {
    api.Coupons.fetchForCamp(props.camp.id).then((res) => setCouponsCount(res.coupons.length))
  }, [])

  return (
    <>
      <Accordion styled fluid className='relative z-20'>
        <Accordion.Title active={isActive} index={0} onClick={handleFormClick}>
          <Icon name='dropdown' />
          Add Discount
        </Accordion.Title>

        <Accordion.Content active={isActive}>
          <Form loading={props.isLoading} success={props.discountValid} className='flex flex-col xs:block'>
            <p className='text-gray-500 mb-1 font-normal'>
              If applicable, select a discount type
              {Object.values(props.discountRates).find(
                (x: CampDiscountProps) => x.require_membership_id
              )
                ? ', and enter a valid membership ID, and click to apply.'
                : ' and click to apply.'}{' '}
              {props.camp.slug == 'nickerson-beach-park' ? 'You must be a Nassau County Resident and present a valid Nassau County Drivers License upon check in to receive this discount' :

                'Please have your membership ID available when you arrive.'}
            </p>
            <Divider hidden />
            <Form.Group inline>
              <span className='ui small'></span>
              {Object.keys(props.discountRates).map((type) => {
                return (
                  <DiscountOption
                    key={type}
                    radioOption={radioOption}
                    name={props.discountRates[type].name}
                    discountType={type}
                    onChange={handleDiscountTypeChange}
                  />
                )
              })}
            </Form.Group>
            {props.discountRates[radioOption]?.require_membership_id ? (
              <Form.Input
                loading={props.isLoading}
                fluid
                icon={
                  props.discountValid
                    ? { name: 'check circle', color: 'green' }
                    : { name: 'user circle' }
                }
                iconPosition='left'
                label='Membership ID'
                value={inputValue}
                onChange={handleInputChange}
                error={
                  !props.discountValid && errorMessage
                    ? {
                        content: errorMessage,
                        pointing: 'above',
                      }
                    : null
                }
              />
            ) : (
              <></>
            )}
            {props.discountType?.length &&
              <Message success>
                <p>{appliedMessageForDiscount()}</p>
              </Message>
            }
            <Button
              style={{ backgroundColor: secondaryColor }}
              className='hover:opacity-80 text-white'
              disabled={props.isLoading || !radioOption}
              onClick={applyDiscount}>
              Apply Discount
            </Button>
            <Button
              variant='none'
              style={{marginLeft: 5}}
              className='hover:opacity-80 underline text-red-500'
              disabled={props.isLoading || !radioOption}
              onClick={removeDiscount}>
              Remove Discount
            </Button>
          </Form>
        </Accordion.Content>
      </Accordion>
      {couponsCount ? (
        <Accordion styled fluid className='relative z-20'>
          <Accordion.Title
            active={isCouponFormActive}
            index={0}
            onClick={() => setIsCouponFormActive(!isCouponFormActive)}
          >
            <Icon name='dropdown' />
            Add Coupon
          </Accordion.Title>

          <Accordion.Content active={isCouponFormActive}>
            <Form
              loading={isLoadingCouponCode}
              success={!!couponCode}
              className='flex flex-col xs:block'
            >
              <InputFieldWithLabel
                label='Code'
                value={codeStr}
                onChange={(e) => setCodeStr(e.target.value)}
                className='max-w-md'
              />
              {couponError && (
                <div className='mt-4'>
                  <Alert description={couponError} className='max-w-md' />
                </div>
              )}
              {couponSuccess && (
                <div className='mt-4'>
                  <Alert
                    kind='success'
                    description={couponSuccess}
                    className='max-w-md'
                  />
                </div>
              )}
              <div className='mt-4'>
                <Button
                  style={{ backgroundColor: secondaryColor }}
                  className='hover:opacity-80 text-white'
                  disabled={isLoadingCouponCode || !codeStr}
                  onClick={validateCoupon}
                >
                  Apply Coupon
                </Button>
                <Button
                  variant='none'
                  style={{ color: secondaryColor }}
                  className={classNames(
                    'ml-1 hover:opacity-80 underline',
                    (isLoadingCouponCode || !codeStr) && 'pointer-events-none'
                  )}
                  disabled={isLoadingCouponCode || !codeStr}
                  onClick={() => {
                    setCouponCode(null)
                    setCodeStr('')
                    setErrorMessage('')
                    setCouponSuccess('')
                  }}
                >
                  Remove Coupon
                </Button>
              </div>
            </Form>
          </Accordion.Content>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  )
}

export default DiscountForm
