import { post, transformRequestData } from "./utils/requests"

type RefundParams = {
  amountInCents: number
  reason: string
  isRefundingServiceFee: boolean
}

const refund = async (paymentId: string, data: RefundParams): Promise<{}> =>
  post(`/api/payments/${paymentId}/refunds`, transformRequestData(data))


const Payments = {
  refund
}

export default Payments