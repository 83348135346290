import React, { useContext, useEffect, useRef, useState } from 'react'
import { CampProps, CampingStyleProps, ReservationProps, SiteProps } from '../../../interfaces'
import Button from '../../ui/Button'
import moment from 'moment'
import api from '../../../api2'
import { AdminContext } from '../../../contexts/AdminContext'
import { formatMoney } from '../../../utils/formatMoney'
import { ArrowLeftIcon, PlusIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/solid'
import { formatName } from '../../../utils/formatName'
import PreviewInvoiceToggle from './PreviewInvoiceToggle'
import { formatCampAddress } from '../../../utils/formatAddress'
import {
  LONG_TERM_RESERVATION_KINDS,
  PAYMENT_MODES,
  RESERVATION_KINDS,
  STATUSES,
  longTermReservationKind,
} from '../../../constants'
import * as Sentry from '@sentry/react'
import Spinner from '../../ui/Spinner'
import { FormProvider } from '../../../contexts/FormContext'
import useAdminForm from '../../../hooks/useAdminForm'
import Form from '../../ui/Admin/Form'
import eSendingMode from '../../../enums/eSendingMode'
import SelectInput, { Option } from '../../ui/SelectInput'
import classNames from '../../../utils/classNames'
import { useCookies } from 'react-cookie'
import { sendingInvoiceOptions } from '../../Reservations/sendingOptions'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import { serviceRateMode } from '../../../api2/orders'
import IOrderPreview from '../../../interfaces/IOrderPreview'
import { isInvalidSendingMode } from '../../Reservations/NewReservation'
import { useReactToPrint } from 'react-to-print'
import { useDebouncedCallback } from 'use-debounce'
import IInvoice from '../../../interfaces/IInvoice'
import ILineItem from '../../../interfaces/ILineItem'
import { LINE_ITEM_KINDS, lineItemKind } from '../../../constants/line_item_kinds'
import _ from 'lodash'
import { INVOICE_STATUSES } from '../../../decorators/InvoiceDecorator'
import OrderSummary from '../Orders/OrderSummary'
import roundToMaxPrecision from '../../../utils/roundToMaxPrecision'
import eSendingModeCookieName from '../../../enums/eSendingModeCookieName'
import DiscountProps from '../../../interfaces/DiscountProps'
import parseDateTime from '../../../utils/parseDateTime'
import createMarkup from '../../../utils/createMarkup'

const DESCRIPTION_ELECTRICITY = 'Electricity'

export interface ILineItemLegacy {
  id?: string
  is_description_read_only?: boolean
  is_unit_price_read_only?: boolean
  is_quantity_read_only?: boolean
  description: string
  unit_price: number
  quantity: number
  total_price: number
}

const cookieSendingMode = eSendingModeCookieName.INVOICE

export const sendingModeOptions = [
  {
    label: 'Email',
    value: eSendingMode.EMAIL,
  },
  {
    label: 'Text',
    value: eSendingMode.TEXT,
  },
  {
    label: 'Email & Text',
    value: eSendingMode.EMAIL_AND_TEXT,
  },
]

export type ILineItemField = Omit<ILineItem, 'unit_amount_in_cents' | 'total_amount_in_cents'> & {
  unit_amount: number
  total_amount: number
  is_hidden?: boolean
}

export interface IFormData {
  electricity_fee: number
  last_meter_reading: number
  current_meter_reading: number
  to_name: string
  to_email: string
  to_phone: string
  to_address: string
  memo: string
  items: ILineItemField[]
  sending_mode: eSendingMode
  is_displaying_occurrences?: boolean
}

export const initializeFormDataFor = (
  invoice: IInvoice,
  site: SiteProps,
  camp: CampProps,
  isPreview: boolean,
  cookies?: any
): IFormData => {
  const sendinModeFromCookie =
    cookies &&
    Object.values(eSendingMode)
      .filter((item) => item !== eSendingMode.NONE)
      .includes(cookies[cookieSendingMode])
      ? cookies[cookieSendingMode]
      : false
      
  let defaultSendingMode = sendinModeFromCookie || invoice.reservation?.sending_mode || camp.booking_sending_mode || eSendingMode.EMAIL

  if (defaultSendingMode === 'none') {
    defaultSendingMode = eSendingMode.EMAIL
  }

  const isReservationRecurring = invoice.reservation?.kind === RESERVATION_KINDS.RECURRING
  const longTermKind = invoice.reservation?.long_term_reservation?.kind

  let invoiceLineItems: ILineItemField[] = invoice.order.line_items.map((item) => ({
    ...item,
    quantity: item.kind === LINE_ITEM_KINDS.ELECTRIC ? item.quantity : Math.round(item.quantity),
    unit_amount: item.unit_amount_in_cents / 100.0,
    total_amount: item.total_amount_in_cents / 100.0,
  }))

  const electricityLineItem = invoiceLineItems.find(
    (item) => item.kind === LINE_ITEM_KINDS.ELECTRIC
  )

  const electricKinds: longTermReservationKind[] = [
    LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES,
    LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY,
  ]

  if (!electricityLineItem && isReservationRecurring && electricKinds.includes(longTermKind)) {
    invoiceLineItems.push({
      id: null,
      kind: LINE_ITEM_KINDS.ELECTRIC,
      label: DESCRIPTION_ELECTRICITY,
      unit_amount: camp.electricity_rate,
      quantity: 0,
      total_amount: 0,
      tax_rate: invoiceLineItems.find((item) => item.tax_rate)?.tax_rate || 0,
      order_id: invoice.order_id,
      add_on_id: null,
      reservation_id: null,
      parent_line_item_id: null,
      discount_id: null,
      occurrences: null,
      occurrence_unit: null,
    })
  }

  if (isDisplayingAsMonthly(invoice.reservation)) {
    invoiceLineItems = invoiceLineItems.map((item) =>
      item.reservation_id
        ? {
            ...item,
            unit_amount: item.total_amount,
            is_hidden:
              longTermKind === LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY && isReservationRecurring,
          }
        : item
    )
  }

  return {
    electricity_fee: camp.electricity_rate,
    last_meter_reading: invoice.reservation?.last_meter_reading || site?.last_meter_reading,
    current_meter_reading: invoice.reservation?.meter_reading || site?.last_meter_reading,
    to_name: invoice.recipient_name,
    to_email: invoice.recipient_email,
    to_phone: invoice.recipient_phone,
    to_address: invoice.recipient_address,
    memo: invoice.memo || camp.default_invoice_memo || null,
    items: invoiceLineItems.map((item) => ({
      ...item,
      is_hidden: isPreview ? item.unit_amount == 0 : item.is_hidden,
    })),
    sending_mode: defaultSendingMode,
    is_displaying_occurrences: !isDisplayingAsMonthly(invoice.reservation),
  }
}

const isDisplayingAsMonthly = (reservation: ReservationProps): boolean =>
  reservation
    ? moment(reservation.end_date).diff(moment(reservation.start_date), 'days') >= 28
    : false

interface ComponentProps {
  invoice: IInvoice
  setSelectedInvoice?: (invoice: IInvoice) => any
  updateLocalInvoices?: (invoices: IInvoice[]) => void
  onSuccess?: (invoice: IInvoice) => any
  campingStyle?: CampingStyleProps
  site: SiteProps
  isProcessing?: boolean
  disabled?: boolean
  className?: string
  customerView?: boolean
  isHidingBackButton?: boolean
  camp?: CampProps
  setCamp?: (camp: CampProps) => any
  cookies?: any
  printRef?: React.MutableRefObject<any>
  isForPrint?: boolean
  hasPageBreak?: boolean
}

const EditInvoice = (props: ComponentProps) => {
  const { camp: adminCamp } = useContext(AdminContext)
  const camp = props.camp || adminCamp
  const campingStyles = camp.camping_styles
  const campingStyle =
    props.campingStyle || campingStyles?.find((cs) => props.site.camping_style_id === cs.id)
  const [cookies] = useCookies([cookieSendingMode])
  const [resource, setResource] = useState<IFormData>(
    initializeFormDataFor(props.invoice, props.site, camp, props.customerView, cookies)
  )
  const [electricityUsage, setElectricityUsage] = useState(0)
  const [electricityPrice, setElectricityPrice] = useState(camp.electricity_rate)
  const [localElectricFees, setLocalElectricFees] = useState(0)
  const availableDiscounts = camp.discounts

  useEffect(() => {
    setLocalElectricFees(electricityUsage * electricityPrice)
  }, [electricityUsage, electricityPrice])

  if (!props.invoice) {
    return <></>
  }

  return (
    <div
      className={classNames(
        'mx-auto w-full pb-8 max-w-8xl',
        'px-4 sm:px-8 md:px-12 lg:px-16 xl:px-24'
      )}>
      <FormProvider init={resource}>
        <InvoiceForm
          camp={camp}
          customerView={props.customerView}
          invoice={props.invoice}
          site={props.site}
          campingStyle={campingStyle}
          isHidingBackButton={props.isHidingBackButton}
          setSelectedInvoice={props.setSelectedInvoice}
          onSuccess={props.onSuccess}
          updateLocalInvoices={props.updateLocalInvoices}
          disabled={props.disabled}
          isProcessing={props.isProcessing}
          availableDiscounts={availableDiscounts}
          printRef={props.printRef}
          isForPrint={props.isForPrint}
          hasPageBreak={props.hasPageBreak}
        />
      </FormProvider>
    </div>
  )
}

const AddressField = ({
  label,
  name,
  address,
  handleNameChange,
  handleAddressChange,
  textOnly,
  className,
}: {
  label: string
  name: string
  address: string
  handleNameChange?: any
  handleAddressChange?: any
  textOnly?: boolean
  className?: string
}) => {
  return (
    <div className={className}>
      <label className='block mb-1 text-baselg font-bold'>{label}</label>
      {textOnly ? (
        <>
          <span className='block text-base'>{name}</span>
          <span className='block text-base'>{address}</span>
        </>
      ) : (
        <>
          <input
            type='text'
            placeholder='Name'
            value={name}
            onChange={handleNameChange}
            className='w-full p-2 border rounded mb-2'
          />
          <textarea
            rows={2}
            placeholder='Address'
            value={address}
            onChange={handleAddressChange}
            className='w-full p-2 border rounded'
          />
        </>
      )}
    </div>
  )
}

const ItemRow = ({
  camp,
  item,
  index,
  isPreview,
}: {
  camp: CampProps
  item: ILineItemField
  index: number
  isPreview: boolean
}) => {
  if (item.is_hidden || (item.unit_amount = 0 && isPreview) || (item.total_amount === 0 && item.label === 'Reservation')) {
    return <></>
  }

  const { resource, watch, setValue } = useAdminForm<IFormData>()
  const items = watch('items')
  const totalPrice = watch(`items.${index}.total_amount`)
  const unitPrice = watch(`items.${index}.unit_amount`)
  const occurrences = watch(`items.${index}.occurrences`)
  const quantity = watch(`items.${index}.quantity`)
  const label = watch(`items.${index}.label`)
  const kind = watch(`items.${index}.kind`)
  const last_meter_reading = watch('last_meter_reading')

  const isItemDeletable = () => {
    const unremovableKinds = [
      LINE_ITEM_KINDS.ELECTRIC,
      LINE_ITEM_KINDS.HOLIDAY_RATE,
      "weekend_rate",
      "additional_adults",
      "additional_kids",
      "additional_pets"
    ] as lineItemKind[]
    if(unremovableKinds.includes(item.kind)) return false
    if(item.reservation_id) return false

    return true
  }

  useEffect(() => {
    const watchedItem = watch(`items.${index}`) as ILineItemField
    if (item?.reservation_id || watchedItem?.reservation_id) {
      return null
    }

    setValue(`items.${index}.total_amount`, unitPrice * quantity)
  }, [unitPrice, quantity])

  useEffect(() => {
    if (item?.reservation_id) {
      const hasOccurrences = !!item.occurrences
      let newTotal = item.total_amount / item.quantity

      if (hasOccurrences) newTotal = newTotal / item.occurrences

      setValue(`items.${index}.unit_amount`, newTotal)
    }
  }, [totalPrice])

  const handleElectricityChange = (e) => {
    if (kind !== LINE_ITEM_KINDS.ELECTRIC) return

    if (e.target.name.includes('quantity')) {
      setValue(
        `current_meter_reading`,
        roundToMaxPrecision(Number(e.target.value) + Number(last_meter_reading))
      )
    } else {
      setValue(
        `current_meter_reading`,
        roundToMaxPrecision(Number(quantity) + Number(last_meter_reading))
      )
    }
  }

  const onDeleteItem = () => {
    setValue(
      'items',
      items.filter((_, i) => i !== index)
    )
  }

  if (isPreview) {
    return (
      <div className='grid grid-cols-12 gap-2 mt-4 border-b border-gray-200 pb-4'>
        <div className={resource.is_displaying_occurrences ? 'col-span-4' : 'col-span-5'}>
          {label}
        </div>
        <div className='col-span-2'>{formatMoney(unitPrice)}</div>
        {resource.is_displaying_occurrences && (
          <div className='col-span-1'>{occurrences || '-'}</div>
        )}
        <div className='col-span-2'>{quantity || '-'}</div>
        <div className='col-span-2'>{totalPrice ? formatMoney(totalPrice) : '-'}</div>
      </div>
    )
  } else {
    if (item) {
      const isLabelReadOnly = item.kind === LINE_ITEM_KINDS.ELECTRIC
      const isUnitPriceReadOnly = !!item.reservation_id
      const isQuantityReadOnly = !!item.reservation_id
      const isTotalReadOnly = !item.reservation_id

      return (
        <div className='grid grid-cols-12 gap-2 mb-2'>
          <Form.TextInput
            field={`items.${index}.label`}
            className={resource.is_displaying_occurrences ? 'col-span-4' : 'col-span-5'}
            asText={isLabelReadOnly}
          />
          <Form.NumberInput
            field={`items.${index}.unit_amount`}
            onChange={handleElectricityChange}
            prefix='$'
            suffix={camp.currency.toUpperCase()}
            className='col-span-2'
            asText={isUnitPriceReadOnly}
          />
          {resource.is_displaying_occurrences && (
            <Form.NumberInput field={`items.${index}.occurrences`} className='col-span-1' asText />
          )}
          <Form.NumberInput
            isIntegerOnly={item.kind !== LINE_ITEM_KINDS.ELECTRIC}
            field={`items.${index}.quantity`}
            onChange={handleElectricityChange}
            className='col-span-2'
            asText={isQuantityReadOnly}
          />
          <Form.NumberInput
            field={`items.${index}.total_amount`}
            prefix='$'
            suffix={camp.currency.toUpperCase()}
            className='col-span-2'
            asText={isTotalReadOnly}
          />
          <div className='col-span-1 flex justify-center items-center' style={{ paddingBottom: 7 }}>
            {isItemDeletable() && <TrashIcon onClick={onDeleteItem} className='w-6 h-6 cursor-pointer' />}
          </div>
        </div>
      )
    } else return <></>
  }
}

export const InvoiceForm = ({
  camp,
  customerView,
  invoice,
  site,
  campingStyle,
  isHidingBackButton,
  setSelectedInvoice,
  onSuccess,
  updateLocalInvoices,
  disabled,
  isProcessing,
  availableDiscounts,
  isForPrint,
  hasPageBreak,
  printRef,
}: {
  camp: CampProps
  customerView: boolean
  invoice: IInvoice
  site: SiteProps
  campingStyle: CampingStyleProps
  isHidingBackButton: boolean
  setSelectedInvoice: (invoice: IInvoice) => void
  onSuccess: (invoice: IInvoice) => any
  updateLocalInvoices: (invoices: IInvoice[]) => void
  disabled: boolean
  isProcessing: boolean
  availableDiscounts: DiscountProps[]
  isForPrint?: boolean
  hasPageBreak?: boolean
  printRef?: React.MutableRefObject<any>
}) => {
  const { setValue, resource, watch, updateDefaults, resetDefaults, reset } =
    useAdminForm<IFormData>()
  const { updateLocalReservations, showNotification } = useContext(AdminContext)
  const [cookies, setCookie] = useCookies([cookieSendingMode])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowingCc, setIsShowingCc] = useState(false)
  const [isPreview, setIsPreview] = useState(customerView)
  const [isPrintClicked, setIsPrintClicked] = useState(false)
  const [isLoadingPayOnline, setIsLoadingPayOnline] = useState(false)
  const items = watch('items')
  const currentMeterReading = watch('current_meter_reading')
  const lastMeterReading = watch('last_meter_reading')
  const [sendingMode, setSendingMode] = useState(
    resource.sending_mode || (cookies && cookies[cookieSendingMode]) || eSendingMode.EMAIL
  )
  const targetRef = useRef(null)
  useEffect(() => {
    handleChangeMeterReadings()
  }, [currentMeterReading, lastMeterReading])

  const handleChangeMeterReadings = () => {
    if (Number(currentMeterReading) > Number(lastMeterReading)) {
      const index = items?.findIndex((item) => item.kind === LINE_ITEM_KINDS.ELECTRIC)

      if ([undefined, null].includes(index)) return

      setValue(
        `items.${index}.quantity`,
        roundToMaxPrecision(currentMeterReading - lastMeterReading)
      )
    }
  }

  const handleClickSave = () => {
    updateInvoice(false)
  }

  const handleClickSendInvoice = () => {
    updateInvoice(true)
  }

  const updateInvoice = async (isSendingInvoice: boolean) => {
    setIsLoading(true)

    let mInvoice = invoice

    if (!invoice?.id) {
      try {
        const reservationId = items.find((item) => item.reservation_id)?.reservation_id
        const { invoice: newInvoice } = await api.Invoices.createForReservation(reservationId)
        setSelectedInvoice(newInvoice)
        mInvoice = newInvoice
      } catch(err) {
        console.log("error creating new invoice", err)
        Sentry.captureException(err)
        showNotification({
          type: 'error',
          title: 'Oops',
          description: err.message,
        })
        setIsLoading(false)
        return
      }
    }

    const newSendDate = isSendingInvoice ? moment() : null
    const newDueDate = newSendDate ? moment(newSendDate).add(camp.invoice_due_days, 'days') : null

    api.Invoices.update(mInvoice.id, camp.id, {
      isSending: isSendingInvoice,
      sendingMode: sendingMode || resource.sending_mode || eSendingMode.EMAIL,
      admin_email: isShowingCc ? camp.email : null,
      sendDate: newSendDate
        ? newSendDate
        : invoice.send_date
        ? moment(parseDateTime(invoice.send_date).toJSDate())
        : null,
      dueDate: newSendDate
        ? newDueDate
        : invoice.due_date
        ? moment(parseDateTime(invoice.due_date).toJSDate())
        : null,
      recipientEmail: resource.to_email,
      recipientPhone: resource.to_phone,
      recipientName: resource.to_name,
      recipientAddress: resource.to_address,
      memo: resource.memo,
      lastMeterReading: resource.last_meter_reading,
      meterReading: resource.current_meter_reading,
      lineItemsAttributes: resource.items.map((item) => ({
        id: item.id,
        kind: item.kind,
        label: item.label,
        totalAmountInCents: item.reservation_id ? Number(item.total_amount) * 100.0 : null,
        unitAmountInCents: item.reservation_id ? null : Number(item.unit_amount) * 100.0,
        quantity: item.quantity,
        occurrences: item.occurrences,
        occurrenceUnit: item.occurrence_unit,
        isTaxFeeEnabled: mInvoice.order?.tax_fee_in_cents > 0,
        reservationId: item.reservation_id,
        addOnId: item.add_on_id,
        isAttachedToReservation: !!item.parent_line_item_id,
      })),
    })
      .then(({ invoice: updatedInvoice }) => {
        updateLocalReservations([updatedInvoice.reservation])
        updateLocalInvoiceData(updatedInvoice)
        updateLocalFormData(updatedInvoice)
        setIsLoading(false)
        if (onSuccess) onSuccess(updatedInvoice)
        showNotification({
          type: 'success',
          title: isSendingInvoice ? 'Invoice sent' : 'Invoice updated',
          description: isSendingInvoice
            ? `Invoice ${updatedInvoice.hash_id} has been sent`
            : `Invoice ${updatedInvoice.hash_id} has been updated`,
        })
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
        showNotification({
          type: 'error',
          title: 'Oops',
          description: err.message,
        })
      })
  }

  const updateLocalInvoiceData = (updated: IInvoice) => {
    if (updateLocalInvoices) updateLocalInvoices([updated])
    if (setSelectedInvoice) setSelectedInvoice(updated)
  }

  const updateLocalFormData = (updated: IInvoice) => {
    const formData = initializeFormDataFor(updated, site, camp, isPreview, cookies)
    updateDefaults(formData)
    reset(formData)
  }

  const handleClickPayOnline = () => {
    if (invoice.reservation?.stripe_payment_link_url) {
      window.open(invoice.reservation?.stripe_payment_link_url, '_blank')
    } else {
      setIsLoadingPayOnline(true)
      api.PaymentLinks.create(invoice.reservation?.id, { skip_send: true })
        .then(({ reservation }) => {
          setIsLoadingPayOnline(false)
          if (reservation?.stripe_payment_link_url) {
            window.open(reservation?.stripe_payment_link_url, '_blank')
          } else {
            Sentry.captureException('OK response from payment link creation but no link found')
            throw new Error('Could not create payment link')
          }
        })
        .catch((err) => {
          setIsLoadingPayOnline(false)
          console.error(err)
          alert(JSON.stringify(err))
        })
    }
  }

  const addItem = () => {
    setValue('items', [
      ...items,
      {
        id: null,
        label: '',
        unit_amount: 0,
        quantity: 1,
        total_amount: 0,
        kind: 'basic',
        tax_rate: items[0]?.tax_rate,
        order_id: invoice.order_id,
        occurrences: null,
        occurrence_unit: null,
        add_on_id: null,
        reservation_id: null,
        parent_line_item_id: null,
        discount_id: null,
      },
    ])
  }

  useEffect(() => {
    !(sendingMode === eSendingMode.EMAIL || sendingMode === eSendingMode.EMAIL_AND_TEXT) &&
      setIsShowingCc(false)
  }, [sendingMode])

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  })

  const isShowingSite = (campingStyle && (!campingStyle.is_showing_site_in_notifiers && customerView)) || true

  return (
    <div>
      {customerView ? (
        <div className='mt-12'></div>
      ) : (
        <div className='flex items-center justify-between mb-12'>
          {isHidingBackButton ? (
            <div />
          ) : (
            <Button
              variant='white'
              className='border-none pl-2.5 pr-3'
              icon={<ArrowLeftIcon className='w-5 h-5' />}
              onClick={() => setSelectedInvoice(null)}>
              Back
            </Button>
          )}
          <div className='text-2xl font-semibold'>
            {(invoice.reservation?.user?.camper?.first_name ||
                invoice.reservation?.user?.camper?.last_name ||
                invoice.reservation?.user?.first_name ||
              invoice.reservation?.user?.last_name) &&
              'New invoice for ' +
                formatName(invoice.reservation?.user?.camper || invoice.reservation?.user)}
          </div>

          <div
            className='bg-gray-100 p-3 rounded-md border-dashed border-2 border-gray-300'
            onClick={() => setIsPreview(!isPreview)}>
            <PreviewInvoiceToggle
              checked={isPreview}
              onChange={setIsPreview}
              name='isBookable'
              label='Preview'
              labelClassName='text-lg font-semibold'
            />
          </div>
        </div>
      )}
      <div
        ref={printRef}
        className={classNames(
          isForPrint && 'max-w-4xl',
          isForPrint && hasPageBreak && 'pagebreak',
          'print-margin'
        )}>
        <div className='flex gap-8 justify-between mb-12 flex-col sm:flex-row'>
          <div className='flex-1 max-w-sm'>
            <AddressField
              label='From'
              name={camp.name}
              address={formatCampAddress(camp)}
              textOnly={true}
              className='mb-4'
            />

            {isPreview && !resource.to_name && !resource.to_address ? (
              <></>
            ) : (
              <>
                <span className='block mb-1 text-baselg font-bold'>To</span>
                <Form.TextInput field='to_name' placeholder='Name' asText={isPreview} />
                <Form.TextAreaInput field='to_address' placeholder='Address' asText={isPreview} />
                <Form.TextInput field='to_email' asText={isPreview} placeholder='Email' />
                <Form.TextInput field='to_phone' asText={isPreview} placeholder='Phone' />
              </>
            )}
          </div>
          <div className='flex-1 max-w-sm grid gap-x-2 grid-cols-2'>
            <label className='block mb-2 text-md font-bold'>Invoice #</label>
            <span className='text-base'>{invoice.hash_id}</span>

            {((invoice.reservation?.kind == RESERVATION_KINDS.RECURRING && invoice.send_date) ||
              invoice.reservation?.kind != RESERVATION_KINDS.RECURRING) && (
              <>
                <label className='block mb-2 text-md font-bold'>Invoice Date</label>
                <span className='text-base'>
                  {invoice.reservation?.kind == RESERVATION_KINDS.RECURRING
                    ? parseDateTime(invoice?.send_date).toFormat(`MMMM dd, yyyy`)
                    : parseDateTime(invoice.reservation?.created_at).toFormat(`MMMM dd, yyyy`)}
                </span>
              </>
            )}

            {(invoice.reservation?.kind == RESERVATION_KINDS.RECURRING ||
              invoice.reservation?.payment_mode == PAYMENT_MODES.PAYMENT_LINK) && (
              <>
                <label className='block mb-2 text-md font-bold'>Due Date</label>
                <span className='text-base'>
                  {parseDateTime(invoice.due_date).toFormat(`MMMM dd, yyyy`)}
                </span>
              </>
            )}

            <label className='block mb-2 text-md font-bold'>Dates</label>
            <span className='text-base'>
              {parseDateTime(invoice.reservation?.start_date).toFormat(`MMM dd`)} -
              {parseDateTime(invoice.reservation?.end_date).toFormat(` MMM dd`)}
            </span>

            {isShowingSite && (
              <>
                <label className='block mb-2 text-md font-bold'>Site</label>
                <span className='text-base'>{site?.name}</span>
              </>
            )}

            {invoice.reservation?.kind == RESERVATION_KINDS.RECURRING && (
              <>
                <label className={`block mb-2 text-md font-bold ${!isPreview && 'mt-2.5'}`}>
                  Last meter reading
                </label>
                <div className='relative w-full mb-2'>
                  <Form.NumberInput field='last_meter_reading' suffix='KWh' asText={isPreview} />
                </div>

                <label className={`block mb-2 text-md font-bold ${!isPreview && 'mt-2.5'}`}>
                  Current meter reading
                </label>
                <div className='relative w-full'>
                  <Form.NumberInput field='current_meter_reading' suffix='KWh' asText={isPreview} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className='overflow-y-hidden overflow-x-auto hide-scrollbar' style={{maxWidth: '95vw'}}>
          <div style={{minWidth: 912}}>
            <div className='grid grid-cols-12 gap-2 font-bold mb-2.5 text-baselg pb-1.5 border-b border-gray-900'>
              <div className={resource.is_displaying_occurrences ? 'col-span-4' : 'col-span-5'}>
                Item
              </div>
              <div className='col-span-2'>Price</div>
              {resource.is_displaying_occurrences && <div className='col-span-1'>Nights</div>}
              <div className='col-span-2'>Quantity</div>
              <div className='col-span-2'>Amount</div>
            </div>
            {items?.map((item, index) => (
              <ItemRow
                camp={camp}
                key={index}
                index={index}
                item={{ ...item, is_hidden: isForPrint ? item.unit_amount == 0 : item.is_hidden }}
                isPreview={isPreview}
              />
            ))}
          </div>
        </div>
        <Button
          onClick={addItem}
          variant='white'
          icon={<PlusIcon className='w-5 h-5' />}
          className={classNames(isPreview && 'hidden', 'mt-2 lg:mt-0')}>
          Add Item
        </Button>
        <div className='flex justify-between mt-8 flex-col md:flex-row gap-y-5'>
          <div className='flex-1 mr-4 max-w-lg'>
            {isPreview ? (
              resource.memo && (
                <>
                  <label className='block mb-2 font-bold text-baselg'>Memo</label>
                  <span
                    className='text-base whitespace-pre-wrap break-all md:break-words'
                    dangerouslySetInnerHTML={createMarkup(resource.memo)}></span>
                </>
              )
            ) : (
              <>
                <label className='block mb-2 font-bold text-baselg'>Memo</label>
                <Form.RichTextInput field='memo' className='mb-4' />
              </>
            )}
          </div>
          <div className='flex-1 max-w-sm' style={{minWidth: 250}}>
            <Total
              invoice={invoice}
              site={site}
              camp={camp}
              isCustomerView={isPreview}
              discounts={availableDiscounts}
            />
          </div>
        </div>
      </div>
      <div className='flex gap-8 flex-col'>
        {isForPrint ? (
          <></>
        ) : customerView ? (
          <div className='flex justify-end mt-12'>
            <Button
              variant='white'
              size={window.innerWidth > 400 ? 'large' : 'normal'}
              className='ml-0 md:ml-4 flex-shrink-0 justify-self-end flex items-center mr-2 lg:mr-0'
              onClick={handlePrint}
              disabled={disabled || isProcessing || isLoading}>
              <PrinterIcon className='w-5 h-5 mr-2' />
              Print
            </Button>
            {!invoice.reservation?.long_term_reservation_id && (
              <Button
                variant='white'
                size={window.innerWidth > 400 ? 'large' : 'normal'}
                className='ml-4 flex-shrink-0 justify-self-end'
                onClick={() => (window.location.href = `${window.origin}/r/${invoice.hash_id}`)}>
                View Campground Info
              </Button>
            )}
            {invoice.status !== STATUSES.PAID && (
              <Button
                variant='blue'
                disabled={isLoadingPayOnline}
                size={window.innerWidth > 400 ? 'large' : 'normal'}
                className='ml-4 flex-shrink-0 justify-self-end flex items-center'
                onClick={handleClickPayOnline}>
                {isLoadingPayOnline ? <Spinner size={4} /> : 'Pay Online'}
              </Button>
            )}
          </div>
        ) : (
          <div className='flex justify-center md:justify-end mt-12 items-center flex-col md:flex-row'>
            <div className='flex items-center flex-col-reverse md:flex-row-reverse justify-center mb-3 md:mb-0'>
              <SelectInput
                name='sendingOptions'
                large
                buttonSize='large'
                width='w-auto'
                className='-top-40 left-1/2 transform -translate-x-1/2'
                options={sendingInvoiceOptions}
                selected={sendingInvoiceOptions.find((o) => o.value === sendingMode)}
                setSelected={(o: Option) => {
                  setCookie(cookieSendingMode, o.value)
                  setSendingMode(o.value)
                  setValue('sending_mode', o.value)
                }}
                warningText={
                  isInvalidSendingMode(
                    resource.to_email || invoice.reservation?.user?.camper?.email || invoice.reservation?.user?.email,
                    resource.to_phone || invoice.reservation?.user?.camper?.phone || invoice.reservation?.user?.phone,
                    sendingMode
                  ) && (
                    <div className='text-xs text-yellow-600'>
                      {sendingMode === eSendingMode.EMAIL
                        ? 'Will not send without a valid email'
                        : sendingMode === eSendingMode.TEXT
                        ? 'Will not send without a valid phone number'
                        : 'Will not send without a valid email & phone number'}
                    </div>
                  )
                }
              />
              {(sendingMode === eSendingMode.EMAIL ||
                sendingMode === eSendingMode.EMAIL_AND_TEXT) && (
                <div className='flex items-center md:mr-3 mb-2 md:mb-0'>
                  <CheckboxWithLabel
                    label={`cc ${camp.email}`}
                    value={isShowingCc}
                    setValue={setIsShowingCc}
                    labelClasses='text-sm whitespace-nowrap'
                  />
                </div>
              )}
            </div>
            <div className='flex'>
              <Button
                variant='white'
                size={'normal'}
                className='ml-0 md:ml-4 flex-shrink-0 justify-self-end flex items-center mr-2 lg:mr-0'
                onClick={() => {
                  const prevIsPreview = isPreview
                  setIsPreview(true)
                  handlePrint()
                  setIsPreview(prevIsPreview)
                }}
                disabled={disabled || isProcessing || isLoading}>
                <PrinterIcon className='w-5 h-5 mr-2' />
                Print
              </Button>
              <Button
                variant='white'
                size={'normal'}
                className='ml-0 md:ml-4 flex-shrink-0 justify-self-end'
                onClick={handleClickSave}
                disabled={disabled || isProcessing || isLoading}>
                Save
              </Button>
              <Button
                variant='blue'
                size={'normal'}
                className='ml-4 flex-shrink-0 justify-self-end'
                onClick={handleClickSendInvoice}
                disabled={
                  disabled ||
                  isProcessing ||
                  isLoading ||
                  isInvalidSendingMode(
                    resource.to_email || invoice.reservation?.user?.camper?.email || invoice.reservation?.user?.email,
                    resource.to_phone || invoice.reservation?.user?.camper?.phone || invoice.reservation?.user?.phone,
                    sendingMode
                  )
                }>
                Send now
              </Button>
            </div>
          </div>
        )}
      </div>
      {!isForPrint && (
        <PrintableInvoiceForm
          targetRef={targetRef}
          invoice={invoice}
          camp={camp}
          site={site}
          campingStyle={campingStyle}
          availableDiscounts={availableDiscounts}
        />
      )}
    </div>
  )
}

const PrintableInvoiceForm = ({
  invoice,
  camp,
  site,
  campingStyle,
  availableDiscounts,
  targetRef,
}) => {
  return (
    <div className='hidden'>
      <InvoiceForm
        customerView
        invoice={invoice}
        camp={camp}
        site={site}
        campingStyle={campingStyle}
        availableDiscounts={availableDiscounts}
        isHidingBackButton
        setSelectedInvoice={() => null}
        onSuccess={() => null}
        updateLocalInvoices={() => null}
        disabled
        isProcessing
        isForPrint
        printRef={targetRef}
      />
    </div>
  )
}

const Total = ({
  invoice,
  site,
  camp,
  isCustomerView,
  discounts,
}: {
  invoice: IInvoice
  site: SiteProps
  camp: CampProps
  isCustomerView?: boolean
  discounts: DiscountProps[]
}) => {
  const { watch } = useAdminForm<IFormData>()
  const [preview, setPreview] = useState<IOrderPreview>()
  const [isPreview, setIsPreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const localItems = watch('items')

  const debouncedPreview = useDebouncedCallback((items: ILineItemField[]) => {
    const isPaid = isCustomerView
      ? invoice.status === INVOICE_STATUSES.PAID
      : invoice.status === INVOICE_STATUSES.PAID &&
        localItems.reduce((acc, item) => acc + item.total_amount, 0) ===
          invoice.order.subtotal_in_cents / 100

    const discount = discounts?.find(
      (d) => d.id === items?.find((li) => li.discount_id)?.discount_id
    )

    if (isPaid) {
      api.Orders.orderAndPreviewFor(
        invoice.order.id,
        camp.id,
        invoice.reservation?.selected_camping_style_id || site?.camping_style_id,
        serviceRateMode.payment_link,
        isPaid,
        discount?.slug
      )
        .then(({ order: o, preview: p }) => {
          setPreview(o as IOrderPreview)
          setIsPreview(false)
          setIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
        })
    } else {
      api.Orders.createPreview({
        order_id: invoice.order.id,
        camp_id: camp.id,
        camping_style_id: invoice.reservation?.selected_camping_style_id || site?.camping_style_id,
        is_credit_card_fee_enabled: invoice.order.credit_card_rate > 0,
        is_service_fee_enabled: true,
        service_rate_mode: serviceRateMode.payment_link,
        discount_slug: discount?.slug,
        line_items_attributes: items.map((item) => ({
          id: item.id,
          kind: item.kind,
          label: item.label,
          total_amount_in_cents: item.reservation_id ? Number(item.total_amount) * 100.0 : null,
          unit_amount_in_cents: item.reservation_id ? null : Number(item.unit_amount) * 100.0,
          quantity: item.quantity,
          occurrences: item.occurrences,
          occurrence_unit: item.occurrence_unit,
          is_tax_fee_enabled: item.tax_rate > 0,
          reservation_id: item.reservation_id,
          add_on_id: item.add_on_id,
          is_attached_to_reservation: !!item.parent_line_item_id,
        })),
      })
        .then(({ order }) => {
          setPreview(order.preview)
          setIsPreview(true)
          setIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
        })
    }
  }, 100)

  useEffect(() => {
    setIsLoading(true)
    debouncedPreview(localItems)
  }, [invoice, localItems.reduce((acc, item) => acc + item.total_amount, 0)])

  if (!preview) {
    return <></>
  }

  return (
    <div className=''>
      <OrderSummary order={preview} camp={camp} isPreview={isPreview} isInvoice noBackground />
    </div>
  )
}

export default EditInvoice
