import React, { useContext, useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'

import NewReservationForm from './NewReservationForm'

import { CampProps, ReservationProps, SiteProps, UserProps } from '../../interfaces'
import Button from '../ui/Button'
import { STATUSES, RESERVATION_KINDS, reservationKind, LONG_TERM_RESERVATION_KINDS, longTermReservationKind } from '../../constants'
import Api2 from '../../api2'
import CreateReservationDropdown from './CreateReservationDropdown'
import { UnpaidProps, ReservationPriceDetailsProps } from '../../api2/reservations'
import priceDetailsFor, { PriceDetails } from '../../utils/priceDetailsFor'
import { NewReservationAction } from '../../reducers/newReservation'
import { NewReservationContext } from '../../contexts/admin/NewReservationContext'
import { AdminContext } from '../../contexts/AdminContext'
import Modal from '../ui/Modal'
import IGroupedReservation from '../../interfaces/IGroupedReservation'
import moment from 'moment'
import SelectInput,{Option} from '../ui/SelectInput'
import { sendingOptions } from './sendingOptions'
import eSendingMode from '../../enums/eSendingMode'
import isValidEmail from '../../utils/isValidEmail'
import classNames from '../../utils/classNames'
import { useCookies } from 'react-cookie'
import { sendingModeOptions } from '../Admin/Invoices/EditInvoice'
import CheckboxWithLabel from '../ui/CheckboxWithLabel'
import ReservationDecorator from '../../decorators/ReservationDecorator'
import applyTime from '../../utils/applyTime'
import eSendingModeCookieName from '../../enums/eSendingModeCookieName'

interface ComponentProps {
  isOpenCreateForm: boolean
  closeCreateForm: () => void
  setPriceDetails: (priceDetails: PriceDetails) => void
  setIsLoading
  sites: SiteProps[]
  camp: CampProps
}

const cookieSendingMode = eSendingModeCookieName.NEW_RESERVATION

export const isInvalidSendingMode = (email: string, phone: string, sendingMode: eSendingMode) =>
    (
      [eSendingMode.EMAIL, eSendingMode.EMAIL_AND_TEXT].includes(sendingMode) &&
      !isValidEmail(email)
    ) || (
      [eSendingMode.TEXT, eSendingMode.EMAIL_AND_TEXT].includes(sendingMode) &&
      !phone
    )

const NewReservation = (props: ComponentProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [errorResponse, setErrorResponse] = useState<any>()
  const [isSendingEmail, setIsSendingEmail] = useState(true)
  const [isShowingCc, setIsShowingCc] = useState(false)
  const [rigLengthError, setRigLengthError] = useState<string | null>(null)
  const {
    campingStyles,
    showRefreshModal,
    showPaymentModal,
    updateLocalReservations,
    selectedSendingMode,
    setSelectedSendingMode
  } = useContext(AdminContext)
  const {
    newReservation,
    dispatch,
    inputNotes,
    inputFirstName,
    setInputFirstName,
    inputLastName,
    setInputLastName,
    inputEmail,
    inputPhone,
    setInputPhone,
    inputVehicleRigLength,
    inputVehicleElectric,
    inputVehicleRigType,
    inputVehicleLicense,
    inputVehicleYear,
    inputVehicleMake,
    inputVehicleColor,
    inputAdults,
    inputChildren,
    inputPets,
    isAcceptingDeposit,
    selectedReservationType,
    clearForm,
    selectedSitesForGroup,
    setSelectedSitesForGroup,
    recurringKind,
    address
  } = useContext(NewReservationContext)
  const [cookies, setCookie] = useCookies([cookieSendingMode])

  useEffect(() => {
    if (errorResponse) setErrorResponse(null)
  }, [
    inputNotes,
    inputFirstName,
    inputLastName,
    inputEmail,
    inputPhone,
    setInputPhone,
    inputVehicleRigLength,
    inputVehicleElectric,
    inputVehicleRigType,
    inputVehicleLicense,
    inputVehicleYear,
    inputVehicleMake,
    inputVehicleColor,
    inputAdults,
    inputChildren,
    inputPets,
    isAcceptingDeposit,
    newReservation,
    address
  ])

  useEffect(() => {
    if(props.isOpenCreateForm){
      Object.values(eSendingMode).includes(cookies[cookieSendingMode]) && cookies[cookieSendingMode] && setSelectedSendingMode(cookies[cookieSendingMode])
      !cookies[cookieSendingMode] && setCookie(cookieSendingMode, selectedSendingMode)
    }
  }, [props.isOpenCreateForm])

  const closeModal = () => {
    setErrorResponse(null)
    setSelectedSitesForGroup([])
    dispatch({ type: NewReservationAction.UPDATE_SITE_ID, payload: { siteId: null } })
    props.closeCreateForm()
    clearForm()
  }

  const discountRates = props.camp.discounts.reduce((obj, discount) => {
    const campDiscount = props.camp.camp_discounts.find((x) => x.discount_id == discount.id)
    obj[discount.slug] = { ...campDiscount, name: discount.name }
    return obj
  }, {})

  const onCreateError = (error) => {
    if (!error.status) {
      setErrorResponse(error)
      setIsProcessing(false)
    } else {
      showRefreshModal()
      setErrorResponse(error)
      setIsProcessing(false)
      closeModal()
    }
  }

  type ReservationOptions = {
    isPrePaid?: boolean
    withLink?: boolean
    isPayingNow?: boolean
    isGroupSeparated?: boolean
    recurringKind?: longTermReservationKind
  }

  const createReservation = async (options: ReservationOptions) => {
    const {
      isPrePaid = false,
      withLink = false,
      isPayingNow = false,
      isGroupSeparated = false,
      recurringKind = null,
    } = options
    setIsProcessing(true)

    if (!newReservation.start_date) {
      onCreateError({ message: 'Validation failed: Please select a start date' })
      return
    }

    if (!newReservation.end_date) {
      onCreateError({ message: 'Validation failed: Please select an end date' })
      return
    }

    let siteId = newReservation.site_id
    const isGrouped = selectedReservationType === RESERVATION_KINDS.GROUP
    const isBlocked = selectedReservationType === RESERVATION_KINDS.BLOCKED
    let isMultipleSites = isGrouped || isBlocked
    let siteIds = null // NOTE: only not null if isMultipleSites

    if (isMultipleSites && selectedSitesForGroup.length > 0) {
      siteIds = selectedSitesForGroup.filter((site) => site).map((site) => site.id)
    }

    const site =
      siteIds && siteIds?.length > 0
        ? props.sites.find((s) => s.id === siteIds[0])
        : props.sites.find((s) => s.id === siteId)

    siteId = site.id

    const campingStyle = campingStyles.find((cs) => cs.id === site.camping_style_id)

    const checkinTime = campingStyle?.checkin_time || site?.checkin_time
    const checkoutTime = campingStyle?.checkout_time || site?.checkout_time

    const isHourly = !!campingStyle?.default_hours

    const startDate = isHourly ? newReservation.start_date.toISOString() : applyTime(newReservation.start_date, checkinTime)?.toISOString()
    const endDate = isHourly ? newReservation.end_date.toISOString() : applyTime(newReservation.end_date, checkoutTime)?.toISOString()
    const notes = inputNotes
    const status = newReservation.status
    const userParams = {
      first_name: inputFirstName,
      last_name: inputLastName,
      email: inputEmail,
      phone: inputPhone,
      vehicle_rig_length: inputVehicleRigLength,
      vehicle_electric: inputVehicleElectric,
      vehicle_rig_type: inputVehicleRigType,
      license_plate: inputVehicleLicense,
      vehicle_year: inputVehicleYear,
      vehicle_make: inputVehicleMake,
      vehicle_color: inputVehicleColor,
      adults: inputAdults,
      children: inputChildren,
      pets_info: inputPets,
      address: address
    }
    const isGroup = newReservation.kind === RESERVATION_KINDS.GROUP
    const discountName = newReservation.discount_name
    const passengers = isGroup ? 0 : inputAdults
    const kids = isGroup ? 0 : inputChildren

    let subtotalOverride = null

    if (newReservation.edited_total) subtotalOverride = newReservation.subtotal

    const priceDetailsParams: ReservationPriceDetailsProps = {
      kind: newReservation.kind,
      start_date: startDate,
      end_date: endDate,
      discount_slug: discountName,
      subtotal_override: subtotalOverride,
      is_tax_enabled: newReservation.is_tax_enabled,
      is_holiday_rate_enabled: newReservation.is_holiday_rate_enabled,
      is_weekend_fee_enabled: newReservation.is_weekend_fee_enabled,
      is_passenger_fees_enabled: isGroup ? false : newReservation.is_passenger_fees_enabled,
      is_pets_fee_enabled: newReservation.is_pets_fee_enabled,
      user_electric: inputVehicleElectric,
      number_of_adults: passengers,
      number_of_kids: kids,
      number_of_pets: Number(campingStyle?.extra_pets_price_in_cents) > 0 ? Number(inputPets) : null,
      is_credit_card_fee_enabled: newReservation.is_credit_card_fee_enabled,
      interval_id: newReservation.interval_id,
      // add_ons: data.add_ons, // NOTE: we don't use this in the reservations endpoint
      // is_service_fee_applied: false, // NOTE: we don't use this in the reservations endpoint
    }

    // TODO: refactor this to simplify the params and DRY it up (use above params)
    const priceDetails = (await priceDetailsFor({
      kind: selectedReservationType === RESERVATION_KINDS.RECURRING && (recurringKind === LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY || recurringKind === LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY) ? RESERVATION_KINDS.SEASONAL : newReservation.kind,
      camp: props.camp,
      site: site,
      startDate: newReservation.start_date,
      endDate: newReservation.end_date,
      discountSlug: newReservation.discount_name,
      subtotalOverride: newReservation.edited_total ? newReservation.subtotal : null,
      isTaxableEnabled: newReservation.is_tax_enabled,
      isPassengerFeesEnabled: newReservation.is_passenger_fees_enabled,
      isPetsFeeEnabled: newReservation.is_pets_fee_enabled,
      intervalId: newReservation.interval_id,
      userElectric: inputVehicleElectric,
      numberOfAdults: inputAdults,
      numberOfKids: inputChildren,
      numberOfPets: Number(campingStyle?.extra_pets_price_in_cents) > 0 ? Number(inputPets) : null,
      isHolidayEnabled: newReservation.is_holiday_rate_enabled,
      isWeekendEnabled: newReservation.is_weekend_fee_enabled,
      isCreditCardFeeEnabled: newReservation.is_credit_card_fee_enabled,
    })) as PriceDetails

    const total = priceDetails.total

    let data: UnpaidProps = {
      start_date: startDate,
      end_date: endDate,
      start_time: newReservation.start_time,
      end_time: newReservation.end_time,
      interval_id: newReservation.interval_id,
      notes: notes,
      discount_name: discountName,
      passengers,
      kid_campers: kids,
      vehicle_license_plate: userParams.license_plate,
      sending_mode: selectedSendingMode,
      unpaid_price_in_cents: (discountedSubtotal || newReservation.total_owed_in_cents) * 100,
      // NOTE: don't send the deposit if we're paying now (it'll be added via webhook)
      deposit_in_cents: isPayingNow
        ? null
        : isAcceptingDeposit
        ? newReservation.deposit_in_cents * 100
        : null,
      ...userParams,
      price_details_params: priceDetailsParams,
      admin_email: isShowingCc ? props.camp.email : undefined
    }

    if (selectedReservationType === RESERVATION_KINDS.BLOCKED) {
      Api2.Reservations.createBlocked(siteId, {
        start_date: startDate,
        end_date: endDate,
        interval_id: newReservation.interval_id,
        notes: notes,
        site_ids: siteIds,
      })
        .then(handleCreatedResponse)
        .catch(onCreateError)
    } else if (isPrePaid && selectedReservationType !== RESERVATION_KINDS.RECURRING) {
      Api2.Reservations.createPaid(siteId, {
        ...data,
        unpaid_price_in_cents: total * 100,
        is_grouped: isGrouped,
        site_ids: siteIds,
      })
        .then(handleCreatedResponse)
        .catch(onCreateError)
    } else if (selectedReservationType === RESERVATION_KINDS.RECURRING) {
      {
        Api2.Reservations.createRecurring(siteId, {
          ...data,
          recurring_price_in_cents: total * 100,
          with_payment_link: withLink,
          mark_as_paid: isPrePaid ? true : false,
          kind: recurringKind,
          ...userParams,
        })
          .then((resp) => handleRecurringCreatedResponse(resp, isPayingNow))
          .catch(onCreateError)
      }
    } else {
      {
        Api2.Reservations.createUnpaid(siteId, {
          ...data,
          unpaid_price_in_cents: total * 100,
          with_payment_link: withLink,
          is_grouped: isGrouped,
          site_ids: siteIds,
          is_group_separated: isGroupSeparated,
        })
          .then((resp) => handleCreatedResponse(resp, isPayingNow))
          .catch(onCreateError)
      }
    }
  }

  const handleCreatedResponse = (
    resp: {
      reservation?: ReservationProps
      reservations?: ReservationProps[]
      grouped_reservation?: IGroupedReservation
    },
    openPaymentModal: boolean = false
  ) => {
    const isGroupReservation = !!resp.reservations

    if (!resp.reservation && !isGroupReservation) {
      onCreateError({ message: 'Something went wrong. Please try again.' })
      return
    }

    setErrorResponse(null)
    props.setIsLoading(true)

    if (isGroupReservation) {
      updateLocalReservations(resp.reservations)
    } else {
      dispatch({ type: NewReservationAction.UPDATE_ID, payload: { id: resp.reservation.id } })
      updateLocalReservations([resp.reservation])
    }

    if (openPaymentModal) {
      if (isGroupReservation) {
        if (isAcceptingDeposit) {
          const depositInCents = newReservation.deposit_in_cents * 100.0 // NOTE: not really in cents
          showPaymentModal(
            depositInCents,
            {
              reservations: resp.reservations,
              groupedReservation: resp.grouped_reservation,
              isDeposit: true
            }
          )
        } else {
          showPaymentModal(
            resp.reservations.reduce((acc, r) => {
              const decoratedReservation = new ReservationDecorator(r)
              return acc + (decoratedReservation.remainingOwedBeforeFeesInCents)
            }, 0),
            {
              reservations: resp.reservations,
              groupedReservation: resp.grouped_reservation
            }
          )
        }
      } else if (isAcceptingDeposit) {
        const depositInCents = newReservation.deposit_in_cents * 100.0 // NOTE: not really in cents
        const reservation = { ...resp.reservation }
        showPaymentModal(depositInCents, { reservation, isDeposit: true })
      } else {
        const decoratedReservation = new ReservationDecorator(resp.reservation)
        showPaymentModal(
          (decoratedReservation.remainingOwedBeforeFeesInCents),
          { reservation: resp.reservation }
        )
      }
    }

    closeModal()
    props.setIsLoading(false)
    setIsProcessing(false)
  }

  const handleRecurringCreatedResponse = (resp: { reservations: ReservationProps[] }, isPayingNow: boolean) => {
    setErrorResponse(null)
    closeModal()
    props.setIsLoading(true)

    updateLocalReservations(resp.reservations)

    clearForm()

    if (isPayingNow) {
      const firstRecurringReservation = resp.reservations.sort((a, b) => moment(a.start_date).diff(b.start_date))[0]

      if (isAcceptingDeposit) {
        const depositInCents = newReservation.deposit_in_cents * 100.0 // NOTE: not really in cents
        showPaymentModal(depositInCents, { reservation: firstRecurringReservation, isDeposit: true })
      } else {
        const decoratedReservation = new ReservationDecorator(firstRecurringReservation)
        showPaymentModal(
          decoratedReservation.remainingOwedBeforeFeesInCents,
          { reservation: firstRecurringReservation }
        )
      }
    }

    props.setIsLoading(false)
    setIsProcessing(false)
  }

  const isValid = (): boolean => {
    const res = newReservation

    if (res.kind === RESERVATION_KINDS.GROUP) {
      return !!selectedSitesForGroup?.length
    } else if (res.site_id && res.start_date && res.end_date && res.end_date > res.start_date) {
      return true
    } else {
      return false
    }
  }

  const errorMessageFor = (error) => {
    if (!(error && error.message)) return

    if (error.message.includes('Email has already been taken')) {
      return (
        <>
          <p>
            {error.message}.{' '}
            <a onClick={() => populateUserFieldsFor(error.existing_user)}>Click here</a> if you'd
            like to populate the fields using the existing user:
          </p>
          <pre>
            <code>
              {JSON.stringify(
                error.existing_user,
                ['email', 'first_name', 'last_name', 'phone'],
                2
              )}
            </code>
          </pre>
        </>
      )
    } else {
      return error.message
    }
  }

  const populateUserFieldsFor = (user: UserProps) => {
    setInputFirstName(user.first_name)
    setInputLastName(user.last_name)
    setInputPhone(user.phone)

    setErrorResponse(null)
  }

  const [discountedSubtotal, setDiscountedSubtotal] = useState<number>()

  useEffect(() => {
    !(selectedSendingMode === eSendingMode.EMAIL || selectedSendingMode === eSendingMode.EMAIL_AND_TEXT) && setIsShowingCc(false)
  }, [selectedSendingMode])

  return (
    <div className='pb-16'>
      <Modal maxWidth='max-w-5xl' open={props.isOpenCreateForm} onClose={() => closeModal()}>
        <div>
          <h3 className='text-xl font-semibold mb-3'>New reservation</h3>

          <NewReservationForm isProcessing={isProcessing} errorResponse={errorResponse} rigLengthError={rigLengthError} setRigLengthError={setRigLengthError} />
          <Message error hidden={!errorResponse} content={errorMessageFor(errorResponse)} />
          <div className='flex justify-end items-center md:items-end pt-4 md:pt-6 border-t border-gray-200 flex-col md:flex-row'>
            {selectedReservationType !== 'blocked' && (
              <div className='md:mr-4 flex flex-col-reverse md:flex-row-reverse items-center justify-center mb-3 md:mb-0'>
                <SelectInput
                  name='sendingOptions'
                  large
                  buttonSize='large'
                  width='w-72'
                  isBottomPlacement
                  options={sendingOptions}
                  selected={sendingOptions.find((o) => o.value === selectedSendingMode)}
                  setSelected={(o: Option) => {
                    setSelectedSendingMode(o.value)
                    setCookie(cookieSendingMode, o.value)
                  }}
                  warningText={isInvalidSendingMode(inputEmail, inputPhone, selectedSendingMode) && (
                    <div className='text-xs text-yellow-600'>
                      {selectedSendingMode === eSendingMode.EMAIL
                        ? 'Will not send without a valid email'
                        : selectedSendingMode === eSendingMode.TEXT
                        ? 'Will not send without a valid phone number'
                        : 'Will not send without a valid email & phone number'
                      }
                    </div>
                  )}
                />
                {(selectedSendingMode === eSendingMode.EMAIL || selectedSendingMode === eSendingMode.EMAIL_AND_TEXT) && <div className='flex items-center md:mr-3 mb-2 md:mb-0'>
                  <CheckboxWithLabel
                    label={`cc ${props.camp.email}`}
                    value={isShowingCc}
                    setValue={setIsShowingCc}
                    labelClasses='text-sm whitespace-nowrap'
                  />
                </div>}
              </div>
            )}
            <div className="flex">
              <Button
                variant='gray'
                size='large'
                disabled={isProcessing}
                className='mr-1.5 text-sm'
                onClick={() => {
                  setErrorResponse(null)
                  closeModal()
                }}>
                Cancel
              </Button>
              {selectedReservationType === STATUSES.BLOCKED ? (
                <Button
                  variant='blue'
                  size='large'
                  disabled={isProcessing || errorResponse}
                  onClick={() => {
                    createReservation({})
                  }}>
                  Create blocker
                </Button>
              ) : (
                <CreateReservationDropdown
                  reservationData={newReservation}
                  disabled={!isValid() || isProcessing || errorResponse || rigLengthError?.length}
                  createReservation={createReservation}
                  selectedSendingMode={selectedSendingMode}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NewReservation
