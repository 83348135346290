import { validPaymentModes } from "../types"
import { destroy, post } from "./utils/requests"

/** Initiates the payment processing on the specified reader */
const processPayment = (camp_id: string, payment_intent_id: string) =>
  post('/api/terminal/process_payment', { camp_id, payment_intent_id })

const cancelCurrentPayment = (camp_id: string, payment_intent_id?: string) =>
  destroy('/api/terminal/cancel_current_payment', { camp_id, payment_intent_id })

const checkPaymentStatus = (camp_id: string, payment_intent_id: string) => post('/api/terminal/check_payment_status', { camp_id, payment_intent_id })

/** Captures the amount from a successful card read */
const capturePaymentIntent = (camp_id: string, payment_intent_id: string) =>
  post('/api/terminal/capture_payment_intent', { camp_id, payment_intent_id })

const Terminal = {
  processPayment,
  cancelCurrentPayment,
  capturePaymentIntent,
  checkPaymentStatus,
}

export default Terminal
