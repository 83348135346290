import { ArrowRightIcon, ArrowSmallDownIcon, ArrowSmallUpIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import React from 'react'
import classNames from '../../utils/classNames'

interface ComponentProps {
  name: string
  slug: 'reservations' | 'sites' | 'rent-roll' | 'transactions' | 'tax-collected' | string
  stat: string
  icon: Function
  change?: number
  changeType?: 'number' | 'percentage'
  setSelectedReport: (string) => void
  setPage: (number) => void
}

const ReportButton = (props: ComponentProps) => {
  const changeIsPositive = props.change >= 0

  return (
    <div
      className='flex items-center justify-between bg-white hover:bg-gray-75 hover:cursor-pointer py-5.5 px-6 sm:py-9 sm:px-8 border shadow-pk-card border-gray-200 rounded-lg overflow-hidden'
      onClick={() => {
        props.setSelectedReport(props.slug);
        props.setPage(1);
      }}>
      <div className='relative'>
        <dt>
          <div className='absolute bg-green-500 rounded-md p-3.5'>
            <props.icon className='h-7 w-7 text-white' aria-hidden='true' />
          </div>
          <p className='ml-20 text-gray-600 truncate text-lg'>{props.name}</p>
        </dt>
        <dd className='ml-20 flex items-baseline'>
          <p className='text-1.5xl font-semibold text-gray-900'>{props.stat}</p>
          {props.change !== undefined && (
            <p
              className={classNames(
                changeIsPositive ? 'text-green-600' : 'text-red-600',
                'ml-2 flex items-baseline text-sm font-semibold'
              )}>
              {changeIsPositive ? (
                <ArrowSmallUpIcon
                  className='self-center flex-shrink-0 h-5 w-5 text-green-500'
                  aria-hidden='true'
                />
              ) : (
                <ArrowSmallDownIcon
                  className='self-center flex-shrink-0 h-5 w-5 text-red-500'
                  aria-hidden='true'
                />
              )}

              <span className='sr-only'>{changeIsPositive ? 'Increased' : 'Decreased'} by</span>
              {props.change}
            </p>
          )}
        </dd>
      </div>
      <ChevronRightIcon
        className='flex-shrink-0 ml-2 h-6 w-6 text-gray-400 -mr-2'
        aria-hidden='true'
      />
    </div>
  )
}

export default ReportButton
