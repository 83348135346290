import { ReservationProps } from '../interfaces'
import ICamper from '../interfaces/ICamper'

class CamperDecorator {
  id: string
  email: string
  first_name: string
  last_name: string
  phone: string
  address: string
  company_name: string
  default_adults_count: number
  default_kids_count: number
  default_pets_info: string
  license_plate: string
  vehicle_year: string
  vehicle_make: string
  vehicle_color: string
  vehicle_rig_type: string
  vehicle_rig_length: string
  vehicle_electric: string
  pets_info: string
  notes: string
  blocked_at: string

  emergency_contact_name: string
  emergency_contact_relationship: string
  emergency_contact_email: string
  emergency_contact_phone: string

  additional_vehicle_make: string
  additional_vehicle_year: number
  additional_vehicle_color: string
  additional_vehicle_license_plate: string

  // virtual attributes
  archived_reservations?: ReservationProps[]
  last_stay_date: string
  num_stays: number
  num_nights: number
  reservations?: ReservationProps[]
  spent: number

  constructor(public camper: ICamper) {
    Object.assign(this, camper)
  }

  get formattedName() {
    let ret = ''

    if (this.camper.first_name != null) {
      ret += this.camper.first_name + ' '
    }

    if (this.camper.last_name != null) {
      ret += this.camper.last_name
    }

    if (!this.camper.first_name && !this.camper.last_name) {
      ret = this.camper.email
    }

    return ret
  }

  get isBlocked() {
    return this.camper.blocked_at != null
  }
}

export default CamperDecorator
