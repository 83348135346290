import React, { MutableRefObject, useState } from 'react'
import { titleCase } from '../../../utils'
import classNames from '../../../utils/classNames'
import { RefCallBack, UseFormRegister } from 'react-hook-form'

const TextAreaWithLabel = ({
  rows,
  ref,
  label,
  name,
  value,
  defaultValue,
  disabled,
  className,
  onError,
  onChange,
  onBlur,
  style,
  full,
  register,
  autoComplete,
}: {
  rows?: number
  ref?: MutableRefObject<any> | RefCallBack
  label?: string
  name?: string
  value?: any
  defaultValue?: any
  disabled?: boolean
  className?: string
  onError?: (err) => any
  onChange?: (e) => any
  onBlur?: (e) => any
  style?: any
  full?: boolean
  register?: UseFormRegister<any>
  autoComplete?: string
}) => {
  const [bgColorClass, setBgColorClass] = useState('bg-white')

  return (
    <div className={classNames(full && 'w-full', className)}>
      <label htmlFor='name' className='block font-medium text-md text-gray-900'>
        {label || titleCase(name)}
      </label>
      <div className='mt-1'>
        <textarea
          ref={ref}
          rows={rows || 3}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          id={name}
          autoComplete={autoComplete}
          onError={onError}
          onChange={(e) => {
            if (defaultValue !== undefined && e.target.value !== defaultValue) {
              setBgColorClass('bg-blue-50')
            } else {
              setBgColorClass('bg-white')
            }

            if (onChange) onChange(e)
          }}
          onBlur={onBlur}
          style={style}
          className={classNames(
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700',
            bgColorClass
          )}
          {...(register ? register(name, {}) : {})}
        />
      </div>
    </div>
  )
}

export default TextAreaWithLabel
