const dynamicRound = (num) => {

  // Convert the number to a string
  let strNum = num.toString()
  let decimalIndex = strNum.indexOf('.')

  // Handle case where there is no decimal in number
  if (decimalIndex === -1) {
    return num
  }

  let nonZeroFound = false
  let precision = 0
  for (let i = decimalIndex + 1; i < strNum.length; i++) {
    if (strNum[i] !== '0') {
      nonZeroFound = true
    }
    if (nonZeroFound) {
      precision++
    }
    if (nonZeroFound && strNum[i] === '0') {
      break
    }
  }

  return Number(num.toFixed(precision))
}
export default dynamicRound
