import { post } from "./utils/requests"

const login = async (data: { email: string, password: string }) => post("/admin/login", data)
const logout = async () => post("/admin/logout")

const Sessions = {
  login,
  logout
}

export default Sessions
