import TextAreaInput from './TextAreaInput'
import TextInput from './TextInput'
import NumberInput from './NumberInput'
import SelectInput from './SelectInput'
import RichTextInput from './RichTextInput'
import DollarFieldWithLabel from './DollarFieldWithLabel'
import PercentFieldWithLabel from '../../PercentFieldWithLabel'

const Form = {
  NumberInput,
  RichTextInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  DollarFieldWithLabel,
  PercentFieldWithLabel
}

export default Form
