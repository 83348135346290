import React, { useContext, useState } from 'react'
import { Divider, Dropdown, Form, Header, Select } from 'semantic-ui-react'
import { CAMPING_STYLES, ELECTRICS, PREREQUISITES } from '../../constants'
import { CampingStyleProps, CampProps, PrerequisteProps } from '../../interfaces'
import { validElectric, validRigType } from '../../types'
import MaxRigLengthField from './prerequisites/MaxRigLengthField'
import ElectricField from './prerequisites/ElectricField'
import RigTypeField from './prerequisites/RigTypeField'
import PassengersField from './prerequisites/PassengersField'
import Button from '../ui/Button'
import { BookerContext } from '../../contexts/BookerContext'
import pluralize from '../../utils/pluralize'

interface ComponentProps {
  camp: CampProps
  campingStyle: CampingStyleProps
  prerequisites: PrerequisteProps[]
  setUserRigType
  setUserRigLength
  setUserAmps
  setUserPassengers: (v: number) => void
  setUserKidCampers: (v: number) => void
  setShowPrerequisites
}

const Prerequisites = (props: ComponentProps) => {
  const bookerContext = useContext(BookerContext)
  const { camp, campingStyle, secondaryColor, isKidsPrereqPresent, userRigYear, setUserRigYear } = bookerContext

  const isForBoat = bookerContext.campingStyle.name === CAMPING_STYLES.BOAT_SLIP
  const siteType = isForBoat ? 'boat slip' : 'site'

  const [userRigType, setUserRigType] = useState<validRigType>()
  const [userRigLength, setUserRigLength] = useState(isForBoat ? 20 : 25)
  const [userAmps, setUserAmps] = useState<validElectric>(ELECTRICS.E30)
  const [userPassengers, setUserPassengers] = useState<number>()
  const [userKidCampers, setUserKidCampers] = useState<number>()
  const [userPets, setUserPets] = useState<number>()

  const requiredFields = props.prerequisites.map((pr) => pr.kind)

  const handleRigTypeChange = (e, { value }) => setUserRigType(value)
  const handleRigLengthChange = (e, { value }) => setUserRigLength(value)
  const handleAmpsChange = (e, { value }) => setUserAmps(value)
  const handlePassengersChange = (e, { value }) => setUserPassengers(value)
  const handleKidsCampersChange = (e, { value }) => setUserKidCampers(value)

  const handleSubmit = () => {
    if (requiredFields.includes(PREREQUISITES.RIG_TYPE)) props.setUserRigType(userRigType)
    if (requiredFields.includes(PREREQUISITES.ELECTRIC)) props.setUserAmps(userAmps)
    if (requiredFields.includes(PREREQUISITES.MAX_RIG_LENGTH)) props.setUserRigLength(userRigLength)
    if (requiredFields.includes(PREREQUISITES.PASSENGERS)) props.setUserPassengers(userPassengers)
    if (requiredFields.includes(PREREQUISITES.PASSENGERS) && isKidsPrereqPresent) {
      props.setUserKidCampers(userKidCampers)
    }
    if (requiredFields.includes(PREREQUISITES.PETS)) bookerContext.setUserPets(userPets)
    if (requiredFields.includes(PREREQUISITES.RIG_YEAR)) bookerContext.setUserRigYear(userRigYear)

    props.setShowPrerequisites(false)
  }

  const isValid = () => {
    if (requiredFields.includes(PREREQUISITES.RIG_TYPE) && !userRigType) return false
    if (requiredFields.includes(PREREQUISITES.MAX_RIG_LENGTH) && !userRigLength) return false
    if (requiredFields.includes(PREREQUISITES.ELECTRIC) && !userAmps) return false
    if (requiredFields.includes(PREREQUISITES.PASSENGERS) && !userPassengers) return false
    if (
      requiredFields.includes(PREREQUISITES.PASSENGERS) &&
      isKidsPrereqPresent &&
      userKidCampers === null
    ) {
      return false
    }
    if (requiredFields.includes(PREREQUISITES.PETS) && !userPets && !Number.isInteger(userPets)) return false
    if (
      requiredFields.includes(PREREQUISITES.RIG_YEAR) &&
      (!userRigYear ||
      userRigYear < campingStyle.minimum_rig_year)
    ) {
      return false
    }

    return true
  }

  return (
    <>
      <Divider hidden />
      <p className='text-lg pt-2 pb-4 font-medium'>
        Please fill out the following so we can find you the perfect {siteType}
      </p>
      {/* <p>
        Please provide the following information so we can find you the perfect {siteType} for your setup.
      </p> */}

      <Divider hidden />

      <Form>
        {requiredFields.includes(PREREQUISITES.RIG_TYPE) && (
          <RigTypeField userRigType={userRigType} handleRigTypeChange={handleRigTypeChange} />
        )}
        {requiredFields.includes(PREREQUISITES.MAX_RIG_LENGTH) && (
          <MaxRigLengthField
            userRigLength={userRigLength}
            handleRigLengthChange={handleRigLengthChange}
            isBoat={isForBoat}
            isCamperLength={props.camp.is_camper_length}
            sites={campingStyle.sites}
          />
        )}
        {requiredFields.includes(PREREQUISITES.RIG_YEAR) && (
          <Form.Field required>
            <label>Vehicle year</label>
            <Select
              search
              fluid
              options={[null]
                .concat(
                  Array.from(
                    new Array(new Date().getFullYear() + 1 - 1950),
                    (x, i) => i + 1950
                  ).reverse()
                )
                .map((year) => {
                  return { key: year, value: year, text: year }
                })}
              value={userRigYear}
              onChange={(e, { value }) => setUserRigYear(value as number)}
            />
          </Form.Field>
        )}
        {requiredFields.includes(PREREQUISITES.ELECTRIC) && (
          <ElectricField
            sites={props.camp.sites}
            userAmps={userAmps}
            handleAmpsChange={handleAmpsChange}
          />
        )}
        {requiredFields.includes(PREREQUISITES.PASSENGERS) && (
          <PassengersField
            camp={props.camp}
            userPassengers={userPassengers}
            handlePassengersChange={handlePassengersChange}
            userKidCampers={userKidCampers}
            handleKidCampersChange={handleKidsCampersChange}
          />
        )}
        {requiredFields.includes(PREREQUISITES.PETS) && (
          <Form.Field required>
            <label>Number of pets</label>
            <div className='text-xs text-gray-400 -mt-1 mb-1'>
              {campingStyle.pets_capacity &&
                `A maximum of ${campingStyle.pets_capacity} ${pluralize(
                  'pet',
                  campingStyle.pets_capacity
                )} allowed`}
            </div>
            <Select
              fluid
              search
              options={[null]
                .concat(
                  Array.from(
                    new Array(campingStyle.pets_capacity ? campingStyle.pets_capacity + 1 : 6),
                    (x, i) => i
                  )
                )
                .map((pet) => {
                  return { key: pet, value: pet, text: pet }
                })}
              value={userPets}
              onChange={(e, { value }) => setUserPets(value as number)}
            />
          </Form.Field>
        )}
        <div className='flex justify-start items-center'>
          <Button
            style={{ backgroundColor: secondaryColor }}
            className='hover:opacity-80 text-white'
            size='large'
            disabled={!isValid()}
            onClick={handleSubmit}>
            Next
          </Button>
          {userRigYear < campingStyle.minimum_rig_year && (
            <span className='ml-4 text-gray-400'>
              For vehicles made before {campingStyle.minimum_rig_year}, please call us at{' '}
              {camp.phone}
            </span>
          )}
        </div>
      </Form>
    </>
  )
}

export default Prerequisites
