import { useEffect, useState } from "react"
import { CampProps, CampingStyleProps } from "../interfaces"
import { useCookies } from "react-cookie"
import api from "../api2"
import * as Sentry from "@sentry/react"

const cookieCurrentCampKey = 'park-admin-current-camp-id'

const useAdminCamp = (
  availableCamps: CampProps[],
  fallbackCamp: CampProps
): [CampProps, CampingStyleProps[], (v: CampProps) => void, (v: CampingStyleProps[]) => void] => {
  const [camp, setCamp] = useState<CampProps>()
  const [cookies, setCookie] = useCookies([cookieCurrentCampKey])
  const [campingStyles, setCampingStyles] = useState<CampingStyleProps[]>()

  useEffect(() => {
    const defaultCamp = availableCamps[0] || fallbackCamp

    if (cookies[cookieCurrentCampKey] && defaultCamp.id !== cookies[cookieCurrentCampKey]) {
      const selectedCamp = availableCamps.find((c) => c?.id === cookies[cookieCurrentCampKey])
      setCamp(selectedCamp || defaultCamp)
    } else {
      setCamp(availableCamps[0] || defaultCamp)
    }
  }, [])

  useEffect(() => {
    if (!camp) return

    setCookie(cookieCurrentCampKey, camp.id)

    api.Me.camping_styles_for_camp(camp.id)
      .then((camping_styles) => {
        setCampingStyles(camping_styles)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
      })
  }, [camp])

  return [camp, campingStyles, setCamp, setCampingStyles]
}

export default useAdminCamp
