import React from 'react'
import classNames from '../../../utils/classNames'

interface ComponentProps {
  color?: 'green' | 'red' | 'blue' | 'yellow' | 'gray'
  children: React.ReactNode
}

const Badge = (props: ComponentProps) => {
  const colorClass = props.color
    ? `bg-${props.color}-50 text-${props.color}-700 border-${props.color}-200`
    : 'bg-gray-50 text-gray-700 border-gray-200'

  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border',
        colorClass
      )}
    >
      {props.children}
    </span>
  )
}

export default Badge
