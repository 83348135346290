import { post } from "./utils/requests"

interface IData {
  camp_id: string
  camping_style_id: string
  start_date: string
  end_date: string
}

const forDates = (data: IData) => post('/api/date_availabilities', { ...data })

const DateAvailabilities = {
  forDates
}

export default DateAvailabilities
