import React, { useEffect } from "react"
import { ReservationProps } from "../../interfaces"
import IInvoice from "../../interfaces/IInvoice"
import IOrder from "../../interfaces/IOrder"
import api from "../../api2"
import { useDebouncedCallback } from "use-debounce"
import moment from "moment"
import * as Sentry from "@sentry/react"
import { INVOICE_STATUSES } from "../../decorators/InvoiceDecorator"
import { STATUSES } from "../../constants"
import Spinner from "../ui/Spinner"
import { formatName } from "../../utils/formatName"

interface ComponentProps {
  reservation: ReservationProps
  order: IOrder
  children: (
    renderProps: {
      invoice: IInvoice | null,
      setInvoice: React.Dispatch<React.SetStateAction<IInvoice>>
    }
  ) => JSX.Element
}

const ReservationInvoiceWrapper = ({ reservation, order: propOrder, children }: ComponentProps) => {
  const [order, setOrder] = React.useState<IOrder>(propOrder)
  const [invoice, setInvoice] = React.useState<IInvoice>()
  const [isLoading, setIsLoading] = React.useState(false)

  const debouncedOrder = useDebouncedCallback(() => {
    api.Orders.fetch(reservation?.order_id)
      .then(({ order: o }) => {
        setOrder(o)
      })
      .catch((err) => {
        console.error(`Error loading order for reservation ${reservation?.id}`, err)
      })
  }, 100)

  const debouncedInvoiceForOrder = useDebouncedCallback(() => {
    if (!order || !reservation || !reservation.start_date) return

    api.Invoices.fetchForDates(
      order.camp_id,
      {
        from: moment(reservation.start_date).subtract(1, "day"),
        until: moment(reservation.start_date).add(1, "day"),
        filter: 'reservation_start_date',
        include_single_reservations: true,
        includeArchived: true
      }
    )
      .then(({ invoices }) => {
        const invoice = invoices.find(i => i.order_id === order.id)
        if (invoice) {
          setInvoice(invoice)
        } else {
          setDummyInvoice()
        }

        setIsLoading(false)
      })
      .catch((err) => {
        console.error(`Error loading invoice for order ${order.id}`, err)
        setDummyInvoice()

        setIsLoading(false)
      })
  }, 100)

  const setDummyInvoice = () => {
    Sentry.captureMessage(`Setting dummy invoice for reservation ${reservation?.id}`)

    let invoiceStatus = INVOICE_STATUSES.DRAFT

    if (order && order.status === "paid") {
      invoiceStatus = INVOICE_STATUSES.PAID
    } else if (reservation?.status === STATUSES.PAID) {
      invoiceStatus = INVOICE_STATUSES.PAID
    }

    setInvoice({
      id: null,
      send_date: null,
      due_date: reservation?.start_date,
      hash_id: reservation?.hashed_id,
      is_auto_send: false,
      recipient_email: reservation?.user?.email,
      recipient_phone: reservation?.user?.phone,
      recipient_name: formatName(reservation?.user),
      recipient_address: reservation?.user?.address,
      memo: null,
      deleted_at: null,
      order_id: order.id,
      order: order,
      reservation: reservation, 
      status: invoiceStatus,
      invoice: null
    })
  }

  useEffect(() => {
    setIsLoading(true)

    if (!order) {
      debouncedOrder()
    } else {
      if (!invoice) {
        debouncedInvoiceForOrder()
      }
    }
  }, [order])

  if (!reservation || !order || !invoice || isLoading) {
    return (
      <div className='flex flex-col items-center justify-center w-72 sm:w-96 h-96 sm:h-72'>
        <Spinner size={5} />
        <p className="mt-2 text-sm text-gray-500">Loading invoice details...</p>
      </div>
    )
  }

  return children({ invoice, setInvoice })
}

export default ReservationInvoiceWrapper
