import React, { useEffect, useState } from 'react'
import { CampingStyleProps, CampProps, ReservationProps, SiteProps } from '../interfaces'
import Reservations from './Reservations/Reservations'
import CheckinsToday from './ToDos/ToDos'
import Reports from './Admin/Reports/Reports'
import Notification, { NotificationContainer } from './ui/Notification'
import Navbar from './Admin/Navigation/Navbar'
import { AdminProvider } from '../contexts/AdminProvider'
import api from '../api2'
import * as Sentry from '@sentry/react'
import { CookiesProvider, useCookies } from 'react-cookie'
import useLogRocket from '../hooks/useLogRocket'
import Settings from './Admin/Settings'
import Campers from './Admin/Campers/Campers'
import InvoicesPage from './Admin/Invoices/InvoicesPage'
import { adminPages } from '../types/adminPages'
import useAdminCamp from '../hooks/useAdminCamp'
import Spinner from './ui/Spinner'
import { ADMIN_PAGES } from '../constants'
import StoreContent from './Admin/Store/StoreContent'
import classNames from '../utils/classNames'

interface ComponentProps {
  camp: CampProps
  camps: CampProps[]
  sites: SiteProps[]
  camping_styles: CampingStyleProps[]
  current_user_id: string
  current_user_email: string
  current_user_role: string
}

const Admin = (props: ComponentProps) => {
  const identifyLogRocketUser = useLogRocket()
  const [camp, campingStyles, setCamp, setCampingStyles] = useAdminCamp(props.camps, props.camp)

  // NOTE: do we still need this if we're loading it from the camp?
  const [sites, setSites] = useState(props.sites)
  const [activeItem, setActiveItem] = useState<adminPages>('reservations')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showNewUpdatesMessage, setShowNewUpdatesMessage] = useState(false)
  const [newUpdateMessage, setNewUpdateMessage] = useState('')
  const [notificationReservation, setNotificationReservation] = useState<ReservationProps>()
  const [showLiveUpdatesDisconnected, setShowLiveUpdatesDisconnected] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState<ReservationProps>()

  useEffect(() => {
    newUpdateMessage ? setShowNewUpdatesMessage(true) : setShowNewUpdatesMessage(false)
  }, [newUpdateMessage])

  useEffect(() => {
    setActiveItem('reservations')
  }, [selectedReservation])

  useEffect(() => {
    if (!camp) return

    identifyLogRocketUser(
      {
        id: props.current_user_id,
        email: props.current_user_email
      },
      camp.name
    )

    api.Me.sites_for_camp(camp.id)
      .then((sites) => {
        setSites(sites)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
        setErrorMessage('There was a problem loading sites for this camp. Please reload the page.')
      })
  }, [camp])

  if (!(camp && campingStyles)) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <Spinner size={5} />
        <div className=''>Loading your camp...</div>
      </div>
    )
  }

  return (
    <CookiesProvider>
      <AdminProvider
        currentUserEmail={props.current_user_email}
        currentUserRole={props.current_user_role}
        camp={camp}
        setCamp={setCamp}
        sites={sites}
        setSites={setSites}
        campingStyles={campingStyles}
        setCampingStyles={setCampingStyles}
        selectedReservation={selectedReservation}
        setSelectedReservation={setSelectedReservation}
        currentPage={activeItem}
        setCurrentPage={setActiveItem}>
        <Navbar />
        <NotificationContainer>
          <Notification
            visible={showSuccessMessage}
            success
            title='Changes Saved!'
            description='The reservations you modified were successfully updated.'
            onDismiss={() => setShowSuccessMessage(false)}
          />
          <Notification
            visible={showNewUpdatesMessage}
            title='Online Reservation Booked!'
            description={newUpdateMessage}
            onDismiss={() => {
              setNewUpdateMessage('')
              setNotificationReservation(null)
            }}
            dismissAfter={10000}
            onClick={() => setSelectedReservation(notificationReservation)}
          />
          <Notification
            visible={showLiveUpdatesDisconnected}
            iconWarning
            title='Live Updates Disconnected'
            description="Live updates are disabled because you're disconnected. Try to reload the page to see updates and reconnect."
            refreshButton
            onDismiss={() => setShowLiveUpdatesDisconnected(false)}
            dismissAfter={15000}
          />
          <Notification
            visible={!!errorMessage}
            error
            title='Oops!'
            description={errorMessage}
            onDismiss={() => setErrorMessage('')}
            dismissAfter={10000}
          />
        </NotificationContainer>
        <div
          className={classNames(
            'mt-4 flex flex-grow justify-center px-4 lg:px-10 xl:px-24 2xl:px-36 sm:pb-4 pb-0',
            activeItem == 'store' && 'overflow-hidden' // Disables scroll bar for this page
          )}>
          {activeItem == 'reservations' && (
            <Reservations
              key={camp.id}
              sites={sites}
              setSites={setSites}
              showSuccessMessage={() => setShowSuccessMessage(true)}
              setErrorMessage={setErrorMessage}
              setNewUpdateMessage={setNewUpdateMessage}
              setNotificationReservation={setNotificationReservation}
              setShowLiveUpdatesDisconnected={setShowLiveUpdatesDisconnected}
              currentUserRole={props.current_user_role}
            />
          )}
          {activeItem == 'to-dos' && (
            <CheckinsToday
              key={camp.id}
              camp={camp}
              sites={sites}
              setSelectedReservation={setSelectedReservation}
              setPage={setActiveItem}
            />
          )}
          {activeItem == 'reports' && <Reports key={camp.id} camp={{ ...camp, sites: sites }} />}
          {activeItem == 'guests' && <Campers key={camp.id} />}
          {activeItem == ADMIN_PAGES.STORE && <StoreContent />}
          {activeItem == 'settings' && <Settings key={camp.id} />}
          {activeItem == 'invoices' && (
            <InvoicesPage
              key={camp.id}
              setPage={setActiveItem}
              setSelectedReservation={setSelectedReservation}
            />
          )}
        </div>
      </AdminProvider>
    </CookiesProvider>
  )
}

export default Admin
