import eSendingMode from "../enums/eSendingMode"
import { post } from "./utils/requests"

interface ISendData {
  to: string[]
  cc?: string[]
  from: string
  subject: string
  text: string
  reservation_id?: string
  sending_mode: eSendingMode
  admin_email: string
  reservation_ids?: string[]
  invoice_ids?: string[]
  is_including_invoice: boolean
  is_including_reservation_details: boolean
  is_including_payment_link: boolean
  camp_id?: string
}

const sendEmail = (data: ISendData) => post('/api/messages', { ...data, format: 'email' })

const Messages = {
  sendEmail
}

export default Messages
