import React, { useContext, useState } from 'react'
import { Accordion, Button, Grid, Header, Icon, Image, Loader, Select } from 'semantic-ui-react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Site from './Site'
import { CampingStyleProps, CampProps, SiteProps } from '../../interfaces'
import {
  validRigType,
  validPrerequisiteKind,
  validElectric,
  validCampingStyles,
  validKinds,
} from '../../types'
import RigTypeField from './prerequisites/RigTypeField'
import { CAMPING_STYLES, ELECTRICS, KINDS, PREREQUISITES } from '../../constants'
import { isMobile } from 'react-device-detect'
import electricOptions from './prerequisites/electricOptions'
import sortSites from '../../utils/sortSites'
import { BookerContext } from '../../contexts/BookerContext'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import createMarkup from '../../utils/createMarkup'

const images = require.context('../../../assets/images', true, /^.*-map\..*$/)

const removeQueryParam = (param: string) => {
  // Use URLSearchParams to manipulate query parameters
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete(param)

  // Create new URL object
  const url = new URL(window.location as unknown as string)
  url.search = urlParams.toString()

  // Use history API to update the URL without reloading
  window.history.pushState({}, '', url.toString())
}

interface ComponentProps {
  setActiveSite: any
  camp: CampProps
  userCampingStyle: CampingStyleProps
  nights: number
  sites: SiteProps[]
  setShowDatesCard
  loading: boolean

  userRigType: validRigType
  setUserRigType: (v: validRigType) => any
  userRigLength: number
  setUserRigLength
  userAmps: validElectric
  setUserAmps
}

const Sites = (props: ComponentProps) => {
  const sites: SiteProps[] = props.sites
  const [isActive, setIsActive] = useState(true)

  const { groupedReservationId, setGroupedReservationId, campingStyle } = useContext(BookerContext)

  const handleFormClick = () => {
    setIsActive(!isActive)
  }

  const isBoat = props.userCampingStyle.name === CAMPING_STYLES.BOAT_SLIP
  const suffix = isBoat ? 'ft Boat' : 'ft Rig'
  const isMapPresent = campingStyle.map_url || props.camp.map_url

  let rigLengthOptions: Array<{ key: number; value: number; text: string }> = []

  if (isBoat) {
    rigLengthOptions = [null].concat(Array.from(new Array(34), (x, i) => i + 5)).map((x) => {
      return { key: x, value: x, text: x ? `${x} ${suffix}` : '' }
    })
  } else {
    rigLengthOptions = Array.from(new Array(59), (x, i) => i + 12).map((x) => {
      return { key: x, value: x, text: x ? `${x} ${suffix}` : '' }
    })
  }

  const isFilteringFor = (kind: validPrerequisiteKind): boolean => {
    const requiresRigLength = props.camp.prerequisites.map((pr) => pr.kind).includes(kind)

    let isFilterValuePresent

    if (kind == PREREQUISITES.MAX_RIG_LENGTH) isFilterValuePresent = !!props.userRigLength
    else if (kind == PREREQUISITES.ELECTRIC) isFilterValuePresent = !!props.userAmps
    else if (kind == PREREQUISITES.RIG_TYPE) isFilterValuePresent = !!props.userRigType

    return requiresRigLength && isFilterValuePresent
  }

  const renderSites = () =>
    sites
      .filter((site) => site.is_available)
      .sort((a, b) => sortSites(a, b, props.userCampingStyle))
      .map((site) => {
        return (
          <Site
            key={site.id}
            nights={props.nights}
            site={site}
            isAvailable={site.is_available}
            setActiveSite={props.setActiveSite}
            userElectric={props.userAmps}
          />
        )
      })

  const renderedMap =
    isMobile || !props.camp.is_zoom_enabled ? (
      <Image
        src={props.userCampingStyle.map_url || props.camp.map_url}
        alt='No map available.'
        fluid
      />
    ) : (
      <TransformWrapper>
        <TransformComponent wrapperStyle={{ width: '100%' }} contentStyle={{ width: '100%' }}>
          <Image
            src={props.userCampingStyle.map_url || props.camp.map_url}
            alt='No map available.'
            fluid
          />
        </TransformComponent>
      </TransformWrapper>
    )

  return (
    <>
      <div className='flex items-center gap-2'>
        {props.userCampingStyle.prerequisites.length > 0 ? (
          <>
            <span className='font-semibold flex-shrink-0'>Sites for</span>
            {isFilteringFor(PREREQUISITES.RIG_TYPE) && (
              <div className='w-44'>
                <RigTypeField
                  userRigType={props.userRigType}
                  handleRigTypeChange={(e, { value }) => props.setUserRigType(value)}
                  label={false}
                />
              </div>
            )}
            {isFilteringFor(PREREQUISITES.MAX_RIG_LENGTH) && (
              <div className='w-44'>
                <Select
                  fluid
                  className='sites-filter-dropdown'
                  options={rigLengthOptions}
                  value={props.userRigLength}
                  onChange={(e, { value }) => props.setUserRigLength(value)}
                />
              </div>
            )}
            {isFilteringFor(PREREQUISITES.ELECTRIC) && (
              <div className='w-44'>
                <Select
                  fluid
                  className='sites-filter-dropdown'
                  options={electricOptions(
                    props.camp.sites.filter(
                      (site) => site.camping_style_id == props.userCampingStyle.id
                    )
                  )}
                  value={props.userAmps}
                  onChange={(e, { value }) => props.setUserAmps(value)}
                />
              </div>
            )}
          </>
        ) : (
          <b>Map</b>
        )}
      </div>

      <Grid stackable columns={isMapPresent ? 2 : 1}>
        {isMapPresent && (
          <>
            <Grid.Column only='mobile' id='columnStyle'>
              <Accordion fluid>
                <Accordion.Title active={isActive} index={0} onClick={handleFormClick}>
                  <Icon name='dropdown' /> <b>Map</b>
                </Accordion.Title>
                <Accordion.Content active={isActive}>{renderedMap}</Accordion.Content>
              </Accordion>
            </Grid.Column>
            <Grid.Column only='tablet computer'>
              <div className='mt-4'>{renderedMap}</div>
            </Grid.Column>
          </>
        )}

        {props.loading ? (
          <Loader active />
        ) : renderSites().length > 0 ? (
          <>
            <Grid.Column only='mobile' id='columnStyle'>
              {props.userCampingStyle.notes && (
                <div className='text-black text-lg flex p-4 mb-6 bg-white border border-gray-900 rounded-md cursor-default'>
                  <InformationCircleIcon className='w-5 h-5 text-gray-600 mr-2 mt-1 flex-shrink-0' />
                  <div dangerouslySetInnerHTML={createMarkup(props.userCampingStyle.notes)} />
                </div>
              )}
              <div className='-mt-4'>{renderSites()}</div>
            </Grid.Column>
            <Grid.Column only='tablet computer'>
              {props.userCampingStyle.notes && (
                <div className='text-black text-lg -mt-6 flex p-4 -mb-1 bg-white border border-gray-900 rounded-md cursor-default'>
                  <InformationCircleIcon className='w-5 h-5 text-gray-600 mr-2 mt-1 flex-shrink-0' />
                  <div dangerouslySetInnerHTML={createMarkup(props.userCampingStyle.notes)} />
                </div>
              )}
              <div className='mt-3.5'>{renderSites()}</div>
            </Grid.Column>
          </>
        ) : (
          <Grid.Column>
            <div className='p-8 text-center'>
              <Header icon>
                <Icon name='calendar times outline' className='w-8' />
                No Available Sites
              </Header>
              <p className='pb-4'>
                There are no sites available for these dates that match your requirements. <br />
                Please adjust your date selection.
              </p>
              <Button
                primary
                content='Change Dates'
                onClick={() => {
                  props.setShowDatesCard(true)
                  if (groupedReservationId) {
                    removeQueryParam('grouped_reservation')
                    setGroupedReservationId(null)
                  }
                }}
              />
            </div>
          </Grid.Column>
        )}
      </Grid>
    </>
  )
}

export default Sites
