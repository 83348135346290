const downloadCsv = (campSlug: string, csvData: string, fileNamePrefix: string): void => {
  const blob = new Blob([csvData], { type: "text/csv" })

  const link = document.createElement("a")
  link.href = window.URL.createObjectURL(blob)
  link.download = `${campSlug}-${fileNamePrefix}-${new Date().toISOString()}.csv`

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default downloadCsv
