import React, { FormEventHandler, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from '../../utils/classNames'
import Button from './Button'

interface ComponentProps {
  open: boolean
  setOpen: (v: boolean) => void
  header: string
  content: JSX.Element | JSX.Element[]
  onSubmit: FormEventHandler
  isProcessing: boolean
  isSaveDisabled: boolean
  dialogStyles?: React.CSSProperties
  width?: 'max-w-xl' | 'max-w-2xl'
  handleRemove?: (any) => void
  errorMessage?: string
}

const SlideoverForm = (props: ComponentProps) => {
  if(!props.open) return <></>

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as='div' className='relative z-30' onClose={props.setOpen}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div
              className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16'
              style={{ marginTop: '0px' }}>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'>
                <Dialog.Panel
                  className={classNames('pointer-events-auto w-screen', props.width || 'max-w-md')}
                  style={props.dialogStyles}>
                  <form
                    className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl'
                    onSubmit={props.onSubmit}>
                    <div className='h-0 flex-1 overflow-y-scroll'>
                      <div className='py-6 px-4 sm:px-6'>
                        <div className='flex items-center justify-between pb-6 pt-2 border-b'>
                          <Dialog.Title className='text-xl font-bold'>{props.header}</Dialog.Title>
                          <div className='flex h-8 items-center'>
                            <button
                              type='button'
                              className='rounded-lg bg-white hover:bg-gray-200 p-1 focus:outline-none'
                              onClick={() => props.setOpen(false)}>
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon className='h-7 w-7' aria-hidden='true' />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-1 flex-col justify-between'>
                        <div className='divide-y divide-gray-200 px-4 sm:px-6'>{props.content}</div>
                      </div>
                    </div>
                    <div className='flex flex-shrink-0 justify-end px-4 py-4 items-center'>
                      {props.errorMessage && (
                        <div className='mr-4'>
                          <p className='text-sm text-red-600'>{props.errorMessage}</p>
                        </div>
                      )}
                      <Button
                        type='button'
                        variant='gray'
                        className={classNames(
                          'mr-4 w-28',
                          props.isProcessing && 'pointer-events-none opacity-60'
                        )}
                        onClick={() => props.setOpen(false)}
                        disabled={props.isProcessing}>
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        variant='green'
                        className={classNames(
                          'w-28',
                          props.isProcessing && 'pointer-events-none opacity-60'
                        )}
                        disabled={props.isProcessing || props.isSaveDisabled}>
                        Save
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SlideoverForm
