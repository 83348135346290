import React, { useContext, useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import api from "../../../api2"
import { SiteProps } from "../../../interfaces"
import { AdminContext } from "../../../contexts/AdminContext"
import SelectInput, { Option } from "../../ui/SelectInput"
import IAddOn from "../../../interfaces/IAddOn"
import { set } from "lodash"
import { Combobox } from "@headlessui/react"
import { ChevronUpDownIcon } from "@heroicons/react/24/outline"
import classNames from "../../../utils/classNames"

const mapProductToOption = (product: IAddOn): Option => ({
  label: product.label,
  value: product.id,
})

interface ComponentProps {
  site: SiteProps
  onChange: (product: IAddOn) => void
}

const ProductDropdown = ({ site, onChange }: ComponentProps) => {
  const { camp } = useContext(AdminContext)
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    if (selectedOption) {
      const product = products.find((p) => p.id === selectedOption.value)
      if (product) {
        onChange(product)
      }
    }
  }, [selectedOption])

  const fetchProducts = useDebouncedCallback(async () => {
    api.Products.forSites(camp.id, [site.id])
      .then(({ products: p }) => {
        setProducts(p)
      })
      .catch((err) => {
        console.error(err)
      })
  }, 100)

  const filterProducts = (query: string) => {
    if (!query) {
      setFilteredProducts(products)
    } else {
      setFilteredProducts(products.filter((p) => p.label.toLowerCase().includes(query.toLowerCase())))
    }
  }

  return (
    <Combobox
      as='div'
      value={selectedOption}
      onChange={setSelectedOption}>
      {({ open }) => (
        <>
          <div className='relative'>
            <Combobox.Button className='w-full ghostinspector-new-reservation-site-button'>
              <Combobox.Input
                className='w-full rounded-md border border-gray-200 bg-white pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6 h-10.5'
                onChange={(e) => filterProducts(e.target.value)}
                displayValue={(id: string) => {
                  return filteredProducts.find((p) => p.id === id)?.label || ''
                }}
                onClick={() => filterProducts('')}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
              />
              <div className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </div>
            </Combobox.Button>
            {filterProducts.length > 0 && open && (
              <Combobox.Options
                static
                className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {filteredProducts.map(mapProductToOption).map((option, index) => (
                  <Combobox.Option
                    key={index}
                    value={option.value}
                    className={({ active, selected }) =>
                      classNames(
                        'relative select-none py-2 px-3 ghostinspector-new-reservation-site-li',
                        option.label ? 'opacity-50' : 'cursor-pointer',
                        selected
                          ? 'bg-green-50'
                          : active
                          ? 'bg-green-600 text-white'
                          : 'text-gray-900'
                      )
                    }>
                    {({ active, selected }) => (
                      <>
                        <div className='flex justify-between'>
                          <div className='flex flex-col'>
                            <span
                              className={classNames(
                                'grow truncate text-md',
                                selected && 'font-semibold'
                              )}>
                              {option.label}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </>
      )}
    </Combobox>
  )
}

export default ProductDropdown
