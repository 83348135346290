import React, { useState, Fragment, useEffect, useContext } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { Moment } from 'moment'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import classNames from '../../utils/classNames'
import { AdminContext } from '../../contexts/AdminContext'

const intervalOptions = ['Today', 'Tomorrow', 'Yesterday']

interface ComponentProps {
  visible: boolean
  setSelectedDate: (date: Moment) => void
  selectedDate: Moment
  selectedInterval: string
  setSelectedInterval: (interval: string) => void
}

const ToDosDatePicker = (props: ComponentProps) => {
  const [isFocusStart, setIsFocusStart] = useState(false)
  const { camp } = useContext(AdminContext)


  const onChange = (date: Moment ) => {
    props.setSelectedInterval('Custom')
    props.setSelectedDate(date)
  }
  
  useEffect(() => {
    let date: Moment = moment()

    switch (props.selectedInterval) {
      case 'Today':
        date = moment()
        props.setSelectedDate(date)
        break
      case 'Tomorrow':
        date = moment().add(1, 'day')
        props.setSelectedDate(date)
        break
      case 'Yesterday':
        date = moment().subtract(1, 'day')
        props.setSelectedDate(date)
        break
      case 'Custom':
        break
      default:
        break
    }
  }, [props.selectedInterval])

  return (
    <div className='flex'>
      <Listbox
        value={props.selectedInterval}
        onChange={(option) => {
          props.setSelectedInterval(option)
        }}>
        {({ open }) => (
          <>
            <div className='relative pl-4 sm:pl-0'>
              <Listbox.Button
                className={classNames(
                  'rounded-l-md inline-flex justify-center items-center w-full border border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-gray-100 open-sans noOutline'
                )}>
                {props.selectedInterval}
                <span className=''>
                  <ChevronDownIcon className='ml-2 mt-0.5 h-5.5 w-5.5' aria-hidden='true' />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Listbox.Options className='absolute z-10 mt-1 w-56 bg-white shadow-lg max-h-96 rounded-md py-1 text-base overflow-auto focus:outline-none'>
                  {intervalOptions.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(
                          active && 'bg-gray-100',
                          'cursor-pointer select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-medium text-green-600' : 'font-normal',
                              'block truncate'
                            )}>
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                'text-green-600 absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <Menu as='div' className='relative inline-block text-left z-10 mr-auto'>
        <div>
          <Menu.Button
            className={
              'inline-flex justify-center items-center w-full border border-l-0 rounded-r-md border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2  focus:ring-offset-gray-100 open-sans noOutline'
            }>
            <CalendarIcon className='mr-3.5 -ml-1 mb-0.25 mt-0.25 h-5.5 w-5.5' aria-hidden='true' />
            {props.selectedDate.format('MMMM Do')}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <DayPickerSingleDateController
              noBorder={true}
              initialVisibleMonth={() => moment(props.selectedDate)}
              date={moment(props.selectedDate).utcOffset(camp.timezone_offset)}
              onDateChange={onChange}
              isOutsideRange={(day: Moment) => false}
              focused={isFocusStart}
              onFocusChange={(e) => setIsFocusStart(e.focused)}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default ToDosDatePicker
