import React, { useContext, useEffect } from 'react'
import { useLayoutEffect, useRef, useState } from 'react'
import { ReservationProps, SiteProps } from '../../../interfaces'
import { AdminContext } from '../../../contexts/AdminContext'
import Row from './Table/Row'
import { mapInvoiceToRowItem } from './InvoicesPage'
import Form from '../../ui/Admin/Form'
import Button from '../../ui/Button'
import { BoltIcon, EyeIcon } from '@heroicons/react/20/solid'
import pluralize from '../../../utils/pluralize'
import api from '../../../api2'
import classNames from '../../../utils/classNames'
import InvoicesModalContent from './InvoicesModalContent'
import { KINDS, LONG_TERM_RESERVATION_KINDS, longTermReservationKind } from '../../../constants'
import Spinner from '../../ui/Spinner'
import useAdminForm from '../../../hooks/useAdminForm'
import { useDebouncedCallback } from 'use-debounce'
import OptionSelector, { IOption } from '../../ui/Admin/OptionSelector'
import { isEqual, sortBy } from 'lodash'
import IInvoice from '../../../interfaces/IInvoice'
import moment, { Moment } from 'moment'
import EditInvoice, { initializeFormDataFor, InvoiceForm } from './EditInvoice'
import { useReactToPrint } from 'react-to-print'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import { validKinds } from '../../../types'
import FilterBy from '../Reports/FilterBy'
import InvoicesDatePicker from './InvoicesDatePicker'
import BulkActionsButton from './BulkActionsButton'
import { SortableTableHeader } from '../SortableTableHeader'
import ILineItem from '../../../interfaces/ILineItem'
import { INVOICE_FILTER_OPTIONS, filterOptions } from './InvoiceFilterOptions'
import { INVOICE_STATUSES } from '../../../decorators/InvoiceDecorator'
import { bySort } from '../../../utils/sorts'
import { Interval } from 'luxon'
import parseDateTime from '../../../utils/parseDateTime'
import isDateBetween from '../../../utils/isDateBetween'
import eSendingModeCookieName from '../../../enums/eSendingModeCookieName'
import { useCookies } from 'react-cookie'
import { FormProvider } from '../../../contexts/FormContext'


export interface RowItem {
  isSiteOnly: boolean
  amountOwed: number
  invoice: IInvoice
  status: INVOICE_STATUSES
  invoiceId: string
  site: SiteProps
  siteId: string
  siteName: string
  siteSort: number
  reservation: ReservationProps
  reservationId: string
  reservationStartDate: Moment
  reservationEndDate: Moment
  reservationRecurringKind: longTermReservationKind | "seasonal"
  camperName: string
  lastMeterReading: number
  currentMeterReading: number
  electricUsed: number
  electricSubtotal: number
  invoiceSendDate: Moment
  invoiceDueDate: Moment
}

const mapSiteToRowItem = (site: SiteProps): RowItem => ({
  isSiteOnly: true,
  amountOwed: null,
  invoice: null,
  status: null,
  invoiceId: null,
  site,
  siteId: site.id,
  siteName: site.name,
  siteSort: site.sort,
  reservation: null,
  reservationId: null,
  reservationStartDate: null,
  reservationEndDate: null,
  reservationRecurringKind: null,
  camperName: null,
  lastMeterReading: site.last_meter_reading,
  currentMeterReading: site.last_meter_reading,
  electricUsed: 0,
  electricSubtotal: 0,
  invoiceSendDate: null,
  invoiceDueDate: null,
})

const cookieSendingMode = eSendingModeCookieName.INVOICE

interface ComponentProps {
  rowItems: RowItem[]
  setRowItems: (rowItems: RowItem[]) => void
  setSelectedInvoice: (invoice: IInvoice) => void
  refreshLocalInvoices: () => void
  isShowingAllSites: boolean
  setIsShowingAllSites: React.Dispatch<React.SetStateAction<boolean>>
  selectedStartDate: Moment
  setSelectedStartDate: React.Dispatch<React.SetStateAction<Moment>>
  selectedEndDate: Moment
  setSelectedEndDate: React.Dispatch<React.SetStateAction<Moment>>
  selectedInterval
  setSelectedInterval
  sortBy
  setSortBy
  isAscending
  setIsAscending
  isLoading: boolean
  selectedFilterOption
  setSelectedFilterOption
  selectedFilterBy
  setSelectedFilterBy
}

const Table = (props: ComponentProps) => {
  const applySites = (items: RowItem[]): RowItem[] => {
    if (!props.isShowingAllSites || !isMeterFieldsEditable) return items

    const electricSiteKinds = [
      KINDS.RV_BACKIN,
      KINDS.RV_HYBRID,
      KINDS.RV_PULLTHROUGH,
      KINDS.BOAT_SLIP,
    ] as validKinds[]

    const combined = sites
      .filter((site) => electricSiteKinds.includes(site.kind))
      .sort(bySort)
      .flatMap((site) => {
        const siteItems = items.filter((item) => item.siteId === site.id)
        return siteItems.length ? siteItems : mapSiteToRowItem(site)
      })

    if (resource.rowItems.length !== combined.length) {
      updateDefaults({ ...resource, rowItems: combined })
      reset({ ...resource, rowItems: combined })
    }

    return combined
  }

  const {
    camp,
    setCamp,
    campingStyles,
    updateLocalReservations,
    showMessageModal,
    showModal,
    showNotification,
    sites,
    setSites,
  } = useContext(AdminContext)

  const [cookies] = useCookies([cookieSendingMode])

  const { watch, updateDefaults, resetDefaults, reset, resource } = useAdminForm<{
    electric_rate: number
    rowItems: RowItem[]
  }>()
  const checkbox = useRef()
  const [checked, setChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPrintLoading, setIsPrintLoading] = useState(false)
  const [isLoadingElectric, setIsLoadingElectric] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([])
  const [isMeterFieldsEditable, setIsMeterFieldsEditable] = useState(false)
  const electricKinds: longTermReservationKind[] = [
    LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES,
    LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY,
  ]
  const [filteredRowItems, setFilteredRowItems] = useState<RowItem[]>(props.rowItems)
  const electric_rate = watch('electric_rate')
  const isVisibleElectric =
    !!electric_rate || filteredRowItems.some((i) => electricKinds.includes(i.reservationRecurringKind as longTermReservationKind) || (i.isSiteOnly && props.isShowingAllSites))
  const targetRef = useRef(null)

  const updateElectric = useDebouncedCallback(() => {
    if (electric_rate === camp.electricity_rate) return

    setIsLoadingElectric(true)
    api.Camps.update(camp.id, { electricity_rate: electric_rate })
      .then(({ camp: mCamp }) => {
        setCamp({ ...camp, ...mCamp })
        updateDefaults({ electric_rate: mCamp.electricity_rate, rowItems: props.rowItems })
        reset({ electric_rate: mCamp.electricity_rate, rowItems: resource.rowItems })
        setIsLoadingElectric(false)
      })
      .catch((err) => {
        setIsLoadingElectric(false)
        showNotification({
          type: 'error',
          title: 'Error updating electric price',
          description: err.message,
        })
      })
  }, 1000)

  useEffect(() => {
    updateElectric()
  }, [watch('electric_rate')])

  useEffect(() => {
    filterInvoices()
  }, [
    props.rowItems,
    props.selectedFilterOption,
    props.isShowingAllSites,
    props.selectedEndDate,
    props.selectedFilterBy,
  ])

  const byDateAndSite = (a: RowItem, b: RowItem) => {
    const aDate = a.invoiceDueDate?.format() || ''
    const bDate = b.invoiceDueDate?.format() || ''

    if (aDate === bDate) {
      const aSite = a.siteSort || a.reservation?.site?.sort || ''
      const bSite = b.siteSort || b.reservation?.site?.sort || ''
      return (props.isAscending ? -1 : 1) * (aSite < bSite ? -1 : 1)
    } else {
      return (props.isAscending ? -1 : 1) * (aDate < bDate ? -1 : 1)
    }
  }

  const bySite = (a: RowItem, b: RowItem) => {
    const aSite = a.siteSort || a.reservation?.site?.sort || ''
    const bSite = b.siteSort || b.reservation?.site?.sort || ''
    return (props.isAscending ? -1 : 1) * (aSite < bSite ? -1 : 1)
  }

  const byName = (a: RowItem, b: RowItem) => {
    const aName = a.invoice.recipient_name || a.camperName || a.reservation?.user?.last_name || ''
    const bName = b.invoice.recipient_name || b.camperName || b.reservation?.user?.last_name || ''
    return (props.isAscending ? -1 : 1) * (aName < bName ? -1 : 1)
  }

  const filterInvoices = () => {
    if (!props.selectedStartDate || !props.selectedEndDate) return

    if (props.selectedFilterOption.value === 'all') {
      const filtered = props.rowItems.filter((i) => (i.invoice?.deleted_at || i.reservation?.deleted_at) === null)
      setFilteredRowItems(applySites(
        filtered.sort(byDateAndSite).filter((item) => {
          if (props.selectedFilterBy == 'start_date') {
            return isDateBetween(
              item.reservation?.start_date,
              props.selectedStartDate.toISOString(),
              props.selectedEndDate.toISOString()
            )
          } else if (props.selectedFilterBy == 'end_date') {
            return isDateBetween(
              item.reservation?.end_date,
              props.selectedStartDate.toISOString(),
              props.selectedEndDate.toISOString()
            )
          } else if (props.selectedFilterBy == 'due_date') {
            return isDateBetween(
              item.invoiceDueDate.toISOString(),
              props.selectedStartDate.toISOString(),
              props.selectedEndDate.toISOString()
            )
          }
        })
      ))
    } else {
      const filtered = props.rowItems.filter((i) => {
        const deletedAt = i.invoice?.deleted_at || i.reservation?.deleted_at
        const isDeleted = deletedAt ? moment(deletedAt).isBetween(props.selectedStartDate, props.selectedEndDate) : false

        // NOTE: default filter out deleted invoices in FE
        let isSelected = isDeleted

        if (props.selectedFilterOption.value === INVOICE_FILTER_OPTIONS.DRAFT)
          isSelected = isSelected && i.status === INVOICE_STATUSES.DRAFT
        else if (props.selectedFilterOption.value === INVOICE_FILTER_OPTIONS.OUTSTANDING)
          isSelected = isSelected && [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.OVERDUE].includes(i.status)
        else if (props.selectedFilterOption.value === INVOICE_FILTER_OPTIONS.PAST_DUE)
          isSelected = isSelected && i.status === INVOICE_STATUSES.OVERDUE
        else if (props.selectedFilterOption.value === INVOICE_FILTER_OPTIONS.PAID)
          isSelected = isSelected && i.status === INVOICE_STATUSES.PAID
        else if (props.selectedFilterOption.value === INVOICE_FILTER_OPTIONS.ARCHIVED)
          isSelected = isDeleted

        return isSelected
      })

      setFilteredRowItems(
        applySites(
          filtered.sort(bySite).filter((invoice) => {
            if (props.selectedFilterBy == 'start_date') {
              return moment(invoice.reservation?.start_date).isBetween(
                props.selectedStartDate,
                props.selectedEndDate
              )
            } else if (props.selectedFilterBy == 'end_date') {
              return moment(invoice.reservation?.end_date).isBetween(
                props.selectedStartDate,
                props.selectedEndDate
              )
            } else if (props.selectedFilterBy == 'due_date') {
              return moment(invoice.invoiceDueDate).isBetween(
                props.selectedStartDate,
                props.selectedEndDate
              )
            }
          })
        )
      )
    }
  }

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedInvoiceIds.length > 0 && selectedInvoiceIds.length < filteredRowItems.length
    setChecked(selectedInvoiceIds.length === filteredRowItems.length)
    setIndeterminate(isIndeterminate)
    if (checkbox.current) {
      ;(checkbox.current as any).indeterminate = isIndeterminate
    }
  }, [selectedInvoiceIds])

  const selectedRowItems = () => {
    return resource.rowItems.filter((item) => selectedInvoiceIds.includes(item.invoiceId))
  }

  const toggleAll = () => {
    setSelectedInvoiceIds(checked || indeterminate ? [] : filteredRowItems.map((i) => i.invoiceId))
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  const normalizeNumber = (value: any) => {
    if (value === '' || value === undefined || value === null) {
      return 0
    }

    return Number(value)
  }

  const updateLocalSites = (updatedSites: SiteProps[]) => {
    setSites(
      sites.map((existingSite) => {
        const updatedSite = updatedSites.find((us) => us.id === existingSite.id)
        return updatedSite ? updatedSite : existingSite
      })
    )
  }

  const upsertElectricLineItem = (items: ILineItem[], totalAmountInCents: number, quantity: number): ILineItem[] => {
    const updatedItems = items.map((item) => {
      if (item.kind === 'electric') {
        return {
          ...item,
          unit_amount_in_cents: camp.electricity_rate * 100,
          quantity: quantity,
        }
      }
      return item
    })

    const electricLineItem = updatedItems.find((item) => item.kind === 'electric')

    if (!electricLineItem) {
      const newElectricLineItem: ILineItem = {
        id: null,
        kind: 'electric',
        label: 'Electricity',
        total_amount_in_cents: totalAmountInCents,
        unit_amount_in_cents: camp.electricity_rate * 100,
        quantity: quantity,
        occurrences: null,
        occurrence_unit: null,
        tax_rate: 0.0,
        order_id: null,
        reservation_id: null,
        add_on_id: null,
        parent_line_item_id: null,
        discount_id: null,
      }
      updatedItems.push(newElectricLineItem)
    }

    return updatedItems
  }

  const handleClickElectricCta = () => {
    if (isMeterFieldsEditable) {
      const persistedRows = applySites(props.rowItems)

      const updatedRows = resource.rowItems.filter((item) => {
        const persisted = persistedRows.find((pItem) => pItem.invoiceId === item.invoiceId || pItem.siteId === item.siteId)
        return normalizeNumber(persisted.electricUsed) !== normalizeNumber(item.electricUsed)
      })

      const requests = updatedRows.map((row) => {
        if (row.isSiteOnly) {
          return api.Sites.update(row.siteId, {
            last_meter_reading: row.currentMeterReading,
          })
        } else {
          const lineItemsWithElectric = upsertElectricLineItem(
            row.invoice.order.line_items,
            Math.round(row.electricSubtotal * 100),
            row.electricUsed
          )

          return api.Invoices.update(row.invoiceId, camp.id, {
            isSending: false,
            sendingMode: null,
            sendDate: row.invoice.send_date ? moment(row.invoice.send_date) : null,
            dueDate: row.invoice.due_date ? moment(row.invoice.due_date) : null,
            recipientEmail: row.invoice.recipient_email,
            recipientPhone: row.invoice.recipient_phone,
            recipientName: row.invoice.recipient_name,
            recipientAddress: row.invoice.recipient_address,
            memo: row.invoice.memo,
            lastMeterReading: row.lastMeterReading,
            meterReading: row.currentMeterReading,
            lineItemsAttributes: lineItemsWithElectric.filter((item) => item.total_amount_in_cents !== 0 || item.reservation_id).map((item) => ({
              id: item.id,
              kind: item.kind,
              label: item.label,
              totalAmountInCents: item.reservation_id ? Number(item.total_amount_in_cents) : null,
              unitAmountInCents: item.reservation_id ? null : Number(item.unit_amount_in_cents),
              quantity: item.quantity,
              occurrences: item.occurrences,
              occurrenceUnit: item.occurrence_unit,
              isTaxFeeEnabled: row.invoice.order?.tax_fee_in_cents > 0,
              reservationId: item.reservation_id,
              addOnId: item.add_on_id,
              isAttachedToReservation: !!item.parent_line_item_id
            }))
          })
        }
      })

      Promise.all(requests)
        .then((responses) => {
          let uReservations: ReservationProps[] = [],
            uInvoices: IInvoice[] = [],
            uSites: SiteProps[] = []

          responses.forEach((resp: any) => {
            if (resp.invoice) {
              const invoice = resp.invoice as IInvoice
              uInvoices.push(invoice)
              if (invoice.reservation) {
                const reservation = invoice.reservation
                uReservations.push(reservation)
                const originalSite = sites.find((s) => s.id === reservation.site_id)
                const site = { ...originalSite, last_meter_reading: reservation.site.last_meter_reading }
                uSites.push(site)
              }
            } else if (resp.site) {
              uSites.push(resp.site)
            }
          })

          updateLocalReservations(uReservations)
          props.refreshLocalInvoices()
          updateLocalSites(uSites)
          showNotification({
            type: 'success',
            title: 'Success!',
            description: 'Electric fields have been saved',
          })
        })
        .catch((err) => {
          console.error(err)
          showNotification({
            type: 'error',
            title: 'Failed to save electric fields for reservation',
            description: err.message,
          })
        })

      props.setIsShowingAllSites(false)
      setIsMeterFieldsEditable(false)
      filterInvoices()
    } else {
      filterInvoices()
      setIsMeterFieldsEditable(true)
    }
  }

  const usePrint = useReactToPrint({
    content: () => targetRef.current,
    onAfterPrint: () => setIsPrintLoading(false),
  })

  const handlePrint = async () => {
    setIsPrintLoading(true)
    targetRef.current.style.display = 'block'
    usePrint()
    targetRef.current.style.display = 'none'
  }

  const handleClickSendInvoices = () => {
    showModal(
      <InvoicesModalContent
        invoices={resource.rowItems.filter((item) => selectedInvoiceIds.includes(item.invoiceId)).map((item) => item.invoice)}
        onSuccess={(removed) => {
          // NOTE: already updated local states in modal
          const removedIds = removed.map((r) => r.id)
          setSelectedInvoiceIds(selectedInvoiceIds.filter((id) => !removedIds.includes(id)))
        }}
        refreshLocalInvoices={props.refreshLocalInvoices}
      />,
      true,
      'max-w-2xl'
    )
  }

  const handleClickMessage = () => {
    showMessageModal({
      invoices: selectedRowItems().map((item) => item.invoice),
      isBatch: true,
      isToAndCCBlocked: true,
    })
  }

  const handleClickMarkPaid = () => {
    setIsLoading(true)

    const reservationIds = selectedRowItems().map((item) => item.reservationId)

    api.Reservations.updateMany(reservationIds, {
      status: 'paid',
    })
      .then(({ reservations }) => {
        setIsLoading(false)
        updateLocalReservations(reservations)
        props.refreshLocalInvoices()
        showNotification({
          type: 'success',
          title: 'Success',
          description: `Updated ${reservations.length} ${pluralize(
            'reservation',
            reservations.length
          )}`,
        })
      })
      .catch((err) => {
        setIsLoading(false)
        showNotification({
          type: 'error',
          title: 'Error',
          description: err.message,
        })
      })
  }

  const renderInvoiceDetails = (invoiceId: string, index: number) => {
    const { invoice } = props.rowItems?.find((item) => item.invoiceId === invoiceId)

    if (!invoice) return <></>

    const site = invoice.reservation?.site || sites.find((s) => s.id === invoice.reservation?.site_id)

    return (
      <FormProvider init={initializeFormDataFor(invoice, site, camp, true, cookies)}>
        <InvoiceForm
          invoice={invoice}
          site={site}
          campingStyle={campingStyles.find(
            (cs) => cs.id === site.camping_style_id
          )}
          isProcessing={!!invoice}
          customerView
          disabled
          isForPrint
          hasPageBreak={index > 0}
          camp={camp}
          isHidingBackButton={false}
          setSelectedInvoice={() => null}
          onSuccess={() => null}
          updateLocalInvoices={() => null}
          availableDiscounts={[]}
        />
      </FormProvider>
    )
  }

  const gridTemplateColumns = isVisibleElectric
    ? window.screen.width >= 1280
      ? '3% 5% 5% 9% 6% 9% 7% 8% 10% 12% 11% 9% auto'
      : '5% 7% 7% 7% 7% 8% 8% 8% 10% 10% 10% 10% auto'
    : window.screen.width >= 1280
    ? '3% 11% 9% 18% 10% 8% 15% 17% auto'
    : '5% 13% 13% 13% 13% 20% 10% 10% auto'

  return (
    <>
      {isPrintLoading && (
        <div className='absolute z-50 w-full h-full top-0 left-0 bg-opacity-50 bg-black flex justify-center items-center'>
          <Spinner size={14} className='w-14 h-14 text-white' />
        </div>
      )}
      <div className='relative'>
        <div
          className='flex flex-col px-5 gap-97 print-margin'
          style={{ display: 'none' }}
          ref={targetRef}
        >
          {selectedInvoiceIds?.length ? (
            selectedInvoiceIds.map((invoiceId, idx) =>
              renderInvoiceDetails(invoiceId, idx)
            )
          ) : (
            <></>
          )}
        </div>
        <div className='my-4'>
          <OptionSelector
            options={filterOptions}
            selectedOption={props.selectedFilterOption}
            onChange={props.setSelectedFilterOption}
          />
        </div>
        {isLoading && (
          <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-center justify-center'>
            <Spinner size={12} />
          </div>
        )}
        <div className='flex flex-col xl:flex-row gap-4 xl:items-end justify-between'>
          <div className='flex flex-col xsm:flex-row gap-4'>
            <InvoicesDatePicker
              startDate={props.selectedStartDate}
              setStartDate={props.setSelectedStartDate}
              endDate={props.selectedEndDate}
              setEndDate={props.setSelectedEndDate}
              visible={true}
              selectedInterval={props.selectedInterval}
              setSelectedInterval={props.setSelectedInterval}
            />
            <FilterBy
              defaultFilterBy={props.selectedFilterBy}
              setFilterBy={props.setSelectedFilterBy}
              options={[
                { label: 'Due Date', value: 'due_date' },
                { label: 'Start Date', value: 'start_date' },
                { label: 'End Date', value: 'end_date' },
              ]}
            />
          </div>
          <div className='flex flex-wrap-reverse xsm:flex-row xl:justify-end items-start xsm:items-default-end gap-4'>
            {selectedInvoiceIds.length == 1 && (
              <Button
                className='flex items-center'
                variant={'gray'}
                onClick={() =>
                  props.setSelectedInvoice(
                    props.rowItems.find(
                      (i) => i.invoiceId == selectedInvoiceIds[0]
                    )?.invoice
                  )
                }
              >
                <EyeIcon className='w-5 h-5 mr-2' />
                Edit
              </Button>
            )}
            {isMeterFieldsEditable && (
              <div className='flex items-center' style={{ height: 37.84 }}>
                <CheckboxWithLabel
                  label='Show all sites'
                  value={props.isShowingAllSites}
                  setValue={props.setIsShowingAllSites}
                  labelClasses='text-sm break-words'
                  labelStyle={{ maxWidth: 243 }}
                />
              </div>
            )}
            {isVisibleElectric && (
              <>
                <Button
                  className='flex items-center'
                  variant={isMeterFieldsEditable ? 'blue' : 'gray'}
                  onClick={handleClickElectricCta}
                >
                  <BoltIcon className='w-5 h-5 mr-2' />
                  {isMeterFieldsEditable ? 'Save' : <span>Edit readings</span>}
                </Button>
                <Form.NumberInput
                  className='w-36'
                  disabled={isLoadingElectric}
                  label='Electric price'
                  prefix='$'
                  placeholder='0.00'
                  suffix={camp.currency.toUpperCase()}
                  field='electric_rate'
                />
                <div className='px-2 my-3 hidden xsm:block text-gray-300'>
                  |
                </div>
              </>
            )}
            <div className=''>
              {selectedInvoiceIds.length ? (
                <div
                  className={classNames(
                    'text-sm text-gray-400 mb-0.5',
                    !selectedInvoiceIds.length && 'opacity-0'
                  )}
                >
                  {`${selectedInvoiceIds.length} ${pluralize(
                    'invoice',
                    selectedInvoiceIds.length
                  )}`}
                </div>
              ) : (
                <></>
              )}
              <BulkActionsButton
                disabled={selectedInvoiceIds.length === 0}
                handlePrint={handlePrint}
                handleClickSendInvoices={handleClickSendInvoices}
                handleClickMessage={handleClickMessage}
                handleClickMarkPaid={handleClickMarkPaid}
              />
            </div>
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='relative -mx-4 -my-2 overflow-y-visible sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <div className='flex xl:block justify-center'>
                {isLoading || props.isLoading ? (
                  <div className='absolute bg-white bg-opacity-30 z-40 flex flex-col space-y-4 justify-center items-center h-full w-full left-0 top-20'>
                    <Spinner size={16} />
                    <p>Loading invoices...</p>
                  </div>
                ) : (
                  <div
                    className='overflow-x-scroll xl:overflow-visible max-w-full whitespace-nowrap'
                    style={{ 
                      maxWidth: '95vw'
                     }}
                  >
                    <div
                      style={{
                        minWidth: window.screen.width < 1280 ? 1512 : '100%',
                      }}
                    >
                      <div
                        className='grid overflow-hidden w-full border-b border-gray-300'
                        style={{
                          gridTemplateColumns,
                        }}
                      >
                        <div className='relative px-7 sm:w-12 sm:px-6'>
                          <input
                            type='checkbox'
                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600'
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </div>
                        <div className='pr-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Amount
                        </div>
                        <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                          Status
                        </div>
                        <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                          Kind
                        </div>
                        <SortableTableHeader
                          field='site'
                          sortBy={props.sortBy}
                          setSortBy={props.setSortBy}
                          isAscending={props.isAscending}
                          setIsAscending={props.setIsAscending}
                          isTable={false}
                        >
                          Site
                        </SortableTableHeader>
                        <SortableTableHeader
                          field='name'
                          sortBy={props.sortBy}
                          setSortBy={props.setSortBy}
                          isAscending={props.isAscending}
                          setIsAscending={props.setIsAscending}
                          isTable={false}
                        >
                          Reservation
                        </SortableTableHeader>
                        <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                          Invoice sent
                        </div>
                        <SortableTableHeader
                          field='due_date'
                          sortBy={props.sortBy}
                          setSortBy={props.setSortBy}
                          isAscending={props.isAscending}
                          setIsAscending={props.setIsAscending}
                          isTable={false}
                        >
                          Due date
                        </SortableTableHeader>

                        {isVisibleElectric && (
                          <>
                            <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                              Last meter (kWh)
                            </div>
                            <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                              Current meter (kWh)
                            </div>
                            <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                              Electric used (kWh)
                            </div>
                            <div className='min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                              Electric subtotal
                            </div>
                          </>
                        )}
                        <div className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
                          <span className='sr-only'>Edit</span>
                        </div>
                      </div>
                      {filteredRowItems.length === 0 ? (
                        <div className='flex justify-center pt-24 w-full text-lg italic text-gray-400'>
                          <div>
                            There are no{' '}
                            {props.selectedFilterOption.label == 'All invoices'
                              ? 'invoices for the selected dates'
                              : props.selectedFilterOption.label.toLowerCase() +
                                ' invoices for the selected dates'}
                          </div>
                        </div>
                      ) : (
                        filteredRowItems
                          .sort(
                            props.isShowingAllSites
                              ? bySite
                              : props.sortBy == 'due_date'
                              ? byDateAndSite
                              : props.sortBy == 'name'
                              ? byName
                              : bySite
                          )
                          .map((item, index) => (
                            <Row
                              gridTemplateColumns={gridTemplateColumns}
                              key={item.invoiceId || item.siteId}
                              rowItem={item}
                              refreshLocalInvoices={props.refreshLocalInvoices}
                              index={index}
                              isSelected={selectedInvoiceIds.includes(
                                item.invoiceId
                              )}
                              setIsSelected={(inv, isSelected) => {
                                setSelectedInvoiceIds(
                                  isSelected
                                    ? [...selectedInvoiceIds, inv.id]
                                    : selectedInvoiceIds.filter(
                                        (id) => id !== inv.id
                                      )
                                )
                              }}
                              setSelectedInvoice={props.setSelectedInvoice}
                              isVisibleElectric={isVisibleElectric}
                              isMeterFieldsEditable={isMeterFieldsEditable}
                            />
                          ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          className='hidden max-w-4xl'
          ref={targetRef}>
          {selectedInvoiceIds?.length ? (
            selectedInvoiceIds.map((invoiceId, idx) => renderInvoiceDetails(invoiceId, idx))
          ) : (
            <></>
          )}
        </div>
    </>
  )
}

export default Table
