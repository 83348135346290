import React, { useEffect, useState } from "react"
import classNames from "../../utils/classNames"

const svgCache = new Map()

const ExternalSvg = ({ url, style, className, asImg }: { url: string, style?: Record<string, any>, className?: string, asImg?: boolean }) => {
  const [svgContent, setSvgContent] = useState(svgCache.get(url) || null)
  const [svgUrl, setSvgUrl] = useState(url)

  useEffect(() => {
    if (!svgCache.has(url)) {
      fetch(url)
        .then((response) => (asImg ? response.blob() : response.text()) as Promise<Blob | string>)
        .then((data) => {
          if (asImg) {
            const blob = data as Blob
            const objectUrl = URL.createObjectURL(blob)
            svgCache.set(url, objectUrl)
            setSvgUrl(objectUrl)
          } else {
            const str = data as string
            svgCache.set(url, str)
            setSvgContent(str)
          }
        })
        .catch(error => console.error('Error fetching the SVG:', error))
    } else {
      setSvgContent(svgCache.get(url))
    }
  }, [url])

  if (asImg && svgUrl) {
    return (
      <img src={svgUrl} alt="" className={classNames("svg-container", className)} style={style} />
    )
  } else if (svgContent) {
    return (
      <div
        className={classNames("svg-container", className)}
        style={style}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    )
  } else {
    return <></>
  }
}

export default ExternalSvg
