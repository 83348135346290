import ICamper from '../interfaces/ICamper'
import IS3Document from '../interfaces/IS3Document'
import { destroy, get, patch } from './utils/requests'

export type tSortDirection = 'asc' | 'desc'

interface IPageOptions {
  page?: number
  per_page?: number
  sort_column?: string
  sort_direction?: tSortDirection
}

interface ICampUpdate {
  name?: string
  color?: string
  email?: string
  phone?: string
  address?: string
  city?: string
  state?: string
  zip?: string
  bio?: string
  season_start_date?: string
  season_end_date?: string
  booking_start_date?: string
  booking_start_mm_dd?: string
  bathroom_code?: string
  wifi_network?: string
  wifi_password?: string
  tax_rate?: number
  electricity_rate?: number
}

const update = (id: string, data: ICampUpdate) => patch(`/api/camps/${id}`, data)
const fetchImages = (id: string): Promise<{ images: Array<IS3Document> }> => get(`/api/camps/${id}/images`)

const uploadImage = async (id: string, file: File): Promise<{ image: IS3Document }> => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(`/api/camps/${id}/images`, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to upload image')
  }

  const resp = await response.json()

  return resp
}

const removeImage = async (id: string, key: string): Promise<{ message: string }> =>
  destroy(`/api/camps/${id}/images`, { key })

interface ICampersResponse {
  campers: ICamper[]
  total_records: number
  total_pages: number
}

const fetchCampers = (id: string, options: IPageOptions): Promise<ICampersResponse> =>
  get(`/api/camps/${id}/campers`, options)

const updateCamper = (id: string, data: ICamper) =>
  patch(`/api/campers/${id}`, data)

const findCamperByData = (data: { campId: string, email?: string, phone?: string }): Promise<ICampersResponse> => {
  const params = new URLSearchParams()
  if (data.email) params.append('email', data.email)
  if (data.phone) params.append('phone', data.phone)
  return get(`/api/camps/${data.campId}/campers?${params.toString()}`)
}

const findCamper = (id: string): Promise<{ camper: ICamper }> => get(`/api/campers/${id}`)

const Camps = {
  fetchCampers,
  fetchImages,
  findCamperByData,
  findCamper,
  update,
  updateCamper,
  uploadImage,
  removeImage
}

export default Camps
