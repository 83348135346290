const RESERVATIONS: 'reservations' = 'reservations'
const TO_DOS: 'to-dos' = 'to-dos'
const REPORTS: 'reports' = 'reports'
const SETTINGS: 'settings' = 'settings'
const GUESTS: 'guests' = 'guests'
const INVOICES: 'invoices' = 'invoices'
const STORE: 'store' = 'store'

export const ADMIN_PAGES = {
  RESERVATIONS,
  TO_DOS,
  REPORTS,
  STORE,
  SETTINGS,
  GUESTS,
  INVOICES,
}
