import React, { createContext } from 'react'
import { CampingStyleProps, CampProps, ReservationProps, SiteProps, UserProps } from '../interfaces'
import IGroupedReservation from '../interfaces/IGroupedReservation'
import eSendingMode from '../enums/eSendingMode'
import { IReservationState } from '../interfaces/IReservationStates'
import { IUpdatingReservation } from '../components/Admin/Reservations/ConfirmationModalContent'
import ICamper from '../interfaces/ICamper'
import { adminPages } from '../types/adminPages'
import IInvoice from '../interfaces/IInvoice'

interface ICtxPayload {
  currentPage: adminPages
  setCurrentPage: (currentPage: adminPages) => any
  currentUserEmail: string
  isParkAdminUser: boolean
  currentUserRole: 'admin' | 'manager' | 'user' | 'user-no-refund' | 'view-only'
  camp: CampProps
  setCamp: (camp: CampProps) => any
  accessibleCamps: CampProps[]
  reloadAccessibleCamps: () => Promise<void>
  sites: SiteProps[]
  setSites: (sites: SiteProps[]) => any
  campingStyles: CampingStyleProps[]
  setCampingStyles: React.Dispatch<React.SetStateAction<CampingStyleProps[]>>
  reservationStates: Record<string, { persisted: ReservationProps; updated?: ReservationProps }>
  setReservationStates: (
    reservationStates: Record<string, { persisted: ReservationProps; updated?: ReservationProps }>
  ) => any
  selectedReservation: ReservationProps
  setSelectedReservation: (reservation: ReservationProps) => any
  selectedCamper: ICamper
  setSelectedCamper: (camper: ICamper) => any
  showRefreshModal: () => any
  showPaymentModal: (
    amount: number,
    options?: {
      reservation?: ReservationProps
      reservations?: ReservationProps[]
      groupedReservation?: IGroupedReservation
      isDeposit?: boolean
    }
  ) => any
  isShowingPaymentModal: boolean
  isShowingMessageModal: boolean
  isMessageSent: boolean
  setIsMessageSent: (isMessageSent: boolean) => void
  selectedSendingMode: eSendingMode
  setSelectedSendingMode: (mode: eSendingMode) => void
  showNotification: (args: {
    type: 'success' | 'warning' | 'error'
    title: string
    description?: string
    dismissAfter?: number
    onClick?: (e: { event: React.MouseEvent }) => void
  }) => void
  showReservationsConfirmation: (
    reservationStates: IReservationState[],
    customCallback?: (data: IUpdatingReservation[]) => Promise<any>
  ) => void
  showConfirm: (message: string, options?: { description?: string }) => Promise<boolean>
  showMessageModal: (options: {
    reservations?: ReservationProps[]
    invoices?: IInvoice[]
    emails?: string[]
    phoneNumbers?: string[]
    isBatch?: boolean
    isToAndCCBlocked?: boolean
  }) => any
  showModal: (
    component: JSX.Element,
    isDynamicWidth?: boolean,
    maxWidth?: string,
    options?: {
      closeCallback?: () => void
    }) => any
  closeModal: () => any
  /** @deprecated */
  updateLocalReservation: (v: ReservationProps) => any
  updateLocalReservations: (v: ReservationProps[]) => any
}

const initialContext = {
  currentPage: 'reservations' as adminPages,
  setCurrentPage: null,
  currentUserEmail: null,
  isParkAdminUser: false,
  currentUserRole: null,
  camp: null,
  setCamp: null,
  accessibleCamps: null,
  reloadAccessibleCamps: null,
  sites: null,
  setSites: null,
  campingStyles: null,
  setCampingStyles: null,
  reservationStates: null,
  setReservationStates: null,
  selectedReservation: null,
  setSelectedReservation: null,
  selectedCamper: null,
  setSelectedCamper: null,
  showRefreshModal: null,
  showPaymentModal: null,
  showMessageModal: null,
  showModal: null,
  closeModal: null,
  isShowingPaymentModal: false,
  isShowingMessageModal: false,
  isMessageSent: false,
  setIsMessageSent: null,
  selectedSendingMode: null,
  setSelectedSendingMode: null,
  showNotification: null,
  showReservationsConfirmation: null,
  showConfirm: null,
  /** @deprecated */
  updateLocalReservation: null,
  updateLocalReservations: null,
}

export const AdminContext = createContext<ICtxPayload>(initialContext)
