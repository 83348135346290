import { Interval } from 'luxon'
import parseDateTime from './parseDateTime'

const isDateBetween = (date: string, start: string, end: string) => {
  const dateToCheck = parseDateTime(date)
  const startDate = parseDateTime(start)
  const endDate = parseDateTime(end)

  const interval = Interval.fromDateTimes(startDate, endDate)

  return interval.contains(dateToCheck)
}

export default isDateBetween