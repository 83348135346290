import React, { useEffect, useState } from 'react'
import iconFor from '../../../utils/iconFor'
import {
  CheckCircleIcon,
  CubeIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { formatMoney } from '../../../utils/formatMoney'
import IAddOn from '../../../interfaces/IAddOn'
import HoverTip from '../../ui/HoverTip'
import ILineItem from '../../../interfaces/ILineItem'
import { LINE_ITEM_KINDS } from '../../../constants/line_item_kinds'
import { PencilIcon } from '@heroicons/react/20/solid'

const mapAddOnToLineItem = (addOn: IAddOn, quantity: number): Partial<ILineItem> => {
  return {
    kind: LINE_ITEM_KINDS.BASIC,
    label: addOn.label,
    total_amount_in_cents: null, // NOTE: calculated on the backend
    unit_amount_in_cents: addOn.amount_in_cents,
    quantity: quantity,
    occurrences: 1, // TODO: add support for nightly/duration-based add-ons
    occurrence_unit: null, // TODO: add support for nightly/duration-based add-ons
    add_on_id: addOn.id,
  }
}

const updateLineItemsByLabel = (lineItems: Array<Partial<ILineItem>>, addOn: IAddOn, quantity: number) => {
  let updated = false

  const updatedArr = lineItems?.map((li) => {
    if (li.add_on_id === addOn.id) {
      updated = true
      return { ...li, quantity }
    } else {
      return li
    }
  })

  if (updated) {
    if (quantity === 0) {
      return updatedArr.filter((li) => li.add_on_id !== addOn.id)
    }
  } else {
    return [...updatedArr, mapAddOnToLineItem(addOn, quantity)]
  }

  return updatedArr
}

interface ComponentProps {
  addOn: IAddOn
  quantity: number
  onIncrement: (any) => void
  onDecrement: (any) => void
  primaryColor: string
  isEditing: boolean
  handleProductClick: (any) => void
}

const AddOn = ({
  addOn,
  quantity,
  onIncrement,
  onDecrement,
  primaryColor,
  isEditing,
  handleProductClick,
}: ComponentProps) => {
  return (
    <div
      className={`w-40 h-32 p-3 border-2 rounded-2xl flex flex-col justify-between hover:bg-gray-100`}
      style={
        quantity > 0
          ? { borderColor: primaryColor, background: primaryColor + '25' }
          : { borderColor: 'rgba(209, 213, 219' }
      }
      onClick={(event) => {
        if (!(addOn.kind == 'boolean' && quantity > 0)) onIncrement(event)
      }}>
      <div className='flex flex-col h-full'>
        {iconFor('add-on', addOn.slug) ? (
          <>
            <img
              src={iconFor('add-on', addOn.slug)}
              className={`h-9 w-9 mb-2 justify-self-center self-center`}
            />
            <div className='flex flex-row flex-1'>
              <div className='text-baselg font-semibold mb-1 leading-5 truncate whitespace-normal'>{addOn.label}</div>
              {addOn.description && (
                <HoverTip content={addOn.description}>
                  <InformationCircleIcon className='w-3.5 h-3.5 ml-1' />
                </HoverTip>
              )}
            </div>
            <span className='text-gray-700 leading-tight'>
              {formatMoney(addOn.amount_in_cents / 100)}{' '}
              {addOn.kind != 'boolean' && quantity > 0 && ' x ' + quantity}
            </span>
          </>
        ) : (
          <>
            <div className='flex flex-row flex-1'>
              <div className='text-baselg font-semibold mb-1 leading-5 truncate whitespace-normal'>{addOn.label}</div>
              {addOn.description && (
                <HoverTip content={addOn.description}>
                  <InformationCircleIcon className='w-3.5 h-3.5 ml-1' />
                </HoverTip>
              )}
            </div>
            <span className='text-gray-700 leading-tight'>
              {formatMoney(addOn.amount_in_cents / 100)}{' '}
              {addOn.kind != 'boolean' && quantity > 0 && ' x ' + quantity}
            </span>
          </>
        )}
      </div>
      <div className='relative flex justify-end bottom-3 -left-3.5 space-x-10'>
        {isEditing && (
          <button onClick={() => {handleProductClick(addOn)}} className='z-10'>
            <div
              className='w-8.5 h-8.5 absolute top-1  ml-1.5 bg-white rounded-full z-0 border-black'
              style={{ borderWidth: '2.5px' }}
            />
            <PencilIcon className='w-4.5 h-4.5 ml-3.5 mt-3 text-black absolute z-10' />
          </button>
        )}

        {quantity > 0 && (
          <button onClick={onDecrement} disabled={quantity <= 0} className='z-10'>
            <div className='w-8 h-8 absolute top-1.5  ml-1.5 bg-white rounded-full z-0' />
            <MinusCircleIcon className='w-11 h-11 text-black absolute z-10' />
          </button>
        )}

        <button onClick={onIncrement} disabled={addOn.kind == 'boolean' && quantity > 0}>
          <div className='w-8 h-8 absolute top-1.5 ml-1.5 bg-white rounded-full z-0' />
          {addOn.kind == 'boolean' && quantity > 0 ? (
            <CheckCircleIcon className='w-11 h-11 text-black absolute z-10 ' />
          ) : (
            <PlusCircleIcon className='w-11 h-11 text-black absolute z-10' />
          )}
        </button>
      </div>
    </div>
  )
}

const AdminAddOns = (props: {
  addOns: IAddOn[]
  primaryColor: string
  lineItems: Array<Partial<ILineItem>>
  setLineItems: (v: Array<Partial<ILineItem>>) => void
  handleProductClick?: () => void
  isEditing?: boolean
}) => {
  const [addOnCounts, setAddOnCounts] = useState({})

  useEffect(() => {
    setAddOnCounts(
      props.lineItems?.reduce((acc, li) => {
        return { ...acc, [li.add_on_id]: li.quantity }
      }, {})
    )
  }, [props.lineItems])

  const handleIncrement = (id: string) => {
    const changingAddOn = props.addOns.find((addOn) => addOn.id === id)
    const newCount = (addOnCounts[id] || 0) + 1
    props.setLineItems(updateLineItemsByLabel(props.lineItems, changingAddOn, newCount))
  }

  const handleDecrement = (id: string) => {
    const changingAddOn = props.addOns.find((addOn) => addOn.id === id)
    const newCount = Math.max((addOnCounts[id] || 0) - 1, 0)
    props.setLineItems(updateLineItemsByLabel(props.lineItems, changingAddOn, newCount))
  }

  return (
    <div className='flex flex-wrap gap-4 gap-y-5 my-4'>
      {props?.addOns?.length > 0 && props.addOns.map((addOn) => (
        <div key={addOn?.id} className=''>
          <AddOn
            addOn={addOn}
            quantity={addOnCounts?.[addOn?.id] || 0}
            onIncrement={(event) => {
              event.stopPropagation()
              handleIncrement(addOn?.id)
            }}
            onDecrement={(event) => {
              event.stopPropagation()
              handleDecrement(addOn?.id)
            }}
            primaryColor={props.primaryColor}
            isEditing={props.isEditing}
            handleProductClick={props.handleProductClick}
          />
        </div>
      ))}
      {
        <div
          className={`w-40 h-32 p-3 border-2 border-dashed rounded-2xl flex flex-col justify-center hover:bg-gray-100`}
          style={{ borderColor: 'rgba(209, 213, 219' }}
          onClick={() => props.handleProductClick()}>
          <div className='flex flex-col'>
            <CubeIcon className={`h-9 w-9 mb-2 justify-self-center self-center text-gray-400`} />
            <div className='flex flex-row'>
              <div className='text-base font-medium mb-1 leading-4 text-center text-gray-500'>
                Add new product
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default AdminAddOns
