import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import juice from 'juice'
import { desktopToolbar, desktopToolbarHyperlink, mobileToolbar } from '../../config/quillToolbar'
import { richTextInputStyles } from '../../constants/rich_text_input_styles'
import { removeNonColorStyles } from './Admin/Form/RichTextInput'

interface ComponentProps {
  id?: string
  defaultValue?: string
  value: string
  setValue: (v: string) => void
  isDisabled?: boolean
  className?: string
  style?: React.CSSProperties
  hyperlink?: boolean
}

const RichTextInput = ({ defaultValue, value, setValue, id, isDisabled, className, style, hyperlink }: ComponentProps) => {
  const [isReady, setIsReady] = useState(false)
  const [quillValue, setQuillValue] = useState('')
  
  useEffect(() => {
    // Hacky solution to stop from autofocusing
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 100)

    setQuillValue(removeNonColorStyles(value || ''))

    return () => clearTimeout(timer)
  }, [])
  
  return (
    <ReactQuill
      theme='snow'
      modules={{ toolbar: window.innerWidth < 550 ? mobileToolbar : hyperlink ? desktopToolbarHyperlink : desktopToolbar }}
      className={className}
      style={style}
      readOnly={!isReady || isDisabled}
      value={quillValue}
      onChange={(v) => {
        setQuillValue(v)
        setValue(juice(richTextInputStyles + v))
      }}
      id={id}
    />
  )
}

export default RichTextInput
