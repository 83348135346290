import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

function useAdminForm<S> (defaultValues: S | Record<any, any>) {
  const reactHookForm = useForm({ defaultValues })
  const  { register, control, reset, watch, setValue } = reactHookForm
  const [isDirty, setIsDirty] = useState(false)
  const [resource, setResource] = useState(defaultValues)

  const state = watch()

  const resetDefaults = () => {
    reset(defaultValues)
  }

  useEffect(() => {
    setIsDirty(
      Object.keys(defaultValues).some((key) => {
        return defaultValues[key] != state[key]
      })
    )
  }, [state])

  // if user tries to navigate away from page, warn them that they will lose unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
        // TODO: make this app-native
        alert('You have unsaved changes. Are you sure you want to leave?')
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return {
    register,
    control,
    watch,
    resetDefaults,
    setValue,
    isDirty,
    defaultValues,
    resource,
  }
}

export default useAdminForm
