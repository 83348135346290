import React, { useContext, useEffect, useState } from 'react'
import classNames from '../../../utils/classNames'
import parkLogoPath from '../../../../assets/images/park_logo__green.svg'
import parkIconPath from '../../../../assets/images/park_icon__green.svg'
import SearchBar from '../SearchBar'
import SearchDropDown from '../SearchDropDown'
import { UserProps } from '../../../interfaces'
import api from '../../../api2'
import AdminDropdown from './AdminDropdown'
import ADMIN_NAVBAR_ITEMS from '../../../constants/admin_navbar_items'
import { AdminContext } from '../../../contexts/AdminContext'
import moment from 'moment'
import Holidays from 'date-holidays'
import * as Sentry from '@sentry/react'

const Navbar = () => {
  const { camp, currentPage, setCurrentPage, currentUserRole } = useContext(AdminContext)
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [searchResults, setSearchResults] = useState<UserProps[]>(null)
  const [searchStatus, setSearchStatus] = useState<any>({})

  const handleLogout = () => {
    api.Sessions.logout()
      .then((data) => {
        console.log("logged out, moving to login page", data)
        window.location.href = '/admin/login'
      })
      .catch((err) => {
        console.error("Error logging out:", err)
        Sentry.captureException("Error logging out user on FE", { contexts: { info: { errorMessage: err } } })
      })
  }

  const checkForTodayHoliday = (targetDate: moment.Moment) => {
    const weekBeforeTarget = moment(targetDate).subtract(7, 'days')
    const twoDaysAfterTarget = moment(targetDate).add(2, 'days')

    return moment().isBetween(weekBeforeTarget, twoDaysAfterTarget)
  }

  const fetchParkLogoSrc = () => {
    const holidays = new Holidays('US')

    const holidayLogos = {
      'Christmas Day': 'christmas_logo.png',
      'Halloween': 'halloween_logo.png',
      'Thanksgiving Day': 'thanksgiving_logo.png',
      'Easter Sunday': 'easter_logo.png',
      'Independence Day': '4th_of_july_logo.png'
    }

    const observedHolidayNames = Object.keys(holidayLogos)
    const observedHolidays = holidays
      .getHolidays(moment().year())
      .filter((h) => observedHolidayNames.includes(h.name))

    for (const holiday of observedHolidays) {
        if (checkForTodayHoliday(moment(holiday.date)) && holidayLogos[holiday.name]) {
            return `https://park-prod-assets.s3.amazonaws.com/camps/logos/${holidayLogos[holiday.name]}`
        }
    }

    return window.innerWidth > 500 ? parkLogoPath : parkIconPath
  }

  return (
    <>
      <div className='sticky top-0 z-20 bg-white border-b flex flex-col justify-center'>
        <>
          <div className='flex-grow mx-4 lg:mx-12 xl:mx-24 2xl:mx-36'>
            <div className='relative flex items-center justify-between h-20'>
              <div className='flex items-center lg:px-0'>
                <div className='flex-shrink-0'>
                  <img
                    src={fetchParkLogoSrc()}
                    className='block z-10 h-8 lg:h-10 w-auto cursor-pointer'
                    onClick={() => {
                      setCurrentPage('reservations')
                    }}
                  />
                </div>
                <div className='hidden lg:block lg:ml-8'>
                  <div className='flex space-x-4'>
                    {ADMIN_NAVBAR_ITEMS.map((item) => {
                      const isCurrent = currentPage == item.key
                      if (
                        !(currentUserRole == 'user' && item.key == 'reports') &&
                        !(
                          currentUserRole == 'view-only' &&
                          (item.key == 'reports' || item.key == 'invoices')
                        )
                      ) {
                        return (
                          <a
                            key={item.name}
                            onClick={(e) => {
                              e.preventDefault()
                              setCurrentPage(item.key)
                            }}
                            href={isCurrent ? null : item.href}
                            className={classNames(
                              isCurrent
                                ? 'bg-gray-100 text-gray-900 hover:text-gray-900'
                                : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900',
                              'px-3 py-2.5 rounded-md text-sm'
                            )}>
                            {item.name}
                          </a>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className='flex-1 flex justify-center px-4 sm:ml-6 sm:justify-end'>
                <SearchBar
                  showResults={() => {
                    if (!showSearchResults) setShowSearchResults(true)
                  }}
                  onBlur={(value: string) => {
                    if (!value) setSearchStatus({})
                    setShowSearchResults(false)
                  }}
                  setSearchResults={setSearchResults}
                  setSearchStatus={setSearchStatus}
                  visible={showSearchResults}
                />
              </div>
              <AdminDropdown
                handleLogout={handleLogout}
                campName={camp.name}
                campSlug={camp.slug}
              />
            </div>
          </div>
        </>
      </div>

      <SearchDropDown
        visible={showSearchResults}
        searchResults={searchResults}
        searchStatus={searchStatus}
      />
    </>
  )
}

export default Navbar
