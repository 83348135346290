import React from 'react'
import { ModalContext } from './ModalContext'


export const ModalProvider = ({
  onClose,
  children
}) => {
  return (
    <ModalContext.Provider value={{ onClose }}>
      { children }
    </ModalContext.Provider>
  )
}