import { Combobox } from "@headlessui/react"
import React, { useContext, useState, useEffect } from "react"
import { useDebouncedCallback } from "use-debounce"
import api2 from "../../../api2"
import { AdminContext } from "../../../contexts/AdminContext"
import CamperDecorator from "../../../decorators/CamperDecorator"
import ICamper from "../../../interfaces/ICamper"
import avatarFor from "../../../utils/avatarFor"
import classNames from "../../../utils/classNames"
import generateAvatar from "../../../utils/generateAvatar"
import generateConsistentNaturalHexColor from "../../../utils/generateNaturalHexColor"
import { checkIsCamperEmpty } from "../Campers/Camper/ProfileCard"
import { XCircleIcon } from "@heroicons/react/20/solid"
import { formatName } from "../../../utils/formatName"

const CamperDropdown = ({
  camper,
  setCamper,
  isCamperLoading,
}: {
  camper: ICamper
  setCamper: React.Dispatch<React.SetStateAction<ICamper>>
  isCamperLoading: boolean
}) => {
  const { camp } = useContext(AdminContext)
  const [inputValue, setInputValue] = useState('')
  const [campers, setCampers] = useState<ICamper[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchCampers = useDebouncedCallback(async (query) => {
    api2.Search.campers(camp.id, query)
      .then(({ campers }) => {
        setCampers(campers)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 100)

  const getAvatar = (camper: ICamper) =>
    avatarFor(
      generateAvatar(camper?.first_name + camper?.last_name || camper?.email || camper?.phone),
      generateConsistentNaturalHexColor(camper?.first_name + camper?.last_name || camper?.email || camper?.phone)
    )

  useEffect(() => {
    if (!checkIsCamperEmpty(camper) && checkIsCamperEmpty(camper)) {
      setCamper(camper)
    }
  }, [camper])

  return (
    <Combobox
      as={React.Fragment}
      disabled={isCamperLoading}
      value={camper}
      onChange={(value) => {
        if (typeof value !== 'string') {
          setCamper(value)
          const existingCamper = campers.find((c) => c.id === value.id)
          const display = formatName(existingCamper)
          setInputValue(display || '')
        }
      }}>
      {({ open }) => (
        <>
          <div className='relative'>
            <Combobox.Button className='w-full ghostinspector-new-reservation-site-button'>
              <Combobox.Input
                placeholder='Start typing to search...'
                className='w-full rounded-md placeholder-gray-300 border border-gray-300 bg-white pl-13 pr-12 py-5 text-gray-900 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:leading-6 h-10.5 focus:border-transparent'
                onChange={(e) => {
                  setIsLoading(true)
                  fetchCampers(e.target.value)
                  setInputValue(e.target.value)
                }}
                value={inputValue}
              />
              <div className='absolute inset-y-1.5 left-0 flex items-center rounded-r-md px-2 focus:outline-none'>
                {getAvatar(camper)}
              </div>
              <div
                className={classNames(
                  'absolute inset-y-1.5 right-0 flex items-center rounded-r-md px-2 focus:outline-none',
                  !inputValue && 'pointer-events-none hidden'
                )}>
                <XCircleIcon
                  className='w-5 h-5 text-gray-300 hover:text-gray-400'
                  onClick={() => {
                    setCamper(null)
                    setInputValue('')
                  }}
                />
              </div>
            </Combobox.Button>
            {open && inputValue.length > 0 && (
              <Combobox.Options
                static
                className='absolute z-10 mt-1 max-h-60 w-full overflow-y-auto overflow-x-visible rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {campers.map((option, index) => {
                  if (
                    !option?.first_name?.length &&
                    !option?.last_name?.length &&
                    !option?.email?.length
                  )
                    return <></>

                  return (
                    <>
                      <Combobox.Option
                        key={index}
                        value={option}
                        className={({ active, selected }) =>
                          classNames(
                            'relative select-none py-2 px-3 ghostinspector-new-reservation-site-li cursor-pointer',
                            selected
                              ? 'bg-green-50'
                              : active
                              ? 'bg-green-600 text-white'
                              : 'text-gray-900'
                          )
                        }>
                        {({ active, selected }) => (
                          <div className='flex items-center w-full justify-between'>
                            <div className='' style={{ flex: '0 0 20px' }}>
                              {getAvatar(option)}
                            </div>
                            <div
                              className={classNames(
                                'grow truncate text-md ml-2.5 flex flex-1',
                                selected && 'font-semibold'
                              )}>
                              {option?.first_name && <>{option.first_name}&nbsp;</>}
                              {option?.last_name && <>{option.last_name}&nbsp;&nbsp;</>}
                              {option?.email && (
                                <div
                                  className={classNames(
                                    'truncate',
                                    active ? 'bg-green-600 text-white' : 'text-gray-400'
                                  )}>
                                  {option.email}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Combobox.Option>
                    </>
                  )
                })}
                {inputValue?.length && !camper?.id && !campers?.length ? (
                  isLoading ? (
                    <div className='text-md font-semibold text-center'>Loading...</div>
                  ) : (
                    <div className='text-md font-semibold text-center'>No guests found</div>
                  )
                ) : (
                  <></>
                )}
              </Combobox.Options>
            )}
          </div>
        </>
      )}
    </Combobox>
  )
}

export default CamperDropdown