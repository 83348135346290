import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import moment, { Moment, MomentInput } from 'moment'

import FullCalendar, { EventApi, DateSelectArg, EventMountArg } from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import luxon2Plugin from '@fullcalendar/luxon2'
import { DateTime } from 'luxon'
import { EventContentArg } from '@fullcalendar/common'

import { CampProps, CampingStyleProps, ReservationProps, SiteProps } from '../../interfaces'
import EventButton from './EventButton'

import Api from '../../api2'
import SiteIcons from '../SiteIcons'
import { titleCase } from '../../utils'
import SlideoverForm from '../ui/SlideoverForm'
import EditSiteForm from '../Admin/Reservations/EditSiteForm'
import { PRICE_DURATIONS } from '../../constants/price_durations'
import { validCampingStyles, validKinds } from '../../types'
import { CAMPING_STYLES, KINDS, STATUSES } from '../../constants'
import EditReservationForm from '../Admin/Reservations/EditReservationForm'
import ReservationSlideoverForm from '../ui/ReservationSlideoverForm'
import { EditReservationProvider } from '../../contexts/admin/EditReservationContext'
import { AdminContext } from '../../contexts/AdminContext'
import * as Sentry from '@sentry/react'
import formatCampingStyleName from '../../utils/formatCampingStyleName'
import { useCookies } from 'react-cookie'
import Modal from '../ui/Modal'
import EditInvoice from '../Admin/Invoices/EditInvoice'
import ReservationsDatePicker from '../Admin/Reservations/ReservationsDatePicker'
import Button from '../ui/Button'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Menu } from '@headlessui/react'
import ReservationInvoiceWrapper from '../helpers/ReservationInvoiceWrapper'
import IOrder from '../../interfaces/IOrder'
import isRvKind from '../../utils/isRvKind'
import { useReactToPrint } from 'react-to-print'
import { useDebouncedCallback } from 'use-debounce'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import CampingStyleSettingsSlideover from '../Admin/Reservations/CampingStyleSettingsSlideover'
import { formatName } from '../../utils/formatName'
import CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD from '../../constants/camp_slugs_with_company_name_field'
import pluralize from '../../utils/pluralize'
import api from '../../api2'
import { has } from 'lodash'
import { isReservationsMatching } from '../../utils/isReservationsMatching'

// NOTE: we hold our own state of persisted/unpersisted reservations
interface ReservationStateProps {
  persisted: ReservationProps
  updated?: ReservationProps
}

interface ChangedFieldsProps {
  siteId?: string
  startDate?: Moment
  endDate?: Moment
}

interface ComponentProps {
  camp: CampProps
  sites: SiteProps[]
  setSites: (sites: SiteProps[]) => void
  changeReservation: any
  newReservationDispatch: any
  openCreateForm: any

  calendarFromDate: Moment
  calendarDuration: number
  setCalendarFromDate: (v: Moment) => void
  setCalendarDuration: (v: number) => void
  isQuarterlyView: boolean
  setIsQuarterlyView: React.Dispatch<React.SetStateAction<boolean>>
}
interface ReservationCheck {
  start: moment.MomentInput
  end: moment.MomentInput
  id?: string
  userId?: string
  siteId?: string
}

const Calendar = (props: ComponentProps) => {
  const {
    camp,
    campingStyles,
    reservationStates,
    setReservationStates,
    isShowingPaymentModal,
    isShowingMessageModal,
    showNotification,
    selectedReservation,
    setSelectedReservation,
    currentUserRole,
    setSelectedCamper,
    updateLocalReservations
  } = useContext(AdminContext)

  const [key, setKey] = useState(Math.random().toString(36).substring(7))

  const reloadCalendar = () => {
    setKey(Math.random().toString(36).substring(7))
  }

  const [selectedSite, setSelectedSite] = useState<SiteProps>()
  const [selectedCampingStyle, setSelectedCampingStyle] = useState<CampingStyleProps>()
  const [editSiteError, setEditSiteError] = useState('')
  const [isUpdatingSite, setIsUpdatingSite] = useState(false)
  const [isSiteSaveDisabled, setIsSiteSaveDisabled] = useState(false)
  
  const [selectedStartDate, setSelectedStartDate] = useState<Moment>()
  const [selectedEndDate, setSelectedEndDate] = useState<Moment>()
  const [isLoadingSelectedReservation, setIsLoadingSelectedReservation] = useState(false)
  const [hasRefetchedReservation, setHasRefetchedReservation] = useState(false)
  const [selectedReservationId, setSelectedReservationId] = useState<string>('')
  const [editReservationError, setEditReservationError] = useState('')
  const [isUpdatingReservation, setIsUpdatingReservation] = useState(false)

  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false)
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false)

  const calendarRef = useRef<FullCalendar>(null)
  const [cookies, setCookie] = useCookies(['isQuarterlyView'])
  const [customViewDate, setCustomViewDate] = useState<Moment>(moment())
  const [selectedInterval, setSelectedInterval] = useState<string>(
    moment().isBefore(moment(camp.season_start_mm_dd, 'MM-DD').year(moment().year()))
      ? `Season Start, ${moment().year()}`
      : 'Today'
  )

  const daysBeforeVisibleAreaForMonthly = window.screen.width > 750 ? 2 : 0
  const daysBeforeVisibleAreaForQuarterly = window.screen.width > 750 ? 5 : 2

  const prevMonth = () => {
    const fullCalendar = calendarRef.current.getApi()
    fullCalendar.prev()

    const newDate = moment(fullCalendar.getDate())

    setSelectedDate(newDate)
  }

  const nextMonth = () => {
    const fullCalendar = calendarRef.current.getApi()
    fullCalendar.next()

    const newDate = moment(fullCalendar.getDate())

    setSelectedDate(newDate)
  }

  const monthlyView = () => {
    calendarRef.current.getApi().changeView('resourceTimelineDefault')

    setCookie('isQuarterlyView', false)
    props.setIsQuarterlyView(false)
  }

  const quarterlyView = () => {
    calendarRef.current.getApi().changeView('resourceTimelineQuarterly')

    setCookie('isQuarterlyView', true)
    props.setIsQuarterlyView(true)
  }

  const switchToTodayQuarterlyView = () => {
    calendarRef.current.getApi().changeView('resourceTimelineQuarterly')
  }

  const isOverlapping = (s1: MomentInput, e1: MomentInput, s2: MomentInput, e2: MomentInput) =>
    moment(s1).isSameOrBefore(e2) && moment(s2).isSameOrBefore(e1)

  const sortedSites = (a: SiteProps, b: SiteProps) => {
    const aStyle = campingStyles.find((cs) => cs.id === a.camping_style_id)
    const bStyle = campingStyles.find((cs) => cs.id === b.camping_style_id)

    if (aStyle?.name === 'rv' && bStyle?.name !== 'rv') {
      return -1
    } else if (aStyle?.name !== 'rv' && bStyle?.name === 'rv') {
      return 1
    } else {
      return a.sort - b.sort
    }
  }

  // NOTE: It's recommended we refactor the slideover to have a single API request for the
  // reservation and all it's dependent data (site, user, etc) to avoid multiple API requests and
  // keep the component logic segregated
  const refetchReservation = useDebouncedCallback(() => {
    setIsLoadingSelectedReservation(true)
    api.Reservations.find(selectedReservation.id)
      .then(({ reservation: res }) => {
        if (!isReservationsMatching(res, selectedReservation)) {
          console.log("reservations do not match, updating...")
          setSelectedReservation(res)
          updateLocalReservations([res])
        }
        setIsLoadingSelectedReservation(false)
        setHasRefetchedReservation(true)
      })
      .catch((err) => {
        console.error(err)
        setIsLoadingSelectedReservation(false)
      })
  }, 100)

  useEffect(() => {
    if (!selectedReservation) {
      if (isSlideoverOpen) setIsSlideoverOpen(false)

      return
    }

    if (!hasRefetchedReservation) {
      refetchReservation()
    }

    if (isSlideoverOpen === false) setIsSlideoverOpen(true)
    if (selectedReservation.deleted_at) return

    const jumpedDate = moment(selectedReservation.start_date)
    const calendarApi = calendarRef.current.getApi()
    if (
      !isOverlapping(
        selectedReservation.start_date,
        selectedReservation.end_date,
        calendarApi.view.activeStart,
        calendarApi.view.activeEnd
      )
    ) {
      // NOTE: when we jump to a new date, we need to re-render the calendar, which will
      // reset the selected reservation, so we need to set it again
      setSelectedReservationId(selectedReservation.id)
      // NOTE: sends a new API request to fetch reservations
      props.setCalendarFromDate(jumpedDate)
      // TODO: find the proper start date given the monthly "grid"
      calendarApi.gotoDate(jumpedDate.toDate())
      calendarApi.zoomTo(moment(jumpedDate).subtract(2, 'days').toDate(), 'resourceTimeline')
    }

    setSelectedStartDate(moment(selectedReservation.start_date))
    setSelectedEndDate(moment(selectedReservation.end_date))
  }, [selectedReservation])

  useEffect(() => {
    if (!isSlideoverOpen && hasRefetchedReservation) {
      setHasRefetchedReservation(false)
    }
  }, [isSlideoverOpen])

  const hasDuplicates = (events: EventApi[]): boolean => {
    try {
      const set = new Set<string>()
      const duplicates: string[] = []

      for (const event of events) {
        if (set.has(event.id)) {
          const message = `Front-end FullCalendar duplicate event found for reservation ${event.id}. Cleaned from the UI but this should not happen.`
          const debugInfo = {
            id: event.id,
            userEmail: reservationStates[event.id]?.persisted?.user?.email,
            userPhone: reservationStates[event.id]?.persisted?.user?.phone,
            siteName: reservationStates[event.id]?.persisted?.site?.name,
            startDate: event.start.toISOString(),
            endDate: event.end.toISOString(),
          }

          console.log(message, debugInfo)
          Sentry.captureMessage(message, {
            level: Sentry.Severity.Warning,
            contexts: {
              info: debugInfo,
            },
          })

          duplicates.push(event.id)
          event.remove()
        } else {
          set.add(event.id)
        }
      }

      return duplicates.length > 0
    } catch (err) {
      console.error('Non-fatal error processing duplicates for full calendar:', err)
    }
  }

  function isDateBetween(
    targetDates: ReservationProps,
    startDate: moment.MomentInput,
    endDate: moment.MomentInput
  ): boolean {
    return (
      moment(targetDates.start_date).isBetween(startDate, endDate, null, '[]') ||
      moment(targetDates.end_date).isBetween(startDate, endDate, null, '[]') ||
      moment(startDate).isBetween(targetDates.start_date, targetDates.end_date, null, '[]') ||
      moment(endDate).isBetween(targetDates.start_date, targetDates.end_date, null, '[]')
    )
  }

  const eventProperties = (ev: EventMountArg) => {
    const eventHarness: HTMLElement = ev.el.closest('.fc-timeline-event-harness')
    const reservation = reservationStates[ev.event.id]?.persisted
    const isImoptredInSameRow =
      reservation.status !== STATUSES.IMPORTED &&
      !!Object.values(reservationStates).find(
        (importedReservation) =>
          importedReservation.persisted.status === STATUSES.IMPORTED &&
          importedReservation.persisted.site_id === reservation.site_id
      )

    const isRecurringEvent = reservation?.long_term_reservation?.kind === 'utilities-only'
    const differenceInDays = moment(reservation.end_date).diff(
      moment(calendarRef.current?.getApi().view.activeStart),
      'days',
      true
    )

    if (reservation.status === STATUSES.IMPORTED) {
      eventHarness.classList.add('imported-event')

      if (differenceInDays <= 1 && props.isQuarterlyView) {
        eventHarness.classList.add('imported-small-event')
      }

      return
    }

    if (
      differenceInDays <= 1 &&
      !ev.event.extendedProps.isSeasonalEvent &&
      reservation.long_term_reservation_id
    ) {
      eventHarness.classList.add('small-bottom-event')
    } else if (differenceInDays <= 1 && ev.event.extendedProps.isSeasonalEvent) {
      eventHarness.classList.add('small-top-event')
    } else if (differenceInDays <= 1) {
      isImoptredInSameRow
        ? eventHarness.classList.add('small-bottom-event')
        : eventHarness.classList.add('small-single-event')
    } else {
      let isBottomEvent = false
      Object.values(reservationStates).forEach((reservationState) => {
        const importedReservation = reservationState?.updated || reservationState.persisted
        if (
          reservation.status !== STATUSES.IMPORTED &&
          reservation.status !== STATUSES.BLOCKED_IMPORT &&
          (importedReservation.status === STATUSES.IMPORTED ||
            importedReservation.status === STATUSES.BLOCKED_IMPORT) &&
          (reservation.long_term_reservation?.kind === 'utilities-only'
            ? reservation.kind !== null
            : true) &&
          reservation.site_id === importedReservation.site_id &&
          isDateBetween(importedReservation, reservation.start_date, reservation.end_date)
        ) {
          isBottomEvent = true
        }
      })

      if (isBottomEvent) {
        eventHarness.classList.add('event-bottom')
        return
      }

      eventHarness.classList.add(
        ev.event.extendedProps.isSeasonalEvent
          ? 'event-top'
          : isRecurringEvent || isImoptredInSameRow
          ? 'event-bottom'
          : 'event-single'
      )
    }
  }

  // NOTE: update selected reservation when reservations update
  useEffect(() => {
    if (!(reservationStates && Object.keys(reservationStates).length && calendarRef?.current)) {
      return
    }

    const selectedId = selectedReservation?.id || selectedReservationId
    const mSelectedReservation = reservationStates[selectedId]?.persisted

    const calendarApi = calendarRef.current.getApi()
    const events = calendarApi.getEvents()

    const isDifferingState = events.length !== Object.keys(reservationStates).length

    if (hasDuplicates(events) || isDifferingState) {
      // NOTE: this will be a workaround for a bug where duplicate FullCalendar events are rendered
      // calendarApi.destroy()
      // reloadCalendar()
    }

    if (mSelectedReservation) {
      if (selectedReservationId) setSelectedReservationId(null)
      setSelectedReservation(mSelectedReservation)
    } else {
      setSelectedReservation(null)
    }
  }, [reservationStates])

  // TODO: what do we do with the PaymentModal... since we need to control this state too?
  // the context!
  useEffect(() => {
    const isDialogueOpen = isInvoiceOpen || isShowingPaymentModal || isShowingMessageModal

    if (isSlideoverOpen && isDialogueOpen && !!selectedReservation) {
      setIsSlideoverOpen(false)
    } else if (!isSlideoverOpen && !isDialogueOpen && !!selectedReservation) {
      setIsSlideoverOpen(true)
    }
  }, [isInvoiceOpen, isShowingPaymentModal, isShowingMessageModal])

  const onDateSelect = (selectionInfo: DateSelectArg) => {
    const siteId = selectionInfo.resource?.id
    const site = props.sites.find((site) => site.id === siteId)
    const campingStyleId = site.camping_style_id
    const campingStyle = campingStyles.find((campingStyle) => campingStyle.id === campingStyleId)

    const isHourly =
      !!campingStyle?.default_hours &&
      campingStyle?.booking_intervals?.length > 0

    let startDt = DateTime.fromJSDate(selectionInfo.start, { zone: camp.timezone })
    let endDt = DateTime.fromJSDate(selectionInfo.end, { zone: camp.timezone })

    if (isHourly) {
      // NOTE: isDefaultHoursApplied
      startDt = startDt.set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
      endDt = endDt
        .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
        .plus({ hours: campingStyle.default_hours })
    } else {
      const checkin = campingStyle?.checkin_time || site.checkin_time
      const checkout = campingStyle?.checkout_time || site.checkout_time
      const [checkinHours, checkinMinutes] = checkin.split(':').map((str) => Number(str))
      const [checkoutHours, checkoutMinutes] = checkout.split(':').map((str) => Number(str))
      startDt = startDt.set({
        hour: checkinHours,
        minute: checkinMinutes,
        second: 0,
        millisecond: 0,
      })
      endDt = endDt.set({ hour: checkoutHours, minute: checkoutMinutes, second: 0, millisecond: 0 })
    }

    if (endDt.startOf('day').diff(startDt.startOf('day')).as('days') > 1 || isHourly) {
      endDt = endDt.minus({ days: 1 })
    }

    const startDate = moment.parseZone(startDt.toString())
    const endDate = moment.parseZone(endDt.toString())

    props.newReservationDispatch({
      type: 'UPDATE_SITE_ID_AND_DATES',
      payload: {
        site_id: siteId,
        start_date: startDate,
        end_date: endDate,
        start_time: startDate.format('HH:mm'),
        end_time: endDate.format('HH:mm'),
      },
    })

    props.openCreateForm()
  }

  const styleFromKind = (kind: validKinds, camping_style: CampingStyleProps) => {
    const rvKinds: validKinds[] = [KINDS.RV_BACKIN, KINDS.RV_HYBRID, KINDS.RV_PULLTHROUGH, CAMPING_STYLES.RV, CAMPING_STYLES.SEASONAL_RV] as validKinds[]

    if (rvKinds.includes(kind)) {
      if (camping_style?.name === CAMPING_STYLES.SEASONAL_RV) {
        return 'Seasonal RV Sites'
      } else if (camping_style?.name === CAMPING_STYLES.RV) {
        return 'RV Sites'
      }
    } else {
      return formatCampingStyleName(kind, true)
    }
  }

  const renderEventContent = (eventInfo: EventContentArg) => (
    <EventButton
      camp={props.camp}
      reservations={reservationStates}
      eventInfo={eventInfo}
      isSelected={selectedReservation?.id === eventInfo.event?.id}
      setSelected={setSelectedReservation}
      calendarApi={calendarRef?.current?.getApi()}
      isQuarterlyView={props.isQuarterlyView}
    />
  )

  const setTodayDate = () => {
    const daysBeforeVisibleArea = props.isQuarterlyView
      ? daysBeforeVisibleAreaForQuarterly
      : daysBeforeVisibleAreaForMonthly
    setSelectedDate(moment(), moment().subtract(daysBeforeVisibleArea, 'days'))
  }

  const setMonth = (month: string, isIntervalNameChanged: boolean = false) => {
    setSelectedInterval(month)

    if (isIntervalNameChanged) {
      let date: Moment = moment()
      switch (month) {
        case 'Today':
          setTodayDate()
          break
        case `Season Start, ${moment().year()}`:
          date = moment(camp.season_start_mm_dd, 'MM-DD').year(moment().year()).hour(12)
          setSelectedDate(moment(date))
          break
        case `Next Season Start, ${moment().year() + 1}`:
          date = moment(camp.season_start_mm_dd, 'MM-DD').year(moment().year() + 1).hour(12)
          setSelectedDate(moment(date))
          break
        case `Previous Season Start, ${moment().year() - 1}`:
          date = moment(camp.season_start_mm_dd, 'MM-DD').year(moment().year() - 1).hour(12)
          setSelectedDate(moment(date))
          break
        case 'January':
          date = moment().startOf('year').month(0).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'February':
          date = moment().startOf('year').month(1).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'March':
          date = moment().startOf('year').month(2).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'April':
          date = moment().startOf('year').month(3).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'May':
          date = moment().startOf('year').month(4).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'June':
          date = moment().startOf('year').month(5).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'July':
          date = moment().startOf('year').month(6).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'August':
          date = moment().startOf('year').month(7).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'September':
          date = moment().startOf('year').month(8).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'October':
          date = moment().startOf('year').month(9).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'November':
          date = moment().startOf('year').month(10).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'December':
          date = moment().startOf('year').month(11).date(1).hour(12)
          setSelectedDate(date)
          break
        case 'Custom':
          break
        default:
          break
      }
    }
  }

  const setSelectedDate = (data: Moment, visibleDate?: Moment) => {
    props.setCalendarFromDate(moment(data.toDate()))
    calendarRef.current?.getApi().gotoDate(visibleDate ? visibleDate.toDate() : data.toDate())
    setCustomViewDate(data)
  }

  useEffect(() => {
    setMonth(
      moment().isBefore(moment(camp.season_start_mm_dd, 'MM-DD').year(moment().year()).hour(12))
        ? `Season Start, ${moment().year()}`
        : 'Today',
      true
    )
  }, [])

  const printRef = useRef(null)

  const deleteScroll = () => {
    const scrollers: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute') as HTMLCollectionOf<HTMLElement>
    for(let i = 0; i < scrollers.length; i++){
      scrollers[i].style.overflow = 'visible'
      scrollers[i].style.position = 'relative'
    }
  }

  const returnScroll = () => {
    const scrollers: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute') as HTMLCollectionOf<HTMLElement>
    
    for(let i = 0; i < scrollers.length; i++){
      scrollers[i].style.overflow = 'scroll'
      scrollers[i].style.position = 'absolute'
    }
  }

  const reactPrint = useDebouncedCallback(useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: returnScroll,
  }), 100)
  

  const handlePrint = () => {
    deleteScroll()

    reactPrint()
  }

  const events = useMemo(
    () =>
      Object.values(reservationStates).map((reservationState) => {
        const reservation = reservationState.updated ?? reservationState.persisted
        const isSeasonalEvent =
          !!reservation.long_term_reservation_id && [null, ''].includes(reservation.kind)

        return {
          id: reservation.id,
          resourceId: reservation.site_id,
          title: reservation.user?.email,
          start: moment(reservation.start_date).toDate(),
          end: moment(reservation.end_date).toDate(),
          reservationId: reservation.id,
          stripeId: reservation.vendor_id,
          startEditable: currentUserRole !== 'view-only' && !reservation.is_locked,
          durationEditable: currentUserRole !== 'view-only' && !reservation.is_locked,
          resourceEditable: currentUserRole !== 'view-only' && !reservation.is_locked,
          isSeasonalEvent: isSeasonalEvent,
          display:
            calendarRef &&
            calendarRef.current &&
            moment(reservation.end_date).isBefore(calendarRef.current.getApi()?.view?.activeStart)
              ? 'none'
              : null,
          userId: reservation?.user_id,
          siteId: reservation.site_id,
        }
      }),
    [reservationStates, currentUserRole, calendarRef]
  )

  const replaceKindWithRV = (siteKind: string, camping_style_id: string) => {
    const cs = campingStyles.find(style => style.id === camping_style_id)

    if (siteKind === KINDS.RV_BACKIN || siteKind === KINDS.RV_PULLTHROUGH || siteKind === KINDS.RV_HYBRID) {
      if (cs?.name === CAMPING_STYLES.SEASONAL_RV) {
        return 'seasonal-rv'
      } else if (cs?.name === CAMPING_STYLES.RV) {
        return 'rv'
      }
      return 'rv'
    }
    return siteKind
  }

  return (
    <>
      {isInvoiceOpen && selectedReservation && (
        <Modal
          open={isInvoiceOpen}
          onClose={() => setIsInvoiceOpen(false)}
          isDynamicWidth
          maxWidth='max-w-7xl'>
          <ReservationInvoiceWrapper
            reservation={selectedReservation}
            order={selectedReservation?.order}>
            {({ invoice, setInvoice }) => (
              <>
                <EditInvoice
                  invoice={invoice}
                  setSelectedInvoice={setInvoice}
                  site={selectedReservation.site}
                  campingStyle={campingStyles.find(
                    (cs) => cs.id === selectedReservation.site.camping_style_id
                  )}
                  onSuccess={(invoice) => setIsInvoiceOpen(false)}
                  isHidingBackButton
                />
              </>
            )}
          </ReservationInvoiceWrapper>
        </Modal>
      )}
      <EditReservationProvider
        reservation={selectedReservation}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setIsUpdatingReservation={setIsUpdatingReservation}>
        <ReservationSlideoverForm
          width='max-w-2xl'
          open={isSlideoverOpen}
          setOpen={(isOpen) => {
            if (isOpen) return

            setSelectedReservation(null)
            setEditReservationError('')
            setIsSlideoverOpen(false)
            setSelectedCamper(null)
          }}
          header={
            selectedReservation?.status === STATUSES.BLOCKED
              ? `Blocker`
              : selectedReservation?.user?.camper?.first_name ||
                selectedReservation?.user?.camper?.last_name ||
                selectedReservation?.user?.first_name ||
                selectedReservation?.user?.last_name
              ? `Reservation for ${selectedReservation?.user?.camper?.company_name && CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD.includes(camp.slug) ? selectedReservation.user.camper.company_name : formatName(selectedReservation?.user?.camper || selectedReservation?.user) }`
              : `Reservation`
          }
          isProcessing={isUpdatingReservation || isLoadingSelectedReservation}
          content={
            <EditReservationForm
              stripeAccountId={props.camp.stripe_account_id}
              reservation={selectedReservation}
              site={
                selectedReservation && props.sites.find((s) => s.id === selectedReservation.site_id)
              }
              isProcessing={isUpdatingReservation || isLoadingSelectedReservation}
              currency={props.camp.currency}
              startDate={selectedStartDate}
              setStartDate={setSelectedStartDate}
              endDate={selectedEndDate}
              setEndDate={setSelectedEndDate}
              openInvoiceModal={() => setIsInvoiceOpen(true)}
              onOpenDialogue={() => setIsSlideoverOpen(false)}
              campName={props.camp.name}
              setSlideoverOpen={setIsSlideoverOpen}
            />
          }
          reservation={selectedReservation}
        />
      </EditReservationProvider>
      {!!selectedSite && (
        <SlideoverForm
          width='max-w-xl'
          open={!!selectedSite}
          setOpen={(isOpen) => {
            if (isOpen) return
            setSelectedSite(null)
            setEditSiteError('')
          }}
          header={`Edit ${selectedSite?.name}`}
          isProcessing={isUpdatingSite}
          isSaveDisabled={isSiteSaveDisabled}
          dialogStyles={{ maxWidth: 538 }}
          errorMessage={editSiteError}
          content={
            <EditSiteForm
              site={selectedSite}
              camp={props.camp}
              isProcessing={isUpdatingSite}
              errorMessage={editSiteError}
              setErrorMessage={setEditSiteError}
              setIsSiteSaveDisabled={setIsSiteSaveDisabled}
              setIsSlideoverOpen={(v: boolean) => {
                if (v) return

                setSelectedSite(null) // TODO: need to be able to bring it back
              }}
            />
          }
          onSubmit={(e) => {
            e.preventDefault()

            const wasNonFlatRateActive = !!selectedSite?.prices.find(
              (p) => p.duration !== PRICE_DURATIONS.FLAT_RATE
            )
            const isNonFlatRateActive = Object.values(PRICE_DURATIONS)
              .filter((d) => d !== PRICE_DURATIONS.FLAT_RATE)
              .some((d) => e.target[`prices[${d}][isActive]`]?.checked)

            const wasFlatRateActive = !!selectedSite?.prices.find(
              (p) => p.duration === PRICE_DURATIONS.FLAT_RATE
            )
            const isFlatRateActive =
              e.target[`prices[${PRICE_DURATIONS.FLAT_RATE}][isActive]`]?.checked &&
              Number(e.target[`prices[${PRICE_DURATIONS.FLAT_RATE}][amount]`]?.value) > 0

            const pricesData = Object.values(PRICE_DURATIONS)
              .map((duration) => {
                const amountValue = Number(e.target[`prices[${duration}][amount]`]?.value)
                const amount_in_cents = amountValue * 100

                const wasActive = !!selectedSite?.prices.find((p) => p.duration === duration)
                const isActive =
                  duration === PRICE_DURATIONS.NIGHTLY ||
                  e.target[`prices[${duration}][isActive]`]?.checked

                if (!isActive && amount_in_cents === null) return null

                const priceId = e.target[`prices[${duration}][id]`]?.value

                if (!priceId && amount_in_cents === 0) {
                  return null
                }

                // destroy only if it's not active and it was active before, but we also handle...
                const isDestroying = wasActive && !isActive
                // the case where a user flips on a flat rate and doesn't flip off other prices (flat rate persists, others are destroyed)
                const isDestroyingOthers =
                  !wasFlatRateActive &&
                  isFlatRateActive &&
                  wasActive &&
                  isActive &&
                  duration !== PRICE_DURATIONS.FLAT_RATE
                // or the case where a user flips on other prices and doesn't flip off flat rate (other prices persist, flat rate is destroyed)
                const isDestroyingFlatRate =
                  !wasNonFlatRateActive &&
                  isNonFlatRateActive &&
                  wasActive &&
                  isActive &&
                  duration === PRICE_DURATIONS.FLAT_RATE

                return {
                  id: priceId,
                  duration,
                  amount_in_cents,
                  _destroy: isDestroying || isDestroyingOthers || isDestroyingFlatRate,
                }
              })
              .filter((x) => x)

            const electricValue = e.target['electric[value]']?.value
            const notesValue = e.target['siteNotes']?.value
            const siteCheckPolicyAdditions = e.target['siteCheckPolicyAdditions']?.value

            let data = {}

            if (e.target['name']?.value) data['name'] = e.target['name'].value
            if (e.target['kind[value]']?.value) data['kind'] = e.target['kind[value]']?.value
            if (e.target['maxRigLength']?.value)
              data['max_rig_length'] = Number(e.target['maxRigLength']?.value)

            if (e.target['minimumCapacity']?.value)
              data['minimum_capacity'] = Number(e.target['minimumCapacity']?.value)
            if (e.target['camperCapacity']?.value)
              data['camper_capacity'] = Number(e.target['camperCapacity']?.value)
            if (e.target['currentMeterReading']?.value)
              data['last_meter_reading'] = Number(e.target['currentMeterReading']?.value)

            data['notes'] = notesValue === '' ? null : notesValue
            data['checkin_policy_additions'] = siteCheckPolicyAdditions === '' ? null : siteCheckPolicyAdditions

            const vendorCalendarData = () => {

              const links = JSON.parse(e.target['vendor_links']?.value || '[]')
  
              if(links?.length){
                return links.map(link => ({
                  id: link.isNewLink ? null : link.id,
                  kind: link.kind,
                  calendar_url: link.value,
                  _destroy: link._destroy
                }))
              } else {
                return []
              }
            }

            data['is_bookable'] = e.target['isBookable']?.value === 'on'
            data['water'] = e.target['water']?.value === 'on'
            data['sewage'] = e.target['sewage']?.value === 'on'
            data['electric'] = electricValue === '' ? null : electricValue
            data['prices_attributes'] = pricesData
            data['vendor_calendars_attributes'] = vendorCalendarData()

            setIsUpdatingSite(true)

            Api.Sites.update(e.target['id'].value, data)
              .then(({ site }) => {
                setIsUpdatingSite(false)
                setSelectedSite(null)
                setEditSiteError('')
                showNotification({
                  type: 'success',
                  title: 'Changes saved!',
                  description: `Sucessfully updated ${site.name}`,
                })
                props.setSites(
                  props.sites.map((oldSite: SiteProps) => (site.id === oldSite.id ? site : oldSite))
                )
              })
              .catch((err) => {
                setEditSiteError(err.message)
                setIsUpdatingSite(false)
                console.error(err)
              })
          }}
        />
      )}
      <CampingStyleSettingsSlideover
        campingStyle={selectedCampingStyle}
        setSelectedCampingStyle={setSelectedCampingStyle}
        closeModal={() => setSelectedCampingStyle(null)}
      />
      <div className='date-navigation absolute z-10 hidden md:flex'>
        {camp.slug === 'lancaster-camp-ground' && (
          <Button
            style={{
              borderRadius: 6,
              padding: '0.4em 0.6em',
              border: '1px solid #D2D2D2',
            }}
            className='hover:bg-gray-100 z-50'
            variant='none'
            onClick={handlePrint}
            children='Print'
          />
        )}
        <ReservationsDatePicker
          selectedDate={customViewDate}
          seasonStart={camp.season_start_mm_dd}
          visible={true}
          setSelectedDate={setSelectedDate}
          isQuarterlyView={cookies['isQuarterlyView'] === 'true'}
          switchToTodayQuarterlyView={switchToTodayQuarterlyView}
          daysBeforeVisibleAreaForMonthly={daysBeforeVisibleAreaForMonthly}
          daysBeforeVisibleAreaForQuarterly={daysBeforeVisibleAreaForQuarterly}
          setTodayDate={setTodayDate}
          setMonth={setMonth}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
        />
        <div className='flex' style={{ columnGap: 4 }}>
          <Button
            variant='none'
            children={<ChevronLeftIcon className='w-6 h-6 -ml-1' />}
            style={{
              borderRadius: 6,
              padding: '0.4em 0.6em',
              width: '35px',
              border: '1px solid #D2D2D2',
            }}
            className='hover:bg-gray-100'
            onClick={prevMonth}
          />

          <Button
            variant='none'
            children={<ChevronRightIcon className='w-6 h-6 -mr-1' />}
            style={{
              borderRadius: 6,
              padding: '0.4em 0.6em',
              width: '35px',
              border: '1px solid #D2D2D2',
            }}
            className='hover:bg-gray-100'
            onClick={nextMonth}
          />
        </div>
        <Button
          style={{
            borderRadius: 6,
            padding: '0.4em 0.6em',
            border: '1px solid #D2D2D2',
          }}
          className='hover:bg-gray-100'
          variant='none'
          onClick={monthlyView}
          children='Monthly'
        />

        <Button
          style={{
            borderRadius: 6,
            padding: '0.4em 0.6em',
            border: '1px solid #D2D2D2',
          }}
          className='hover:bg-gray-100'
          variant='none'
          onClick={quarterlyView}
          children='Quarterly'
        />
      </div>
      <div className='date-navigation absolute z-10 flex md:hidden w-36'>
        <Menu as='div'>
          <Menu.Button
            style={{
              borderRadius: 6,
              border: '1px solid #D2D2D2',
              height: '37.84px',
            }}
            className='bg-white hover:bg-gray-100 px-4 text-mdbase line-height-inherit open-sans'>
            Date selection
          </Menu.Button>
          <Menu.Items
            className={`absolute custom-500:right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ring-gray-300`}>
            <div className='p-1'>
              <Menu.Item>
                {({ active }) => (
                  <div className='flex' style={{ columnGap: 8 }}>
                    {camp.slug === 'lancaster-camp-ground' && (
                      <Button
                        style={{
                          borderRadius: 6,
                          padding: '0.4em 0.6em',
                          border: '1px solid #D2D2D2',
                        }}
                        className='hover:bg-gray-100 z-50'
                        variant='none'
                        onClick={handlePrint}
                        children='Print'
                      />
                    )}
                    <div className='flex' style={{ columnGap: 4 }}>
                      <Button
                        variant='none'
                        children={<ChevronLeftIcon className='w-6 h-6 -ml-1' />}
                        style={{
                          borderRadius: 6,
                          padding: '0.4em 0.6em',
                          width: '35px',
                          border: '1px solid #D2D2D2',
                        }}
                        className='hover:bg-gray-100'
                        onClick={prevMonth}
                      />
                      <Button
                        variant='none'
                        children={<ChevronRightIcon className='w-6 h-6 -mr-1' />}
                        style={{
                          borderRadius: 6,
                          padding: '0.4em 0.6em',
                          width: '35px',
                          border: '1px solid #D2D2D2',
                        }}
                        className='hover:bg-gray-100'
                        onClick={nextMonth}
                      />
                    </div>
                    <Button
                      style={{
                        borderRadius: 6,
                        padding: '0.4em 0.6em',
                        border: '1px solid #D2D2D2',
                      }}
                      className='hover:bg-gray-100'
                      variant='none'
                      onClick={monthlyView}
                      children='Monthly'
                    />

                    <Button
                      style={{
                        borderRadius: 6,
                        padding: '0.4em 0.6em',
                        border: '1px solid #D2D2D2',
                      }}
                      className='hover:bg-gray-100'
                      variant='none'
                      onClick={quarterlyView}
                      children='Quarterly'
                    />
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <ReservationsDatePicker
                    selectedDate={customViewDate}
                    seasonStart={camp.season_start_mm_dd}
                    visible={true}
                    setSelectedDate={setSelectedDate}
                    isQuarterlyView={cookies['isQuarterlyView'] === 'true'}
                    switchToTodayQuarterlyView={switchToTodayQuarterlyView}
                    daysBeforeVisibleAreaForMonthly={daysBeforeVisibleAreaForMonthly}
                    daysBeforeVisibleAreaForQuarterly={daysBeforeVisibleAreaForQuarterly}
                    setTodayDate={setTodayDate}
                    setMonth={setMonth}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                  />
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <div ref={printRef} className='h-full'>
        <FullCalendar
          themeSystem='bootstrap'
          ref={calendarRef}
          key={key}
          schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
          plugins={[resourceTimelinePlugin, interactionPlugin, luxon2Plugin]}
          timeZone={camp.timezone}
          initialView={
            props.isQuarterlyView ? 'resourceTimelineQuarterly' : 'resourceTimelineDefault'
          }
          height='100%'
          slotDuration='24:00' // Needed for time sensitivity
          initialDate={
            window.innerWidth > 750
              ? props.calendarFromDate.subtract(2, 'days').toDate()
              : props.calendarFromDate.toDate()
          }
          scrollTime='00:00:00'
          stickyHeaderDates={true}
          headerToolbar={{
            start: window.innerWidth > 500 && 'title', // will normally be on the left. if RTL, will be on the right
            // center: '',
            end: '', // will normally be on the right. if RTL, will be on the left
          }}
          slotLabelFormat={[{ month: 'short' }, { day: 'numeric', weekday: 'short' }]}
          views={{
            resourceTimelineDefault: {
              type: 'resourceTimeline',
              buttonText: 'Monthly',
              slotDuration: '24:00',
              slotLabelFormat: [{ month: 'short' }, { day: 'numeric', weekday: 'short' }],
              slotLabelDidMount: () => {
                if (!(calendarRef && calendarRef.current)) return
                const calendarApi = calendarRef.current.getApi()
                props.setCalendarFromDate(moment(calendarApi.view.activeStart))
                props.setCalendarDuration(
                  moment(calendarApi.view.activeEnd).diff(calendarApi.view.activeStart, 'days')
                )
              },
              slotMinWidth: 100,
            },
            resourceTimelineQuarterly: {
              type: 'resourceTimeline',
              buttonText: 'Quarterly',
              slotLabelDidMount: () => {
                if (!(calendarRef && calendarRef.current)) return
                const calendarApi = calendarRef.current.getApi()
                props.setCalendarFromDate(moment(calendarApi.view.activeStart))
                props.setCalendarDuration(
                  moment(calendarApi.view.activeEnd).diff(calendarApi.view.activeStart, 'days')
                )
              },
              duration: {
                months: 3,
                days: props.isQuarterlyView
                  ? daysBeforeVisibleAreaForQuarterly
                  : daysBeforeVisibleAreaForMonthly,
              },
              slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
              slotMinWidth: null,
            },
          }}
          // slotLabelFormat={[{ month: 'short' }, { day: 'numeric', weekday: 'short' }]}
          slotMinWidth={100}
          titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
          slotLabelClassNames='text-black'
          duration={{ days: props.calendarDuration }}
          nowIndicator
          resourceAreaColumns={[
            {
              field: 'siteName',
              headerContent: 'Site',
              width: window.innerWidth > 750 ? 105 : 100,
            },
          ]}
          resources={props.sites
            .sort(sortedSites)
            .map((site: SiteProps) => {
              const siteCampingStyle = campingStyles.find((cs) => cs.id === site.camping_style_id)
              const sortedStyle = siteCampingStyle?.name === CAMPING_STYLES.SEASONAL_RV ? CAMPING_STYLES.RV : siteCampingStyle?.name
              return {
                id: site.id,
                siteName: (
                  <div
                    className={`px-4 -ml-3 -mt-8 -mb-2 flex gap-x-3 font-medium ${
                      currentUserRole === 'admin'
                        ? 'hover:bg-gray-100 cursor-pointer'
                        : 'cursor-default'
                    }`}
                    style={{
                      fontFamily: 'Open Sans',
                      lineHeight: '1',
                      paddingRight: 170,
                      paddingTop: '2.5px',
                      paddingBottom: '2.5px',
                    }}
                    onClick={() => {
                      if (currentUserRole == 'admin') setSelectedSite(site)
                    }}>
                    {window.innerWidth > 750 && (
                      <SiteIcons
                        site={site}
                        size='9'
                        campingStyle={siteCampingStyle}
                        isCalendarView
                      />
                    )}
                    <div
                      className='flex flex-col items-start justify-center'
                      style={{ lineHeight: 'normal' }}>
                      <span
                        className='text-baselg focus:outline-none mr-3.5'
                        style={{ color: '#222' }}>
                        {site.name}
                      </span>
                      <div
                        className='flex text-xs items-center gap-x-1 text -mt-0.5'
                        style={{ color: '#888' }}>
                        {[
                          site.electric &&
                            site.electric !== 'none' &&
                            (site.electric === 'basic' ? 'Electric' : `${site.electric}A`),
                          site.max_rig_length && `${site.max_rig_length}ft`,
                          site.water && 'W',
                          site.sewage && 'S',
                          site.minimum_capacity && `${site.minimum_capacity} min`,
                          site.camper_capacity && `${site.camper_capacity} max`,
                        ]
                          .filter(Boolean)
                          .map((item, index, array) => (
                            <React.Fragment key={item}>
                              {item}
                              {index < array.length - 1 && (
                                <div className='w-0.75 h-0.75 bg-gray-400 rounded-full' />
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                ),
                eventBackgroundColor: '#ffffff',
                eventBorderColor: '#ffffff',
                style: sortedStyle,
                styleSort: siteCampingStyle?.sort,
                sort: site.sort,
                ui: {
                  textColor: 'red',
                },
              }
            })
            .sort((a, b) =>
              a.styleSort === null ? 1 : b.styleSort === null ? -1 : a.styleSort - b.styleSort
            )}
          resourceGroupLabelContent={(arg) => {
            const campingStyle = campingStyles.find((cs) => cs.name === arg.groupValue)
            return (
              <div className='flex items-center w-full justify-between'>
                <span>{formatCampingStyleName(campingStyle?.name)}</span>
                <Cog6ToothIcon
                  className='w-4.5 h-4.5 text-gray-500 cursor-pointer hover:text-gray-800'
                  onClick={() => {
                    campingStyle && setSelectedCampingStyle(campingStyle)
                  }}
                />
              </div>
            )
          }}
          resourceGroupField='style'
          resourceOrder='styleSort,sort'
          resourceAreaWidth={window.innerWidth > 750 ? 180 : 100}
          resourceLaneClassNames={(arg) =>
            !props.sites.find((s) => s.id === arg.resource.id).is_bookable && 'bg-gray-150'
          }
          events={events}
          eventContent={(eventInfo) => renderEventContent(eventInfo)}
          editable
          eventOverlap={true}
          eventChange={async (e) => {
            const event = e.event
            const resourceId = event.getResources().map((res) => res.id)[0]

            const persistedReservation: ReservationProps =
              reservationStates[event.extendedProps.reservationId].persisted

            const newStartDate = moment(event.start)
            const newEndDate = moment(event.end)
            const oldStartDate = moment(persistedReservation.start_date)
            const oldEndDate = moment(persistedReservation.end_date)

            let changedFields: ChangedFieldsProps = {}

            if (resourceId != persistedReservation.site_id) {
              changedFields['siteId'] = resourceId
            } else {
              changedFields['siteId'] = null
            }

            if (!newStartDate.isSame(oldStartDate, 'day')) {
              changedFields['startDate'] = newStartDate
            } else {
              changedFields['startDate'] = null
            }

            if (!newEndDate.isSame(oldEndDate, 'day')) {
              changedFields['endDate'] = newEndDate
            } else {
              changedFields['endDate'] = null
            }

            props.changeReservation(event.id, changedFields)
          }}
          selectable={currentUserRole != 'view-only'}
          selectMirror
          select={onDateSelect}
          longPressDelay={300}
          eventLongPressDelay={300}
          selectLongPressDelay={300}
          eventDidMount={eventProperties}
        />
      </div>
    </>
  )
}

export default Calendar
