import React, { useContext, useEffect, useMemo, useState } from 'react'
import { IReservationState } from '../../../interfaces/IReservationStates'
import { FormProvider } from '../../../contexts/FormContext'
import { ReservationProps, UserProps } from '../../../interfaces'
import { RESERVATION_KINDS, reservationKind, reservationStatus } from '../../../constants'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import useAdminForm from '../../../hooks/useAdminForm'
import { AdminContext } from '../../../contexts/AdminContext'
import api from '../../../api2'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import SelectInput, { Option } from '../../ui/SelectInput'
import { sendingOptions } from '../../Reservations/sendingOptions'
import ILongTermReservation from '../../../interfaces/ILongTermReservation'
import IReservationWithErrors from '../../../interfaces/IReservationWithError'
import UpdatingReservationsTable from './UpdatingReservationsTable'
import Button from '../../ui/Button'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import eSendingMode from '../../../enums/eSendingMode'
import { useCookies } from 'react-cookie'
import eSendingModeCookieName from '../../../enums/eSendingModeCookieName'
import IOrder from '../../../interfaces/IOrder'
import Spinner from '../../ui/Spinner'
import { validElectric } from '../../../types'
import { useDebouncedCallback } from 'use-debounce'

export interface IUpdatingFormData {
  reservations: IUpdatingReservation[]
}

export interface IUpdatingReservation {
  id: string
  kind: reservationKind
  long_term_reservation: ILongTermReservation
  is_updating_series?: boolean
  is_auto_updating_price?: boolean
  start_date: string
  end_date: string
  site_id: string
  status: reservationStatus
  deleted_at: string
  passengers: number
  kid_campers: number
  user?: UserProps
  total_owed_in_cents: number
  order?: IOrder
  is_loading: boolean
  old_values: Omit<
    IUpdatingReservation,
    | 'old_values'
    | 'kind'
    | 'long_term_reservation'
    | 'is_loading'
    | 'order'
    | 'is_auto_updating_price'
  >
  admin_email?: string
}

export const mapStatesToFormData = (
  states: IReservationState[],
  campEmail?: string,
  isShowingCc?: boolean
): IUpdatingReservation[] => {
  return states
    .map(({ persisted, updated }) => {
      if (!updated) return null

      const canUpdateSeries = persisted.kind === 'recurring' && persisted.site_id != updated.site_id

      return {
        id: persisted.id,
        kind: persisted.kind,
        long_term_reservation: persisted.long_term_reservation,
        is_updating_series: canUpdateSeries,
        is_auto_updating_price: false,
        start_date: updated.start_date,
        end_date: updated.end_date,
        site_id: updated.site_id,
        status: updated.status,
        deleted_at: updated.deleted_at,
        passengers: updated.passengers,
        kid_campers: updated.kid_campers,
        user: updated.user,
        total_owed_in_cents: updated.total_owed_in_cents,
        is_loading: true,
        old_values: {
          id: persisted.id,
          start_date: persisted.start_date,
          end_date: persisted.end_date,
          site_id: persisted.site_id,
          status: persisted.status,
          deleted_at: persisted.deleted_at,
          passengers: persisted.passengers,
          kid_campers: persisted.kid_campers,
          user: persisted.user,
          total_owed_in_cents: persisted.total_owed_in_cents,
        },
        admin_email: isShowingCc ? campEmail : null,
      }
    })
    .filter((r) => r)
}

interface ComponentProps {
  reservationStates: IReservationState[]
  customCallback?: (data: IUpdatingReservation[]) => Promise<any>
}

const ConfirmationModalContent = (props: ComponentProps) => {
  const { camp } = useContext(AdminContext)
  const [isShowingCc, setIsShowingCc] = useState(false)

  return (
    <FormProvider
      init={{
        reservations: mapStatesToFormData(props.reservationStates, camp.email, isShowingCc),
      }}>
      <UpdatingReservationsForm
        isShowingCc={isShowingCc}
        setIsShowingCc={setIsShowingCc}
        customCallback={props.customCallback}
      />
    </FormProvider>
  )
}

const cookieSendingMode = eSendingModeCookieName.RESERVATION_CHANGES

export const rebuildOrderFor = async (reservation: IUpdatingReservation): Promise<IOrder> => {
  try {
    const { order } = await api.Reservations.PriceDetails.regenerate(reservation.id, {
      siteId: reservation.site_id,
      startDate: reservation.start_date,
      endDate: reservation.end_date,
      numberOfAdults: reservation.passengers,
      numberOfKids: reservation.kid_campers,
      userElectric: reservation.user?.vehicle_electric as validElectric,
    })

    return order
  } catch (err) {
    console.error(err)
  }
}

const UpdatingReservationsForm = ({
  customCallback,
  isShowingCc,
  setIsShowingCc,
}: {
  customCallback?: (data: IUpdatingReservation[]) => Promise<any>
  isShowingCc: boolean
  setIsShowingCc: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { onClose } = useContext(ModalContext)
  const { camp } = useContext(AdminContext)
  const { resource, setValue } = useAdminForm<IUpdatingFormData>()
  const [failedReservations, setFailedReservations] = useState<IReservationWithErrors[]>([])
  const [succeededReservations, setSucceededReservations] = useState<ReservationProps[]>([])
  const [cookies, setCookie] = useCookies([cookieSendingMode])
  const [isLoading, setIsLoading] = useState(false)

  const { selectedSendingMode, setSelectedSendingMode, updateLocalReservations } =
    useContext(AdminContext)

  const handleClickCancel = () => {
    onClose()
  }

  const handleClickUpdate = () => {
    setIsLoading(true)
    const queuedReservations = resource.reservations.filter(
      (r) => succeededReservations.findIndex((s) => s.id === r.id) === -1
    )
    console.log('isSubmitting')

    if (customCallback) {
      setIsLoading(true)
      customCallback(queuedReservations)
        .then(() => {
          onClose()
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.reservations) {
            setSucceededReservations(err.reservations)
            updateLocalReservations(err.reservations)
          }
          if (err.records) {
            setFailedReservations(err.records)
          } else if (err.record) {
            setFailedReservations([err.record])
          } else {
            console.error(err)
          }
          setIsLoading(false)
        })
    } else {
      api.Reservations.updateEach({
        reservations: queuedReservations,
        sending_mode: selectedSendingMode,
        admin_email: isShowingCc ? camp.email : undefined,
      })
        .then(({ reservations }) => {
          setSucceededReservations(reservations)
          updateLocalReservations(reservations)
          onClose()
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.reservations) {
            setSucceededReservations(err.reservations)
            updateLocalReservations(err.reservations)
          }
          if (err.records) {
            setFailedReservations(err.records)
          } else if (err.record) {
            setFailedReservations([err.record])
          } else {
            console.error(err)
          }
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (Object.values(eSendingMode).includes(cookies[cookieSendingMode]) && cookies[cookieSendingMode]) {
      setSelectedSendingMode(cookies[cookieSendingMode])
    }
    if (!cookies[cookieSendingMode]) {
      setCookie(cookieSendingMode, selectedSendingMode)
    }
  
    fetchNewPrices()
  }, [])

  useEffect(() => {
    fetchNewPrices()
  }, [resource.reservations])

  useEffect(() => {
    !(
      selectedSendingMode === eSendingMode.EMAIL ||
      selectedSendingMode === eSendingMode.EMAIL_AND_TEXT
    ) && setIsShowingCc(false)
    selectedSendingMode && setCookie(cookieSendingMode, selectedSendingMode)
  }, [selectedSendingMode])

  const fetchNewPrices = useDebouncedCallback(async () => {
    resource.reservations.forEach(async (res, index) => {
      if (res.kind == RESERVATION_KINDS.RECURRING) return
      const order = await rebuildOrderFor(res)
      const updatedRes = {
        ...res,
        total_owed_in_cents: order.total_in_cents,
        order: order,
      }
      setValue(`reservations.${index}`, updatedRes)
    })
  }, 50)

  return (
    <div className='bg-white rounded-lg text-left overflow-visible transform transition-all'>
      {isLoading && (
        <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-center justify-center'>
          <Spinner size={12} />
        </div>
      )}
      <div className='mb-6 sm:mb-10'>
        <div className='sm:flex sm:items-start flex-shrink'>
          <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10'>
            <ExclamationCircleIcon className='h-6 w-6 text-yellow-600' aria-hidden='true' />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
            <div className='text-lg leading-6 font-medium text-gray-900'>Update Reservations</div>
            <div className='mt-2'>
              <p className='text-lg text-gray-500 mb-3 mt-1'>
                You're updating a reservation for the following camper
                {resource.reservations?.length > 1 && 's'}:
              </p>
              <UpdatingReservationsTable
                succeededReservations={succeededReservations}
                failedReservations={failedReservations}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='sm:flex sm:flex-row justify-end items-end pb-px'>
        <div className='mr-0.5 overflow-visible flex items-center flex-col-reverse md:flex-row-reverse'>
          <SelectInput
            name='sendingOptions'
            width='w-72'
            isBottomPlacement
            large
            options={sendingOptions}
            selected={sendingOptions.find((o) => o.value === selectedSendingMode)}
            setSelected={(o: Option) => {
              setSelectedSendingMode(o.value)
            }}
          />
          {(selectedSendingMode === eSendingMode.EMAIL ||
            selectedSendingMode === eSendingMode.EMAIL_AND_TEXT) && (
            <div className='flex items-center md:mr-3 mb-2 md:mb-0'>
              <CheckboxWithLabel
                label={`cc ${camp.email}`}
                value={isShowingCc}
                setValue={setIsShowingCc}
                labelClasses='text-sm whitespace-nowrap'
              />
            </div>
          )}
        </div>
        <div className='flex sm:inline mt-2 sm:mt-0'>
          <Button
            type='button'
            variant='gray'
            className='sm:ml-2 mr-2.5 w-1/2 sm:w-32 rounded-md'
            onClick={handleClickCancel}>
            Cancel
          </Button>
          <Button
            type='button'
            variant='blue'
            className='w-1/2 sm:w-32'
            onClick={handleClickUpdate}>
            Update
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModalContent
