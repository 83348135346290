import React, { useContext } from 'react'
import { Form, Select } from 'semantic-ui-react'
import { BookerContext } from '../../../contexts/BookerContext'
import { CampProps } from '../../../interfaces'
import pluralize from '../../../utils/pluralize'

interface ComponentProps {
  camp: CampProps
  userPassengers: number
  handlePassengersChange: (event, more) => any
  userKidCampers: number
  handleKidCampersChange: (event, more) => void
}

const PassengersField = ({
  camp,
  userPassengers,
  handlePassengersChange,
  userKidCampers,
  handleKidCampersChange,
}: ComponentProps) => {
  const { campingStyle, isKidsPrereqPresent } = useContext(BookerContext)

  // Hardcode increased passenger limit for Camps, which books the whole campground for up to 150 people at a time
  let numberOfPossibleAdults =
    campingStyle.adults_capacity ||
    (['camp-cottonwood', 'camp-currie'].includes(camp.slug) ? 300 : 20)
  let numberOfPossibleKids = campingStyle.kids_capacity || numberOfPossibleAdults

  if (campingStyle.guest_capacity) {
    numberOfPossibleAdults = campingStyle.adults_capacity
      ? Math.min(campingStyle.adults_capacity, campingStyle.guest_capacity - (userKidCampers || 0))
      : campingStyle.guest_capacity - (userKidCampers || 0)
    numberOfPossibleKids = campingStyle.kids_capacity
      ? Math.min(campingStyle.kids_capacity, campingStyle.guest_capacity - (userPassengers || 0))
      : campingStyle.guest_capacity - (userPassengers || 0)
  }

  const passengersOptions = [null]
    .concat(Array.from(new Array(numberOfPossibleAdults), (x, i) => i + 1))
    .map((x) => {
      return { key: x, value: x, text: x }
    })

  const kidCampersOptions = [null]
    .concat(Array.from(new Array(numberOfPossibleKids + 1), (x, i) => i))
    .map((x) => {
      return { key: x, value: x, text: x }
    })

  return (
    <>
      <div className='mt-4 mb-4'>
        <div className='text-md font-bold'>Guest info</div>
        {campingStyle.guest_capacity && (
          <div className='text-xs text-gray-400 mb-1'>
            {`Max ${campingStyle.guest_capacity} ${pluralize(
              'guest',
              campingStyle.guest_capacity
            )} total`}
          </div>
        )}
      </div>
      <Form.Field required>
        <label>
          Number of {isKidsPrereqPresent ? 'adults' : 'guests'}{' '}
          {campingStyle.extra_passenger_age && `(aged ${campingStyle.extra_passenger_age} and up)`}
        </label>
        <Select
          clearable
          fluid
          search
          className='sites-filter-dropdown'
          options={passengersOptions}
          value={userPassengers}
          onChange={handlePassengersChange}
        />
      </Form.Field>
      {isKidsPrereqPresent && (
        <Form.Field required>
          <label>
            Number of kids{' '}
            {!!campingStyle.extra_kid_campers_age
              ? `(aged ${campingStyle.extra_kid_campers_age}-${
                  campingStyle.extra_passenger_age - 1
                })`
              : !!campingStyle.extra_passenger_age
              ? `(aged ${campingStyle.extra_passenger_age - 1} and under)`
              : ''}
          </label>
          <Select
            clearable
            fluid
            search
            className='sites-filter-dropdown'
            options={kidCampersOptions}
            value={userKidCampers}
            onChange={handleKidCampersChange}
          />
        </Form.Field>
      )}
    </>
  )
}

export default PassengersField
