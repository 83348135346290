import React, { useContext } from 'react'
import { Transition } from 'semantic-ui-react'
import { SiteProps } from '../../interfaces'
import { titleCase } from '../../utils'
import Button from '../ui/Button'
import { KINDS } from '../../constants'
import { BookerContext } from '../../contexts/BookerContext'

interface ComponentProps {
  site: SiteProps | null
  changeSiteCallback: any
}

const SelectedSite = (props: ComponentProps) => {
  const { secondaryColor } = useContext(BookerContext)

  let siteLabel: string
  let siteElectric: string
  let siteOrientation: string
  let selectedSiteText = ''

  if (props.site) {
    siteLabel = props.site.name
    siteElectric = props.site.electric && props.site.electric != 'none' && `${props.site.electric} Amp`
    siteOrientation = titleCase(props.site.kind)

    if (
      (props.site.kind == KINDS.RV_PULLTHROUGH ||
      props.site.kind == KINDS.RV_BACKIN ||
      props.site.kind == KINDS.RV_HYBRID) && siteElectric
    ) {
      selectedSiteText = `${siteLabel} - ${siteElectric + ' '}${siteOrientation}`
    } else {
      selectedSiteText = siteLabel
    }
  }

  return (
    <Transition.Group animation='fade right'>
      <div className='ui selection-header'>{selectedSiteText}</div>
      <Button
        variant='none'
        style={{ borderColor: secondaryColor, color: secondaryColor }}
        className='hover:opacity-80 float-right border-2 bg-white'
        size='normal'
        onClick={() => props.changeSiteCallback()}>
        Change
      </Button>
    </Transition.Group>
  )
}

export default SelectedSite
