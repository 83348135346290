const NONE: 'none' = 'none'
const BASIC: 'basic' = 'basic'
const E15: '15' = '15'
const E20: '20' = '20'
const E30: '30' = '30'
const E50: '50' = '50'

const SPLIT_15_30: '15/30' = '15/30'
const SPLIT_20_30: '20/30' = '20/30'
const SPLIT_30_50: '30/50' = '30/50'
const SPLIT_15_30_50: '15/30/50' = '15/30/50'
const SPLIT_20_30_50: '20/30/50' = '20/30/50'

const ELECTRICS = {
  NONE,
  BASIC,
  E15,
  E20,
  E30,
  E50,
  SPLIT_15_30,
  SPLIT_20_30,
  SPLIT_30_50,
  SPLIT_15_30_50,
  SPLIT_20_30_50
}

export { ELECTRICS }
