import React, { useContext, useEffect, useState } from 'react'
import IHistoryRecord from '../../../interfaces/IHistoryRecord'
import classNames from '../../../utils/classNames'
import { ArrowUturnLeftIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import api from '../../../api2'
import { useDebouncedCallback } from 'use-debounce'
import { DateTime } from 'luxon'
import { AdminContext } from "../../../contexts/AdminContext"
import { ArrowTopRightOnSquareIcon, ArrowUpRightIcon, ReceiptRefundIcon } from "@heroicons/react/20/solid"
import HoverTip from "../../ui/HoverTip"
import Button from "../../ui/Button"
import IOrder from "../../../interfaces/IOrder"
import RefundModalContents from "../../Reservations/RefundModal/RefundModalContents"
import IPayment from '../../../interfaces/IPayment'

interface ComponentProps {
  reservationId: string
  orderId: string
  setSlideoverOpen: (value: boolean) => void
}

const ReservationHistory = (props: ComponentProps) => {
  const { camp, showModal } = useContext(AdminContext)
  const [isLoading, setIsLoading] = useState(true)
  const [history, setHistory] = useState<IHistoryRecord[]>([])
  const [order, setOrder] = useState<IOrder>()
  const [isLoadingOrder, setIsLoadingOrder] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    debouncedHistory()
    debouncedOrder()
  }, [])

  const debouncedOrder = useDebouncedCallback(() => {
    api.Orders.fetch(props.orderId)
      .then((resp) => {
        setOrder(resp.order)
        setIsLoadingOrder(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoadingOrder(false)
      })
  }, 100)

  const debouncedHistory = useDebouncedCallback(() => {
    api.Reservations.history(props.reservationId)
      .then((resp) => {
        setHistory(resp.history)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }, 100)

  const handleClickRefund = (payment: IPayment, externalUrl: string) => {
    props.setSlideoverOpen(false)
    showModal(
      <RefundModalContents
        payment={payment}
        initialAmount={payment.amount_in_cents / 100}
        externalUrl={externalUrl}
      />,
      true,
      'max-w-2xl',
      {
        closeCallback: () => {
          debouncedHistory()
          props.setSlideoverOpen(true)
        },
      }
    )
  }

  if (isLoading) {
    return (
      <ul role='list' className='mt-3 space-y-6'>
        {Array.from({ length: 3 }).map((_, index) => (
          <li key={index} className='relative flex gap-x-4'>
            <div
              className={classNames(
                index === 2 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center'
              )}>
              <div className='w-px bg-gray-200' />
            </div>
            <div className='relative flex h-6 w-6 flex-none items-center justify-center bg-white'>
              <div className='h-4 w-4 rounded-full bg-gray-200 animate-pulsing' />
            </div>
            <div className='flex-auto py-0.5'>
              <div className='h-3 w-48 rounded-md bg-gray-200 animate-pulsing' />
            </div>
            <div className='flex-none py-0.5'>
              <div className='h-3 w-20 rounded-md bg-gray-200 animate-pulsing' />
            </div>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <ul role='list' className='mt-3 space-y-6'>
      {history.map((record, index) => (
        <li key={index} className='relative flex gap-x-4'>
          <div
            className={classNames(
              index === history.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center'
            )}>
            <div className='w-px bg-gray-200' />
          </div>
          <>
            <div className='relative flex h-6 w-6 flex-none items-center justify-center bg-white'>
              {record.kind === 'paid' ? (
                <CheckCircleIcon className='h-4 w-4 text-green-600' aria-hidden='true' />
              ) : record.label === 'Reservation deleted' ? (
                <XCircleIcon className='h-4 w-4 text-red-600' aria-hidden='true' />
              ) : record.kind === 'refund' ? (
                <ArrowUturnLeftIcon className='h-4 w-4 text-yellow-600' aria-hidden='true' />
              ) : (
                <div className='h-2 w-2 rounded-full bg-gray-100 ring-1 ring-gray-300' />
              )}
            </div>
            <p className='flex flex-col py-0.5 text-md leading-5 text-gray-500'>
              <div className='flex justify-between'>
                {record.external_url ? (
                  <HoverTip xs placement='top' content={record.external_url.label}>
                    <a
                      className='flex items-center text-blue-500 hover:underline cursor-pointer'
                      onClick={() => {
                        window.open(record.external_url.url, '_blank')
                      }}>
                      <span className='font-semibold'>{record.label}</span>
                      <ArrowTopRightOnSquareIcon className='h-4 w-4 ml-2' />
                    </a>
                  </HoverTip>
                ) : (
                  <span className='font-semibold text-gray-900'>{record.label}</span>
                )}
              </div>
              {record.kind === 'refund' && record.resource.reason && (
                <span className='text-gray-450'>Reason: {record.resource.reason}</span>
              )}
              <time
                dateTime={record.datetime}
                className='py-0.5 text-sm leading-5 text-gray-400 italic'>
                {DateTime.fromISO(record.datetime, { zone: camp.timezone }).toFormat(
                  'LLL d, yyyy h:mm a'
                )}
              </time>
            </p>
            <div className='flex flex-grow justify-end'>
              {record.kind === 'paid' && record.external_url && record.resource && (
                <Button
                  type='button'
                  variant='white'
                  size='small'
                  shadow='small'
                  disabled={!order}
                  className={classNames(
                    'flex-shrink-0 justify-self-end flex text-gray-600 h-9',
                    !order && 'cursor-not-allowed opacity-30'
                  )}
                  onClick={() =>
                    handleClickRefund(record.resource as IPayment, record.external_url.url)
                  }
                  children={
                    <>
                      <ReceiptRefundIcon className='w-5 h-5 mr-2' />
                      Refund
                    </>
                  }
                />
              )}
            </div>
          </>
        </li>
      ))}
    </ul>
  )
}

export default ReservationHistory
