import React, { useContext, useState } from 'react'
import Form from '../../../ui/Admin/Form'
import { AdminContext } from '../../../../contexts/AdminContext'
import electricOptionsFor from '../../../../utils/admin/electricOptionsFor'
import { RIG_TYPES } from '../../../../constants/rigTypes'
import { formatPhoneNumber, titleCase } from '../../../../utils'
import { InformationCircleIcon, TruckIcon } from '@heroicons/react/24/outline'
import useAdminForm from '../../../../hooks/useAdminForm'
import ICamper from '../../../../interfaces/ICamper'
import CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD from '../../../../constants/camp_slugs_with_company_name_field'

const Info = () => {
  const { camp, sites } = useContext(AdminContext)
  const { watch } = useAdminForm<Partial<ICamper>>()

  const [isShowingEmergencyContact, setIsShowingEmergencyContact] = useState(
    !!(
      watch('emergency_contact_name') ||
      watch('emergency_contact_relationship') ||
      watch('emergency_contact_email') ||
      watch('emergency_contact_phone')
    )
  )

  const [isShowingAdditonalVehicle, setIsShowingAdditionalVehicle] = useState(
    !!(
      watch('additional_vehicle_make') ||
      watch('additional_vehicle_year') ||
      watch('additional_vehicle_color') ||
      watch('additional_vehicle_license_plate')
    )
  )

  const electricOptions = electricOptionsFor(sites)
  const rigTypeOptions = [null]
    .concat(Object.values(RIG_TYPES))
    .map((rigType) => ({ key: rigType, value: titleCase(rigType), label: titleCase(rigType) }))

  return (
    <form className='mb-8 sm:mb-32'>
      <div className='flex-col'>
        <Form.RichTextInput field='notes' label='Notes' />
        <div className='my-6 space-y-1'>
          <div className='text-lgxl font-semibold text-gray-900'>Basic info</div>
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.TextInput field='first_name' label='First name' />
          <Form.TextInput field='last_name' label='Last name' />
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.TextInput field='email' label='Email' inputType='email' />
          <Form.TextInput field='phone' label='Phone' formatValue={formatPhoneNumber} />
        </div>
        {/* NOTE: used to replace full name on event calendar when present for specific camps */}
        {CAMP_SLUGS_WITH_CAMPER_COMPANY_NAME_FIELD.includes(camp.slug) && (
          <div className='grid grid-cols-2 gap-6'>
            <Form.TextInput field='company_name' label='Company name' />
          </div>
        )}
        <div className='grid grid-cols-1'>
          <Form.TextAreaInput className='w-full' field='address' label='Address' rows={2} />
        </div>
        <div className='my-6 space-y-1'>
          <div className='text-lgxl font-semibold text-gray-900'>Default passengers</div>
        </div>
        <div className='grid grid-cols-3 gap-6'>
          <Form.NumberInput field='default_adults_count' label='Adults' />
          <Form.NumberInput field='default_kids_count' label='Kids' />
          <Form.TextInput field='default_pets_info' label='Pets' />
        </div>
        {!isShowingEmergencyContact ? (
          <button
            className='border-2 border-dashed border-gray-300 p-4 h-16 w-64 mt-4 flex items-center rounded-md cursor-pointer hover:bg-gray-75 text-gray-600'
            onClick={() => {
              if (!isShowingEmergencyContact) {
                setIsShowingEmergencyContact(true)
              }
            }}>
            <InformationCircleIcon className='h-6 w-6 mr-2' />
            Add emergency contact
          </button>
        ) : (
          <>
            <div className='my-6 space-y-1'>
              <div className='text-lgxl font-semibold text-gray-900'>Emergency contact info</div>
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <Form.TextInput field='emergency_contact_name' label='Name' />
              <Form.TextInput field='emergency_contact_relationship' label='Relationship' />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <Form.TextInput field='emergency_contact_email' label='Email' inputType='email' />
              <Form.TextInput field='emergency_contact_phone' label='Phone' />
            </div>
          </>
        )}
        <div className='my-6 space-y-1'>
          <div className='text-lgxl font-semibold text-gray-900'>Vehicle info</div>
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.TextInput field='license_plate' label='License Plate' />
          <Form.SelectInput field='vehicle_rig_type' label='RV Type' options={rigTypeOptions} />
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.TextInput field='vehicle_rig_length' label='Vehicle Rig Length' />
          <Form.SelectInput field='vehicle_electric' label='Amperage' options={electricOptions} />
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.TextInput field='vehicle_make' label='Vehicle Make & Model' />
          <Form.TextInput field='vehicle_color' label='Vehicle Color' />
        </div>
        <div className='grid grid-cols-2 gap-6'>
          <Form.NumberInput field='vehicle_year' label='Vehicle Year' />
        </div>
      </div>

      {!isShowingAdditonalVehicle ? (
        <button
          className='border-2 border-dashed border-gray-300 p-4 h-16 w-64 mt-4 flex items-center rounded-md cursor-pointer hover:bg-gray-75 text-gray-600'
          onClick={() => {
            if (!isShowingAdditonalVehicle) {
              setIsShowingAdditionalVehicle(true)
            }
          }}>
          <TruckIcon className='h-6 w-6 mr-2' />
          Add additional vehicle
        </button>
      ) : (
        <>
          <div className='my-6 space-y-1'>
            <div className='text-lgxl font-semibold text-gray-900'>Additional vehicle info</div>
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <Form.TextInput field='additional_vehicle_license_plate' label='License Plate' />
            <Form.TextInput field='additional_vehicle_color' label='Color' />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <Form.TextInput field='additional_vehicle_make' label='Make & Model' />
            <Form.TextInput field='additional_vehicle_year' label='Year' />
          </div>
        </>
      )}
    </form>
  )
}

export default Info
