import React, { useContext, useEffect, useState } from "react"
import { FormContext } from "../../../../contexts/FormContext"
import classNames from "../../../../utils/classNames"
import { formatMoney } from "../../../../utils/formatMoney"

interface ComponentProps {
  field: string
  label?: string
  prefix?: string
  suffix?: string
  suffixButtonCta?: string | JSX.Element
  placeholder?: string
  onSuffixButtonClick?: () => void
  className?: string
  hidden?: boolean
  disabled?: boolean
  stopPropagation?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void
  asText?: boolean
  isIntegerOnly?: boolean
}

const NumberInput = ({
  field,
  label,
  prefix,
  suffix,
  onSuffixButtonClick,
  suffixButtonCta,
  placeholder,
  className,
  hidden,
  disabled,
  stopPropagation,
  onChange,
  onInput,
  asText,
  isIntegerOnly
}: ComponentProps) => {
  const { resource, register, isChanged, watch, errors, clearError } = useContext(FormContext)

  if (asText) {
    return (
      <div className={classNames(className, 'flex items-center', hidden ? 'hidden' : '')}>
        {prefix === '$' ? formatMoney(watch(field)) : watch(field)}
      </div>
    )
  }

  const { onChange: registeredOnChange, ...registered } = register(field)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isIntegerOnly && ['.', ','].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <div className={classNames(className, hidden && 'hidden', disabled && 'opacity-30')}>
      {label && (
        <label
          htmlFor={field}
          className={classNames(
            'mb-1 block text-md font-medium',
            errors[field] ? 'text-red-600' : 'text-gray-900'
          )}>
          {label}
          {errors[field] && (
            <span className='ml-1 text-red-500 text-xs'>{errors[field].message}</span>
          )}
        </label>
      )}
      <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
        {prefix && (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            <span className='text-gray-500 sm:text-sm'>{prefix}</span>
          </div>
        )}
        <input
          {...registered}
          onChange={(e) => {
            if (errors[field]) clearError(field)
            if (onChange) onChange(e)
            registeredOnChange(e)
          }}
          onKeyDown={handleKeyDown}
          onInput={onInput}
          type='number'
          placeholder={placeholder}
          hidden={!!hidden}
          min={0}
          defaultValue={resource[field]}
          onClick={(e) => stopPropagation && e.stopPropagation()}
          disabled={disabled}
          className={classNames(
            'block w-full',
            prefix && 'pl-8',
            suffix && 'md:pr-12',
            'rounded-md focus:border-green-700 focus:ring-green-700',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            errors[field] ? 'border-red-600' : 'border-gray-300',
            isChanged(field) ? 'bg-blue-50' : 'bg-white'
          )}
          step='any'
        />
        {suffix && (
          <div
            className='hidden md:flex pointer-events-none absolute inset-y-0 items-center pr-3'
            style={{ right: suffixButtonCta && onSuffixButtonClick ? '7rem' : '0rem' }}>
            <span className='text-gray-500 sm:text-sm' id='price-currency'>
              {suffix}
            </span>
          </div>
        )}
        {onSuffixButtonClick && suffixButtonCta && (
          <button
            type='button'
            className='relative w-44 -ml-px inline-flex justify-center items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 focus:border-green-700 focus:ring-green-700 focus:ring-1'
            onClick={onSuffixButtonClick}>
            {suffixButtonCta}
          </button>
        )}
      </div>
    </div>
  )
}

export default NumberInput
