import React from 'react'
import { UseFormRegister, UseFormWatch } from 'react-hook-form'
import classNames from '../../utils/classNames'

interface ComponentProps {
  resource: any
  field: string
  label: string
  isChanged?: boolean // TODO: generalize this internally
  register: UseFormRegister<any>
  prefix?: string
  suffix?: string
  suffixButtonCta?: string | JSX.Element
  onSuffixButtonClick?: () => void
  percentage?: boolean
}

const NumberInput = ({ resource, field, label, register, isChanged, prefix, suffix, suffixButtonCta, onSuffixButtonClick, percentage }: ComponentProps) => {
  const isButtonShown = suffixButtonCta && onSuffixButtonClick

  return (
    <div className=''>
      <label htmlFor={field} className="text-sm font-semibold leading-6 text-gray-900">
        {label}
      </label>
      <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm mt-3'>
        {prefix &&
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            <span className='text-gray-500 sm:text-sm'>{prefix}</span>
          </div>
        }
        <input
          {...register(field)}
          type="number"
          min={0}
          defaultValue={percentage ? resource[field] * 100 :resource[field]} // TODO: update the save so that it divides by 100 for percentage values
          className={classNames(
            'block w-full',
            prefix && 'pl-8',
            suffix && 'pr-12',
            suffixButtonCta ? 'rounded-l-md' : 'rounded-md',
            'border-gray-300 focus:border-green-700 focus:ring-green-700',
            'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
            isChanged ? 'bg-blue-50' : 'bg-white'
          )}
        />
        {suffix &&
          <div
            className='pointer-events-none absolute inset-y-0 flex items-center pr-3'
            style={{ right: isButtonShown ? '7rem' : '0rem' }}>
            <span className='text-gray-500 sm:text-sm' id='price-currency'>
              {suffix}
            </span>
          </div>
        }
        {onSuffixButtonClick && suffixButtonCta &&
          <button
            type='button'
            className='relative w-44 -ml-px inline-flex justify-center items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 focus:border-green-700 focus:ring-green-700 focus:ring-1'
            onClick={onSuffixButtonClick}>
            {suffixButtonCta}
          </button>
        }
      </div>
    </div>
  )
}

export default NumberInput
