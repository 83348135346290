import React, { useState } from 'react'
import { Card, Container, Divider, Menu, Message, Segment } from 'semantic-ui-react'
import { titleCase } from '../utils'
import { CampProps } from '../interfaces'

import SignupForm from './AdminSignup/SignupForm'
import parkLogoPath from '../../assets/images/park_logo__green.svg'

interface componentProps {
  camp: CampProps
}

const AdminSignup = (props: componentProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const handleLoginMenuItemClick = () => {
    window.location.href = `${window.origin}/admin/login`
  }

  return (
    <>
      <Segment>
        <Container>
          <Menu secondary>
            <span className='flex-inline align-middle mr-2'>
              <img src={parkLogoPath} className='inline z-10 h-10' />
            </span>
            <span className='flex relative p-4 mr-4 align-middle leading-none font-bold'>
              {titleCase(props.camp.name)}
            </span>
            <Menu.Menu position='right'>
              <Menu.Item name='login' active={false} onClick={handleLoginMenuItemClick} />
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
      <Container>
        <Message hidden={!errorMessage} error={!!errorMessage} content={errorMessage} />
        <Divider hidden fitted={!!errorMessage} />
      </Container>
      <Container>
        <Segment basic className='flex justify-center'>
          <Card.Group centered className='max-w-xl'>
            <SignupForm campSlug={props.camp.slug} setErrorMessage={setErrorMessage} />
          </Card.Group>
        </Segment>
      </Container>
    </>
  )
}

export default AdminSignup