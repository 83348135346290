import moment, { Moment } from "moment"

const applyTime = (date: Moment, time: string): Moment => {
  if (!date || !time) return date

  const [hours, minutes] = time.split(':')
  return moment(date).hours(parseInt(hours)).minutes(parseInt(minutes))
}

export default applyTime
