import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { FormContext } from '../../../../contexts/FormContext'
import { Controller } from 'react-hook-form'
import classNames from '../../../../utils/classNames'
import { titleCase } from '../../../../utils'
import { desktopToolbar, mobileToolbar } from '../../../../config/quillToolbar'
import juice from 'juice'
import { richTextInputStyles } from '../../../../constants/rich_text_input_styles'
import 'react-quill/dist/quill.snow.css'
interface ComponentProps {
  field: string
  label?: string
  subheader?: string
  inputType?: string
  className?: string
  disabled?: boolean
  required?: boolean
}

export const removeNonColorStyles = (htmlString: string) => {
  const stylePattern: RegExp = /style="([^"]*)"/g

  const colorPattern: RegExp = /(color|background-color):[^;"]+;/g

  if (!htmlString) {
    return ''
  }

  const modifiedHtml: string = htmlString.replace(stylePattern, (match: string) => {
    const styles: string = match.match(/"([^"]*)"/)![1]
    const colorStyles: string[] = styles.match(colorPattern) || []
    return colorStyles.length > 0 ? `style="${colorStyles.join(';')}"` : ''
  })

  return modifiedHtml
}

const RichTextInput = ({
  field,
  label,
  subheader,
  className,
  disabled,
  required,
}: ComponentProps) => {
  const { isChanged, control, resource, errors, clearError } = useContext(FormContext)
  const [isReady, setIsReady] = useState(false)
  const [isFieldDirty, setIsFieldDirty] = useState(false)

  const [quillValue, setQuillValue] = useState('')

  useEffect(() => {
    // Hacky solution to stop from autofocusing
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 100)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setIsFieldDirty(isChanged(field))
  }, [resource])

  return (
    <div className={disabled && 'opacity-30 pointer-events-none'}>
      {label?.length && (
        <div className='mb-1'>
          <label
            htmlFor='name'
            className={classNames(
              'mb-1 block font-medium text-md leading-4',
              errors[field] ? 'text-red-600' : 'text-gray-900'
            )}>
            {label || titleCase(field)}
            {errors[field] && (
              <span className='ml-1 text-red-500 text-xs'>{errors[field].message}</span>
            )}
          </label>
          {subheader && <span className='text-gray-500 font-normal text-sm'>{subheader}</span>}
        </div>
      )}
      <Controller
        name={field}
        control={control}
        rules={required ? { required: 'Content is required' } : {}}
        render={({ field }) => {
          if (!quillValue.length) {
            setQuillValue(removeNonColorStyles(field.value))
          }

          return (
            <ReactQuill
              {...field}
              theme='snow'
              modules={{
                toolbar: window.innerWidth > 550 ? desktopToolbar : mobileToolbar,
              }}
              className={classNames(
                errors[field.name] ? 'border-red-600' : 'border-gray-300',
                isFieldDirty ? 'bg-blue-50' : 'bg-white',
                className
              )}
              value={quillValue}
              readOnly={!isReady || disabled}
              onChange={(v) => {
                if (errors[field.name]) clearError(field.name)
                setQuillValue(v)
                field.onChange(v === '<p><br></p>' ? null : juice(richTextInputStyles + v))
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default RichTextInput
