import handleApiError from '../utils/handle_error'
import handleApiResponse from '../utils/check_ok_response'
import moment from 'moment'
import _ from 'lodash'

const getCsrf = () => document.querySelector("meta[name='csrf-token']").getAttribute('content')

interface RequestOptions {
  headers?: Record<string, string>
}

const request = async (
  path: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  query?: object,
  body?: object,
  options?: RequestOptions
): Promise<any> => {
  const requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrf(),
      ...(options?.headers || {}),
    },
  }

  if (body) requestOptions['body'] = JSON.stringify(body)

  return fetch(path + paramsFor(query), requestOptions)
    .then((response) => { return handleApiResponse(response, options?.headers) })
    .catch(handleApiError)
}

const paramsFor = (query?: object) => {
  if (!query) return ''

  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value !== null)
  )

  const qs = new URLSearchParams(filteredQuery as Record<string, string>)
  return '?' + qs.toString()
}

////////////////////////////////////////////////////////////////////////////////

const get = async (path: string, query?: object, options?: { headers: Record<string, string> }) => {
  return request(path, 'GET', query, null, options)
}
const post = async (path: string, body?: object) => request(path, 'POST', null, body)
const patch = async (path: string, body?: object) => request(path, 'PATCH', null, body)
const destroy = async (path: string, body?: object) => request(path, 'DELETE', null, body)

const transformRequestData = <T extends Record<string, any>>(data: T): Record<string, any> => {
  const transformedData: Record<string, any> = {}

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key]
      const snakeCaseKey = _.snakeCase(key)

      if (moment.isMoment(value)) {
        transformedData[snakeCaseKey] = value.toISOString()
      } else if (Array.isArray(value)) {
        transformedData[snakeCaseKey] = value.map((v) => transformRequestData(v))
      } else if (typeof value === 'object' && value !== null) {
        transformedData[snakeCaseKey] = transformRequestData(value)
      } else {
        transformedData[snakeCaseKey] = value
      }
    }
  }

  return transformedData
}

export { get, post, patch, destroy, transformRequestData }