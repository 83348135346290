import React, { useState, useCallback, useRef } from 'react'
import Modal from '../components/ui/Modal'
import Button from '../components/ui/Button'
import { XCircleIcon } from '@heroicons/react/20/solid'
import classNames from '../utils/classNames'

interface UseConfirmReturn {
  showConfirm: (message: string) => Promise<boolean>
  ConfirmModal: any
}

export const useConfirm = (): UseConfirmReturn => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [description, setDescription] = useState('')
  const resolveRejectRef = useRef<(value: boolean) => void>()

  const showConfirm = useCallback((message: string, options?: { description?: string }): Promise<boolean> => {
    setMessage(message)
    setDescription(options?.description)
    setIsOpen(true)
    return new Promise<boolean>((resolve) => {
      resolveRejectRef.current = resolve
    })
  }, [])

  const handleConfirm = useCallback(() => {
    if (resolveRejectRef.current) {
      resolveRejectRef.current(true)
    }
    setIsOpen(false)
  }, [])

  const handleCancel = useCallback(() => {
    if (resolveRejectRef.current) {
      resolveRejectRef.current(false)
    }
    setIsOpen(false)
  }, [])

  const ConfirmModal = ({ isOpen: boolean }) => {
    return (
      <Modal isDynamicWidth open={isOpen} onClose={handleCancel}>
        <div className=''>
          <div className='mb-12 sm:mr-8'>
            <div className='sm:flex sm:items-start place-items-center content-center'>
              <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-50 sm:mx-0 sm:h-10 sm:w-10'>
                <XCircleIcon className='h-12 w-12 text-red-600' />
              </div>
              <div className='mt-3 self-center text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <div className='text-lg leading-6 font-medium text-gray-900 mr-2'>
                  {message}
                </div>
                {description && (
                  <div className='mt-2'>
                    {description}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='flex justify-end items-center'>
            <Button
              type='button'
              variant='gray'
              className='sm:ml-2 mr-2.5 w-1/2 sm:w-32 rounded-md'
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='red' onClick={handleConfirm} className='w-1/2 sm:w-32'>
              Remove
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  return { showConfirm, ConfirmModal }
}
