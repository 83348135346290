import { Moment } from "moment"
import { get } from "./utils/requests"

const sites = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(`/admin/reports/sites`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page })
const sites_dashboard = (campId: String, startDate: Moment, endDate: Moment) =>
  get(`/admin/reports/sites_dashboard`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString() })
const rent_roll = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(`/admin/reports/rent_roll`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page })
const rent_roll_dashboard = (campId: String, startDate: Moment, endDate: Moment) =>
  get(`/admin/reports/rent_roll_dashboard`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString() })
const reservations = (campId: String, startDate: Moment, endDate: Moment, filterBy: string, page: Number) =>
  get(`/admin/reports/reservations`, {
    camp_id: campId,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    filter_by: filterBy,
    page: page,
  })
const reservations_volume = (campId: String, startDate: Moment, endDate: Moment, filterBy: string, page: Number) =>
  get(`/admin/reports/reservations_volume`, {
    camp_id: campId,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    filter_by: filterBy,
    page: page,
  })
const reservations_dashboard = (campId: String, startDate: Moment, endDate: Moment, filterBy: string) =>
  get(`/admin/reports/reservations_dashboard`, {
    camp_id: campId,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    filter_by: filterBy
  })
const sites_occupancy = (campId: String, startDate: Moment, endDate: Moment, filterBy: string, page: Number) =>
  get(`/admin/reports/sites_occupancy`, {
    camp_id: campId,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    filter_by: filterBy,
    page: page,
  })
const add_ons = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(`/admin/reports/add_ons`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page })

const add_ons_dashboard = (campId: String, startDate: Moment, endDate: Moment) =>
  get(`/admin/reports/add_ons_dashboard`, { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString() })

const sites_csv = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(
    `/admin/reports/sites`,
    { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page },
    { headers: { Accept: 'text/csv' } }
  )
const rent_roll_csv = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(
    `/admin/reports/rent_roll`,
    { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page },
    { headers: { Accept: 'text/csv' } }
  )
const reservations_csv = (campId: String, startDate: Moment, endDate: Moment, filterBy: string, page: Number) =>
  get(
    `/admin/reports/reservations`,
    { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), filter_by: filterBy, page: page },
    { headers: { Accept: 'text/csv' } }
  )
const add_ons_csv = (campId: String, startDate: Moment, endDate: Moment, page: Number) =>
  get(
    `/admin/reports/add_ons`,
    { camp_id: campId, start_date: startDate.toISOString(), end_date: endDate.toISOString(), page: page },
    { headers: { Accept: 'text/csv' } }
  )

const Reports = {
  sites,
  sites_dashboard,
  rent_roll,
  rent_roll_dashboard,
  reservations,
  sites_csv,
  rent_roll_csv,
  reservations_csv,
  reservations_volume,
  reservations_dashboard,
  sites_occupancy,
  add_ons,
  add_ons_dashboard,
  add_ons_csv,
}

export default Reports
