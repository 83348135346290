import { CampingStyleProps } from '../interfaces'
import { get, patch } from './utils/requests'

const update = async (
  campingStyleId: string,
  data: CampingStyleProps
): Promise<{ camping_style: CampingStyleProps }> =>
  patch(`/api/camping_styles/${campingStyleId}`, data)

const getPricesFor = async (campingStyleId: string) =>
  get(`/api/camping_styles/${campingStyleId}/prices`)

const dateConfigurationsFor = async (campingStyleId: string) =>
  get(`/api/camping_styles/${campingStyleId}/date_configurations`)

const CampingStyles = {
  dateConfigurationsFor,
  getPricesFor,
  update,
}

export default CampingStyles
