import { Moment } from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { formatMoney } from '../../../utils/formatMoney'
import { AdminContext } from '../../../contexts/AdminContext'
import { formatToNearestDollar } from '../../../utils/formatMoney'

Chart.register(CategoryScale)

interface ComponentProps {
  sitesOccupancyData: Array<any> // Specify the type argument for the Array type
  reservationsVolumeData: Array<any> // Specify the type argument for the Array type
  startDate: Moment
  endDate: Moment
  numberOfSites: number
  isLoading: boolean
}

const ChartsGrid = ({
  sitesOccupancyData,
  reservationsVolumeData,
  isLoading,
}: ComponentProps) => {
  let dateLabels = []
  const [labels, setLabels] = useState<any>([])
  const [reservationVolumeValues, setReservationVolumeValues] = useState<any>([])
  const [occupancyValues, setOccupancyValues] = useState<any>([])
  const { camp } = useContext(AdminContext)

  useEffect(() => {
    setLabels(dateLabels)
    setReservationVolumeValues(reservationsVolumeData)
    setOccupancyValues(sitesOccupancyData)
  }, [sitesOccupancyData, reservationsVolumeData])

  let reservationVolumeData = {
    labels: labels,
    datasets: [
      {
        label: 'Reservation volume',
        data: reservationVolumeValues ? reservationVolumeValues["data"] : [],
        fill: false,
        borderColor: 'rgba(5, 150, 105)',
        tension: 0.1,
      },
    ],
  }

  const reservationVolumeOptions = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return formatToNearestDollar(value, camp.currency)
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''

            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.parsed.y)
            }
            return label
          },
        },
      },
    },
  }

  let occupancyData = {
    labels: labels,
    datasets: [
      {
        label: 'Overall Occupancy',
        data: occupancyValues ? occupancyValues["data"] : [],
        fill: true,
        backgroundColor: 'rgba(5, 150, 105, .7)',
        tension: 0.1,
      },
    ],
  }
  const occupancyOptions = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + '%'
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ''

            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(1) + '%'
            }
            return label
          },
        },
      },
    },
  }

  return (
    <>
      <div className='flex flex-col md:flex-row gap-8'>
        <div className='w-full sm:w-1/2' style={{ maxHeight: '550px', maxWidth: '750px' }}>
          <div className='text-xl font-bold mb-1'>Total Reservation Volume</div>
          <div className='text-2xl font-light mb-5 text-gray-600'>
            {!isLoading &&
              reservationsVolumeData &&
              formatMoney(reservationsVolumeData["summary"]["total_volume"])}
          </div>
          <Line data={reservationVolumeData} options={reservationVolumeOptions} />
        </div>
        <div className='w-full sm:w-1/2' style={{ maxHeight: '550px', maxWidth: '750px' }}>
          <div className='text-xl font-bold mb-1'>Average occupancy</div>
          <div className='text-2xl font-light mb-5 text-gray-600'>
            {!isLoading && sitesOccupancyData?.["summary"]?.["average_occupancy"]}%
          </div>
          <Bar data={occupancyData} options={occupancyOptions} />
        </div>
      </div>
    </>
  )
}

export default ChartsGrid
