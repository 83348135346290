import _ from "lodash"
import { ReservationProps } from "../interfaces"

const normalizeValue = (value: any) => {
  return value === undefined || value === null || value === "" ? null : value
}

const reservationDiff = (res1: ReservationProps, res2: ReservationProps): Record<string, [any, any]> => {
  const differences: Record<string, [any, any]> = {}

  if (!res1 || !res2) return differences

  // Check the basic fields
  const basicFields = [
    'id',
    'user_id',
    'site_id',
    'vendor_id',
    'total',
    'start_date',
    'end_date',
    'created_at',
    'updated_at',
    'status',
    'discount_type',
    'discount_membership_id',
    'deleted_at',
    'is_checked_in',
    'passengers',
    'kid_campers',
    'total_owed_in_cents',
    'total_paid_in_cents',
    'park_fee_in_cents',
    'stripe_payment_link_url',
    'stripe_payment_link_admin_url',
    'selected_camping_style_id',
    'kind',
    'long_term_reservation_id',
    'water_fee_in_cents',
    'electricity_fee_in_cents',
    'is_campendium',
    'is_manual_charge',
    'notes',
    'payment_mode',
    'is_locked',
    'is_checked_out',
    'grouped_reservation_id',
    'is_invoice_sent',
    'vendor_calendar_id',
    'last_meter_reading',
    'meter_reading',
    'invoice_sent_at',
    'invoice_memo',
    'sending_mode',
    'pets_info',
    'hashed_id',
    'order_id',
  ]

  for (const field of basicFields) {
    if (!_.isEqual(normalizeValue(res1[field]), normalizeValue(res2[field]))) {
      differences[field] = [res1[field], res2[field]]
    }
  }

  // Check nested objects (site, user, long_term_reservation, order, invoice)
  const nestedFields = ['site', 'user', 'long_term_reservation', 'order', 'invoice']

  for (const field of nestedFields) {
    if (!_.isEqual(normalizeValue(res1[field]), normalizeValue(res2[field]))) {
      differences[field] = [res1[field], res2[field]]
    }
  }

  return differences
}

const isReservationsMatching = (res1: ReservationProps, res2: ReservationProps): boolean => {
  const differences = reservationDiff(res1, res2)
  if (Object.keys(differences).length > 0) {
    console.log('differences', differences)
  }

  return Object.keys(differences).length === 0
}

export { isReservationsMatching, reservationDiff }
