import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import {
  ChatBubbleLeftIcon,
  ChevronDownIcon,
  EnvelopeIcon,
  MegaphoneIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/solid'
import Icon from '../../icon'
import classNames from '../../utils/classNames'
import { CheckIcon } from '@heroicons/react/20/solid'

export interface Option {
  label: string
  value: any
  hidden?: boolean
  isBottomPlacement?: boolean
}

interface ComponentProps {
  name?: string
  className?: string
  width?: string
  label?: string
  labelClassName?: string 
  options: Array<Option>
  selected: Option | Option[]
  setSelected: (option: Option | Option[]) => void
  disabled?: boolean
  large?: boolean
  buttonSize?: 'small' | 'normal' | 'large' | string
  isBottomPlacement?: boolean
  warningText?: JSX.Element
  multiple?: boolean
}

const SelectInput = ({
  name,
  className,
  width,
  label,
  labelClassName,
  options,
  selected,
  setSelected,
  disabled,
  large,
  buttonSize,
  isBottomPlacement,
  warningText,
  multiple
}: ComponentProps) => {
  
  const selectedValue = Array.isArray(selected) ? selected?.[0]?.value : selected?.value
  const selectedLabel = Array.isArray(selected) ? selected?.[0]?.label : selected?.label

  return (
    <Listbox multiple={multiple} name={name} value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          {label && <Listbox.Label className={classNames('block text-sm text-gray-700', labelClassName)}>{label}</Listbox.Label>}
          <div className='relative'>
            <Listbox.Button
              className={classNames(
                'bg-white relative w-full border border-gray-300 open-sans rounded-md shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-700 focus:border-green-700',
                disabled && 'opacity-50 pointer-events-none',
                warningText ? 'py-1.5' : buttonSize == 'large' ? 'py-3' : 'py-2'
              )}>
              <span className='truncate flex items-center'>
                {!multiple ? iconForValue(selectedValue, selectedLabel) : null}
                <div className='flex flex-col'>
                  <span>{(Array.isArray(selected) ? selected.map(style => style.label).join(', ') : selectedLabel)  || '\u00A0'}</span>
                  {warningText && <span>{warningText}</span>}
                </div>
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options
                className={classNames(
                  className && className,
                  width ? width : 'w-full',
                  'absolute z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none'
                )}
                style={
                  isBottomPlacement && {
                    top: '-13.4rem',
                    right: '0rem',
                  }
                }>
                {options
                  .filter((o) => o.hidden !== true)
                  .map((option) => (
                    <Listbox.Option
                      key={option?.value}
                      className={({ active }) =>
                        classNames(
                          active && 'bg-gray-100',
                          'cursor-pointer select-none relative pl-3 pr-9',
                          large ? 'py-3' : 'py-2'
                        )
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <div className='flex items-center'>
                          {iconForValue(option?.value, option.label)}
                          <span
                            className={classNames(
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate'
                            )}>
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                'text-green-600 absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default SelectInput

const iconForValue = (value: string, label?: string) => {
  if (label == 'No electric') return null

  switch (value) {
    case 'email':
      return <EnvelopeIcon className='text-gray-400 w-5.5 h-5.5 mr-3.5 flex-shrink-0' />
    case 'text':
      return <ChatBubbleLeftIcon className='text-gray-400 w-5.5 h-5.5 mr-3.5 flex-shrink-0' />
    case null || '' || 'none':
      return <NoSymbolIcon className='text-gray-400 w-5.5 h-5.5 mr-3.5 flex-shrink-0' />
    case 'email_and_text':
      return <MegaphoneIcon className='text-gray-400 w-5.5 h-5.5 mr-3.5 flex-shrink-0' />
    case 'single':
      return (
        <Icon.SingleReservation
          className={classNames('w-9 h-9 mr-2 self-center stroke-current text-gray-900 flex-shrink-0')}
        />
      )
    case 'recurring':
      return (
        <Icon.RecurringReservation
          className={classNames('w-9 h-9 mr-2 self-center stroke-current text-gray-900 flex-shrink-0')}
        />
      )
    case 'group':
      return (
        <Icon.GroupReservation
          className={classNames('w-9 h-9 mr-2 self-center stroke-current text-gray-900 flex-shrink-0')}
        />
      )
    case 'blocked':
      return (
        <Icon.BlockedReservation
          className={classNames('w-9 h-9 mr-2 self-center stroke-current text-gray-900 flex-shrink-0')}
        />
      )
    default:
      return null
  }
}
