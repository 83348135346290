import React, { useContext, useEffect, useRef, useState } from 'react'
import classNames from '../../../../utils/classNames'
import moment from 'moment'
import { formatMoney } from '../../../../utils/formatMoney'
import { AdminContext } from '../../../../contexts/AdminContext'
import Form from '../../../ui/Admin/Form'
import useAdminForm from '../../../../hooks/useAdminForm'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import DropdownButton from '../../../ui/Admin/DropdownButton'
import { LONG_TERM_RESERVATION_KINDS, longTermReservationKind } from '../../../../constants'
import Badge from '../../../ui/Admin/Badge'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import InvoicesModalContent from '../InvoicesModalContent'
import api from '../../../../api2'
import { formatDateRange } from '../../../../utils/formatDateRange'
import IInvoice from '../../../../interfaces/IInvoice'
import copyText from '../../../../utils/copyText'
import { RowItem } from '../Table'
import HoverTip from '../../../ui/HoverTip'
import { INVOICE_STATUSES } from '../../../../decorators/InvoiceDecorator'
import roundToMaxPrecision from '../../../../utils/roundToMaxPrecision'

const formattedDate = (date: string) => {
  if (!date) return '-'

  const isIncludingYear = moment(date).year() !== moment().year()
  return moment(date).format(isIncludingYear ? "MMM D 'YY" : 'MMM D')
}

const statusBadgeFor = (invoice: IInvoice) => {
  switch (invoice.status) {
    case INVOICE_STATUSES.PAID:
      return <Badge color='green'>Paid</Badge>
    case INVOICE_STATUSES.QUEUED:
      return <Badge color='yellow'>Queued</Badge>
    case INVOICE_STATUSES.DRAFT:
      return <Badge>Draft</Badge>
    case INVOICE_STATUSES.UPCOMING:
      return <Badge color='blue'>Upcoming</Badge>
    case INVOICE_STATUSES.OPEN:
      return <Badge color='blue'>Open</Badge>
    default:
      return <Badge color='red'>Overdue</Badge>
  }
}

const RecurringKindBadgeFor = (kind: longTermReservationKind | "seasonal") => {
  switch (kind) {
    case "seasonal":
      return <Badge color='gray'>Seasonal</Badge>
    case LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES:
      return <Badge color='gray'>Rent & Utilities</Badge>
    case LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY:
      return <Badge color='gray'>Utilities Only</Badge>
    default:
      return <Badge color='gray'>Rent Only</Badge>
  }
}

interface IFormData {
  electric_rate: number
  rowItems: RowItem[]
}

interface ComponentProps {
  rowItem: RowItem
  refreshLocalInvoices: () => void
  index: number
  isSelected: boolean
  setIsSelected: (invoice: IInvoice, isSelected: boolean) => void
  setSelectedInvoice: (invoice: IInvoice) => void
  isVisibleElectric: boolean
  isMeterFieldsEditable: boolean
  gridTemplateColumns: string
}

const Row = (props: ComponentProps) => {
  const {
    camp,
    showPaymentModal,
    showMessageModal,
    showModal,
    updateLocalReservations,
    showNotification,
    setCurrentPage,
    setSelectedReservation,
  } = useContext(AdminContext)
  const { resource, setValue } = useAdminForm<IFormData>()
  const refId = props.rowItem.invoiceId || props.rowItem.siteId
  const thisRowItem = props.rowItem.isSiteOnly ? props.rowItem : resource.rowItems.find((item) => item.invoiceId === refId || item.siteId === refId) || props.rowItem
  const thisIndex = resource.rowItems.findIndex((item) => item.invoiceId === refId || item.siteId === refId)
  const electricKinds: longTermReservationKind[] = [
    LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES,
    LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY,
  ]
  const isShowingElectricFields =
    props.isVisibleElectric && (
      electricKinds.includes(thisRowItem.reservationRecurringKind as longTermReservationKind) ||
      thisRowItem.isSiteOnly
    )

  useEffect(() => {
    const electricUsed = roundToMaxPrecision(Number(thisRowItem.currentMeterReading) - Number(thisRowItem.lastMeterReading))
    let fee = electricUsed * resource.electric_rate
    setValue(`rowItems.${thisIndex}.electricSubtotal`, fee)
  }, [resource.electric_rate])

  const onChangeLastMeterReading = (e) => {
    const lastMeterReading = e.target.value
    const electricUsed = roundToMaxPrecision(Number(thisRowItem.currentMeterReading) - Number(lastMeterReading))
    setValue(`rowItems.${thisIndex}.lastMeterReading`, lastMeterReading)
    if (electricUsed >= 0) {
      setValue(`rowItems.${thisIndex}.electricUsed`, electricUsed)
      setValue(`rowItems.${thisIndex}.electricSubtotal`, electricUsed * resource.electric_rate)
    }
  }

  const onChangeMeterReading = (e) => {
    const meterReading = e.target.value
    const electricUsed = roundToMaxPrecision(Number(meterReading) - Number(thisRowItem.lastMeterReading))
    setValue(`rowItems.${thisIndex}.currentMeterReading`, meterReading)
    if (electricUsed >= 0) {
      setValue(`rowItems.${thisIndex}.electricUsed`, electricUsed)
      setValue(`rowItems.${thisIndex}.electricSubtotal`, electricUsed * resource.electric_rate)
    }
  }

  const onChangeElectricUsed = (e) => {
    const electricUsed = e.target.value
    if (Number(electricUsed) < 0) {
      return
    }
    const meterReading = roundToMaxPrecision(Number(thisRowItem.lastMeterReading) + Number(electricUsed))
    setValue(`rowItems.${thisIndex}.electricUsed`, electricUsed)
    setValue(`rowItems.${thisIndex}.currentMeterReading`, meterReading)
    setValue(`rowItems.${thisIndex}.electricSubtotal`, electricUsed * resource.electric_rate)
  }

  const dropdownOptionsFor = (invoice: IInvoice) => {
    const options = [
      {
        label: 'Edit invoice',
        onClick: () => {
          props.setSelectedInvoice(invoice)
        },
      },
      {
        label: 'Copy invoice link',
        onClick: () => {
          copyText(window.location.origin + `/i/${invoice.hash_id}`)
        },
      },
      {
        label: 'View reservation',
        onClick: () => {
          setCurrentPage('reservations')
          setSelectedReservation(invoice.reservation)
        },
      },
      {
        label: '_divider_',
      },
      {
        label: 'Accept payment now',
        onClick: () => {
          // TODO: payment modal shouldn't expect reservation, but order
          showPaymentModal(
            invoice.order.unpaid_total_in_cents,
            { reservation: invoice.reservation }
          )
        },
      },
      {
        label: '_divider_',
      },
      {
        label: 'Send invoice',
        onClick: () => {
          showModal(
            <InvoicesModalContent
              invoices={[invoice]}
              onSuccess={(reservations) => {}}
              refreshLocalInvoices={props.refreshLocalInvoices}
            />
          )
        },
      },
      {
        label: 'Send message',
        onClick: () => {
          // TODO: message modal shouldn't expect only reservation
          showMessageModal({ reservations: [invoice.reservation] })
        },
      },
    ]

    if (invoice.status !== INVOICE_STATUSES.PAID) {
      options.splice(4, 0, {
        label: 'Mark as paid',
        onClick: () => {
          api.Reservations.update(invoice.reservation?.id, {
            status: 'paid',
          })
            .then(({ reservation }) => {
              updateLocalReservations([reservation])
              props.refreshLocalInvoices()
              showNotification({
                type: 'success',
                title: 'Success',
                description: 'Marked invoice as paid',
              })
            })
            .catch((err) => {
              showNotification({
                type: 'error',
                title: 'Error',
                description: err.message,
              })
            })
        },
      })
    }

    return options
  }

  const isMissingContactInfo = !thisRowItem.invoice?.recipient_email && !thisRowItem.invoice?.recipient_phone

  return (
    <div
      style={{
        gridTemplateColumns: props.gridTemplateColumns,
      }}
      className={classNames(
        'hover:bg-gray-50 transition-all duration-100 h-13 grid overflow-hidden xl:overflow-visible w-full border-b border-gray-200',
        props.isSelected ? 'bg-gray-50' : undefined
      )}
      onClick={() => props.setIsSelected(thisRowItem.invoice, !props.isSelected)}>
      <div className='relative'>
        {props.isSelected && <div className='absolute inset-y-0 left-0 w-0.5 bg-green-600' />}
        <input
          type='checkbox'
          className='absolute transition-all duration-100 left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600'
          checked={props.isSelected}
          onChange={(e) => props.setIsSelected(thisRowItem.invoice, e.target.checked)}
        />
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
        {!thisRowItem.isSiteOnly && (
          <>
            {formatMoney(thisRowItem.amountOwed, camp.currency)}
            <Form.NumberInput hidden field={`rowItems.${thisIndex}.amountOwed`} />
          </>
        )}
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
        {!thisRowItem.isSiteOnly && statusBadgeFor(thisRowItem.invoice)}
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
        {thisRowItem.reservationRecurringKind &&
          RecurringKindBadgeFor(thisRowItem.reservationRecurringKind)}
      </div>
      <div className='whitespace-nowrap text-sm flex items-center'>
        <div className='font-medium text-gray-900'>{thisRowItem.siteName}</div>
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 w-16 flex items-center'>
        {!thisRowItem.isSiteOnly && (
          <>
            {isMissingContactInfo && (
              <HoverTip content='Missing contact info' xs>
                <ExclamationTriangleIcon className='w-5 h-5 -ml-6 mr-1 text-yellow-600' />
              </HoverTip>
            )}
            <span className='flex flex-col'>
              <div className='font-medium text-gray-900'>{thisRowItem.camperName}</div>
              <div className='font-normal text-gray-500'>
                {formatDateRange(
                  thisRowItem.reservation?.start_date,
                  thisRowItem.reservation?.end_date
                )}
              </div>
            </span>
          </>
        )}
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 flex items-center justify-center'>
        {!thisRowItem.isSiteOnly && formattedDate(thisRowItem.invoiceSendDate.toISOString())}
      </div>
      <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
        {!thisRowItem.isSiteOnly &&
          (thisRowItem.invoiceDueDate?.isValid()
            ? formattedDate(thisRowItem.invoiceDueDate.format())
            : '-')}
      </div>
      {props.isVisibleElectric && (
        <>
          <div className='whitespace-nowrap text-sm text-gray-500 pr-3 flex items-center'>
            {isShowingElectricFields && !thisRowItem.isSiteOnly && props.isMeterFieldsEditable ? (
              <Form.NumberInput
                field={`rowItems.${thisIndex}.lastMeterReading`}
                suffix='kWh'
                className='w-20 md:w-40'
                onChange={onChangeLastMeterReading}
                stopPropagation
              />
            ) : (
              <div className=''>{Number(thisRowItem.lastMeterReading)}</div>
            )}
          </div>
          <div className='whitespace-nowrap text-sm text-gray-500 pr-3 flex items-center'>
            {isShowingElectricFields &&
              (props.isMeterFieldsEditable ? (
                <Form.NumberInput
                  field={`rowItems.${thisIndex}.currentMeterReading`}
                  suffix='kWh'
                  className='w-20 md:w-40'
                  onChange={onChangeMeterReading}
                  stopPropagation
                />
              ) : (
                <div className=''>{Number(thisRowItem.currentMeterReading)}</div>
              ))}
          </div>
          <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
            {isShowingElectricFields &&
              (props.isMeterFieldsEditable ? (
                <>
                  <Form.NumberInput
                    field={`rowItems.${thisIndex}.electricUsed`}
                    suffix='kWh'
                    className='w-20 md:w-40'
                    onChange={onChangeElectricUsed}
                    stopPropagation
                  />
                  <Form.NumberInput hidden field={`rowItems.${thisIndex}.electricSubtotal`} />
                </>
              ) : (
                <>
                  {roundToMaxPrecision(
                    Number(thisRowItem.currentMeterReading) - Number(thisRowItem.lastMeterReading)
                  )}
                  <Form.NumberInput hidden field={`rowItems.${thisIndex}.electricUsed`} />
                  <Form.NumberInput hidden field={`rowItems.${thisIndex}.electricSubtotal`} />
                </>
              ))}
          </div>
          <div className='whitespace-nowrap text-sm text-gray-500 flex items-center'>
            {isShowingElectricFields && (
              <>
                {formatMoney(thisRowItem.electricSubtotal)}
                <Form.NumberInput hidden field={`rowItems.${thisIndex}.electricSubtotal`} />
              </>
            )}
          </div>
        </>
      )}
      <div className='font-medium sm:pr-3' onClick={(e) => e.stopPropagation()}>
        {!thisRowItem.isSiteOnly && (
          <DropdownButton stopPropagation options={dropdownOptionsFor(thisRowItem.invoice)}>
            <EllipsisHorizontalIcon className='rounded-md p-2 w-10 h-10 transition-colors duration-100 hover:bg-gray-100 text-gray-600 cursor-pointer' />
          </DropdownButton>
        )}
      </div>
    </div>
  )
}

export default Row
