import React from 'react'
import classNames from '../../utils/classNames'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

export const SortableTableHeader = ({
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  field,
  children,
  className,
  isTable = true,
}: {
  sortBy: string
  setSortBy: (sortBy: string) => void
  isAscending: boolean
  setIsAscending: (isAscending: boolean) => void
  field: string
  children: React.ReactNode
  className?: string
  isTable?: boolean
}) => {
  return isTable ? (
    <th
      scope='col'
      className={classNames(
        className,
        ' py-3.5 pr-3 text-left text-md font-semibold text-gray-900'
      )}
    >
      <TableHeader
        sortBy={sortBy}
        setSortBy={setSortBy}
        isAscending={isAscending}
        setIsAscending={setIsAscending}
        field={field}
        children={children}
      />
    </th>
  ) : (
    <div
      className={classNames(
        className,
        ' py-3.5 pr-3 text-left text-md font-semibold text-gray-900'
      )}
    >
      <TableHeader
        sortBy={sortBy}
        setSortBy={setSortBy}
        isAscending={isAscending}
        setIsAscending={setIsAscending}
        field={field}
        children={children}
      />
    </div>
  )
}

const TableHeader = ({
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  field,
  children,
}: {
  sortBy: string
  setSortBy: (sortBy: string) => void
  isAscending: boolean
  setIsAscending: (isAscending: boolean) => void
  field: string
  children: React.ReactNode
}) => {
  return (
    <a
      className='group inline-flex'
      onClick={() => {
        if (sortBy === field) setIsAscending(!isAscending)
        else {
          setIsAscending(true)
          setSortBy(field)
        }
      }}
    >
      {children}
      <span
        className={classNames(
          'ml-2 flex-none rounded',
          sortBy == field
            ? 'bg-gray-100 text-gray-900 group-hover:bg-gray-200'
            : 'ml-2 flex-none rounded text-gray-400 invisible group-hover:visible group-focus:visible'
        )}
      >
        {isAscending ? (
          <ChevronDownIcon className='h-5 w-5 mt-0.5' aria-hidden='true' />
        ) : (
          <ChevronUpIcon className='h-5 w-5 mt-0.5' aria-hidden='true' />
        )}
      </span>
    </a>
  )
}
