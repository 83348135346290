import React, { useContext } from 'react'
import Button from '../../ui/Button'
import moment from 'moment'
import classNames from '../../../utils/classNames'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import ICamper from '../../../interfaces/ICamper'
import Spinner from '../../ui/Spinner'
import { CamperBlocked } from '../../ui/StatusBadges'
import { formatMoneyFromCents } from '../../../utils/formatMoney'
import { AdminContext } from '../../../contexts/AdminContext'
import NewCamperModalContent from './NewCamperModalContent'
import stripHtmlTags from '../../../utils/stripHtmlTags'
import { SortableTableHeader } from '../SortableTableHeader'
import api from '../../../api2'
import downloadCsv from '../../../utils/downloadCsv'

interface ComponentProps {
  reloadCampers: () => void
  records: any[]
  isLoading: boolean
  selectedRecord: any
  totalRecords: number
  totalPages: number
  page: number
  setPage: (page: number) => void
  perPage: number
  setPerPage: (perPage: number) => void
  sortBy: string
  setSortBy: (sortBy: string) => void
  isAscending: boolean
  setIsAscending: (isAscending: boolean) => void
  onRowClick: (record: any) => void
}

const PaginatedTable = ({
  reloadCampers,
  records,
  isLoading,
  selectedRecord,
  totalRecords,
  totalPages,
  page,
  setPage,
  perPage,
  setPerPage,
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  onRowClick,
}: ComponentProps) => {
  const { showModal, camp } = useContext(AdminContext)

  const handleClickAddCamper = () => {
    showModal(<NewCamperModalContent onSuccess={(camper) => reloadCampers()} />, false, 'max-w-4xl')
  }

  const handleClickDownloadCsv = () => {
    api.Campers.csv(camp.id)
      .then((csv) => {
        downloadCsv(camp.slug, csv, 'campers')
      })
      .catch((err) => {
        alert(err)
      })
  }

  if (isLoading) {
    return (
      <div className='absolute bg-white bg-opacity-30 z-40 flex flex-col space-y-4 justify-center items-center h-full w-full left-0 top-20'>
        <Spinner size={16} />
        <p>Loading guests...</p>
      </div>
    )
  }

  return (
    <div className='bg-white top-20 py-4 z-10'>
      <div className='px-4 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-2xl font-semibold leading-6 text-gray-900'>Guests</h1>
          </div>
          <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
            <Button className='mr-2' onClick={handleClickAddCamper} variant='blue'>
              Add guest
            </Button>
            <Button onClick={handleClickDownloadCsv} variant='blue'>
              Download CSV
            </Button>
          </div>
        </div>
        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='relative min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <SortableTableHeader
                      field='last_name'
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      isAscending={isAscending}
                      setIsAscending={setIsAscending}
                      className={'pl-4'}>
                      Name
                    </SortableTableHeader>
                    <SortableTableHeader
                      field='email'
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      isAscending={isAscending}
                      setIsAscending={setIsAscending}
                      className={'pl-4'}>
                      Email
                    </SortableTableHeader>
                    <SortableTableHeader
                      field='phone'
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      isAscending={isAscending}
                      setIsAscending={setIsAscending}
                      className={'pl-4'}>
                      Phone
                    </SortableTableHeader>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900'>
                      Times booked
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900'>
                      Spent
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900'>
                      Last stayed
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 w-24 truncate max-w-xs'>
                      Notes
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-0'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='relative divide-y divide-gray-200 bg-white transition-opacity'>
                  {records.map((camper: ICamper) => (
                    <tr
                      key={camper.id}
                      className={classNames(
                        'hover:bg-gray-50 cursor-pointer',
                        selectedRecord?.id === camper.id && 'bg-gray-100'
                      )}
                      onClick={() => onRowClick(camper)}>
                      <td className='whitespace-nowrap flex py-4 pl-4 pr-3 text-md font-medium text-gray-900'>
                        {camper.first_name ? camper.first_name : ''}{' '}
                        {camper.last_name ? camper.last_name : ''}{' '}
                        {camper.blocked_at && <CamperBlocked small className='ml-1.5' />}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500'>
                        {camper.email != null ? camper.email : ''}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500'>
                        {camper.phone != null ? camper.phone : ''}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500'>
                        {camper.num_stays != null ? camper.num_stays : ''}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500'>
                        {camper.spent != null ? formatMoneyFromCents(camper.spent) : ''}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500'>
                        {camper.last_stay_date != null
                          ? moment(camper.last_stay_date).format(camp.date_format)
                          : ''}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-md text-gray-500 w-24 truncate max-w-xs'>
                        {camper.notes != null ? stripHtmlTags(camper.notes) : ''}
                      </td>
                      <td className='relative whitespace-nowrap py-4 pl-3 text-right text-md'>
                        <a>
                          <ArrowRightIcon className='text-gray-700 h-6 w-6' />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <nav
        className='flex items-center justify-between border-t border-gray-200 bg-white px-4 pt-6 sm:px-6'
        aria-label='Pagination'>
        <div className='hidden sm:block'>
          <p className='text-sm text-gray-700'>
            Showing <span className='font-medium'>{perPage * page - perPage + 1}</span> to{' '}
            <span className='font-medium'>{Math.min(perPage * page, totalRecords)}</span> of{' '}
            <span className='font-medium'>{totalRecords}</span> results
          </p>
        </div>
        <div className='flex flex-1 justify-between sm:justify-end'>
          <Button
            variant='white'
            onClick={() => setPage(1)}
            disabled={page <= 1}
            className='ml-3'>
              First
          </Button>
          <Button
            variant='white'
            onClick={() => {
              if (page > 1) setPage(page - 1)
            }}
            disabled={page <= 1}
            className='ml-3'>
              Previous
          </Button>
          <Button
            variant='white'
            onClick={() => {
              if (page < totalPages) setPage(page + 1)
            }}
            disabled={page >= totalPages}
            className='ml-3'>
              Next
          </Button>
          <Button
            variant='white'
            onClick={() => setPage(totalPages)}
            disabled={page >= totalPages}
            className='ml-3'>
              Last
          </Button>
        </div>
      </nav>
    </div>
  )
}

export default PaginatedTable
