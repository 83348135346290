import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserProps } from '../../interfaces'
import { useDebouncedCallback } from 'use-debounce/lib'
import api from '../../api2'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { AdminContext } from '../../contexts/AdminContext'

interface ComponentProps {
  showResults: any
  onBlur: (currentValue: string) => void
  setSearchResults: (v: UserProps[]) => void
  setSearchStatus: (v: any) => void
  visible: boolean
}

const SearchBar = (props: ComponentProps) => {
  const { camp } = useContext(AdminContext)

  const inputRef = useRef<HTMLInputElement>()

  const fetchUsers = (query: string) => {
    if (!query) return

    props.setSearchStatus({ status: 'loading', message: 'Still fetching results...' })

    api.Search.users(camp.id, query)
      .then(({ users }) => {
        props.setSearchStatus({})
        props.setSearchResults(users)
      })
      .catch((err) => {
        props.setSearchResults(null)
        props.setSearchStatus({ status: 'error', message: err })
      })
  }
  const debounce = useDebouncedCallback(fetchUsers, 3000)

  useEffect(() => {
    if(props.visible){
      fetchUsers(inputRef.current.value)
    }
  }, [props.visible])
  
  return (
    <div className='w-full sm:max-w-xs'>
      <label htmlFor='search' className='sr-only'>
        Search
      </label>
      <div className='relative'>
        <div className='pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center'>
          <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          id='search'
          ref={inputRef}
          name='search'
          className='block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-green focus:border-green sm:text-sm'
          placeholder='Search by name or email'
          type='search'
          autoComplete='off'
          onChange={() => {
            const value = inputRef.current.value
            if (value) {
              props.setSearchStatus({ status: 'waiting', message: 'Fetching results...' })
              debounce(value)
            } else {
              debounce(value)
              props.setSearchStatus({})
              props.setSearchResults(null)
            }
          }}
          onClick={props.showResults}
          onInput={props.showResults}
          onBlur={() => props.onBlur(inputRef.current.value)}
        />
      </div>
    </div>
  )
}

export default SearchBar
