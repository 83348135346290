import React, { useContext, useEffect } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import SiteIcons from '../SiteIcons'

import { SiteProps } from '../../interfaces'
import { validElectric } from '../../types'
import { BookerContext } from '../../contexts/BookerContext'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import { PRICE_DURATIONS } from '../../constants/price_durations'

interface ComponentProps {
  nights: number
  site: SiteProps
  isAvailable: boolean
  setActiveSite: any
  userElectric: validElectric
}

const Site = (props: ComponentProps) => {
  const { campingStyle, trackForCamp, trackForPark } = useContext(BookerContext)

  return (
    <>
      <Button
        basic
        fluid
        primary
        disabled={!props.isAvailable}
        onClick={() => {
          trackForCamp('view_item')
          trackForPark('view_item')
          props.setActiveSite(props.site)
        }}
        id='button-site'>
        <div className='flex justify-between h-9'>
          <div className='flex items-center'>
            <div className='flex items-center'>
              <span className='font-bold'>{props.site.name}</span>
              {props.site.calculated_nightly_price_in_cents != 0 && (
                <span className='ml-1 opacity-75'>
                  - {formatMoneyFromCents(props.site.calculated_nightly_price_in_cents)}{' '}
                  {props.site.calculated_frequency_label}
                </span>
              )}
            </div>
            {props.site.badge_label && (
              <span className='inline-flex items-center px-3 py-0.5 ml-1.5 rounded-full text-sm font-medium bg-green-100 text-green-800'>
                {props.site.badge_label}
              </span>
            )}
          </div>
          <div>
            <div className='hidden xxs:inline'>
              <SiteIcons site={props.site} size='12' campingStyle={campingStyle} />
            </div>
          </div>
        </div>
      </Button>
    </>
  )
}

export default Site
