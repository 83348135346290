import { adminPages } from "../types/adminPages"
import { ADMIN_PAGES } from "./admin_pages"

const ADMIN_NAVBAR_ITEMS: Array<{
  name: string
  key: adminPages
  href: string
}> = [
  {
    name: 'Reservations',
    key: ADMIN_PAGES.RESERVATIONS,
    href: '/admin/reservations',
  },
  {
    name: "To-do's",
    key: ADMIN_PAGES.TO_DOS,
    href: '#',
  },
  {
    name: 'Reports',
    key: ADMIN_PAGES.REPORTS,
    href: '#',
  },
  {
    name: 'Invoices',
    key: ADMIN_PAGES.INVOICES,
    href: '#',
  },
  {
    name: 'Guests',
    key: ADMIN_PAGES.GUESTS,
    href: '#',
  },
  {
    name: 'Store',
    key: ADMIN_PAGES.STORE,
    href: '#',
  },
]

export default ADMIN_NAVBAR_ITEMS
