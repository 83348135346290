import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import {
  BoltIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  EyeIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid'
import { PrinterIcon } from '@heroicons/react/24/solid'
import Button from '../../ui/Button'
import classNames from '../../../utils/classNames'

export default function BulkActionsButton({
  disabled,
  handlePrint,
  handleClickSendInvoices,
  handleClickMessage,
  handleClickMarkPaid,
}) {
  return (
    <div className='text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button disabled={disabled}>
            <Button
              disabled={disabled}
              variant='white'
              className='flex items-center justify-end w-40'
            >
              Bulk actions
              <ChevronDownIcon
                className='-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100'
                aria-hidden='true'
              />
            </Button>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='absolute left-0 xsm:right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white z-20 shadow-lg ring-1 ring-gray-200 focus:outline-none'>
            <div className='py-1.5'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } group flex w-full items-center px-2 py-2 text-base`}
                    onClick={handlePrint}>
                    <PrinterIcon className='w-5 h-5 mr-2' />
                    Print invoices
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } group flex w-full items-center px-2 py-2 text-base`}
                    onClick={handleClickSendInvoices}>
                    <DocumentTextIcon className='w-5 h-5 mr-2' />
                    Send invoices
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } group flex w-full items-center px-2 py-2 text-base`}
                    onClick={handleClickMessage}>
                    <EnvelopeIcon className='w-5 h-5 mr-2' />
                    Message guests
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className='py-1.5'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'text-gray-900'
                    } group flex w-full items-center px-2 py-2 text-base`}
                    onClick={handleClickMarkPaid}>
                    <DocumentCheckIcon className='w-5 h-5 mr-2' />
                    Mark as paid
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
