import moment from 'moment'

export const formatDateRange = (startDate: string, endDate: string) => {
  if (!startDate && !endDate) {
    return ''
  }

  const currentYear = moment().year()
  const startYear = moment(startDate).year()
  const endYear = moment(endDate).year()

  const formatWithYear = (date: string) => moment(date).format('MMM Do, YYYY')
  const formatWithoutYear = (date: string) => moment(date).format('MMM Do')

  const startFormat = formatWithoutYear(startDate)
  const endFormat = endYear === currentYear ? formatWithoutYear(endDate) : formatWithYear(endDate)

  return moment(startDate).isSame(endDate, 'day') ? startFormat : `${startFormat} - ${endFormat}`
}
