import moment, { Moment } from 'moment'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import api from '../../api'
import { STATUSES } from '../../constants'
import { CampProps, ReservationProps, SiteProps } from '../../interfaces'
import { ChevronRightIcon, PrinterIcon } from '@heroicons/react/24/solid'
import TodoDropdownButton from '../ui/TodoDropdownButton'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { AdminContext } from '../../contexts/AdminContext'
import ToDoItem from '../ToDos/ToDoItem'
import ToDosDatePicker from './ToDosDatePicker'
import { useReactToPrint } from 'react-to-print'
import Button from '../ui/Button'

interface ComponentProps {
  camp: CampProps
  sites: SiteProps[]
  setPage: (page: any) => void
  setSelectedReservation: (reservation: ReservationProps) => void
}

interface IPrintOption {
  name: string
  toPrint: ReservationProps[]
  section?: 'checkins' | 'checkouts' | 'current'
}

const ToDos = (props: ComponentProps) => {
  const { camp, currentUserEmail } = useContext(AdminContext)

  const [isLoading, setIsLoading] = useState(false)
  const [reservations, setReservations] = useState<ReservationProps[]>([])
  const [selectedDate, setSelectedDate] = useState<Moment>(moment())
  const [checkins, setCheckins] = useState<ReservationProps[]>([])
  const [checkouts, setCheckouts] = useState<ReservationProps[]>([])
  const [current, setCurrent] = useState<ReservationProps[]>([])
  const [todosToPrint, setTodosToPrint] = useState<ReservationProps[]>([])
  const [todosTitle, setTodosTitle] = useState<string>('')
  const [selectedInterval, setSelectedInterval] = useState<string>('Today')

  const targetRef = useRef(null)

  useEffect(() => {
    fetchReservations(selectedDate)
  }, [selectedDate])

  useEffect(() => {
    setCheckins(
      reservations.filter(
        (checkin) =>
          !checkin.is_checked_in &&
          moment(checkin.start_date).dayOfYear() == selectedDate.dayOfYear() &&
          !(checkin.kind == 'recurring' && checkin.long_term_reservation?.kind == 'utilities-only')
      )
    )

    setCheckouts(
      reservations.filter(
        (checkin) =>
          !checkin.is_checked_out &&
          moment(checkin.end_date).dayOfYear() == selectedDate.dayOfYear() &&
          !(
            checkin.kind == 'recurring' && checkin.long_term_reservation?.kind == 'utilities-only'
          ) &&
          !(
            checkin.kind == 'recurring' &&
            checkin.long_term_reservation &&
            checkin.long_term_reservation.reservations.some((reservation) =>
              moment(reservation.end_date).isAfter(moment(checkin.end_date))
            )
          )
      )
    )

    setCurrent(
      reservations.filter(
        (checkin) =>
          (checkin.user?.camper?.first_name ||
            checkin.user?.camper?.last_name ||
            checkin.user?.camper?.email ||
            checkin.user?.camper?.phone ||
            checkin.user?.first_name ||
            checkin.user?.last_name ||
            checkin.user?.email ||
            checkin.user?.phone) &&
          ((moment(checkin.start_date).dayOfYear() != selectedDate.dayOfYear() &&
            moment(checkin.end_date).dayOfYear() != selectedDate.dayOfYear()) ||
            (moment(checkin.start_date).dayOfYear() == selectedDate.dayOfYear() &&
              checkin.is_checked_in)) &&
          !(checkin.kind == 'recurring' && checkin.long_term_reservation?.kind == 'utilities-only')
      )
    )
  }, [reservations])

  const fetchReservations = (date: Moment) => {
    // setIsLoading(true)

    const from = date.clone().startOf('day')
    const until = date.clone().endOf('day')

    api
      .fetchReservations({
        camp_id: camp.id,
        from: from,
        until: until,
        except_status: STATUSES.BLOCKED,
        include_recurrences: true
      })
      .then((response) => {
        setReservations(response)
        // setIsLoading(false)
      })
      .catch((err) => {
        alert(JSON.stringify(err))
        setIsLoading(false)
      })
  }

  const reservationUpdated = (reservation) => {
    let mreservations = [...reservations]
    const index = reservations.findIndex((res) => res.id == reservation.id)
    mreservations[index] = reservation
    setReservations(mreservations)
  }

  const usePrint = useReactToPrint({
    content: () => targetRef.current,
    onAfterPrint: () => {
      setTodosToPrint([])
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (todosToPrint?.length) {
      setIsLoading(true)
      const itemButtons: Element[] = targetRef.current.querySelectorAll('.print-hide')
      const itemChecbox: Element[] = targetRef.current.querySelectorAll('.checbox-show')
      const wrapItems: Element[] = targetRef.current.querySelectorAll('.print-wrap')

      itemButtons.forEach((item) => item.classList.add('hidden'))
      wrapItems.forEach((item) => item.classList.add('flex-wrap'))
      itemChecbox.forEach((item) => {
        item.classList.remove('hidden')
        item.classList.add('flex')
      })

      targetRef.current.style.display = 'flex'
      usePrint()
      targetRef.current.style.display = 'none'
    }
  }, [todosToPrint])

  const printOptions: IPrintOption[] = [
    {
      name: 'Checking in',
      toPrint: checkins,
      section: 'checkins',
    },
    {
      name: 'Checking out',
      toPrint: checkouts,
      section: 'checkouts',
    },
    {
      name: 'Checked out',
      toPrint: reservations.filter((checkin) => checkin.is_checked_out),
      section: 'checkouts',
    },
    {
      name: 'Current guests',
      toPrint: current,
      section: 'current',
    },
    {
      name: 'All guests',
      toPrint: [
        ...checkins,
        ...checkouts,
        ...reservations.filter((checkin) => checkin.is_checked_out),
        ...current,
      ],
    },
  ]

  return (
    <>
      {isLoading ? (
        <Loader size='huge' active />
      ) : (
        <div className='flex-grow sm:mx-4 pb-12'>
          <div className='flex justify-between mr-4 sm:mr-0 flex-wrap gap-y-4 flex-col sm:flex-row'>
            <div className='flex gap-1'>
              <ToDosDatePicker
                selectedDate={selectedDate}
                visible={true}
                setSelectedDate={setSelectedDate}
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
              />
              <Menu as='div' className={'relative flex-shrink-0 ml-4'}>
                <Menu.Button className='h-full'>
                  <Button
                    variant='none'
                    className='h-full items-center flex-shrink-0 justify-self-end flex border border-gray-300 hover:bg-gray-50'>
                    <PrinterIcon className='w-5 h-5 mr-2' />
                    Print
                  </Button>
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <div className='left-auto right-0 origin-top-right sm:right-auto sm:origin-top-left absolute sm:left-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40'>
                    <Menu.Items>
                      {printOptions.map((option) => (
                        <Menu.Item as='div'>
                          <Button
                            variant='none'
                            className='flex items-center py-4 px-6 hover:bg-gray-100 cursor-pointer w-full text-gray-700'
                            onClick={() => {
                              setTodosToPrint(option.toPrint)
                              setTodosTitle(option.name)
                            }}
                            disabled={isLoading || !option.toPrint?.length}>
                            {option.name}
                          </Button>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </div>
                </Transition>
              </Menu>
            </div>
            <TodoDropdownButton
              checkins={checkins}
              checkouts={checkouts}
              reservations={reservations}
            />
          </div>

          <div className='mx-auto w-full rounded-2xl bg-white mt-8'>
            <Disclosure defaultOpen={true} as='div' className='mt-8'>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-start'>
                    <ChevronRightIcon
                      className={`${
                        open ? 'rotate-90 transform' : ''
                      } h-6 w-6 text-gray-700 mr-2 mt-0.5`}
                    />
                    <div className='text-xl mb-2 font-semibold'>Checking in today</div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {checkins.length == 0 ? (
                      <div className='italic'>All guests are checked in today!</div>
                    ) : (
                      <div className='bg-white shadow border border-gray-200 overflow-hidden sm:rounded-md'>
                        <ul role='list' className='divide-y divide-gray-200'>
                          {checkins
                            .sort((a, b) => a.site.sort - b.site.sort)
                            .map((checkin) => (
                              <li key={checkin.id}>
                                <ToDoItem
                                  setPage={props.setPage}
                                  setSelectedReservation={props.setSelectedReservation}
                                  checkin={checkin}
                                  updateCallback={reservationUpdated}
                                  campCurrency={camp.currency}
                                  section='checkins'
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure defaultOpen={true} as='div' className='mt-8'>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-start'>
                    <ChevronRightIcon
                      className={`${
                        open ? 'rotate-90 transform' : ''
                      } h-6 w-6 text-gray-700 mr-2 mt-0.5`}
                    />
                    <div className='text-xl mb-2 font-semibold'>Checking out today</div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {checkouts.length == 0 ? (
                      <div className='italic'>All guests are checked out today!</div>
                    ) : (
                      <div className='bg-white shadow border border-gray-200 overflow-hidden sm:rounded-md'>
                        <ul role='list' className='divide-y divide-gray-200'>
                          {checkouts
                            .sort((a, b) => a.site.sort - b.site.sort) // todo - fix this
                            .map((checkin) => (
                              <li key={checkin.id}>
                                <ToDoItem
                                  setPage={props.setPage}
                                  setSelectedReservation={props.setSelectedReservation}
                                  checkin={checkin}
                                  updateCallback={reservationUpdated}
                                  campCurrency={camp.currency}
                                  section='checkouts'
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure defaultOpen={true} as='div' className='mt-8'>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-start'>
                    <ChevronRightIcon
                      className={`${
                        open ? 'rotate-90 transform' : ''
                      } h-6 w-6 text-gray-700 mr-2 mt-0.5`}
                    />
                    <div className='text-xl mb-2 font-semibold'>Checked out</div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {reservations.filter((checkin) => checkin.is_checked_out).length == 0 ? (
                      <div className='italic'>All guests are checked out!</div>
                    ) : (
                      <div className='bg-white shadow border border-gray-200 overflow-hidden sm:rounded-md'>
                        <ul role='list' className='divide-y divide-gray-200'>
                          {reservations
                            .filter((checkin) => checkin.is_checked_out)
                            .map((checkin) => (
                              <li key={checkin.id}>
                                <ToDoItem
                                  setPage={props.setPage}
                                  setSelectedReservation={props.setSelectedReservation}
                                  checkin={checkin}
                                  updateCallback={reservationUpdated}
                                  campCurrency={camp.currency}
                                  section='checkouts'
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure defaultOpen={true} as='div' className='mt-8'>
              {({ open }) => (
                <>
                  <Disclosure.Button className='flex w-full justify-start'>
                    <ChevronRightIcon
                      className={`${
                        open ? 'rotate-90 transform' : ''
                      } h-6 w-6 text-gray-700 mr-2 mt-0.5`}
                    />
                    <div className='text-xl mb-2 font-semibold'>Current guests</div>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    {current.length == 0 ? (
                      <div className='italic'>No guests are staying today!</div>
                    ) : (
                      <div className='bg-white shadow border border-gray-200 overflow-hidden sm:rounded-md'>
                        <ul role='list' className='divide-y divide-gray-200'>
                          {current
                            .sort((a, b) => a.site.sort - b.site.sort)
                            .map((checkin) => (
                              <li key={checkin.id}>
                                <ToDoItem
                                  setPage={props.setPage}
                                  setSelectedReservation={props.setSelectedReservation}
                                  checkin={checkin}
                                  updateCallback={reservationUpdated}
                                  campCurrency={camp.currency}
                                  section='current'
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>

          <div className='hidden justify-center p-5 print-margin' ref={targetRef}>
            <div className='flex flex-col items-center w-full'>
              {printOptions.map((option, i) => {
                if (
                  (todosTitle !== 'All guests' && option.name !== todosTitle) ||
                  option.name === 'All guests' ||
                  !option.toPrint?.length
                )
                  return

                  return (
                    <div className='w-full pagebreakafter'>
                      <div className='font-semibold text-3xl pb-5 text-center'>
                        {option.name}{' '}
                        {['Current guests', 'Check in', 'Check out'].includes(
                          option.name
                        )
                          ? selectedDate.format('MM-DD-YYYY')
                          : ''}
                      </div>
                      <div className='bg-white sm:rounded-md flex-1 w-full'>
                        <ul role='list' className='w-full'>
                          {option.toPrint
                            .sort((a, b) => a.site.sort - b.site.sort)
                            .map((checkin, idx) => (
                              <li
                                key={checkin.id}
                                className={`border border-gray-200 w-full-0 ${
                                  idx !== 0 && idx % 10 === 0 ? 'pagebreak' : ''
                                } ${idx !== 0 && idx % 10 !== 0 ? 'border-t-0' : ''}`}
                              >
                                <ToDoItem
                                  setPage={props.setPage}
                                  setSelectedReservation={props.setSelectedReservation}
                                  checkin={checkin}
                                  updateCallback={reservationUpdated}
                                  campCurrency={camp.currency}
                                  section={option.section}
                                  forPrint
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ToDos
