import React from 'react'
import classNames from '../../utils/classNames'

const RealTimeIndicator = ({ active, className }: { active: boolean, className?: string }) => {
  const bgColor = active ? 'bg-green-50' : 'bg-gray-100'
  const textColor = active ? 'text-green' : 'text-500'
  const indicatorColor = active ? 'bg-green-500' : 'bg-gray-700'
  const indicatorAnimate = active ? 'animate-ping' : ''

  return (
    <div className={classNames(className, 'hidden custom-500:block')}>
      <span className={classNames(bgColor, textColor, 'inline-flex justify-items-center items-center px-4 py-0.5 text-gray- rounded-full text-sm')}>
        <span className='block w-2 h-2 mr-2'>
          {
            active == null ? (
              <svg className="animate-spin -ml-1 mr-3 h-2 w-2 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <>
                <span className={classNames(indicatorColor, 'absolute w-2 h-2 rounded-full')} />
                <span className={classNames(indicatorColor, 'absolute w-2 h-2 rounded-full', indicatorAnimate)} />
              </>
            )
          }
        </span>
        {active == null ? 'Live updates: connecting...' : active ? 'Live updates: on' : 'Live updates: off'}
      </span>
    </div>
  )
}

export default RealTimeIndicator
