import { CampingStyleProps, CampProps, SiteProps } from '../interfaces'
import { get } from './utils/requests'

const camps = (): Promise<CampProps[]> => get('/api/me/camps')
const sites_for_camp = (id: string): Promise<SiteProps[]> => get('/api/me/sites', { camp_id: id })
const camping_styles_for_camp = (id: string): Promise<CampingStyleProps[]> =>
  get('/api/me/camping_styles', { camp_id: id })

const Me = {
  camps,
  sites_for_camp,
  camping_styles_for_camp,
}

export default Me
