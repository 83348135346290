import IAddOn from "../interfaces/IAddOn"
import { get } from "./utils/requests"

const forSites = (camp_id: string, site_ids?: string[]): Promise<{ products: IAddOn[] }> =>
  get(`/api/camps/${camp_id}/products/?${site_ids?.map(site_id => `site_ids[]=${site_id}`)?.join('&')}`)

const Products = {
  forSites,
}

export default Products
