import { UserProps } from '../interfaces'
import ICamper from '../interfaces/ICamper'

const userParamsInitialState: UserProps | ICamper = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  phone: null,
  license_plate: null, // NOTE: license_plate does not have a vehicle_ prefix in the database
  vehicle_year: null,
  vehicle_make: null,
  vehicle_color: null,
  vehicle_rig_type: null,
  vehicle_rig_length: null,
  vehicle_electric: null,
  pets_info: null,
  address: null,
  emergency_contact_name: null,
  emergency_contact_relationship: null,
  emergency_contact_email: null,
  emergency_contact_phone: null,

  additional_vehicle_make: null,
  additional_vehicle_year: null,
  additional_vehicle_color: null,
  additional_vehicle_license_plate: null,
}

export const userParamsActionTypes = {
  email: 'UPDATE_EMAIL',
  first_name: 'UPDATE_FIRST_NAME',
  last_name: 'UPDATE_LAST_NAME',
  phone: 'UPDATE_PHONE',
  license_plate: 'UPDATE_LICENSE_PLATE',
  vehicle_year: 'UPDATE_VEHICLE_YEAR',
  vehicle_make: 'UPDATE_VEHICLE_MAKE',
  vehicle_color: 'UPDATE_VEHICLE_COLOR',
  vehicle_rig_type: 'UPDATE_VEHICLE_RIG_TYPE',
  vehicle_rig_length: 'UPDATE_VEHICLE_RIG_LENGTH',
  vehicle_electric: 'UPDATE_VEHICLE_ELECTRIC',
  pets_info: 'UPDATE_PETS_INFO',
  user: 'UPDATE_USER',
  address: 'UPDATE_ADDRESS',
  emergency_contact_name: 'UPDATE_EMERGENCY_CONTACT_NAME',
  emergency_contact_relationship: 'UPDATE_EMERGENCY_CONTACT_RELATIONSHIP',
  emergency_contact_email: 'UPDATE_EMERGENCY_CONTACT_EMAIL',
  emergency_contact_phone: 'UPDATE_EMERGENCY_CONTACT_PHONE',

  additional_vehicle_make: 'UPDATE_ADDITIONAL_VEHICLE_MAKE',
  additional_vehicle_year: 'UPDATE_ADDITIONAL_VEHICLE_YEAR',
  additional_vehicle_color: 'UPDATE_ADDITIONAL_VEHICLE_COLOR',
  additional_vehicle_license_plate: 'UPDATE_ADDITIONAL_VEHICLE_LICENSE_PLATE',
}

const userParamsReducer = (state: UserProps | ICamper, action: any): UserProps | ICamper => {
  switch (action.type) {
    case userParamsActionTypes.email:
      return { ...state, email: action.payload.email }
    case userParamsActionTypes.first_name:
      return { ...state, first_name: action.payload.first_name }
    case userParamsActionTypes.last_name:
      return { ...state, last_name: action.payload.last_name }
    case userParamsActionTypes.phone:
      return { ...state, phone: action.payload.phone }
    case userParamsActionTypes.license_plate:
      // NOTE: for legacy reasons, license_plate does not have a vehicle_ prefix in the database
      return { ...state, license_plate: action.payload.license_plate }
    case userParamsActionTypes.vehicle_year:
      return { ...state, vehicle_year: action.payload.vehicle_year }
    case userParamsActionTypes.vehicle_make:
      return { ...state, vehicle_make: action.payload.vehicle_make }
    case userParamsActionTypes.vehicle_color:
      return { ...state, vehicle_color: action.payload.vehicle_color }
    case userParamsActionTypes.vehicle_rig_type:
      return { ...state, vehicle_rig_type: action.payload.vehicle_rig_type }
    case userParamsActionTypes.vehicle_rig_length:
      return { ...state, vehicle_rig_length: action.payload.vehicle_rig_length }
    case userParamsActionTypes.vehicle_electric:
      return { ...state, vehicle_electric: action.payload.vehicle_electric }
    case userParamsActionTypes.pets_info:
      return { ...state, pets_info: action.payload.pets_info }
    case userParamsActionTypes.address:
      return { ...state, address: action.payload.address }
    case userParamsActionTypes.user:
      return { ...action.payload.user }
    case userParamsActionTypes.emergency_contact_name:
      return { ...state, emergency_contact_name: action.payload.emergency_contact_name }
    case userParamsActionTypes.emergency_contact_relationship:
      return {
        ...state,
        emergency_contact_relationship: action.payload.emergency_contact_relationship,
      }
    case userParamsActionTypes.emergency_contact_email:
      return { ...state, emergency_contact_email: action.payload.emergency_contact_email }
    case userParamsActionTypes.emergency_contact_phone:
      return { ...state, emergency_contact_phone: action.payload.emergency_contact_phone }
    case userParamsActionTypes.additional_vehicle_make:
      return { ...state, additional_vehicle_make: action.payload.additional_vehicle_make }
    case userParamsActionTypes.additional_vehicle_year:
      return { ...state, additional_vehicle_year: action.payload.additional_vehicle_year }
    case userParamsActionTypes.additional_vehicle_color:
      return { ...state, additional_vehicle_color: action.payload.additional_vehicle_color }
    case userParamsActionTypes.additional_vehicle_license_plate:
      return {
        ...state,
        additional_vehicle_license_plate: action.payload.additional_vehicle_license_plate,
      }
    default:
      throw new Error(`ERROR: unknown action type ${action.type} in userParamsReducer`)
  }
}

export { userParamsReducer, userParamsInitialState }
