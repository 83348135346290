import React, { SyntheticEvent, useContext } from 'react'
import { DropdownProps, Form, Select } from 'semantic-ui-react'
import { AdminContext } from '../../../contexts/AdminContext'
import { SiteProps } from '../../../interfaces'

interface ComponentProps {
  userRigLength: number
  handleRigLengthChange: (event: SyntheticEvent, data: DropdownProps) => void
  isBoat: boolean
  isCamperLength: boolean
  sites: SiteProps[]
}

const MaxRigLengthField = ({
  userRigLength,
  handleRigLengthChange,
  isBoat,
  isCamperLength,
  sites
}: ComponentProps) => {
  const suffix = isBoat ? 'ft Boat' : 'ft Rig'

  let rigLengthOptions: Array<{ key: number; value: number; text: string }> = []

  let maxLength = sites.reduce((highest, current) => {
    if (current.is_bookable) {
      return current.max_rig_length > highest ? current.max_rig_length : highest
    }
    return highest
  }, 0)

  if (isBoat) {
    rigLengthOptions = [null].concat(Array.from(new Array(maxLength >= 5 ? maxLength - 4 : 0), (x, i) => i + 5)).map((x) => {
      return { key: x, value: x, text: x ? `${x} ${suffix}` : '' }
    })
  } else {
    rigLengthOptions = [null].concat(Array.from(new Array(maxLength >= 12 ? maxLength - 11 : 0), (x, i) => i + 12)).map((x) => {
      return { key: x, value: x, text: x ? `${x} ${suffix}` : '' }
    })
  }

  const label = isBoat
    ? 'Boat length'
    : isCamperLength
    ? 'Camper length'
    : 'Rig Length'

  return (
    <Form.Field required>
      <label>{label}</label>
      <Select
        clearable
        search
        fluid
        className='sites-filter-dropdown'
        options={rigLengthOptions}
        value={userRigLength}
        onChange={handleRigLengthChange}
      />
    </Form.Field>
  )
}

export default MaxRigLengthField
