import React, { useContext, useEffect, useState } from 'react'
import refundImg from '../../../../assets/images/refund.png'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import Button from '../../ui/Button'
import { AdminContext } from '../../../contexts/AdminContext'
import api from '../../../api2'
import { ModalContext } from '../../../contexts/admin/ModalContext'
import InputFieldWithCurrency from '../../Admin/Inputs/InputFieldWithCurrency'
import TextAreaWithLabel from '../../Admin/Inputs/TextAreaWithLabel'
import { useDebouncedCallback } from 'use-debounce'
import ProcessingState from '../../ui/Admin/ProcessingState'
import twoDecimalPlaces from '../../../utils/twoDecimalPlaces'
import RefundOptionSelector from './RefundOptionsSelector'
import { refundFilterOptions } from './RefundOptions'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import HoverTip from '../../ui/HoverTip'
import RefundOrderSummary from './RefundOrderSummary'
import IPayment from '../../../interfaces/IPayment'
import { set } from 'lodash'

interface IComponentProps {
  payment: IPayment
  initialAmount: number
  externalUrl: string
}

const RefundModalContents = ({ payment, initialAmount, externalUrl }: IComponentProps) => {
  const { onClose } = useContext(ModalContext)
  const { camp, showNotification } = useContext(AdminContext)
  const [isRefundingServiceFee, setIsRefundingServiceFee] = useState(false)
  const [refundAmount, setRefundAmount] = useState(Number(twoDecimalPlaces(initialAmount)))
  const [reason, setReason] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>()
  const [showSuccess, setShowSuccess] = useState<boolean>()
  const [selectedFilterOption, setSelectedFilterOption] = useState(refundFilterOptions[0])

  const debouncedRefund = useDebouncedCallback(() => {
    api.Payments.refund(payment.id, {
      amountInCents: Number(refundAmount) * 100.0,
      reason: reason,
      isRefundingServiceFee: isRefundingServiceFee,
    })
      .then(() => {
        setShowSuccess(true)
        setTimeout(() => {
          onClose()
        }, 2000)
      })
      .catch((err) => {
        showNotification({
          type: 'error',
          title: 'Failed to refund payment',
          description: err.message,
          dismissAfter: 5000,
        })
        console.error(err)
        setIsLoading(false)
      })
  }, 100)

  useEffect(() => {
    switch (selectedFilterOption.value) {
      case 'net':
        setRefundAmount((payment.amount_in_cents - payment.application_fee_in_cents - payment.stripe_fee_in_cents) / 100)  
        break;
      case 'full':
        setRefundAmount(payment.amount_in_cents / 100)
        break;
      default:
        break;
    }
  }, [selectedFilterOption])

  const handleCancel = () => {
    onClose()
  }

  const handleRefund = () => {
    setIsLoading(true)
    debouncedRefund()
  }

  if (isLoading) {
    return (
      <ProcessingState
        loadingMessages={[
          'Processing refund...',
          'Contacting payment processor...',
          "Dotting i's, crossing t's...",
          'Almost finished...',
        ]}
        successMessage={showSuccess && 'Refund successful'}
      />
    )
  }

  return (
    <div className='flex items-start gap-3'>
      <img src={refundImg} className='hidden xs:block' />
      <div className='flex flex-col'>
        <div className='mb-5'>
          <div className='font-semibold text-lg mb-1.5'>Refund payment</div>
          <div className=''>
            Refunds take 5-10 days to appear on a customer's statement. Stripe's fees for the
            original payment won't be returned.
          </div>
        </div>
        <div className='mb-3 flex flex-col justify-start'>
          <RefundOptionSelector
            options={refundFilterOptions}
            selectedOption={selectedFilterOption}
            onChange={setSelectedFilterOption}
          />
          <InputFieldWithCurrency
            value={refundAmount}
            onChange={(e) => {
              setRefundAmount(e.target.value)
              setSelectedFilterOption(refundFilterOptions[2])
            }}
            currency={camp.currency}
            disabled={isLoading}
            label='Refund'
          />
          <div className='mt-4 flex'>
            <CheckboxWithLabel
              label={`Refund the service fee`}
              value={isRefundingServiceFee}
              setValue={() => {
                if (isRefundingServiceFee) {
                  setIsRefundingServiceFee(false)

                  if (selectedFilterOption.value === 'net') {
                    setRefundAmount(
                      Number(
                        twoDecimalPlaces(
                          (payment.amount_in_cents -
                            payment.application_fee_in_cents -
                            payment.stripe_fee_in_cents) /
                            100
                        )
                      )
                    )
                  }
                } else {
                  if (selectedFilterOption.value === 'net') {
                    setRefundAmount(
                      Number(
                        twoDecimalPlaces(
                          (payment.amount_in_cents -
                            payment.application_fee_in_cents -
                            payment.stripe_fee_in_cents) /
                            (1 - payment.application_fee_in_cents / payment.amount_in_cents) /
                            100
                        )
                      )
                    )
                  }

                  setIsRefundingServiceFee(true)
                }
              }}
              disabled={isLoading}
              labelClasses='text-xs font-semibold'
            />
            <HoverTip
              content={
                "When checked, an amount of Park's service fee proportional to the refund amount will be drawn from our account to contribute to the refund."
              }
              width='w-96'
              white>
              <InformationCircleIcon className='w-3.5 h-3.5 ml-0.5' />
            </HoverTip>
          </div>
          <RefundOrderSummary
            paymentTotal={payment.amount_in_cents}
            parkApplicationFees={payment.application_fee_in_cents}
            stripeProcessingFees={payment.stripe_fee_in_cents}
            yourNet={
              payment.amount_in_cents -
              payment.application_fee_in_cents -
              payment.stripe_fee_in_cents
            }
            yourUpdatedNet={
              isRefundingServiceFee
                ? payment.amount_in_cents -
                  (payment.stripe_fee_in_cents + payment.application_fee_in_cents) +
                  ((refundAmount * 100) / payment.amount_in_cents) *
                    payment.application_fee_in_cents -
                  refundAmount * 100
                : payment.amount_in_cents -
                  payment.application_fee_in_cents -
                  payment.stripe_fee_in_cents -
                  refundAmount * 100
            }
            camp={camp}
            externalUrl={externalUrl}
          />
        </div>
        <div className=''>
          <TextAreaWithLabel
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            disabled={isLoading}
            label='Reason for refund (optional - for internal use only)'
          />
        </div>
        <div className='flex justify-end gap-3 mt-6'>
          <Button
            variant='none'
            className='border border-gray-350 text-gray-500 hover:bg-gray-300 hover:text-gray-600'
            children={'Cancel'}
            disabled={isLoading}
            onClick={handleCancel}
          />
          <Button variant='blue' children={'Refund'} disabled={isLoading} onClick={handleRefund} />
        </div>
      </div>
    </div>
  )
}

export default RefundModalContents
