import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import classNames from '../../../utils/classNames'
import { FormProvider } from '../../../contexts/FormContext'
import { CampingStyleProps } from '../../../interfaces'
import useAdminForm from '../../../hooks/useAdminForm'
import Form from '../../ui/Admin/Form'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Button from '../../ui/Button'
import api from '../../../api2'
import { AdminContext } from '../../../contexts/AdminContext'
import Toggle from '../../ui/Toggle'
import { Form as TimePicker } from 'semantic-ui-react'
import moment from 'moment'
import formatCampingStyleName from '../../../utils/formatCampingStyleName'
import { titleCase } from '../../../utils'

interface ComponentProps {
  campingStyle: CampingStyleProps
  setSelectedCampingStyle: React.Dispatch<React.SetStateAction<CampingStyleProps>>
  closeModal: () => void
}

const CampingStyleSettingsSlideover = (props: ComponentProps) => {
  
  if(!props.campingStyle) return <></>

  return (
    <Transition.Root show={!!props.campingStyle} as={Fragment}>
      <Dialog as='div' className='relative z-30' onClose={props.closeModal}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16' style={{ marginTop: '0px' }}>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className={classNames('pointer-events-auto w-screen max-w-xl')}>
                  <div className='flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl'>
                    {props.campingStyle && (
                      <FormProvider init={props.campingStyle}>
                        <EditCampingStyleForm
                          campingStyle={props.campingStyle}
                          setSelectedCampingStyle={props.setSelectedCampingStyle}
                          closeModal={props.closeModal}
                        />
                      </FormProvider>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const EditCampingStyleForm = ({
  campingStyle,
  setSelectedCampingStyle,
  closeModal
}: {
  campingStyle: CampingStyleProps
  setSelectedCampingStyle: React.Dispatch<React.SetStateAction<CampingStyleProps>>
  closeModal: () => void
}) => {
  const { setCampingStyles, showNotification, isParkAdminUser } = useContext(AdminContext)
  const { resource, updateDefaults, reset, register, isDirty, watch, setValue } = useAdminForm<CampingStyleProps>()

  const [isProcessing, setIsProcessing] = useState(false)

  const updateLocalCampingStyles = (updatedCampingStyles: CampingStyleProps[]) => {
    setCampingStyles((prevCampingStyles) =>
      prevCampingStyles.map((c) => {
        const updatedCampingStyle = updatedCampingStyles.find((uc) => uc.id === c.id)
        return updatedCampingStyle ? updatedCampingStyle : c
      })
    )
  }

  const updateSelectedCampingStyle = (updatedCampingStyle: CampingStyleProps) => {
    setSelectedCampingStyle((prevCampingStyle) => {
      return { ...prevCampingStyle, ...updatedCampingStyle }
    })
  }

  const updateFormState = (updatedCampingStyle: CampingStyleProps) => {
    updateDefaults(updatedCampingStyle)
    reset(updatedCampingStyle)
  }

  const updateCampingStyle = async (e) => {
    e.preventDefault()

    setIsProcessing(true)
    api.CampingStyles.update(campingStyle.id, resource)
      .then(({ camping_style: updatedCampingStyle }) => {
        updateLocalCampingStyles([updatedCampingStyle])
        updateSelectedCampingStyle(updatedCampingStyle)
        updateFormState(updatedCampingStyle)

        showNotification({
          type: 'success',
          title: 'Changes saved!',
          description: `Sucessfully updated your ${titleCase(updatedCampingStyle.name)} settings`,
        })
        setIsProcessing(false)
      })
      .catch((err) => {
        console.error(err)
        showNotification({
          type: 'error',
          title: 'Error',
          description:
            `There was an error updating your settings. Please try again.`,
        })
        setIsProcessing(false)
      })
  }

  useEffect(() => {
    register('notes', {})
    register('is_bookable_online', {})
  }, [register])

  if (!campingStyle) {
    return <></>
  }

  return (
    <form className='flex flex-col h-full' onSubmit={updateCampingStyle}>
      <div className='overflow-auto flex-1 w-full'>
        <div className='px-4 sm:px-6'>
          <div className='text-xl py-6 font-bold w-full flex items-center justify-between border-b border-gray-200'>
            Edit {formatCampingStyleName(campingStyle.name)} camping style
            <button
              type='button'
              className='rounded-lg bg-white hover:bg-gray-200 p-1 focus:outline-none'
              onClick={closeModal}>
              <XMarkIcon className='h-7 w-7' aria-hidden='true' />
            </button>
          </div>
          <div className='flex flex-col gap-3 py-6'>
            <SwitchField
              field='is_showing_features'
              label='Visible features:'
              description='When toggled off, does not show site features in the booker'
            />
            <Form.NumberInput
              field='minimum_bookable_nights'
              label='Minimum bookable nights:'
              isIntegerOnly
            />
            <SwitchField
              field='allow_same_day_reservations'
              label='Same day reservations:'
              description="When toggled off, guests can't book a reservation for the current day/night. Not recommend for properties with walk-ins."
            />
            {resource.allow_same_day_reservations == true && (
              <TimePicker.Input
                step='900'
                type='time'
                field='same_day_reservations_cutoff_time'
                label='Same day reservations cutoff time:'
                defaultValue={moment(
                  campingStyle.same_day_reservations_cutoff_time,
                  'HH:mm'
                ).format('HH:mm')}
                onChange={(e) => setValue('same_day_reservations_cutoff_time', e.target.value)}
                className='flex flex-col gap-1 text-md font-medium text-gray-900'
              />
            )}
            <TimePicker.Input
              step='900'
              type='time'
              field='checkin_time'
              label='Checkin time:'
              defaultValue={moment(campingStyle.checkin_time, 'HH:mm').format('HH:mm')}
              onChange={(e) => setValue('checkin_time', e.target.value)}
              className='flex flex-col gap-1 text-md font-medium text-gray-900'
            />
            <TimePicker.Input
              step='900'
              type='time'
              field='checkout_time'
              label='Checkout time:'
              defaultValue={moment(campingStyle.checkout_time, 'HH:mm').format('HH:mm')}
              onChange={(e) => setValue('checkout_time', e.target.value)}
              className='flex flex-col gap-1 text-md font-medium text-gray-900'
            />
            <Form.PercentFieldWithLabel 
              field='tax_rate'
              label='Tax rate:'
              prefix='%'
              placeholder='0'
            />
            <Form.DollarFieldWithLabel
              field='holiday_fee_in_cents'
              label='Holiday fee:'
              prefix='$'
            />
            <Form.DollarFieldWithLabel
              field='weekend_fee_in_cents'
              label='Weekend fee:'
              prefix='$'
            />
            <Form.DollarFieldWithLabel
              field='default_deposit_in_cents'
              label='Default deposit:'
              subheader='(default when taking a deposit for a manual reservation)'
              prefix='$'
            />
            <Form.RichTextInput field='notes' label='Notes' subheader='(shown when booking)' />
            {isParkAdminUser && (
              <>
                <hr className='mt-6 mb-4' />

                <div className='text-lgxl font-bold'>Park Admin fields</div>
                <Toggle
                  disabled={isProcessing}
                  checked={watch('is_bookable_online')}
                  onChange={(v) => setValue('is_bookable_online', v)}
                  name='isBookable'
                  label='Bookable'
                  description='When disabled, this camping style will not be bookable online.'
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className='py-4 border-t border-gray-200 w-full flex justify-end px-4'>
        <Button
          type='button'
          variant='gray'
          className={classNames('mr-4 w-28', isProcessing && 'pointer-events-none opacity-30')}
          disabled={isProcessing}
          onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='green'
          className={classNames(
            'w-28',
            (isProcessing || !isDirty) && 'pointer-events-none opacity-30'
          )}
          disabled={isProcessing || !isDirty}>
          Save
        </Button>
      </div>
    </form>
  )
}

type BooleanFields = 'is_bookable_online' | 'is_showing_features' | 'allow_same_day_reservations'

const SwitchField = ({ field, label, description }: { field: BooleanFields; label: string, description?: string }) => {
  const { setValue, watch, register } = useAdminForm<CampingStyleProps>()

  const checked = watch(field)

  useEffect(() => {
    register(field, {})
  }, [register])

  return (
    <div className='flex justify-between items-center w-full'>
      <div className=''>
        <label className='mb-1 block text-md font-medium text-gray-900'>{label}</label>
        <div className='text-sm text-gray-500'>{description}</div>
      </div>
      <Switch
        checked={checked}
        onChange={(v) => setValue(field, v)}
        className={classNames(
          checked ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-200 hover:bg-gray-300',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </div>
  )
}

export default CampingStyleSettingsSlideover
