import React, { MutableRefObject, useState } from 'react'
import { titleCase } from '../../../utils'
import classNames from '../../../utils/classNames'
import { RefCallBack, UseFormRegister } from 'react-hook-form'

const InputFieldWithLabel = ({
  type,
  inputRef,
  label,
  name,
  value,
  defaultValue,
  disabled,
  readOnly,
  className,
  inputClassName,
  onError,
  onChange,
  onBlur,
  style,
  full,
  register,
  required,
  step,
}: {
  type?: string
  inputRef?: MutableRefObject<any> | RefCallBack
  label?: string
  name?: string
  value?: any
  defaultValue?: any
  disabled?: boolean
  readOnly?: boolean
  className?: string
  inputClassName?: string
  onError?: (err) => any
  onChange?: (e) => any
  onBlur?: (e) => any
  style?: any
  full?: boolean
  register?: UseFormRegister<any>
  required?: boolean
  step?: string | number
}) => {
  const [bgColorClass, setBgColorClass] = useState('bg-white')

  return (
    <div
      className={classNames(
        full && 'w-full transition-opacity duration-300',
        className,
        disabled && 'opacity-30'
      )}>
      {label && (
        <label htmlFor='name' className='block font-semibold text-md text-gray-900 mb-0.5'>
          {label || titleCase(name)}
          {required && <span className='text-red-600 ml-0.5 text-lg'>*</span>}
        </label>
      )}
      <div>
        <input type='text' autoComplete='false' className='hidden'></input>
        <input
          ref={inputRef}
          type={type || 'text'}
          value={value}
          autoComplete='new-password'
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={readOnly}
          name={name}
          id={name}
          step={step}
          onError={onError}
          required={required}
          onChange={(e) => {
            if (defaultValue !== undefined && e.target.value !== defaultValue) {
              setBgColorClass('bg-blue-50')
            } else {
              setBgColorClass('bg-white')
            }

            if (onChange) onChange(e)
          }}
          onBlur={onBlur}
          style={style}
          className={classNames(
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700',
            bgColorClass,
            inputClassName
          )}
          {...(register ? register(name, {}) : {})}
        />
      </div>
    </div>
  )
}

export default InputFieldWithLabel
