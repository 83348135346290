function hashStringToSeed(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    // JavaScript performs bitwise operations using 32-bit signed integers.
    // We use unsigned right shift to ensure positive values.
    hash = (hash << 5) - hash + char
    hash >>>= 0 // Convert to 32bit unsigned integer
  }
  return hash
}

function seededRandom(seed) {
  const a = 1664525
  const c = 1013904223
  const m = 4294967296 // 2^32
  // Ensure modulo operation behaves as expected by using positive seeds
  seed = (a * seed + c) % m
  return seed / m
}

function generateAvatar(hashString) {
  if (!hashString || hashString.length === 0) {
    return 0
  }

  const seed = hashStringToSeed(hashString)
  let random = Math.floor(seededRandom(seed) * 9) + 1 // Generate number 1-9
  return random
}

// Example usage:
const num = generateAvatar('timgay')
console.log(num) // This will output a valid number between 1 and 9

export default generateAvatar
