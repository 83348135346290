import { useContext } from 'react'
import { FormContext, ICtxPayload } from '../contexts/FormContext'

function useAdminForm<T = any>(): ICtxPayload<T> {
  const context = useContext(FormContext)
  if (!context) throw new Error('useAdminForm must be used within an AdminFormProvider')
  return context
}

export default useAdminForm
