import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import React, { useContext, useEffect, useState } from 'react'
import api from '../../../api2'
import Button from '../../ui/Button'
import Spinner from '../../ui/Spinner'
import { AdminContext } from '../../../contexts/AdminContext'
import Alert from '../../ui/Alert'
import ILineItem from '../../../interfaces/ILineItem'
import IPaymentMethods from '../../../interfaces/IPaymentMethods'
import { mapLineItemToParams } from '../../../api2/orders'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import { RadioGroup } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { WifiIcon } from '@heroicons/react/24/outline'
import ePaymentType from '../../../enums/ePaymentType'
import IOrder from '../../../interfaces/IOrder'
import { formatName } from '../../../utils/formatName'

// TODO: this publick key will always be in the FE code, but it'd be nice to add
// it as an ENV to get it out of the codebase directly.
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBKEY

interface ComponentProps {
  ctaText: string
  paymentIntentId: string
  setPaymentIntentId: (intentId: string) => void
  onSuccess: () => void
  order: IOrder
  isCamperPresent: boolean
  campingStyleId: string
  isTaxFeeEnabled: boolean
  isCreditCardFeeEnabled: boolean
  discountSlug: string
  lineItems: Array<Partial<ILineItem>>
  depositLineItems: Array<Partial<ILineItem>>
  paymentMethod: ePaymentType
  setPaymentMethod: (method: ePaymentType) => void
  paymentMethods: Array<IPaymentMethods>
  errorMessage: string
  setErrorMessage: (message: string) => void
  setPaymentStatus: (status: string) => void
  setIntervalId: (id: any) => void
  setShowCloseButton: (show: boolean) => void
  setNewOrder?: (order: IOrder) => void
}

const StripeCardForm = ({
  ctaText,
  setPaymentIntentId,
  onSuccess,
  order,
  isCamperPresent,
  campingStyleId,
  isTaxFeeEnabled,
  isCreditCardFeeEnabled,
  discountSlug,
  lineItems,
  depositLineItems,
  paymentMethod,
  setPaymentMethod,
  paymentMethods,
  errorMessage,
  setErrorMessage,
  setPaymentStatus,
  setIntervalId,
  setShowCloseButton,
  setNewOrder
}: ComponentProps) => {
  const { camp } = useContext(AdminContext)
  const { stripe_reader_id, stripe_account_id, pos_service_rate } = camp

  const [stripePromise, setStripePromise] = useState<Promise<Stripe>>()

  useEffect(() => {
    setStripePromise(loadStripe(stripePublicKey, { stripeAccount: stripe_account_id }))
  }, [])

  if (!stripePromise) {
    return (
      <div className='flex flex-col justify-center items-center'>
        <Spinner size={5} />
        <div className='mt-1 text-gray-400 text-sm'>Loading...</div>
      </div>
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <Form
        ctaText={ctaText}
        setPaymentIntentId={setPaymentIntentId}
        onSuccess={onSuccess}
        order={order}
        isCamperPresent={isCamperPresent}
        campingStyleId={campingStyleId}
        isTaxFeeEnabled={isTaxFeeEnabled}
        isCreditCardFeeEnabled={isCreditCardFeeEnabled}
        discountSlug={discountSlug}
        lineItems={lineItems}
        depositLineItems={depositLineItems}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        paymentMethods={paymentMethods}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setPaymentStatus={setPaymentStatus}
        setIntervalId={setIntervalId}
        setShowCloseButton={setShowCloseButton}
        setNewOrder={setNewOrder}
      />
    </Elements>
  )
}

const Form = ({
  ctaText,
  setPaymentIntentId,
  onSuccess,
  order,
  isCamperPresent,
  campingStyleId,
  isTaxFeeEnabled,
  isCreditCardFeeEnabled,
  discountSlug,
  lineItems,
  depositLineItems,
  paymentMethod,
  setPaymentMethod,
  paymentMethods,
  errorMessage,
  setErrorMessage,
  setPaymentStatus,
  setIntervalId,
  setShowCloseButton,
  setNewOrder
}: {
  ctaText: string
  setPaymentIntentId: (intentId: string) => void
  onSuccess: () => void
  order: IOrder
  isCamperPresent: boolean
  campingStyleId: string
  isTaxFeeEnabled: boolean
  isCreditCardFeeEnabled: boolean
  discountSlug: string
  lineItems: Array<Partial<ILineItem>>
  depositLineItems: Array<Partial<ILineItem>>
  paymentMethod: 'link' | 'card-reader' | 'card-input' | 'cash'
  setPaymentMethod: (method: 'link' | 'card-reader' | 'card-input' | 'cash') => void
  paymentMethods: Array<IPaymentMethods>
  errorMessage: string
  setErrorMessage: (message: string) => void
  setPaymentStatus: (status: string) => void
  setIntervalId: (id: any) => void
  setShowCloseButton: (show: boolean) => void
  setNewOrder?: (order: IOrder) => void
}) => {
  const { camp } = useContext(AdminContext)
  const isCardReader = !!camp.stripe_reader_id
  const { showConfirm } = useContext(AdminContext)

  const [isDisabled, setIsDisabled] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSavingCc, setIsSavingCc] = useState(false)
  const [localPaymentMethods, setLocalPaymentMethods] = useState<IPaymentMethods[]>(paymentMethods);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    localPaymentMethods?.length > 0 ? localPaymentMethods[0].id : isCardReader ? 'reader' : 'new'
  )

  useEffect(() => {
    if (selectedPaymentMethodId === 'new' && paymentMethods?.length) {
      setSelectedPaymentMethodId(paymentMethods[0].id)
      setLocalPaymentMethods(paymentMethods)
    } else if (!paymentMethods?.length && !order?.id) {
      setSelectedPaymentMethodId(isCardReader ? 'reader' : 'new')
      setLocalPaymentMethods([])
    }
  }, [paymentMethods])

  const stripe = useStripe()
  const elements = useElements()

  const handleCardElementChange = (event) => {
    setErrorMessage('')
    setIsDisabled(event.error || !event.complete)
  }

  const handleCancelCreditCard = async (camperId: string, method: any) => {
    setIsProcessing(true)
    if (
      await showConfirm('Are you sure you want to delete this credit card?', {
        description: method.brand.toUpperCase() + ' ending in ' + method.last4,
      })
    ) {
      api.Campers.removeCreditCard(camperId, method.id)
        .then(() => {
          setIsProcessing(false)
          setLocalPaymentMethods((prevMethods) =>
            prevMethods.filter((method) => method.id !== method.id)
          )
          return
        })
        .catch((err) => {
          setIsProcessing(false)
          console.error(err)
          setErrorMessage(err.message)
        })
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    setIsProcessing(true)
    setErrorMessage('')

    const isCardReader = selectedPaymentMethodId === 'reader'

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      const { payment_intent } = await api.Orders.createPaymentIntent({
        order_id: order?.id,
        camp_id: camp.id,
        camper_id: order?.camper_id,
        camping_style_id: campingStyleId,
        is_credit_card_fee_enabled: isCreditCardFeeEnabled,
        discount_slug: discountSlug,
        is_storing_credit_card_enabled: isSavingCc,
        is_terminal_payment: isCardReader,
        selected_payment_method_id:
          selectedPaymentMethodId === 'new' || selectedPaymentMethodId === 'reader'
            ? null
            : selectedPaymentMethodId,
        line_items_attributes: lineItems.map((li) => mapLineItemToParams(li, isTaxFeeEnabled)),
        partial_line_items_attributes: depositLineItems?.map((li) =>
          mapLineItemToParams(li, false)
        ),
      })

      setNewOrder && setNewOrder(payment_intent.order) 
      // THIS is for paying with stored card
      if (payment_intent.status === 'succeeded') {
        setIsProcessing(false)
        onSuccess()
        return
      }

      setPaymentIntentId(payment_intent.id)

      const clientSecret = payment_intent.client_secret

      let result = null

      if (isCardReader) {
        setPaymentMethod('card-reader')
        result = api.Terminal.processPayment(camp.id, payment_intent.id)
          .then(() => {
            let attemptCount = 0
            const maxAttempts = 10

            const intervalId = setInterval(async () => {
              setIntervalId(intervalId)

              const result = await api.Terminal.checkPaymentStatus(camp.id, payment_intent.id)

              setPaymentStatus(result?.action?.status)

              attemptCount++

              if (result?.action?.status == 'succeeded') {
                clearInterval(intervalId)
                setIsProcessing(false)
                onSuccess()
                return
              } else {
                if (result?.action?.status == 'failed' || attemptCount >= maxAttempts) {
                  if (result?.action?.status == 'failed') {
                    console.error(result?.action?.failure_message)
                    setErrorMessage(result?.action?.failure_message)
                  } else {
                    console.error('Max attempts reached')
                    setErrorMessage('Max attempts reached')
                  }
                  if (camp.stripe_reader_id) {
                    api.Terminal.cancelCurrentPayment(camp.id, payment_intent.id).catch((err) => {
                      console.error(err.message)
                    })
                  }
                  setPaymentStatus('failed')
                  setIsProcessing(false)
                  clearInterval(intervalId)
                  return
                }
              }
            }, 5000)

            setIsProcessing(false)
            return
          })
          .catch((err) => {
            console.error('processPaymentFailure')
            console.error(err)
            console.error(err.message)
            setErrorMessage(err.message)
            setIsProcessing(false)
            setPaymentStatus('failed')

            return
          })
        setShowCloseButton(true)
      } else {
        result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        })
      }

      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        console.error(result.error.message)
        setErrorMessage(result.error.message)

        setIsProcessing(false)

        if (isCardReader && camp.stripe_reader_id) {
          api.Terminal.cancelCurrentPayment(camp.id, payment_intent.id).catch((err) => {
            console.error(err.message)
          })
        }

        return
      } else {
        if (!isCardReader) {
          // With CC details
          setIsProcessing(false)

          // The payment has been processed!
          if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            onSuccess()
          }
        }
      }
    } catch (err) {
      console.error('[FATAL]', err)
      setIsProcessing(false)

      if (isCardReader && camp.stripe_reader_id) {
        api.Terminal.cancelCurrentPayment(camp.id).catch((err) => {
          console.error(err.message)
        })
      }
    }
  }

  return (
    <form autoComplete='chrome-off' onSubmit={handleSubmit}>
      {!!errorMessage && (
        <div className='mb-4'>
          <Alert description={errorMessage} />
        </div>
      )}

      <RadioGroup
        value={selectedPaymentMethodId}
        onChange={setSelectedPaymentMethodId}
        className='mb-4'>
        <div className='space-y-2 -mt-1.5'>
          {localPaymentMethods?.length > 0 &&
            localPaymentMethods.map((method, idx) => (
              <RadioGroup.Option key={`payment-method-${idx}`} value={method.id}>
                {({ checked }) => (
                  <div
                    className={`py-2 flex justify-between rounded-lg cursor-pointer ${
                      checked ? 'border-2 border-green-600 font-semibold' : 'border-gray-300 border'
                    }`}>
                    <div className='flex flex-row items-center'>
                      <div className='p-1.5 mx-3.5 bg-white border border-green-500 rounded-full w-5 h-5 flex items-center justify-center'>
                        <div
                          className={`w-3 h-3 rounded-full flex-shrink-0 ${
                            checked ? 'bg-green-600' : 'bg-white'
                          }`}
                        />
                      </div>
                      <div className='flex flex-col justify-center'>
                        <div className='leading-4'>
                          {method.brand.toUpperCase()} ending in {method.last4}
                        </div>
                        <div className='text-gray-400 text-xs font-normal'>
                          Expires {method.exp_month}/{method.exp_year}
                        </div>
                      </div>
                    </div>
                    {order?.camper_id && (
                        <XMarkIcon
                          className='h-8 w-8 text-gray-700 cursor-pointer mr-1 p-0.75 rounded-full hover:bg-gray-100'
                          onClick={() => handleCancelCreditCard(order?.camper_id, method)}
                        />
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          {isCardReader && (
            <RadioGroup.Option value='reader'>
              {({ checked }) => (
                <div
                  className={`py-2 px-3 flex items-center rounded-lg cursor-pointer ${
                    checked
                      ? 'border-2 border-green-600 text-green-600 font-semibold'
                      : 'border-gray-300 border'
                  }`}>
                  <WifiIcon className='h-5 w-5 mr-2 transform rotate-90' />
                  Take payment with card reader
                </div>
              )}
            </RadioGroup.Option>
          )}
          {(isCardReader || localPaymentMethods?.length > 0) && (
            <RadioGroup.Option value='new'>
              {({ checked }) => (
                <div
                  className={`py-2 px-3 flex items-center rounded-lg cursor-pointer ${
                    checked
                      ? 'border-2 border-green-600 text-green-600 font-semibold'
                      : 'border-gray-300 border'
                  }`}>
                  <PlusIcon className='w-5 h-5 mr-2' />
                  Add new card
                </div>
              )}
            </RadioGroup.Option>
          )}
        </div>
      </RadioGroup>

      {selectedPaymentMethodId == 'new' && (
        <div className='mb-4 rounded-md border border-gray-200 py-2 px-2.5'>
          <CardElement onChange={handleCardElementChange} />
        </div>
      )}

      {(selectedPaymentMethodId == 'new' || selectedPaymentMethodId == 'reader') &&
        isCamperPresent && (
          <CheckboxWithLabel
            label='Save card securely for future payments'
            value={isSavingCc}
            setValue={setIsSavingCc}
            labelClasses='text-md whitespace-nowrap'
          />
        )}

      <div className='mt-6 flex items-center justify-end'>
        <Button
          type='submit'
          className='px-6 flex justify-center items-center'
          variant='blue'
          disabled={!stripe || !elements || isProcessing || Number(order?.total_in_cents) <= 0}>
          {isProcessing ? (
            <>
              <Spinner size={7} className='-mr-1 absolute' />
              {ctaText}
            </>
          ) : (
            ctaText
          )}
        </Button>
      </div>
    </form>
  )
}

export default StripeCardForm
