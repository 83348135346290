const titleCase = (str: string) => {
  if (!str) return ''

  let words: string[] = str.toLowerCase().split(/\s/)
  let final = []

  for (let word of words) {
    if (word === 'rv') {
      final.push('RV')
    } else if (word.includes('-')) {
      let hyphen_arr = []
      let splits = word.split('-')

      for (let split of splits) {
        hyphen_arr.push(split.charAt(0).toUpperCase() + split.slice(1))
      }
      final.push(hyphen_arr.join('-'))
    } else {
      final.push(word.charAt(0).toUpperCase() + word.slice(1))
    }
  }
  return final.join(' ').replace(/_/g, ' ')
}

export default titleCase
