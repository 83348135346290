import { PriceDetails as IPriceDetail } from './priceDetailsFor'

/** Sums all values into one price details object */
const mergePriceDetails = (pds: IPriceDetail[]): IPriceDetail => {
  let pd = pds[0] || {} as IPriceDetail

  for (let i = 1; i < pds.length; i++) {
    if (pds[i] === null) continue

    pd = {
      ...pd,
      nightly_price: pd.nightly_price + pds[i].nightly_price,
      subtotal: pd.subtotal + pds[i].subtotal,
      discounted_subtotal: pd.discounted_subtotal + pds[i].discounted_subtotal,
      holiday_total_fee: pd.holiday_total_fee + pds[i].holiday_total_fee,
      discounted_holiday_total_fee: pd.discounted_holiday_total_fee + pds[i].discounted_holiday_total_fee,
      service_fee: pd.service_fee + pds[i].service_fee,
      tax: pd.tax + pds[i].tax,
      total: pd.total + pds[i].total,
      additional_fees: [
        ...(pd.additional_fees || []),
        ...(pds[i].additional_fees || []),
      ],
      adults_fee: pd.adults_fee + pds[i].adults_fee,
      kids_fee: pd.kids_fee + pds[i].kids_fee,
    }
  }

  return pd
}

export default mergePriceDetails
