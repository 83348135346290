import { isEqual } from 'lodash'
import React, { createContext, useContext, ReactNode, PropsWithChildren, useState, useEffect } from 'react'
import { Control, DeepPartial, ErrorOption, FieldErrors, Path, SubmitHandler, UseFormHandleSubmit, UseFormRegister, UseFormSetValue, UseFormWatch, useForm } from 'react-hook-form'

export type ICtxPayload<S> = {
  resource: S
  register: UseFormRegister<S>
  control: Control<S>
  handleSubmit: UseFormHandleSubmit<S>
  defaultValues: S
  resetDefaults: () => void
  reset: (values?: DeepPartial<S>) => void
  updateDefaults: (newDefaults: S) => void
  isDirty: boolean
  isChanged: (field: string) => boolean
  watch: UseFormWatch<S>
  setValue: UseFormSetValue<S>
  errors: FieldErrors<S>
  setError: (name: keyof S, error: FieldErrors<S>[keyof S]) => void
  clearError: (name: keyof S) => void
  clearErrors: () => void
}

export const FormContext = createContext<ICtxPayload<any> | undefined>(undefined)

type FormProviderProps<S> = {
  init: DeepPartial<S>
  children: React.ReactNode
}

export const FormProvider = <S,>({ children, init }: FormProviderProps<S>) => {
  const [defaultValues, setDefaultValues] = useState<S>(init as S)
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<S>({ defaultValues: defaultValues as DeepPartial<S> })
  const [isDirty, setIsDirty] = useState(false)
  const resource = watch()

  useEffect(() => {
    setDefaultValues(init as S)
    reset(init as DeepPartial<S>)
  }, [init])

  useEffect(() => {
    setIsDirty(Object.keys(defaultValues).some((key) => isChanged(key)))
  }, [resource, defaultValues])

  // if user tries to navigate away from page, warn them that they will lose unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
        // TODO: make this app-native
        alert('You have unsaved changes. Are you sure you want to leave?')
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  const isChanged = (field: string) => {
    return !isEqual(normalizeValue(defaultValues[field]), normalizeValue(resource[field]))
  }

  const normalizeValue = (value: any) => {
    if (value === '' || value === undefined) {
      return null
    }

    if (typeof value === 'string') {
      const isNumber = /^-?\d+\.?\d*$/.test(value)
      return isNumber ? Number(value) : value
    }

    return value
  }

  const resetDefaults = () => {
    reset(defaultValues)
  }

  const updateDefaults = (newDefaults: S) => {
    setDefaultValues(prev => ({ ...prev, ...newDefaults }))
  }

  const setFieldError = (name: keyof S, error: FieldErrors<S>[keyof S]) => {
    setError(name as unknown as Path<S>, error as ErrorOption)
  }

  const clearFieldError = (name: keyof S) => {
    clearErrors(name as unknown as Path<S>)
  }

  const clearAllErrors = () => {
    Object.keys(errors).forEach((key) => clearFieldError(key as keyof S))
  }

  return (
    <FormContext.Provider
      value={{
        resource,
        register,
        control,
        handleSubmit,
        defaultValues,
        resetDefaults,
        reset,
        updateDefaults,
        isDirty,
        isChanged,
        watch,
        setValue,
        errors,
        setError: setFieldError,
        clearError: clearFieldError,
        clearErrors: clearAllErrors,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
