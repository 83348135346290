import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from '../../utils/classNames'
import { ModalProvider } from '../../contexts/admin/ModalProvider'

interface ComponentProps {
  children: JSX.Element
  open: boolean
  onClose: () => void
  className?: string
  maxWidth?: string
  isDynamicWidth?: boolean
  closeClassNames?: string
}

export default function Modal(props: ComponentProps) {
  return (
    <ModalProvider onClose={props.onClose}>
      {props.open && (
        <Transition.Root show={props.open} as={Fragment}>
          <Dialog
            as='div'
            className={classNames(
              'relative',
              props.className,
              !props.className?.includes('z-') && 'z-40'
            )}
            onClose={props.onClose}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-0 z-10 overflow-y-auto pt-1.5 pb-8 sm:mb-0'>
              <div className='flex min-h-full items-center justify-center p-3 text-center md:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-100'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                  <Dialog.Panel
                    className={classNames(
                      'relative transform overflow-visible rounded-lg bg-white',
                      props.maxWidth ? props.maxWidth : 'max-w-4xl',
                      !props.isDynamicWidth && 'w-full',
                      'px-4 pt-5 pb-4 text-left shadow-xl sm:my-8 sm:p-6'
                    )}>
                    <div
                      className={classNames(
                        'absolute top-0 right-0 pt-4.5 pr-4.5 block z-20',
                        props.closeClassNames
                      )}>
                      <button
                        type='button'
                        className='rounded-lg bg-white hover:bg-gray-200 p-1 focus:outline-none'
                        onClick={props.onClose}>
                        <span className='sr-only'>Close</span>
                        <XMarkIcon className='h-8 w-8' aria-hidden='true' />
                      </button>
                    </div>
                    {props.children}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </ModalProvider>
  )
}
