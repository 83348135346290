import React, { useState, Fragment, useEffect, useContext } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { Moment } from 'moment'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import classNames from '../../../utils/classNames'
import { AdminContext } from '../../../contexts/AdminContext'

interface ComponentProps {
  visible: boolean
  setSelectedDate: (data: Moment, visibleDate?: Moment) => void
  selectedDate: Moment
  seasonStart: string
  isQuarterlyView: boolean
  switchToTodayQuarterlyView: () => void
  daysBeforeVisibleAreaForQuarterly: number
  daysBeforeVisibleAreaForMonthly: number
  isMonthlyIntervals?: boolean
  setTodayDate: () => void
  setMonth: (month: string, isIntervalNameChanged?: boolean) => void
  selectedInterval: string
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>>
}

const ReservationsDatePicker = (props: ComponentProps) => {
  const { camp } = useContext(AdminContext)
  const [isFocusStart, setIsFocusStart] = useState(false)
  
  const intervalOptions = [
    'Today',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  camp.season_start_mm_dd && intervalOptions.splice(1, 0, ...[`Season Start, ${moment().year()}`, `Next Season Start, ${moment().year() + 1}`, `Previous Season Start, ${moment().year() - 1}`])

  useEffect(() => {
    if (props.selectedInterval === 'Today'){
      props.setMonth('Today', true)
      props.isQuarterlyView && props.switchToTodayQuarterlyView()
    } 
  }, [props.selectedInterval, props.isQuarterlyView])
  
  return (
    <div className={`flex flex-col my-1 md:flex-row md:my-0`}>
      <Listbox
        value={props.selectedInterval}
        onChange={(option) => {
          props.setMonth(option, true)
        }}>
        {({ open }) => (
          <>
            <div className='relative'>
              <Listbox.Button
                className={classNames(
                  (props.isMonthlyIntervals || window.screen.width !< 768) ? 'rounded-md' : 'rounded-l-md',
                  '',
                  'inline-flex justify-center items-center w-full border border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-gray-100 open-sans noOutline'
                )}>
                {props.selectedInterval}
                <span className=''>
                  <ChevronDownIcon className='ml-2 mt-0.5 h-5.5 w-5.5' aria-hidden='true' />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Listbox.Options className='absolute month-options z-50 mt-1 bg-white shadow-lg max-h-96 rounded-md py-1 text-base overflow-auto focus:outline-none' >
                  {intervalOptions.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(
                          active && 'bg-gray-100',
                          'cursor-pointer select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-medium text-green-600' : 'font-normal',
                              'block truncate'
                            )}>
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                'text-green-600 absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <Menu as='div' className='relative inline-block text-left z-10 mr-auto w-full md:w-auto'>
        <div>
          <Menu.Button
            className={classNames(
              props.isMonthlyIntervals ? 'hidden' : 'inline-flex',
              `justify-center items-center w-full border border-l-0 rounded-md mt-1 md:rounded-none md:rounded-r-md md:mt-auto border-gray-300 shadow-sm px-4 py-2.5 bg-white font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2  focus:ring-offset-gray-100 open-sans noOutline`
            )}>
            <CalendarIcon className='mr-3.5 -ml-1 mb-0.25 mt-0.25 h-5.5 w-5.5' aria-hidden='true' />
            {props.selectedDate?.format('MMMM Do')}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items className='origin-top-right fixed md:absolute left-1/2 md:left-0 transform md:transform-none -translate-x-1/2 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <DayPickerSingleDateController
              noBorder={true}
              initialVisibleMonth={() => props.selectedDate}
              date={moment(props.selectedDate).utcOffset(camp.timezone_offset)}
              onDateChange={date => {
                props.setSelectedDate(date)
                props.setMonth(date.format('MMMM'))
              }}
              isOutsideRange={(day: Moment) => false}
              focused={true}
              onFocusChange={(e) => true}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
            />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default ReservationsDatePicker
