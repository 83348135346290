import AddOns from './add_ons'
import Campers from './campers'
import Camps from './camps'
import CampingStyles from './camping_styles'
import Coupons from './coupons'
import DateAvailabilities from './date_availabilities'
import { fetchSite, fetchSites } from './sites/index'
import GroupedReservations from './grouped_reservations'
import Invoices from './invoices'
import Messages from './messages'
import Me from './me'
import Orders from './orders'
import Payments from './payments'
import PaymentIntents from './payment_intents'
import PaymentLinks from './payment_links'
import Products from './products'
import Reservations from './reservations'
import Search from './search'
import Sessions from './sessions'
import Sites from './sites'
import Terminal from './terminal'
import Users from './users'
import PriceDetails from './price_details'
import Reports from './reports'

const api = {
  AddOns,
  Campers,
  Camps,
  CampingStyles,
  Coupons,
  DateAvailabilities,
  fetchSite,
  fetchSites,
  GroupedReservations,
  Invoices,
  Messages,
  Me,
  Orders,
  Payments,
  PaymentIntents,
  PaymentLinks,
  Products,
  Reservations,
  Search,
  Sessions,
  Sites,
  Terminal,
  Users,
  PriceDetails,
  Reports,
}

export default api
