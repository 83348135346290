import React, { useState, useEffect, useContext } from 'react'
import { Button, Card, Container, Divider, Loader, Transition } from 'semantic-ui-react'
import moment, { Moment } from 'moment'
import { CampingStyleProps, CampProps, SiteProps } from '../../interfaces'
import Sites from './Sites'
import Prerequisites from './Prerequisites'
import SelectedSite from './SelectedSite'
import api from '../../api2'
import { validElectric, validRigType } from '../../types'
import { PREREQUISITES } from '../../constants'
import { BookerContext } from '../../contexts/BookerContext'
import { useDebouncedCallback } from 'use-debounce'
import PrerequisiteProps from '../../interfaces/PrerequisiteProps'

interface ComponentProps {
  visible: boolean
  setActiveSite: any // TODO: proper function type
  camp: CampProps
  startDate: Moment | null
  endDate: Moment | null
  setShowDatesCard
  isShowingDatesCard: boolean

  userCampingStyle: CampingStyleProps

  userRigType: validRigType
  setUserRigType: (v: validRigType) => any
  userRigLength: number
  setUserRigLength
  userAmps: validElectric
  setUserAmps
  userPassengers: number
  setUserPassengers: (v: number) => any
  userKidCampers: number
  setUserKidCampers: (v: number) => any
  showPayment: boolean
  groupedPrerequisites: PrerequisiteProps[] | null
  groupId: string | null
  isSkippingSiteSelection: boolean
}

const SitesCard = (props: ComponentProps) => {
  const [sites, setSites] = useState<SiteProps[]>([])
  const [activeSite, setActiveSite] = useState<SiteProps>()
  const [firstAvailableSite, setFirstAvailableSite] = useState<SiteProps>()
  const [loading, setLoading] = useState(true)
  const { userPets } = useContext(BookerContext)

  const {
    startDate,
    endDate,
    groupedReservationId,
    selectedInterval,
    campingStyle,
    selectedSite,
    trackForCamp,
    trackForPark
  } = useContext(BookerContext)

  const nights: number = startDate && endDate && moment(endDate).hours(12).diff(moment(startDate).hours(12), 'days')

  let prereqs = props.groupedPrerequisites || props.userCampingStyle.prerequisites

  if((groupedReservationId || props.groupId)){
    prereqs = prereqs?.filter(prereq => prereq.kind === PREREQUISITES.PETS || prereq.kind === PREREQUISITES.PASSENGERS)
  }

  const prereqsAreFulfilled = () => {
    if (props.groupId?.length && props.groupedPrerequisites?.filter(prereq => prereq.kind === PREREQUISITES.PETS || prereq.kind === PREREQUISITES.PASSENGERS)?.length) return false
    if (prereqs?.length <= 0) return true

    const requiredFields = prereqs.map(pr => pr.kind)
    if (requiredFields.includes('max_rig_length') && !props.userRigLength) return false
    if (requiredFields.includes('electric') && !props.userAmps) return false
    if (requiredFields.includes(PREREQUISITES.PASSENGERS) && !props.userPassengers) return false
    if (requiredFields.includes(PREREQUISITES.PETS) && userPets === null) return false

    return true
  }

  const [showPrerequisitesScreen, setShowPrerequisitesScreen] = useState<boolean>(
    !prereqsAreFulfilled()
  )

  const [transition, setTransition] = useState(false)

  // NOTE: recalculate if we should show screen if the camping style changes
  useEffect(() => {
    setShowPrerequisitesScreen(!prereqsAreFulfilled())
  }, [props.userCampingStyle])

  useEffect(() => {
    setLoading(true)
    const isElectricPrereqRequired = campingStyle.prerequisites.find(prereq => prereq.kind === PREREQUISITES.ELECTRIC)

    if (isElectricPrereqRequired && !props.userAmps && !(groupedReservationId || props.groupId) || props.isShowingDatesCard) return

    debouncedSites()
  }, [
    props.startDate,
    props.endDate,
    props.userRigType,
    props.userRigLength,
    props.userAmps,
    props.userCampingStyle,
    props.userPassengers,
    props.userKidCampers,
    groupedReservationId,
    props.visible,
    props.isShowingDatesCard,
    showPrerequisitesScreen
  ])

  useEffect(() => {
    props.setActiveSite(activeSite)
  }, [activeSite])

  // Used to remove activeSite if the card is turning visible and already has activeSite
  useEffect(() => {
    if (props.visible && activeSite) {
      setActiveSite(null)
    }
  }, [props.visible])

  useEffect(() => {
    if (props.isSkippingSiteSelection && prereqsAreFulfilled() && startDate && endDate && firstAvailableSite) {
      props.setActiveSite(firstAvailableSite)
      props.setShowDatesCard(false)
    }

    setShowPrerequisitesScreen(!prereqsAreFulfilled())
  }, [prereqsAreFulfilled()])

  useEffect(() => {
    if(firstAvailableSite && campingStyle?.name !== firstAvailableSite?.kind){
      setFirstAvailableSite(undefined)
    }
  }, [firstAvailableSite])

  const debouncedSites = useDebouncedCallback(async () => {
    return (startDate && endDate) && api
      .fetchSites(
        props.camp.slug,
        props.startDate?.toISOString(),
        props.endDate?.toISOString(),
        {
          campingStyleId: props.userCampingStyle.id,
          rigType: props.userRigType,
          rigLength: props.userRigLength,
          electric: props.userAmps,
          numberOfAdults: props.userPassengers,
          numberOfKids: props.userKidCampers,
          groupedReservationId,
          intervalId: selectedInterval?.id,
        }
      )
      .then((sites: SiteProps[]) => {
        if (props.isSkippingSiteSelection) {
          const firstAvailableSite = sites.filter((site) => site.is_available)[0]
          if (firstAvailableSite) {
            setFirstAvailableSite(firstAvailableSite)
            if (prereqsAreFulfilled()) {
              setActiveSite(firstAvailableSite)
            }
          } else {
            setSites([])
          }
        } else {
          if (props.visible && !showPrerequisitesScreen) {
            trackForCamp('view_item_list')
            trackForPark('view_item_list')
          }
          setSites(sites)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }, 100)

  useEffect(() => {
    setActiveSite(null)
    setFirstAvailableSite(undefined)
  }, [campingStyle])

  const changeSite = () => {
    if (props.showPayment) {
      trackForCamp('remove_from_cart')
      trackForPark('remove_from_cart')
    }
    setActiveSite(null)
  }

  const cardPageClasses = () => {
    return (props.visible ? 'visible transition' : 'hidden transition') +
      (!activeSite ? ' active' : '')
  }

  const SitesContainer = () => (
    !activeSite ? (
      <Sites
        setActiveSite={setActiveSite}
        camp={props.camp}
        userCampingStyle={props.userCampingStyle}
        nights={nights}
        sites={sites}
        setShowDatesCard={props.setShowDatesCard}
        userRigType={props.userRigType}
        setUserRigType={props.setUserRigType}
        userRigLength={props.userRigLength}
        setUserRigLength={props.setUserRigLength}
        userAmps={props.userAmps}
        setUserAmps={props.setUserAmps}
        loading={loading}
      />
    ) : (
      <SelectedSite site={activeSite} changeSiteCallback={changeSite} />
    )
  )

  if ((activeSite || selectedSite) && prereqsAreFulfilled() && props.isSkippingSiteSelection) {
    return <></>
  }

  return (
    <Card fluid className={cardPageClasses()} id='sitesCard'>
      <Card.Content>
        {
          showPrerequisitesScreen
            ? <Prerequisites
                camp={props.camp}
                campingStyle={props.userCampingStyle}
                prerequisites={prereqs}
                setUserRigType={props.setUserRigType}
                setUserRigLength={props.setUserRigLength}
                setUserAmps={props.setUserAmps}
                setUserPassengers={props.setUserPassengers}
                setUserKidCampers={props.setUserKidCampers}
                setShowPrerequisites={setShowPrerequisitesScreen}
              />
            : <></>
        }
        <Transition visible={!showPrerequisitesScreen} animation='fade right' duration={500}>
          <Container className='transition-container' >
            <SitesContainer />
          </Container>
        </Transition>
      </Card.Content>
    </Card>
  )
}

export default SitesCard
