import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { CampProps } from '../../../interfaces'
import { Transition } from '@headlessui/react'
import backgroundImagePath from '../../../../assets/images/illustration__isaac_lefever__wilderness.png'

interface ErrorBoundaryProps {
  children: React.ReactNode
  camp: CampProps
}

interface AppBoundaryState {
  camp: CampProps
  hasError: boolean
  errorInfo: string | null
}

class AppBoundary extends React.Component<ErrorBoundaryProps, AppBoundaryState> {
  constructor(props: { children: React.ReactNode, camp: CampProps }) {
    super(props)
    this.state = {
      camp: props.camp,
      hasError: false,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorInfo: error.message }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Caught an error:", error, errorInfo)
    Sentry.captureException(error, { contexts: { info: { ...errorInfo } } })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='absolute w-full h-full'>
          <Transition
            show
            appear
            enter="transition-opacity duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <div
              id='404-bg-image'
              className='z-0 absolute h-full w-full bg-gray-800'
              style={{
                backgroundImage: `url(${backgroundImagePath})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
            />
          </Transition>
          <div className='h-full w-full flex justify-center items-center'>
            <div className='z-20 md:mx-0 mx-4 max-w-screen-sm box-border bg-white rounded-xl md:p-16 p-8 gray-900 shadow-xl bg-opacity-90'>
              <div className='text-5xl font-black mb-8'>
                Apologies!
              </div>
              <p className='text-lg'>
                Our online service is currently unavailable. Please wait a moment and reload the page or if the problem persists, contact us by phone to complete your reservation.
              </p>
              <div className='flex mt-4'>
                <button
                  className="mr-4 bg-green-900 hover:bg-green-800 transition-colors duration-100 text-white py-2 px-4 rounded"
                  onClick={() => window.location.reload()}
                >
                  Reload Page
                </button>
                {this.state?.camp?.phone &&
                  <a
                    className="mr-4 bg-green-900 hover:bg-green-800 hover:text-white transition-colors duration-100 text-white py-2 px-4 rounded"
                    href={`tel:${this.state.camp?.phone}`}
                  >
                    Contact us
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default AppBoundary
