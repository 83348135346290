import React, { useState, useEffect, useContext } from 'react'
import { Moment } from 'moment'
import DiscountForm from './DiscountForm'
import { titleCase } from '../../utils'
import { CampProps, CampingStyleProps, SiteProps } from '../../interfaces'
import CampDiscountProps from '../../interfaces/CampDiscountProps'
import pluralize from '../../utils/pluralize'
import { BookerContext } from '../../contexts/BookerContext'
import { PRICE_DURATIONS } from '../../constants/price_durations'
import { useDebouncedCallback } from 'use-debounce/lib'
import priceDetailsFor, { PriceDetails as IPriceDetails } from '../../utils/priceDetailsFor'
import * as Sentry from '@sentry/react'
import Spinner from '../ui/Spinner'
import { Dialog, Transition } from '@headlessui/react'
import { formatMoney } from '../../utils/formatMoney'
import AddOns from '../AddOns/AddOns'
import dynamicRound from '../../utils/dynamicRound'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const discountRatesFor = (camp: CampProps, campingStyle: CampingStyleProps, duration: number): Record<string, CampDiscountProps> => {
  let is_weekly = false
  let is_monthly = false

  if (duration >= 30) {
    is_monthly = true
  } else if(duration >= 7) {
    is_weekly = true
  }

  const campingStyleDiscountRates = campingStyle.discounts?.reduce((obj, discount) => {
    const campingStyleDiscount = campingStyle.camping_style_discounts.filter(discount => discount.is_bookable).find((x) => x.discount_id == discount.id)

    if (campingStyleDiscount?.restricted_durations.includes(PRICE_DURATIONS.WEEKLY) && (is_weekly || (is_monthly && !campingStyleDiscount?.restricted_durations.includes(PRICE_DURATIONS.MONTHLY)))) {
      return obj
    } else if (campingStyleDiscount?.restricted_durations.includes(PRICE_DURATIONS.MONTHLY) && is_monthly) {
      return obj
    }

    if(campingStyleDiscount) obj[discount.slug] = { ...campingStyleDiscount, name: discount.name }
    return obj
  }, {}) || {}

  const campDiscountRates = camp.discounts?.reduce((obj, discount) => {
    const campDiscount = camp.camp_discounts.filter(discount => discount.is_bookable).find((x) => x.discount_id == discount.id)
    if (campDiscount?.restricted_durations.includes(PRICE_DURATIONS.WEEKLY) && (is_weekly || (is_monthly && !campDiscount?.restricted_durations.includes(PRICE_DURATIONS.MONTHLY)))) {
      return obj
    } else if (campDiscount?.restricted_durations.includes(PRICE_DURATIONS.MONTHLY) && is_monthly) {
      return obj
    }
    if(campDiscount) obj[discount.slug] = { ...campDiscount, name: discount.name }
    return obj
  }, {}) || {}

  return { ...campDiscountRates, ...campingStyleDiscountRates }
}

interface ComponentProps {
  camp: CampProps
  selectedSite: SiteProps
  nights: number
  reservationStartDate: Moment
  reservationEndDate: Moment
  discountType
  setDiscountType
  discountId
  setDiscountId
  userPassengers: number
  userKidCampers: number
}

const PriceDetails = (props: ComponentProps) => {
  const {
    campingStyle: bookerCampingStyle,
    selectedAddOns,
    setSelectedAddOns,
    userElectric,
    selectedInterval,
    userPets,
    groupedReservationId,
    isKidsPrereqPresent,
    couponCode,
  } = useContext(BookerContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [priceDetails, setPriceDetails] = useState<IPriceDetails>()

  const selectedSiteCampingStyle = props.camp?.camping_styles?.find(style => style.id === props.selectedSite?.camping_style_id) || bookerCampingStyle
  const campingStyle = groupedReservationId ? selectedSiteCampingStyle : bookerCampingStyle

  const fetchPriceDetails = useDebouncedCallback(() => {
    const hasRequiredProps =
      props.camp &&
      campingStyle &&
      props.selectedSite &&
      props.reservationStartDate &&
      props.reservationEndDate

    if (!hasRequiredProps) return

    priceDetailsFor({
      kind: null, // NOTE: is not "recurring", so null. Same as "occasional"
      camp: props.camp,
      campingStyle: campingStyle,
      site: props.selectedSite,
      startDate: props.reservationStartDate,
      endDate: props.reservationEndDate,
      discountSlug: props.discountType,
      couponCodes: [couponCode],
      isTaxableEnabled: true,
      isHolidayEnabled: true,
      isWeekendEnabled: true,
      isServiceFeeApplied: true,
      numberOfAdults: props.userPassengers,
      numberOfKids: props.userKidCampers,
      numberOfPets: userPets,
      userElectric: userElectric,
      addOns: selectedAddOns,
      intervalId: selectedInterval?.id,
    })
      .then((pd: IPriceDetails) => {
        setPriceDetails(pd)
        setDiscountRates(discountRatesFor(props.camp, campingStyle, pd.nights))
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureException(err)
        setIsLoading(false)
        setErrorMessage('Something went wrong. Please reload the page')
      })
  }, 150)

  const [discountValid, setDiscountValid] = useState(false)
  const [discountRates, setDiscountRates] = useState(discountRatesFor(
    props.camp,
    campingStyle,
    props.nights
  ))

  const canUseDiscount = (camp: CampProps, nights: number) => {
    if (camp.max_discounted_nights) {
      return nights <= camp.max_discounted_nights
    } else {
      return true
    }
  }

  useEffect(() => {
    fetchPriceDetails()
  }, [
    props.selectedSite,
    props.reservationStartDate,
    props.reservationEndDate,
    selectedAddOns,
  ])

  useEffect(() => {
    let possibleDiscount: CampDiscountProps = discountRates[props.discountType]
    const requiresDiscountId: boolean = possibleDiscount?.require_membership_id || false

    if (requiresDiscountId) {
      if (props.discountId?.length > 6) {
        setIsLoading(true)
        fetchPriceDetails()
        setDiscountValid(true)
      } else {

        setDiscountValid(false)
      }
    } else {
      if (props.discountType) {
        setIsLoading(true)
        fetchPriceDetails()
        setDiscountValid(true)
      } else {

        setIsLoading(true)
        fetchPriceDetails()
        setDiscountValid(false)
      }
    }
  }, [priceDetails?.subtotal, props.discountType, props.discountId, couponCode])

  useEffect(() => {
    setSelectedAddOns([])
  }, [props.selectedSite])

  // TODO: format this better...
  if (!priceDetails) {
    return <>Loading...</>
  }

  return (
    <div className='relative space-y-6'>
      <Transition
        show={isLoading}
        enter='transition-opacity ease-out duration-75'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity ease-in duration-75'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'>
        <div className='absolute w-full h-full z-10 bg-white bg-opacity-80 flex items-center justify-center text-sm'>
          <Spinner size={5} />
          Loading...
        </div>
      </Transition>
      <div className=''>
        {priceDetails.flat_rate_price ? (
          <div className='mb-2 w-full flex justify-between'>
            <div>Flat rate</div>
            <div className='text-right'>{formatMoney(priceDetails.flat_rate_price)}</div>
          </div>
        ) : (
          priceDetails.nightly_price != 0 && (
            <div className='mb-2 w-full flex justify-between'>
              <div className=''>
                {formatMoney(priceDetails.nightly_price)}
                {priceDetails.frequency_label &&
                  ` x ${priceDetails.nights} ${pluralize(priceDetails.frequency_label, priceDetails.nights)}`
                }
              </div>
              <div className='text-right'>
                {priceDetails.discounted_subtotal ? (
                  <>
                    <strong style={{ marginRight: '1rem' }}>
                      {formatMoney(priceDetails.discounted_subtotal)}
                    </strong>
                    <em>
                      <s>{formatMoney(priceDetails.subtotal)}</s>
                    </em>
                  </>
                ) : (
                  <strong>{formatMoney(priceDetails.subtotal)}</strong>
                )}
              </div>
            </div>
          )
        )}
        <TaxesAndFees priceDetails={priceDetails} camp={props.camp} campingStyle={campingStyle} />
        <div className='mt-4 pt-4 border-t border-gray-300 flex justify-between w-full'>
          <div className='w-full flex justify-between font-bold'>
            <div className=''>Total</div>
            <div className='text-right'>{formatMoney(priceDetails.total)}</div>
          </div>
        </div>
      </div>
      <AddOns
        campingStyle={selectedSiteCampingStyle}
        primaryColor={props.camp.primary_color}
        startDate={props.reservationStartDate}
        endDate={props.reservationEndDate}
        selectedSiteId={props.selectedSite.id}
        groupedReservationId={groupedReservationId}
      />
      {canUseDiscount(props.camp, props.nights) &&
        (Object.keys(discountRates || {}).length > 0) &&
        !priceDetails.flat_rate_price && (
          <DiscountForm
            camp={props.camp}
            campingStyle={campingStyle}
            discountRates={discountRates}
            discountType={props.discountType}
            setDiscountType={props.setDiscountType}
            discountId={props.discountId}
            setDiscountId={props.setDiscountId}
            discountValid={discountValid}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
    </div>
  )
}

interface ITaxesAndFeesProps{
  priceDetails: IPriceDetails
  camp: CampProps
  campingStyle: CampingStyleProps
}

const TaxesAndFees = ({priceDetails, camp, campingStyle}: ITaxesAndFeesProps) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const { isKidsPrereqPresent } = useContext(BookerContext)

  if( !camp.is_bundling_fees_for_guest || (!priceDetails.tax_rate && !priceDetails.credit_card_rate)) {
    return (
      <>
        {!!priceDetails.holiday_nights && priceDetails.holiday_nightly_fee ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>
              {`Holiday surcharge ${formatMoney(priceDetails.holiday_nightly_fee)} x ${
                priceDetails.holiday_nights
              } ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
            </div>
            <div className='text-right'>
              {priceDetails.discounted_holiday_total_fee ? (
                <>
                  <strong style={{ marginRight: '1rem' }}>
                    {formatMoney(priceDetails.discounted_holiday_total_fee)}
                  </strong>
                  <em>
                    <s>{formatMoney(priceDetails.holiday_total_fee)}</s>
                  </em>
                </>
              ) : (
                <strong>{formatMoney(priceDetails.holiday_total_fee)}</strong>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {!!priceDetails.weekend_nights && priceDetails.weekend_nightly_fee ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>
              {`Weekend surcharge ${formatMoney(priceDetails.weekend_nightly_fee)} x ${
                priceDetails.weekend_nights
              } ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
            </div>
            <div className='text-right'>
              {priceDetails.discounted_weekend_total_fee ? (
                <>
                  <strong style={{ marginRight: '1rem' }}>
                    {formatMoney(priceDetails.discounted_weekend_total_fee)}
                  </strong>
                  <em>
                    <s>{formatMoney(priceDetails.weekend_total_fee)}</s>
                  </em>
                </>
              ) : (
                <strong>{formatMoney(priceDetails.weekend_total_fee)}</strong>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {(priceDetails.additional_fees?.length ||
          priceDetails.adults_fee > 0 ||
          priceDetails.kids_fee > 0 ||
          priceDetails.pets_fee > 0) && (
          <div className='grid grid-cols-3 w-full'>
            {priceDetails.additional_fees
              ?.filter((fee) => fee.quantity)
              ?.map((fee) => {
                const label = fee.type === 'add-on' ? fee.label : titleCase(fee.slug)
                return fee.is_applied_nightly ? (
                  <>
                    <div className='col-span-2 mb-2'>
                      {`${formatMoney(fee.unit_price)} `}
                      {label}{' '}
                      {fee.quantity > 1 &&
                        `x ${fee.quantity / priceDetails.nights} x
                    ${priceDetails.nights} ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
                    </div>
                    <div className='col-span-1 text-right'>{formatMoney(fee.total_price)}</div>
                  </>
                ) : (
                  <>
                    <div className='col-span-2 mb-2'>
                      {label} {fee.quantity > 1 && `x ${fee.quantity}`}
                    </div>
                    <div className='col-span-1 text-right'>{formatMoney(fee.total_price)}</div>
                  </>
                )
              })}
            {priceDetails.adults_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_adults} extra{' '}
                  {pluralize(isKidsPrereqPresent ? 'adult' :'guest', priceDetails.additional_adults)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.adults_fee)}</div>
              </>
            )}
            {priceDetails.kids_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_kids} extra{' '}
                  {pluralize('kid', priceDetails.additional_kids)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'nights', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.kids_fee)}</div>
              </>
            )}
            {priceDetails.pets_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_pets} extra{' '}
                  {pluralize('pet', priceDetails.additional_pets)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'nights', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.pets_fee)}</div>
              </>
            )}
          </div>
        )}
        {priceDetails.tax_rate > 0 ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>{campingStyle?.tax_rate_label || 'Taxes Collected'} ({dynamicRound(priceDetails.tax_rate * 100)}%)</div>
            <div className='text-right'>{formatMoney(priceDetails.tax)}</div>
          </div>
        ) : (
          <></>
        )}
        {priceDetails.credit_card_rate > 0 ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>Credit Card Fees ({dynamicRound(priceDetails.credit_card_rate * 100)}%)</div>
            <div className='text-right'>{formatMoney(priceDetails.credit_card_fee)}</div>
          </div>
        ) : (
          <></>
        )}
        {!camp.is_absorbing_service_fee && (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>Service Fee</div>
            <div className='text-right'>{formatMoney(priceDetails.service_fee)}</div>
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        {!!priceDetails.holiday_nights && priceDetails.holiday_nightly_fee ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>
              {`Holiday surcharge ${formatMoney(priceDetails.holiday_nightly_fee)} x ${
                priceDetails.holiday_nights
              } ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
            </div>
            <div className='text-right'>
              {priceDetails.discounted_holiday_total_fee ? (
                <>
                  <strong style={{ marginRight: '1rem' }}>
                    {formatMoney(priceDetails.discounted_holiday_total_fee)}
                  </strong>
                  <em>
                    <s>{formatMoney(priceDetails.holiday_total_fee)}</s>
                  </em>
                </>
              ) : (
                <strong>{formatMoney(priceDetails.holiday_total_fee)}</strong>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {!!priceDetails.weekend_nights && priceDetails.weekend_nightly_fee ? (
          <div className='mb-2 w-full flex justify-between'>
            <div className=''>
              {`Weekend surcharge ${formatMoney(priceDetails.weekend_nightly_fee)} x ${
                priceDetails.weekend_nights
              } ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
            </div>
            <div className='text-right'>
              {priceDetails.discounted_weekend_total_fee ? (
                <>
                  <strong style={{ marginRight: '1rem' }}>
                    {formatMoney(priceDetails.discounted_weekend_total_fee)}
                  </strong>
                  <em>
                    <s>{formatMoney(priceDetails.weekend_total_fee)}</s>
                  </em>
                </>
              ) : (
                <strong>{formatMoney(priceDetails.weekend_total_fee)}</strong>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {(priceDetails.additional_fees?.length ||
          priceDetails.adults_fee > 0 ||
          priceDetails.kids_fee > 0 ||
          priceDetails.pets_fee > 0) && (
          <div className='grid grid-cols-3 w-full'>
            {priceDetails.additional_fees
              ?.filter((fee) => fee.quantity)
              ?.map((fee) => {
                const label = fee.type === 'add-on' || fee.type === 'camping-style' ? fee.label : titleCase(fee.slug)
                return fee.is_applied_nightly ? (
                  <>
                    <div className='col-span-2 mb-2'>
                      {`${formatMoney(fee.unit_price)} `}
                      {label}{' '}
                      {fee.quantity > 1 &&
                        `x ${fee.quantity / priceDetails.nights} x
                    ${priceDetails.nights} ${pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}`}
                    </div>
                    <div className='col-span-1 text-right'>
                      <strong>{formatMoney(fee.total_price)}</strong>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-span-2 mb-2'>
                      {label} {fee.quantity > 1 && `x ${fee.quantity}`}
                    </div>
                    <div className='col-span-1 text-right'>
                      <strong>{formatMoney(fee.total_price)}</strong>
                    </div>
                  </>
                )
              })}
            {priceDetails.adults_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_adults} extra{' '}
                  {pluralize('guest', priceDetails.additional_adults)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'night', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.adults_fee)}</div>
              </>
            )}
            {priceDetails.kids_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_kids} extra{' '}
                  {pluralize('kid', priceDetails.additional_kids)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'nights', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.kids_fee)}</div>
              </>
            )}
            {priceDetails.pets_fee > 0 && (
              <>
                <div className='col-span-2 mb-2'>
                  {priceDetails.additional_pets} extra{' '}
                  {pluralize('pet', priceDetails.additional_pets)} fee x {priceDetails.nights}{' '}
                  {pluralize(priceDetails.frequency_label || 'nights', priceDetails.nights)}
                </div>
                <div className='col-span-1 text-right'>{formatMoney(priceDetails.pets_fee)}</div>
              </>
            )}
          </div>
        )}
        <div>
          <div className="mb-2 w-full flex justify-between">
            <div className="flex items-start">
              {priceDetails.tax_rate > 0 ? 'Taxes & Fees' : 'Fees'}
              <div className="relative">
                <InformationCircleIcon
                  className="w-3.5 ml-0.5 cursor-pointer"
                  onMouseEnter={() => setIsInfoOpen(true)}
                  onMouseLeave={() => setIsInfoOpen(false)}
                />
                <Transition
                  className="price-details-info bg-white rounded-md absolute"
                  show={isInfoOpen}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="wrapper text-sm whitespace-nowrap py-4 px-2 flex flex-col gap-y-2">
                    {camp.slug === 'weathervane-cottages' && priceDetails.tax_rate == 0.08 ? (
                      <>
                        <div className='w-full flex'>
                          <div>{campingStyle.tax_rate_label.split('+')[0].trim()} (7%):</div>
                          &nbsp;
                          <div className='text-right'>{formatMoney(priceDetails.subtotal * 0.07)}</div>
                        </div>
                        <div className='w-full flex'>
                          <div>{campingStyle.tax_rate_label.split('+')[1].trim()} (1%):</div>
                          &nbsp;
                          <div className='text-right'>{formatMoney(priceDetails.subtotal * 0.01)}</div>
                        </div>
                      </>
                    ) : priceDetails.tax_rate > 0 ? (
                      <div className='w-full flex'>
                        <div>
                          {campingStyle.tax_rate_label || 'Taxes Collected'} ({dynamicRound(priceDetails.tax_rate * 100)}%):
                        </div>
                        &nbsp;
                        <div className='text-right'>{formatMoney(priceDetails.tax)}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {priceDetails.credit_card_rate > 0 ? (
                      <div className="w-full flex">
                        <div>
                          Credit Card Fees (
                          {dynamicRound(priceDetails.credit_card_rate * 100)}%):
                        </div>
                        <div className="text-right">
                          &nbsp;{formatMoney(priceDetails.credit_card_fee)}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {!camp.is_absorbing_service_fee && (
                      <div className="w-full flex">
                        <div>Service Fee: &nbsp;</div>
                        <div className="text-right">
                          {formatMoney(priceDetails.service_fee)}
                        </div>
                      </div>
                    )}
                  </div>
                </Transition>
                <div></div>
              </div>
            </div>
            <div className="text-right">
              <strong>
                {formatMoney(
                  camp.is_absorbing_service_fee ? (
                    dynamicRound((priceDetails.tax || 0) + (priceDetails.credit_card_fee || 0))
                  ) : (
                    dynamicRound(
                      (priceDetails.tax || 0) + (priceDetails.credit_card_fee || 0) + (priceDetails.service_fee || 0)
                    )
                  )
                )}
              </strong>
            </div>
          </div>
          <div></div>
        </div>
      </>
    )
  }
}

export default PriceDetails
