import { DateTime } from "luxon"
import eSendingMode from "../enums/eSendingMode"
import { CampProps, CampingStyleProps, SiteProps } from "../interfaces"
import CampDiscountProps from "../interfaces/CampDiscountProps"
import DiscountProps from "../interfaces/DiscountProps"
import PrerequisiteProps from "../interfaces/PrerequisiteProps"
import { pricingMode } from "../types/pricingMode"

class CampDecorator implements CampProps {
  object: CampProps
  is_published: boolean
  id: string
  slug: string
  name: string
  created_at: Date
  updated_at: Date
  bio: string
  map_url: string
  logo_url: string
  website: string
  address: string
  city: string
  state: string
  zip: string
  country: string
  timezone: string
  timezone_offset: string
  phone: string
  email: string
  rep_name: string
  rep_phone: string
  rep_email: string
  pos_service_rate: number
  tax_rate: number
  holiday_fee_in_cents: number
  weekend_fee_in_cents: number
  currency: string
  booking_sending_mode?: eSendingMode
  rules: string
  camping_styles: CampingStyleProps[]
  prerequisites: PrerequisiteProps[]
  discounts: DiscountProps[]
  camp_discounts: CampDiscountProps[]
  requires_license_plate: boolean
  minimum_holiday_weekend_nights: number
  minimum_weekend_nights: number
  max_stay_nights: number
  max_stay_nights_message: string
  season_start_date: string // ISO DateTime string
  season_end_date: string // ISO DateTime string
  booking_start_date: string // ISO DateTime string
  season_start_mm_dd: string
  season_end_mm_dd: string
  booking_start_mm_dd: string
  bookable_for_n_days: number
  max_discounted_nights: number
  stripe_account_id: string
  stripe_reader_id?: string
  sites: SiteProps[]
  allow_same_day_reservations: boolean
  same_day_reservations_cutoff_time: string
  electricity_rate: number
  primary_color: string
  secondary_color: string
  header_color: string
  is_camper_length: boolean
  is_zoom_enabled: boolean
  is_enabled_auto_invoices: boolean
  invoice_due_days: number
  is_absorbing_service_fee: boolean
  is_bundling_fees_for_guest: boolean
  google_analytics_id: string
  google_ad_conversion_id: string
  date_format: string
  price_mode: pricingMode
  default_invoice_memo: string
  wifi_network: string
  wifi_password: string
  bathroom_code: string

  constructor(private camp: CampProps) {
    Object.assign(this, camp)
  }

  get bookingStartDatetime() {
    if (!this.camp.booking_start_mm_dd) return null

    let dt = DateTime.fromFormat(this.camp.booking_start_mm_dd, 'LL-dd HH:mm:ss', { zone: this.camp.timezone })
    if (!dt || !dt.isValid) {
      dt = DateTime.fromFormat(this.camp.booking_start_mm_dd, 'LL-dd', { zone: this.camp.timezone })
    }

    if (!dt || !dt.isValid) return null

    return dt
  }

  get seasonStartDatetime() {
    if (!this.camp.season_start_mm_dd) return null
    return DateTime.fromFormat(this.camp.season_start_mm_dd, 'LL-dd', { zone: this.camp.timezone })
  }

  get seasonEndDatetime() {
    if (!this.camp.season_end_mm_dd) return null
    return DateTime.fromFormat(this.camp.season_end_mm_dd, 'LL-dd', { zone: this.camp.timezone })
  }

  get isBookable() {
    if (!this.bookingStartDatetime) return true

    const isTodayAfterBookingStart = this.bookingStartDatetime.diffNow().milliseconds < 0
    const isTodayBeforeSeasonEnd = this.seasonEndDatetime.diffNow().milliseconds > 0
    return isTodayAfterBookingStart && isTodayBeforeSeasonEnd
  }
}

export default CampDecorator
