import React, { useContext, useEffect, useState } from 'react'
import { CAMPING_STYLES, ELECTRICS, KINDS } from '../../../constants'
import { PRICE_DURATIONS } from '../../../constants/price_durations'
import { CampProps, PriceProps, SiteProps } from '../../../interfaces'
import { validCampingStyles, validDurations, validElectric, validKinds } from '../../../types'
import { titleCase } from '../../../utils'
import classNames from '../../../utils/classNames'
import HoverTip from '../../ui/HoverTip'
import SelectInput, {Option} from '../../ui/SelectInput'
import Toggle from '../../ui/Toggle'
import CalendarSyncInputs from './EditSiteForm/CalendarSyncInputs'
import { AdminContext } from '../../../contexts/AdminContext'
import SitePhotoUpload from '../SitePhotoUpload'
import RichTextInput from '../../ui/RichTextInput'
import NumberInput from '../../ui/NumberInput'
import InputFieldWithCurrency from '../Inputs/InputFieldWithCurrency'
import Button from '../../ui/Button'
import VendorCalendarModalContent, { ExternalCalendarUrlInput } from '../VendorCalendars/VendorCalendarModalContent'
import IVendorCalendar from '../../../interfaces/IVendorCalendar'

const campingStyleOptions = [
  { label: 'RV', value: CAMPING_STYLES.RV },
  { label: 'Seasonal RV', value: CAMPING_STYLES.RV },
  { label: 'Cabin', value: CAMPING_STYLES.CABIN },
  { label: 'Tent', value: CAMPING_STYLES.TENT },
  { label: 'Boat Slip', value: CAMPING_STYLES.BOAT_SLIP },
  { label: 'Boat Rental', value: CAMPING_STYLES.BOAT_RENTAL },
  { label: 'Mobile Home', value: CAMPING_STYLES.MOBILE_HOME },
  { label: 'Storage', value: CAMPING_STYLES.STORAGE },
  { label: 'Pavilion', value: CAMPING_STYLES.PAVILION },
  { label: 'Cabana', value: CAMPING_STYLES.CABANA },
  { label: 'Event Center', value: CAMPING_STYLES.EVENT_CENTER },
  { label: 'Garage', value: CAMPING_STYLES.GARAGE },
  { label: 'Horse Pen', value: CAMPING_STYLES.HORSE_PEN },
  { label: 'Horse Stall', value: CAMPING_STYLES.HORSE_STALL },
  { label: 'RV Storage', value: CAMPING_STYLES.RV_STORAGE },
  { label: 'Rental RV', value: CAMPING_STYLES.RENTAL_RV },
  { label: 'Lodge', value: CAMPING_STYLES.LODGE },
  { label: 'Golf Cart', value: CAMPING_STYLES.GOLF_CART },
  { label: 'Pool', value: CAMPING_STYLES.POOL },
  { label: 'Shelter', value: CAMPING_STYLES.SHELTER },
  { label: 'Vendor Booth', value: CAMPING_STYLES.VENDOR_BOOTH },
  { label: 'Disc Golf', value: CAMPING_STYLES.DISC_GOLF },
  { label: 'Dry Camping', value: CAMPING_STYLES.DRY_CAMPING },
  { label: 'Dome', value: CAMPING_STYLES.DOME },
  { label: 'Glamping', value: CAMPING_STYLES.GLAMPING },
  { label: 'Home', value: CAMPING_STYLES.HOME },
  { label: 'Lodging', value: CAMPING_STYLES.LODGING },
  { label: 'Bonfire', value: CAMPING_STYLES.BONFIRE },
  { label: 'Truck', value: CAMPING_STYLES.TRUCK },
  { label: 'Croup', value: CAMPING_STYLES.GROUP },
  { label: 'Cottage', value: CAMPING_STYLES.COTTAGE },
  { label: 'Main House', value: CAMPING_STYLES.MAIN_HOUSE },
  { label: 'Food Truck', value: CAMPING_STYLES.FOOD_TRUCK },
  { label: 'Miscellaneous', value: CAMPING_STYLES.MISC },
  { label: 'Ensuite Unit', value: CAMPING_STYLES.ENSUITE_UNIT },
  { label: 'Unpowered Site', value: CAMPING_STYLES.UNPOWERED_SITE },
  { label: 'Tipi', value: CAMPING_STYLES.TIPI },
  { label: 'Kayak', value: CAMPING_STYLES.KAYAK },
  { label: 'Van', value: CAMPING_STYLES.VAN },
  { label: 'Room', value: CAMPING_STYLES.ROOM },
  { label: 'Rental Item', value: CAMPING_STYLES.RENTAL_ITEM },
  { label: 'Generator', value: CAMPING_STYLES.GENERATOR },
  { label: 'Picnic Area', value: CAMPING_STYLES.PICNIC_AREA },
  { label: 'Kitchen', value: CAMPING_STYLES.KITCHEN },
  { label: 'Platform', value: CAMPING_STYLES.PLATFORM },
  { label: 'Canvas Tent', value: CAMPING_STYLES.CANVAS_TENT },
]

const kindOptions = [
  { label: 'Back-in', value: KINDS.RV_BACKIN },
  { label: 'Pull-through', value: KINDS.RV_PULLTHROUGH },
  { label: 'Hybrid', value: KINDS.RV_HYBRID },
  // NOTE: these are automatically set when changing camping style. Legacy reasons.
  { label: 'Tent', value: KINDS.TENT, hidden: true },
  { label: 'Cabin', value: KINDS.CABIN, hidden: true },
  { label: 'Boat Slip', value: KINDS.BOAT_SLIP, hidden: true },
  { label: 'Boat Rental', value: KINDS.BOAT_RENTAL, hidden: true },
  { label: 'Mobile Home', value: KINDS.MOBILE_HOME, hidden: true },
  { label: 'Storage', value: KINDS.STORAGE, hidden: true },
  { label: 'Pavilion', value: KINDS.PAVILION, hidden: true },
  { label: 'Cabana', value: KINDS.CABANA, hidden: true },
  { label: 'Event Center', value: KINDS.EVENT_CENTER, hidden: true },
  { label: 'Garage', value: KINDS.GARAGE, hidden: true },
  { label: 'Horse Pen', value: KINDS.HORSE_PEN, hidden: true },
  { label: 'Horse Stall', value: KINDS.HORSE_STALL, hidden: true },
  { label: 'RV Storage', value: KINDS.RV_STORAGE, hidden: true },
  { label: 'Rental RV', value: KINDS.RENTAL_RV, hidden: true },
  { label: 'Lodge', value: KINDS.LODGE, hidden: true },
  { label: 'Golf Cart', value: KINDS.GOLF_CART, hidden: true },
  { label: 'Pool', value: KINDS.POOL, hidden: true },
  { label: 'Shelter', value: KINDS.SHELTER, hidden: true },
  { label: 'Vendor Booth', value: KINDS.VENDOR_BOOTH, hidden: true },
  { label: 'Disc Golf', value: KINDS.DISC_GOLF, hidden: true },
  { label: 'Dry Camping', value: KINDS.DRY_CAMPING, hidden: true },
  { label: 'Dome', value: KINDS.DOME, hidden: true },
  { label: 'Glamping', value: KINDS.GLAMPING, hidden: true },
  { label: 'Home', value: KINDS.HOME, hidden: true },
  { label: 'Lodging', value: KINDS.LODGING, hidden: true },
  { label: 'Bonfire', value: KINDS.BONFIRE, hidden: true },
  { label: 'Truck', value: CAMPING_STYLES.TRUCK, hidden: true },
  { label: 'Croup', value: CAMPING_STYLES.GROUP, hidden: true },
  { label: 'Cottage', value: CAMPING_STYLES.COTTAGE, hidden: true },
  { label: 'Main House', value: CAMPING_STYLES.MAIN_HOUSE, hidden: true },
  { label: 'Tipi', value: CAMPING_STYLES.TIPI, hidden: true },
  { label: 'Kayak', value: CAMPING_STYLES.KAYAK, hidden: true },
  { label: 'Van', value: CAMPING_STYLES.VAN, hidden: true },
  { label: 'Room', value: CAMPING_STYLES.ROOM, hidden: true },
  { label: 'Rental Item', value: CAMPING_STYLES.RENTAL_ITEM, hidden: true },
  { label: 'Generator', value: CAMPING_STYLES.GENERATOR, hidden: true },
  { label: 'Picnic Area', value: CAMPING_STYLES.PICNIC_AREA, hidden: true },
  { label: 'Kitchen', value: CAMPING_STYLES.KITCHEN, hidden: true },
  { label: 'Platform', value: CAMPING_STYLES.PLATFORM, hidden: true },
  { label: 'Canvas Tent', value: CAMPING_STYLES.CANVAS_TENT, hidden: true },
]

const electricOptions = [
  { label: "Don't show electric", value: null },
  { label: "Electric (use where amperages don't matter)", value: ELECTRICS.BASIC },
  { label: 'No electric', value: ELECTRICS.NONE },
  ...Object.values(ELECTRICS)
    .filter((e) => e && e !== 'basic' && e !== 'none')
    .map((e) => ({ label: titleCase(e) + ' amp', value: e })),
]

const generatePriceTypesFor = (
  sites: SiteProps[]
): { label: string; duration: validDurations }[] => {
  let priceTypes = [
    { label: 'Nightly rate', duration: PRICE_DURATIONS.NIGHTLY },
    null,
    { label: 'Weekly rate', duration: PRICE_DURATIONS.WEEKLY },
    { label: 'Monthly rate', duration: PRICE_DURATIONS.MONTHLY },
    null,
  ] as { label: string; duration: validDurations }[]

  if (sites.flatMap((s) => s.prices).some((p) => p.duration === PRICE_DURATIONS.NIGHTLY_2_PLUS)) {
    priceTypes[1] = { label: 'Nightly rate (2+ nights)', duration: PRICE_DURATIONS.NIGHTLY_2_PLUS }
  }

  if (sites.flatMap((s) => s.prices).some((p) => p.duration === PRICE_DURATIONS.FLAT_RATE)) {
    priceTypes[4] = { label: 'Flat rate', duration: PRICE_DURATIONS.FLAT_RATE }
  }

  return priceTypes.filter((p) => p)
}

const kindToStyle = (kind: validKinds): validCampingStyles => {
  if (!kind) return

  const rvKinds: validKinds[] = [KINDS.RV_BACKIN, KINDS.RV_PULLTHROUGH, KINDS.RV_HYBRID]

  if (rvKinds.includes(kind)) {
    return CAMPING_STYLES.RV
  } else {
    return kind as validCampingStyles
  }
}

interface ComponentProps {
  site?: SiteProps
  camp?: CampProps
  isProcessing: boolean
  errorMessage: string
  setErrorMessage: (message: string) => void
  setIsSiteSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setIsSlideoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EditSiteForm = (props: ComponentProps) => {
  const { sites, isParkAdminUser, showModal } = useContext(AdminContext)
  const [id, setId] = useState(props.site?.id)
  const [name, setName] = useState(props.site?.name)
  const [isBookable, setIsBookable] = useState(props.site?.is_bookable)
  const [prices, setPrices] = useState<Partial<PriceProps & { isActive: boolean }>[]>(
    props.site?.prices
  )
  const [numberOfActivePrices, setNumberOfActivePrices] = useState(
    prices.filter((p) => (!!p && !!p.id) || (!!p && p['isActive'])).length
  )
  const [priceTypes, setPriceTypes] = useState<{ label: string; duration: validDurations }[]>(
    generatePriceTypesFor(sites)
  )
  const [selectedCampingStyle, setSelectedCampingStyle] = useState<validCampingStyles>(
    kindToStyle(props.site?.kind as validKinds)
  )
  const [selectedKind, setSelectedKind] = useState<validKinds>(props.site?.kind as validKinds)
  const [selectedElectric, setSelectedElectric] = useState<validElectric>(
    props.site?.electric as validElectric
  )
  const [maxRigLength, setMaxRigLength] = useState<string>(String(props.site?.max_rig_length))
  const [camperCapacity, setCamperCapacity] = useState<string>(String(props.site?.camper_capacity))
  const [minimumCapacity, setMinimumCapacity] = useState<string>(String(props.site?.minimum_capacity))
  const [currentMeterReading, setCurrentMeterReading] = useState<string>(
    String(props.site?.last_meter_reading)
  )
  const [notes, setNotes] = useState<string>(String(props.site?.notes || ''))
  const [checkinPolicyAdditions, setCheckinPolicyAdditions] = useState<string>(
    props.site?.checkin_policy_additions
  )
  const rigLengthStyles: validCampingStyles[] = [CAMPING_STYLES.RV, CAMPING_STYLES.BOAT_SLIP]
  const CAPACITY_STYLES: validCampingStyles[] = [
    CAMPING_STYLES.CABIN,
    CAMPING_STYLES.RENTAL_RV,
    CAMPING_STYLES.MOBILE_HOME,
    CAMPING_STYLES.HOME,
    CAMPING_STYLES.LODGING,
    CAMPING_STYLES.LODGE,
    CAMPING_STYLES.GLAMPING,
    CAMPING_STYLES.DOME,
  ]
  const [water, setWater] = useState<boolean>(props.site.water || false)
  const [sewage, setSewage] = useState<boolean>(props.site.sewage || false)

  // TODO: useAdminForm hook w/ resetDefaults() function
  useEffect(() => {
    if (!props.site) return

    setId(props.site?.id)
    setName(props.site?.name)
    setIsBookable(props.site?.is_bookable)
    setPrices(props.site?.prices)
    setNumberOfActivePrices(
      props.site?.prices?.filter((p) => (!!p && !!p.id) || (!!p && p['isActive'])).length
    )
    setSelectedCampingStyle(kindToStyle(props.site?.kind as validKinds))
    setSelectedKind(props.site?.kind as validKinds)
    setSelectedElectric(props.site?.electric as validElectric)
    setMaxRigLength(String(props.site?.max_rig_length))
    setCamperCapacity(String(props.site?.camper_capacity))
    setMinimumCapacity(String(props.site?.minimum_capacity))
    setCurrentMeterReading(String(props.site?.last_meter_reading))
    setNotes(String(props.site?.notes || ''))
    setCheckinPolicyAdditions(props.site?.checkin_policy_additions)
    setWater(Boolean(props.site.water))
    setSewage(Boolean(props.site.sewage))
  }, [props.site])

  useEffect(() => {
    setPriceTypes(generatePriceTypesFor(sites))
  }, [sites])

  const handleAddVendorCalendar = (e) => {
    props.setIsSlideoverOpen(false)
    e.preventDefault()

    showModal(
      <VendorCalendarModalContent site={props.site} vendorCalendar={null} />,
      false,
      "",
      {
        closeCallback: () => {
          
          props.setIsSlideoverOpen(true)
        },
      }
    )
  }

  const handleEditVendorCalendar = (vendorCalendar: IVendorCalendar) => {
    props.setIsSlideoverOpen(false)

    showModal(
      <VendorCalendarModalContent site={props.site} vendorCalendar={vendorCalendar} />,
      false,
      "",
      {
        closeCallback: () => {
          props.setIsSlideoverOpen(true)
        },
      }
    )
  }

  return (
    <div className='space-y-3.5 pt-0 pb-5'>
      <input hidden readOnly type='text' defaultValue={id} name='id' id='id' className='hidden' />

      <div>
        <Toggle
          disabled={props.isProcessing}
          checked={isBookable}
          onChange={setIsBookable}
          name='isBookable'
          label='Bookable'
          description='When disabled, this site will not be bookable online.'
        />
      </div>

      <div>
        <label className='block text-md text-gray-900'>Site name </label>
        <div className='mt-1'>
          <input
            type='text'
            value={name}
            disabled={props.isProcessing}
            onChange={(e) => setName(e.currentTarget.value)}
            name='name'
            id='name'
            className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
          />
        </div>
      </div>

      <div className='flex'>
        <div className='flex-1'>
          <label className='block text-md text-gray-900'>Site Type </label>
          <div className='mt-1'>
            <SelectInput
              name='camping_style'
              options={campingStyleOptions}
              selected={campingStyleOptions.find((o) => o.value === selectedCampingStyle)}
              setSelected={(o: Option) => setSelectedCampingStyle(o.value)}
              disabled
            />
          </div>
        </div>

        {selectedCampingStyle === CAMPING_STYLES.RV && (
          <div className={classNames('ml-4 flex-1', selectedCampingStyle === CAMPING_STYLES.RV ? 'visible' : 'hidden')}>
            <label className='block text-md text-gray-900'>Direction </label>
            <div className='mt-1'>
              <SelectInput
                name='kind'
                options={kindOptions}
                selected={kindOptions.find((o) => o.value === selectedKind)}
                setSelected={(o: Option) => setSelectedKind(o.value)}
              />
            </div>
          </div>
        )}
      </div>

      {rigLengthStyles.includes(selectedCampingStyle) && (
        <div>
          <label className='block text-md text-gray-900'>
            Max {selectedCampingStyle === CAMPING_STYLES.BOAT_SLIP ? 'boat' : 'rig'} length (ft.){' '}
          </label>
          <div className='mt-1'>
            <input
              type='number'
              step='1'
              value={maxRigLength}
              disabled={props.isProcessing}
              onChange={(e) => setMaxRigLength(e.currentTarget.value)}
              name='maxRigLength'
              id='maxRigLength'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
            />
          </div>
        </div>
      )}

      {(selectedCampingStyle == CAMPING_STYLES.CABIN ||
        selectedCampingStyle == CAMPING_STYLES.TENT ||
        selectedCampingStyle == CAMPING_STYLES.RV ||
        selectedCampingStyle == CAMPING_STYLES.BOAT_SLIP ||
        selectedCampingStyle == CAMPING_STYLES.STORAGE ||
        selectedCampingStyle == CAMPING_STYLES.RENTAL_RV) && (
        <div className=''>
          <label className='block text-md text-gray-900'>Electric (amps) </label>
          <div className='mt-1'>
            <SelectInput
              name='electric'
              options={electricOptions}
              selected={electricOptions.find((o) => o.value === selectedElectric)}
              setSelected={(o: Option) => setSelectedElectric(o.value)}
            />
          </div>
        </div>
      )}

      {CAPACITY_STYLES.includes(selectedCampingStyle) && (
        <div className=''>
          <label className='block text-md text-gray-900'>Max capacity</label>
          <div className='mt-1'>
            <input
              type='number'
              step='1'
              value={camperCapacity}
              disabled={props.isProcessing}
              onChange={(e) => setCamperCapacity(e.currentTarget.value)}
              name='camperCapacity'
              id='camperCapacity'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
            />
          </div>
        </div>
      )}

      {CAPACITY_STYLES.includes(selectedCampingStyle) && isParkAdminUser && (
        <div className=''>
          <label className='block text-md text-gray-900'>Minimum capacity</label>
          <div className='mt-1'>
            <input
              type='number'
              step='1'
              value={minimumCapacity}
              disabled={props.isProcessing}
              onChange={(e) => setMinimumCapacity(e.currentTarget.value)}
              name='minimumCapacity'
              id='minimumCapacity'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
            />
          </div>
        </div>
      )}

      {(selectedCampingStyle == CAMPING_STYLES.RV || selectedCampingStyle == CAMPING_STYLES.BOAT_SLIP) && (
        <div className=''>
          <label className='block text-md text-gray-900'>Current Meter Reading (KWh) </label>
          <div className='mt-1'>
            <input
              type='number'
              step='1'
              value={currentMeterReading}
              disabled={props.isProcessing}
              onChange={(e) => setCurrentMeterReading(e.currentTarget.value)}
              name='currentMeterReading'
              id='currentMeterReading'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
            />
          </div>
        </div>
      )}

      <div className=''>
        <label htmlFor='features' className='block font-semibold text-gray-900 pt-4 mb-4'>
          Features{' '}
        </label>
        <div className='flex gap-2 flex-col'>
          <Toggle disabled={props.isProcessing} checked={water} onChange={setWater} name='water' label='Water' />

          <Toggle disabled={props.isProcessing} checked={sewage} onChange={setSewage} name='sewage' label='Sewage' />
        </div>
      </div>


      <div className=''>
        <label htmlFor='prices' className='block font-semibold text-gray-900 pt-4 mb-4'>
          Prices{' '}
        </label>
        <div className='flex'>
          <label htmlFor='project-name' className='w-1/5 mr-4 text-md text-gray-900'>
            {' '}
            Enabled{' '}
          </label>
          <label htmlFor='project-name' className='w-2/5 mr-4 text-md text-gray-900'>
            {' '}
            Rate Type{' '}
          </label>
          <label htmlFor='project-name' className='w-2/5 text-md text-gray-900'>
            {' '}
            Amount{' '}
          </label>
        </div>

        {priceTypes
          .map((type) => {
            const isOnlyOneActive = numberOfActivePrices < 2
            const price = basePriceFor(type.duration, prices as PriceProps[])
            const isPersisted = !!price && !!price.id
            const isActive = isPersisted || (!!price && price['isActive'])

            return (
              <div className='flex flex-row items-center mb-2'>
                <div className='w-1/5 mr-4'>
                  {isOnlyOneActive && isActive ? (
                    <HoverTip content='A site needs at least one price'>
                      <Toggle
                        disabled={true}
                        name={`prices[${type.duration}][isActive]`}
                        checked={isActive}
                        onChange={(v) => {
                          if (v) {
                            setPrices([
                              ...prices.filter((p) => p.duration !== type.duration),
                              { amount_in_cents: 0, duration: type.duration, isActive: true },
                            ])
                            setNumberOfActivePrices(numberOfActivePrices + 1)
                          } else {
                            !!price
                              ? setPrices([
                                  ...prices.filter((p) => p.duration !== type.duration),
                                  {
                                    amount_in_cents: price.amount_in_cents,
                                    duration: type.duration,
                                    isActive: false,
                                  },
                                ])
                              : null
                            setNumberOfActivePrices(numberOfActivePrices - 1)
                          }
                        }}
                      />
                    </HoverTip>
                  ) : (
                    <Toggle
                      disabled={props.isProcessing}
                      name={`prices[${type.duration}][isActive]`}
                      checked={isActive}
                      onChange={(v) => {
                        if (v) {
                          setPrices([
                            ...prices.filter((p) => p.duration !== type.duration),
                            { amount_in_cents: 0, duration: type.duration, isActive: true },
                          ])
                          setNumberOfActivePrices(numberOfActivePrices + 1)
                        } else {
                          !!price
                            ? setPrices([
                                ...prices.filter((p) => p.duration !== type.duration),
                                {
                                  amount_in_cents: price.amount_in_cents,
                                  duration: type.duration,
                                  isActive: false,
                                },
                              ])
                            : null
                          setNumberOfActivePrices(numberOfActivePrices - 1)
                        }
                      }}
                    />
                  )}
                </div>

                <div className={classNames('w-2/5 mr-4', (!isActive || props.isProcessing) && 'opacity-50 pointer-events-none')}>
                  {type.label}
                </div>

                <div className={classNames('w-2/5', (!isActive || props.isProcessing) && 'opacity-50')}>
                  <div className='mt-1'>
                    <InputFieldWithCurrency
                      type='number'
                      disabled={!isActive || props.isProcessing}
                      defaultValue={Number(price?.amount_in_cents) / 100}
                      name={`prices[${type.duration}][amount]`}
                      id='amount'
                      currency={props.camp.currency}
                      full
                    />
                    {/* <input
                      type='number'
                      step='any'
                      disabled={!isActive || props.isProcessing}
                      defaultValue={Number(price?.amount_in_cents) / 100}
                      name={`prices[${type.duration}][amount]`}
                      id='amount'
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
                    /> */}
                    <input
                      type='text'
                      readOnly
                      defaultValue={type.duration}
                      name={`prices[${type.duration}][duration]`}
                      id='duration'
                      className='hidden'
                    />
                    {price && (
                      <input
                        type='text'
                        readOnly
                        defaultValue={price.id}
                        name={`prices[${type.duration}][id]`}
                        id='priceId'
                        className='hidden'
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          })
          .filter((c) => c)}

        <div className=''>
          <label className='block text-md text-gray-900'>
            Site Notes <span className='text-gray-400'>(shown when booking)</span>
          </label>
          <div className='mt-1'>
            <textarea value={notes} name='siteNotes' className='hidden' />
            <RichTextInput
              value={notes}
              setValue={setNotes}
              isDisabled={props.isProcessing}
              id='siteNotes'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
              hyperlink
            />
          </div>
        </div>

        <div className='mt-4'>
          <label className='block text-md text-gray-900'>
            Site-specific check-in policy <span className='text-gray-400'>(shown on confirmation page)</span>
          </label>
          <div className='mt-1'>
            <textarea value={checkinPolicyAdditions} name='siteCheckPolicyAdditions' className='hidden' />
            <RichTextInput
              value={checkinPolicyAdditions}
              setValue={setCheckinPolicyAdditions}
              isDisabled={props.isProcessing}
              id='siteCheckinPolicyAdditions'
              className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
              hyperlink
            />
          </div>
        </div>
        <div className='mt-4'>
          <SitePhotoUpload site={props.site} />
        </div>
        <div className='mt-4 mb-24'>
          <label className='block font-semibold text-gray-900 pt-4 mb-1'>Calendar sync</label>
          <div className='text-sm text-gray-500 mb-4'>
            Sync your calendar with another booking platform to avoid double bookings.
          </div>
          <div className="">
            <Button variant="white" className="w-full" onClick={handleAddVendorCalendar}>
              Add External Calendar Sync
            </Button>
          </div>
          <label className='block font-semibold text-gray-900 pt-4 mb-1'>Links</label>
          {props.site?.vendor_calendars?.length ? (
            <div className='flex flex-col gap-y-5'>
              {props.site.vendor_calendars.map((vendor) => (
                <div key={vendor.id} className="w-full flex">
                  <ExternalCalendarUrlInput url={vendor.calendar_url} setUrl={() => {}} readOnly />
                  <Button className="ml-2" variant="white" onClick={() => handleEditVendorCalendar(vendor)}>
                    Edit
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <div className='text-gray-300 italic'>No external calendars linked.</div>
          )}
        </div>
      </div>
    </div>
  )
}

const basePriceFor = (duration: validDurations, prices: PriceProps[]): PriceProps => {
  const pricesForDuration = prices.filter((p) => p.duration === duration)
  return pricesForDuration.reduce((acc, price) => {
    if (!price.electric && acc.electric) return price
    if (!price.range_start && acc.range_start) return price
    return acc
  }, pricesForDuration[0])
}

export default EditSiteForm
