import React, { useContext, useEffect } from 'react'
import { CampProps, CampingStyleProps } from '../../../interfaces'
import { UseFormReturn } from 'react-hook-form'
import classNames from '../../../utils/classNames'
import { PriceModes } from '../../../constants/price_modes'
import { RadioGroup } from '@headlessui/react'

interface ComponentProps {
  reactHookForm: UseFormReturn<
    CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>
  >
}

interface IPriceModeOption {
  mode: PriceModes
  description: string
  name: string
}

const priceModeOptions: IPriceModeOption[] = [
  {
    mode: PriceModes.TIERED,
    name: 'Tiered',
    description:
      'The full nightly rate is always applied, even for reservations earning a discounted weekly or monthly rate.',
  },
  {
    mode: PriceModes.PRORATED,
    name: 'Pro-rated',
    description:
      'Extra days on a weekly or monthly reservation are prorated to the discounted rate.',
  },
]

const Pricing = ({ reactHookForm }: ComponentProps) => {
  const { register, watch, setValue } = reactHookForm
  const pricingMode = watch('price_mode')

  const switchPricingMode = (value: IPriceModeOption) => {
    setValue('price_mode', value.mode)
  }

  useEffect(() => {
    register('price_mode', {})
  }, [register])

  return (
    <div>
      <div>
        <label className='text-sm font-semibold leading-6 text-gray-900'>Price mode:</label>
        <p className='mb-4 text-sm leading-6 text-gray-600'>
          How are prices calculated across different durations like nightlies vs weeklies.
        </p>
        <div className='flex gap-2'>
          <div aria-label='Privacy setting' className='w-full'>
            <RadioGroup
              value={priceModeOptions.find((option) => option.mode === pricingMode)}
              onChange={switchPricingMode}
              className='-space-y-px rounded-md bg-white w-full'>
              {priceModeOptions.map((mode, settingIdx) => (
                <RadioGroup.Option
                  key={mode.name}
                  value={mode}
                  aria-label={mode.name}
                  aria-description={mode.description}
                  className={({ checked }) =>
                    classNames(
                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      settingIdx === priceModeOptions.length - 1
                        ? 'rounded-bl-md rounded-br-md'
                        : '',
                      checked ? 'z-10 border-green-200 bg-green-50' : 'border-gray-200',
                      'relative flex cursor-pointer border p-4 focus:outline-none'
                    )
                  }>
                  {({ checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? 'border-4 border-green-600'
                            : ' border border-gray-300 bg-white',
                          'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full'
                        )}
                        aria-hidden='true'>
                        {/* <span className="h-1.5 w-1.5 rounded-full bg-white" /> */}
                      </span>
                      <span className='ml-3 flex flex-col'>
                        <span
                          className={classNames(
                            checked ? 'text-green-900' : 'text-gray-900',
                            'block text-sm font-medium'
                          )}>
                          {mode.name}
                        </span>
                        <span
                          className={classNames(
                            checked ? 'text-green-700' : 'text-gray-500',
                            'block text-sm'
                          )}>
                          {mode.description}
                        </span>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
