import { DateTime } from 'luxon'

const parseDateTime = (dateStr: string): DateTime => {
  const date = DateTime.fromISO(dateStr)
  if (!date.isValid) {
    return DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm:ss z')
  } else {
    return date
  }
}

export default parseDateTime
