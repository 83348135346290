import React, { useState } from 'react'
import { Switch } from '@headlessui/react'
import classNames from '../../utils/classNames'


const ToggleLine = ({ checked, onChange, label }) => {
  return (
    <Switch.Group
      as='div'
      className='w-full flex items-center justify-between p-2 cursor-pointer bg-gray-50 hover:bg-gray-100 rounded-lg'
      onClick={() => onChange(!checked)}
    >
      <Switch.Label as='span' className='mr-3'>
        {label}
      </Switch.Label>
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-green-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default ToggleLine
