import React, { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import classNames from '../../utils/classNames'
import { UseFormRegister } from 'react-hook-form'

interface ComponentProps {
  label: string | JSX.Element
  value?: boolean
  setValue?: (v: boolean) => void
  disabled?: boolean
  register?: UseFormRegister<any>
  name?: string
  labelClasses?: string
  labelStyle?: React.CSSProperties
}

const CheckboxWithLabel = ({ label, value, setValue, disabled, register, name, labelClasses, labelStyle }: ComponentProps) => {
  const handleClick = () => {
    if (disabled) return

    if (register && name) {
      // NOTE: controlled by react-hook-form, so we don't need to do anything
    } else {
      setValue(!value)
    }
  }

  return (
    <div
      className={classNames(
        'relative flex items-center cursor-pointer',
        disabled && 'opacity-30 pointer-events-none'
      )}
      onClick={handleClick}>
      <div className='flex h-5 items-center'>
        <input
          id={name || (typeof label === 'string' ? label : '')}
          aria-describedby='send-email-description'
          name={name || (typeof label === 'string' ? label : '')}
          type='checkbox'
          className='h-4 w-4 rounded border-gray-300 text-green-700 focus:ring-green-700 cursor-pointer'
          checked={value}
          disabled={disabled}
          {...(register && name ? register(name, {}) : {})}
        />
      </div>

      <label
        htmlFor='send-email'
        className={classNames('ml-3 text-md font-medium text-gray-700 cursor-pointer', labelClasses)}
        style={labelStyle}>
        {label}
      </label>
    </div>
  )
}

export default CheckboxWithLabel
