import React, { useContext, useEffect, useState } from 'react'
import api from '../../../api2'
import { AdminContext } from '../../../contexts/AdminContext'
import ICamper from '../../../interfaces/ICamper'
import { useDebouncedCallback } from 'use-debounce'
import { tSortDirection } from '../../../api2/camps'
import Camper from './Camper'
import { FormProvider } from '../../../contexts/FormContext'
import PaginatedTable from './PaginatedTable'

const Campers = () => {
  const { camp, selectedCamper, setSelectedCamper, currentPage } = useContext(AdminContext)

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [campers, setCampers] = useState<ICamper[]>([])
  const [totalCampers, setTotalCampers] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [sortBy, setSortBy] = useState(null)
  const [isAscending, setIsAscending] = useState(false)

  const fetchCampers = useDebouncedCallback(() => {
    const paginationOptions = {
      page: page,
      per_page: perPage,
      sort_column: sortBy,
      sort_direction: (isAscending ? 'asc' : 'desc') as tSortDirection
    }

    api.Camps.fetchCampers(camp.id, paginationOptions)
      .then(({ campers: _campers, total_pages, total_records }) => {
        setCampers(_campers)
        setTotalCampers(total_records)
        setTotalPages(total_pages)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 50)

  useEffect(() => {
    setPage(1)
  }, [sortBy, isAscending])

  useEffect(() => {
    setIsLoading(true)
    fetchCampers()
  }, [camp, page, perPage, sortBy, isAscending])

  useEffect(() => {
    if (!selectedCamper) return

    setCampers(campers.map((c) => c.id === selectedCamper.id ? selectedCamper : c))
  }, [selectedCamper])

  useEffect(() => {
    if (currentPage !== 'guests' && selectedCamper) {
      setSelectedCamper(null)
    }
  }, [currentPage])

  return (
    <div className='w-full lg:mx-auto mx-4'>
      {!selectedCamper ? (
        <div className=''>
          <PaginatedTable
            reloadCampers={fetchCampers}
            records={campers}
            isLoading={isLoading}
            selectedRecord={selectedCamper}
            totalRecords={totalCampers}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
            onRowClick={(camper: ICamper) => setSelectedCamper(camper)}
          />
        </div>
      ) : (
        <div className=''>
          <FormProvider init={{
            ...selectedCamper,
            notes: selectedCamper.notes || '',
            reservations: selectedCamper.reservations || []
          }}>
            <Camper camper={selectedCamper} setSelectedCamper={setSelectedCamper} reloadCampers={fetchCampers} />
          </FormProvider>
        </div>
      )}
    </div>
  )
}

export default Campers
