import React, { useContext, useEffect, useState } from 'react'
import Button from '../../ui/Button'
import InputFieldWithLabel from '../../Admin/Inputs/InputFieldWithLabel'
import { CreditCardIcon } from '@heroicons/react/24/solid'
import { AdminContext } from '../../../contexts/AdminContext'
import isValidEmail from '../../../utils/isValidEmail'
import api from '../../../api2'
import Alert from '../../ui/Alert'
import ILineItem from '../../../interfaces/ILineItem'
import { mapLineItemToParams } from '../../../api2/orders'
import IOrder from '../../../interfaces/IOrder'

interface ComponentProps {
  email: string
  onSuccess: () => void
  order: IOrder
  setNewOrder?: (order: IOrder) => void
  campingStyleId: string
  isTaxFeeEnabled: boolean
  isCreditCardFeeEnabled: boolean
  discountSlug: string
  lineItems: Array<Partial<ILineItem>>
  depositLineItems: Array<Partial<ILineItem>>
}

const PaymentLinkForm = (props: ComponentProps) => {
  const { camp, showNotification } = useContext(AdminContext)

  const [email, setEmail] = useState(props.email)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setEmail(props.email)
  }, [props.email])

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    setIsLoading(true)

    api.Orders.createPaymentLink({
      order_id: props.order?.id,
      camp_id: camp.id,
      camper_id: props.order?.camper_id,
      camping_style_id: props.campingStyleId,
      is_credit_card_fee_enabled: props.isCreditCardFeeEnabled,
      discount_slug: props.discountSlug,
      line_items_attributes: props.lineItems.map((li) => mapLineItemToParams(li, props.isTaxFeeEnabled)),
      partial_line_items_attributes: props.depositLineItems?.map((li) => mapLineItemToParams(li, false)),
      email
    })
      .then(({ payment_link }) => {
        // TODO: update local order from payment_link.order
        props.setNewOrder && props.setNewOrder(payment_link.order)
        props.onSuccess()
        setIsLoading(false)
      })
      .catch((err) => {
        setErrorMessage(err.message)
        showNotification({ type: 'error', title: err.message })
        setIsLoading(false)
      })
  }

  return (
    <div className='flex flex-col'>
      <InputFieldWithLabel className='w-full' value={email} onChange={handleChangeEmail} label='Email' />
      {!!errorMessage && (
        <div className='mt-4'>
          <Alert description={errorMessage} />
        </div>
      )}
      <div className='mt-6 flex justify-end'>
        <Button
          onClick={handleSubmit}
          className='px-6'
          variant='blue'
          disabled={!isValidEmail(email) || isLoading || Number(props.order?.total_in_cents) <= 0}
        >
          Send payment link
        </Button>
      </div>
    </div>
  )
}

export default PaymentLinkForm
