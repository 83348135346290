import React, { useContext, useEffect, useState } from 'react'
import { Card, Transition } from 'semantic-ui-react'
import { Moment } from 'moment'
import { CampingStyleProps, CampProps, SiteProps } from '../../interfaces'
import Features from './Features'
import Payment from './Payment'
import { validElectric, validRigType } from '../../types'
import { BookerContext } from '../../contexts/BookerContext'
import Button from '../ui/Button'
import { formatMoneyFromCents } from '../../utils/formatMoney'
import classNames from '../../utils/classNames'

interface ComponentProps {
  visible: boolean
  camp: CampProps
  site: SiteProps
  startDate: Moment
  endDate: Moment
  setIsPaying: any
  userCampingStyle: CampingStyleProps
  userRigType: validRigType
  userRigLength: number
  userElectric: validElectric
  userPassengers: number
  userKidCampers: number
  onError: (errorMessage: string) => void
  skipShowFeatures: boolean
  showPayment: boolean
  setShowPayment: any
}

const SelectionCard = (props: ComponentProps) => {
  // TODO: replace props with this context
  const { trackForCamp, trackForPark } = useContext(BookerContext)

  useEffect(() => {
    if (props.skipShowFeatures || !props.userCampingStyle?.is_showing_features && !props.site?.notes && props.visible) {
      props.setShowPayment(true)
      return
    }

    if (props.visible && !props.skipShowFeatures) {
      props.setShowPayment(false)
    }
  }, [props.visible])

  const onCtaClick = () => {
    trackForCamp('add_to_cart')
    trackForPark('add_to_cart')
    props.setShowPayment(true)
  }

  if (!(props.site && props.startDate && props.endDate)) {
    return <></>
  }

  return (
    <Card
      fluid
      className={props.visible ? 'transition visible active' : 'transition hidden'}>
      <Card.Content>
        <Transition.Group animation='fade right'>
          <Features
            visible={!props.showPayment}
            site={props.site}
            nights={props.endDate.diff(props.startDate, 'days')}
            confirmCallback={onCtaClick}
            userElectric={props.userElectric}
            userCampingStyle={props.userCampingStyle}
          />
          <Payment
            visible={props.showPayment}
            camp={props.camp}
            camping_style={props.userCampingStyle}
            site={props.site}
            startDate={props.startDate}
            endDate={props.endDate}
            setIsPaying={props.setIsPaying}
            userRigType={props.userRigType}
            userRigLength={props.userRigLength}
            userElectric={props.userElectric}
            userPassengers={props.userPassengers}
            userKidCampers={props.userKidCampers}
            onError={props.onError}
          />
          <BottomBar
            visible={!props.showPayment}
            onCtaClick={onCtaClick}
            ctaText='SELECT THIS SITE'
          />
        </Transition.Group>
      </Card.Content>
    </Card>
  )
}

const BottomBar = ({ visible, onCtaClick, ctaText }) => {
  const { selectedSite, secondaryColor, campingStyle } = useContext(BookerContext)

  if (!visible) return <></>

  return (
    <div className='h-20 px-4 fixed bottom-0 left-0 bg-white w-full flex items-center justify-between border-t border-gray-300'>
      <p
        className={classNames(
          'text-gray-600',
          selectedSite.calculated_nightly_price_in_cents == 0 && 'invisible'
        )}>
        {formatMoneyFromCents(selectedSite.calculated_nightly_price_in_cents)} {selectedSite.calculated_frequency_label}
      </p>

      <div className='float-right font-semibold'>
        <Button
          style={{ backgroundColor: secondaryColor }}
          className='hover:opacity-80 text-white float-right font-semibold'
          size='large'
          disabled={!selectedSite.is_available}
          onClick={onCtaClick}>
          {ctaText}
        </Button>
      </div>
    </div>
  )
}

export default SelectionCard
