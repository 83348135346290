import React, { useContext, useReducer, useState } from 'react'
import api from '../../api'
import { STATUSES } from '../../constants'
import { ReservationProps, UserProps } from '../../interfaces'
import pluralize from '../../utils/pluralize'
import {
  ArrowRightIcon,
  CalendarIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  PencilIcon,
  UserGroupIcon,
  ArrowLeftOnRectangleIcon,
  PlusCircleIcon,
  CheckIcon,
  CheckBadgeIcon,
} from '@heroicons/react/24/solid'
import Button from '../ui/Button'
import { AdminContext } from '../../contexts/AdminContext'
import ToDoStatusBadge from './ToDoStatusBadge'
import { formatName } from '../../utils/formatName'
import stripHtmlTags from '../../utils/stripHtmlTags'
import { formatMoney } from '../../utils/formatMoney'
import { formatDateRange } from '../../utils/formatDateRange'
import ReservationDecorator from '../../decorators/ReservationDecorator'
import classNames from '../../utils/classNames'

const ToDoItem = ({
  checkin,
  updateCallback,
  setSelectedReservation,
  setPage,
  campCurrency,
  section,
  isInvoiceSentDefault,
  forPrint
}: {
  checkin: ReservationProps
  updateCallback: (reservation: ReservationProps) => any
  setSelectedReservation: (reservation: ReservationProps) => any
  setPage: (page: string) => any
  campCurrency: string
  section: 'checkins' | 'checkouts' | 'invoices' | 'current'
  isInvoiceSentDefault?: boolean
  forPrint?: boolean
}) => {
  const { showPaymentModal, campingStyles } = useContext(AdminContext)

  const updateReservation = (reservationId: string, props: ReservationProps) => {
    api
      .updateReservation(reservationId, props)
      .then((updatedRes: ReservationProps) => {
        updateCallback(updatedRes)
      })
      .catch((err) => {
        alert(JSON.stringify(err))
      })
  }

  return (
    <div className='block'>
      <div className='flex sm:flex-row sm:space-y-0 space-y-4 flex-col content-center justify-between px-4 py-4 sm:px-6'>
        <div className='space-y-2'>
          <div className='flex flex-col sm:flex-row sm:items-center flex-wrap'>
            <div className=''>
              <ToDoStatusBadge
                status={checkin.status}
                isCheckedIn={checkin.is_checked_in}
                isCheckedOut={checkin.is_checked_out}
                name={formatName(checkin.user?.camper ? checkin.user?.camper : checkin.user)}
                section={section}
                forPrint={forPrint}
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-x-4 lg:gap-x-6 print-wrap'>
              <div className='flex items-start text-gray-500  flex-shrink-0'>
                <div className='flex items-center'>
                  <MapPinIcon
                    className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  {checkin.site.name}
                </div>
              </div>
              <div className='flex items-start text-gray-500 flex-shrink-0'>
                <div className='flex items-center'>
                  <CalendarIcon
                    className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  {formatDateRange(checkin.start_date, checkin.end_date)}
                </div>
              </div>
              <div className='flex items-start text-gray-500 flex-shrink-0'>
                <div className='flex items-center'>
                  <CurrencyDollarIcon
                    className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  {formatMoney((checkin.total_owed_in_cents || checkin.total) / 100, campCurrency)}
                </div>
              </div>
              {!forPrint && (
                <>
                  {checkin.passengers > 0 && (
                    <div className="flex items-start text-gray-500 flex-shrink-0">
                      <div className="flex items-center">
                        <UserGroupIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {campingStyles.find(
                          (style) =>
                            checkin.selected_camping_style_id == style.id ||
                            checkin.site.camping_style_id == style.id
                        )?.extra_kid_campers_price_in_cents > 0
                          ? `${checkin.passengers} ${pluralize(
                              ' adult',
                              checkin.passengers
                            )}${
                              checkin.kid_campers > 0
                                ? ', ' +
                                  checkin.kid_campers +
                                  pluralize(' kid', checkin.kid_campers)
                                : ''
                            }`
                          : checkin.passengers +
                            pluralize(' guest', checkin.passengers)}
                      </div>
                    </div>
                  )}
                  {checkin?.notes && (
                    <div className="flex items-start text-gray-500 mr-4">
                      <div className="flex items-center">
                        <PencilIcon
                          className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {stripHtmlTags(checkin.notes)}
                      </div>
                    </div>
                  )}
                  {checkin?.price_details?.additional_fees?.filter(
                    (fee) => fee.type === 'add-on'
                  )?.length ? (
                    <div className="todo-addon flex items-center overflow-x-auto text-gray-400 gap-x-4 lg:gap-x-6">
                      {checkin?.price_details?.additional_fees
                        ?.filter((fee) => fee.type === 'add-on')
                        .map((addon) => (
                          <div
                            className={`flex items-center whitespace-nowrap`}
                          >
                            <PlusCircleIcon className="mr-1.5 h-5 w-5 text-gray-400" />
                            <div>
                              {addon.label} x {addon.quantity}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='relative sm:flex-shrink-0 flex items-center justify-end print-hide'>
          {checkin.status == STATUSES.PAID ? (
            // <div className=''>
            //   <Button
            //     variant='gray'
            //     onClick={() => updateReservation(checkin.id, { status: STATUSES.UNPAID })}
            //     disabled={!!checkin.vendor_id}>
            //     {!checkin.vendor_id ? 'Unpaid' : `Prepaid`}
            //   </Button>
            // </div>
            <></>
          ) : (
            <>
              <Button
                className={'mr-2'}
                variant='white'
                onClick={() =>
                  showPaymentModal(
                    new ReservationDecorator(checkin).remainingOwedBeforeFeesInCents,
                    { reservation: checkin }
                  )
                }
                icon={<CreditCardIcon className='w-5 h-5 text-gray-700 hidden sm:block' />}>
                Pay now
              </Button>
              <Button
                size='normal'
                variant='white'
                onClick={() => updateReservation(checkin.id, { status: STATUSES.PAID })}>
                Paid <span className='hidden sm:inline'>in full</span>
              </Button>
            </>
          )}
          <div
            className={`ml-2 ${
              checkin.kind == 'recurring' || (section == 'checkouts' && 'hidden')
            }`}>
            <Button
              size='normal'
              type='button'
              variant='none'
              className={classNames(
                'flex items-center text-mdbase',
                checkin.is_checked_in
                  ? 'bg-green-600 hover:bg-green-700 text-white'
                  : 'bg-white hover:bg-gray-100 border border-gray-400 hover:border-gray-500'
              )}
              onClick={() => {
                updateReservation(checkin.id, { is_checked_in: !checkin.is_checked_in })
              }}
              icon={
                checkin.is_checked_in ? (
                  <CheckBadgeIcon className='w-5 h-5 hidden sm:block' />
                ) : (
                  <CheckBadgeIcon className='w-5 h-5 text-green-600 hidden sm:block' />
                )
              }>
              {checkin.is_checked_in ? 'Checked-in' : 'Check in'}
            </Button>
          </div>
          {section == 'checkouts' && (
            <div className='ml-2'>
              <Button
                size='normal'
                type='button'
                variant='none'
                className='flex items-center text-mdbase bg-white hover:bg-gray-100 border border-gray-400 hover:border-gray-500'
                style={{ height: '40px' }}
                onClick={() => updateReservation(checkin.id, { is_checked_out: true })}>
                <ArrowLeftOnRectangleIcon className='w-5 h-5 z-10 mr-2 hidden sm:block' />
                <div className='absolute ml-1 w-2.5 h-4 rounded-md bg-red-100 hidden sm:block' />
                Check out
              </Button>
            </div>
          )}
          <a
            className='py-3 px-1 sm:hidden ml-1 '
            onClick={() => {
              setPage('reservations')
              setSelectedReservation(checkin)
            }}>
            <ArrowRightIcon className='text-gray-700 h-6 w-6' />
          </a>
          <div className='hidden sm:inline ml-4 pl-2 border-l border-gray-200'>
            <Button
              size='normal'
              variant='white'
              onClick={() => {
                setPage('reservations')
                setSelectedReservation(checkin)
              }}
              className='flex items-center ml-2'>
              {/* View reservation */}
              <ArrowRightIcon className='text-gray-700 h-6 w-6' />
            </Button>
          </div>
        </div>
        <div className='hidden checbox-show gap-4 items-center'>
          <div className='items-center flex flex-col justify-center gap-0.5'>
            <div style={{ fontSize: 10 }} className='text-xs whitespace-nowrap'>
              Checked in
            </div>
            <div className='w-8 h-8 border-2 flex items-center justify-center'>
              {checkin.is_checked_in && <CheckIcon />}
            </div>
          </div>
          <div className='items-center flex flex-col justify-center gap-0.5'>
            <div style={{ fontSize: 10 }}>Paid</div>
            <div className='w-8 h-8 border-2 flex items-center justify-center'>
              {checkin.status === STATUSES.PAID && <CheckIcon />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToDoItem
