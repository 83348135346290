import { CAMPING_STYLES } from "./camping_styles"

const RV_BACKIN: 'back-in' = 'back-in'
const RV_PULLTHROUGH: 'pull-through' = 'pull-through'
const RV_HYBRID: 'hybrid' = 'hybrid'

// NOTE: these are the same as camping styles
const TENT = 'tent'
const CABIN = 'cabin'
const BOAT_SLIP = 'boat-slip'
const BOAT_RENTAL = 'boat-rental'
const MOBILE_HOME = 'mobile-home'
const STORAGE = 'storage'
const PAVILION = 'pavilion'
const CABANA = 'cabana'
const EVENT_CENTER = 'event-center'
const GARAGE = 'garage'
const HORSE_PEN = 'horse-pen'
const HORSE_STALL = 'horse-stall'
const SHELTER = 'shelter'
const RV_STORAGE = 'rv-storage'
const RENTAL_RV = 'rental-rv'
const LODGE = 'lodge'
const GOLF_CART = 'golf-cart'
const POOL = 'pool'
const VENDOR_BOOTH = 'vendor-booth'
const DISC_GOLF = 'disc-golf'
const DRY_CAMPING = 'dry-camping'
const DOME = 'dome'
const GLAMPING = 'glamping'
const HOME = 'home'
const LODGING = 'lodging'
const BONFIRE = 'bonfire'
const TRUCK = 'truck'
const GROUP = 'group'
const COTTAGE = 'cottage'
const MAIN_HOUSE = 'main-house'
const FOOD_TRUCK = 'food-truck'
const MISC = 'miscellaneous'
const ENSUITE_UNIT = 'ensuite-unit'
const UNPOWERED_SITE = 'unpowered-site'
const TIPI = 'tipi'
const KAYAK = 'kayak'
const VAN = 'van'
const ROOM = 'room'
const GENERATOR = 'generator'
const RENTAL_ITEM = 'rental-item'
const PICNIC_AREA = 'picnic-area'
const KITCHEN = 'kitchen'
const PLATFORM = 'platform'
const CANVAS_TENT = 'canvas-tent'
const SEASONAL_RV = 'seasonal-rv'

const KINDS = {
  RV_BACKIN,
  RV_PULLTHROUGH,
  RV_HYBRID,
  TENT,
  CABIN,
  BOAT_SLIP,
  BOAT_RENTAL,
  MOBILE_HOME,
  STORAGE,
  PAVILION,
  CABANA,
  EVENT_CENTER,
  GARAGE,
  HORSE_PEN,
  HORSE_STALL,
  SHELTER,
  RV_STORAGE,
  RENTAL_RV,
  LODGE,
  GOLF_CART,
  POOL,
  VENDOR_BOOTH,
  DISC_GOLF,
  DRY_CAMPING,
  DOME,
  GLAMPING,
  HOME,
  LODGING,
  BONFIRE,
  TRUCK,
  GROUP,
  COTTAGE,
  MAIN_HOUSE,
  FOOD_TRUCK,
  MISC,
  ENSUITE_UNIT,
  UNPOWERED_SITE,
  TIPI,
  KAYAK,
  VAN,
  ROOM,
  PICNIC_AREA,
  GENERATOR,
  RENTAL_ITEM,
  SEASONAL_RV,
  KITCHEN,
  PLATFORM,
  CANVAS_TENT,
}

export { KINDS }
