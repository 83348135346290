import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { titleCase } from '../../utils'
import classNames from '../../utils/classNames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Icon from '../../icon'
import HoverTip from './HoverTip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

interface ComponentProps {
  label?: string
  options: Array<{ label: string; value: string }>
  selectedOption: any
  onChange?: (v: string) => void
}

const ReservationTypeSelector = (props: ComponentProps) => {

  return (
    <RadioGroup className='flex-grow max-w-2xl' value={props.selectedOption}>
      <RadioGroup.Label className='text-base text-gray-900'>{props.label}</RadioGroup.Label>

      <div className='flex flex-row gap-4'>
        {props.options.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.value}
            onClick={() => {
              props.onChange(option.value)
            }}
            className={({ checked, active }) =>
              classNames(
                'ghostinspector-new-reservation-type-'+option.value,
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-1 ring-green-600' : '',
                'flex-grow relative bg-white border rounded-lg shadow-sm p-2.5 flex cursor-pointer focus:outline-none'
              )
            }>
            {({ checked, active }) => (
              <>
                <div className='flex-1 flex justify-center w-28'>
                  <div className='flex flex-col text-center justify-items-center'>
                    <CampingStyleIcon
                      name={option.value}
                      className={classNames(
                        'w-12 h-12 self-center stroke-current',
                        checked ? 'text-green-600' : 'text-gray-900'
                      )}
                    />
                    <RadioGroup.Label
                      as='span'
                      className={classNames(
                        'flex text-sm',
                        checked ? 'text-green-600 font-bold' : 'text-gray-900 font-medium'
                      )}>
                      {titleCase(option.value)}
                      <HoverTip content={hovertipText(option.value)}>
                        <InformationCircleIcon className='w-3.5 h-3.5 ml-0.5' />
                      </HoverTip>
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    'h-5 w-5 text-green-600 absolute right-4',
                    !checked ? 'invisible' : ''
                  )}
                  aria-hidden='true'
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-green-600' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const hovertipText = (timing: string) => {
  switch (timing) {
    case 'single':
      return 'Standard reservations for single campers'
    case 'recurring':
      return 'Breaks the reservation into monthly segments for sending invoices to long-term campers'
    case 'group':
      return 'Hold multiple sites for a group and send a link that each camper can use to book indiviudually'
    case 'blocked':
      return 'Block a site from being booked'
  }
}

const svgForName = (name: string) => {
  switch (name) {
    case 'single':
      return Icon.SingleReservation
    case 'recurring':
      return Icon.RecurringReservation
    case 'group':
      return Icon.GroupReservation
    case 'blocked':
      return Icon.BlockedReservation
    default:
      console.error('Missing icon for:', name)
      return <svg />
  }
}

const CampingStyleIcon = ({ name, className }: { name: string; className: string }) => {
  const IconSvg = svgForName(name)
  return <IconSvg className={className} />
}

export default ReservationTypeSelector
