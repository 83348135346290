import React, { useState } from 'react'
import Spinner from '../../ui/Spinner'

type iSiteReport = {
  site_name: string
  booked: number
  avg_rate: number
  revenue: number
  unbooked: number
  occupancy: string
}

interface ComponentProps {
  data: iSiteReport[]
  headers?: any
}

const Table = (props: ComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className='relative'>
      <div className='sm:flex sm:items-center'>
        {isLoading && (
          <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-center justify-center'>
            <Spinner size={12} />
          </div>
        )}
        <div className='sm:flex-auto'></div>
      </div>
      <div className='mt-8 flow-root'>
        <div className='-mx-4 -my-2 overflow-y-visible sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='relative'>
              <table className='min-w-full table-fixed divide-y divide-gray-300'>
                <thead>
                  <tr>
                    {props.headers.map((header, index) => {
                      return (
                        <th
                          scope='col'
                          key={index}
                          className='pr-3 py-3.5 text-left text-base font-semibold text-gray-900'>
                          {header.label}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {props.data &&
                    props.data.map((siteData, index) => {
                      return (
                        <tr className='h-10' key={index}>
                          {Object.keys(siteData).map((key) => (
                            <td className='text-gray-500'>{siteData[key]}</td>
                          ))}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table
