import { ELECTRICS } from '../../constants'
import { SiteProps } from '../../interfaces'
import { validElectric } from '../../types'

// NOTE: if we ever restrict sites in the admin view, then we'll need to update this to fetch all
// sites for camp
const electricOptionsFor = (sites: SiteProps[]): { label: string; value: validElectric }[] => {
  let is15A = false
  let is20A = false
  let is30A = false
  let is50A = false
  let isNonElectric = false

  if (sites && sites.length > 0) {
    sites.forEach((site) => {
      if (site?.electric === ELECTRICS.E15) {
        is15A = true
      } else if (site?.electric === ELECTRICS.E20) {
        is20A = true
      } else if (site?.electric === ELECTRICS.E30) {
        is30A = true
      } else if (site?.electric === ELECTRICS.E50) {
        is50A = true
      } else if (site?.electric === ELECTRICS.SPLIT_30_50) {
        is30A = true
        is50A = true
      } else if (site?.electric === ELECTRICS.SPLIT_15_30_50) {
        is15A = true
        is30A = true
        is50A = true
      } else if (site?.electric === ELECTRICS.SPLIT_20_30_50) {
        is20A = true
        is30A = true
        is50A = true
      } else if (site?.electric === ELECTRICS.SPLIT_15_30) {
        is15A = true
        is30A = true
      } else if (site?.electric === ELECTRICS.SPLIT_20_30) {
        is20A = true
        is30A = true
      } else if (site?.electric === null || site?.electric === ELECTRICS.NONE) {
        isNonElectric = true
      }
    })
  }

  const ampsOptions = []
  if (is15A) ampsOptions.push(ELECTRICS.E15)
  if (is20A) ampsOptions.push(ELECTRICS.E20)
  if (is30A) ampsOptions.push(ELECTRICS.E30)
  if (is50A) ampsOptions.push(ELECTRICS.E50)
  if (isNonElectric) ampsOptions.push(ELECTRICS.NONE)

  const availableElectricOptions = ampsOptions.map((v) => ({
    label: v === ELECTRICS.NONE ? 'Non-electric' : `${v.replace('E', '')}-amp`,
    value: v,
  }))

  return [{ label: '\u00A0', value: null }, ...availableElectricOptions]
}

export default electricOptionsFor
