export default (resp: Response, headers?: any) => {
  if (resp.ok) {
    return handleOkResponseByContent(resp, headers)
  } else {
    return Promise.reject(resp)
  }
}

const handleOkResponseByContent = async (resp: Response, headers?: any) => {
  switch(headers && headers['Accept']) {
    case 'text/csv':
      return resp.text()
    default:
      return resp.json()
  }
}
