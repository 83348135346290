import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

interface ComponentProps {
  setFilter: (v: string) => void
}

const StoreSearchBar = (props: ComponentProps) => {

  return (
    <div className='w-full sm:max-w-md'>
      <label htmlFor='search' className='sr-only'>
        Search
      </label>
      <div className='relative'>
        <div className='pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center'>
          <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          id='search'
          name='search'
          className='block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-base placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-green focus:border-green'
          placeholder='Search products'
          type='search'
          autoComplete='off'
          onChange={(e) => {
            props.setFilter(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default StoreSearchBar
