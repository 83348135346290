import React, { useState } from 'react'
import api from '../api2'
import RefreshModal from '../components/Admin/RefreshModal'
import { CampProps, ReservationProps, UserProps } from '../interfaces'
import { AdminContext } from './AdminContext'
import IGroupedReservation from '../interfaces/IGroupedReservation'
import MessageModal from '../components/Admin/Reservations/MessageModal'
import Notification, { NotificationContainer } from '../components/ui/Notification'
import * as Sentry from '@sentry/react'
import Modal from '../components/ui/Modal'
import ConfirmationModalContent, { IUpdatingReservation } from '../components/Admin/Reservations/ConfirmationModalContent'
import { IReservationState } from '../interfaces/IReservationStates'
import eSendingMode from '../enums/eSendingMode'
import ICamper from '../interfaces/ICamper'
import PosModal from '../components/Reservations/PosModal'
import { useConfirm } from '../hooks/useConfirm'
import IInvoice from '../interfaces/IInvoice'

export const AdminProvider = ({
  currentPage,
  setCurrentPage,
  currentUserEmail,
  currentUserRole,
  camp,
  setCamp,
  sites,
  campingStyles,
  setCampingStyles,
  setSites,
  selectedReservation,
  setSelectedReservation,
  children,
}) => {
  const { showConfirm, ConfirmModal } = useConfirm()
  
  const [reservationStates, setReservationStates] = useState({})
  const [refreshModalOpen, setRefreshModalOpen] = useState(false)
  const [isShowingPaymentModal, setIsShowingPaymentModal] = useState(false)
  const [paymentModalAmountInCents, setPaymentModalAmountInCents] = useState(0)
  const [paymentModalReservation, setPaymentModalReservation] = useState<ReservationProps>(null)
  const [paymentModalReservations, setPaymentModalReservations] = useState<ReservationProps[]>(null)
  const [paymentModalGroupedReservation, setPaymentModalGroupedReservation] =
    useState<IGroupedReservation>(null)
  const [paymnentModalIsDeposit, setPaymentModalIsDeposit] = useState(false)
  const [isParkAdminUser, setIsParkAdminUser] = useState(
    currentUserEmail?.includes('@poweredbypark.com') || false
  )
  const [isShowingMessageModal, setIsShowingMessageModal] = useState(false)
  const [messageModalReservations, setMessageModalReservations] = useState<ReservationProps[]>(null)
  const [messageModalInvoices, setMessageModalInvoices] = useState<IInvoice[]>(null)
  const [messageModalEmails, setMessageModalEmails] = useState<string[]>(null)
  const [messageModalPhoneNumbers, setMessageModalPhoneNumbers] = useState<string[]>(null)
  const [isMessageModalBatch, setIsMessageModalBatch] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationType, setNotificationType] = useState<'success' | 'warning' | 'error'>('success')
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationDescription, setNotificationDescription] = useState('')
  const [notificationDismissAfter, setNotificationDismissAfter] = useState<number>(null)
  const [notificationOnClick, setNotificationOnClick] = useState<() => void>(null)
  const [isMessageSent, setIsMessageSent] = useState(false)
  const [selectedSendingMode, setSelectedSendingMode] = useState<eSendingMode>(eSendingMode.EMAIL) // TODO: default to cookies for this setting
  const [accessibleCamps, setAccessibleCamps] = useState<CampProps[]>([])
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [isModalDynamicWidth, setIsModalDynamicWidth] = useState(false)
  const [modalMaxWidth, setModalMaxWidth] = useState('max-w-6xl')
  const [modalContent, setModalContent] = useState(null)
  const [modalCloseCallback, setModalCloseCallback] = useState<() => any>()
  const [selectedCamper, setSelectedCamper] = useState<ICamper>(null)
  const [isToAndCCBlocked, setisToAndCCBlocked] = useState(false)

  const reloadAccessibleCamps = async () => {
    return api.Me.camps()
      .then((camps) => {
        setAccessibleCamps(camps)
      })
      .catch((err) => {
        console.error(err)
        Sentry.captureMessage(`Error fetching camps for admin user - ${err.message}`)
      })
  }

  const showModal = (
    component: JSX.Element,
    isDynamicWidth = true,
    maxWidth?,
    options: { closeCallback?: () => any } = {}
  ) => {
    setIsModalDynamicWidth(isDynamicWidth)
    setModalMaxWidth(maxWidth)
    setModalContent(component)
    setIsShowingModal(true)
    if (options.closeCallback) {
      setModalCloseCallback(() => options.closeCallback)
    } else {
      setModalCloseCallback(null)
    }
  }

  const closeModal = () => {
    setIsShowingModal(false)
    setModalContent(null)
    setIsModalDynamicWidth(false)
    setModalMaxWidth('max-w-6xl')
    setModalCloseCallback(null)
  }

  const showPaymentModal = (
    amountInCents: number,
    options?: {
      reservation?: ReservationProps
      reservations?: ReservationProps[]
      groupedReservation?: IGroupedReservation
      isDeposit?: boolean
    }
  ) => {
    setPaymentModalAmountInCents(amountInCents)
    setPaymentModalReservation(options.reservation)
    setPaymentModalReservations(options.reservations)
    setPaymentModalGroupedReservation(options.groupedReservation)
    setPaymentModalIsDeposit(options.isDeposit)
    setIsShowingPaymentModal(true)
  }

  const showMessageModal = ({
    reservations,
    invoices,
    emails,
    phoneNumbers,
    isBatch,
    isToAndCCBlocked
  }: {
    reservations?: ReservationProps[],
    invoices?: IInvoice[]
    emails?: string[]
    phoneNumbers?: string[]
    isToAndCCBlocked?: boolean
    isBatch: boolean
  }) => {
    setMessageModalReservations(reservations)
    setMessageModalInvoices(invoices)
    setMessageModalEmails(emails)
    setMessageModalPhoneNumbers(phoneNumbers)
    setIsMessageModalBatch(!!isBatch)
    setisToAndCCBlocked(!!isToAndCCBlocked)
    setIsShowingMessageModal(true)
  }

  const showRefreshModal = () => {
    setRefreshModalOpen(true)
  }

  /** @deprecated */
  const updateLocalReservation = (res: ReservationProps) => {
    const mReservations = { ...reservationStates }

    if (res.deleted_at) {
      delete mReservations[res.id]
    } else {
      mReservations[res.id] = {
        persisted: { ...res },
        updated: null,
      }
    }

    setReservationStates(mReservations)
  }

  const updateLocalReservations = (rs: ReservationProps[]) => {
    const mReservations = { ...reservationStates }

    rs.forEach((res) => {
      if (res.deleted_at) {
        // NOTE: set to null to remove from state, then delete
        mReservations[res.id] = { persisted: null, updated: null }
        delete mReservations[res.id]
      } else {
        mReservations[res.id] = {
          persisted: { ...res },
          updated: null,
        }
      }
    })

    setReservationStates(mReservations)
  }

  const showNotification = (args: {
    type: 'success' | 'warning' | 'error'
    title: string
    description?: string
    dismissAfter?: number
    onClick?: () => void
  }) => {
    setNotificationType(args.type)
    setNotificationTitle(args.title)
    setNotificationDescription(args.description)
    setNotificationDismissAfter(args.dismissAfter)
    setIsNotification(true)
    setNotificationOnClick(args.onClick)
  }

  const showReservationsConfirmation = (
    reservationStates: IReservationState[],
    customCallback?: (data: IUpdatingReservation[]) => Promise<any>
  ) => {
    showModal(
      <ConfirmationModalContent reservationStates={reservationStates} customCallback={customCallback} />,
      true,
      'max-w-6xl'
    )
  }

  return (
    <AdminContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        currentUserEmail,
        currentUserRole,
        isParkAdminUser,
        camp,
        setCamp,
        accessibleCamps,
        reloadAccessibleCamps,
        sites,
        setSites,
        campingStyles,
        setCampingStyles,
        reservationStates,
        setReservationStates,
        selectedReservation,
        setSelectedReservation,
        selectedCamper,
        setSelectedCamper,
        showRefreshModal,
        showPaymentModal,
        showMessageModal,
        showModal,
        closeModal,
        isShowingPaymentModal,
        isShowingMessageModal,
        isMessageSent,
        setIsMessageSent,
        selectedSendingMode,
        setSelectedSendingMode,
        showNotification,
        updateLocalReservation,
        updateLocalReservations,
        showReservationsConfirmation, // NOTE: used specifically for deleting reservation
        showConfirm, // NOTE: generic confirm modal\
      }}>
      <RefreshModal open={refreshModalOpen} onClose={() => setRefreshModalOpen(false)} />
      {isShowingPaymentModal && <PosModal
        open={isShowingPaymentModal}
        onClose={() => setIsShowingPaymentModal(false)}
        owedInCents={paymentModalAmountInCents}
        reservation={paymentModalReservation}
        reservations={paymentModalReservations}
        groupedReservation={paymentModalGroupedReservation}
        isDeposit={paymnentModalIsDeposit}
        // TODO: drop this when we're all in on orders
        onChangeIntentId={async (intentId) => {
          if (!intentId) return

          if (paymentModalGroupedReservation) {
            await api.GroupedReservations.updateIntent(paymentModalGroupedReservation.id, intentId)

            // TODO: update local state for all reservations in group
          } else {
            const { reservation: updatedRes } = await api.Reservations.update(
              paymentModalReservation.id,
              {
                vendor_id: intentId,
                is_manual_charge: true,
              }
            )

            updateLocalReservations([updatedRes])
          }
        }}
      />}
      <MessageModal
        open={isShowingMessageModal}
        onClose={() => {
          setMessageModalEmails([])
          setMessageModalReservations([])
          setIsShowingMessageModal(false)
        }}
        reservations={messageModalReservations}
        invoices={messageModalInvoices}
        emails={messageModalEmails}
        phoneNumbers={messageModalPhoneNumbers}
        isBatch={isMessageModalBatch}
        isToAndCCBlocked={isToAndCCBlocked}
      />
      <Modal
        open={isShowingModal}
        onClose={() => {
          setIsShowingModal(false)
          if (modalCloseCallback) modalCloseCallback()
        }}
        maxWidth={modalMaxWidth}
        isDynamicWidth={isModalDynamicWidth}>
        {modalContent}
      </Modal>
      <ConfirmModal />
      <NotificationContainer>
        <Notification
          visible={isMessageSent}
          success
          title='Message sent!'
          description='Your message was successfully sent.'
          onDismiss={() => setIsMessageSent(false)}
        />
        <Notification
          visible={isNotification}
          success={notificationType === 'success'}
          warning={notificationType === 'warning'}
          error={notificationType === 'error'}
          title={notificationTitle}
          description={notificationDescription}
          onDismiss={() => setIsNotification(false)}
          dismissAfter={notificationDismissAfter}
          onClick={notificationOnClick}
        />
      </NotificationContainer>
      {children}
    </AdminContext.Provider>
  )
}
