import { Moment } from "moment"
import { KINDS, STATUSES, RESERVATION_KINDS } from "../constants"
import { ReservationProps, UserProps } from "../interfaces"
import { validElectric } from "../types"
import { post, patch, get, destroy, transformRequestData } from "./utils/requests"
import eSendingMode from "../enums/eSendingMode"
import { ILineItemLegacy } from "../components/Admin/Invoices/EditInvoice"
import IHistoryRecord from "../interfaces/IHistoryRecord"
import { IPriceDetails } from "./price_details"
import IOrder from "../interfaces/IOrder"

export type SendInvoiceParams = {
  id: string
  recurring_price_in_cents: number
  electricity_fee_in_cents: number
  last_meter_reading: number
  meter_reading: number
  electric_used: number
  is_sending_invoice: boolean
  sending_mode: eSendingMode
  admin_email?: string
}

export type ReservationPriceDetailsProps = {
  kind: string
  start_date: string
  end_date: string
  discount_slug: string
  subtotal_override: number
  is_tax_enabled: boolean
  is_holiday_rate_enabled: boolean
  is_weekend_fee_enabled: boolean
  is_passenger_fees_enabled: boolean
  is_pets_fee_enabled: boolean
  number_of_adults: number
  number_of_kids: number
  number_of_pets: number
  user_electric: validElectric
  is_credit_card_fee_enabled: boolean
  interval_id: string
  // add_ons: IAddOn[], // NOTE: we don't use this in the reservations endpoint
  // is_service_fee_applied: boolean, // NOTE: we don't use this in the reservations endpoint
}

export type UnpaidProps = Omit<UserProps, 'license_plate'> & {
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  interval_id: string
  notes: string
  unpaid_price_in_cents?: number
  passengers: number
  kid_campers: number
  vehicle_license_plate: string
  discount_name: string
  sending_mode?: eSendingMode
  with_payment_link?: boolean
  price_details_params?: ReservationPriceDetailsProps
  deposit_in_cents?: number
  site_ids?: string[]
  is_grouped?: boolean
  is_group_separated?: boolean
  admin_email?: string
}

type PaidProps = UnpaidProps

type OnlineProps = Omit<UnpaidProps, 'total'> & {
  stripe_payment_intent_id: string
}

type BlockedProps = {
  start_date: string
  end_date: string
  interval_id: string
  notes: string
  site_ids: string
}

type RecurringProps = Omit<UnpaidProps, 'discount_name' | 'unpaid_price_in_cents'> & {
  mark_as_paid: boolean
  recurring_price_in_cents: number
  kind: string
}

type InvoiceProps = {
  water_fee_in_cents?: number
  electricity_fee_in_cents: number
  electric_used: number
  meter_reading: number
  last_meter_reading?: number
  mark_as_paid: boolean
  is_sending_invoice: boolean
  with_payment_link: boolean
  notes?: string
  invoice_memo?: string
  camper_address?: string
  items?: ILineItemLegacy[]
  admin_email?: string
}

type UpdateProps = Partial<
  Omit<ReservationProps, 'total'> & { unpaid_price_in_cents: number }
>

const createOnline = (siteId: string, data: OnlineProps, do_not_book?: boolean) =>
  post(`/sites/${siteId}/reservations`, { ...data, dry_run: do_not_book })

const createUnpaid = (siteId: string, data: UnpaidProps) =>
  post(`/sites/${siteId}/reservations`, data)

const createPaid = (siteId: string, data: PaidProps) =>
  post(`/sites/${siteId}/reservations`, { ...data, mark_as_paid: true })

const createBlocked = (siteId: string, data: BlockedProps) =>
  post(`/sites/${siteId}/reservations`, { ...data, status: STATUSES.BLOCKED })

const find = (id: string): Promise<{ reservation: ReservationProps }> =>
  get(`/api/reservations/${id}`)

const update = (id: string, data: UpdateProps): Promise<{ reservation: ReservationProps, following_recurrences?: ReservationProps[] }> =>
  patch(`/reservations/${id}`, data)

const updateMany = (ids: string[], data: UpdateProps) =>
  patch(`/admin/reservations/batch`, { ids, ...data })

type BatchEachUpdateProps = UpdateProps & {
  is_updating_series?: boolean
}

const updateEach = (data: { reservations: BatchEachUpdateProps[], sending_mode: eSendingMode, admin_email: string }): Promise<{ reservations: ReservationProps[] }> =>
  patch(`/admin/reservations/batch_each`, {
    reservations: data.reservations,
    sending_mode: data.sending_mode,
    admin_email: data.admin_email
  })

const recurrencesFor = (id: string): Promise<{ recurrences: ReservationProps[] }> =>
  get(`/api/reservations/${id}/recurrences`)

const createRecurring = (siteId: string, data: RecurringProps) =>
  post(`/sites/${siteId}/reservations/recurrings`, { ...data })

const forDates = (campId: string, startDate: Moment, endDate: Moment): Promise<{ reservations: ReservationProps[] }> =>
  get('/admin/reservations', { camp_id: campId, from: startDate.toISOString(), until: endDate.toISOString() })

const history = (id: string): Promise<{ history: IHistoryRecord[] }> =>
  get(`/api/reservations/${id}/history`)

const remove = (
  id: string,
  data?: { include_recurrences?: boolean }
): Promise<{ reservations: ReservationProps[] }> =>
  destroy(`/reservations/${id}`, { ...data })

const sendEmail = (data: {
  reservation_ids: string[]
  cc?: string[]
  from: string
  subject: string
  text: string
  is_including_invoice: boolean
  is_including_reservation_details: boolean
}) => post('/api/messages', { ...data, format: 'email' })

const Messages = {
  sendEmail
}

interface PriceDetailRegenerateParams {
  siteId: string
  startDate: Moment | string
  endDate: Moment | string
  numberOfAdults: number
  numberOfKids: number
  userElectric: validElectric
}

const regenerate = (
  id: string,
  data: PriceDetailRegenerateParams
): Promise<{ price_detail: IPriceDetails, order: IOrder }> =>
  post(`/api/reservations/${id}/price_details`, transformRequestData(data))

const PriceDetails = {
  regenerate
}

const Reservations = {
  Messages,
  PriceDetails,
  createBlocked,
  createOnline,
  createUnpaid,
  createPaid,
  createRecurring,
  find,
  forDates,
  history,
  update,
  updateMany,
  updateEach,
  recurrencesFor,
  remove,
}

export default Reservations
