import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import classNames from '../../../utils/classNames'
import { titleCase } from '../../../utils'
import HoverTip from '../HoverTip'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { INVOICE_FILTER_OPTIONS } from '../../Admin/Invoices/InvoiceFilterOptions'

export interface IOption {
  label: string
  value: string
  icon?: (...args: any) => any
}

interface ComponentProps {
  label?: string
  options: Array<IOption>
  selectedOption: IOption
  onChange?: (v: IOption) => void
}

const hovertipText = (value: string) => {
  switch (value) {
    case INVOICE_FILTER_OPTIONS.DRAFT:
      return 'Invoices that have not yet been sent'
    case INVOICE_FILTER_OPTIONS.OUTSTANDING:
      return 'Invoices that have been sent but not yet paid'
    case INVOICE_FILTER_OPTIONS.PAST_DUE:
      return 'Invoices that have been sent and payment is overdue'
    case INVOICE_FILTER_OPTIONS.PAID:
      return 'Invoices that have been successfully paid'
    case INVOICE_FILTER_OPTIONS.ALL:
      return 'List of all invoices'
  }
}

const OptionSelector = (props: ComponentProps) => {
  return (
    <RadioGroup className='' value={props.selectedOption.value}>
      <RadioGroup.Label className='text-base text-gray-900'>{props.label}</RadioGroup.Label>

      <div className='flex flex-row gap-4 flex-wrap max-w-screen'>
        {props.options.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.value}
            onClick={() => props.onChange(option)}
            className={({ checked, active }) =>
              classNames(
                'transition-colors duration-100 hover:border-gray-400',
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-1 ring-green-600' : '',
                'flex-grow relative bg-white border rounded-lg shadow-sm flex cursor-pointer focus:outline-none',
                window.screen.width === 425 ? 'px-1.5 py-2.5' : 'p-2.5' 
              )
            }>
            {({ checked, active }) => (
              <>
                <div className='flex-1 flex justify-start w-28'>
                  <div className='flex items-center text-center justify justify-items-center'>
                    {option.icon && (
                      <option.icon
                        className={classNames(
                          'w-12 h-12 self-center stroke-current',
                          checked ? 'text-green-600' : 'text-gray-900'
                        )}
                      />
                    )}
                    <RadioGroup.Label
                      as='span'
                      className={classNames(
                        'flex text-sm ml-3',
                        checked ? 'text-green-600 font-bold' : 'text-gray-900 font-medium'
                      )}>
                      {titleCase(option.label)}
                      <HoverTip content={hovertipText(option.value)}>
                        <InformationCircleIcon className='w-3.5 h-3.5 ml-0.5 hidden md:block' />
                      </HoverTip>
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    'h-5 w-5 text-green-600 absolute right-1.5 top-1',
                    !checked ? 'invisible' : ''
                  )}
                  aria-hidden='true'
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-green-600' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}


export default OptionSelector
