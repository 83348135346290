import moment from 'moment'
import React, { useContext } from 'react'
import { ReservationProps } from '../interfaces'
import { AdminContext } from '../contexts/AdminContext'
import { formatMoney } from '../utils/formatMoney'

interface ReservationStateProps {
  persisted: ReservationProps
  updated?: ReservationProps
}

const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export default function UpdatedReservationsTable({ updatedReservationStates }: { updatedReservationStates: ReservationStateProps[] }) {
  const { sites, camp } = useContext(AdminContext)

  return (
    <>
      <p className='text-lg text-gray-500 mb-3 mt-1'>
        You're updating a reservation for the following camper{updatedReservationStates.length > 1 && 's'}:
      </p>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='overflow-hidden border border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Camper
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Site
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Arrival
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Departure
                    </th>
                    <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Amount Owed
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {updatedReservationStates.map(({ persisted, updated }) => {
                    if (!updated) return <></>
                    if (updated.status != 'blocked')
                      return (
                        <tr key={updated.user?.email}>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            <div className='flex flex-col'>
                              <div className='font-medium text-gray-900'>
                                <span>
                                  {updated.user?.first_name + ' ' + updated.user?.last_name}
                                </span>
                              </div>
                              <div className='text-gray-500 text-xssm leading-4'>
                                <span>{updated.user?.email}</span>
                              </div>
                              <div className='text-gray-500 text-xssm leading-4'>
                                <span>{updated.user?.phone}</span>
                              </div>
                            </div>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {persisted.site_id != updated.site_id && (
                              <span className='text-red-600 line-through mr-2'>
                                {sites.find((s) => s.id === persisted.site_id)?.name}
                              </span>
                            )}
                            <span>{sites.find((s) => s.id === updated.site_id)?.name}</span>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {!moment(persisted.start_date).isSame(updated.start_date, 'd') && (
                              <span className='text-red-600 line-through mr-2'>
                                {moment(persisted.start_date).format('M/D/YY')}
                              </span>
                            )}
                            <span className='mr-2'>
                              {moment(updated.start_date).format('M/D/YY')}
                            </span>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {!moment(persisted.end_date).isSame(updated.end_date, 'd') && (
                              <span className='text-red-600 line-through mr-2'>
                                {moment(persisted.end_date).format('M/D/YY')}
                              </span>
                            )}
                            <span className='mr-2'>
                              {moment(updated.end_date).format('M/D/YY')}
                            </span>
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {persisted.total_owed_in_cents != updated.total_owed_in_cents && (
                              <span className='text-red-600 line-through mr-2'>
                                {formatMoney(persisted.total_owed_in_cents / 100, camp.currency)}
                              </span>
                            )}
                            <span>
                              {formatMoney(updated.total_owed_in_cents / 100, camp.currency)}
                            </span>
                          </td>
                        </tr>
                      )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
