import { CAMPING_STYLES } from '../constants'
import { validCampingStyles } from '../types'
import titleCase from './titleCase'

const formatCampingStyleName = (name: validCampingStyles | string, plural?: boolean) => {
  const formattedName = name?.replace(/-/g, ' ') || ''

  switch (formattedName) {
    case CAMPING_STYLES.RV:
      return 'RV Site' + (plural ? 's' : '')
    case CAMPING_STYLES.TENT:
      return 'Tent Site' + (plural ? 's' : '')
    case CAMPING_STYLES.STORAGE:
      return 'Storage unit' + (plural ? 's' : '')
    case CAMPING_STYLES.RV_STORAGE:
      return 'RV Storage'
    case CAMPING_STYLES.RENTAL_RV:
      return 'Rental RV' + (plural ? 's' : '')
    default:
      return titleCase(formattedName) + (plural ? 's' : '')
  }
}

export default formatCampingStyleName
