import React, { FormEventHandler, Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarDaysIcon, EnvelopeIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid'
import classNames from '../../utils/classNames'
import Button from './Button'
import { ReservationProps, SiteProps } from '../../interfaces'
import moment from 'moment'
import { STATUSES } from '../../constants'
import { EditReservationContext } from '../../contexts/admin/EditReservationContext'
import {
  StatusBlocked,
  StatusDeleted,
  StatusLocked,
  StatusPaid,
  StatusUnpaid,
  StatusImported,
} from './StatusBadges'
import { formatDateRange } from '../../utils/formatDateRange'
import { AdminContext } from '../../contexts/AdminContext'
import DeleteConfirmationModalContent from '../Admin/Reservations/DeleteConfirmationModalContent'
import api from '../../api2'
import { HashtagIcon } from '@heroicons/react/20/solid'
import Spinner from './Spinner'
import IInvoice from '../../interfaces/IInvoice'
import IOrder from '../../interfaces/IOrder'

interface ComponentProps {
  open: boolean
  setOpen: (v: boolean) => void
  header: string
  content: JSX.Element | JSX.Element[]
  onSubmit?: FormEventHandler
  isProcessing: boolean
  width?: 'max-w-xl' | 'max-w-2xl'
  reservation?: ReservationProps
}

const ReservationSlideoverForm = (props: ComponentProps) => {
  const { camp, showModal, updateLocalReservations, showNotification, currentUserRole } =
    useContext(AdminContext)
  const { onUpdateReservation, refUpdateReservationForm } = useContext(EditReservationContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hashId, setHashId] = useState<string>(props.reservation?.hashed_id)
  const [order, setOrder] = useState<IOrder>(null)
  const [invoice, setInvoice] = useState<IInvoice>(null)
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false)
  const [isLoadingOrder, setIsLoadingOrder] = useState(false)

  useEffect(() => {
    if (!props.reservation) return

    if (props.reservation.order_id) {
      setIsLoadingOrder(true)
      api.Orders.fetch(props.reservation?.order_id)
        .then(({ order }) => {
          setOrder(order)
          setIsLoadingOrder(false)
        })
        .catch((err) => {
          console.error(`Error loading order for reservation ${props.reservation.id}`, err)
          setIsLoadingOrder(false)
        })
    }

    setHashId(props.reservation.hashed_id)
    setIsLoadingInvoice(true)

    if (!props.reservation.start_date) return

    api.Invoices.fetchForDates(
      camp.id,
      {
        from: moment(props.reservation.start_date).subtract(1, "day"),
        until: moment(props.reservation.start_date).add(1, "day"),
        filter: 'reservation_start_date',
        includeArchived: true,
      }
    )
      .then(({ invoices }) => {
        const invoice = invoices.find(i => i.order_id === props.reservation.order_id)
        if (invoice) {
          setInvoice(invoice)
          setHashId(invoice.hash_id)
        }
        setIsLoadingInvoice(false)
      })
      .catch((err) => {
        console.error(`Error loading invoice for reservation ${props.reservation.id}`, err)
        setIsLoadingInvoice(false)
      })

    setHashId(props.reservation.hashed_id)
  }, [props.reservation])

  const handleRemove = async () => {
    // TODO: for some reason this sometimes happens 🤔 We should remove this and apply confirm
    if (!props.reservation) return

    if (props.reservation.status !== STATUSES.BLOCKED) {
      showModal(<DeleteConfirmationModalContent reservation={props.reservation} />)
    } else {
      setIsLoading(true)
      api.Reservations.remove(props.reservation.id)
        .then(({ reservations }) => {
          updateLocalReservations(reservations)
          setIsLoading(false)
        })
        .catch((error) => {
          showNotification({
            type: 'error',
            title: 'Error removing reservation',
            description: error.message,
          })
          console.error(error)
          setIsLoading(false)
        })
    }
  }

  const handleClickRestore = async () => {
    if (!props.reservation) return

    setIsLoading(true)
    api.Reservations.update(props.reservation.id, { deleted_at: null })
      .then(({ reservation }) => {
        updateLocalReservations([reservation])
        setIsLoading(false)
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          title: 'Error restoring reservation',
          description: error.message,
        })
        console.error(error)
        setIsLoading(false)
      })
  }

  if(!props.open) return <></>

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as='div' className='relative z-30' onClose={props.setOpen}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div
              className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16'
              style={{ marginTop: '0px' }}>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'>
                <Dialog.Panel
                  className={classNames('pointer-events-auto w-screen', props.width || 'max-w-md')}>
                  <form
                    ref={refUpdateReservationForm}
                    className='flex h-full flex-col bg-white shadow-xl'
                    onSubmit={onUpdateReservation}>
                    <div className='h-0 flex-1 overflow-y-scroll'>
                      <div
                        className={classNames(
                          'pt-6 pb-4 px-4 sm:px-6',
                          props.reservation?.deleted_at ? 'bg-red-50' : 'bg-gray-100'
                        )}>
                        <div className='flex items-center justify-between'>
                          <Dialog.Title
                            className={classNames(
                              'text-2xl font-semibold',
                              props.reservation?.deleted_at && 'line-through'
                            )}>
                            {props.header}
                          </Dialog.Title>
                          <div className='flex h-8 items-center'>
                            <button
                              type='button'
                              className='rounded-lg bg-white hover:bg-gray-200 p-1 focus:outline-none'
                              onClick={() => props.setOpen(false)}>
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon className='h-7 w-7' aria-hidden='true' />
                            </button>
                          </div>
                        </div>
                        <div className='flex items-center mt-2 whitespace-nowrap'>
                          <div className='flex items-center text-gray-600'>
                            <MapPinIcon className='w-5 h-5 text-gray-600 mr-1 xs:mr-2' />
                            <div className='font-medium'>{props.reservation?.site.name}</div>
                          </div>
                          <div className='flex items-center text-gray-600 ml-4 sm:ml-6'>
                            <CalendarDaysIcon className='w-5 h-5 text-gray-600 mr-1 xs:mr-2' />
                            <div className=''>
                              <span className='font-medium'>
                                {formatDateRange(
                                  props.reservation?.start_date,
                                  props.reservation?.end_date
                                )}
                              </span>
                            </div>
                          </div>
                          <div className='ml-4 sm:ml-6 font-medium'>
                            {props.reservation?.deleted_at ? (
                              <StatusDeleted
                                deleted_at={moment(props.reservation?.deleted_at).fromNow()}
                              />
                            ) : props.reservation?.status === STATUSES.UNPAID ? (
                              <StatusUnpaid />
                            ) : props.reservation?.status === STATUSES.PAID ? (
                              <StatusPaid refundedAmountInCents={order?.amount_refunded_in_cents} />
                            ) : [STATUSES.IMPORTED, 'blocked-import'].includes(
                                props.reservation?.status
                              ) ? (
                              <StatusImported />
                            ) : (
                              <StatusBlocked />
                            )}
                          </div>
                          {props.reservation?.is_locked && (
                            <div className='ml-4 sm:ml-6 font-medium'>
                              <StatusLocked />
                            </div>
                          )}
                          {hashId && (
                            <div className='flex items-center text-gray-600 ml-4 sm:ml-6'>
                              {isLoadingInvoice ? (
                                <div className='mr-0.5'>
                                  <Spinner size={5} />
                                </div>
                              ) : (
                                <HashtagIcon className='w-5 h-5 text-gray-600 mr-0.5' />
                              )}
                              <a
                                className={classNames(
                                  'font-medium',
                                  isLoadingInvoice
                                    ? 'text-gray-500 pointer-events-none'
                                    : 'hover:underline cursor-pointer'
                                )}
                                onClick={() => !isLoadingInvoice && window.open(`/i/${hashId}`)}>
                                {hashId}
                              </a>
                            </div>
                          )}
                          <div className='ml-4 sm:ml-6 font-medium hidden xs:block'>
                            {props.reservation?.kind === 'recurring' &&
                              (props.reservation?.invoice?.send_date || invoice?.send_date) && (
                                <div className='flex items-center justify-start text-gray-600'>
                                  <div className='flex items-center'>
                                    <EnvelopeIcon className='w-5 h-5 flex-shrink-0 mr-1 xs:mr-2' />
                                    <span className=''>Invoice sent</span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className='flex justify-between mt-5 text-sm text-gray-500'>
                          <span className='mr-4 italic'>
                            Created{' '}
                            {moment(props.reservation?.created_at).format(
                              `${camp.date_format} h:mma`
                            )}
                          </span>
                          <span className='italic'>
                            Last edited {moment(props.reservation?.updated_at).fromNow()}
                          </span>
                        </div>
                      </div>
                      <div className='flex flex-1 flex-col justify-between'>
                        <div className='divide-y divide-gray-200 px-4 sm:px-6'>{props.content}</div>
                      </div>
                    </div>
                    <div className='flex flex-shrink-0 justify-between items-center px-4 sm:px-6 py-4 gap-4'>
                      {currentUserRole != 'view-only' && (
                        <>
                          <div className=''>
                            {props.reservation?.deleted_at ? (
                              <Button
                                variant='blue'
                                type='button'
                                disabled={isLoading}
                                onClick={handleClickRestore}
                                className='mr-auto w-28'>
                                Restore
                              </Button>
                            ) : (
                              <Button
                                variant='red'
                                type='button'
                                disabled={isLoading}
                                onClick={handleRemove}
                                className='mr-auto w-28'>
                                Remove
                              </Button>
                            )}
                          </div>
                          <div className='flex items-center gap-4'>
                            <Button
                              type='button'
                              variant='gray'
                              className={classNames(
                                'w-28',
                                props.isProcessing && 'pointer-events-none opacity-60'
                              )}
                              onClick={() => props.setOpen(false)}
                              disabled={
                                props.isProcessing || isLoading || !!props.reservation?.deleted_at
                              }>
                              Cancel
                            </Button>
                            <Button
                              type='submit'
                              variant='green'
                              className={classNames(
                                'w-28 mr-4',
                                props.isProcessing && 'pointer-events-none opacity-60'
                              )}
                              disabled={
                                props.isProcessing || isLoading || !!props.reservation?.deleted_at
                              }>
                              Save
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ReservationSlideoverForm
