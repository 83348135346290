import React, { useState, useEffect } from 'react'
import { Card, Header, Transition } from 'semantic-ui-react'
import { Moment } from 'moment'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'

import CheckoutForm from './CheckoutForm'
import PriceDetails from './PriceDetails'

import { CampingStyleProps, CampProps, SiteProps } from '../../interfaces'
import { discountType } from '../../constants'
import { validElectric, validRigType } from '../../types'

// TODO: move these into environmental variables
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBKEY

interface ComponentProps {
  camp: CampProps
  camping_style: CampingStyleProps
  site: SiteProps
  startDate: Moment
  endDate: Moment
  visible: boolean
  setIsPaying: any
  userRigType: validRigType
  userRigLength: number
  userElectric: validElectric
  userPassengers: number
  userKidCampers: number
  onError: (errorMessage: string) => void
}

const Payment = (props: ComponentProps) => {
  const [discountType, setDiscountType] = useState<discountType>()
  const [discountId, setDiscountId] = useState<string>()
  const [stripePromise, setStripePromise] = useState<Promise<Stripe>>()
  const nights = props.startDate && props.endDate && props.endDate.hour(12).diff(props.startDate.hour(12), 'days')

  useEffect(() => {
    // TODO: this publick key will always be in the FE code, but it'd be nice to add
    // it as an ENV to get it out of the codebase directly.
    setStripePromise(
      loadStripe(
        stripePublicKey,
        { stripeAccount: props.camp.stripe_account_id }
      )
    )
  }, [])

  useEffect(() => {
    if (props.camp.max_discounted_nights &&
        props.startDate &&
        props.endDate &&
        props.endDate.diff(props.startDate, 'days') > props.camp.max_discounted_nights) {
      setDiscountType(null)
      setDiscountId('')
    }
  }, [props.startDate, props.endDate])

  if (!stripePromise) return <></>

  return (
    <Transition visible={props.visible} animation='fade right'>
      <div>
        <h2 className='text-lg font-bold mb-2' id='payment-header'>
          Price Details
        </h2>
        <PriceDetails
          camp={props.camp}
          selectedSite={props.site}
          nights={nights}
          reservationStartDate={props.startDate}
          reservationEndDate={props.endDate}
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountId={discountId}
          setDiscountId={setDiscountId}
          userPassengers={props.userPassengers}
          userKidCampers={props.userKidCampers}
        />

        <Header as='h2'>Payment Details</Header>

        <Elements stripe={stripePromise}>
          <CheckoutForm
            camp={props.camp}
            site={props.site}
            startDate={props.startDate}
            endDate={props.endDate}
            setIsPaying={props.setIsPaying}
            discountType={discountType}
            discountId={discountId}
            userRigType={props.userRigType}
            userRigLength={props.userRigLength}
            userElectric={props.userElectric}
            userPassengers={props.userPassengers}
            userKidCampers={props.userKidCampers}
            onError={props.onError}
          />
        </Elements>
      </div>
    </Transition>
  )
}

export default Payment
