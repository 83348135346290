import React from 'react'
import { RadioGroup } from '@headlessui/react'
import classNames from '../../utils/classNames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { CAMPING_STYLES } from '../../constants'
import { validCampingStyles } from '../../types'

import Icon from '../../icon'
import formatCampingStyleName from '../../utils/formatCampingStyleName'
import ExternalSvg from './ExternalSvg'
import { CampingStyleProps } from '../../interfaces'
import * as Sentry from '@sentry/react'

interface ComponentProps {
  label?: string
  campingStyles: CampingStyleProps[]
  selectedCampingStyle: CampingStyleProps
  setCampingStyle: (v: CampingStyleProps) => void
  campColor?: string
}

const CampingStyleSelector = (props: ComponentProps) => {
  return (
    <div className='flex-col'>
      <div className='text-base text-gray-900 w-full'>{props.label}</div>
      <RadioGroup
        className='flex-grow w-screen max-w-2xl overflow-x-auto'
        value={props.selectedCampingStyle.id}
        onChange={(id) => {
          const cs = props.campingStyles.find((cs) => id === cs.id)
          props.setCampingStyle(cs)
        }}>
        <div className='mt-4 flex flex-row gap-4 pb-1 px-px'>
          {props.campingStyles.map((cs, index) => (
            <RadioOption key={index} campingStyle={cs} color={props.campColor} />
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

const RadioOption = ({ campingStyle, color }: { campingStyle: CampingStyleProps; color: string }) => (
  <RadioGroup.Option
    value={campingStyle.id}
    className={({ checked, active }) =>
      classNames(
        checked ? 'border-transparent' : 'border-gray-300',
        'flex-grow',
        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
      )
    }>
    {({ checked, active }) => (
      <>
        <div className='flex-1 flex justify-center'>
          <div className='flex flex-col text-center justify-items-center items-center'>
            <CampingStyleIcon
              campingStyle={campingStyle}
              style={checked ? { color: color } : { color: 'rgb(17, 24, 39)' }}
            />
            <RadioGroup.Label
              as='span'
              className={classNames(
                'block text-sm',
                checked ? 'font-bold fill-current' : 'font-medium'
              )}
              style={checked ? { color: color } : { color: 'rgb(17, 24, 39)' }}>
              {formatCampingStyleName(campingStyle.name)}
            </RadioGroup.Label>
          </div>
        </div>
        <CheckCircleIcon
          className={classNames('h-5 w-5 absolute right-4', !checked ? 'invisible' : '')}
          aria-hidden='true'
          style={checked ? { color: color } : { color: 'rgb(17, 24, 39)' }}
        />
        <div
          className={classNames('absolute -inset-px rounded-lg pointer-events-none border-2')}
          style={checked ? { borderColor: color } : { borderColor: 'transparent' }}
          aria-hidden='true'
        />
      </>
    )}
  </RadioGroup.Option>
)

const svgForName = (name: validCampingStyles) => {
  switch (name) {
    case CAMPING_STYLES.RV:
      return Icon.Rv
    case CAMPING_STYLES.TENT:
      return Icon.Tent
    case CAMPING_STYLES.CABIN:
      return Icon.Cabin
    case CAMPING_STYLES.BOAT_RENTAL:
      return Icon.BoatRental
    case CAMPING_STYLES.BOAT_SLIP:
      return Icon.BoatSlip
    case CAMPING_STYLES.MOBILE_HOME:
      return Icon.MobileHome
    case CAMPING_STYLES.STORAGE:
      return Icon.Storage
    case CAMPING_STYLES.PAVILION:
    case CAMPING_STYLES.SHELTER:
      return Icon.Pavilion
    case CAMPING_STYLES.CABANA:
      return Icon.Cabana
    case CAMPING_STYLES.GARAGE:
      return Icon.Garage
    case CAMPING_STYLES.HORSE_PEN:
      return Icon.HorsePen
    case CAMPING_STYLES.HORSE_STALL:
      return Icon.HorseStall
    case CAMPING_STYLES.RV_STORAGE:
      return Icon.RvStorage
    case CAMPING_STYLES.EVENT_CENTER:
      return Icon.EventCenter
    case CAMPING_STYLES.RENTAL_RV:
      return Icon.RentalRv
    case CAMPING_STYLES.LODGE:
      return Icon.Lodge
    case CAMPING_STYLES.GOLF_CART:
      return Icon.GolfCart
    case CAMPING_STYLES.POOL:
      return Icon.Pool
    case CAMPING_STYLES.VENDOR_BOOTH:
      return Icon.VendorBooth
    case CAMPING_STYLES.DISC_GOLF:
      return Icon.DiscGolf
    case CAMPING_STYLES.DRY_CAMPING:
      return Icon.DryCamping
    case CAMPING_STYLES.DOME:
      return Icon.Dome
    case CAMPING_STYLES.GLAMPING:
      return Icon.Glamping
    case CAMPING_STYLES.HOME:
      return Icon.Home
    case CAMPING_STYLES.LODGING:
      return Icon.Lodging
    case CAMPING_STYLES.BONFIRE:
      return Icon.Bonfire
    case CAMPING_STYLES.TRUCK:
      return Icon.Truck
    case CAMPING_STYLES.GROUP:
      return Icon.Group
    case CAMPING_STYLES.COTTAGE:
      return Icon.Cottage
    case CAMPING_STYLES.MAIN_HOUSE:
      return Icon.MainHouse
    case CAMPING_STYLES.FOOD_TRUCK:
      return Icon.FoodTruck
    case CAMPING_STYLES.MISC:
      return Icon.Misc
    case CAMPING_STYLES.ENSUITE_UNIT:
      return Icon.EnsuiteUnit
    case CAMPING_STYLES.UNPOWERED_SITE:
      return Icon.UnpoweredSite
    case CAMPING_STYLES.TIPI:
      return Icon.Tipi
    case CAMPING_STYLES.KAYAK:
      return Icon.Kayak
    case CAMPING_STYLES.VAN:
      return Icon.Van
    case CAMPING_STYLES.ROOM:
      return Icon.Room
    case CAMPING_STYLES.GENERATOR:
      return Icon.Generator
    case CAMPING_STYLES.RENTAL_ITEM:
      return Icon.RentalItem
    case CAMPING_STYLES.PICNIC_AREA:
      return Icon.PicnicArea
    case CAMPING_STYLES.SEASONAL_RV:
      return Icon.Rv
    case CAMPING_STYLES.KITCHEN:
      return Icon.Kitchen
    case CAMPING_STYLES.PLATFORM:
      return Icon.Platform
    case CAMPING_STYLES.CANVAS_TENT:
      return Icon.CanvasTent
    default:
      console.error('Missing icon for:', name)
      return null
  }
}

export const CampingStyleIcon = ({ campingStyle, style, isFilled }: { campingStyle: CampingStyleProps; style: any; isFilled?: boolean }) => {
  try {
    if (campingStyle.icon_url) {
      if (isFilled) {
        return <ExternalSvg url={campingStyle.icon_url} style={style} />
      } else {
        return <ExternalSvg url={campingStyle.icon_url} style={style} />
      }
    } else {
      console.log('iconUrl not provided, using name to use local icon', campingStyle.name)
      let IconSvg = svgForName(campingStyle.name)
      if (!IconSvg) {
        throw new Error('Icon not found')
      }
      return <IconSvg style={style} />
    }
  } catch(err) {
    Sentry.captureMessage('Error rendering camping style icon', {
      contexts: {
        info: {
          camping_style_id: campingStyle.id,
          camping_style_name: campingStyle.name,
          camping_style_icon_url: campingStyle.icon_url,
          camp_id: campingStyle.camp_id
        }
      }
    })
    const IconSvg = svgForName(CAMPING_STYLES.MISC)
    return <IconSvg style={style} />
  }
}

export default CampingStyleSelector
