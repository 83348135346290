import React, { useEffect } from "react"
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { titleCase } from "../../utils";

Chart.register(CategoryScale);

interface componentProps {
  ReservationsData: string
  setErrorMessage
}

const CampStatistics = (props: componentProps) => {
  const monthlyGrossData = {
    label: "Gross",
    backgroundColor: "hsl(217, 57%, 51%)",
    borderColor: "hsl(217, 57%, 51%)",
    fill: false,
    tension: 0.4,
    data: props.ReservationsData["gross_revenue_monthly"]["total_paid_by_day"]
  }

  const monthlyNetData = props.ReservationsData["net_revenue_monthly"] != null ? {
    label: "Net",
    borderColor: "rgb(255, 159, 64)",
    backgroundColor: "rgb(255, 159, 64)",
    fill: false,
    tension: 0.4,
    data: props.ReservationsData["net_revenue_monthly"]["total_paid_by_day"]
  } : null

  const yearlyGrossData = {
    label: "Gross",
    backgroundColor: "hsl(217, 57%, 51%)",
    borderColor: "hsl(217, 57%, 51%)",
    data: props.ReservationsData["gross_revenue_yearly"]["total_paid_by_month"]
  }

  const yearlyNetData = props.ReservationsData["net_revenue_yearly"] != null ? {
    label: "Net",
    backgroundColor: "rgb(255, 159, 64)",
    borderColor: "rgb(255, 159, 64)",
    data: props.ReservationsData["net_revenue_yearly"]["total_paid_by_month"]
  } : null

  const montlhyData = {
    labels: props.ReservationsData["gross_revenue_monthly"]["labels"],
    datasets: props.ReservationsData["net_revenue_monthly"] != null ? [monthlyGrossData, monthlyNetData] : [monthlyGrossData]
  }

  const yearlyData = {
    labels: props.ReservationsData["gross_revenue_yearly"]["labels"],
    datasets: props.ReservationsData["net_revenue_yearly"] != null ? [yearlyGrossData, yearlyNetData] : [yearlyGrossData]
  }

  const byCampData: Record<string, {
    gross_revenue: number
    net_revenue: number
    through_park: number
    total_reservations: number
    total_reservations_through_park: number
    total_sites: number
  }> = props.ReservationsData["by_camp"]

  return (
    <div className='md:px-8 lg:px-0'>
      <div className="chart-container md:w-full lg:w-1/3 mx-auto">
        <h2 style={{ textAlign: "center" }}>
          Revenue - last 30 days
        </h2>
        <h2 style={{ textAlign: "center" }}>
          Gross: ${props.ReservationsData["gross_revenue_monthly"]["total_paid_last_month"].toLocaleString()}
        </h2>
        {props.ReservationsData["net_revenue_monthly"] != null && (
        <h2 style={{ textAlign: "center" }}>
          Net: ${props.ReservationsData["net_revenue_monthly"]["total_paid_last_month"].toLocaleString()}
        </h2>
        )}
        <Line
          data={montlhyData}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: props.ReservationsData["net_revenue_monthly"] != null ? true : false
              },
            },
          }}
        />
  
        <h2 style={{ textAlign: "center" }}>
          Revenue - year to date
        </h2>
        <h2 style={{ textAlign: "center" }}>
          Gross: ${props.ReservationsData["gross_revenue_yearly"]["total_paid_last_year"].toLocaleString()}
        </h2>
        {props.ReservationsData["net_revenue_yearly"] != null && (
        <h2 style={{ textAlign: "center" }}>
          Net: ${props.ReservationsData["net_revenue_yearly"]["total_paid_last_year"].toLocaleString()}
        </h2>
        )}
        <Line
          data={yearlyData}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: props.ReservationsData["net_revenue_yearly"] != null ? true : false
              },
            },
          }}
        />
      </div>

      <div className='md:w-full lg:w-1/2 mx-auto'>
        {props.ReservationsData["net_revenue_by_camp"] != null && props.ReservationsData["net_revenue_by_camp"] != null && (
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Camp
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Gross revenue
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Revenue through park
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Park net revenue
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      # of sites
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      # of reservations
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      # of reservations through park
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Object.entries(byCampData)
                    .sort(([a, aData], [b, bData]) => bData.net_revenue - aData.net_revenue)
                    .map(([campSlug, data]) => (
                    <tr key={campSlug}>
                      <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {titleCase(campSlug)}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${data.gross_revenue.toLocaleString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${data.through_park.toLocaleString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${data.net_revenue.toLocaleString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        {data.total_sites.toLocaleString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        {data.total_reservations.toLocaleString()}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                        {data.total_reservations_through_park.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CampStatistics
