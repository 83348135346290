import { ClockIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import classNames from '../../utils/classNames'
import Modal from '../ui/Modal'

interface ComponentProps {
  open: boolean
  onClose: () => void
}

const RefreshModal = ({ open, onClose }: ComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const onRefresh = () => {
    setIsLoading(true)
    window.location.reload()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-14 sm:w-14">
            <ExclamationTriangleIcon className="h-8 w-8 text-yellow-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-xl font-semibold leading-6 text-gray-700">
              Something went wrong
            </h3>
            <div className="mt-4">
              <p className="text-gray-500">
                You may be using an outdated version of Park. Please refresh the page to update and try again. If the problem persists, please contact support.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className={classNames(
              "inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto",
              isLoading && "opacity-50 cursor-not-allowed"
            )}
            onClick={onRefresh}
            disabled={isLoading}
          >
            Refresh
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Dismiss
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RefreshModal
