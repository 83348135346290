import React, { useContext, useState } from 'react'
import Spinner from '../../ui/Spinner'
import { formatMoney, formatMoneyFromCents } from '../../../utils/formatMoney'
import SummaryStat from './SummaryStat'
import { formatName, formatNameFromFirstLast } from '../../../utils/formatName'
import moment from 'moment'
import { AdminContext } from '../../../contexts/AdminContext'

type iReservationReport = {
  arrival: Date
  departure: Date
  created_at: Date
  name: string
  site: string
  owed: number
  paid: number
  taxes: number
  credit_card_fees_collected: number
  service_fee: number
  total_collected: number
  stripe_fee: number
  net_received: number
  status: string
  payment_mode: string
  type: string
  duration: string
  email: string
  phone: string
  notes: string
  site_type: string
  adults: number
  kids: number
  discount_type: string
}

type iReservationSummaryReport = {
  amount_of_reservations: number
  total_net_received: number
  taxes_collected: number
}
interface ComponentProps {
  data: iReservationReport[]
  summary: iReservationSummaryReport
}

const ReservationsTable = (props: ComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { camp, campingStyles } = useContext(AdminContext)

  const isCollectingTax = campingStyles?.map((style) => style.tax_rate > 0).includes(true)
  const isCollectingCreditCardFee = camp.camping_styles?.map((style) => style.credit_card_rate > 0).includes(true)

  return (
    <div className='relative'>
      <div className='sm:flex sm:items-center'>
        {isLoading && (
          <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-start pt-72 justify-center'>
            <Spinner size={12} />
          </div>
        )}
        <div className='sm:flex-auto'></div>
      </div>
      <div className='flow-root'>
        <div className='overflow-y-visible sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='flex gap-5'>
              <SummaryStat
                label='Total # of reservations'
                value={props.summary && props.summary.amount_of_reservations}
              />
              <SummaryStat
                label='Total Net Received'
                value={props.summary && formatMoney(props.summary.total_net_received)}
              />
              {isCollectingTax && (
                <SummaryStat
                  label='Taxes Collected'
                  value={props.summary && formatMoney(props.summary.taxes_collected)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='overflow-y-visible overflow-x-scroll sm:-mx-6 lg:-mx-8 w-screen'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='relative'>
              <table className='min-w-full table-auto divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='pr-4 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Arrival
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Departure
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Created at
                    </th>
                    <th
                      scope='col'
                      className='pl-3 pr-16 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Name
                    </th>
                    <th
                      scope='col'
                      className='pl-3 pr-6 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Site
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Owed
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Paid
                    </th>
                    {isCollectingTax && (
                      <th
                        scope='col'
                        className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                        Taxes collected
                      </th>
                    )}
                    {isCollectingCreditCardFee && (
                      <th
                        scope='col'
                        className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                        Credit Card Fees Collected
                      </th>
                    )}
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Service Fee
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Total Collected
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Stripe Fee
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Net received
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Status
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Payment Mode
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Type
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Duration
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Email
                    </th>
                    <th
                      scope='col'
                      className='pl-3 pr-20 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Phone
                    </th>
                    <th
                      scope='col'
                      className='pl-3 pr-8 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Site type
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Adults
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-right text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Kids
                    </th>
                    <th
                      scope='col'
                      className='pl-3 py-3.5 text-left text-base font-semibold text-gray-900 whitespace-nowrap'>
                      Discount
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {props.data &&
                    props.data.map((siteData, index) => {
                      const {
                        arrival,
                        departure,
                        created_at,
                        owed,
                        paid,
                        taxes,
                        net_received,
                        credit_card_fees_collected,
                        service_fee,
                        total_collected,
                        stripe_fee,
                        status,
                        payment_mode,
                        type,
                        duration,
                        site,
                        name,
                        email,
                        phone,
                        site_type,
                        adults,
                        kids,
                        discount_type,
                      } = siteData

                      return (
                        <tr className='h-10' key={index}>
                          <td className='text-left font-bold'>
                            {moment(arrival).format('MM-DD-YY')}
                          </td>
                          <td className='pl-3 text-left text-gray-500'>
                            {moment(departure).format('MM-DD-YY')}
                          </td>
                          <td className='pl-3 text-left text-gray-500'>
                            {moment(created_at).format('MM-DD-YY')}
                          </td>
                          <td className='pl-3 text-left text-gray-500'>{name}</td>
                          <td className='pl-3 text-left text-gray-500'>{site}</td>
                          <td className='pl-3 text-right text-gray-500'>
                            {owed > 0 ? formatMoney(owed) : '-'}
                          </td>
                          <td className='pl-3 text-right text-gray-500'>
                            {paid > 0 ? formatMoney(paid) : '-'}
                          </td>
                          {isCollectingTax && (
                            <td className='pl-3 text-right text-gray-500'>
                              {taxes > 0 ? formatMoney(taxes) : '-'}
                            </td>
                          )}
                          {isCollectingCreditCardFee && (
                            <td className='pl-3 text-right text-gray-500'>
                              {credit_card_fees_collected > 0
                                ? formatMoney(credit_card_fees_collected)
                                : '-'}
                            </td>
                          )}
                          <td className='pl-3 text-right text-gray-500'>
                            {service_fee > 0 ? formatMoney(service_fee) : '-'}
                          </td>
                          <td className='pl-3 text-right text-gray-500'>
                            {total_collected > 0 ? formatMoney(total_collected) : '-'}
                          </td>
                          <td className='pl-3 text-right text-gray-500'>
                            {stripe_fee > 0 ? formatMoney(stripe_fee) : '-'}
                          </td>
                          <td className='pl-3 text-right text-gray-500'>
                            {net_received > 0 ? formatMoney(net_received) : '-'}
                          </td>
                          <td className='pl-3 text-left text-gray-500'>{status}</td>
                          <td className='pl-3 text-left text-gray-500'>{payment_mode}</td>
                          <td className='pl-3 text-left text-gray-500'>{type}</td>
                          <td className='pl-3 text-left text-gray-500'>{duration}</td>
                          <td className='pl-3 text-left text-gray-500'>{email}</td>
                          <td className='pl-3 text-left text-gray-500'>{phone}</td>
                          <td className='pl-3 text-left text-gray-500'>{site_type}</td>
                          <td className='pl-3 text-right text-gray-500'>
                            {adults > 0 ? adults : '-'}
                          </td>
                          <td className='pl-3 text-right text-gray-500'>{kids > 0 ? kids : '-'}</td>
                          <td className='pl-3 text-left text-gray-500'>{discount_type}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservationsTable
