import React, { useState } from 'react'
import { Card, Divider, Form } from 'semantic-ui-react'
import api from '../../api'
import CheckboxWithLabel from '../ui/CheckboxWithLabel'
import { set } from 'lodash'

interface componentProps {
  campSlug: string
  setErrorMessage
}

const SignupForm = (props: componentProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isCheckedTerms, setIsCheckedTerms] = useState(false)


  const queryParams = new URLSearchParams(decodeURIComponent(window.location.search))
  const role = queryParams.get('role') || 'admin'

  const handleEmailChange = (e, { value }) => {
    setEmail(value)
  }

  const emailIsValid = () => {
    const regexp = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
    return regexp.test(email)
  }

  const handlePasswordChange = (e, { value }) => {
    setPassword(value)
  }

  const passwordIsValid = () => {
    const regexp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
    return regexp.test(password)
  }

  const handlePasswordConfirmationChange = (e, { value }) => {
    setPasswordConfirmation(value)
  }

  const passwordsMatch = () => {
    return password == passwordConfirmation
  }

  const handleSubmit = () => {
    setIsLoading(true)

    api
      .adminSignup(props.campSlug, email, password, passwordConfirmation, role)
      .then(() => {
        window.location.href = `${window.origin}/admin/login`
        props.setErrorMessage('')
      })
      .catch((errResponse) => {
        let errors = []

        for (let key in errResponse) {
          errors.push([key, errResponse[key]].join(' '))
        }

        props.setErrorMessage(errors.join(', '))
        setIsLoading(false)
      })
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Sign up</Card.Header>
        <Card.Meta>Create an administrative account.</Card.Meta>

        <Divider />

        <Form onSubmit={handleSubmit} loading={isLoading}>
          <Form.Input
            label='Email'
            icon={
              email.length > 0 && emailIsValid()
                ? {
                    name: 'check circle',
                    color: 'green',
                  }
                : {
                    name: 'mail',
                  }
            }
            iconPosition='left'
            value={email}
            placeholder={'Email'}
            onChange={handleEmailChange}
          />

          <Form.Input
            label='Password'
            type='password'
            icon={
              password.length > 0 && passwordIsValid()
                ? {
                    name: 'check circle',
                    color: 'green',
                  }
                : {
                    name: 'key',
                  }
            }
            iconPosition='left'
            value={password}
            placeholder={'Password'}
            onChange={handlePasswordChange}
          />

          <Form.Input
            label='Password Confirmation'
            type='password'
            icon={
              passwordConfirmation.length > 0 && passwordsMatch()
                ? {
                    name: 'check circle',
                    color: 'green',
                  }
                : {
                    name: 'key',
                  }
            }
            iconPosition='left'
            value={passwordConfirmation}
            placeholder={'Retype password'}
            onChange={handlePasswordConfirmationChange}
          />

          <div className='my-8'>
            <CheckboxWithLabel
              label={
                <span>
                  I acknowledge that I have reviewed and accept our{' '}
                  <a
                    className='underline text-blue-500'
                    href='https://www.poweredbypark.com/terms-and-conditions'
                    target='_blank'>
                    Terms of Service
                  </a> and consent to the storing and processing of your personal data as described in our{' '}
                  <a
                    className='underline text-blue-500'
                    href='https://www.poweredbypark.com/privacy-policy'
                    target='_blank'>
                    Privacy Policy
                  </a>
                  .
                </span>
              }
              value={isCheckedTerms}
              setValue={() => setIsCheckedTerms(!isCheckedTerms)}
            />
          </div>

          <Form.Button fluid primary disabled={!(isCheckedTerms && email && password && passwordConfirmation && password === passwordConfirmation)}>
            Sign up
          </Form.Button>
        </Form>
      </Card.Content>
    </Card>
  )
}

export default SignupForm