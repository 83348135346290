import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../ui/Modal'
import { ReservationProps } from '../../../interfaces'
import Button from '../../ui/Button'
import { AdminContext } from '../../../contexts/AdminContext'
import classNames from '../../../utils/classNames'
import api from '../../../api2'
import CheckboxWithLabel from '../../ui/CheckboxWithLabel'
import Alert from '../../ui/Alert'
import MessageTypeSelector from './MessageTypeSelector'
import { formatName } from '../../../utils/formatName'
import { TagsInput } from 'react-tag-input-component'
import eSendingMode from '../../../enums/eSendingMode'
import { useCookies } from 'react-cookie'
import { STATUSES } from '../../../constants'
import HoverTip from '../../ui/HoverTip'
import eSendingModeCookieName from '../../../enums/eSendingModeCookieName'
import IInvoice from '../../../interfaces/IInvoice'

const removeDuplicates = (array: any[]) => {
  return array.filter(
    (value, index, array) => value != null && value.trim() !== '' && array.indexOf(value) === index
  )
}

interface ComponentProps {
  open: boolean
  onClose: () => void
  reservations?: ReservationProps[]
  invoices?: IInvoice[]
  emails?: string[]
  phoneNumbers?: string[]
  isBatch?: boolean
  isToAndCCBlocked?: boolean
}

const cookieSendingMode = eSendingModeCookieName.MESSAGE

const MessageModal = ({
  open,
  onClose,
  reservations,
  invoices,
  emails,
  phoneNumbers,
  isBatch,
  isToAndCCBlocked,
}: ComponentProps) => {
  const { setIsMessageSent, currentUserEmail } = useContext(AdminContext)

  const [isProcessing, setIsProcessing] = useState(false)
  const [isShowingCc, setIsShowingCc] = useState(false)
  const [isCcActive, setIsCcActive] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isIncludingReservation, setIsIncludingReservation] = useState(false)
  const [isIncludingInvoice, setIsIncludingInvoice] = useState(false)
  const [isIncludingPaymentLink, setIsIncludingPaymentLink] = useState(false)
  const [toEmails, setToEmails] = useState<string[]>([])
  const [toPhoneNumbers, setToPhoneNumbers] = useState<string[]>([])
  const [ccEmails, setCcEmails] = useState<string[]>([])
  const [messageContent, setMessageContent] = useState<string>('')
  const [cookies, setCookie] = useCookies([cookieSendingMode])
  const { camp, selectedSendingMode, setSelectedSendingMode } = useContext(AdminContext)

  const emailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const phoneValidation = /^(?=(?:.*[0-9]){6,})\+?([0-9]{1,4})?[-.\s]?(\()?([0-9]{1,3})?(\))?[-.\s]?([0-9]{1,4})[-.\s]?([0-9]{1,4})[-.\s]?([0-9]{1,9})$/


  useEffect(() => {
    const reservationPhoneNumbers = reservations?.map((r) => r.user?.camper?.phone || r.user?.phone) || []
    const invoicePhoneNumbers = invoices?.map((i) => i.recipient_phone) || []
    const combinedPhoneNumbers = (phoneNumbers || []).concat(reservationPhoneNumbers).concat(invoicePhoneNumbers)
    setToPhoneNumbers(removeDuplicates(combinedPhoneNumbers))
    const reservationEmails = reservations?.map((r) => r.user?.camper?.email || r.user?.email) || []
    const invoiceEmails = invoices?.map((i) => i.recipient_email) || []
    const combinedEmails = (emails || []).concat(reservationEmails).concat(invoiceEmails)
    setToEmails(removeDuplicates(combinedEmails))

    if (!open) {
      setIsProcessing(false)
      setIsShowingCc(false)
      setErrorMessage('')
      setIsIncludingReservation(false)
      setIsIncludingInvoice(false)
      setIsIncludingPaymentLink(false)
      setToEmails([])
      setToPhoneNumbers([])
      setMessageContent('')
    }
  }, [open])

  useEffect(() => {
    if(open){
      Object.values([eSendingMode.EMAIL, eSendingMode.TEXT]).includes(cookies[cookieSendingMode]) &&
        cookies[cookieSendingMode] &&
        setSelectedSendingMode(cookies[cookieSendingMode])
      !cookies[cookieSendingMode] && setCookie(cookieSendingMode, selectedSendingMode)
    }
  }, [open])

  const handleSend = (e) => {
    setErrorMessage('')
    setIsProcessing(true)
    e.preventDefault()

    api.Messages.sendEmail({
      to: selectedSendingMode == eSendingMode.EMAIL ? toEmails : toPhoneNumbers,
      cc: ccEmails.map((email) => email.trim()),
      from: e.target.from?.value,
      subject: e.target.subject?.value,
      text: messageContent,
      sending_mode: selectedSendingMode,
      admin_email: isCcActive ? camp.email : null,
      reservation_id: reservations?.length === 1 ? reservations[0].id : null,
      reservation_ids: isBatch ? (reservations ? reservations.map((r) => r.id) : null) : null,
      invoice_ids: isBatch ? (invoices ? invoices.map((i) => i.id) : null) : null,
      is_including_invoice: isIncludingInvoice,
      is_including_reservation_details: isIncludingReservation,
      is_including_payment_link: isIncludingPaymentLink,
      camp_id: camp.id,
    })
      .then(() => {
        setIsProcessing(false)
        setIsMessageSent(true)
        onClose()
      })
      .catch((err) => {
        setErrorMessage(err.message)
        setIsMessageSent(false)
        setIsProcessing(false)
      })
  }

  return (
    <Modal open={open} onClose={onClose} maxWidth='max-w-3xl'>
      <>
        <div className='flex items-center justify-between mb-4 sm:mb-4 bg-gray-100 px-4 pt-6 pb-4 -m-4 sm:-m-6 rounded-t-lg'>
          <div className='flex flex-col w-full'>
            <div className='font-semibold text-xl'>
              {reservations?.length && reservations.length > 1
                ? 'Message guests'
                : reservations?.length
                ? `Message ${formatName(reservations[0].user?.camper || reservations[0].user) || 'guest'}`
                : `Message guest`}
            </div>
          </div>
        </div>

        <form className='space-y-4' onSubmit={handleSend}>
          {errorMessage && <Alert description={errorMessage} />}

          <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
            <label htmlFor='from' className='text-baselg text-gray-500 mt-2.5'>
              Type:
            </label>
            <div className='mt-2 sm:col-span-5 sm:mt-0 flex justify-start flex-col md:flex-row'>
              <MessageTypeSelector
                options={[eSendingMode.EMAIL, eSendingMode.TEXT]}
                selectedOption={selectedSendingMode}
                onChange={(e) => {
                  setSelectedSendingMode(e)
                  setCookie(cookieSendingMode, e)
                }}
              />
              {selectedSendingMode == eSendingMode.EMAIL && (
                <div className='flex items-center md:ml-3 mt-2 md:mt-0'>
                  <CheckboxWithLabel
                    label={`cc ${camp.email}`}
                    value={isCcActive}
                    setValue={setIsCcActive}
                    labelClasses='text-sm break-words w-64 truncate'
                    labelStyle={{ maxWidth: 243 }}
                  />
                </div>
              )}
            </div>
          </div>

          {selectedSendingMode == eSendingMode.EMAIL && (
            <>
              <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
                <label htmlFor='to' className='w-20 text-baselg text-gray-500 mt-2.5'>
                  To:
                </label>
                <div className='mt-2 sm:col-span-5 sm:mt-0'>
                  <div className='flex rounded-md shadow-sm'>
                    <div className='relative flex items-stretch focus-within:z-10 w-full'>
                      <div className='w-full'>
                        <TagsInput
                          value={toEmails}
                          onChange={setToEmails}
                          onBlur={(e: any) => {
                            const value = e.target.value.trim()

                            if (
                              value?.length &&
                              !toEmails.includes(value) &&
                              emailValidation.test(value)
                            ) {
                              setToEmails([...toEmails, value])
                              e.target.value = ''
                            }
                          }}
                          name='to'
                          separators={[',', 'Enter', ' ', 'Tab']}
                          beforeAddValidate={(value) =>
                            emailValidation.test(value) || toEmails.includes(value)
                          }
                          classNames={{
                            tag: '',
                            input: 'focus:outline-none focus:ring-0 focus:ring-offset-0',
                          }}
                          disabled={isProcessing || isToAndCCBlocked}
                        />
                      </div>
                    </div>
                    <button
                      type='button'
                      className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-5 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100'
                      onClick={() => setIsShowingCc(!isShowingCc)}>
                      Cc
                    </button>
                  </div>
                </div>
              </div>
              {isShowingCc && (
                <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
                  <label htmlFor='cc' className='text-baselg text-gray-500 mt-2'>
                    Cc:
                  </label>
                  <div className='mt-2 sm:col-span-5 sm:mt-0'>
                    <TagsInput
                      value={ccEmails}
                      onChange={setCcEmails}
                      name='to'
                      separators={[',', 'Enter', ' ', 'Tab']}
                      beforeAddValidate={(value) =>
                        emailValidation.test(value) || ccEmails.includes(value)
                      }
                      classNames={{
                        tag: '',
                        input: 'focus:outline-none focus:ring-0 focus:ring-offset-0',
                      }}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim()

                        if (
                          value?.length &&
                          !ccEmails.includes(value) &&
                          emailValidation.test(value)
                        ) {
                          setCcEmails([...ccEmails, value])
                          e.target.value = ''
                        }
                      }}
                      disabled={isProcessing || isToAndCCBlocked}
                    />
                  </div>
                </div>
              )}
              <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
                <label htmlFor='from' className='text-baselg text-gray-500 mt-1.5'>
                  From:
                </label>
                {/* TODO : update to dropdown selector once we support multiple emails for a camp */}
                <div className='mt-2 sm:col-span-5 sm:mt-0'>
                  <input
                    disabled={true}
                    type='email'
                    id='from'
                    name='from'
                    defaultValue={camp?.email}
                    required
                    className='cursor-not-allowed p-1 w-full border rounded-md opacity-60 border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 px-2'
                  />
                </div>
              </div>

              <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
                <label htmlFor='subject' className='text-lg text-gray-500 mt-1'>
                  Subject:
                </label>
                <div className='mt-2 sm:col-span-5 sm:mt-0'>
                  <input
                    disabled={isProcessing}
                    type='text'
                    id='subject'
                    name='subject'
                    defaultValue={`Message from ${camp?.name}`}
                    required
                    className='p-1 w-full border rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 px-2'
                  />
                </div>
              </div>
            </>
          )}

          {selectedSendingMode == 'text' && (
            <>
              <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
                <label htmlFor='to' className='w-20 text-baselg text-gray-500 mt-2.5'>
                  To:
                </label>
                <div className='mt-2 sm:col-span-5 sm:mt-0'>
                  <div className='flex rounded-md shadow-sm'>
                    <div className='relative flex flex-grow items-stretch focus-within:z-10'>
                      <div className='w-full'>
                        <TagsInput
                          value={toPhoneNumbers}
                          onChange={setToPhoneNumbers}
                          name='to'
                          separators={[',', 'Enter', 'Tab']}
                          beforeAddValidate={(value) =>
                            phoneValidation.test(value) || toPhoneNumbers.includes(value)
                          }
                          classNames={{
                            tag: '',
                            input: 'focus:outline-none focus:ring-0 focus:ring-offset-0',
                          }}
                          disabled={isProcessing || isToAndCCBlocked}
                          onBlur={(e: any) => {
                            const value = e.target.value.trim()

                            if (
                              value?.length &&
                              !toPhoneNumbers.includes(value) &&
                              phoneValidation.test(value)
                            ) {
                              setToPhoneNumbers([...toPhoneNumbers, value])
                              e.target.value = ''
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='sm:grid sm:grid-cols-6 sm:items-start sm:gap-4'>
            <label htmlFor='message' className='w-20 text-lg text-gray-500'>
              Message:
            </label>
            <div className='mt-2 sm:col-span-5 sm:mt-0'>
              <textarea
                rows={4}
                disabled={isProcessing}
                id='message'
                value={messageContent}
                onChange={(event) => {
                  setMessageContent(event.target.value)
                }}
                name='message'
                className='p-1 w-full border rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700 px-2'
              />
            </div>
          </div>

          <div className='pt-4 border-t border-gray-200'>
            <div className='flex space-x-2 justify-between'>
                {reservations?.length > 0 && (
                  <div className='flex flex-col md:flex-row md:gap-x-4'>
                    <div className='flex items-center'>
                      <CheckboxWithLabel
                        label='Add invoice'
                        value={isIncludingInvoice}
                        setValue={setIsIncludingInvoice}
                        disabled={isProcessing || !reservations.some((r) => r?.price_details)}
                      />
                    </div>
                    <div className='flex items-center'>
                      <CheckboxWithLabel
                        label='Add reservation info'
                        value={isIncludingReservation}
                        setValue={setIsIncludingReservation}
                        disabled={isProcessing}
                      />
                    </div>
                    <div className='flex items-center'>
                      {selectedSendingMode !== eSendingMode.TEXT && (
                        <>
                          {reservations.every(
                            (r) => r?.status === STATUSES.PAID
                          ) ? (
                            <HoverTip
                              xs
                              placement='top'
                              content={
                                reservations.length > 1
                                  ? 'All reservations are paid, no payment link will be Added'
                                  : 'Reservation is paid, no payment link will be Added'
                              }
                            >
                              <CheckboxWithLabel
                                label='Add payment link'
                                value={isIncludingPaymentLink}
                                setValue={setIsIncludingPaymentLink}
                                disabled={true}
                              />
                            </HoverTip>
                          ) : (
                            <CheckboxWithLabel
                              label='Add payment link'
                              value={isIncludingPaymentLink}
                              setValue={setIsIncludingPaymentLink}
                              disabled={isProcessing}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

              <div className='flex items-center'>
                <div className='hidden sm:block'>
                  <Button
                    type='button'
                    variant='gray'
                    className={classNames(
                      'mr-4 w-28',
                      isProcessing && 'pointer-events-none opacity-60'
                    )}
                    onClick={onClose}
                    disabled={isProcessing}>
                    Cancel
                  </Button>
                </div>
                <Button
                  type='submit'
                  variant='green'
                  className='w-28'
                  disabled={
                    (!messageContent.trim() && !isIncludingInvoice && !isIncludingReservation) ||
                    (selectedSendingMode === eSendingMode.EMAIL && !toEmails.length) ||
                    (selectedSendingMode === eSendingMode.TEXT && !toPhoneNumbers.length)
                  }
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </form>
      </>
    </Modal>
  )
}

export default MessageModal
