import React, { useContext, useEffect, useRef, useState } from 'react'
import { Combobox, Disclosure, Transition } from '@headlessui/react'
import { ChevronUpDownIcon, CreditCardIcon, EllipsisHorizontalIcon, PrinterIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import moment, { Moment } from 'moment'
import { STATUSES } from '../../../constants'
import { CampingStyleProps, ReservationProps, SiteProps, UserProps } from '../../../interfaces'
import classNames from '../../../utils/classNames'
import { CheckBadgeIcon as CheckBadgeIconSolid, EnvelopeIcon } from '@heroicons/react/24/solid'
import Button from '../../ui/Button'
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates'
import api from '../../../api'
import api2 from '../../../api2'
import InputFieldWithLabel from '../Inputs/InputFieldWithLabel'
import { AdminContext } from '../../../contexts/AdminContext'
import { EditReservationContext } from '../../../contexts/admin/EditReservationContext'
import Accordian from '../../ui/Accordian'
import PriceDetail from '../PriceDetail'
import SitesDropdown from '../../Reservations/SitesDropdown/SitesDropdown'
import { PriceDetails as IPriceDetails } from '../../../utils/priceDetailsFor'
import { formatName } from '../../../utils/formatName'
import IGroupedReservation from '../../../interfaces/IGroupedReservation'
import ProfileCard, { checkIsCamperEmpty } from '../Campers/Camper/ProfileCard'
import generateSiteOptions from '../../Reservations/SitesDropdown/generateSiteOptions'
import { TagIcon, UserIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'
import CamperDecorator from '../../../decorators/CamperDecorator'
import twoDecimalPlaces from '../../../utils/twoDecimalPlaces'
import RichTextInput from '../../ui/RichTextInput'
import ArrowLeftOnRectangleIconSolid from '../../../../assets/images/park__icon_ux__check_out.svg'
import OrderSummary from '../Orders/OrderSummary'
import IOrder from '../../../interfaces/IOrder'
import { useReactToPrint } from 'react-to-print'
import EditInvoice from '../Invoices/EditInvoice'
import { serviceRateMode } from '../../../api2/orders'
import IOrderPreview from '../../../interfaces/IOrderPreview'
import { useDebouncedCallback } from 'use-debounce'
import { RIG_TYPES } from '../../../constants/rigTypes'
import ReservationInvoiceWrapper from '../../helpers/ReservationInvoiceWrapper'
import ReservationHistory from './ReservationHistory'
import { createElectricOptions } from '../../../utils/admin/createElectricOptions'
import { titleCase } from '../../../utils'
import InputFieldWithCurrency from '../Inputs/InputFieldWithCurrency'
import HoverTip from '../../ui/HoverTip'
import GroupModalContents from '../GroupedReservations/GroupModalContents'
import avatarFor from '../../../utils/avatarFor'
import generateAvatar from '../../../utils/generateAvatar'
import generateConsistentNaturalHexColor from '../../../utils/generateNaturalHexColor'
import { Icon, Message } from 'semantic-ui-react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import ICamper from '../../../interfaces/ICamper'
import NewCamperModalContent, { newCamper } from '../Campers/NewCamperModalContent'
import GuestDropdown from './GuestDropdown'
import { set } from 'lodash'

const stripeIntentUrlFor = (account_id: string, intent_id: string) => {
  const environment = window.origin.includes('localhost') ? '/test' : ''
  return `https://dashboard.stripe.com/${account_id}${environment}/payments/${intent_id}`
}

interface ComponentProps {
  stripeAccountId: string
  reservation?: ReservationProps
  site?: SiteProps
  isProcessing: boolean
  currency: string
  campName: string

  startDate: Moment
  setStartDate: (v: Moment) => void
  endDate: Moment
  setEndDate: (v: Moment) => void
  openInvoiceModal: () => any
  onOpenDialogue: () => any
  setSlideoverOpen: (v: boolean) => void
}

const EditReservationForm = (props: ComponentProps) => {
  const {
    setCurrentPage,
    setSelectedReservation,
    setSelectedCamper,
    camp,
    campingStyles,
    sites,
    reservationStates,
    showModal,
    closeModal,
    showPaymentModal,
    showMessageModal,
    currentUserRole,
    selectedCamper,
    showNotification,
    updateLocalReservations,
  } = useContext(AdminContext)
  const {
    updateLocalReservation,
    errorMessage,
    siteId,
    setSiteId,
    onUpdateReservation,
    refUpdateReservationForm,
  } = useContext(EditReservationContext)

  const site =
    sites.find((s) => props.reservation?.site_id) || props.reservation?.site || props.site
  const campingStyle = campingStyles.find((cs) => cs.id === site?.camping_style_id)

  const selectedSite = sites.find((site) => site.id === props?.reservation?.site_id)

  const [id, setId] = useState(props.reservation?.id)

  const [isFocusStart, setIsFocusStart] = useState(false)
  const [isFocusEnd, setIsFocusEnd] = useState(false)
  const [groupedReservation, setGroupedReservation] = useState<IGroupedReservation>()
  const [additionalNotesValue, setAdditionalNotesValue] = useState(props.reservation?.notes)

  const [camper, setCamper] = useState<CamperDecorator>()
  const [isCamperLoading, setIsCamperLoading] = useState(false)
  const [license_plate, set_license_plate] = useState<string>()
  const [vehicle_year, set_vehicle_year] = useState<string>()
  const [vehicle_make, set_vehicle_make] = useState<string>()
  const [vehicle_color, set_vehicle_color] = useState<string>()
  const [vehicle_rig_type, set_vehicle_rig_type] = useState<string>()
  const [vehicle_rig_length, set_vehicle_rig_length] = useState<string>()
  const [vehicle_electric, set_vehicle_electric] = useState<string>()
  const [address, set_address] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const passengers = useRef()
  const kid_campers = useRef()
  const pets_info = useRef()
  const targetRef = useRef(null)
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)
  const [newCamperInputValue, setNewCamperInputValue] = useState(selectedCamper?.first_name || selectedCamper?.last_name || selectedCamper?.email || '')

  const usePrint = useReactToPrint({
    content: () => targetRef.current,
  })

  useEffect(() => {
    debouncedCamper(props.reservation?.user?.camper_id)
  }, [props.reservation])

  const [siteOptions, setSiteOptions] = useState(
    generateSiteOptions(sites, props.reservation, reservationStates, campingStyles)
  )

  const debouncedCamper = useDebouncedCallback((camperId: string) => {
    if (!camperId) return
    setIsCamperLoading(true)

    api2.Camps.findCamper(camperId)
      .then(({ camper: foundCamper }) => {
        setCamper(new CamperDecorator(foundCamper))
        setIsCamperLoading(false)
        set_license_plate(foundCamper?.license_plate)
        set_vehicle_year(foundCamper?.vehicle_year)
        set_vehicle_make(foundCamper?.vehicle_make)
        set_vehicle_color(foundCamper?.vehicle_color)
        set_vehicle_rig_type(foundCamper?.vehicle_rig_type)
        set_vehicle_rig_length(foundCamper?.vehicle_rig_length)
        set_vehicle_electric(foundCamper?.vehicle_electric)
        set_address(foundCamper?.address)
      })
      .catch((err) => {
        setIsCamperLoading(false)
        console.error(err)
      })
  }, 100)

  const isHourly = !!campingStyles.find((cs) => cs.id === props.site?.camping_style_id)
    ?.default_hours

  const [total_owed, set_total_owed] = useState(
    twoDecimalPlaces((props.reservation?.total_owed_in_cents || props.reservation?.total) / 100)
  )
  const [total_paid, set_total_paid] = useState(
    twoDecimalPlaces(props.reservation?.total_paid_in_cents / 100)
  )

  // NOTE: this submits the form and updates the reservation (and waits for the response) before
  // it opens the payment modal.
  const handleAcceptPayment = async () => {
    const formRef = refUpdateReservationForm

    // TODO: also check if there are changed fields
    if (formRef.current) {
      const form = formRef.current
      const promise = new Promise((resolve) => {
        const onSubmitWrapper = async (event) => {
          const res = await onUpdateReservation(event, true)
          form.removeEventListener('submit', onSubmitWrapper)
          resolve(res)
        }
        form.addEventListener('submit', onSubmitWrapper)
      })

      // TODO: make sure that the success notification is not shown when the form submits in this
      // case (we want it to happen silently 🤫)
      form.dispatchEvent(new Event('submit', { cancelable: true }))

      const reservation = (await promise) as ReservationProps
      const owedInCents = reservation.total_owed_in_cents
        ? reservation.total_owed_in_cents
        : parseFloat(total_owed) * 100.0

      showPaymentModal(owedInCents, { reservation })
    }
  }

  const handleClickSendInvoice = (e) => {
    props.openInvoiceModal()
  }

  const handleClickCheckedIn = (e) => {
    let is_checked_in: boolean = false
    let is_checked_out: boolean = false

    if (!props.reservation?.is_checked_in && !props.reservation?.is_checked_out) {
      is_checked_in = true
      is_checked_out = false
    } else if (
      props.reservation?.is_checked_in &&
      !props.reservation?.is_checked_out
    ) {
      is_checked_in = true
      is_checked_out = true
    } else if (
      props.reservation?.is_checked_in &&
      props.reservation?.is_checked_out
    ) {
      is_checked_in = false
      is_checked_out = false
    }

    setIsLoading(true)
    debouncedUpdateCheckedInStatus(is_checked_in, is_checked_out)
  }

  const debouncedUpdateCheckedInStatus = useDebouncedCallback((isCheckedIn, isCheckedOut) => {
    api
      .updateReservation(props.reservation?.id, {
        is_checked_in: isCheckedIn,
        is_checked_out: isCheckedOut,
      })
      .then((updatedRes: ReservationProps) => {
        updateLocalReservation(updatedRes)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        alert(JSON.stringify(err))
      })
  }, 100)

  useEffect(() => {
    if (!props.reservation?.grouped_reservation_id) return

    api2.GroupedReservations.find(props.reservation?.grouped_reservation_id)
      .then((gr) => {
        setGroupedReservation(gr)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [props.reservation?.grouped_reservation_id])

  useEffect(() => {
    set_total_owed(
      twoDecimalPlaces((props.reservation?.total_owed_in_cents || props.reservation?.total) / 100)
    )
    set_total_paid(twoDecimalPlaces(props.reservation?.total_paid_in_cents / 100))
  }, [props.reservation?.total_owed_in_cents, props.reservation?.total_paid_in_cents])

  const onClickEditCamper = (isNewCamper: boolean = false) => {
    props.setSlideoverOpen(false)
    showModal(
      <NewCamperModalContent
        userId={props.reservation?.user_id}
        reservationId={props.reservation?.id}
        camper={isNewCamper ? { ...newCamper, first_name: newCamperInputValue || null } : camper}
        onSuccess={(updatedCamper) => {
          debouncedCamper(updatedCamper.id)
        }}
      />,
      false,
      'max-w-4xl',
      {
        closeCallback: () => {
          props.setSlideoverOpen(true)
        },
      }
    )
  }

  const onClickDetachCamper = () => {
    setIsLoading(true)
    api2.Users.update(
      props.reservation?.user_id,
      {
        camper_id: null
      } as Parameters<typeof api2.Users.update>[1]
    )
      .then((updatedUser) => {
        const updated = { ...props.reservation, user: updatedUser }
        updateLocalReservations([updated])
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        showNotification({
          type: 'error',
          title: 'Could not detach camper',
          description: err.message,
        })
      })
  }

  const electricOptions = createElectricOptions(selectedSite)
  const rigTypeOptions = [null]
    .concat(Object.values(RIG_TYPES))
    .map((rigType) => ({ key: rigType, value: titleCase(rigType), label: titleCase(rigType) }))

  const isCamperEmpty = !camper || checkIsCamperEmpty(camper)
  const startDateValue = moment(props?.reservation?.deleted_at).isValid() ? props.reservation?.start_date : props.startDate
  const endDateValue = moment(props?.reservation?.deleted_at).isValid() ? props.reservation?.end_date : props.endDate

  return (
    <div className='pb-5 pt-4'>
      {errorMessage && (
        <div className='mb-4'>
          <p className='text-sm sm:text-md text-red-600'>{errorMessage}</p>
        </div>
      )}

      <input hidden readOnly type='text' defaultValue={id} name='id' id='id' className='hidden' />
      <input
        hidden
        readOnly
        type='text'
        defaultValue={props.reservation?.user?.id}
        name='userId'
        id='userId'
        className='hidden'
      />
      <input
        hidden
        readOnly
        type='text'
        value={selectedCamper?.id || camper?.id}
        name='camperId'
        id='camperId'
        className='hidden'
      />

      <>
        <div className='pb-1'>
          {props.reservation?.grouped_reservation_id && (
            <div className='flex items-center bg-gray-100 justify-between mb-4 py-2 px-2.5 border border-dashed border-gray-300 rounded-md -mx-3'>
              {/* TODO: Make this dynamic */}
              <div className='font-semibold'>
                Part of{' '}
                {formatName(groupedReservation?.user) == null
                  ? 'a'
                  : ' the ' + formatName(groupedReservation?.user)}{' '}
                group
              </div>
              <div className='flex'>
                {props.reservation?.grouped_reservation_id && (
                  <a
                    className={classNames(
                      'focus:outline-none transition ease-in-out duration-100 rounded-md cursor-pointer',
                      'px-3.5 py-2.5',
                      'flex items-center text-sm sm:text-md bg-white hover:text-black hover:bg-gray-50 border border-gray-400 hover:border-gray-500'
                    )}
                    onClick={() => {
                      showModal(
                        <GroupModalContents
                          groupedReservationId={props.reservation?.grouped_reservation_id}
                        />,
                        true,
                        'max-w-3xl'
                      )
                    }}>
                    <UserGroupIcon className='w-5 h-5 text-gray-700 mr-2' />
                    <div>Manage Group</div>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='flex items-center justify-between mb-0.5'>
          <div className='font-bold text-xl'>Summary</div>
          <div className='flex gap-2'>
            {props.reservation?.status !== STATUSES.BLOCKED && (
              <Button
                type='button'
                variant='white'
                size='small'
                shadow='small'
                className={'flex items-center text-gray-600'}
                disabled={isLoading}
                onClick={handleClickCheckedIn}>
                {props?.reservation?.is_checked_in ? (
                  !props?.reservation?.is_checked_out && (
                    <img src={ArrowLeftOnRectangleIconSolid} className='w-5 h-5 mr-2 text-black' />
                  )
                ) : (
                  <CheckBadgeIconSolid className='w-5 h-5 mr-2' style={{ color: '#3A8860' }} />
                )}
                {props?.reservation?.is_checked_in
                  ? !props?.reservation?.is_checked_out
                    ? 'Check-out'
                    : 'Remove checked status'
                  : 'Check-in'}
              </Button>
            )}
            <Button
              type='button'
              variant='white'
              size='small'
              shadow='small'
              className='flex-shrink-0 justify-self-end flex text-gray-600'
              onClick={usePrint}
              children={
                <>
                  <PrinterIcon className='w-5 h-5 mr-2' />
                  Print
                </>
              }
            />
          </div>
        </div>

        <div className=''>
          <div className='w-full mb-3'>
            <SitesDropdown
              label='Site'
              disabled={props.reservation?.is_locked}
              reservation={props.reservation}
              selectedReservationType={props.reservation?.kind}
              selectedIds={[siteId]}
              setSelectedIds={(ids: string[]) => setSiteId(ids[0])}
            />
          </div>
          <input hidden id='site_id' name='site_id' value={siteId} />
        </div>

        <div className='flex mb-2'>
          <div className='flex-1 grow'>
            <div>
              <label htmlFor='name' className='block font-semibold text-md text-gray-900'>
                Check-in
              </label>
              {/* <CalendarDaysIcon className='h-7 w-7 text-gray-600 absolute top-1.5 left-2' aria-hidden='true' /> */}
              <div
                className={classNames(
                  'datepicker-disabled-styles',
                  isHourly ? 'flex items-center' : 'block'
                )}>
                <SingleDatePicker
                  noBorder
                  id='start_date'
                  block
                  date={moment(startDateValue).utcOffset(camp.timezone_offset)}
                  onDateChange={(date) => {
                    if (!date) return

                    props.setStartDate(date)
                    if (props.endDate && date.isSameOrAfter(props.endDate)) {
                      props.setEndDate(null)
                    }
                  }}
                  isOutsideRange={(day) => false}
                  focused={isFocusStart}
                  onFocusChange={(e) => setIsFocusStart(e.focused)}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel={true}
                  displayFormat={camp.date_format}
                  disabled={
                    props.reservation?.status === STATUSES.IMPORTED ||
                    props.reservation?.status === STATUSES.BLOCKED_IMPORT
                  }
                />
                {isHourly && (
                  <InputFieldWithLabel
                    type='time'
                    step='900'
                    className='w-36 ml-2'
                    disabled={
                      props.reservation?.status === STATUSES.IMPORTED ||
                      props.reservation?.status === STATUSES.BLOCKED_IMPORT
                    }
                    defaultValue={DateTime.fromISO(props.reservation?.start_date, {
                      zone: camp.timezone,
                    }).toFormat('HH:mm')}
                    onBlur={(time) => {
                      // TODO: this logic is correct, but gets overwritten on save. Let's remove that logic to overwrite the times
                      const splitTime = time.target.value.split(':')
                      props.setStartDate(
                        moment(props.startDate)
                          .utcOffset(camp.timezone_offset)
                          .hours(Number(splitTime[0]))
                          .minutes(Number(splitTime[1]))
                      )
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className='ml-4 flex-1 grow'>
            <div>
              <label htmlFor='name' className='block font-semibold text-md text-gray-900'>
                Check-out
              </label>
              <div
                className={classNames(
                  'datepicker-disabled-styles',
                  isHourly ? 'flex items-center' : 'block'
                )}>
                <SingleDatePicker
                  noBorder
                  id='end_date'
                  block
                  date={moment(endDateValue)?.utcOffset(camp.timezone_offset)}
                  onDateChange={(date) => props.setEndDate(date)}
                  focused={isFocusEnd}
                  onFocusChange={(e) => setIsFocusEnd(e.focused)}
                  numberOfMonths={1}
                  initialVisibleMonth={() => props.endDate || props.startDate || moment()}
                  hideKeyboardShortcutsPanel={true}
                  isOutsideRange={(day) => isInclusivelyBeforeDay(day, props.startDate)}
                  displayFormat={camp.date_format}
                  disabled={
                    props.reservation?.status === STATUSES.IMPORTED ||
                    props.reservation?.status === STATUSES.BLOCKED_IMPORT
                  }
                />
                {isHourly && (
                  <InputFieldWithLabel
                    type='time'
                    step='900'
                    className='w-36 ml-2'
                    disabled={
                      props.reservation?.status === STATUSES.IMPORTED ||
                      props.reservation?.status === STATUSES.BLOCKED_IMPORT
                    }
                    defaultValue={DateTime.fromISO(props.reservation?.end_date, {
                      zone: camp.timezone,
                    }).toFormat('HH:mm')}
                    onBlur={(time) => {
                      // TODO: this logic is correct, but gets overwritten on save. Let's remove that logic to overwrite the times
                      const splitTime = time.target.value.split(':')
                      const mEndDate = moment(props.endDate)
                      mEndDate.isValid()
                        ? props.setEndDate(
                            moment(props.endDate)
                              .utcOffset(camp.timezone_offset)
                              .hours(Number(splitTime[0]))
                              .minutes(Number(splitTime[1]))
                          )
                        : props.setEndDate(null)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>

      <div className='flex mb-2'>
        <div className='flex-grow'>
          <label htmlFor='name' className='block font-semibold text-md text-gray-900'>
            Reservation Notes
          </label>
          <div className='mt-1'>
            <input name='notes' id='notes' hidden value={additionalNotesValue} />
            <RichTextInput
              defaultValue={props.reservation?.notes}
              value={additionalNotesValue}
              setValue={setAdditionalNotesValue}
              id='notes'
            />
          </div>
        </div>
      </div>

      {props.reservation?.status != STATUSES.BLOCKED && (
        <div className='flex mb-12'>
          <InputFieldWithLabel
            type='number'
            full
            inputRef={passengers}
            name='passengers'
            label='Adults'
            defaultValue={props.reservation?.passengers}
            disabled={props.isProcessing}
          />
          <InputFieldWithLabel
            type='number'
            full
            inputRef={kid_campers}
            name='kid_campers'
            label='Kids'
            defaultValue={props.reservation?.kid_campers}
            disabled={props.isProcessing}
            className='ml-4'
          />
          <InputFieldWithLabel
            type='text'
            full
            inputRef={pets_info}
            name='pets_info'
            label='Pets'
            defaultValue={props.reservation?.pets_info}
            disabled={props.isProcessing}
            className='ml-4'
          />
        </div>
      )}

      {props.reservation?.status != STATUSES.BLOCKED && (
        <>
          <div className='flex items-center w-full justify-between mb-2 mt-6'>
            <div className='font-bold text-xl'>Guest</div>
            <div className='flex flex-wrap justify-end'>
              {!isCamperLoading &&
                (camper?.id ? (
                  <Button
                    type='button'
                    variant='white'
                    size='small'
                    shadow='small'
                    className='flex items-center mr-2 text-gray-600'
                    disabled={isCamperLoading}
                    onClick={() => onClickEditCamper()}>
                    <UserIcon className='w-5 h-5 mr-2' />
                    <div className='hidden sm:block'>Edit guest</div>
                    <div className='sm:hidden'>Edit</div>
                  </Button>
                ) : (
                  <Button
                    type='button'
                    variant='white'
                    size='small'
                    shadow='small'
                    className='flex items-center mr-2 text-gray-600'
                    disabled={isCamperLoading}
                    onClick={() => onClickEditCamper()}>
                    <UserIcon className='w-5 h-5 mr-2' />
                    <div className='hidden sm:block'>Add guest</div>
                    <div className='sm:hidden'>Add</div>
                  </Button>
                ))}
              {(props.reservation?.user?.email ||
                props.reservation?.user?.phone ||
                props.reservation?.user?.camper?.email ||
                props.reservation?.user?.camper?.phone) && (
                <Button
                  type='button'
                  variant='white'
                  size='small'
                  shadow='small'
                  className='flex items-center text-gray-600 mr-2'
                  onClick={() => {
                    showMessageModal({ reservations: [props.reservation] })
                    props.onOpenDialogue()
                  }}>
                  <EnvelopeIcon className='w-5 h-5 mr-2' />
                  <div className='hidden sm:block'>Send message</div>
                  <div className='sm:hidden'>Message</div>
                </Button>
              )}
              <GuestDropdown
                isCamperLoading={isCamperLoading}
                camper={camper}
                onClickEditCamper={() => onClickEditCamper()}
                onClickRemoveCamper={() => onClickDetachCamper()}
                onClickViewCamper={() => {
                  setSelectedCamper(camper)
                  setCurrentPage('guests')
                }}
                onClickMessageCamper={() => {
                  showMessageModal({ reservations: [props.reservation] })
                  props.onOpenDialogue()
                }}
              />
            </div>
          </div>
          <div className='pb-12 relative'>
            <ProfileCard
              camper={camper}
              isBlocked={camper?.isBlocked}
              isCamperLoading={isCamperLoading}
            />
          </div>
        </>
      )}

      {props.reservation?.status != STATUSES.BLOCKED &&
        props.reservation?.status != STATUSES.IMPORTED &&
        props.reservation?.status != STATUSES.BLOCKED_IMPORT &&
        currentUserRole != 'view-only' && (
          <>
            <div className='flex items-center justify-between mb-2'>
              <div className='flex flex-col'>
                <div className='text-xl font-bold'>Invoice</div>
                <HoverTip content={'View customer invoice page'} xs>
                  <div
                    className='text-sm text-blue-500 hover:underline font-medium -mt-0.5 cursor-pointer'
                    onClick={() => {
                      window.open(`${window.origin}/i/${props.reservation?.hashed_id}`, '_blank')
                    }}>
                    #{props.reservation?.hashed_id}
                  </div>
                </HoverTip>
              </div>
              <div className='flex'>
                {groupedReservation &&
                  (groupedReservation.stripe_payment_link_admin_url ? (
                    <Button
                      variant='white'
                      size='small'
                      shadow='small'
                      className='flex items-center text-gray-600 mr-2'
                      onClick={() => {
                        window.open(groupedReservation.stripe_payment_link_admin_url, '_blank')
                      }}>
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 mr-2' />
                      <div className='hidden sm:block'>View Payment Link</div>
                      <div className='sm:hidden'>View</div>
                    </Button>
                  ) : groupedReservation.stripe_intent_id ? (
                    <Button
                      variant='white'
                      size='small'
                      shadow='small'
                      className='flex items-center text-gray-600 mr-2'
                      onClick={() => {
                        const url = stripeIntentUrlFor(
                          camp.stripe_account_id,
                          groupedReservation.stripe_intent_id
                        )
                        window.open(url, '_blank')
                      }}>
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-700 mr-2' />
                      <div className='hidden sm:block'>View Payment Link</div>
                      <div className='sm:hidden'>View</div>
                    </Button>
                  ) : (
                    <></>
                  ))}

                <Button
                  variant='white'
                  size='small'
                  shadow='small'
                  className='flex items-center text-gray-600 mr-2'
                  onClick={handleAcceptPayment}>
                  <CreditCardIcon className='w-5 h-5 text-gray-700 mr-2' />
                  <div className='hidden sm:block'>Take payment</div>
                  <div className='sm:hidden'>Pay</div>
                </Button>

                <Button
                  variant='white'
                  size='small'
                  shadow='small'
                  className='flex items-center text-gray-600'
                  type='button'
                  onClick={handleClickSendInvoice}>
                  <EnvelopeIcon className='w-5 h-5 text-gray-700 mr-2' />
                  <div className='hidden sm:block'>Edit invoice</div>
                  <div className='sm:hidden'>Invoice</div>
                </Button>
              </div>
            </div>
            <div className='flex pb-4 items-end'>
              <div className='w-1/2'>
                <InputFieldWithCurrency
                  value={total_owed || ''}
                  type='number'
                  disabled={props.isProcessing}
                  label='Total owed'
                  name='unpaid_price_in_cents'
                  id='total_owed_in_cents'
                  onChange={(e) => set_total_owed(e.target.value)}
                  full
                  placeholder='0.00'
                  currency={props.currency}
                  isChanged={
                    Math.round(parseFloat(total_owed) * 100) !==
                    Number(props.reservation?.total_owed_in_cents)
                  }
                />
              </div>

              <div className='ml-4 w-1/2'>
                <label htmlFor='name' className='block font-semibold text-md text-gray-900 mb-0.5'>
                  Total paid
                </label>
                <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <span className='text-gray-500 sm:text-md'>$</span>
                  </div>
                  <input
                    value={total_paid || ''}
                    type='number'
                    step='any'
                    disabled={props.isProcessing}
                    name='total_paid_in_cents'
                    id='total_paid_in_cents'
                    onChange={(e) => {
                      set_total_paid(e.target.value)
                    }}
                    className={classNames(
                      'block w-full rounded-l-md border-gray-300 pl-7 xs:pr-12 focus:border-green-700 focus:ring-green-700',
                      'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500',
                      Math.round(parseFloat(total_paid) * 100) !==
                        Number(props.reservation?.total_paid_in_cents)
                        ? 'bg-blue-50'
                        : 'bg-white'
                    )}
                    placeholder='0.00'
                    aria-describedby='price-currency'
                  />
                  <div
                    className='pointer-events-none absolute inset-y-0 hidden xs:flex items-center pr-3'
                    style={{ right: '6.5rem' }}>
                    <span className='text-gray-500 sm:text-md' id='price-currency'>
                      {props.currency.toUpperCase()}
                    </span>
                  </div>
                  <button
                    type='button'
                    className='relative w-44 -ml-px inline-flex justify-center items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-3 py-2 text-sm sm:text-md font-medium text-gray-600 hover:bg-gray-100 focus:border-green-700 focus:ring-green-700 focus:ring-1'
                    onClick={() => {
                      set_total_paid(total_owed.toString())
                    }}>
                    Paid<span className='hidden sm:inline ml-1'> in full</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'flex gap-4',
                !!props.reservation?.price_details ? 'mb-0' : 'border-b pb-4 mb-6'
              )}>
              {props.reservation?.discount_type && (
                <div className='flex items-center justify-center rounded-md bg-gray-100 h-12 px-3 mb-4'>
                  <div className='mr-2'>
                    <TagIcon className='w-5 h-5' />
                  </div>
                  <div className='flex flex-col'>
                    <div className='flex items-center'>
                      <div className='font-semibold'>
                        {titleCase(props.reservation?.discount_type)} Discount
                      </div>
                    </div>
                    {props.reservation?.discount_membership_id && (
                      <div className='text-sm sm:text-md text-gray-600 -mt-1'>
                        Member ID: <code>{props.reservation?.discount_membership_id}</code>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <ReservationOrder reservation={props.reservation} campingStyle={campingStyle} />
            {!props.reservation?.grouped_reservation_id && (
              <div className='mt-12 mb-12'>
                <div className='text-xl font-bold mb-2'>History</div>
                <ReservationHistory
                  reservationId={props.reservation?.id}
                  orderId={props.reservation?.order_id}
                  setSlideoverOpen={props.setSlideoverOpen}
                />
              </div>
            )}
          </>
        )}
      <div className='hidden print-margin'>
        <ReservationInvoiceWrapper reservation={props.reservation} order={props.reservation?.order}>
          {({ invoice }) => (
            <EditInvoice
              invoice={invoice}
              setSelectedInvoice={() => null}
              updateLocalInvoices={() => null}
              site={props.site}
              campingStyle={campingStyle}
              isProcessing={!!invoice}
              customerView
              disabled
              printRef={targetRef}
              isForPrint
            />
          )}
        </ReservationInvoiceWrapper>
      </div>
    </div>
  )
}

const ReservationOrder = ({
  reservation,
  campingStyle,
}: {
  reservation: ReservationProps
  campingStyle: CampingStyleProps
}) => {
  const { camp } = useContext(AdminContext)
  const [order, setOrder] = useState<IOrder>()
  const [preview, setPreview] = useState<IOrderPreview>()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const isPaid = reservation?.status === STATUSES.PAID

  const debouncedOrderPreviews = useDebouncedCallback(() => {
    if (!reservation?.order_id) return

    api2.Orders.orderAndPreviewFor(
      reservation.order_id,
      camp.id,
      reservation.selected_camping_style_id || reservation.site?.camping_style_id,
      serviceRateMode.payment_link,
      reservation.status === STATUSES.PAID,
      reservation.discount_type
    )
      .then(({ order, preview }) => {
        setOrder(order)
        setPreview(preview)
        setIsLoading(false)
        return Promise.resolve(order)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
  }, 100)

  useEffect(() => {
    if (!reservation?.order_id) return

    setIsLoading(true)
    debouncedOrderPreviews()
  }, [reservation])

  if (errorMessage || !reservation?.order_id) {
    if (!reservation?.price_details) return null

    return (
      <div className='border-b gap-4 w-full'>
        <Accordian label='Price details'>
          <PriceDetail
            priceDetail={reservation?.price_details as IPriceDetails}
            isRecurring={reservation?.kind === 'recurring'}
            isShowingTax={true}
            taxRate={(reservation?.price_details as IPriceDetails)?.tax_rate || camp.tax_rate}
            campingStyle={campingStyle}
          />
        </Accordian>
      </div>
    )
  }

  const isOrderMatchingReservationOwed =
    Math.round(order?.total_in_cents) === reservation?.total_owed_in_cents
  const isPreviewMatchingReservationOwed =
    Math.round(preview?.total_without_service_fee_in_cents) === reservation?.total_owed_in_cents

  const isSynced = isPaid ? isOrderMatchingReservationOwed : isPreviewMatchingReservationOwed

  if (!isSynced) {
    return (
      <div className='border-b pb-4 mb-6 gap-4 w-full'>
        <Accordian label='Price details'>
          <div className='flex justify-center items-center text-sm italic text-gray-500'>
            The breakdown is not available because the total owed was updated
          </div>
        </Accordian>
      </div>
    )
  }

  return (
    <Accordian label='Price details'>
      <OrderSummary
        order={isPaid ? order : preview}
        isPreview={!isPaid}
        isHidingServiceFee
        camp={camp}
        isLoading={isLoading}
        isIncludingLineItems
      />
    </Accordian>
  )
}



export default EditReservationForm
