export const formatMoney = (value: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
}

export const formatToNearestDollar = (value: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
  })

  return formatter.format(value)
}

export const formatMoneyFromCents = (value: number, currency?: string) => formatMoney(value / 100.0, currency)
