import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import classNames from '../../utils/classNames'

const Accordian = ({ label, children }: { label: string; children: JSX.Element | JSX.Element[] }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className='w-full'>
            <div className='w-full flex items-center justify-between bg-gray-50 hover:bg-gray-100 px-4 py-4 rounded-sm'>
              <span className='grow font-bold'>{label}</span>
              <ChevronDownIcon
                className={classNames(
                  'w-5 h-5 transition-all duration-200',
                  open && '-rotate-180 transform'
                )}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className='bg-gray-50 px-4 py-4'>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Accordian
