import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import Slider from 'react-slick'
import Spinner from './Spinner'

const RightArrow = ({ onClick }: any) => (
  <div onClick={onClick} className='absolute z-20 top-1/2 right-2 transform -translate-y-1/2'>
    <ArrowRightCircleIcon className='text-gray-50 hover:text-gray-200 opacity-90 hover:opacity-100 w-12 h-12 cursor-pointer' />
  </div>
)

const LeftArrow = ({ onClick }: any) => (
  <div onClick={onClick} className='absolute z-20 top-1/2 left-2 transform -translate-y-1/2'>
    <ArrowLeftCircleIcon className='text-gray-50 hover:text-gray-200 opacity-90 hover:opacity-100 w-12 h-12 cursor-pointer' />
  </div>
)

export default function CampImagesCarousel({
  imageUrls,
  isLoading,
  isInfinite = true,
}: {
  imageUrls: string[]
  isLoading: boolean,
  isInfinite?: boolean
}) {
  var settings = {
    arrows: true,
    infinite: isInfinite,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    rows: 1,
    swipeToSlide: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  }

  return (
    <>
      <div className='slider-container relative'>
        {isLoading ? (
          <div className='z-10 absolute top-0 bg-white w-full h-full opacity-70 flex items-center justify-center'>
            <Spinner size={10} />
          </div>
        ) : (
          <Slider {...settings}>
            {imageUrls.map((imageUrl) => {
              return (
                <div className='noOutline'>
                  <img src={imageUrl} className='w-full max-h-72 pt-2 pb-1 pr-4' key={imageUrl} />
                </div>
              )
            })}
          </Slider>
        )}
      </div>
    </>
  )
}
