import { CampingStyleProps, SiteProps } from '../interfaces'

const sortSites = (a: SiteProps, b: SiteProps, campingStyle: CampingStyleProps) => {
  if (a.camping_style_id === b.camping_style_id) {
    return a.sort - b.sort
  } else {
    return a.camping_style_id === campingStyle.id ? -1 : 1
  }
}

export default sortSites
