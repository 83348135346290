const SINGLE: 'single' = 'single'
const RECURRING: 'recurring' = 'recurring'
const BLOCKED: 'blocked' = 'blocked'
const GROUP: 'group' = 'group'
const SEASONAL: 'seasonal' = 'seasonal'

const RESERVATION_KINDS = { SINGLE, RECURRING, BLOCKED, GROUP, SEASONAL }
type reservationKind = typeof SINGLE | typeof RECURRING | typeof BLOCKED | typeof GROUP | typeof SEASONAL

export { RESERVATION_KINDS, reservationKind }
