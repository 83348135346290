import {
  ArrowLeftIcon,
  BookOpenIcon,
  InboxArrowDownIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid'
import moment, { Moment } from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import api2 from '../../../api2'
import { CampProps, ReservationProps } from '../../../interfaces'
import Spinner from '../../ui/Spinner'
import ReportsDatePicker from './ReportsDatePicker'
import { ArrowDownTrayIcon, TagIcon } from '@heroicons/react/24/outline'
import Button from '../../ui/Button'
import { AdminContext } from '../../../contexts/AdminContext'
import ChartsGrid from './ChartsGrid'
import ReportButton from '../../ui/ReportButton'
import Table from './Table'
import SitesTable from './SitesTable'
import RentRollTable from './RentRollTable'
import ReservationsTable from './ReservationsTable'
import FilterBy from './FilterBy'
import AddOnsTable from './AddOnsTable'
import { reportFilterCookie } from '../../../constants/cookies'
import { useCookies } from 'react-cookie'
import downloadCsv from '../../../utils/downloadCsv'
import { set } from 'lodash'

interface ComponentProps {
  camp: CampProps
}

const getHeader = (slug) => {
  switch (slug) {
    case 'reservations':
      return 'Reservations Report'

    case 'sites':
      return 'Sites Report'

    case 'rent-roll':
      return 'Rent-Roll Report'

    case 'add-ons':
      return 'Add-Ons Report'

    case 'transactions':
      return 'Transactions Report'

    case 'tax-collected':
      return 'Tax Collected Report'

    default:
      return 'Your Overview'
  }
}

const reports = [
  {
    name: 'Reservations',
    slug: 'reservations',
    stat: '0 reservations',
    icon: BookOpenIcon,
  },
  {
    name: 'Sites & Occupancy',
    slug: 'sites',
    stat: '$0',
    icon: MapPinIcon,
  },
  {
    name: 'Rent-Roll',
    slug: 'rent-roll',
    stat: '$0',
    icon: InboxArrowDownIcon,
  },
  {
    name: 'Add-Ons',
    slug: 'add-ons',
    stat: '$0',
    icon: TagIcon,
  },
  // {
  //   name: 'Transactions',
  //   slug: 'transactions',
  //   stat: '$22,520.96',
  //   icon: CurrencyDollarIcon,
  // },
  // {
  //   name: 'Tax Collected',
  //   slug: 'tax-collected',
  //   stat: '$1,120.91',
  //   icon: ReceiptPercentIcon,
  // },
]

const Reports = (props: ComponentProps) => {
  const { camp, sites } = useContext(AdminContext)
  const [cookies, setCookie] = useCookies([reportFilterCookie])

  const [isLoading, setIsLoading] = useState(true)
  const [sitesOccupancyData, setSitesOccupancyData] = useState<Array<ReservationProps>>()
  const [reservationsVolumeData, setReservationsVolumeData] = useState<Array<ReservationProps>>()
  const [selectedReport, setSelectedReport] = useState<
    'reservations' | 'sites' | 'rent-roll' | 'add-ons' | 'transactions' | 'tax-collected'
  >()
  const [data, setData] = useState<any[]>([])
  const [reservationsDashboardData, setReservationsDashboardData] = useState<any>()
  const [sitesDashboardData, setSitesDashboardData] = useState<any>()
  const [addOnsDashboardData, setAddOnsSitesDashboardData] = useState<any>()
  const [rentRollDashboardData, setRentRollDashboardData] = useState<any>()
  const [updatedReports, setUpdatedReports] = useState<Array<any>>([]);
  const [selectedStartDate, setSelectedStartDateWithOffset] = useState<Moment>(moment().subtract(7, 'days').hour(12).add(1, 'days'))
  const [selectedEndDate, setSelectedEndDateWithOffset] = useState<Moment>(moment().hour(12).add(1, 'days'))
  const [selectedFilterBy, setSelectedFilterBy] = useState<string>(cookies[reportFilterCookie] || "start_date")
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(50)

  const setSelectedStartDate = (date) => {
    const dateWithOffset = date.utcOffset(camp.timezone_offset, true);
    setSelectedStartDateWithOffset(dateWithOffset);
  };

  const setSelectedEndDate = (date) => {
    if (date) {
      const dateWithOffset = date.hour(23).minute(59).utcOffset(camp.timezone_offset, true)
      setSelectedEndDateWithOffset(dateWithOffset)
    }
  }

  useEffect(() => {
    if (!selectedReport || !selectedStartDate || !selectedEndDate) {
      setIsLoading(false)
      return
    }

    if (selectedReport == 'sites') {
      setIsLoading(true)

      const sitesPromise = api2.Reports.sites(camp.id, selectedStartDate, selectedEndDate, page);
      const sitesDashboardPromise = api2.Reports.sites_dashboard(camp.id, selectedStartDate, selectedEndDate);

      Promise.all([sitesPromise, sitesDashboardPromise])
        .then(([sitesData, sitesDashboardData]) => {
          setData(sitesData);
          setTotalRecords(sitesData['total_count']);
          setSitesDashboardData(sitesDashboardData);
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (selectedReport == 'rent-roll') {
      setIsLoading(true)

      const rentRollPromise = api2.Reports.rent_roll(camp.id, selectedStartDate, selectedEndDate, page)
      const rentRollDashboardPromise = api2.Reports.rent_roll_dashboard(camp.id, selectedStartDate, selectedEndDate)

      Promise.all([rentRollPromise, rentRollDashboardPromise])
        .then(([rentRollData, rentRollDashboardData]) => {
          setData(rentRollData)
          setTotalRecords(rentRollData['total_count'])
          setRentRollDashboardData(rentRollDashboardData)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else if (selectedReport == 'reservations') {
      setIsLoading(true)

      const reservationsPromise = api2.Reports.reservations(camp.id, selectedStartDate, selectedEndDate, selectedFilterBy, page);
      const reservationsDashboardPromise = api2.Reports.reservations_dashboard(camp.id, selectedStartDate, selectedEndDate, selectedFilterBy);

      Promise.all([reservationsPromise, reservationsDashboardPromise])
        .then(([reservationsData, reservationsDashboardData]) => {
          setData(reservationsData);
          setTotalRecords(reservationsData['total_count']);
          setReservationsDashboardData(reservationsDashboardData);
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (selectedReport == 'add-ons') {
      setIsLoading(true)

      const addOnsPromise = api2.Reports.add_ons(camp.id, selectedStartDate, selectedEndDate, page)
      const addOnsDashboardPromise = api2.Reports.add_ons_dashboard(camp.id, selectedStartDate, selectedEndDate)

      Promise.all([addOnsPromise, addOnsDashboardPromise])
        .then(([addOnsData, addOnsDashboardData]) => {
          setData(addOnsData)
          setTotalRecords(addOnsData['total_count'])
          setAddOnsSitesDashboardData(addOnsDashboardData)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [selectedReport, selectedEndDate, selectedFilterBy, page])

  const fetchCSV = () => {
    if (selectedReport == 'sites') {
      api2.Reports.sites_csv(camp.id, selectedStartDate, selectedEndDate, page)
      .then((data) => {
        downloadCsv(camp.slug, data, "sites-report")
        setIsLoading(false)
      })
      .catch((err) => {
        alert(JSON.stringify(err))
        setIsLoading(false)
      })
    } else if (selectedReport == 'rent-roll') {
      api2.Reports.rent_roll_csv(camp.id, selectedStartDate, selectedEndDate, page)
        .then((data) => {
          downloadCsv(camp.slug, data, "rent-roll-report")
          setIsLoading(false)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
          setIsLoading(false)
        })
    } else if (selectedReport == 'reservations') {
      api2.Reports.reservations_csv(camp.id, selectedStartDate, selectedEndDate, selectedFilterBy, page)
        .then((data) => {
          downloadCsv(camp.slug, data, "reservations-report")
          setIsLoading(false)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
          setIsLoading(false)
        })
    } else if (selectedReport == 'add-ons') {
      api2.Reports.add_ons_csv(camp.id, selectedStartDate, selectedEndDate, page)
        .then((data) => {
          downloadCsv(camp.slug, data, "add-ons-report")
          setIsLoading(false)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
          setIsLoading(false)
        })
      }
  }

  useEffect(() => {
    if (!(selectedStartDate && selectedEndDate)) return
    setIsLoading(true)

    // Create an array of API calls
    const apiCalls = [
      api2.Reports.sites_dashboard(camp.id, selectedStartDate, selectedEndDate),
      api2.Reports.rent_roll_dashboard(camp.id, selectedStartDate, selectedEndDate),
      api2.Reports.reservations_dashboard(camp.id, selectedStartDate, selectedEndDate, selectedFilterBy),
      api2.Reports.add_ons_dashboard(camp.id, selectedStartDate, selectedEndDate),
      api2.Reports.reservations_volume(camp.id, selectedStartDate, selectedEndDate, "created_at", 1),
      api2.Reports.sites_occupancy(camp.id, selectedStartDate, selectedEndDate, "start_date", 1),
    ]

    // Use Promise.all to wait for all API calls to complete
    Promise.all(apiCalls)
    .then(([sitesDashboardData, rentRollDashboardData, reservationsDashboardData, AddOnsDashboardData, reservationsVolumeData, sitesOccupancyData]) => {
      // Update the reports based on the data from each API call
      updateReports('sites', sitesDashboardData, setUpdatedReports);
      updateReports('rent-roll', rentRollDashboardData, setUpdatedReports);
      updateReports('reservations', reservationsDashboardData, setUpdatedReports);
      updateReports('add-ons', AddOnsDashboardData, setUpdatedReports);
      setReservationsVolumeData(reservationsVolumeData);
      setSitesOccupancyData(sitesOccupancyData);
      setReservationsDashboardData(reservationsDashboardData);
      setRentRollDashboardData(rentRollDashboardData);
      setAddOnsSitesDashboardData(AddOnsDashboardData);
      setSitesDashboardData(sitesDashboardData);
      setIsLoading(false);
    })
    .catch(err => {
      alert(JSON.stringify(err));
      setIsLoading(false);
    })
  }, [selectedEndDate])

  const updateReports = (slug, data, callback) => {
    const reportIndex = reports.findIndex(report => report.slug === slug);

    if (reportIndex !== -1) {
      const updatedReport = { ...reports[reportIndex] };

      switch (slug) {
        case 'reservations':
          updatedReport.stat = `${data['amount_of_reservations']} reservations`;
          break;
        case 'sites':
          updatedReport.stat = `$${data['avg_site_revenue']} per site`;
          break;
        case 'rent-roll':
          updatedReport.stat = `$${data['total_monthly_rent']} this month`;
          break;
        case 'add-ons':
          updatedReport.stat = `${data['total_sold']} sold`;
          break;
        // Add cases for other reports if needed
        default:
          break;
      }

      updatedReports[reportIndex] = updatedReport;
      setUpdatedReports(updatedReports);
    }
  }

  const returnReport = () => {
    switch (selectedReport) {
      case 'reservations':
        return <ReservationsTable data={data['data']} summary={reservationsDashboardData} />
      case 'sites':
        return <SitesTable data={data['data']} summary={sitesDashboardData} />
      case 'rent-roll':
        return <RentRollTable data={data['data']} summary={rentRollDashboardData} />
      case 'add-ons':
        return <AddOnsTable data={data['data']} summary={addOnsDashboardData} />
      case 'transactions':
        return <Table data={data['data']} />
      case 'tax-collected':
        return <Table data={data['data']} />
      default:
        break
    }
  }

  return (
    <div className='block flex-grow px-4 lg:px-0 pb-6 w-screen'>
      {isLoading && (
        <div className='absolute bg-white bg-opacity-30 z-40 flex flex-col space-y-4 justify-center items-center h-full w-full top-20'>
          <Spinner size={16} />
          <p>Loading stats...</p>
        </div>
      )}

      {selectedReport && (
        <div className='flex justify-between'>
          <Button
            variant='white'
            className='border-none h-11'
            icon={<ArrowLeftIcon className='w-5 h-5' />}
            onClick={() => setSelectedReport(null)}>
            Back
          </Button>
          <div className='text-2xl mt-2 mb-4 font-semibold'>{getHeader(selectedReport)}</div>
          <div className='w-24' />
        </div>
      )}

      <div className='flex flex-wrap gap-4'>
        <ReportsDatePicker
          startDate={selectedStartDate}
          setStartDate={setSelectedStartDate}
          endDate={selectedEndDate}
          setEndDate={setSelectedEndDate}
          visible={true}
          isMonthlyIntervals={selectedReport == 'rent-roll'}
        />
        {selectedReport && selectedReport == 'reservations' ? (
          <FilterBy
            defaultFilterBy={selectedFilterBy}
            setFilterBy={setSelectedFilterBy}
            options={[
              { label: 'Arrival Date', value: 'start_date' },
              { label: 'Departure Date', value: 'end_date' },
              { label: 'Created Date', value: 'created_at' },
            ]}
          />
        ) : (
          <></>
        )}
        {selectedReport && (
          <Button
            variant='blue'
            className='sm:ml-auto flex-shrink-0'
            onClick={fetchCSV}>
            <div className='flex items-center justify-center sm:justify-start'>
              <span className='flex-shrink-0'>Download report</span>
              <ArrowDownTrayIcon className='w-6 h-6 text-white ml-2' />
            </div>
          </Button>
        )}
      </div>
      <div className='mb-4' />
      {selectedReport ? (
        <>
          <div className=''>{returnReport()}</div>
          <nav
            className='flex items-center justify-between border-t border-gray-200 pt-6'
            aria-label='Pagination'>
            <div className='hidden sm:block'>
              <p className='text-sm text-gray-700'>
                Showing <span className='font-medium'>{perPage * page - perPage + 1}</span> to{' '}
                <span className='font-medium'>{Math.min(perPage * page, totalRecords)}</span> of{' '}
                <span className='font-medium'>{totalRecords}</span> results
              </p>
            </div>
            {totalRecords > perPage && (
              <div className='flex flex-1 justify-between sm:justify-end'>
                <a
                  onClick={() => {
                    if (page > 1) setPage(page - 1)
                  }}
                  className='relative inline-flex items-center rounded-md bg-white cursor-pointer px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0'>
                  Previous
                </a>
                <a
                  onClick={() => {
                    if (page <= totalRecords / perPage) setPage(page + 1)
                  }}
                  className='relative ml-3 inline-flex items-center rounded-md bg-white cursor-pointer px-3 py-2 text-md font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0'>
                  Next
                </a>
              </div>
            )}
          </nav>
        </>
      ) : (
        <>
          <ChartsGrid
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            sitesOccupancyData={sitesOccupancyData}
            reservationsVolumeData={reservationsVolumeData}
            numberOfSites={sites.length}
            isLoading={isLoading}
          />
          <div className='text-1.5xl mt-8 mb-4 font-bold'>
            Reports{' '}
            <span className='text-baselg font-normal text-gray-500 ml-1 italic'>
              (click to view)
            </span>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-20'>
            {(updatedReports || reports).map((report, id) => (
              <ReportButton
                name={report.name}
                slug={report.slug}
                stat={report.stat}
                icon={report.icon}
                key={id}
                setSelectedReport={setSelectedReport}
                setPage={setPage}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Reports
