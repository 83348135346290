import { IOption } from '../../ui/Admin/OptionSelector'

export enum REFUND_FILTER_OPTIONS {
  NET = 'net',
  FULL = 'full',
  CUSTOM = 'custom',
}

export const refundFilterOptions: IOption[] = [
  {
    value: REFUND_FILTER_OPTIONS.NET,
    label: 'Refund net',
  },
  {
    value: REFUND_FILTER_OPTIONS.FULL,
    label: 'Full refund',
  },
  {
    value: REFUND_FILTER_OPTIONS.CUSTOM,
    label: 'Custom',
  },
]