import React from 'react'
import { Form } from 'semantic-ui-react'

interface ComponentProps {
  radioOption
  name: string
  discountType: string
  onChange
}

const DiscountOption = (props: ComponentProps) => {
  return <Form.Field>
    <Form.Radio
      label={props.name}
      name='radioGroup'
      value={props.discountType}
      checked={props.radioOption == props.discountType}
      onChange={props.onChange}
    />
  </Form.Field>
}

export default DiscountOption