import React, { useEffect, useState } from 'react'
import { Card, Container, Divider, Form, Menu, Message, Segment } from 'semantic-ui-react'

import parkLogoPath from '../../assets/images/park_logo__green.svg'

interface ComponentProps {
  authenticity_token: string
  notices?: Array<string>[]
}

const ResetPassword = (props: ComponentProps) => {
  const handleLoginMenuItemClick = () => {
    window.location.href = `${window.origin}/admin/login`
  }

  return (
    <>
      <Segment>
        <Container>
          <Menu secondary>
            <span className='flex-inline align-middle mr-2'>
              <img src={parkLogoPath} className='inline z-10 h-10' />
            </span>
            <Menu.Menu position='right'>
              <Menu.Item
                name='login'
                onClick={handleLoginMenuItemClick}
              />
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
      <Container>
        {
          props.notices?.map(([type, message]: [string, string]) => {
            return <Message key={type} content={message} />
          })
        }
      </Container>
      <Container>
        <Segment basic>
          <Card.Group centered>
            <Card>
              <Card.Content>
                <Card.Header>Password Reset</Card.Header>
                <Card.Meta>Type in the email to your account and we'll send you an email with a link to change your password.</Card.Meta>

                <Divider />

                <Form method='post' action='/password_resets'>
                  <input name='authenticity_token' hidden value={props.authenticity_token} />
                  <Form.Input
                    label='Email'
                    name='email'
                    icon='mail'
                    iconPosition='left'
                    placeholder={'Email'}
                  />

                  <Form.Button fluid primary>Reset Password</Form.Button>
                </Form>
              </Card.Content>
            </Card>
          </Card.Group>
        </Segment>
      </Container>
    </>
  )
}

export default ResetPassword
