import moment from "moment"
import { validElectric } from "../types"
import { post } from "./utils/requests"

export type IAdditionalFee = {
  kind: string // TODO: this may not be here
  type: string
  id: string
  slug: string
  label?: string
  unit_price: number
  quantity: number
  total_price: number
  is_applied_nightly: boolean
  nights?: number
  site_ids?: Array<string>
}

export type IAddOnParam = {
  id: string
  quantity: number
}

export type IPriceDetails = {
  nights: number
  nightly_price: number
  frequency_label: string
  subtotal: number
  discounted_subtotal: number
  tax_rate: number
  tax: number
  service_rate: number
  service_fee: number
  total: number
  holiday_nights: number
  holiday_nightly_fee: number
  holiday_total_fee: number
  discounted_holiday_total_fee: number
  weekend_nights: number
  weekend_nightly_fee: number
  weekend_total_fee: number
  discounted_weekend_total_fee: number
  additional_adults: number
  adults_fee: number
  additional_kids: number
  kids_fee: number
  additional_pets: number
  pets_fee: number
  flat_rate_price: number
  additional_fees: Array<IAdditionalFee>
  credit_card_rate: number
  credit_card_fee: number

  start_date?: moment.Moment
  end_date?: moment.Moment
}

type ForSiteDataProps = {
  kind: string
  startDate: string
  endDate: string
  discountSlug: string
  couponCodes: string[]
  subtotalOverride: number
  isTaxEnabled: boolean
  is_passenger_fees_enabled: boolean
  isPetsFeeEnabled: boolean
  isHolidayEnabled: boolean
  isWeekendEnabled: boolean
  isServiceFeeApplied: boolean
  isCreditCardFeeEnabled?: boolean
  campingStyleId: string
  number_of_adults?: number
  number_of_kids?: number
  number_of_pets?: number
  user_electric: validElectric
  add_ons?: Array<IAddOnParam>
  interval_id?: string
}

const forSite = (siteId: String, data: ForSiteDataProps): Promise<IPriceDetails> =>
  post(`/sites/${siteId}/price_details`, {
    camping_style_id: data.campingStyleId,
    kind: data.kind,
    start_date: data.startDate,
    end_date: data.endDate,
    discount_slug: data.discountSlug,
    coupon_codes: data.couponCodes,
    subtotal_override: data.subtotalOverride,
    is_tax_enabled: data.isTaxEnabled,
    is_passenger_fees_enabled: data.is_passenger_fees_enabled,
    is_pets_fee_enabled: data.isPetsFeeEnabled,
    is_holiday_rate_enabled: data.isHolidayEnabled,
    is_weekend_fee_enabled: data.isWeekendEnabled,
    is_service_fee_applied: data.isServiceFeeApplied,
    is_credit_card_fee_enabled: data.isCreditCardFeeEnabled,
    number_of_adults: data.number_of_adults,
    number_of_kids: data.number_of_kids,
    number_of_pets: data.number_of_pets,
    user_electric: data.user_electric,
    add_ons: data.add_ons,
    interval_id: data.interval_id,
  })
    .then((resp) => mapResponse(resp?.price_detail, data.startDate, data.endDate))

const forSites = (siteIds: string[], data: ForSiteDataProps): Promise<IPriceDetails[]> =>
  post(`/price_details`, {
    site_ids: siteIds,
    camping_style_id: data.campingStyleId,
    kind: data.kind,
    start_date: data.startDate,
    end_date: data.endDate,
    discount_slug: data.discountSlug,
    coupon_codes: data.couponCodes,
    subtotal_override: data.subtotalOverride,
    is_tax_enabled: data.isTaxEnabled,
    is_credit_card_fee_enabled: data.isCreditCardFeeEnabled,
    is_passenger_fees_enabled: data.is_passenger_fees_enabled,
    is_pets_fee_enabled: data.isPetsFeeEnabled,
    is_holiday_rate_enabled: data.isHolidayEnabled,
    is_weekend_fee_enabled: data.isWeekendEnabled,
    is_service_fee_applied: data.isServiceFeeApplied,
    number_of_adults: data.number_of_adults,
    number_of_kids: data.number_of_kids,
    number_of_pets: data.number_of_pets,
    user_electric: data.user_electric,
    add_ons: data.add_ons,
  })
    .then((resp) => resp?.price_details?.map((pd) => mapResponse(pd, data.startDate, data.endDate)))

const mapResponse = (price_detail, start_date: string, end_date: string): IPriceDetails => {
  return {
    nights: price_detail.nights,
    nightly_price: price_detail.nightly_price,
    frequency_label: price_detail.frequency_label,
    subtotal: price_detail.subtotal,
    discounted_subtotal: price_detail.discounted_subtotal,
    tax_rate: price_detail.tax_rate,
    tax: price_detail.tax,
    service_rate: price_detail.service_rate,
    service_fee: price_detail.service_fee,
    total: price_detail.total,
    holiday_nights: price_detail.holiday_nights,
    holiday_nightly_fee: price_detail.holiday_nightly_fee,
    holiday_total_fee: price_detail.holiday_total_fee,
    discounted_holiday_total_fee: price_detail.discounted_holiday_total_fee,
    weekend_nights: price_detail.weekend_nights,
    weekend_nightly_fee: price_detail.weekend_nightly_fee,
    weekend_total_fee: price_detail.weekend_total_fee,
    discounted_weekend_total_fee: price_detail.discounted_weekend_total_fee,
    flat_rate_price: price_detail.flat_rate_price,
    additional_fees: price_detail.additional_fees,
    credit_card_fee: price_detail.credit_card_fee,
    credit_card_rate: price_detail.credit_card_rate,

    // TODO: move these to additional_fees
    additional_adults: price_detail.additional_adults,
    adults_fee: price_detail.adults_fee,
    additional_kids: price_detail.additional_kids,
    kids_fee: price_detail.kids_fee,
    additional_pets: price_detail.additional_pets,
    pets_fee: price_detail.pets_fee,

    start_date: moment(start_date),
    end_date: moment(end_date),
  }
}

const PriceDetails = {
  forSite,
  forSites
}

export default PriceDetails
