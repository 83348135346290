import React from 'react'
import ICamper from '../../interfaces/ICamper'
import { PencilIcon } from '@heroicons/react/24/solid'
import { formatName } from '../../utils/formatName'
import { CamperBlocked } from './StatusBadges'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { formatPhoneNumber } from '../../utils'
import avatarFor from '../../utils/avatarFor'
import generateNaturalHexColor from '../../utils/generateNaturalHexColor'
import generateAvatar from '../../utils/generateAvatar'

interface ComponentProps {
  camper: ICamper
  onClose: () => void
}

const CamperCard = ({ camper, onClose }: ComponentProps) => {
  return (
    <div className='flex flex-col bg-gray-50 rounded-lg border border-gray-200 px-4.5 py-4'>
      <div className='flex justify-between items-center'>
        <div className='flex flex-row'>
          <div className='w-16 h-16 mr-4'>
            {avatarFor(
              generateAvatar(camper.first_name + camper.last_name || camper.email || camper.phone),
              generateNaturalHexColor(
                camper.first_name + camper.last_name || camper.email || camper.phone
              )
            )}
          </div>
          <div className='flex flex-col space-y-1'>
            <div className='text-gray-800 text-lg leading-6'>{formatName(camper)}</div>
            {camper.blocked_at && <CamperBlocked />}
            <div className='text-gray-450 text-md leading-4'>{camper.email}</div>
            <div className='text-gray-450 text-md leading-4'>{formatPhoneNumber(camper.phone)}</div>
          </div>
        </div>
        <div
          className='hover:bg-gray-100 h-7 w-7 rounded-md flex items-center justify-center'
          onClick={onClose}>
          <XMarkIcon className='w-76 h-6 text-gray-700' />
        </div>
      </div>
    </div>
  )
}

export default CamperCard
