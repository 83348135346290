import React, { MutableRefObject } from 'react'
import { titleCase } from '../../../utils'
import classNames from '../../../utils/classNames'

const InputFieldWithIcon = ({
  type,
  ref,
  label,
  name,
  defaultValue,
  disabled,
  className,
  icon,
  onError,
  onChange,
  onBlur,
  full,
}: {
  type?: string
  ref?: MutableRefObject<any>
  label?: string
  name?: string
  defaultValue?: any
  disabled?: boolean
  className?: string
  icon: any
  onError: (err) => any
  onChange: (e) => any
  onBlur: (e) => any
  full?: boolean
}) => {
  return (
    <div className={classNames(full && 'w-full', 'relative', className)}>
      {label ||
        (name && (
          <label htmlFor='name' className='block font-medium text-md text-gray-900'>
            {label || titleCase(name)}
          </label>
        ))}
      <div className='mt-1 flex'>
        <div
          className='absolute top-9 left-1.5 text-gray-400 w-6 h-6 z-10 mt-0.5'
>
          {icon}
        </div>
        <input
          ref={ref}
          type={type || 'text'}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          id={name}
          onError={onError}
          onChange={onChange}
          onBlur={onBlur}
          className='block w-full rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700'
          style={{paddingLeft: '30px'}}
        />
      </div>
    </div>
  )
}

export default InputFieldWithIcon
