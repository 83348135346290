const roundToMaxPrecision = (number: number, maxPrecision: number = 6) => {
  const decimalIndex = number.toString().indexOf('.')
  if (decimalIndex === -1) {
    return number // Number is an integer, no rounding needed
  }

  const decimalPlaces = number.toString().slice(decimalIndex + 1).length
  const precision = Math.min(decimalPlaces, maxPrecision)
  return Number(number.toFixed(precision))
}

export default roundToMaxPrecision
