import React, { useState, useEffect } from 'react'
import { Icon, Segment, Header, Loader, Card, Transition } from 'semantic-ui-react'
import moment, { Moment } from 'moment'
import backgroundImagePath from '../../assets/images/illustration__isaac_lefever__wilderness.png'
import parkLogoWhitePath from '../../assets/images/park_logo__white.svg'
import { CampingStyleProps, CampProps, SiteProps } from '../interfaces'
import DatesCard from './Dates/DatesCard'
import SitesCard from './Sites/SitesCard'
import SelectionCard from './DetailsPayment/SelectionCard'
import { formatPhoneNumber } from '../utils'
import { validCampingStyles, validElectric, validRigType } from '../types'
import { CAMPING_STYLES, STATUSES } from '../constants'
import { MapPinIcon } from '@heroicons/react/24/solid'
import CampImagesCarousel from './ui/CampImagesCarousel'
import { BookerProvider } from '../contexts/BookerContext'
import api from '../api2'
import useCampTheme from '../hooks/useCampTheme'
import AppBoundary from './Booker/ErrorBoundaries/AppBoundary'
import CampDecorator from '../decorators/CampDecorator'
import createMarkup from '../utils/createMarkup'
import PrerequisiteProps from '../interfaces/PrerequisiteProps'
import isValidPhoneNumber from '../utils/isValidPhoneNumber'

interface ComponentProps {
  camp: CampProps
  preferred_checkin_times: Record<string, string> // e.g. { campingStyleId: '15:00' }
  preferred_checkout_times: Record<string, string> // e.g. { campingStyleId: '15:00' }
}

const Camp = (props: ComponentProps) => {
  const queryParams = new URLSearchParams(decodeURIComponent(window.location.search))
  const mGroupedReservationId = queryParams.get('grouped_reservation')

  const {
    campPrimaryColor,
    campSecondaryColor,
    campHeaderColor,
    campFontFamily,
    campName,
    campCity,
    campState
  } = useCampTheme(props.camp)

  const [isUserCampingStyleChange, setIsUserCampingStyleChange] = useState<boolean>(false)
  const [camp, setCamp] = useState<CampProps>(props.camp)
  const [startDate, setStartDate] = useState<Moment>()
  const [endDate, setEndDate] = useState<Moment>()
  const [loading, setLoading] = useState<boolean>(true)
  const [isImagesLoading, setIsImagesLoading] = useState<boolean>(true)
  const [isPaying, setIsPaying] = useState(false)
  const [groupIsNotFound, setGroupIsNotFound] = useState(false)

  const [userRigType, setUserRigType] = useState<validRigType>()
  const [userRigLength, setUserRigLength] = useState<number>()
  const [userAmps, setUserAmps] = useState<validElectric>()
  const [userPassengers, setUserPassengers] = useState<number>()
  const [userKidCampers, setUserKidCampers] = useState<number>()

  const [userCampingStyle, setUserCampingStyle] = useState<CampingStyleProps>(
    props.camp.camping_styles?.find(
      (cs) => cs.is_bookable_online && cs.name === queryParams.get('camping_style')
    ) ||
      props.camp.camping_styles?.find(
        (cs) => cs.is_bookable_online && cs.name === CAMPING_STYLES.RV
      ) ||
      props.camp.camping_styles?.filter((cs) => cs.is_bookable_online)[0]
  )

  const [showDatesCard, setShowDatesCard] = useState<boolean>(true)
  const [showSites, setShowSites] = useState<boolean>(false)
  const [showSelectionCard, setShowSelectionCard] = useState(false)
  const [showPayment, setShowPayment] = useState(
    !mGroupedReservationId && userCampingStyle?.is_skipping_site_selection
  )

  const isSkippingSiteSelection = mGroupedReservationId ? false : userCampingStyle?.is_skipping_site_selection
  
  const [activeSite, setActiveSite] = useState<SiteProps>()
  const [imageUrls, setImageUrls] = useState<string[]>([])

  const [groupedReservationId, setGroupedReservationId] = useState(null)
  const [groupedPrerequisites, setGroupedPrerequisites] = useState<PrerequisiteProps[] | null>(null)
  const [unavailableDates, setUnavailableDates] = useState<Record<string, string[]>>({})

  const checkForGroupId = () => {
    if (mGroupedReservationId) {
      api.GroupedReservations.find(mGroupedReservationId)
        .then((grouped_reservation) => {
          setStartDate(moment(grouped_reservation.from_date))
          setEndDate(moment(grouped_reservation.to_date))
          
          const filteredGroupedReservations = (grouped_reservation.reservations || []).filter((res) => res.status !== STATUSES.PAID && res.site.kind !== CAMPING_STYLES.TENT)
          const firstReservation = filteredGroupedReservations[0] || grouped_reservation.reservations[0]
          
          const campingStyleId = firstReservation?.selected_camping_style_id ||
            firstReservation?.site?.camping_style_id
          setGroupedReservationId(grouped_reservation.id)
          setGroupedPrerequisites(grouped_reservation.reservations.map(reservation => props?.camp?.camping_styles?.find((cs) => cs?.id === reservation?.site?.camping_style_id)?.prerequisites)?.flat()?.filter(kind => kind))
          setUserCampingStyle(props.camp.camping_styles?.find((cs) => cs.id === campingStyleId))
        })
        .catch((error) => {
          setGroupIsNotFound(true)
          console.error(error)
        })
    }
  }

  useEffect(() => {
    setCamp(props.camp)
    setLoading(false)

    const qStartDate = queryParams.get('start_date')
    const qEndDate = queryParams.get('end_date')

    if (qStartDate && moment(qStartDate).isValid()) {
      setStartDate(moment(qStartDate))
    }

    if (qEndDate && moment(qEndDate).isValid()) {
      setEndDate(moment(qEndDate))
    }

    checkForGroupId()

    setIsImagesLoading(true)
    api.Camps.fetchImages(camp.id)
      .then(({ images }) => {
        setImageUrls(images.map((image) => image.url))
        setIsImagesLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsImagesLoading(false)
      })
  }, [])

  useEffect(() => {
    if (groupedReservationId) setShowDatesCard(false)
  }, [groupedReservationId])

  useEffect(() => {
    // Reset active/selected sites and do not show sites or selection cards
    setActiveSite(null)

    if (!showDatesCard) {
      setShowSites(true)
      return
    }

    setShowSites(false)
    setShowSelectionCard(false)
  }, [showDatesCard])

  const onDatesComplete = () => {
    // NOTE: Reset active/selected sites
    setActiveSite(null)

    if (startDate && endDate) {
      setShowSites(true)

      if (isSkippingSiteSelection) {
        if (Number(userCampingStyle.prerequisites?.length) < 1) {
          setShowSelectionCard(true)
        } else {
          setShowSites(true)
        }
      }
    } else {
      setShowSites(false)
    }
  }

  useEffect(() => {
    // NOTE: if skipping site selection, use onDatesComplete to show selection card
    if (isSkippingSiteSelection && Number(userCampingStyle.prerequisites?.length) < 1) return

    setShowSelectionCard(!!activeSite)
  }, [activeSite])

  const resetAllPrereq = () => {
    setUserRigType(null)
    setUserRigLength(null)
    setUserAmps(null)
    setUserPassengers(null)
    setUserKidCampers(null)
  }

  useEffect(() => {
    // Reset all prereq info
    resetAllPrereq()
    setIsUserCampingStyleChange(true)
    if (isUserCampingStyleChange && startDate && !groupedReservationId) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [userCampingStyle])

  const onSubmitError = (errorMessage: string) => {
    // NOTE: trigger new fetching of sites
    const og = userRigLength
    setUserRigLength(null)
    setUserRigLength(og)
  }

  const queryObject: { t?: string } = decodeURIComponent(window.location.search)
    .split('?')
    .pop()
    .split('&')
    .reduce((obj, x) => {
      const [key, value] = x.split('=')
      obj[key] = value
      return obj
    }, {})

  const bypassUnpublishedFlag = queryObject.t == 'bypass'

  // TODO: replace all camp references with decoratedCamp via API
  const decoratedCamp = new CampDecorator(camp)

  if (groupIsNotFound) {
    return (
      <>
        <Transition
          show={true}
          enter="transition-opacity duration-700"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div
            id='404-bg-image'
            className='z-0 absolute h-full w-full bg-gray-800'
            style={{
              backgroundImage: `url(${backgroundImagePath})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          />
        </Transition>
        <div className='h-full w-full flex justify-center items-center'>
          <div className='md:mx-0 mx-4 z-10 max-w-screen-sm box-border mt-32 md:mt-72 bg-white rounded-xl md:p-16 p-8 gray-900 shadow-xl bg-opacity-90'>
            <div className='text-5xl font-black mb-8'>
              Oops!
            </div>
            <p className='text-lg'>
              That link is no longer available.
            </p>
            <p className='text-lg mt-4'>
              You can book a reservation by <a href={window.location.pathname}>clicking here</a>, or contact us for a new link.
            </p>
          </div>

          <img
            src={parkLogoWhitePath}
            className='z-10 h-16 absolute md:left-16 bottom-16 filter drop-shadow-xl'
          />
        </div>
      </>
    )
  }

  return (
    <AppBoundary camp={camp}>
      <BookerProvider
        camp={camp}
        preferred_checkin_times={props.preferred_checkin_times}
        preferred_checkout_times={props.preferred_checkout_times}
        campingStyle={userCampingStyle}
        setCampingStyle={setUserCampingStyle}
        selectedSite={activeSite}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        userElectric={userAmps}
        groupedReservationId={groupedReservationId}
        setGroupedReservationId={setGroupedReservationId}
        secondaryColor={campSecondaryColor}
        unavailableDates={unavailableDates}
        setUnavailableDates={setUnavailableDates}>
        {(props.camp.is_published && decoratedCamp.isBookable) || bypassUnpublishedFlag ? (
          loading ? (
            <Loader active />
          ) : (
            <div className='flex-column'>
              <div
                className='flex justify-center h-32 shadow-none drop-shadow-none'
                style={{ backgroundColor: campPrimaryColor }}>
                <div className='ml-4 flex-grow flex items-center my-8'>
                  <div id='camp-text' style={{ color: camp.header_color }}>
                    <h2
                      className='text-2xl sm:text-3xl header pt-0.5'
                      style={{ fontFamily: campFontFamily || 'Josefin sans' }}>
                      {campName}
                    </h2>
                    <span className='subheader flex items-center'>
                      <MapPinIcon className='w-5 h-5 mr-1.5' />
                      <span>
                        {campCity && !campState ? `${campCity}` : `${campCity}, ${campState}`}
                      </span>
                    </span>
                  </div>
                  {props.camp.logo_url && (
                    <img
                      src={props.camp.logo_url}
                      className='h-32 ml-auto py-2 mr-4'
                      style={{ maxWidth: '50%' }}
                    />
                  )}
                </div>
              </div>

              {/* NOTE: only show bio if dates card is visible + camp has bio */}
              <Transition
                animation='fade right'
                duration={300}
                visible={showDatesCard && !!props.camp.bio}>
                {/* TODO: add check if there are images but no bio */}
                <Segment basic>
                  <Header as='h3' content='About Us' />
                  <p
                    id='camp-bio'
                    className='whitespace-pre-wrap'
                    dangerouslySetInnerHTML={createMarkup(props.camp.bio)}></p>
                </Segment>
              </Transition>

              {imageUrls.length > 0 && showDatesCard && (
                <div className='w-full'>
                  <CampImagesCarousel imageUrls={imageUrls} isLoading={isImagesLoading} />
                </div>
              )}

              <DatesCard
                visible={showDatesCard}
                setShowDatesCard={setShowDatesCard}
                onComplete={onDatesComplete}
                resetAllPrereq={resetAllPrereq}
              />
              <SitesCard
                visible={showSites}
                camp={camp}
                startDate={startDate}
                endDate={endDate}
                setShowDatesCard={setShowDatesCard}
                isShowingDatesCard={showDatesCard}
                setActiveSite={setActiveSite}
                userRigType={userRigType}
                setUserRigType={setUserRigType}
                userRigLength={userRigLength}
                setUserRigLength={setUserRigLength}
                userAmps={userAmps}
                setUserAmps={setUserAmps}
                userPassengers={userPassengers}
                setUserPassengers={setUserPassengers}
                userKidCampers={userKidCampers}
                setUserKidCampers={setUserKidCampers}
                userCampingStyle={userCampingStyle}
                showPayment={showPayment}
                groupedPrerequisites={groupedPrerequisites}
                groupId={mGroupedReservationId}
                isSkippingSiteSelection={isSkippingSiteSelection}
              />
              <SelectionCard
                visible={showSelectionCard}
                camp={camp}
                site={activeSite}
                startDate={startDate}
                endDate={endDate}
                setIsPaying={setIsPaying}
                userRigType={userRigType}
                userRigLength={userRigLength}
                userElectric={userAmps}
                userPassengers={userPassengers}
                userKidCampers={userKidCampers}
                userCampingStyle={userCampingStyle}
                onError={onSubmitError}
                skipShowFeatures={isSkippingSiteSelection}
                showPayment={showPayment}
                setShowPayment={setShowPayment}
              />
            </div>
          )
        ) : (
          <div className='flex-column camp-header'>
            <div
              className='flex justify-center h-32 shadow-none drop-shadow-none'
              style={{ backgroundColor: camp.primary_color }}>
              <div className='ml-4 flex-grow flex items-center my-8'>
                <div className='text-white' id='camp-text' style={{ color: camp.header_color }}>
                  <h2 className='text-2xl sm:text-3xl header pt-0.5'>{campName}</h2>
                  <span className='subheader flex items-center'>
                    <MapPinIcon className='w-5 h-5 mr-1.5' />
                    <span>{`${campCity}, ${campState}`}</span>
                  </span>
                </div>
                {props.camp.logo_url && (
                  <img
                    src={props.camp.logo_url}
                    className='h-32 ml-auto py-2 mr-4 '
                    style={{ maxWidth: '50%' }}
                  />
                )}
              </div>
            </div>
            <Card fluid id='coming-soon-segment'>
              <div className='content'>
                <Segment placeholder style={{ textAlign: 'center' }}>
                  <Header icon>
                    <Icon name='map' />
                    {decoratedCamp.bookingStartDatetime
                      ? 'Reservations for next season will open soon!'
                      : 'Coming Soon!'}
                  </Header>
                  <div className='flex justify-center'>
                    {props.camp.is_published ? (
                      decoratedCamp.bookingStartDatetime ? (
                        <div className='max-w-lg'>
                          Reservations for the upcoming season will be available for booking
                          starting on{' '}
                          <span className='font-bold'>
                            {decoratedCamp.bookingStartDatetime.toFormat('LLLL d')} at{' '}
                            {decoratedCamp.bookingStartDatetime.toFormat('h:mm a ZZZZ')}
                          </span>
                          , we look forward to welcoming you soon.
                        </div>
                      ) : (
                        <div className='max-w-md'>
                          Reservations for the upcoming season will be available soon, we look
                          forward to welcoming you.
                        </div>
                      )
                    ) : (
                      <>
                        We're excited to go live with our online bookings soon, in the meantime
                        please call our office at
                        <a href={isValidPhoneNumber(props.camp.phone) && `tel:${props.camp.phone}`} className='ml-1 font-bold'>
                          {props.camp.phone}
                        </a>
                      </>
                    )}
                  </div>
                </Segment>
              </div>
            </Card>
          </div>
        )}
      </BookerProvider>
    </AppBoundary>
  )
}

export default Camp
