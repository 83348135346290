import React, { useContext, useEffect, useState } from 'react'
import iconFor from '../../utils/iconFor'
import {
  CheckCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { formatMoney } from '../../utils/formatMoney'
import { BookerContext } from '../../contexts/BookerContext'
import { IAddOnParam } from '../../api2/price_details'
import IAddOn from '../../interfaces/IAddOn'
import { CampingStyleProps } from '../../interfaces'
import { Moment } from 'moment'
import { useDebouncedCallback } from 'use-debounce'
import api from '../../api2'
import * as Sentry from '@sentry/react'
import classNames from '../../utils/classNames'
import { bySort } from '../../utils/sorts'

const updateAddOnById = (addons: Array<IAddOnParam>, id: string, quantity: number) => {
  let updated = false

  const updatedArr = addons.map((a) => {
    if (a.id === id) {
      updated = true
      return { ...a, quantity }
    } else {
      return a
    }
  })

  if (!updated) {
    updatedArr.push({ id, quantity } as IAddOnParam)
  }

  return updatedArr.filter((a) => a.quantity > 0)
}

interface ComponentProps {
  addOn: IAddOn
  maxQuantity: number
  quantity: number
  onIncrement: () => void
  onDecrement: () => void
  primaryColor: string
}

// TODO: add average price when some are selected for multiple unique prices case
const priceStringFor = (addOn: IAddOn): string => {
  if (addOn.addable_camping_style_id && ![null, undefined].includes(addOn.available_sites)) {
    const uniqueSitePrices = addOn.available_sites
      .map((site) => site.calculated_nightly_price_in_cents)
      .filter((v, i, a) => a.indexOf(v) === i)
    return uniqueSitePrices.length > 1 ? 'Add to see price' : formatMoney(uniqueSitePrices[0] / 100)
  } else {
    return formatMoney(addOn.amount_in_cents / 100)
  }
}

const AddOn = ({
  addOn,
  quantity,
  maxQuantity,
  onIncrement,
  onDecrement,
  primaryColor,
}: ComponentProps) => {
  const { selectedAddOns, setSelectedAddOns } = useContext(BookerContext)
  const isAtQuantityLimit = maxQuantity && quantity >= maxQuantity
  const priceString = priceStringFor(addOn)

  useEffect(() => {
    setSelectedAddOns(updateAddOnById(selectedAddOns, addOn.id, quantity))
  }, [quantity])

  return (
    <div
      className={`w-40 h-40 xs:w-48 xs:h-48 md:w-64 md:h-48 p-3 xs:p-5 border-2 rounded-3xl flex flex-col justify-between`}
      style={
        quantity > 0
          ? { borderColor: primaryColor, background: primaryColor + '25' }
          : { borderColor: 'rgba(209, 213, 219' }
      }>
      <div className='flex flex-col'>
        {iconFor('add-on', addOn.slug) ? (
          <>
            <img
              src={iconFor('add-on', addOn.slug)}
              className={`h-9 w-9 xs:h-12 xs:w-12 mb-2 justify-self-center self-center`}
            />
            <div className='text-lg font-semibold mb-1'>{addOn.label}</div>
            <span
              className='text-gray-500 text-sm leading-tight mb-1 h-full overflow-hidden overflow-ellipsis whitespace-pre-line'
              style={{ maxHeight: '32px' }}>
              {addOn.description}
            </span>
            <span className='text-gray-700 leading-tight'>
              {priceString} {addOn.kind != 'boolean' && quantity > 0 && ' x ' + quantity}
            </span>
          </>
        ) : (
          <>
            <h2 className='text-lg font-bold mb-1'>{addOn.label}</h2>
            <span
              className='text-gray-500 text-sm leading-tight mb-1 h-full overflow-hidden overflow-ellipsis whitespace-pre-line'
              style={{ maxHeight: '78px' }}>
              {addOn.description}
            </span>
            <span className='text-gray-700 leading-tight'>
              {priceString} {addOn.kind != 'boolean' && quantity > 0 && ' x ' + quantity}
            </span>
          </>
        )}
      </div>
      <div className='relative flex justify-end bottom-3 xs:bottom-1 -left-3.5 space-x-11'>
        {quantity > 0 && (
          <button onClick={onDecrement} disabled={quantity <= 0}>
            <div className='w-8 h-8 absolute xs:top-1.5 ml-1.5 bg-white rounded-full z-0' />
            <MinusCircleIcon className='w-11 h-11 text-black absolute z-10' />
          </button>
        )}

        <button
          onClick={onIncrement}
          disabled={(addOn.kind == 'boolean' && quantity > 0) || isAtQuantityLimit}
        className={isAtQuantityLimit && 'cursor-not-allowed'}>
          <div className='w-8 h-8 absolute top-1.5 ml-1.5 bg-white rounded-full z-0' />
          {addOn.kind == 'boolean' && quantity > 0 ? (
            <CheckCircleIcon className='w-11 h-11 text-black absolute z-10' />
          ) : (
            <PlusCircleIcon
              className={classNames(
                'w-11 h-11 absolute z-10',
                isAtQuantityLimit ? 'text-gray-300' : 'text-black'
              )}
            />
          )}
        </button>
      </div>
    </div>
  )
}

const availableAddOnsFrom = (addOns: Array<IAddOn>, selectedSiteId: string, groupedReservationId: string) =>
  addOns
    .map((a) => a.addable_camping_style_id ? { ...a, available_sites: a.available_sites?.filter((s) => s.id !== selectedSiteId) } : a)
    .filter((a) => a.addable_camping_style_id ? !groupedReservationId && Number(a.available_sites?.length) > 0 : true)
    .filter((a) => a.is_bookable)

const AddOns = (props: {
  campingStyle: CampingStyleProps
  startDate: Moment
  endDate: Moment
  primaryColor: string
  selectedSiteId: string
  groupedReservationId: string
}) => {
  const [addOnCounts, setAddOnCounts] = useState({})
  const [addOns, setAddOns] = useState(
    availableAddOnsFrom(
      props.campingStyle.add_ons || [],
      props.selectedSiteId,
      props.groupedReservationId
    )
  )

  useEffect(() => {
    debouncedAddOns(props.campingStyle.id, props.startDate, props.endDate)
  }, [props.campingStyle, props.startDate, props.endDate])

  const debouncedAddOns = useDebouncedCallback(async (campingStyleId, startDate, endDate) => {
    api.AddOns.fetch(campingStyleId, startDate, endDate)
      .then(({ add_ons }) => {
        setAddOns(availableAddOnsFrom(add_ons, props.selectedSiteId, props.groupedReservationId))
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
  }, 500)

  const handleIncrement = (id) => {
    const thisAddOn = addOns.find((a) => a.id === id)
    const newValue = (addOnCounts[id] || 0) + 1

    const isCampingStyle = !!thisAddOn.addable_camping_style_id

    if (isCampingStyle) {
      if (newValue <= thisAddOn.available_sites.length) {
        setAddOnCounts({ ...addOnCounts, [id]: newValue })
      }
    } else {
      setAddOnCounts({ ...addOnCounts, [id]: newValue })
    }
  }

  const handleDecrement = (id) => {
    setAddOnCounts({ ...addOnCounts, [id]: Math.max((addOnCounts[id] || 0) - 1, 0) })
  }

  if (!addOns || addOns.length === 0) {
    return <></>
  }

  return (
    <div className='flex flex-wrap gap-4 gap-y-5'>
      {addOns
        .filter((addon) => !['always-applied', 'hidden'].includes(addon.kind))
        .sort(bySort)
        .map((addOn) => (
          <div className=''>
            <AddOn
              key={addOn.id}
              addOn={addOn}
              quantity={addOnCounts[addOn.id] || 0}
              maxQuantity={addOn.addable_camping_style_id ? addOn.available_sites?.length : null}
              onIncrement={() => handleIncrement(addOn.id)}
              onDecrement={() => handleDecrement(addOn.id)}
              primaryColor={props.primaryColor}
            />
          </div>
        ))}
    </div>
  )
}

export default AddOns
