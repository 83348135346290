import React from 'react'
import { Transition } from '@headlessui/react'

import backgroundImagePath from '../../assets/images/illustration__isaac_lefever__wilderness.png'
import parkLogoWhitePath from '../../assets/images/park_logo__white.svg'

const NotFound = () => {
  return (
    <>
      <Transition
        show={true}
        enter="transition-opacity duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div
          id='404-bg-image'
          className='z-0 absolute h-full w-full bg-gray-800'
          style={{
            backgroundImage: `url(${backgroundImagePath})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        />
      </Transition>
      <div className='h-full w-full flex justify-center items-center'>
        <div className='md:mx-0 mx-4 z-10 max-w-screen-sm box-border bg-white rounded-xl md:p-16 p-8 gray-900 shadow-xl bg-opacity-90'>
          <div className='text-5xl font-black mb-8'>
            Oops!
          </div>
          <p className='text-lg'>
          We couldn’t find the page you were looking for.
          </p>
          <p className='text-lg mt-4'>Please check the URL you entered and try again.</p>
        </div>

        <img
          src={parkLogoWhitePath}
          className='z-10 h-16 absolute md:left-16 bottom-16 filter drop-shadow-xl'
        />
      </div>
    </>
  )
}

export default NotFound
