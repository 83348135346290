import { ELECTRICS } from '../../constants'
import { SiteProps } from '../../interfaces'

export const createElectricOptions = (site?: SiteProps) => {

  const electricOption = site?.electric?.split('/')?.map((electric) => {
    return { label: electric === ELECTRICS.NONE ? 'Non-electric' : `${electric}-amp`, value: electric }
  })

  if (!electricOption?.length) return []
  
  return [
    { label: '\u00A0', value: null },
    ...electricOption,
  ]
}
