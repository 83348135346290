import moment from 'moment'
import { ReservationProps } from '../interfaces'
import IInvoice from '../interfaces/IInvoice'
import IOrder from '../interfaces/IOrder'
import { STATUSES } from '../constants'

export enum INVOICE_STATUSES {
  DRAFT = 'draft',
  QUEUED = 'queued',
  UPCOMING = 'upcoming',
  OPEN = 'open',
  OVERDUE = 'overdue',
  PAID = 'paid'
}

class InvoiceDecorator {
  id: string
  hash_id: string
  send_date: string
  due_date: string
  is_auto_send: boolean
  recipient_email: string
  recipient_phone: string
  recipient_name: string
  recipient_address: string
  memo: string
  deleted_at: string
  order_id: string
  order: IOrder
  reservation?: ReservationProps
  status: INVOICE_STATUSES

  constructor(public invoice: IInvoice) {
    Object.assign(this, invoice)
  }
}

export default InvoiceDecorator
