import React, { useState } from 'react'
import { Header, Icon, Segment, Grid, Accordion, Image } from 'semantic-ui-react'
import moment from 'moment'

import { CampingStyleProps, CampProps, ReservationProps, SiteProps } from '../interfaces'
import { titleCase } from '../utils'
import { MapPinIcon } from '@heroicons/react/24/solid'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isMobile } from 'react-device-detect'
import ReferralModal from './Confirmation/ReferralModal'
import { KINDS, PAYMENT_MODES } from '../constants'
import createMarkup from '../utils/createMarkup'
import { DateTime } from 'luxon'

const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

interface ComponentProps {
  reservation: ReservationProps
  email: string
  site: SiteProps
  camp: CampProps
  is_admin: boolean
  camping_style: CampingStyleProps
}

const Confirmation = (props: ComponentProps) => {
  const showCampgroundRules = props.camp.rules ? true : false

  const isHourly = !!props.camping_style?.default_hours
  const isMapPresent = props.camping_style.map_url || props.camp.map_url

  const startDate = DateTime.fromISO(props.reservation.start_date).setZone(props.camp.timezone)
  const endDate = DateTime.fromISO(props.reservation.end_date).setZone(props.camp.timezone)
  const amountPaid = usd.format(
    (props.reservation.total_owed_in_cents || props.reservation.total) / 100
  )

  const [isActive, setIsActive] = useState(true)

  const handleFormClick = () => {
    setIsActive(!isActive)
  }

  let checkinTime: string
  let checkoutTime: string

  checkinTime = startDate.toFormat('h:mma')
  checkoutTime = endDate.toFormat('h:mma')

  const renderedMap = isMobile ? (
    <Image src={props.camping_style.map_url || props.camp.map_url} alt='No map available.' fluid />
  ) : (
    <TransformWrapper>
      <TransformComponent wrapperStyle={{ width: '100%' }} contentStyle={{ width: '100%' }}>
        <Image
          src={props.camping_style.map_url || props.camp.map_url}
          alt='No map available.'
          fluid
        />
      </TransformComponent>
    </TransformWrapper>
    )
  
  return (
    <>
      <div
        className='flex justify-center h-32 shadow-none drop-shadow-none'
        style={{ backgroundColor: props.camp.primary_color }}>
        <div className='ml-4 flex-grow flex items-center my-8'>
          <div style={{ color: props.camp.header_color }} id='camp-text'>
            <h2
              className='text-2xl sm:text-3xl header pt-0.5'
              style={{ fontFamily: 'Josefin sans' }}>
              {props.camp.name}
            </h2>
            <span className='subheader flex items-center'>
              <MapPinIcon className='w-5 h-5 mr-1.5' />
              <span>
                {props.camp.city && !props.camp.state
                  ? `${props.camp.city}`
                  : `${props.camp.city}, ${props.camp.state}`}
              </span>
            </span>
          </div>
          {props.camp.logo_url && (
            <img
              src={props.camp.logo_url}
              className='h-32 ml-auto py-2 mr-4 '
              style={{ maxWidth: '50%' }}
            />
          )}
        </div>
      </div>

      <ReferralModal show={!props.is_admin} setShow={() => null} camp={props.camp} />

      <Segment id='confirmation-column'>
        <Grid stackable columns={isMapPresent ? 2 : 1}>
          {isMapPresent && (
            <Grid.Column only='tablet computer'>
              <Header as='h3' content='Map' />
              {renderedMap}
            </Grid.Column>
          )}
          <Grid.Column>
            <Header as='h3' textAlign='center'>
              Reservation Details
            </Header>
            <Grid basic='very'>
              <Grid.Row>
                <Grid.Column floated='left' width={5}>
                  Location:{' '}
                </Grid.Column>
                <Grid.Column floated='right' width={11} textAlign='right'>
                  <strong>
                    {props.camp.address}, {props.camp.city}, {props.camp.state}
                  </strong>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column floated='left' width={5}>
                  Site:
                </Grid.Column>
                <Grid.Column floated='right' width={11} textAlign='right'>
                  <strong>
                    {!props.camping_style.is_skipping_site_selection &&
                    props.camping_style.is_showing_site_in_notifiers
                      ? titleCase(props.site.name)
                      : 'Assigned upon arrival'}
                  </strong>
                </Grid.Column>
              </Grid.Row>

              {isHourly ? (
                <>
                  <Grid.Row>
                    <Grid.Column floated='left' width={5}>
                      Start time:
                    </Grid.Column>
                    <Grid.Column floated='right' width={11} textAlign='right'>
                      <strong>{startDate.toFormat('MMM d, yyyy')} </strong>at{' '}
                      <strong> {checkinTime}</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column floated='left' width={5}>
                      End time:
                    </Grid.Column>
                    <Grid.Column floated='right' width={11} textAlign='right'>
                      <strong>{endDate.toFormat('MMM d, yyyy')} </strong>at{' '}
                      <strong> {checkoutTime}</strong>
                    </Grid.Column>
                  </Grid.Row>
                </>
              ) : (
                <>
                  <Grid.Row>
                    <Grid.Column floated='left' width={5}>
                      Arrival:
                    </Grid.Column>
                    <Grid.Column floated='right' width={11} textAlign='right'>
                      <strong>{startDate.toFormat('MMM d, yyyy')} </strong>after{' '}
                      <strong> {checkinTime}</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column floated='left' width={5}>
                      Departure:
                    </Grid.Column>
                    <Grid.Column floated='right' width={11} textAlign='right'>
                      <strong>{endDate.toFormat('MMM d, yyyy')} </strong>before{' '}
                      <strong> {checkoutTime}</strong>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <Grid.Column floated='left' width={5}>
                  Check-in policy:
                </Grid.Column>
                <Grid.Column
                  floated='right'
                  width={11}
                  textAlign='right'
                  className='whitespace-pre-wrap'
                  dangerouslySetInnerHTML={createMarkup(
                    [
                      props.camping_style.checkin_policy || '',
                      props.site.checkin_policy_additions || '',
                    ].join('\n')
                  )}></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column floated='left' width={5}>
                  Cancellation policy:
                </Grid.Column>
                <Grid.Column
                  floated='right'
                  width={11}
                  textAlign='right'
                  className='whitespace-pre-wrap'
                  dangerouslySetInnerHTML={createMarkup(
                    props.camping_style.refund_policy
                  )}></Grid.Column>
              </Grid.Row>

              {props.camp.wifi_network && (
                <Grid.Row>
                  <Grid.Column floated='left' width={5}>
                    Wi-Fi network name:
                  </Grid.Column>
                  <Grid.Column floated='right' width={11} textAlign='right'>
                    <strong>{props.camp.wifi_network}</strong>
                  </Grid.Column>
                </Grid.Row>
              )}

              {props.camp.wifi_password && (
                <Grid.Row>
                  <Grid.Column floated='left' width={5}>
                    Wi-Fi password:
                  </Grid.Column>
                  <Grid.Column floated='right' width={11} textAlign='right'>
                    <strong>{props.camp.wifi_password}</strong>
                  </Grid.Column>
                </Grid.Row>
              )}

              {props.camp.bathroom_code && (
                <Grid.Row>
                  <Grid.Column floated='left' width={5}>
                    Bathroom code:
                  </Grid.Column>
                  <Grid.Column floated='right' width={11} textAlign='right'>
                    <strong>{props.camp.bathroom_code}</strong>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column floated='left' width={5}>
                  Amount:
                </Grid.Column>
                <Grid.Column floated='right' width={11} textAlign='right'>
                  <strong>{amountPaid}</strong>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {props.reservation.payment_mode === PAYMENT_MODES.ONLINE && (
              <p className='confirmation-paragraph'>
                We’ve sent a reservation confirmation with your site info and a receipt to your
                email, <strong>{props.email}</strong>. We look forward to your stay!
              </p>
            )}
          </Grid.Column>
          {isMapPresent && (
            <Grid.Column only='mobile' id='columnStyle'>
              <Accordion fluid>
                <Accordion.Title active={isActive} index={0} onClick={handleFormClick}>
                  <Icon name='dropdown' /> <b>Map</b>
                </Accordion.Title>
                <Accordion.Content active={isActive}>{renderedMap}</Accordion.Content>
              </Accordion>
            </Grid.Column>
          )}
        </Grid>
      </Segment>

      {showCampgroundRules ? (
        <div
          className={`${
            !props.camp.rules.includes('ui placeholder segment') &&
            'rules-container bg-gray-50 p-4 mx-4 border border-gray-200 shadow-sm rounded-md whitespace-pre-wrap'
          }`}
          dangerouslySetInnerHTML={createMarkup(props.camp.rules)}
        />
      ) : (
        <div></div>
      )}

      <Header as='h4' className='confirmation-footer'>
        You may close this window.
      </Header>
      <br></br>
    </>
  )
}

export default Confirmation
