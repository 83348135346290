const copyText = (str: string) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(str)
  } else {
    const el = document.createElement('textarea')
    el.value = str
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }
}

export default copyText
