import React, { useContext, useEffect, useState } from 'react'
import { RegisterOptions, UseFormRegisterReturn, UseFormReturn } from 'react-hook-form'
import { CampProps, CampingStyleProps } from '../../../interfaces'
import RichTextInput from '../../ui/RichTextInput'
import NumberInput from '../../ui/NumberInput'
import api from '../../../api2'
import { useDebouncedCallback } from 'use-debounce'
import { AdminContext } from '../../../contexts/AdminContext'
import Pricing from './Pricing'

interface ComponentProps {
  reactHookForm: UseFormReturn<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>
}

const InvoicesSettings = ({ reactHookForm }: ComponentProps) => {
  const { register, setValue, watch } = reactHookForm

  const { camp  } = useContext(AdminContext)

  const memo = watch('default_invoice_memo')
  const electricRate = watch('electricity_rate')

  const changeMemo = (value: string) => {
    setValue('default_invoice_memo', value)
  }

  useEffect(() => {
    register('default_invoice_memo', {})
    register('electricity_rate', {})
  }, [])

  return (
    <div className='flex flex-col gap-3'>
      <Pricing reactHookForm={reactHookForm} />
      <NumberInput
        label='Electric price'
        prefix='$'
        suffix={camp.currency.toUpperCase()}
        field='electricity_rate'
        resource={{ electricRate }}
        register={register}
      />
      <div className=''>
        <label className='block text-sm font-semibold leading-6 text-gray-900 mb-4'>Default memo:</label>
        <RichTextInput value={memo} setValue={changeMemo} />
      </div>
    </div>
  )
}

export default InvoicesSettings
