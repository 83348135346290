import { discountType } from "../constants"
import { validElectric } from "../types"
import { get, post } from "./utils/requests"

export type UpsertData = {
  id: string
  camping_style_id: string
  site_id: string
  start_date: string
  end_date: string
  user_electric: validElectric
  user_passengers: number
  user_kid_campers: number
  number_of_pets: number
  discount_type?: discountType
  discount_membership_id?: string
  payment_mode?: string
}

const upsert = (data: UpsertData) => post('/payments/intent', data)
const fetch = (intent_id: string) => get(`/payments/intent/${intent_id}`)

const PaymentIntents = {
  fetch,
  upsert
}

export default PaymentIntents
