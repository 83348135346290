import { UserProps } from '../interfaces'
import { patch } from './utils/requests'

type UpdateProps = {
  first_name: string
  last_name: string
  email: string
  phone: string
  license_plate: string
  vehicle_year: string
  vehicle_make: string
  vehicle_color: string
  vehicle_rig_type: string
  vehicle_rig_length: number
  vehicle_electric: string
  pets_info: string
  camper_id?: string
}

const update = (id: string, data: UpdateProps): Promise<UserProps> =>
  patch(`/users/${id}`, data)

const Users = {
  update
}

export default Users
