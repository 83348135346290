import React, {
  createContext,
  FormEvent,
  FormEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import api from '../../api2'
import { ReservationProps, UserProps } from '../../interfaces'
import { AdminContext } from '../AdminContext'
import ICamper from '../../interfaces/ICamper'
import { formatName } from '../../utils/formatName'
import { IUpdatingFormData } from '../../components/Admin/Reservations/ConfirmationModalContent'
import { STATUSES } from '../../constants'
import { isEqual, update } from 'lodash'

interface ICtxPayload {
  reservation: ReservationProps
  setReservation: (v: ReservationProps) => void
  onUpdateReservation: (e: FormEvent<HTMLFormElement>, isForPayNow?: boolean) => Promise<void>
  refUpdateReservationForm: React.MutableRefObject<HTMLFormElement>
  isSendingEmail: boolean
  setIsSendingEmail: (v: boolean) => void
  isSendingEmailDisabled: boolean
  setIsSendingEmailDisabled: (v: boolean) => void
  errorMessage: string
  setErrorMessage: (v: string) => void
  updateLocalReservation: (reservation: ReservationProps, user?: UserProps) => any
  siteId: string
  setSiteId: (v: string) => void
}

const initialContext = {
  reservation: null,
  setReservation: null,
  onUpdateReservation: null,
  refUpdateReservationForm: null,
  isSendingEmail: null,
  setIsSendingEmail: null,
  isSendingEmailDisabled: null,
  setIsSendingEmailDisabled: null,
  errorMessage: null,
  setErrorMessage: null,
  updateLocalReservation: null,
  siteId: null,
  setSiteId: null,
}

export const EditReservationContext = createContext<ICtxPayload>(initialContext)

export const EditReservationProvider = ({
  reservation,
  selectedStartDate,
  selectedEndDate,
  setIsUpdatingReservation,
  children,
}) => {
  const {
    reservationStates,
    setReservationStates,
    setSelectedReservation,
    showReservationsConfirmation,
    showNotification,
    isParkAdminUser,
    setSelectedCamper
  } = useContext(AdminContext)

  const [editedReservation, setEditedReservation] = useState(reservation)
  const [isSendingEmail, setIsSendingEmail] = useState(!!reservation?.user?.email)
  const [isSendingEmailDisabled, setIsSendingEmailDisabled] = useState(!reservation?.user?.email)
  const [siteId, setSiteId] = useState(reservation?.site_id)
  const refUpdateReservationForm = useRef()

  const [errorMessage, setErrorMessage] = useState('')

  const reservationDataFromSubmit = (e) => {
    let data = { id: e.target['id'].value || reservation.id, site_id: siteId }

    const owed_input = e.target['total_owed_in_cents']
    const paid_input = e.target['total_paid_in_cents']
    const passengers_input = e.target['passengers']
    const kids_input = e.target['kid_campers']
    const pets_info_input = e.target['pets_info']
    const notes_input = e.target['notes']

    if (!owed_input?.disabled) data['unpaid_price_in_cents'] = Math.round(Number(owed_input?.value) * 100)
    if (!paid_input?.disabled) data['total_paid_in_cents'] = Math.round(Number(paid_input?.value) * 100)
    if (!passengers_input?.disabled) data['passengers'] = passengers_input?.value
    if (!kids_input?.disabled) data['kid_campers'] = kids_input?.value
    if (!pets_info_input?.disabled) data['pets_info'] = pets_info_input?.value
    if (!notes_input?.disabled) data['notes'] = notes_input?.value

    // NOTE: ReactDates library renders inputs after the dom loads, so the form cannot access the
    // fields like other inputs.
    data['start_date'] = selectedStartDate?.toISOString()
    data['end_date'] = selectedEndDate?.toISOString()

    data['trigger_update_emails'] = isSendingEmail

    if (!owed_input?.value) delete data['unpaid_price_in_cents']
    if (!paid_input?.value) delete data['total_paid_in_cents']
    data['long_term_reservation'] = reservation?.long_term_reservation

    return data
  }

  const applyFormDataToConfirmationState = (id: string, reservationData: Partial<ReservationProps>, userData: UserProps) => {
    const persisted = reservationStates[id].persisted
    const updated = reservationStates[id].updated || reservationStates[id].persisted
    return {
      persisted: persisted,
      updated: { ...updated, ...reservationData, user: userData },
    }
  }

  const prepareData = (e: FormEvent<HTMLFormElement>) => {
    const reservationId = e.target['id'].value
    const userId = e.target['userId'].value
    const camperId = e.target['camperId'].value
    const camperData = {
      vehicle_year: e.target['vehicle_year']?.value,
      vehicle_make: e.target['vehicle_make']?.value,
      vehicle_color: e.target['vehicle_color']?.value,
      vehicle_rig_type: e.target['vehicle_rig_type']?.value,
      vehicle_rig_length: e.target['vehicle_rig_length']?.value,
      license_plate: e.target['vehicle_license_plate']?.value,
      vehicle_electric: e.target['vehicle_electric']?.value,
    }
    const reservationData: Partial<ReservationProps> = reservationDataFromSubmit(e)
    const userData = {
      first_name: e.target['first_name']?.value,
      last_name: e.target['last_name']?.value,
      email: e.target['email']?.value,
      phone: e.target['phone']?.value,
      vehicle_year: e.target['vehicle_year']?.value,
      vehicle_make: e.target['vehicle_make']?.value,
      vehicle_color: e.target['vehicle_color']?.value,
      vehicle_rig_type: e.target['vehicle_rig_type']?.value,
      vehicle_rig_length: e.target['vehicle_rig_length']?.value,
      license_plate: e.target['vehicle_license_plate']?.value,
      vehicle_electric: e.target['vehicle_electric']?.value,
      /** @deprecated, persisted in reservation now */
      pets_info: e.target['pets_info']?.value,
      camper_id: camperId 
    }

    return {
      reservationId,
      userId,
      camperId,
      camperData,
      reservationData,
      userData      
    }
  }

  const isConfirmationNeeded = (
    reservationData: Partial<ReservationProps>,
    userData: Partial<UserProps>
  ): boolean => {
    const { start_date, end_date, site_id, passengers, kid_campers } = reservationData
    const { vehicle_electric } = userData
    const persisted = reservationStates[reservationData.id]?.persisted

    if (!persisted) return false

    if (persisted.status === STATUSES.BLOCKED || persisted.status === STATUSES.IMPORTED) {
      return false
    }

    if (
      (start_date && !isEqual(start_date, persisted.start_date)) ||
      (end_date && !isEqual(end_date, persisted.end_date)) ||
      (site_id && !isEqual(site_id, persisted.site_id)) ||
      (passengers && !isEqual(Number(passengers), Number(persisted.passengers))) ||
      (kid_campers && !isEqual(Number(kid_campers), Number(persisted.kid_campers))) ||
      (vehicle_electric && !isEqual(vehicle_electric, persisted.user?.vehicle_electric))
    ) {
      return true
    }

    return false
  }

  const onUpdateReservation = async (e: FormEvent<HTMLFormElement>, isForPayNow = false) => {
    e.preventDefault()

    const preparedData = prepareData(e)
   
    const isConfirming = isForPayNow
      ? false
      : isConfirmationNeeded(preparedData.reservationData, preparedData.userData)

    setSelectedCamper(null)

    if (isParkAdminUser) console.log('Reservation ID:', preparedData.reservationId)

    if (isConfirming) {
      const reservationState = applyFormDataToConfirmationState(
        preparedData.reservationId,
        preparedData.reservationData,
        preparedData.userData
      )

      showReservationsConfirmation([reservationState], async (data) => {
        setIsUpdatingReservation(true)
        const thisData = data.find((d) => d.id === preparedData.reservationId)
        const requestData = {
          ...preparedData.reservationData,
          start_date: thisData.start_date,
          end_date: thisData.end_date,
          site_id: thisData.site_id,
          passengers: thisData.passengers,
          kid_campers: thisData.kid_campers,
          user_electric: preparedData.userData.vehicle_electric,
          is_updating_series: thisData.is_updating_series,
          is_auto_updating_price: thisData.is_auto_updating_price,
        }
        console.log('Request Data: ', requestData)
        return sendRequests({ ...preparedData, reservationData: requestData }, true)
      })
    } else {
      setIsUpdatingReservation(true)
      return sendRequests(preparedData)
    }
  }

  const sendRequests = async (data: ReturnType<typeof prepareData>, isThrowing = false) => {
    const { reservationId, userId, camperId, camperData, reservationData, userData } = data

    let requests: Promise<any>[] = [api.Reservations.update(reservationId, reservationData)]

    if (camperId) {
      requests.push(api.Camps.updateCamper(camperId, camperData as ICamper))
    }

    console.log('Requests: ', requests)

    try {
      const [{ reservation, following_recurrences }, userData] = await Promise.all(requests)
      setIsUpdatingReservation(false)
      updateLocalReservation({ ...reservation, long_term_reservation: reservationData.long_term_reservation }, userData?.user)
      following_recurrences?.forEach((r) => {
        updateLocalReservation({ ...r, long_term_reservation: reservationData.long_term_reservation }, userData?.user)
      })
      return Promise.resolve(reservation)
    } catch (err) {
      setIsUpdatingReservation(false)
      if (isThrowing) throw err
      setErrorMessage(err.message)
      console.error(err)
    }
  }

  const updateLocalReservation = (reservation: ReservationProps, user?: UserProps) => {
    setIsUpdatingReservation(false)
    setSelectedReservation(null)
    if (isParkAdminUser) console.log('User: ', user)
    showNotification({
      type: 'success',
      title: 'Changes saved!',
      description: `Sucessfully updated reservation${
        user && (user.first_name || user.last_name || user.email)
          ? ' for ' + (user.first_name || user.last_name ? formatName(user) : user.email)
          : ''
      }`,
    })

    // NOTE: reconstruct reservation with updated user data too
    let updated: ReservationProps = { ...reservation }

    if (user) {
      updated = { ...reservation, user }
    }

    const mReservations = { ...reservationStates }
    mReservations[updated.id].persisted = { ...updated }
    setReservationStates(mReservations)
  }

  useEffect(() => {
    if (!reservation) {
      setErrorMessage('')
    } else {
      setSiteId(reservation.site_id)
    }
  }, [reservation])

  return (
    <EditReservationContext.Provider
      value={{
        reservation: editedReservation,
        setReservation: setEditedReservation,
        onUpdateReservation,
        refUpdateReservationForm,
        isSendingEmail,
        setIsSendingEmail,
        isSendingEmailDisabled,
        setIsSendingEmailDisabled,
        errorMessage,
        setErrorMessage,
        updateLocalReservation,
        siteId,
        setSiteId,
      }}>
      {children}
    </EditReservationContext.Provider>
  )
}
