import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { BanknotesIcon, CreditCardIcon, LinkIcon } from '@heroicons/react/20/solid'
import classNames from '../../../utils/classNames'

interface ComponentProps {
  label?: string
  options: Array<string>
  selectedOption: any
  onChange?: any
}

const PaymentTypeSelector = (props: ComponentProps) => {
  return (
    <RadioGroup className='mb-4' value={props.selectedOption}>
      <RadioGroup.Label className='text-base text-gray-900'>{props.label}</RadioGroup.Label>

      <div className='flex flex-row gap-4'>
        {props.options.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option}
            onClick={() => {
              props.onChange(option)
            }}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-1 ring-green-600' : '',
                'flex-grow relative bg-white border rounded-lg shadow-sm p-2.5 flex cursor-pointer focus:outline-none'
              )
            }>
            {({ checked, active }) => (
              <>
                <div className='flex-grow flex justify-start'>
                  <div className='flex items-center text-center justify justify-items-center mt-0.5'>
                    <Icon
                      name={String(option)}
                      className={classNames(
                        'w-5 h-5 self-center ml-0',
                        checked ? 'text-green-600' : 'text-gray-900'
                      )}
                    />
                    <RadioGroup.Label
                      as='span'
                      className={classNames(
                        'flex text-sm ml-2 leading-tight',
                        checked ? 'text-green-600 font-semibold' : 'text-gray-900 font-medium'
                      )}>
                      {Label(String(option))}
                    </RadioGroup.Label>
                  </div>
                </div>
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-green-600' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const Icon = ({ name, className }: { name: string; className: string }) => {
  switch (name) {
    case 'card-input':
      return <CreditCardIcon className={className} />
    case 'link':
      return <LinkIcon className={className} />
    case 'cash':
      return <BanknotesIcon className={className} />
    default:
      console.error('Missing icon for:', name)
      return <svg />
  }
}

const Label = ( name: string ) => {
  switch (name) {
    case 'card-input':
      return 'Credit card'
    case 'link':
      return 'Payment link'
    case 'cash':
      return 'Offline'
    default:
      break
  }
}


export default PaymentTypeSelector
