import * as React from 'react'
import { titleCase } from '../../utils'

import { SiteProps } from '../../interfaces'

import iconFor from '../../utils/iconFor'
import { ELECTRICS, KINDS } from '../../constants'
import ExternalSvg from '../ui/ExternalSvg'
import formatCampingStyleName from '../../utils/formatCampingStyleName'

let featureText: object = {
  camper_capacity: '?',
  kind: '?',
  pad_type: '?',
  max_rig_length: 'Max rig length: ?',
  extra_car_space: 'Extra car space',
  electric: 'Electric, ? Amps',
  has_wifi: 'Wifi',
  water: 'Water',
  sewage: 'Sewage',
  public_bathrooms: 'Bathrooms',
  showers: '? showers',
  has_laundry: 'Laundry',
  pets: 'Pets allowed',
  public_kitchen: 'Public kitchen access',
  dump_station: 'Dump station available',
  dumpster: 'Dumpsters',
  picnic_table: 'Picnic table',
  cable_tv: 'Cable TV',
  fire_pit: 'Fire pit',
  cellular: 'Mobile cell reception',
  dog_park: 'Dog park',
  general_store: 'General store',
  pool: 'Pool',
  premium_view: 'Premium view',
  has_heating: 'Heater',
  has_microwave: 'Microwave',
  has_oven: 'Oven',
  has_refrigerator: 'Refrigerator',
  has_stove: 'Stove',
  has_air_conditioning: 'Air-conditioning',
  has_private_bathroom: 'Private bathroom',
  number_of_futon_beds: '? futon bed',
  number_of_king_beds: '? king bed',
  number_of_queen_beds: '? queen bed',
  number_of_full_beds: '? full bed',
  number_of_twin_beds: '? twin bed',
  number_of_single_beds: '? single bed',
  number_of_bunk_beds: '? bunk bed',
  has_grill_barbecue: 'Grill/Barbecue',
  has_restaurant: 'Restaurant',
  has_tv: 'TV',
  has_playground: 'Playground',
  has_propane_fill_up: 'Propane fill-up',
  has_volleyball_court: 'Volleyball court',
  has_basketball_court: 'Basketball court',
  disc_golf_course: 'Disc golf course',
  has_golf_course: 'Golf course',
  has_mini_golf_course: 'Mini golf course',
  has_community_fire_pit: 'Community fire pit',
  has_hotplate: 'Hotplate',
  has_towels: 'Bring your own towels',
}

const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

interface componentProps {
  name: keyof SiteProps
  value: any
  site: SiteProps
}

const hiddenIfNull = ['has_linens', 'fire_pit', 'picnic_table']

const Feature = (props: componentProps) => {
  if (!iconFor(props.name, props.value) && props.name != 'electric' && props.name != 'kind') {
    return null
  }

  if (hiddenIfNull.includes(props.name) && (props.value === undefined || props.value === null)) {
    return null
  }

  const name = props.name
  let value = props.value

  if (name == 'showers') {
    value = titleCase(value)
  }

  const featureTextCheck = () => {
    if (name === 'camper_capacity') {
      return featureText[name].replace('?', 'Max capacity: ' + value)
    } else if (name == 'max_rig_length') {
      return featureText[name].replace('?', value + ' feet')
    } else if (name === 'has_laundry') {
      return displayTextForLaundry(name, value, props.site.laundry_cost)
    } else if (name === 'fire_pit') {
      return value ? featureText[name].replace('?', value) : 'No fire pits available'
    } else if (name === 'picnic_table') {
      return value ? featureText[name].replace('?', value) : 'No picnic tables available'
    } else if (name === 'has_linens' && value !== undefined) {
      return value ? 'Linens provided' : 'Bring your own linens'
    } else if (name === 'water' && value === false) {
      return 'No water available at this site'
    } else if (name === 'has_towels' && value !== undefined) {
      return value ? 'Linens provided' : 'Bring your own linens'
    } else if (name == 'electric') {
      if (value == ELECTRICS.NONE) {
        return 'No electric available at this site'
      } else if (value === ELECTRICS.BASIC) {
        return 'Electricity'
      } else {
        return featureText[name].replace('?', value)
      }
    } else if (name == 'has_wifi' && value) {
      let wifiText = featureText[name]

      if (props.site.wifi_extra_charge_cents) {
        wifiText += ` (extra charge of ${usd.format(props.site.wifi_extra_charge_cents / 100)})`
      }
      return wifiText
    } else if (name == 'kind') {
      if (value === KINDS.RV_HYBRID) {
        return 'You may need to back-in or pull-through'
      } else {
        return featureText[name].replace('?', formatCampingStyleName(value))
      }
    } else if (name == 'pad_type' && value) {
      return `${titleCase(value)} surface`
    } else if (name.includes('beds')) {
      if (value === 1) {
        return featureText[name].replace('?', value)
      } else {
        return featureText[name].replace('?', value) + 's'
      }
    } else {
      return featureText[name].replace('?', value)
    }
  }

  return (
    props.value != null && (
      <div className='flex flex-row'>
        {props.name === "kind" && props.site.icon_url ? (
          <ExternalSvg url={props.site.icon_url} className={`h-12 w-12`} asImg />
        ) : (
          <img src={iconFor(props.name, props.value)} className='h-12 w-12' />
        )}
        <div className='flex items-center -mt-0.5' style={{ marginLeft: '1rem' }}>
          {featureTextCheck()}
        </div>
      </div>
    )
  )
}

const displayTextForLaundry = (name: string, value: boolean, cost: number) => {
  if (!value) return 'No laundry available'

  let suffix = ''

  if (cost !== null) {
    suffix = cost == 0 ? ', free-to-use' : `, pay-per-use ${usd.format(cost)}`
  }

  return featureText[name] + suffix
}

export default Feature
