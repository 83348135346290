import React, { useContext } from 'react'
import { Form, Select } from 'semantic-ui-react'
import { BookerContext } from '../../../contexts/BookerContext'
import { SiteProps } from '../../../interfaces'
import electricOptions from './electricOptions'

interface ComponentProps {
  sites: SiteProps[]
  userAmps: string
  handleAmpsChange: (e: React.SyntheticEvent, obj: object) => void
}

const ElectricField = ({ sites, userAmps, handleAmpsChange }: ComponentProps) => {
  const { campingStyle } = useContext(BookerContext)

  const campingStyleSites = sites.filter((site) => site.camping_style_id == campingStyle.id)

  return (
    <Form.Field required>
      <label>Preferred amperage</label>
      <Select
        clearable
        fluid
        className='sites-filter-dropdown'
        options={electricOptions(campingStyleSites)}
        value={userAmps}
        onChange={handleAmpsChange}
      />
    </Form.Field>
  )
}

export default ElectricField
