import React, { useContext, useEffect } from 'react'
import { titleCase } from '../../utils'
import classNames from '../../utils/classNames'
import pluralize from '../../utils/pluralize'
import { PriceDetails as IPriceDetails } from '../../utils/priceDetailsFor'
import dynamicRound from '../../utils/dynamicRound'
import { AdminContext } from '../../contexts/AdminContext'
import { formatMoney } from '../../utils/formatMoney'
import { CampingStyleProps } from '../../interfaces'
import { NewReservationContext } from '../../contexts/admin/NewReservationContext'
import { LONG_TERM_RESERVATION_KINDS } from '../../constants'

const money = Intl.NumberFormat('us-en', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

interface ComponentProps {
  priceDetail: IPriceDetails
  isRecurring: boolean
  taxRate: number
  amountPaid?: number
  campingStyle: CampingStyleProps
  isShowingTax?: boolean
}

const PriceDetail = ({
  priceDetail,
  isRecurring,
  taxRate,
  amountPaid,
  campingStyle,
  isShowingTax,
}: ComponentProps) => {
  const { camp } = useContext(AdminContext)
  const newReservationData = useContext(NewReservationContext)

  

  useEffect(() => {
    console.log(newReservationData)
  }, [isShowingTax])

  return (
    <div className='bg-gray-50 p-4 rounded-md whitespace-nowrap'>
      <div className='text-lg font-bold mb-4'>
        {isRecurring &&
        (newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY ||
          newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY)
          ? 'Season'
          : isRecurring
          ? 'Monthly'
          : 'Reservation'}{' '}
        price
      </div>
      <div className='flex flex-col justify-between'>
        <div className='flex flex-col'>
          {priceDetail?.flat_rate_price ? (
            <>
              <div className='mb-2 flex justify-between w-full'>
                <div>Flat rate</div>
                <div className='ml-8'>
                  {formatMoney(priceDetail?.flat_rate_price, camp.currency)}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='mb-2 flex justify-between w-full'>
                {isRecurring &&
                (newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY ||
                  newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY) ? (
                  <div>Season subtotal</div>
                ) : isRecurring ? (
                  <div>Monthly subtotal</div>
                ) : (
                  <div>
                    {formatMoney(priceDetail.nightly_price, camp.currency)} x{' '}
                    {Number(priceDetail.nights)}{' '}
                    {pluralize(priceDetail.frequency_label, priceDetail.nights)}
                  </div>
                )}
                <div className='ml-8'>{formatMoney(priceDetail.subtotal, camp.currency)}</div>
              </div>
            </>
          )}
          {!!priceDetail.holiday_nights && priceDetail.holiday_nightly_fee ? (
            <div className='mb-2 flex justify-between w-full'>
              <div className=''>
                {`Holiday ${formatMoney(priceDetail.holiday_nightly_fee, camp.currency)} x ${
                  priceDetail.holiday_nights
                } ${pluralize(priceDetail.frequency_label, priceDetail.holiday_nights)}`}
              </div>
              <div className='text-right'>
                {formatMoney(priceDetail.holiday_total_fee, camp.currency)}
              </div>
            </div>
          ) : (
            <></>
          )}
          {!!priceDetail.weekend_nights && priceDetail.weekend_nightly_fee ? (
            <div className='mb-2 flex justify-between w-full'>
              <div className=''>
                {`Weekend ${formatMoney(priceDetail.weekend_nightly_fee, camp.currency)} x ${
                  priceDetail.weekend_nights
                } ${pluralize(priceDetail.frequency_label, priceDetail.weekend_nights)}`}
              </div>
              <div className='text-right'>
                {formatMoney(priceDetail.weekend_total_fee, camp.currency)}
              </div>
            </div>
          ) : (
            <></>
          )}
          {!!priceDetail.discounted_subtotal &&
          !!(priceDetail.subtotal - priceDetail.discounted_subtotal) ? (
            !!priceDetail.holiday_nights && !!priceDetail.discounted_holiday_total_fee ? (
              <div className={classNames('mb-2 flex justify-between w-full')}>
                <div className=''>Discount</div>
                <div className='ml-8'>
                  -{' '}
                  {formatMoney(
                    priceDetail.subtotal -
                      priceDetail.discounted_subtotal +
                      (priceDetail.holiday_total_fee - priceDetail.discounted_holiday_total_fee),
                    camp.currency
                  )}
                </div>
              </div>
            ) : (
              <div className={classNames('mb-2 flex justify-between w-full')}>
                <div className=''>Discount</div>
                <div className='ml-8'>
                  -{' '}
                  {formatMoney(
                    priceDetail.subtotal - priceDetail.discounted_subtotal,
                    camp.currency
                  )}
                </div>
              </div>
            )
          ) : (
            <></>
          )}
          {(priceDetail.additional_fees?.length ||
            priceDetail.adults_fee > 0 ||
            priceDetail.kids_fee > 0 ||
            priceDetail.pets_fee > 0) && (
            <div className='grid grid-cols-3 w-full'>
              {priceDetail.additional_fees?.map((fee) => {
                if (fee.quantity > 0)
                  return (
                    <>
                      <div className='col-span-2 mb-2'>
                        {fee.label || titleCase(fee.slug)}{' '}
                        {formatMoney(fee.unit_price, camp.currency)} x {fee.quantity}
                      </div>
                      <div className='col-span-1 text-right'>
                        {formatMoney(fee.total_price, camp.currency)}
                      </div>
                    </>
                  )
              })}
              {priceDetail.adults_fee > 0 && (
                <>
                  <div className='col-span-2 mb-2'>
                    {priceDetail.additional_adults} extra{' '}
                    {pluralize('guest', priceDetail.additional_adults)} fee x {priceDetail.nights}{' '}
                    {pluralize(priceDetail.frequency_label, priceDetail.nights)}
                  </div>
                  <div className='col-span-1 text-right'>
                    {formatMoney(priceDetail.adults_fee, camp.currency)}
                  </div>
                </>
              )}
              {priceDetail.kids_fee > 0 && (
                <>
                  <div className='col-span-2 mb-2'>
                    {priceDetail.additional_kids} extra{' '}
                    {pluralize('kid', priceDetail.additional_kids)} fee x {priceDetail.nights}{' '}
                    {pluralize(priceDetail.frequency_label, priceDetail.nights)}
                  </div>
                  <div className='col-span-1 text-right'>
                    {formatMoney(priceDetail.kids_fee, camp.currency)}
                  </div>
                </>
              )}
              {priceDetail.pets_fee > 0 && (
                <>
                  <div className='col-span-2 mb-2'>
                    {priceDetail.additional_pets} extra{' '}
                    {pluralize('pet', priceDetail.additional_pets)} fee x {priceDetail.nights}{' '}
                    {pluralize(priceDetail.frequency_label, priceDetail.nights)}
                  </div>
                  <div className='col-span-1 text-right'>
                    {formatMoney(priceDetail.pets_fee, camp.currency)}
                  </div>
                </>
              )}
            </div>
          )}
          {Boolean(isShowingTax && priceDetail.tax && priceDetail.tax > 0) && (
            <div className={classNames('mb-2 flex justify-between w-full')}>
              <div className=''>{`${campingStyle?.tax_rate_label || 'Tax'} ${
                newReservationData.newReservation.kind == 'group'
                  ? ''
                  : `(${dynamicRound(taxRate * 100)}%)`
              }`}</div>
              <div className='ml-8'>{formatMoney(priceDetail.tax, camp.currency)}</div>
            </div>
          )}
          {Boolean(priceDetail.credit_card_fee && priceDetail.credit_card_fee > 0) && (
            <div className={classNames('mb-2 flex justify-between w-full')}>
              <div className=''>{`Credit Card Fee (${dynamicRound(
                priceDetail.credit_card_rate * 100
              )}%)`}</div>
              <div className='ml-8'>{formatMoney(priceDetail.credit_card_fee, camp.currency)}</div>
            </div>
          )}
          {!!amountPaid && (
            <div className='border-b border-gray-500 text-green-800 pb-3 mb-4 flex justify-between w-full'>
              <div className=''>Paid</div>
              <div className=''>-{formatMoney(amountPaid, camp.currency)}</div>
            </div>
          )}
        </div>
        {!!(priceDetail.service_rate && priceDetail.service_fee) && (
          <div
            className={classNames(
              'mb-2 flex justify-between w-full',
              camp.is_absorbing_service_fee && 'text-gray-400'
            )}>
            <div className=''>
              Service fee
              {camp.is_absorbing_service_fee && (
                <span className='text-xs ml-1'>(Not included in total)</span>
              )}
            </div>
            {formatMoney(priceDetail.service_fee, camp.currency)}
          </div>
        )}
        {amountPaid ? (
          <div className='mt-2 pt-4 border-t border-gray-500 flex justify-between w-full'>
            <div className='font-bold'>Total</div>
            <div className='font-bold'>
              {formatMoney(priceDetail.total - amountPaid, camp.currency)}
            </div>
          </div>
        ) : (
          <div className='mt-2 pt-4 border-t border-gray-500 flex justify-between w-full'>
            <div className='font-bold'>Total</div>
            <div className='font-bold'>{formatMoney(priceDetail.total, camp.currency)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PriceDetail
