import React, { useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CampProps, CampingStyleProps } from '../../../interfaces'
import TextAreaWithLabel from '../Inputs/TextAreaWithLabel'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Controller } from 'react-hook-form'
import { AdminContext } from '../../../contexts/AdminContext'
import { desktopToolbar, desktopToolbarHyperlink, mobileToolbar } from '../../../config/quillToolbar'
import RichTextInput from '../../ui/RichTextInput'

interface ComponentProps {
  reactHookForm: UseFormReturn<CampProps & Pick<CampingStyleProps, 'checkin_policy' | 'refund_policy'>>
}

const PolicyAndRulesForm = ({ reactHookForm }: ComponentProps) => {
  const { register, control, setValue, watch } = reactHookForm

  const onCheckinPolicyStateChange = (editorState) => {
    setValue('checkin_policy', editorState)
  }
  const onRefundPolicyStateChange = (editorState) => {
    setValue('refund_policy', editorState)
  }

  const checkinPolicyContent = watch('checkin_policy')
  const refundPolicyContent = watch('refund_policy')
  const rulesContent = watch('rules')
  
  useEffect(() => {
    register('checkin_policy', {})
    register('refund_policy', {})
  }, [register])

  return (
    <div className=''>
      <div className='my-6 space-y-2'>
        <div className='text-xl font-semibold text-gray-900'>Policy & Rules</div>
        <div className='font-medium text-gray-500'>Shows up in booking confirmations and emails.</div>
      </div>
      <div className='grid grid-cols-1 gap-4'>
        <div>
          <label className='block font-medium text-md text-gray-900'>Checkin policy</label>
          <RichTextInput
            value={checkinPolicyContent}
            setValue={onCheckinPolicyStateChange}
            style={{ marginTop: 3.5 }}
            hyperlink
          />
        </div>
        <div>
          <label className='block font-medium text-md text-gray-900'>Cancellation policy</label>
          <RichTextInput
            value={refundPolicyContent}
            setValue={onRefundPolicyStateChange}
            style={{ marginTop: 3.5 }}
            hyperlink
          />
        </div>
        <div>
          <label className='block font-medium text-md mb-1 text-gray-900'>Rules</label>
          <Controller
            name='rules'
            control={control}
            rules={{ required: 'Content is required' }}
            render={({ field }) => (
              <RichTextInput
                value={field.value}
                setValue={field.onChange}
                style={{ marginTop: 3.5 }}
                hyperlink
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default PolicyAndRulesForm
