import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import classNames from '../../../utils/classNames'
import { titleCase } from '../../../utils'
import { IOption } from '../../ui/Admin/OptionSelector'

interface ComponentProps {
  label?: string
  options: Array<IOption>
  selectedOption: IOption
  onChange?: (v: IOption) => void
}

const RefundOptionSelector = (props: ComponentProps) => {
  return (
    <RadioGroup className='mb-3 mt-1' value={props.selectedOption.value}>
      <RadioGroup.Label className='text-base text-gray-900'>{props.label}</RadioGroup.Label>

      <div className='flex flex-row gap-4 flex-wrap max-w-screen'>
        {props.options.map((option, index) => (
          <RadioGroup.Option
            key={index}
            value={option.value}
            onClick={() => {props.onChange(option)}}
            className={({ checked, active }) =>
              classNames(
                'transition-colors duration-100 hover:border-gray-400',
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-1 ring-green-600' : '',
                'flex-grow relative bg-white border rounded-lg shadow-sm p-2.5 flex cursor-pointer focus:outline-none'
              )
            }>
            {({ checked, active }) => (
              <>
                <div className='flex-1 flex justify-start whitespace-nowrap w-28'>
                  <div className='flex items-center text-center justify justify-items-center'>
                    {option.icon && (
                      <option.icon
                        className={classNames(
                          'w-12 h-12 self-center stroke-current',
                          checked ? 'text-green-600' : 'text-gray-900'
                        )}
                      />
                    )}
                    <RadioGroup.Label
                      as='span'
                      className={classNames(
                        'flex text-sm ml-2',
                        checked ? 'text-green-600 font-bold' : 'text-gray-900 font-medium'
                      )}>
                      <div className=''>{titleCase(option.label)}</div>
                      {/* Leaving here in case we need to add a hovertip explainer
                      <HoverTip content={hovertipText(option.value)}>
                        <InformationCircleIcon className='w-3.5 h-3.5 ml-0.5' />
                      </HoverTip> */}
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    'h-5 w-5 text-green-600 absolute right-1.5 top-1',
                    !checked ? 'invisible' : ''
                  )}
                  aria-hidden='true'
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-green-600' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default RefundOptionSelector
