import React, { useContext, useState } from 'react'
import { FormContext } from '../../../../contexts/FormContext'
import classNames from '../../../../utils/classNames'
import SelectInput, {Option as SelectInputOption} from '../../SelectInput'
import { Controller } from 'react-hook-form'

interface Option<T> {
  label: string
  value: T
  hidden?: boolean
  isBottomPlacement?: boolean
}

const HookSelectInput = ({
  field,
  label,
  options,
  className,
  disabled,
  placeholder,
  asText,
}: {
  field: string
  label?: string
  options: Array<Option<string>>
  className?: string
  disabled?: boolean
  placeholder?: string
  asText?: boolean
}) => {
  const { resource, control, register, isChanged, watch, errors, clearError } = useContext(FormContext)
  const { onChange: registeredOnChange, ...registered } = register(field)

  if (asText) {
    return (
      <div className={classNames(className, 'flex items-center py-2')}>
        {watch(field)}
      </div>
    )
  }

  return (
    <div className={classNames('mb-2', className)}>
      <div
        className={classNames(className, disabled && 'opacity-30')}
      >
        {label &&
          <label
            htmlFor='name'
            className={classNames(
              'mb-1 block font-medium text-md',
              errors[field] ? 'text-red-600' : 'text-gray-900',
            )}
          >
            {label}
            {errors[field] && (
              <span className="ml-1 text-red-500 text-xs">{errors[field].message}</span>
            )}
          </label>
        }
        <div>
          <SelectInput
            className={classNames("w-full", isChanged(field) ? 'bg-blue-50' : 'bg-white')}
            options={options}
            selected={options.find((o) => o.value === ([undefined, null, ""].includes(watch(field)) ? null : watch(field)))}
            setSelected={(option: SelectInputOption) => {
              const event = {
                target: {
                  name: field,
                  value: option.value,
                }
              }
              registeredOnChange(event)
              clearError(field)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default HookSelectInput
