import { useEffect, useState } from 'react'
import { CampProps } from '../interfaces'
import ReactGA from 'react-ga4'

const PARK_GA_ID = 'G-KGQBEWW0K0'

const useGoogleAnalytics = (camp: CampProps) => {
  const [campGoogleAnalyticsId] = useState<string | null>(camp?.google_analytics_id)
  const [campGoogleAdConversionId] = useState<string | null>(camp?.google_ad_conversion_id)

  useEffect(() => {
    ReactGA.initialize(PARK_GA_ID)
    if (campGoogleAnalyticsId) ReactGA.initialize(campGoogleAnalyticsId)
  }, [])

  const trackForPark = (action: string, params: object = {}) => {
    ReactGA.event(action, { send_to: PARK_GA_ID, ...params })
  }

  const trackForCamp = (action: string, params: object = {}) => {
    ReactGA.event(action, { send_to: campGoogleAnalyticsId, ...params })
  }

  const trackAdConversionForCamp = (action: string, params: object = {}) => {
    ReactGA.event(action, { send_to: campGoogleAdConversionId, ...params })
  }

  return { trackForPark, trackForCamp, trackAdConversionForCamp }
}

export default useGoogleAnalytics
