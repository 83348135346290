import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { LONG_TERM_RESERVATION_KINDS } from '../../constants'
import { useCookies } from 'react-cookie'

const groupOptions = [
  {
    name: 'Pay separately',
    description: 'The leader will receive a link to share with the group to book individually',
    value: true,
  },
  {
    name: 'Pay all together',
    description: 'The leader will pay for the group all at once',
    value: false,
  },
]

const recurringOptions = [
  {
    name: 'Monthly rent + electric',
    description:
      'Used for monthly campers to track and send monthly invoices for rent and include electric charges',
    value: LONG_TERM_RESERVATION_KINDS.RENT_AND_UTILITIES,
  },
  {
    name: 'Monthly rent only',
    description:
      'Used to automatically send invoices to campers where electric is included in the monthly price',
    value: LONG_TERM_RESERVATION_KINDS.RENT_ONLY,
  },
  {
    name: 'Seasonal + monthly electric',
    description:
      'Used for seasonal or long-term campers that pay up front, and electric is billed monthly',
    value: LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY,
  },
  ,
  {
    name: 'Seasonal only',
    description:
      'Used for seasonal or long-term campers that pay up front',
    value: LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ReservationOptionsSelector({
  setSelectedOption,
  reservationType,
}: {
  setSelectedOption: any
  reservationType: 'group' | 'recurring'
}) {
  const [options, setOptions] = useState<any[]>(
    reservationType == 'group' ? groupOptions : recurringOptions
  )
  const [cookies, setCookie] = useCookies<any>(['recurring-option'])
  const [selected, setSelected] = useState<any>(
    cookies['recurring-option'] && reservationType == 'recurring'
      ? options.find((option) => option?.value === cookies['recurring-option'])
      : options[0]
  )

  useEffect(() => {
    if (selected) {
      setSelectedOption(selected.value)
      if (reservationType == 'recurring') {
        setCookie('recurring-option', selected.value)
      }
    }
  }, [selected])

  return (
    <RadioGroup value={selected} onChange={setSelected} className={'mb-4'}>
      <div className='-space-y-px rounded-md bg-white'>
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'z-10 border-green-200 bg-green-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none'
              )
            }>
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-green-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-green-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center flex-shrink-0'
                  )}
                  aria-hidden='true'>
                  <span className='rounded-full bg-white w-1.5 h-1.5' />
                </span>
                <span className='ml-3 flex flex-col'>
                  <RadioGroup.Label
                    as='span'
                    className={classNames(
                      checked ? 'text-green-900' : 'text-gray-900',
                      'block text-md font-semibold'
                    )}>
                    {option.name}
                  </RadioGroup.Label>
                  {checked && (
                    <RadioGroup.Description
                      as='span'
                      className={classNames(
                        checked ? 'text-green-700' : 'text-gray-500',
                        'block text-md mt-1'
                      )}>
                      {option.description}
                    </RadioGroup.Description>
                  )}
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
