import React, { ChangeEvent, MutableRefObject, useState } from 'react'
import { JsxElement } from 'typescript'
import { titleCase } from '../../../utils'
import classNames from '../../../utils/classNames'

const InputFieldWithButton = ({
  type,
  ref,
  label,
  name,
  defaultValue,
  value,
  disabled,
  className,
  icon,
  onClick,
  onChange,
  full,
  readOnly,
  button,
}: {
  type?: string
  ref?: MutableRefObject<any>
  label?: string
  name?: string
  defaultValue?: any
  value?: any
  disabled?: boolean
  className?: string
  icon?: any
  onClick?: () => any
  full?: boolean
  onChange?: (e: ChangeEvent) => any
  readOnly?: boolean
  button?: JSX.Element | JSX.Element[]
}) => {
  const [bgColorClass, setBgColorClass] = useState('bg-white')

  return (
    <div className={classNames("transition-opacity duration-150", full && 'w-full', className, disabled && "opacity-30 pointer-events-none")}>
      {label ||
        (name && (
          <label htmlFor='name' className='block font-medium text-md text-gray-900'>
            {label || titleCase(name)}
          </label>
        ))}
      <div className='mt-1 flex w-full'>
        <input
          ref={ref}
          type={type || 'text'}
          autoComplete='new-password'
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          id={name}
          readOnly={readOnly}
          className={classNames(
            'block flex-grow -mr-8 rounded-md border-gray-300 shadow-sm focus:border-green-700 focus:ring-green-700',
            bgColorClass
          )}
          onChange={(e) => {
            if (defaultValue !== undefined && e.target.value !== defaultValue) {
              setBgColorClass('bg-blue-50')
            } else {
              setBgColorClass('bg-white')
            }

            if (onChange) onChange(e)
          }}
        />
        {button ? (
          button
        ) : (
          <button
            type='button'
            className='relative p-1.5 top-1 bg-white text-gray-300 hover:text-gray-500 w-8 h-8 z-10 rounded-md hover:bg-gray-100'
            style={{ right: '6px', width: '30px', height: '30px' }}
            onClick={onClick}>
            {icon}
          </button>
        )}
      </div>
    </div>
  )
}

export default InputFieldWithButton
