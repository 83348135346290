import React, { useState, useEffect } from 'react'
import { Listbox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import classNames from '../../../utils/classNames'
import { useCookies } from 'react-cookie'
import { reportFilterCookie } from '../../../constants/cookies'

interface ReportsFilterByProps {
  defaultFilterBy: string
  setFilterBy: (filter: string) => void
  options: any[]
}

const FilterBy = (props: ReportsFilterByProps) => {
  const [cookies, setCookie] = useCookies([reportFilterCookie])
  const [selectedFilter, setSelectedFilter] = useState<string>(
    props.defaultFilterBy || props.options[0].value
  )

  useEffect(() => {
    if (!cookies[reportFilterCookie]) {
      setCookie(reportFilterCookie, props.options[0].value, { path: '/' })
    }
  }, [cookies[reportFilterCookie], props.options, setCookie])

  const handleChange = (option: string) => {
    setSelectedFilter(option)
    props.setFilterBy(option)
    setCookie(reportFilterCookie, option, { path: '/' })
  }

  return (
    <div className='flex'>
      <Listbox value={selectedFilter} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className='relative'>
              <div className='flex items-center'>
                <span className='mr-2 whitespace-nowrap font-semibold'>Filter by:</span>
                <Listbox.Button
                  className={classNames(
                    'rounded-md inline-flex justify-center items-center w-full border border-gray-300 shadow-sm px-4 py-2.5 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-gray-100'
                  )}>
                  {props.options.find((option) => option.value === selectedFilter)?.label}
                  <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden='true' />
                </Listbox.Button>
              </div>

              <Listbox.Options className='absolute z-10 mt-1 w-56 bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none'>
                {props.options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100' : '',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option.value}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}>
                          {option.label}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              active ? 'text-amber-600' : 'text-amber-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default FilterBy
