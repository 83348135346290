import CamperDecorator from "../decorators/CamperDecorator"
import ICamper from "../interfaces/ICamper"
import IOrder from "../interfaces/IOrder"
import IS3Document from "../interfaces/IS3Document"
import { destroy, get, patch, post } from "./utils/requests"

const create = (campId: string, data: ICamper & { user_id?: string, reservation_id?: string }): Promise<CamperDecorator> =>
  post(`/api/camps/${campId}/campers`, data)
    .then((response) => Promise.resolve(new CamperDecorator(response.camper)))

const update = (id: string, data: ICamper): Promise<CamperDecorator> =>
  patch(`/api/campers/${id}`, data)

const remove = (id: string): Promise<{ message: string }> =>
  destroy(`/api/campers/${id}`)

const fetchDocuments = async (id: string): Promise<{ documents: Array<IS3Document> }> =>
  get(`/api/campers/${id}/documents`)

const uploadDocument = async (id: string, file: File): Promise<{ document: IS3Document }> => {
  const formData = new FormData()
  formData.append('file', file)
  const response = await fetch(`/api/campers/${id}/documents`, {
    method: 'POST',
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Failed to upload image')
  }

  const resp = await response.json()

  return resp
}

const removeDocument = async (id: string, key: string): Promise<{ message: string }> =>
  destroy(`/api/campers/${id}/documents`, { key })

const csv = async (campId: string): Promise<string> =>
  get(`/api/camps/${campId}/campers`, {}, { headers: { Accept: 'text/csv' } })

const removeCreditCard = async (camper_id: string, id: string): Promise<{ message: string }> =>
  destroy(`/api/campers/${camper_id}/credit_cards/${id}`)

const Campers = {
  create,
  csv,
  fetchDocuments,
  update,
  uploadDocument,
  removeDocument,
  removeCreditCard,
  remove,
  Orders: {
    all: (camperId: string): Promise<{ orders: IOrder[] }> => get(`/api/campers/${camperId}/orders`),
  }
}

export default Campers
