import { serviceRateMode } from "../api2/orders"
import ePaymentType from "../enums/ePaymentType"

const serviceRateModeForPaymentType = (paymentMethod: ePaymentType) => {
  switch (paymentMethod) {
    case ePaymentType.LINK:
      return serviceRateMode.payment_link
    default:
      return serviceRateMode.pos
  }
}

export default serviceRateModeForPaymentType
