import React, { useContext, useEffect, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { NewReservationProps, ReservationProps, SiteProps } from '../../../interfaces'
import SiteIcons from '../../SiteIcons'
import { CAMPING_STYLES, KINDS, RESERVATION_KINDS, reservationKind } from '../../../constants'
import { AdminContext } from '../../../contexts/AdminContext'
import generateSiteOptions from './generateSiteOptions'
import { set } from 'lodash'
import { ChevronDownIcon, MapPinIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export interface SiteOption {
  key: string
  value: string
  title: string
  description?: string
  site: SiteProps
  isDisabled?: boolean
  selectedReservationType?: string // NOTE: this might be better as reservationKind
  // TODO: why are these here?
  selectedSitesForGroup?
  setSelectedSitesForGroup?
  required?: boolean
}

type RvKind = typeof KINDS.RV_BACKIN | typeof KINDS.RV_HYBRID | typeof KINDS.RV_PULLTHROUGH

const SitesDropdown = ({
  label,
  selectedIds,
  setSelectedIds,
  reservation,
  selectedReservationType,
  required,
  disabled,
}: {
  label?: string
  selectedIds: string[]
  setSelectedIds: (ids: string[]) => void
  reservation: ReservationProps | NewReservationProps
  selectedReservationType?: reservationKind
  required?: boolean
  disabled?: boolean
}) => {
  const { sites, campingStyles, reservationStates } = useContext(AdminContext)
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<SiteOption[]>([])
  const selectedOptions = options.filter((option) => selectedIds.includes(option.key))
  const isSelectingMultiple =
    selectedReservationType == RESERVATION_KINDS.GROUP ||
    selectedReservationType == RESERVATION_KINDS.BLOCKED
  const [siteView, setSiteView] = useState(options)

  const filterSiteViewArray = (value: string | React.ChangeEvent<HTMLInputElement>) => {
    const query = typeof value === 'string' ? value : value.target.value

    setQuery(query)

    setSiteView(
      options.filter((site) =>
        site.title.trim().toLocaleLowerCase().includes(query.trim().toLocaleLowerCase())
      )
    )
  }

  useEffect(() => {
    if (!isOpen && query) {
      setIsOpen(true)
    }
  }, [query])

  useEffect(() => {
    setOptions(generateSiteOptions(sites, reservation, reservationStates, campingStyles))
  }, [isOpen])

  const handleInputClick = () => {
    setOptions(generateSiteOptions(sites, reservation, reservationStates, campingStyles))
  }

  const handleSitesSelection = (selectedSites: SiteOption[]) => {
    const newSelectedIds = selectedSites.reduce(
      (acc: string[], site: SiteOption) =>
        acc.includes(site.key) ? acc.filter((key) => key !== site.key) : (acc.push(site.key), acc),
      []
    )

    setSelectedIds(newSelectedIds)
  }

  const rvKinds = [
    KINDS.RENTAL_RV,
    KINDS.RV_BACKIN,
    KINDS.RV_HYBRID,
    KINDS.RV_PULLTHROUGH,
    KINDS.RV_STORAGE,
  ]

  let rvType

  if (rvKinds.includes(selectedOptions[0]?.site?.kind as RvKind)) {
    const selectedSitesCampingStyleId = selectedOptions.map((option) => option?.site?.camping_style_id)

    const selectedCampingStyle = campingStyles.find((style) => style.id === selectedSitesCampingStyleId[0])

    if (selectedCampingStyle?.name === CAMPING_STYLES.SEASONAL_RV) {
      rvType = 'Seasonal RVs'
    } else if (selectedCampingStyle?.name === CAMPING_STYLES.RV) {
      rvType = 'RVs'
    }
  } else {
    rvType = selectedOptions[0]?.site.kind.charAt(0).toUpperCase() + selectedOptions[0]?.site.kind.slice(1)
  }

  const isSelectAllCampingStylesShow = () => {
    const selectedSitesCampingStyleId = selectedOptions.map(
      (option) => option?.site?.camping_style_id
    )

    if (selectedSitesCampingStyleId.length === 0) return false

    if (selectedSitesCampingStyleId.every((id) => id === selectedSitesCampingStyleId[0]))
      return true

    return false
  }

  const selectAllCampingStylesCheck = (option: SiteOption) => {
    const selectedSitesCampingStyleId = selectedOptions.map(
      (option) => option?.site?.camping_style_id
    )

    if (selectedSitesCampingStyleId[0] === option?.site?.camping_style_id) return true

    return false
  }

  return (
    <div>
      {isSelectingMultiple ? (
        <Combobox
          as='div'
          multiple
          value={selectedOptions}
          onChange={handleSitesSelection}
          disabled={disabled}>
          {({ open }) => (
            <>
              <div className='flex justify-between items-end'>
                <Combobox.Label className='block font-semibold text-mdbase text-gray-900'>
                  {label}s {required && <span className='text-red-600'>*</span>}
                </Combobox.Label>
                <div>
                  {isSelectAllCampingStylesShow() ? (
                    <span
                      className={classNames(
                        'ml-3.5 mb-0.5 inline underline text-xs text-gray-500 cursor-pointer hover:text-gray-900'
                      )}
                      onClick={() => {
                        let newArray: string[] = []

                        options.map((option) => {
                          if (!option.description && selectAllCampingStylesCheck(option))
                            newArray.push(option.key)
                        })

                        setSelectedIds(newArray)
                      }}>
                      Select all{' '}{rvType}
                    </span>
                  ) : (
                    <></>
                  )}
                  <span
                    className={classNames(
                      'ml-3.5 mb-0.5 inline underline text-xs text-gray-500 cursor-pointer hover:text-gray-900'
                    )}
                    onClick={() => {
                      let newArray: string[] = []

                      options.map((option) => {
                        if (!option.description) newArray.push(option.key)
                      })

                      setSelectedIds(newArray)
                    }}>
                    Select all
                  </span>
                  <span
                    className={classNames(
                      'ml-3.5 mb-0.5 inline underline text-xs text-gray-500 cursor-pointer hover:text-gray-900'
                    )}
                    onClick={() => {
                      setSelectedIds([])
                    }}>
                    Remove all
                  </span>
                </div>
              </div>
              <div className='relative'>
                <Combobox.Button className='w-full'>
                  <Combobox.Input
                    className={classNames(
                      'w-full rounded-md border-0 bg-white pl-3 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6 h-10.5',
                      disabled && 'opacity-50 bg-gray-200'
                    )}
                    onChange={filterSiteViewArray}
                    autoComplete='cc-csc'
                    displayValue={() =>
                      !isOpen ? selectedOptions.map((option) => option?.title).join(',  ') : ''
                    }
                    onClick={() => {
                      filterSiteViewArray('')
                    }}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                  />
                </Combobox.Button>

                {siteView.length > 0 && open && (
                  <Combobox.Options
                    static
                    className='absolute z-10 mt-1 max-h-60 w-full inav overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm focus:border-transparent'>
                    {siteView.map((option) => (
                      <Combobox.Option
                        key={option.key}
                        value={option}
                        disabled={option.isDisabled}
                        className={({ active, selected }) =>
                          classNames(
                            'relative select-none py-2 px-3',
                            option.description ? 'opacity-50' : 'cursor-pointer',
                            selectedOptions.some((siteOption) => siteOption.key == option.key) &&
                              'bg-green-100',
                            active && 'bg-gray-100'
                          )
                        }>
                        {({ active, selected }) => (
                          <>
                            <div className='flex justify-between'>
                              <div className='flex flex-col'>
                                <span
                                  className={classNames(
                                    'grow truncate text-md',
                                    selected && 'font-semibold'
                                  )}>
                                  {option.title}
                                </span>
                                <span className={classNames('truncate text-red-400 text-xs')}>
                                  {option.description}
                                </span>
                              </div>
                              <SiteIcons
                                site={option.site}
                                size='10'
                                isDropdown
                                campingStyle={campingStyles.find(
                                  (style) => style.id == option?.site?.camping_style_id
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </>
          )}
        </Combobox>
      ) : (
        <Combobox
          as='div'
          value={selectedOptions[0]}
          onChange={(option) => setSelectedIds([option.key])}
          disabled={disabled}>
          {({ open }) => (
            <>
              <Combobox.Label
                className={'block font-bold leading-6 text-gray-900'}
                style={{ fontSize: '.92857143em' }}>
                {label} <span className='text-red-600'>*</span>
                {disabled && <span className='text-gray-500 font-medium ml-2 text-sm italic'>Site locked</span>}
              </Combobox.Label>
              <div className='relative'>
                <Combobox.Button className='w-full ghostinspector-new-reservation-site-button z-10'>
                  {/* TODO: add in icons to match designs
                  <MapPinIcon
                    className='h-7 w-7 text-gray-600 absolute top-1.5 left-2'
                    aria-hidden='true'
                  /> */}
                  <Combobox.Input
                    className={classNames(
                      'w-full rounded-md border border-gray-300 bg-white pl-3 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:leading-6 h-10.5 focus:border-transparent',
                      disabled && 'opacity-50 bg-gray-200'
                    )}
                    onChange={filterSiteViewArray}
                    displayValue={(option: SiteOption) => (!isOpen ? option.title : '')}
                    onClick={() => filterSiteViewArray('')}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                  />
                </Combobox.Button>

                {siteView.length > 0 && open && (
                  <Combobox.Options
                    static
                    className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
                    {siteView.map((option) => (
                      <Combobox.Option
                        key={option.key}
                        value={option}
                        disabled={option.isDisabled}
                        className={({ active, selected }) =>
                          classNames(
                            'relative select-none py-2 px-3 ghostinspector-new-reservation-site-li',
                            option.description ? 'opacity-50' : 'cursor-pointer',
                            selected
                              ? 'bg-green-50'
                              : active
                              ? 'bg-green-600 text-white'
                              : 'text-gray-900'
                          )
                        }>
                        {({ active, selected }) => (
                          <>
                            <div className='flex justify-between'>
                              <div className='flex flex-col'>
                                <span
                                  className={classNames(
                                    'grow truncate text-md',
                                    selected && 'font-semibold'
                                  )}>
                                  {option.title}
                                </span>
                                <span
                                  className={classNames(
                                    'truncate text-red-400 text-xs',
                                    active ? 'text-green-200' : 'text-gray-500'
                                  )}>
                                  {option.description}
                                </span>
                              </div>
                              <SiteIcons
                                site={option.site}
                                size='10'
                                isDropdown
                                campingStyle={campingStyles.find(
                                  (style) => style.id == option?.site?.camping_style_id
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </>
          )}
        </Combobox>
      )}
    </div>
  )
}

export default SitesDropdown
