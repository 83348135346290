import { Transition } from '@headlessui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { NewReservationContext } from '../../contexts/admin/NewReservationContext'
import { AdminContext } from '../../contexts/AdminContext'
import { CampProps, NewReservationProps, SiteProps } from '../../interfaces'
import classNames from '../../utils/classNames'
import priceDetailsFor, { PriceDetails as IPriceDetails } from '../../utils/priceDetailsFor'
import DiscountFormGroup from '../Reservations/DiscountFormGroup'
import Spinner from '../ui/Spinner'
import ToggleLine from '../ui/ToggleLine'
import PriceDetail from './PriceDetail'
import { LONG_TERM_RESERVATION_KINDS, RESERVATION_KINDS } from '../../constants'
import ReservationOptionsSelector from '../ui/ReservationOptionsSelector'
import Alert from '../ui/Alert'
import mergePriceDetails from '../../utils/mergePriceDetails'
import { useCookies } from 'react-cookie'
import { NewReservationAction } from '../../reducers/newReservation'

const reservationKinds = Object.values(RESERVATION_KINDS)
type validReservationKinds = (typeof reservationKinds)[number]

interface ComponentProps {
  camp: CampProps
  sites: Array<SiteProps>
  reservation: NewReservationProps
  selectedSiteIds?: Array<string>
  selectedReservationType: string
  reservationDispatch
  isAcceptingDeposit: boolean
  setIsAcceptingDeposit: (isAcceptingDeposit: boolean) => void
  isShowingPassengerFeeToggle: boolean
  hasPetsFees: boolean
}

const PriceDetailsSection = (props: ComponentProps) => {
  const isGroupReservation =
    props.selectedReservationType === RESERVATION_KINDS.GROUP &&
    (props.selectedSiteIds || []).length > 1

  const [priceDetails, setPriceDetails] = useState<IPriceDetails>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [cookies, setCookie] = useCookies(['isTaxAdded', 'isReccuringTaxAdded'])
  const { camp, campingStyles } = useContext(AdminContext)
  const [subtotalOverride, setSubtotalOverride] = useState<string>(props.reservation.subtotal ? String(props.reservation.subtotal) : null)
  // NOTE: this isn't always available
  const newReservationData = useContext(NewReservationContext)
  const userElectric = newReservationData?.inputVehicleElectric
  const numberOfAdults = newReservationData?.inputAdults
  const numberOfKids = newReservationData?.inputChildren
  const isPassengerFeesEnabled = props.reservation?.is_passenger_fees_enabled
  const numberOfPets = newReservationData.inputPets

  // NOTE: we want to make sure the tax rate defaults to the camping style first
  const site = props.sites.find(
    (s) =>
      s.id ===
      (props.selectedSiteIds?.length ? props.selectedSiteIds[0] : props.reservation.site_id)
  )
  const campingStyle = campingStyles.find((cs) => cs.id === site?.camping_style_id)
  const taxRate = campingStyle?.tax_rate || props.camp.tax_rate
  const hasPassengerFees =
    props.isShowingPassengerFeeToggle &&
    (campingStyle?.max_free_passengers !== null || campingStyle?.max_free_guests !== null) &&
    (Number(campingStyle?.extra_passengers_price_in_cents) > 0 || Number(campingStyle?.extra_guests_price_in_cents) > 0)
  const holidayRate = campingStyle?.holiday_fee_in_cents !== null
    ? campingStyle?.holiday_fee_in_cents
    : props.camp.holiday_fee_in_cents

  const weekendFeeInCents = campingStyle?.holiday_fee_in_cents !== null
    ? campingStyle?.weekend_fee_in_cents
    : props.camp.weekend_fee_in_cents

  const recalculatePriceDetails = async () => {
    setIsLoading(true)
    setErrorMessage('')
    debouncedPriceDetails()
  }

  const debouncedPriceDetails = useDebouncedCallback(() => {
    if (!(props.reservation.start_date && props.reservation.end_date)) return

    let localSubtotalOverride: number = null

    if (props.reservation.edited_total) {
      if (subtotalOverride == '') {
        localSubtotalOverride = 0
      } else {
        localSubtotalOverride = subtotalOverride === null ? null : Number(subtotalOverride)
      }
    }

    let data: Parameters<typeof priceDetailsFor>[0] = {
      kind: props.selectedReservationType === RESERVATION_KINDS.RECURRING &&
      (
        newReservationData.recurringKind === LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY
        || newReservationData.recurringKind === LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY
      )
      ? RESERVATION_KINDS.SEASONAL
      : props.selectedReservationType as validReservationKinds,
      camp: props.camp,
      startDate: props.reservation.start_date,
      endDate: props.reservation.end_date,
      discountSlug: props.reservation.discount_name,
      subtotalOverride: localSubtotalOverride,
      isTaxableEnabled: props.reservation.is_tax_enabled,
      isPassengerFeesEnabled: isPassengerFeesEnabled && props.isShowingPassengerFeeToggle,
      isPetsFeeEnabled: props.reservation.is_pets_fee_enabled,
      intervalId: props.reservation.interval_id,
      userElectric,
      numberOfAdults,
      numberOfKids,
      numberOfPets: Number(campingStyle?.extra_pets_price_in_cents) > 0 ? Number(numberOfPets) : null,
      isHolidayEnabled: props.reservation.is_holiday_rate_enabled,
      isWeekendEnabled: props.reservation.is_weekend_fee_enabled,
      isCreditCardFeeEnabled: props.reservation.is_credit_card_fee_enabled,
    }

    if (isGroupReservation) {
      priceDetailsFor({ ...data, siteIds: props.selectedSiteIds })
        .then((pds: IPriceDetails[]) => {
          // TODO: we should have separate line items for separate sites, but for now this can work
          const pd = mergePriceDetails(pds)
          setPriceDetails(pd)

          if (!props.reservation.edited_total) {
            setSubtotalOverride(pd.subtotal.toFixed(2))
            props.reservationDispatch({
              type: 'UPDATE_SUBTOTAL',
              payload: { subtotal: Number(pd.subtotal.toFixed(2)) },
            })
          }

          setIsLoading(false)
        })
        .catch((err) => {
          if (err) console.error(err)
          setIsLoading(false)
        })
    } else {
      const site = props.sites.find((s) => s.id === props.reservation.site_id)
      priceDetailsFor({ ...data, site })
        .then((pd: IPriceDetails) => {
          props.reservationDispatch({
            type: 'UPDATE_PRICE_DETAILS',
            payload: { price_details: pd },
          })
          setPriceDetails(pd)

          if (!props.reservation.edited_total) {
            setSubtotalOverride(pd.subtotal.toFixed(2))
            props.reservationDispatch({
              type: 'UPDATE_SUBTOTAL',
              payload: { subtotal: Number(pd.subtotal.toFixed(2)) },
            })
          }

          setIsLoading(false)
        })
        .catch((err) => {
          if (err) {
            console.error(err)
            setErrorMessage(err.message || '')
          }
          setIsLoading(false)
        })
    }
  }, 1000)

  useEffect(() => {
    recalculatePriceDetails()
  }, [
    // NOTE: reservation fields
    props.selectedSiteIds?.join(''), // NOTE: join() prevents recalculation when set to same values
    props.reservation.site_id,
    props.reservation.start_date,
    props.reservation.end_date,
    userElectric,
    numberOfAdults,
    numberOfKids,
    newReservationData.selectedReservationType,
    newReservationData.recurringKind,

    // NOTE: price detail toggles
    props.reservation.discount_name,
    props.reservation.is_holiday_rate_enabled,
    props.reservation.is_tax_enabled,
    !!taxRate && props.reservation.is_tax_enabled,
    hasPassengerFees && props.reservation?.is_passenger_fees_enabled,
    !!holidayRate && props.reservation.is_holiday_rate_enabled,
    !!weekendFeeInCents && props.reservation.is_weekend_fee_enabled,
    !!campingStyle?.credit_card_rate && props.reservation.is_credit_card_fee_enabled,
    props.hasPetsFees ? numberOfPets : null,
    props.reservation.is_pets_fee_enabled
  ])

  useEffect(() => {
    if (subtotalOverride !== null && props.reservation.edited_total) {
      recalculatePriceDetails()
    }
  }, [subtotalOverride, props.reservation.edited_total])

  const isRecurring = props.selectedReservationType === RESERVATION_KINDS.RECURRING

  useEffect(() => {
    const cookieTax = isRecurring ? 'isReccuringTaxAdded' : 'isTaxAdded'

    if (cookies[cookieTax]?.length) {
      props.reservationDispatch({
        type: 'UPDATE_IS_TAX_ENABLED',
        payload: { is_tax_enabled: cookies[cookieTax] === 'true' },
      })
    }
  }, [props.selectedReservationType])

  const isGroup = props.selectedReservationType == RESERVATION_KINDS.GROUP

  if (
    !(
      props.reservation.site_id &&
      props.reservation.start_date &&
      props.reservation.end_date &&
      priceDetails
    )
  ) {
    return (
      <div className='flex flex-col'>
        {errorMessage && (
          <div className='md:ml-4 mb-4 max-w-md'>
            <Alert description={`Error calculating price details: ${errorMessage}`} />
          </div>
        )}
        <div className='bg-gray-50 p-4 rounded-md h-48 flex place-items-center justify-center text-center md:ml-4'>
          <div className='text-sm text-gray-500'>
            Please select a site + dates to see camper price information.
          </div>
        </div>
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className='flex flex-col'>
        {errorMessage && (
          <div className='md:ml-4 mb-4 max-w-md'>
            <Alert description={`Error calculating price details: ${errorMessage}`} />
          </div>
        )}
        <div className='bg-gray-50 p-4 rounded-md h-48 flex place-items-center justify-center text-center md:ml-4'>
          <div className='text-sm text-gray-500'>Please try another date range or site.</div>
        </div>
      </div>
    )
  }

  return (
    <div className='md:ml-4 h-full w-full md:w-2/5 flex flex-col'>
      {isGroup && (
        <ReservationOptionsSelector
          setSelectedOption={newReservationData.setIsPayingSeparately}
          reservationType='group'
        />
      )}
      {isRecurring && (
        <ReservationOptionsSelector
          setSelectedOption={newReservationData.setRecurringKind}
          reservationType='recurring'
        />
      )}
      {(!isGroup || !newReservationData.isPayingSeparately) && (
        <>
          <div className=''>
            {isRecurring &&
            (newReservationData.recurringKind != LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY
              && newReservationData.recurringKind != LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY
            ) ? (
              <div>
                <div className='mb-1 flex justify-between items-center'>
                  <label htmlFor='name' className='inline font-bold text-md text-gray-900'>
                    Monthly price
                  </label>
                </div>
                <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <span className='text-gray-500 sm:text-sm'>$</span>
                  </div>
                  <input
                    type='number'
                    value={subtotalOverride}
                    defaultValue={subtotalOverride}
                    step='any'
                    name='total_paid_in_cents'
                    id='total_paid_in_cents'
                    onChange={(e) => {
                      setSubtotalOverride(e.target.value)
                      props.reservationDispatch({
                        type: 'UPDATE_SUBTOTAL',
                        payload: { subtotal: e.target.value === null ? null : Number(e.target.value) },
                      })

                      props.reservationDispatch({ type: 'EDITED_TOTAL' })
                    }}
                    className='block w-full rounded-l-md border-gray-300 pl-7 pr-12 focus:border-green-700 focus:ring-green-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
                    style={{ paddingLeft: '1.75rem' }}
                    placeholder='0.00'
                    aria-describedby='price-currency'
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className='mb-1 flex justify-between items-center'>
                  <label htmlFor='name' className='inline font-bold text-md text-gray-900'>
                    {isRecurring &&
                    (newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.UTILITIES_ONLY
                      || newReservationData.recurringKind == LONG_TERM_RESERVATION_KINDS.SEASONAL_ONLY
                    )
                      ? 'Season total'
                      : 'Subtotal'}
                  </label>
                  <span
                    className={classNames(
                      'inline underline text-xs text-blue-900',
                      !props.reservation.edited_total
                        ? 'opacity-30 pointer-events-none'
                        : 'cursor-pointer opacity-80 hover:opacity-100'
                    )}
                    onClick={() => {
                      recalculatePriceDetails()
                      props.reservationDispatch({ type: 'UNEDITED_TOTAL' })
                    }}
                  >
                    Reset
                  </span>
                </div>
                <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <span className='text-gray-500 sm:text-sm'>$</span>
                  </div>
                  <input
                    type='number'
                    value={subtotalOverride}
                    defaultValue={subtotalOverride}
                    step='any'
                    min={0}
                    name='total_paid_in_cents'
                    id='total_paid_in_cents'
                    onChange={(e) => {
                      setSubtotalOverride(e.target.value)
                      props.reservationDispatch({
                        type: 'UPDATE_SUBTOTAL',
                        payload: {
                          subtotal: e.target.value === null ? null : Number(e.target.value),
                        },
                      })

                      props.reservationDispatch({ type: 'EDITED_TOTAL' })
                    }}
                    className='block w-full rounded-l-md border-gray-300 pl-7 pr-12 focus:border-green-700 focus:ring-green-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
                    style={{ paddingLeft: '1.75rem' }}
                    placeholder='0.00'
                    aria-describedby='price-currency'
                  />
                </div>
              </div>
            )}
          </div>

          {taxRate && (
            <div className='mt-4'>
              <ToggleLine
                checked={props.reservation.is_tax_enabled}
                onChange={(v: boolean) => {
                  props.reservationDispatch({
                    type: 'UPDATE_IS_TAX_ENABLED',
                    payload: { is_tax_enabled: v },
                  })
                  setCookie(props.reservation.kind === 'recurring' ? 'isReccuringTaxAdded' : 'isTaxAdded', v)
                }}
                label={
                  <div className='text-gray-900'>Add {campingStyle?.tax_rate_label || 'Tax'}</div>
                }
              />
            </div>
          )}

          {!isRecurring && (
            <>
              {hasPassengerFees && props.isShowingPassengerFeeToggle && (
                <div className='mt-4'>
                  <ToggleLine
                    checked={props.reservation.is_passenger_fees_enabled}
                    onChange={(v: boolean) => {
                      props.reservationDispatch({
                        type: 'UPDATE_IS_PASSENGER_FEES_ENABLED',
                        payload: { is_passenger_fees_enabled: v },
                      })
                    }}
                    label={<div className='text-gray-900'>Passenger fees</div>}
                  />
                </div>
              )}

              {props.hasPetsFees && (
                <div className='mt-4'>
                  <ToggleLine
                    checked={props.reservation.is_pets_fee_enabled}
                    onChange={(v: boolean) => {
                      props.reservationDispatch({
                        type: 'UPDATE_IS_PETS_FEE_ENABLED',
                        payload: { is_pets_fee_enabled: v },
                      })
                    }}
                    label={<div className='text-gray-900'>Add Pets Fees</div>}
                  />
                </div>
              )}

              {holidayRate && !isRecurring && (
                <div className='mt-4'>
                  <ToggleLine
                    checked={props.reservation.is_holiday_rate_enabled}
                    onChange={(v: boolean) => {
                      props.reservationDispatch({
                        type: 'UPDATE_IS_HOLIDAY_RATE_ENABLED',
                        payload: { is_holiday_rate_enabled: v },
                      })
                      recalculatePriceDetails()
                    }}
                    label={<div className='text-gray-900'>Add Holiday Fees</div>}
                  />
                </div>
              )}

              {weekendFeeInCents && !isRecurring && (
                <div className='mt-4'>
                  <ToggleLine
                    checked={props.reservation.is_weekend_fee_enabled}
                    onChange={(v: boolean) => {
                      props.reservationDispatch({
                        type: 'UPDATE_IS_WEEKEND_FEE_ENABLED',
                        payload: { is_weekend_fee_enabled: v },
                      })
                      recalculatePriceDetails()
                    }}
                    label={<div className='text-gray-900'>Add Weekend Fees</div>}
                  />
                </div>
              )}
            </>
          )}

          {campingStyle?.credit_card_rate && (
            <div className='mt-4'>
              <ToggleLine
                checked={props.reservation.is_credit_card_fee_enabled}
                onChange={(v: boolean) => {
                  props.reservationDispatch({
                    type: 'UPDATE_IS_CREDIT_CARD_FEE_ENABLED',
                    payload: { is_credit_card_fee_enabled: v },
                  })
                }}
                label={<div className='text-gray-900'>Add Credit Card Fees</div>}
              />
            </div>
          )}

          {(props.camp.discounts.length >= 1 || campingStyle.discounts?.length >= 1) && (
            <div className='mt-4'>
              <div className='inline font-bold text-md text-gray-900'>Discount</div>
              <DiscountFormGroup
                camp={props.camp}
                campingStyle={campingStyle}
                newReservation={props.reservation}
                newReservationDispatch={props.reservationDispatch}
              />
            </div>
          )}

          <div className='relative mt-4'>
            <Transition
              show={isLoading}
              enter='transition-opacity ease-out duration-75'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-in duration-75'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='z-10 absolute w-full h-full bg-white opacity-50 flex items-center justify-center'>
                <Spinner size={6} />
              </div>
            </Transition>
            <PriceDetail
              priceDetail={priceDetails}
              isRecurring={isRecurring}
              taxRate={taxRate}
              isShowingTax={props.reservation.is_tax_enabled}
              campingStyle={campingStyle}
            />
          </div>
          <div className='mb-4'>
            <div className='mb-1 flex justify-between items-center mt-4'>
              <label
                htmlFor='name'
                className={classNames(
                  'inline font-bold text-md text-gray-900',
                  !props.isAcceptingDeposit && 'opacity-0'
                )}>
                Deposit amount
              </label>
              <span
                className='inline underline text-sm text-gray-400 hover:text-gray-500 cursor-pointer'
                onClick={() => props.setIsAcceptingDeposit(!props.isAcceptingDeposit)}
              >
                {props.isAcceptingDeposit ? 'Remove Deposit' : 'Take Deposit'}
              </span>
            </div>
            {props.isAcceptingDeposit && (
              <div className='relative flex flex-grow items-stretch focus-within:z-10 shadow-sm'>
                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                  <span className='text-gray-500 sm:text-sm'>$</span>
                </div>
                <input
                  type='number'
                  value={props.reservation.deposit_in_cents || ''}
                  step='any'
                  name='deposit_in_cents'
                  id='deposit_in_cents'
                  onChange={(e) => {
                    props.reservationDispatch({
                      type: 'UPDATE_DEPOSIT_AMOUNT',
                      payload: {
                        deposit_in_cents: parseFloat(parseFloat(e.target.value).toFixed(2)),
                      },
                    })
                  }}
                  className='block w-full rounded-l-md border-gray-300 pl-7 pr-12 focus:border-green-700 focus:ring-green-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
                  style={{ paddingLeft: '1.75rem' }}
                  placeholder='0.00'
                  aria-describedby='price-currency'
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default PriceDetailsSection
