import { useState, useEffect } from 'react'

// How to use with 1 breakpoint
//   const isSmall = useBreakpoint('(min-width: 640px)')
//   const isMedium = useBreakpoint('(min-width: 768px)')
//   const isLarge = useBreakpoint('(min-width: 1024px)')
  
// How to use with multiple breakpoints
//   const isSmall = useBreakpoint('(min-width: 640px) and (max-width: 767px)')
//   const isMedium = useBreakpoint('(min-width: 768px) and (max-width: 1023px)')
//   const isLarge = useBreakpoint('(min-width: 1024px) and (max-width: 1279px)')
//   const isXLarge = useBreakpoint('(min-width: 1280px) and (max-width: 1535px)')
//   const isXXLarge = useBreakpoint('(min-width: 1536px)')

function useBreakpoint(breakpoint) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia(breakpoint)

    const handleChange = () => setMatches(mediaQuery.matches)

    // Set the initial value
    setMatches(mediaQuery.matches)

    // Add listener
    mediaQuery.addEventListener('change', handleChange)

    // Cleanup listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [breakpoint])

  return matches
}

export default useBreakpoint
