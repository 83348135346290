import { useEffect } from 'react'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { uniqueId } from 'lodash'

const getSessionId = (): string => {
  const existingSessionId = localStorage.getItem('guestSessionId')
  if (existingSessionId) return existingSessionId

  const newSessionId = uniqueId()
  localStorage.setItem('guestSessionId', newSessionId)
  return newSessionId
}

interface LogRocketOptions {
  sampleRate: number
}

const useLogRocket = (options?: LogRocketOptions) => {
  const isProd = process.env.RAILS_ENV === 'production'
  const isBrowser = typeof window !== 'undefined'
  const key = process.env.LOGROCKET_KEY

  const shouldInitialize = options?.sampleRate ? Math.random() < options?.sampleRate : true

  useEffect(() => {
    if (!(key && isBrowser && isProd && shouldInitialize)) return

    const sessionId = getSessionId()
    LogRocket.init(key)

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL)
        scope.setUser({ id: sessionId })
      })
    })
  }, [shouldInitialize])

  const identifyLogRocketUser = (user: { id?: string; email: string }, campName?: string) => {
    LogRocket.identify(user.id || user.email, {
      email: user.email,
      campName: campName,
    })
  }

  return identifyLogRocketUser
}

export default useLogRocket
