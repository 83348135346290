import { NewReservationProps } from '../interfaces'
import { PriceDetails } from '../utils/priceDetailsFor'

const newReservationInitialState: NewReservationProps = {
  id: null,
  site_id: null,
  status: 'unpaid',
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  passengers: null,
  subtotal: null,
  deposit_in_cents: null,
  total_owed_in_cents: null,
  notes: null,
  edited_total: false,
  is_tax_enabled: true,
  is_passenger_fees_enabled: true,
  is_pets_fee_enabled: true,
  is_holiday_rate_enabled: true,
  is_weekend_fee_enabled: true,
  is_credit_card_fee_enabled: true,
  interval_id: null,
}

export enum NewReservationAction {
  UPDATE_ID = 'UPDATE_ID',
  UPDATE_SITE_ID = 'UPDATE_SITE_ID',
  UPDATE_SITE_ID_AND_DATES = 'UPDATE_SITE_ID_AND_DATES',
  UPDATE_STATUS_AND_KIND = 'UPDATE_STATUS_AND_KIND',
  UPDATE_DISCOUNT_NAME = 'UPDATE_DISCOUNT_NAME',
  UPDATE_COUPON_CODES = 'UPDATE_COUPON_CODES',
  UPDATE_IS_TAX_ENABLED = 'UPDATE_IS_TAX_ENABLED',
  UPDATE_IS_PASSENGER_FEES_ENABLED = 'UPDATE_IS_PASSENGER_FEES_ENABLED',
  UPDATE_IS_PETS_FEE_ENABLED = 'UPDATE_IS_PETS_FEE_ENABLED',
  UPDATE_IS_HOLIDAY_RATE_ENABLED = 'UPDATE_IS_HOLIDAY_RATE_ENABLED',
  UPDATE_IS_WEEKEND_FEE_ENABLED = 'UPDATE_IS_WEEKEND_FEE_ENABLED',
  UPDATE_IS_CREDIT_CARD_FEE_ENABLED = 'UPDATE_IS_CREDIT_CARD_FEE_ENABLED',
  UPDATE_START_DATE = 'UPDATE_START_DATE',
  UPDATE_START_TIME = 'UPDATE_START_TIME',
  UPDATE_END_DATE = 'UPDATE_END_DATE',
  UPDATE_END_TIME = 'UPDATE_END_TIME',
  UPDATE_NOTES = 'UPDATE_NOTES',
  UPDATE_SUBTOTAL = 'UPDATE_SUBTOTAL',
  UPDATE_DEPOSIT_AMOUNT = 'UPDATE_DEPOSIT_AMOUNT',
  UPDATE_PRICE_DETAILS = 'UPDATE_PRICE_DETAILS',
  UPDATE_INTERVAL_ID = 'UPDATE_INTERVAL_ID',
  EDITED_TOTAL = 'EDITED_TOTAL',
  UNEDITED_TOTAL = 'UNEDITED_TOTAL',
  UPDATE_PASSENGERS = 'UPDATE_PASSENGERS',
  UPDATE_KID_CAMPERS = 'UPDATE_KID_CAMPERS',
}

const newReservationReducer = (state: NewReservationProps, action: any): NewReservationProps => {
  switch (action.type) {
    case NewReservationAction.UPDATE_ID:
      return { ...state, id: action.payload.id }
    case NewReservationAction.UPDATE_SITE_ID:
      return { ...state, site_id: action.payload.site_id }
    case NewReservationAction.UPDATE_SITE_ID_AND_DATES:
      return {
        ...state,
        site_id: action.payload.site_id,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        start_time: action.payload.start_time,
        end_time: action.payload.end_time,
      }
    case NewReservationAction.UPDATE_STATUS_AND_KIND:
      return {
        ...state,
        status: action.payload.status,
        kind: action.payload.kind,
      }
    case NewReservationAction.UPDATE_NOTES:
      return { ...state, notes: action.payload.notes }
    case NewReservationAction.UPDATE_DISCOUNT_NAME:
      return { ...state, discount_name: action.payload.discount_name }
    case NewReservationAction.UPDATE_COUPON_CODES:
      return { ...state, couponCodes: action.payload.coupon_codes }
    case NewReservationAction.UPDATE_IS_TAX_ENABLED:
      return { ...state, is_tax_enabled: action.payload.is_tax_enabled }
    case NewReservationAction.UPDATE_IS_PASSENGER_FEES_ENABLED:
      return { ...state, is_passenger_fees_enabled: action.payload.is_passenger_fees_enabled }
    case NewReservationAction.UPDATE_IS_PETS_FEE_ENABLED:
      return { ...state, is_pets_fee_enabled: action.payload.is_pets_fee_enabled }
    case NewReservationAction.UPDATE_IS_HOLIDAY_RATE_ENABLED:
      return { ...state, is_holiday_rate_enabled: action.payload.is_holiday_rate_enabled }
    case NewReservationAction.UPDATE_IS_WEEKEND_FEE_ENABLED:
      return { ...state, is_weekend_fee_enabled: action.payload.is_weekend_fee_enabled }
    case NewReservationAction.UPDATE_IS_CREDIT_CARD_FEE_ENABLED:
      return { ...state, is_credit_card_fee_enabled: action.payload.is_credit_card_fee_enabled }
    case NewReservationAction.UPDATE_START_DATE:
      return { ...state, start_date: action.payload.start_date }
    case NewReservationAction.UPDATE_START_TIME:
      return { ...state, start_time: action.payload.start_time }
    case NewReservationAction.UPDATE_END_DATE:
      return { ...state, end_date: action.payload.end_date }
    case NewReservationAction.UPDATE_END_TIME:
      return { ...state, end_time: action.payload.end_time }
    case NewReservationAction.UPDATE_SUBTOTAL:
      return { ...state, subtotal: action.payload.subtotal }
    case NewReservationAction.UPDATE_DEPOSIT_AMOUNT:
      return { ...state, deposit_in_cents: action.payload.deposit_in_cents }
    case NewReservationAction.UPDATE_PRICE_DETAILS:
      const priceDetails = action.payload.price_details as PriceDetails
      return {
        ...state,
        subtotal: Number(priceDetails.subtotal?.toFixed(2)),
        total_owed_in_cents: Number(priceDetails.total?.toFixed(2)),
      }
    case NewReservationAction.UPDATE_INTERVAL_ID:
      return { ...state, interval_id: action.payload.interval_id }
    case NewReservationAction.EDITED_TOTAL:
      return { ...state, edited_total: true }
    case NewReservationAction.UNEDITED_TOTAL:
      return { ...state, edited_total: false }
    case NewReservationAction.UPDATE_PASSENGERS:
      return { ...state, passengers: action.payload.passengers }
    case NewReservationAction.UPDATE_KID_CAMPERS:
      return { ...state, kid_campers: action.payload.kid_campers }
    default:
      throw new Error(`ERROR: unknown action type ${action.type} in userParamsReducer`)
  }
}

export { newReservationInitialState, newReservationReducer }
